import React, { useContext } from "react";
import { CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
    SupportTicketPopupContext,
    DECISION_STEPS,
    ISSUE_ACCOUNT,
    CREATE_SUPPORT_TICKET,
} from "../GetHelpLabel";
import { STEP_PREVIOUS_CREATE_TICKET } from "../../../../../constants";

const IssueAccount1 = () => {
    const { setCurrentStep } = useContext(SupportTicketPopupContext);

    return (
        <div className="ticket-popup-content">
            <div className="btn-back-ticket">
                <CIcon name="icon-chevron-left" width="6" />
                <span onClick={() => setCurrentStep(DECISION_STEPS.step3)}>
                    Back
                </span>
            </div>
            <div className="ticket-popup-header">
                <h5>We're Here to Help!</h5>
                <p>Tell us more about your issue.</p>
            </div>
            <div className="ticket-popup-body">
                <CButton
                    onClick={() => setCurrentStep(ISSUE_ACCOUNT.step2)}
                    color="primary"
                >
                    I'm having issues giving users access
                </CButton>
                <CButton
                    onClick={() => setCurrentStep(ISSUE_ACCOUNT.step3)}
                    color="primary"
                >
                    I'm having issues understanding my invoice
                </CButton>
                <CButton
                    color="primary"
                    onClick={() => {
                        setCurrentStep(CREATE_SUPPORT_TICKET.step1);
                        localStorage.setItem(
                            STEP_PREVIOUS_CREATE_TICKET,
                            ISSUE_ACCOUNT.step1
                        );
                    }}
                >
                    I'm having issues with my credit card or upgrades
                </CButton>
                <CButton
                    color="primary"
                    onClick={() => {
                        setCurrentStep(CREATE_SUPPORT_TICKET.step1);
                        localStorage.setItem(
                            STEP_PREVIOUS_CREATE_TICKET,
                            ISSUE_ACCOUNT.step1
                        );
                    }}
                >
                    Something else
                </CButton>
            </div>
        </div>
    );
};

export default IssueAccount1;
