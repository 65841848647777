import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setViewedRule } from '../../../../../../actions/common';
import { callApi } from '../../../../../../apiCaller';
import { API_CLIENT_RULE } from '../../../../../../constants';
import { getTokenCookie, toastError } from '../../../../../../utils';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import LookupTable from './LookupTable';
export const RuleTableContext = React.createContext({});
const EditLookupTable = ({ id }) => {
     const dispatch = useDispatch();
     const token = getTokenCookie();
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     // const { activeRule, listRules } = useSelector((state) => state.theme.viewedRules);
     const editingRuleReload = useSelector((state) => state.theme.editingRuleReload);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const [activeStep, setActiveStep] = useState(1);
     const [rule, setRule] = useState();
     const [initialRule, setInitialRule] = useState();
     const [fetchRuleLoading, setFetchRuleLoading] = useState();
     const activeListener = useSelector((state) => state.subscriber.activeListener);

     const fetchRule = () => {
          setFetchRuleLoading(true);
          callApi(`${API_CLIENT_RULE}${id}`, 'GET', null, token)
               .then((response) => {
                    if (response.status === 200) {
                         const { rule } = response.data;
                         if (rule.accountId !== activeAccount.id) {
                              window.location.href = '/';
                         }
                         setRule(rule);
                         setInitialRule(cloneDeep(rule));
                         dispatch(setViewedRule(cloneDeep(rule)));
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setFetchRuleLoading(false);
               });
     };
     // }
     // editingRuleReload meaning: Editing rule pusher will make editingRuleReload = !editingRuleReload,
     // so other users are editing the same rule will fetch rule again
     useEffect(fetchRule, [editingRuleReload, accountListeners]);

     return (
          <>
               {fetchRuleLoading ? (
                    <CenterSpinner />
               ) : (
                    rule && initialRule && (
                         <>
                              <RuleTableContext.Provider
                                   value={{
                                        activeStep,
                                        setRule,
                                        setActiveStep,
                                        fetchRule,
                                        activeListener
                                   }}
                              >

                                   <LookupTable rule={rule} initialRule={initialRule} />
                              </RuleTableContext.Provider>
                         </>
                    )
               )}
          </>
     );
};

export default EditLookupTable;
