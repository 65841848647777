import React from "react";
import { CFooter } from '@coreui/react';

const SubscriberFooter = () => {
     return (
          <CFooter fixed={false}>
          </CFooter>
     )
}

export default React.memo(SubscriberFooter)
