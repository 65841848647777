import SwitchPopup from './SwitchPopup';
import ConfirmRemovePopup from './ConfirmRemovePopup';
import RemoveUserFromAccount from './RemoveUserFromAccount';
import InviteUserPopup from './InviteUserPopup';
import ConfirmSaveChange from './ConfirmSaveChange';
import SetPasswordPopup from './SetPasswordPopup';
import ChangeEmailPopup from './ChangeEmailPopup';
import UpdatePaymentPopup from './UpdatePaymentPopup';
import Popup from './Popup';
import ComparePlansPopup from './ComparePlansPopup';
import InstructionPopup from './InstructionPopup';
import VariableValueModal from './variable-value/VariableValueModal';
import LeaveComponentChangePopup from './LeaveComponentChangePopup';
import TermAndConditionPopup from './TermAndConditionPopup';
import ShowImage from './ShowImage';
import TermAndConditionMasterClassPopup from './TermAndConditionMasterClassPopup'

export {
     SwitchPopup,
     ConfirmRemovePopup,
     RemoveUserFromAccount,
     InviteUserPopup,
     ConfirmSaveChange,
     SetPasswordPopup,
     ChangeEmailPopup,
     UpdatePaymentPopup,
     Popup,
     ComparePlansPopup,
     InstructionPopup,
     VariableValueModal,
     LeaveComponentChangePopup,
     TermAndConditionPopup,
     ShowImage,
     TermAndConditionMasterClassPopup,
}