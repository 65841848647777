import {
     CInvalidFeedback
} from '@coreui/react';
import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import Select from 'react-select';
import { RegionContext } from './SingleRow';

import dataCountries from '../../../../../../assets/consentCountries.json';

const countries = dataCountries.map(country => { return { value: country, label: country } });

const RowCountry = () => {
     const { errors, setFieldValue } = useFormikContext();
     const { CONSENT_REGION_TYPES, row, index, onChangeRegionType, handleHasChange } = useContext(RegionContext);

     const regionRequired = errors.geographicRegions && errors.geographicRegions[index] && errors.geographicRegions[index].country;
     const regionTypeRequired = errors.geographicRegions && errors.geographicRegions[index] && errors.geographicRegions[index].regionType;
     const valueRegionType = CONSENT_REGION_TYPES.filter(option => option.value === row.regionType);
     const valueCountry = countries.filter(option => option.value === row.country);

     const onChangeCountry = (value) => {
          setFieldValue(`geographicRegions.${index}.country`, value);
          handleHasChange();
     };

     return (
          <>
               <div className='region-type'>
                    <Select
                         className={(regionTypeRequired) ? 'is-invalid' : null}
                         value={valueRegionType}
                         name={`regionsType.${index}`}
                         options={CONSENT_REGION_TYPES}
                         onChange={(e) => onChangeRegionType(e.value)}
                         placeholder="Select a region type"
                    />
                    <CInvalidFeedback>{regionTypeRequired}</CInvalidFeedback>
               </div>

               <div className='region-type'>
                    <Select
                         className={(regionRequired) ? ' is-invalid' : ''}
                         value={valueCountry}
                         isDisabled={!row.regionType}
                         name={`country.${index}`}
                         options={countries}
                         onChange={(e) => onChangeCountry(e.value)}
                         placeholder="Select a Country"
                    />
                    <CInvalidFeedback>{regionRequired}</CInvalidFeedback>
               </div>
          </>
     )
}
export default RowCountry;