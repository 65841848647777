import React, { useContext, useState } from 'react';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import { CButton, CCard, CCardBody, CForm, CFormGroup } from '@coreui/react';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import { callTokenApi } from '../../../../../apiCaller';
import { ACCOUNT_QUESTION_ARR, API_CLIENT_USER_INFO, DORMANT_STATUSES } from '../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { toastError } from '../../../../../utils';
import { setUser } from '../../../../../actions/common';
import { urlParams } from '../../../../../helpers/cms/subscriber';
import { useHistory } from 'react-router-dom';
import { setShowAccountDormantPopup, setShowCreateAccountPopup } from '../../../../../actions/subscriber';


const UserDesRole = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.theme.user);
    const { stepsData, setStepsData, setCurrentStep, prevSteps, AnsweredAccountInfo } = useContext(CreateAccountContext);
    const accounts = useSelector((state) => state.subscriber.accounts);
    const dormantAccount = useSelector((state) => state.subscriber.dormantAccount);
    const [isLoading, setIsLoading] = useState(false);
    const dataUser = (user && JSON.parse(user.registerUserInfo)) || JSON.parse(stepsData.dataUser)
    const options = [
        'Leader (VP, Chief Marketing Officer, etc.)',
        'Specialist (marketer, developer, data expert, etc.)',
    ];

    const _arrSlug = [
        'masterclass',
        'certification',
        'office-hours',
        'become-a-partner',
        'support-tickets',
        'profile',
        'email-notifications',
        'payment-methods',
    ];

    const defaultPackageIdParam = urlParams('defaultPackageId');
    const _pathName = window.location.pathname.replace('/', '');

    const activeAccounts = accounts ? accounts.filter(account => !DORMANT_STATUSES.includes(account.platformAccountStatus)) : [];
    const showBackBtn = (activeAccounts.length > 0 || dormantAccount || (user && user.registerMasterClass) || !!_arrSlug.find(slug => _pathName.includes(slug)));

    const onBackBtnClicked = () => {
        if (user) {

            if (showBackBtn && prevSteps.length <= 0) {
                if (dormantAccount) {
                    dispatch(setShowAccountDormantPopup(true));
                }
                dispatch(setShowCreateAccountPopup(false));
                if (defaultPackageIdParam) {
                    history.push('/');
                }
            }
            else {
                setCurrentStep(null, CHANGE_STEP_TYPE.subStepPrev);
            }
        } else {
            setCurrentStep(STEPS.userSizeCompany)
        }
    };

    const goToNextStep = () => {
        setCurrentStep(null, CHANGE_STEP_TYPE.mainStepNext);
    };

    const saveData = (dataUser) => {
        setIsLoading(true);
        callTokenApi(API_CLIENT_USER_INFO, 'PUT', { registerUserInfo: dataUser })
            .then(response => {
                if (response.status === 200) {
                    setStepsData({ ...stepsData, dataUser: JSON.stringify(dataUser) })
                    dispatch(setUser({ ...user, registerUserInfo: JSON.stringify(dataUser) }));
                    goToNextStep();
                } else {
                    setIsLoading(false);
                    toastError(response);
                }
            })
    };

    const onSubmit = (e) => {
        const value = e.target.textContent;
        if (user) {
            if (dataUser.desRole) {
                if (dataUser.desRole === value) {
                    goToNextStep();
                } else {
                    dataUser.desRole = value;
                    saveData(dataUser);
                }
            } else {
                dataUser.desRole = value;
                saveData(dataUser);
            }
        } else {
            const dataUser = JSON.parse(stepsData.dataUser)
            dataUser.desRole = value
            setStepsData({ ...stepsData, dataUser: JSON.stringify(dataUser) });
            setCurrentStep(STEPS.userRevenue)
        }
    };

    return (
        <CreateAccountBody hideStepProgress={true} onBackBtnClicked={onBackBtnClicked}>
            <CCard className="account-brings">
                <AnsweredAccountInfo step={STEPS.userDesRole} arr={ACCOUNT_QUESTION_ARR} />
                <CCardBody>
                    <CForm>
                        {
                            isLoading ? (
                                <CenterSpinner />
                            ) : (
                                <CFormGroup>
                                    <p>What best describes your role?</p>
                                    <div className="list-bring">
                                        {
                                            options.map((option) => {
                                                return (
                                                    <CButton key={option} className="btn-select" onClick={onSubmit}>
                                                        {option}
                                                    </CButton>
                                                );
                                            })
                                        }
                                    </div>
                                </CFormGroup>
                            )
                        }
                    </CForm>
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    )
}

export default UserDesRole;