import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
     CCreateElement,
     CSidebar,
     CSidebarBrand,
     CSidebarNav,
     CSidebarNavDivider,
     CSidebarNavTitle,
     CSidebarNavDropdown,
     CSidebarNavItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

import { setSidebarShow } from '../../actions/common';

// sidebar nav config
import { adminNav } from '../_nav';

const AdminSidebar = () => {
     const dispatch = useDispatch();
     const show = useSelector(state => state.theme.sidebarShow);

     return (
          <CSidebar
               show={show}
               unfoldable
               onShowChange={(val) => dispatch(setSidebarShow(val))}
               className="c-sidebar-left"
          >
               <CSidebarBrand className="d-md-down-none" to="/">
                    <CIcon
                         className="c-sidebar-brand-full"
                         name="logo-light"
                         height={35}
                    />
                    <CIcon
                         className="c-sidebar-brand-minimized"
                         name="sygnet"
                         height={35}
                    />
               </CSidebarBrand>
               <CSidebarNav>
                    <CCreateElement
                         items={adminNav}
                         components={{
                              CSidebarNavDivider,
                              CSidebarNavDropdown,
                              CSidebarNavItem,
                              CSidebarNavTitle
                         }}
                    />
               </CSidebarNav>
               {/* <CSidebarMinimizer className="c-d-md-down-none" /> */}
          </CSidebar>
     )
}

export default React.memo(AdminSidebar);
