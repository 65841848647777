import CIcon from '@coreui/icons-react';
import { CLink } from '@coreui/react';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAsideShow, setFlexibleModal } from '../../../../../actions/common';
import { setActiveAccount, setSignUpStep, setShowUpgradeAccountPopup } from '../../../../../actions/subscriber';
import { SUBSCRIBER_MY_ACCOUNT_PATH } from '../../../../../constants';
import { SupportTicketPopupContext } from '../GetHelpLabel';

const TicketSuccessfullyCreate = () => {
     const dispatch = useDispatch();
     const { ticket, closePopup } = useContext(SupportTicketPopupContext);
     const signUpStep = useSelector((state) => state.subscriber.signUpStep);
     const createdAccount = useSelector((state) => state.subscriber.createdAccount);
     const showUpgradeAccountPopup = useSelector((state) => state.subscriber.showUpgradeAccountPopup);

     const openSupportCenter = () => {
          dispatch(setFlexibleModal({ show: false, showLv2: false, component: '' }));
          if (createdAccount) {
               dispatch(setActiveAccount(createdAccount));
          }
          if (showUpgradeAccountPopup) {
               dispatch(setShowUpgradeAccountPopup(false));
          }
          dispatch(setAsideShow(false));
          closePopup();
          dispatch(
               setSignUpStep({
                    previousStep: signUpStep.currentStep,
               })
          );
          setTimeout(() => {
               document.body.classList.remove('no-scroll');
          }, 500);
     };

     return (
          <div className='ticket-popup-content ticket-popup-successfully'>
               <div className='btn-close-ticket'>
                    <span onClick={closePopup}>
                         <CIcon name='cil-x' width='12' />
                         Close
                    </span>
               </div>
               <div className='ticket-popup-header'>
                    <h5>Ticket Successfully Created!</h5>
               </div>
               <div className='ticket-popup-body'>
                    <div className='ticket-popup-text'>
                         <p>
                              We've created your support ticket and emailed you a copy of your request. Check your spam and promotions folder and move the email to your
                              inbox if you find the email there.
                         </p>
                         <p>
                              The name of your request is: <strong>{ticket.name}</strong>
                         </p>
                         <p>
                              Your Ticket Number is: <strong>Case-{ticket.caseId}</strong>
                         </p>
                         <p>
                              Support tickets are managed inside your user account. Any manager or owner of an account can see open and past support tickets for their
                              account.
                         </p>
                    </div>
                    <div className='text-center'>
                         <CLink
                              className='btn btn-primary'
                              to={SUBSCRIBER_MY_ACCOUNT_PATH.SUPPORT_TICKETS_WITH_PARAMS_ID.replace(':id', ticket.id)}
                              onClick={openSupportCenter}
                         >
                              Take Me to the Support Center
                         </CLink>
                    </div>
               </div>
          </div>
     );
};

export default TicketSuccessfullyCreate;
