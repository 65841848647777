import React, { useState } from 'react';
import {
     CButton,
     CInput,
     CInvalidFeedback,
     CForm,
     CFormGroup
} from '@coreui/react';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { CARD_TYPES, TRIAL_DAYS } from '../../../constants';

const validationSchema = (cardInputMask, cardCVCMask) => {
     let cardInputMaskTemp = cardInputMask.replace(/\s/gi, '');
     let cardNumberLength = cardInputMaskTemp.length;
     let cardCVCMaskTemp = cardCVCMask.replace(/\s/gi, '');
     let cardCVCLength = cardCVCMaskTemp.length;

     return Yup.object().shape({
          stripeCardName: Yup.string()
               .required('Card Name is required')
          ,
          stripeCardNumber: Yup.string()
               .required('Card Number is required')
               .test(
                    'lenCardNumber',
                    `Card Number must be exactly ${cardNumberLength} digits`,
                    value => { return value && value.replace(/_/gi, '').replace(/\s/gi, '').length === cardNumberLength },
               )
          ,
          stripeCardExpiration: Yup.string()
               .required('Expiration is required')
               .test(
                    'validateExpiration',
                    `Card Expiration has not been fully entered`,
                    value => { return value && value.replace(/_/gi, '').replace(/\s/gi, '').length === 7 },
               )
          ,
          stripeCardCVC: Yup.string()
               .required('CVC number is required')
               .test(
                    'lenCardCVC',
                    `Card CVC Number must be exactly ${cardCVCLength} digits`,
                    value => { return value && value.replace(/_/gi, '').replace(/\s/gi, '').length === cardCVCLength },
               )
          ,
     })
}

const initialValues = {
     stripeCardName: '',
     stripeCardNumber: '',
     stripeCardExpiration: '',
     stripeCardCVC: '',
}

const PaymentInfo = ({ onSubmit, submitBtnText, onlyUpdatePayment, isUpgrade, firstUpgrade, pricePerMonth, annualBilling, pricePerYear, balance }) => {
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const [cardInputMask, setCardInputMask] = useState('9999 9999 9999 9999');
     const [cardCVCMask, setCardCVCMask] = useState('999');
     const [cardCVCPlaceholder, setCardCVCPlaceholder] = useState('CVV');
     const [cardType, setCardType] = useState('default');

     const handleUpdatePayment = (values, actions) => {
          onSubmit(values, actions);
     }

     const getStripeCardImage = () => {
          for (const type in CARD_TYPES) {
               const cardTypeDefined = CARD_TYPES[type];
               if (typeof cardTypeDefined.NAME !== 'undefined' && cardType === cardTypeDefined.NAME) {
                    return <img className="card-active" src={cardTypeDefined.IMAGE_SRC} alt={cardTypeDefined.NAME} />;
               }
          }
          return <img src="/assets/images/card-discover.svg" alt="Discover" />;
     }

     return (
          <>
               <Formik
                    initialValues={initialValues}
                    validationSchema={() => validationSchema(cardInputMask, cardCVCMask)}
                    onSubmit={handleUpdatePayment}
                    validateOnChange={false}
                    validateOnBlur={false}
               >
                    {
                         ({
                              values,
                              errors,
                              handleChange,
                              handleSubmit,
                              setFieldValue,
                              setFieldError,
                              isSubmitting,
                         }) => {
                              const resetCardType = () => {
                                   setCardType('default');
                                   setCardInputMask(CARD_TYPES.OTHER.INPUT_MASK);
                                   setCardCVCMask(CARD_TYPES.OTHER.CVC_MASK);
                                   setCardCVCPlaceholder(CARD_TYPES.OTHER.CVC_PLACEHOLDER);
                              }

                              const handleChangeCardNumber = (e) => {
                                   const { value } = e.target;
                                   let cardNumberValue = value.replace(/_/gi, '').replace(/ /gi, '');
                                   if (cardNumberValue) {
                                        for (const type in CARD_TYPES) {
                                             const cardType = CARD_TYPES[type];
                                             if (typeof cardType.NAME !== 'undefined') {
                                                  if (cardType.REGEX_PREFIX.test(cardNumberValue)) {
                                                       setCardType(cardType.NAME);
                                                       setCardInputMask(cardType.INPUT_MASK);
                                                       setCardCVCMask(cardType.CVC_MASK);
                                                       setCardCVCPlaceholder(cardType.CVC_PLACEHOLDER);
                                                       break;
                                                  }
                                             } else {
                                                  resetCardType();
                                             }
                                        }
                                   } else {
                                        resetCardType();
                                   }

                                   setFieldValue('stripeCardCVC', '')
                                   setFieldValue('stripeCardNumber', value)
                                   if (errors['stripeCardNumber']) {
                                        setFieldError('stripeCardNumber', undefined);
                                   }
                              }

                              const updateCardOnPaste = (e) => {
                                   let cardNumberValue = e.clipboardData.getData('Text');
                                   cardNumberValue = cardNumberValue.replace(/\D/g, '');
                                   let currentCardNumber = values.stripeCardNumber.replace(/\D/g, '');
                                   if (currentCardNumber.length !== cardNumberValue.length) {
                                        e.preventDefault();
                                        for (const type in CARD_TYPES) {
                                             const cardType = CARD_TYPES[type];
                                             if (typeof cardType.NAME !== 'undefined') {
                                                  if (cardType.REGEX_PREFIX.test(cardNumberValue)) {
                                                       setCardType(cardType.NAME);
                                                       setCardInputMask(cardType.INPUT_MASK);
                                                       setCardCVCMask(cardType.CVC_MASK);
                                                       setCardCVCPlaceholder(cardType.CVC_PLACEHOLDER);
                                                       break;
                                                  }
                                             }
                                        }
                                        values.stripeCardNumber = cardNumberValue;
                                   }
                              }
                              return (
                                   <CForm onSubmit={handleSubmit} noValidate>
                                        <CFormGroup className="credit-card-name">
                                             {/* <p className="update-card-details">Add Your Credit Card</p> */}
                                             {/* <CLabel>Name on Card</CLabel> */}
                                             <CInput
                                                  name='stripeCardName'
                                                  invalid={!!errors.stripeCardName}
                                                  placeholder="Name on card"
                                                  value={values.stripeCardName ? values.stripeCardName : ''}
                                                  disabled={isSubmitting}
                                                  onChange={(e) => {
                                                       handleChange(e);
                                                       if (errors['stripeCardName']) {
                                                            setFieldError('stripeCardName', undefined);
                                                       }
                                                  }}
                                             />
                                             <CInvalidFeedback>{errors.stripeCardName}</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup className="input-card-number">
                                             {/* <CLabel>Card Number</CLabel> */}
                                             {/* <div className="logo-card">
                                                  {getStripeCardImage()}
                                             </div> */}
                                             <div className="position">
                                                  <InputMask
                                                       className={`form-control${(!!errors.stripeCardNumber) ? ' is-invalid' : ''}`}
                                                       name='stripeCardNumber'
                                                       placeholder="0000 0000 0000 0000"
                                                       value={values.stripeCardNumber ? values.stripeCardNumber : ''}
                                                       disabled={isSubmitting}
                                                       onChange={handleChangeCardNumber}
                                                       onPaste={updateCardOnPaste}
                                                       mask={cardInputMask}
                                                       maskChar="_"
                                                  />
                                                  <div className="logo-card">
                                                       {getStripeCardImage()}
                                                  </div>
                                                  {/* <CImg src="/assets/images/visa.png" className="input-card-number__img" /> */}
                                                  <CInvalidFeedback>{errors.stripeCardNumber}</CInvalidFeedback>
                                             </div>
                                        </CFormGroup>
                                        <CFormGroup className="expiration-card">
                                             {/* <CLabel>Expiration</CLabel> */}
                                             <InputMask
                                                  className={`form-control${(!!errors.stripeCardExpiration) ? ' is-invalid' : ''}`}
                                                  name='stripeCardExpiration'
                                                  placeholder="MM / YY"
                                                  value={values.stripeCardExpiration ? values.stripeCardExpiration : ''}
                                                  disabled={isSubmitting}
                                                  onChange={(e) => {
                                                       handleChange(e);
                                                       if (errors['stripeCardExpiration']) {
                                                            setFieldError('stripeCardExpiration', undefined);
                                                       }
                                                  }}
                                                  mask="99/2099"
                                                  maskChar="_"
                                             />
                                             <CInvalidFeedback>{errors.stripeCardExpiration}</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup className="cvc-card">
                                             {/* <CLabel>CVC</CLabel> */}
                                             <InputMask
                                                  className={`form-control${(!!errors.stripeCardCVC) ? ' is-invalid' : ''}`}
                                                  name='stripeCardCVC'
                                                  placeholder={cardCVCPlaceholder}
                                                  value={values.stripeCardCVC ? values.stripeCardCVC : ''}
                                                  disabled={isSubmitting}
                                                  onChange={(e) => {
                                                       handleChange(e);
                                                       if (errors['stripeCardCVC']) {
                                                            setFieldError('stripeCardCVC', undefined);
                                                       }
                                                  }}
                                                  mask={cardCVCMask}
                                                  maskChar="_"
                                             />
                                             <CInvalidFeedback>{errors.stripeCardCVC}</CInvalidFeedback>
                                        </CFormGroup>
                                        {
                                             !onlyUpdatePayment && (
                                                  <>
                                                       {
                                                            isUpgrade ? (
                                                                 (activeAccount.packagePricePerMonth > 0 && firstUpgrade) ? (
                                                                      <p className="payment-description p-0">
                                                                           If you are in a current billing cycle, we will reduce the amount charged today by the pro-rated, unused time on your current package.
                                                                      </p>
                                                                 ) : (
                                                                      <p className="payment-description p-0">
                                                                           Your subscription is paid {annualBilling ? 'annually' : 'monthly'}. We'll validate your credit card now and{" "}
                                                                           will charge your card ${annualBilling ? pricePerYear.toLocaleString('en-US') : pricePerMonth.toLocaleString('en-US')} {firstUpgrade ? `after your ${TRIAL_DAYS} free days` : `today`}. If you are in a current billing cycle, we will reduce the amount charged today by the pro-rated, unused time on your current package.
                                                                      </p>
                                                                 )
                                                            ) : (
                                                                 <p className="payment-description p-0">
                                                                      We will refund you credit card ${balance} for the time you have paid for and not used on your current plan.
                                                                      {(pricePerMonth !== 0) && ` We will also then bill your credit card for $${annualBilling ? pricePerYear.toLocaleString('en-US') : pricePerMonth.toLocaleString('en-US')} for the new plan that you have chosen.`}
                                                                 </p>
                                                            )
                                                       }
                                                  </>
                                             )
                                        }
                                        <CButton
                                             color="success"
                                             type="submit"
                                             className="btn-save"
                                             disabled={isSubmitting}
                                        >
                                             {isSubmitting ? (<span className="dots-waiting">Waiting</span>) : submitBtnText}
                                        </CButton>
                                   </CForm>
                              )
                         }
                    }
               </Formik>
          </>
     )
}

PaymentInfo.propTypes = {
     onSubmit: PropTypes.func,
     submitBtnText: PropTypes.string,
     isUpgrade: PropTypes.bool,
     pricePerMonth: PropTypes.number,
     pricePerYear: PropTypes.number,
     annualBilling: PropTypes.bool,
     balance: PropTypes.number,
     firstUpgrade: PropTypes.bool
}

PaymentInfo.defaultProps = {
     onSubmit: f => f
}

export { PaymentInfo }
