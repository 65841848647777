import React, { useContext, useEffect, useState } from 'react';
import { CButton, CFormGroup, CCard, CCardBody, CInput } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { fetchGTMContainersByGTMAccount, setSubscriberState } from '../../../../../../actions/subscriber';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { toastError, tryParseJSON } from '../../../../../../utils';
import { useScrollFaded } from '../../../../../../helpers/customHooks';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_DESTINATIONS_GTM_CONTAINERS } from '../../../../../../constants';
import Waiting from '../../../../../general/Loadings/Waiting';
import { SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';

const ContainerList = ({ sortedGTMContainers, selectedGTMContainer, handleSelectContainer, displayNoOptions, optionDisabled }) => (
     <div className='gtm-container-list'>
          {sortedGTMContainers.length === 0 && displayNoOptions ? (
               <p>No filtering results</p>
          ) : (
               sortedGTMContainers.map((gtmContainer) => {
                    const classes = classNames('btn-select', { active: selectedGTMContainer && selectedGTMContainer.containerId === gtmContainer.containerId });

                    return (
                         <CButton
                              type='button'
                              className={classes}
                              onClick={() => handleSelectContainer(gtmContainer)}
                              key={gtmContainer.containerId}
                              disabled={optionDisabled}
                         >
                              {gtmContainer.containerName}
                         </CButton>
                    );
               })
          )}
     </div>
);

const GTMSelectContainer = () => {
     const dispatch = useDispatch();
     const { setCurrentStep, stepsData, setStepsData, handleUpdateSwitchType } = useContext(SwitchTypeWizardContext);
     const gtmContainers = useSelector((state) => state.subscriber.gtmContainers);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);

     const { selectedGTMAccount, selectedGTMContainer } = stepsData;
     const accountDomains = tryParseJSON(activeAccount.domains);
     const domain = Array.isArray(accountDomains) ? accountDomains[0].name : '';

     const [filterText, setFilterText] = useState('');
     const [isLoading, setIsLoading] = useState(true);
     const [nextStepLoading, setNextStepLoading] = useState(false);
     const [rateLimitExceeded, setRateLimitExceeded] = useState(false);

     const filterGTMContainers = gtmContainers
          .map((el) => ({ ...el, containerName: `${el.name} (${el.publicId})` }))
          .filter((el) => el.containerName.toLowerCase().includes(filterText.toLowerCase()));

     const displayFilter = gtmContainers.length > 8;
     const { containerListClass, onScrollContainerList } = useScrollFaded({ hasScroll: filterGTMContainers.length > 3 });

     // const sortGTMContainers = useCallback((filterGTMContainers) => {
     //     let sortedGTMContainers = [...filterGTMContainers];
     //     const foundTopContainerIndex = filterGTMContainers.findIndex((el) => includeLowercase(el.name, domain));

     //     if (foundTopContainerIndex > 0) {
     //         moveElementToFrontArr(sortedGTMContainers, foundTopContainerIndex);
     //     }

     //     return sortedGTMContainers;
     // }, [domain]);

     // let sortedGTMContainers = useMemo(() => sortGTMContainers(filterGTMContainers), [filterGTMContainers, sortGTMContainers]);
     let sortedGTMContainers = [...filterGTMContainers];

     const handleFirstLoad = () => {
          // if (gtmContainers.length === 0) {
          const rateLimitExceededNext = () => {
               setRateLimitExceeded(true);
          };

          dispatch(fetchGTMContainersByGTMAccount({ accountId: activeAccount.id, gtmAccountId: selectedGTMAccount.accountId, setIsLoading, rateLimitExceededNext }));
          // } else {
          //     setIsLoading(false);
          // }
     };

     useEffect(handleFirstLoad, []);

     const onBackBtnClicked = () => {
          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmSelectAccount);
     };

     const handleSelectContainer = (container) => {
          setStepsData({ selectedGTMContainer: container });
     };

     const goNext = () => {
          const data = {
               container: selectedGTMContainer,
               accountId: activeAccount.id,
          };

          setNextStepLoading(true);

          callTokenApi(API_CLIENT_DESTINATIONS_GTM_CONTAINERS, 'POST', data).then((response) => {
               if (response.status === 200) {
                    const newActiveAccount = { ...activeAccount, gtmInfo: response.data.gtmInfo };

                    dispatch(
                         setSubscriberState({ activeAccount: newActiveAccount, gtmVariablesInLL: null, gtmTriggersInLL: null, gtmTagsInLL: null, gtmChangelog: null })
                    );

                    setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmVerifyChanges);
                    handleUpdateSwitchType({ initStep: SWITCH_TYPE_WIZARD_STEP.gtmVerifyChanges });

                    // if (gtmWizard.data) {
                    //     setCurrentStep(GTM_WIZARD_STEPS.gtmCreateData, CHANGE_STEP_TYPE.NEXT);
                    // } else {
                    // dispatch(setGTMWizard({ show: false }));
                    // }
               } else {
                    setNextStepLoading(false);
                    toastError(response);
               }
          });
     };

     const onFilterChange = (e) => {
          setFilterText(e.target.value);
     };

     return (
          <SwitchTypeWizardBody onBackBtnClicked={onBackBtnClicked} backBtnDisabled={isLoading}>
               <CCard className={classNames('account-container', { 'no-container': gtmContainers.length === 0 })}>
                    <CCardBody>
                         {isLoading ? (
                              <CenterSpinner />
                         ) : (
                              <CFormGroup>
                                   <h3>Select Your container</h3>
                                   {rateLimitExceeded ? (
                                        <p>
                                             Sorry for the inconvenience. We have hit a 60-second API limit with Google Tag Manager. We're working with them to increase
                                             the limit.
                                        </p>
                                   ) : gtmContainers.length === 0 ? (
                                        <p>You have no GTM container, please create one on your GTM.</p>
                                   ) : (
                                        <>
                                             {gtmContainers.length === 1 ? (
                                                  <p>You have 1 GTM container.</p>
                                             ) : (
                                                  <p>
                                                       You have multiple GTM containers. Select the one that you use on <b>{domain}</b>. The one we think you may use is
                                                       listed at the top.
                                                  </p>
                                             )}
                                             {displayFilter && (
                                                  <>
                                                       <CInput className='filter' placeholder='Type to filter list...' value={filterText} onChange={onFilterChange} />
                                                       <div className={classNames('list-container', { 'd-none': filterGTMContainers.length > 3 })}>
                                                            <ContainerList
                                                                 sortedGTMContainers={sortedGTMContainers}
                                                                 selectedGTMContainer={selectedGTMContainer}
                                                                 handleSelectContainer={handleSelectContainer}
                                                                 displayNoOptions
                                                                 optionDisabled={nextStepLoading}
                                                            />
                                                       </div>
                                                  </>
                                             )}
                                             <div
                                                  className={classNames('list-container', containerListClass, {
                                                       'd-none': displayFilter && filterGTMContainers.length <= 3,
                                                  })}
                                                  onScroll={onScrollContainerList}
                                             >
                                                  <ContainerList
                                                       sortedGTMContainers={sortedGTMContainers}
                                                       selectedGTMContainer={selectedGTMContainer}
                                                       handleSelectContainer={handleSelectContainer}
                                                       optionDisabled={nextStepLoading}
                                                  />
                                             </div>
                                             <CButton className='btn-save' disabled={!selectedGTMContainer || nextStepLoading} onClick={goNext}>
                                                  <Waiting isLoading={nextStepLoading}>NEXT</Waiting>
                                             </CButton>
                                        </>
                                   )}
                                   <span className='foot-note'>
                                        {rateLimitExceeded
                                             ? 'The integration still works, we are just experiencing heavy volume.  Click Go Back, wait a few seconds, and try to connect to GTM again.'
                                             : "Don't see your container? You might not have the correct access. Go back and choose manual, or choose to skip this step for now."}
                                   </span>
                                   <CButton className='btn-navigation-step' onClick={onBackBtnClicked}>
                                        <i className='fal fa-angle-left'></i>Go Back
                                   </CButton>
                              </CFormGroup>
                         )}
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default GTMSelectContainer;
