import CIcon from '@coreui/icons-react';
import { CCol, CRow } from '@coreui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import XLSX from 'xlsx';
import { useEventAndVariableAccountOptions } from '../../../../../../helpers/customHooks';
import { capitalizeFirstLetter, useOutsideHandling } from '../../../../../../utils';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';

const ECommerceExportTable = ({ tableRows }) => {
     const [showImportExport, setShowImportExport] = useState(false);

     const exportToFile = (type = 'csv', fileName = 'table-import-export', onlyRow1And2) => {
          let dataToExport = [['event', 'trigger']];

          if (!onlyRow1And2) {
               if (Array.isArray(tableRows)) {
                    tableRows.forEach(({ event, triggerName }) => {
                         dataToExport.push([event, triggerName]);
                    });
               }
          }

          if (type === 'csv') {
               import('../../../../../../utils').then((utils) => {
                    utils.exportToCSV(`${fileName}.${type}`, dataToExport);
               });
          } else {
               var workSheet = XLSX.utils.aoa_to_sheet(dataToExport);

               let workBook = XLSX.utils.book_new();
               XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');

               XLSX.writeFile(workBook, `${fileName}.${type}`);
          }
     };

     const handClickImportExport = useCallback(() => {
          setTimeout(() => {
               setShowImportExport(!showImportExport);
          }, 100);
     }, [showImportExport]);

     const handleClickOutside = useCallback(() => {
          setShowImportExport(false);
     }, []);

     const wrapperRef = useRef(null);
     useOutsideHandling(wrapperRef, handleClickOutside);

     // Handle lockdown table header End
     return (
          <div className='import-export-wrapper'>
               <div className='import-export'>
                    <CIcon name='cil-chevron-double-down' height='14' />
                    <button type='button' onClick={handClickImportExport}>
                         Export
                    </button>
               </div>
               <ul ref={wrapperRef} className={showImportExport ? '' : 'hidden'}>
                    <li>
                         <button color='primary' type='button' onClick={() => exportToFile('csv')}>
                              <CIcon name='cil-cloud-download' height='14'></CIcon>
                              <span>Export existing table values</span>
                         </button>
                    </li>
               </ul>
          </div>
     );
};
export const LookupTableContext = React.createContext({});

const ECommerceLookupTable = ({ stepsData }) => {
     const flexibleModal = useSelector(state => state.theme.flexibleModal);

     const { conditions, customVariables, data } = stepsData;
     const triggers = data.triggers;
     const tableRows = [];
     const { fetchLoading } = useEventAndVariableAccountOptions(true);

     const getNonTierVariables = useCallback((variables) => {
          let variableData = [];
          if (variables && variables.length > 0) {
               variables.forEach(variable => {
                    if (variable.status && variable.childs) {
                         variableData.push(...getNonTierVariables(variable.childs));
                    }
                    else {
                         variableData.push(variable);
                    }
               })
          }
          return variableData;
     }, [])
     
     for (let i = 0; i < conditions.length; i++) {
          const condition = conditions[i];
          const event = condition.find((item) => item.g0.value === 'true').g0.key;
          const trigger = triggers.find((item) => item.id === customVariables[i][0].id);
          const triggerName = trigger ? trigger.name : null;
          const tableRow = {
               event,
               triggerName,
          };
          tableRows.push(tableRow);
     }
     // const pusherCondition = conditions[0].filter(col => {
     //      switch (col.g0.type) {
     //           case "Event":
     //                if (eventOptions.find(event => event.code === col.g0.key)) {
     //                     return col;
     //                }
     //                break;
     //           case "NativeBrowserStorage":
     //                let findNativeBS = nativeBrowserStorageOptions.find(event => JSON.stringify(event.key) === col.g0.key);
     //                if (findNativeBS) {
     //                     col.g0.key = JSON.stringify(findNativeBS.key);
     //                     return col;
     //                }
     //                break;
     //           // case "Variable":
     //           //      if (nonTierVariables.find(variable => variable.fullName === col.g0.key)) {
     //           //           return col;
     //           //      }
     //           //      break;
     //           default:
     //      }

     //      return null;
     // });
     const observedElementsRef = useRef([]);

     useEffect(() => {
          // Handle lockdown table header Start
          let header = document.querySelectorAll('.flexible-modal-header');
          let body = document.querySelectorAll('.flexible-modal-body');
          let table = document.querySelector(".lookup-table-init");
          let stickyTable = document.querySelector(".lookup-table-sticky");

          if (header.length > 0) {
               header = header[!flexibleModal.showLv2 ? 0 : 1];
          }

          if (body.length > 0) {
               body = body[!flexibleModal.showLv2 ? 0 : 1];
          }

          let headerHeight = header.offsetHeight;

          const handleResize = () => {
               let tableHeaderTop = table ? table.offsetTop : 0;
               let headerBottom = headerHeight + body.scrollTop;
               let tableHeader = table ? table.childNodes[0] : [];
               let tableHeader2ndRow = tableHeader.childNodes[1];
               let tableHeaderColumns;
               if (tableHeader2ndRow && tableHeader2ndRow.childNodes) {
                    tableHeaderColumns = tableHeader2ndRow.childNodes;
               }
               let stickyTableHeader = stickyTable.childNodes[0];
               headerHeight = header.offsetHeight;

               const updateStickyTable = () => {
                    stickyTable.style.width = table.parentNode.offsetWidth + 'px';
                    stickyTable.style.top = headerHeight + 'px';
                    for (let i = 0; i < tableHeaderColumns.length; i++) {
                         if (stickyTableHeader.childNodes[1].childNodes[i]) {
                              stickyTableHeader.childNodes[1].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                         }
                         // stickyTableHeader.childNodes[1].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                         // stickyTableHeader.childNodes[2].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                    }
               }

               if (headerBottom > tableHeaderTop + 10) {
                    if (window.screen.width === 991) {
                         setTimeout(() => {
                              updateStickyTable();
                         }, 600);
                    } else {
                         updateStickyTable();
                    }
               }
          }
          let modalBody = document.querySelectorAll('.flexible-modal-body');

          if (modalBody.length > 0) {
               modalBody = modalBody[!flexibleModal.showLv2 ? 0 : 1];
          }

          const handleScroll = () => {
               let tableHeaderTop = table ? table.offsetTop : 0;
               let headerBottom = headerHeight + body.scrollTop;
               let tableHeader = table ? table.childNodes[0] : [];
               let tableHeader2ndRow = tableHeader.childNodes ? tableHeader.childNodes[1] : null;
               let tableHeaderColumns;
               if (tableHeader2ndRow && tableHeader2ndRow.childNodes) {
                    tableHeaderColumns = tableHeader2ndRow.childNodes;
               }
               let stickyTableHeader = stickyTable.childNodes[0];
               if (headerBottom > tableHeaderTop + 15) {
                    tableHeader.style.visibility = 'hidden';
                    stickyTable.style.display = 'block';
                    stickyTable.style.width = table.parentNode.offsetWidth + 'px';
                    stickyTable.style.top = headerHeight + 'px';
                    for (let i = 0; i < tableHeaderColumns.length; i++) {
                         if (stickyTableHeader.childNodes[1].childNodes[i]) {
                              stickyTableHeader.childNodes[1].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                         }
                         // if (stickyTableHeader.childNodes[2].childNodes[i]) {
                         //      stickyTableHeader.childNodes[2].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                         // }
                    }
               } else {
                    table.children[0].style.visibility = 'visible';
                    stickyTable.style.display = 'none';
               }
          }

          modalBody.addEventListener('scroll', handleScroll);

          const resizeObserver = new ResizeObserver(entries => {
               entries.forEach(() => {
                    handleResize();
               });
          });

          const observedElements = document.querySelectorAll(`.ll-flexible-modal`);

          observedElements.forEach(element => {
               resizeObserver.observe(element);
          });

          observedElementsRef.current = Array.from(observedElements);

          return () => {
               // modalBody.removeEventListener('resize', handleResize);
               resizeObserver.disconnect();
               modalBody.removeEventListener('scroll', handleScroll);
          }
     }, []) // eslint-disable-line react-hooks/exhaustive-deps

     const tableWrapperOnScroll = (e) => {
          let stickyTable = document.querySelector('.lookup-table-sticky');
          let scrollLeft = e.target.scrollLeft;
          stickyTable.scrollLeft = scrollLeft;
     }
     return (
          <div className='form-edit-lookup trigger-rule ecommerce default-lookup-rule'>
               <CRow>
                    <CCol md='12' className='form-edit-lookup-title trigger-rule'>
                         <h1 title={stepsData.name}>
                              {`Edit ${capitalizeFirstLetter(stepsData.type)} Trigger Rule : ${stepsData.name}`}
                         </h1>
                    </CCol>
                    <CCol md='12' className='form-edit-lookup-description'>
                         <p>Manage variable values based on the conditions you selected when building this rule.</p>
                    </CCol>
               </CRow>
               <div className='table-wrapper mb-3' onScroll={(e) => tableWrapperOnScroll(e)}>
                    <table className='table table-user-source lookup-table lookup-table-init'>
                         {fetchLoading ? (
                              <tbody>
                                   <tr>
                                        <td>
                                             <CenterSpinner />
                                        </td>
                                   </tr>
                              </tbody>
                         ) : (
                              <>
                                   <thead>
                                        <tr className='rule-flow'>
                                             <td colSpan='1'>If these conditions are met</td>
                                             <td colSpan='1'>Fire these triggers</td>
                                        </tr>
                                        <tr className='table-header'>
                                             <td className='user-source-header-item'>
                                                  <span>Event</span>
                                             </td>
                                             <td className='user-source-header-item'>
                                                  <span>Trigger</span>
                                             </td>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        {tableRows.map((row, rowIndex) => {
                                             return (
                                                  <tr className='table-body' key={rowIndex}>
                                                       <td className='event-variable-value url-parameter'>
                                                            <span>{row.event}</span>
                                                       </td>
                                                       <td className='custom-variable-value user-source'>
                                                            <span>{row.triggerName}</span>
                                                       </td>
                                                  </tr>
                                             );
                                        })}
                                   </tbody>
                              </>
                         )}

                    </table>
                    <table className="table lookup-table lookup-table-sticky">
                         <thead>
                              <tr className='rule-flow'>
                                   <td colSpan='1'>If these conditions are met</td>
                                   <td colSpan='1'>Fire these triggers</td>
                              </tr>
                              <tr className='table-header'>
                                   <td className='user-source-header-item'>
                                        <span>Event</span>
                                   </td>
                                   <td className='user-source-header-item'>
                                        <span>Trigger</span>
                                   </td>
                              </tr>
                         </thead>
                    </table>
               </div>
               <ECommerceExportTable tableRows={tableRows} />
          </div>
     );
};

ECommerceLookupTable.propTypes = {
     stepsData: PropTypes.object,
};
export default ECommerceLookupTable;
