import React, { useContext, useState } from 'react';
import {
     CButton,
     CCard,
     CCardBody,
     CForm,
     CFormGroup,
     CInput,
     CLabel,
     CTextarea,
     CCol,
     CInvalidFeedback
} from '@coreui/react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { API_CLIENT_DESTINATIONS_CUSTOM } from '../../../../../../constants';
import { callTokenApi } from '../../../../../../apiCaller';
import { toastError } from '../../../../../../utils';
import Waiting from '../../../../../general/Loadings/Waiting';
import { HoverInfoIconTooltip } from '../../../../../general/custom-tooltips';
import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';

const validationSchema = (setValidateOnChange) => {
     setValidateOnChange(true);

     return Yup.object().shape({
          name: Yup.string().trim().required('Required.').matches(/^[a-zA-Z0-9 ]+$/g, 'Allow only alphabet, numeric and spacing characters.')
     })
}

const CustomDestination = () => {
     const { account, setCurrentStep, setStepsData, stepsData } = useContext(CreateAccountContext);
     const activeAccountId = account.id
     const reducerAccountDestinations = useSelector((state) => state.subscriber.accountDestinations);
     const [validateOnChange, setValidateOnChange] = useState(false);

     const initialValues = { name: '', description: '' };

     const createDestination = (values, { setSubmitting }) => {
          const body = {
               name: values.name,
               description: values.description,
               accountId: activeAccountId
          };

          callTokenApi(API_CLIENT_DESTINATIONS_CUSTOM, 'POST', body)
               .then(async response => {
                    if (response.status === 200) {
                         const { destination } = response.data;
                         let newAccountDestinations = [...reducerAccountDestinations];

                         newAccountDestinations.push({
                              ...destination,
                              configStatus: true
                         })

                         let destinations = [...stepsData.allSelectedDestinations, destination]
                         setStepsData({ allSelectedDestinations: destinations })
                         setCurrentStep(STEPS.chooseDestination)
                    } else {
                         setSubmitting(false);
                         toastError(response);
                    }
               })
     };


     const onSubmit = (values, actions) => {
          createDestination(values, actions);
     };

     const onBackBtnClicked = () => {
          setCurrentStep(STEPS.chooseDestination)
     }
     return (
          <CreateAccountBody onBackBtnClicked={onBackBtnClicked} mainContentClassName='width-medium'>
               <div className='custom-destination-modal'>
                    <CCard className="name-destination">
                         <CCardBody>
                              <Formik
                                   initialValues={initialValues}
                                   validationSchema={() => validationSchema(setValidateOnChange)}
                                   onSubmit={onSubmit}
                                   validateOnChange={validateOnChange}
                                   validateOnBlur={false}
                              >
                                   {
                                        ({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
                                             <CForm noValidate onSubmit={handleSubmit}>
                                                  <h3>Name Your Destination</h3>
                                                  <p>
                                                       Provide a name and description for your destination so you can manage it easily inside ListenLayer.
                                                  </p>
                                                  <CFormGroup className="text-left">
                                                       <CFormGroup row className="align-items-center mb-4">
                                                            <CCol md="3" className="d-flex align-items-center">
                                                                 <CLabel className="name">Name</CLabel>
                                                                 <div className="tooltip-wrapper">
                                                                      <HoverInfoIconTooltip>
                                                                           <p>We recommend using the name of the destination or something you will easily recognize.</p>
                                                                      </HoverInfoIconTooltip>
                                                                 </div>
                                                            </CCol>
                                                            <CCol md="9">
                                                                 <CInput
                                                                      name="name"
                                                                      invalid={!!errors.name}
                                                                      value={values.name}
                                                                      onChange={handleChange}
                                                                      disabled={isSubmitting}
                                                                 />
                                                                 <CInvalidFeedback>{errors.name}</CInvalidFeedback>
                                                            </CCol>
                                                       </CFormGroup>
                                                       <CFormGroup row className="align-items-center">
                                                            <CLabel className="col-md-3">Description</CLabel>
                                                            <CCol md="9">
                                                                 <CTextarea
                                                                      name="description"
                                                                      invalid={!!errors.description}
                                                                      value={values.description}
                                                                      onChange={handleChange}
                                                                      disabled={isSubmitting}
                                                                 />
                                                                 <CInvalidFeedback>{errors.description}</CInvalidFeedback>
                                                            </CCol>
                                                       </CFormGroup>
                                                  </CFormGroup>
                                                  <CButton type="submit" className="btn-save mb-3" disabled={isSubmitting}>
                                                       <Waiting isLoading={isSubmitting}>SAVE CHANGES</Waiting>
                                                  </CButton>
                                                  <div>You can edit all settings for your destination after it is created.</div>
                                             </CForm>
                                        )
                                   }
                              </Formik>
                         </CCardBody>
                    </CCard>
               </div>
          </CreateAccountBody>
     );
};

export default CustomDestination;
