import React, { useState, useCallback } from 'react'
import {
     CModal,
     CModalBody,
     CInput,
     CForm,
     CFormGroup,
     CButton,
     CInvalidFeedback,
     CLabel
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';

import { getTokenCookie, toastError } from '../../../utils';
import { callApi } from '../../../apiCaller';
import { API_SEND_CODE_UPDATE_EMAIL, API_VERIFY_CODE_UPDATE_EMAIL } from '../../../constants';
import { setUser } from '../../../actions/common';
import { setManagers, setOwner } from '../../../actions/subscriber';

const NewEmail = ({ onCancel, oldEmail, setNewEmail }) => {
     const token = getTokenCookie();
     const [validateOnChange, setValidateOnChange] = useState(false);

     const initialValues = {
          newEmail: ''
     }

     const validationSchema = useCallback(() => {
          if (!validateOnChange) {
               setValidateOnChange(true);
          }

          return (
               Yup.object().shape({
                    newEmail: Yup.string()
                         .required('New email is required')
                         .email('Must be a valid email')
               })
          )
     }, [validateOnChange])

     const onSubmit = ({ newEmail }, { setSubmitting, resetForm, setFieldError }) => {
          if (newEmail.toLowerCase() === oldEmail.toLowerCase()) {
               setSubmitting(false)
               setFieldError('newEmail', 'New and old email must not be the same (case-insensitive)');
               return;
          }

          const data = {
               email: oldEmail,
               newEmail
          }

          callApi(API_SEND_CODE_UPDATE_EMAIL, 'POST', data, token)
               .then(response => {
                    if (response.status === 200) {
                         toast.success(response.data.message);
                         setValidateOnChange(false);
                         resetForm();
                         setNewEmail(newEmail);
                    } else {
                         setSubmitting(false);
                         toastError(response);
                    }
               })
     }

     return (
          <>
               <div className="change-email-description">
                    <p>
                         In order to update your email address, you must verify ownership of both.{" "}
                         We will sent a unique code to your old email address and the new one. {" "}
                         You will be required to enter these codes on the following screen.
                    </p>
               </div>
               <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnChange={validateOnChange}
                    validateOnBlur={false}
               >
                    {
                         ({
                              values,
                              errors,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                         }) => (
                              <CForm onSubmit={handleSubmit} noValidate >
                                   <CFormGroup>
                                        <CLabel htmlFor="newEmail">New Email Address:</CLabel>
                                        <CInput
                                             id="newEmail"
                                             placeholder="email"
                                             autoComplete="newEmail"
                                             invalid={!!errors.newEmail}
                                             disabled={isSubmitting}
                                             value={values.newEmail}
                                             onChange={handleChange}
                                        />
                                        <CInvalidFeedback>{errors.newEmail}</CInvalidFeedback>
                                   </CFormGroup>
                                   <div className="form-actions">
                                        <CButton onClick={onCancel} className="btn-light">CANCEL CHANGES</CButton>
                                        <CButton type="submit" disabled={isSubmitting} className="btn-primary">
                                             {isSubmitting ? (<>Waiting<span className="dots-waiting"></span></>) : "Send Codes"}
                                        </CButton>
                                   </div>
                              </CForm>
                         )
                    }
               </Formik>
          </>
     )
}

const EmailCodes = ({ onCancel, oldEmail, newEmail, setNewEmail }) => {
     const token = getTokenCookie();
     const dispatch = useDispatch();
     const [validateOnChange, setValidateOnChange] = useState(false);
     const user = useSelector(state => state.theme.user);
     const owner = useSelector(state => state.subscriber.owner);
     const managers = useSelector(state => state.subscriber.managers);

     const initialValues = {
          oldEmailCode: ''
     }

     const onClose = () => {
          setNewEmail('');
          onCancel();
     }

     const validationSchema = useCallback(() => {
          if (!validateOnChange) {
               setValidateOnChange(true);
          }

          return (
               Yup.object().shape({
                    oldEmailCode: Yup.string()
                         .required('Required')
                    ,
               })
          )
     }, [validateOnChange])

     const onSubmit = ({ oldEmailCode, newEmailCode }, { setErrors, resetForm, setSubmitting }) => {
          const data = {
               email: oldEmail,
               code: oldEmailCode,
               newEmail,
               newEmailCode,
          }

          callApi(API_VERIFY_CODE_UPDATE_EMAIL, 'POST', data, token)
               .then(response => {
                    if (response.status === 200) {
                         resetForm();
                         toast.success('Successfully change email');

                         // Update owner or a manager's email if owner or managers have value
                         if (owner) {
                              if (owner.email === oldEmail) {
                                   let newOwner = { ...owner, email: newEmail };
                                   dispatch(setOwner(newOwner));
                              } else {
                                   let newManagers = [...managers];
                                   let newManagerIndex = findIndex(newManagers, { email: oldEmail });

                                   if (newManagerIndex > -1) {
                                        newManagers[newManagerIndex].email = newEmail;
                                        dispatch(setManagers(newManagers));
                                   }
                              }
                         }

                         let newUser = { ...user, email: newEmail, googleLogin: false, avatar: '' };
                         dispatch(setUser(newUser));
                         setNewEmail('');
                         onCancel();
                    } else if (response.status === 400) {
                         setSubmitting(false);
                         const { codeErrorCase } = response.data;

                         if (codeErrorCase) {
                              switch (codeErrorCase) {
                                   case 1:
                                        setErrors({ oldEmailCode: 'Incorrect!', newEmailCode: 'Incorrect!' });
                                        break;
                                   case 2:
                                        setErrors({ oldEmailCode: 'Incorrect!' });
                                        break;
                                   case 3:
                                        setErrors({ newEmailCode: 'Incorrect!' });
                                        break;
                                   default:
                              }
                         } else {
                              toastError(response);
                         }
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setValidateOnChange(false);
               })
     }

     return (
          <>
               <div className="change-email-description">
                    <p>Enter the codes you received at each email address.</p>
               </div>

               <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnChange={validateOnChange}
                    validateOnBlur={false}
               >
                    {
                         ({
                              values,
                              errors,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                         }) => (
                              <CForm onSubmit={handleSubmit} noValidate >
                                   <CFormGroup>
                                        <CLabel htmlFor="oldEmailCode">{oldEmail} Code</CLabel>
                                        <CInput
                                             id="oldEmailCode"
                                             invalid={!!errors.oldEmailCode}
                                             disabled={isSubmitting}
                                             value={values.oldEmailCode}
                                             onChange={handleChange}
                                             maxLength={4}
                                        />
                                        <CInvalidFeedback>{errors.oldEmailCode}</CInvalidFeedback>
                                   </CFormGroup>
                                   <CFormGroup>
                                        <CLabel htmlFor="newEmailCode">{newEmail} Code</CLabel>
                                        <CInput
                                             id="newEmailCode"
                                             invalid={!!errors.newEmailCode}
                                             disabled={isSubmitting}
                                             value={values.newEmailCode}
                                             onChange={handleChange}
                                             maxLength={4}
                                        />
                                        <CInvalidFeedback>{errors.newEmailCode}</CInvalidFeedback>
                                   </CFormGroup>
                                   <div className="form-actions">
                                        <CButton onClick={onClose} className="btn-light">Cancel Change</CButton>
                                        <CButton type="submit" disabled={isSubmitting} className="btn-primary">
                                             {isSubmitting ? (<>Waiting<span className="dots-waiting"></span></>) : "Verify Ownership"}
                                        </CButton>
                                   </div>
                              </CForm>
                         )
                    }
               </Formik>
          </>
     )
}

const ChangeEmailPopup = ({ show, onCancel, oldEmail }) => {
     const [newEmail, setNewEmail] = useState('');

     return (
          <div className="change-email-popup">
               <CModal
                    show={show}
                    onClose={onCancel}
                    centered
               >
                    <CModalBody>
                         <CIcon name="cil-x" onClick={onCancel} className="icon-close-popup"></CIcon>
                         <div className="change-email-title">
                              <h2>Change Your Email Address</h2>
                         </div>
                         {
                              newEmail ? (
                                   <EmailCodes
                                        onCancel={onCancel}
                                        oldEmail={oldEmail}
                                        newEmail={newEmail}
                                        setNewEmail={setNewEmail}
                                   />
                              ) : (
                                   <NewEmail
                                        onCancel={onCancel}
                                        oldEmail={oldEmail}
                                        setNewEmail={setNewEmail}
                                   />
                              )
                         }
                    </CModalBody>
               </CModal>
          </div>
     )
}

ChangeEmailPopup.propTypes = {
     show: PropTypes.bool,
     onCancel: PropTypes.func,
     oldEmail: PropTypes.string
}

export default ChangeEmailPopup
