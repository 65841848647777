// ** React Imports
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

// ** CoreUI Imports
import { CButton, CInput, CLink, CModal, CModalBody } from '@coreui/react';
import CIcon from '@coreui/icons-react';

// ** Lodash Imports
import truncate from 'lodash/truncate';
import { useSelector } from 'react-redux';

const Confirm = (props) => {
     const { link, show, data, isSystem, onCancel } = props;

     const activeAccount = useSelector((state) => state.subscriber.activeAccount);

     const [copied, setCopied] = useState(false);
     const hostName = isSystem ? window.location.origin + `/${activeAccount.secondId}` : window.location.origin;

     return (
          <div className="confirm-save-popup popup-publish-version">
               <CModal show={show} onClose={onCancel} color="primary" centered>
                    <CModalBody className='confirm-save modal-padding-37'>
                         <CIcon name="cil-x" onClick={onCancel} className="icon-close-popup"></CIcon>

                         <div className="popup-publish-version-description">
                              <h1>{data.confirmTitle}</h1>
                              <p>{data.confirmDescription}</p>
                         </div>

                         <div className="position-relative">
                              <CInput value={truncate(hostName + link, { length: 50 })} readOnly className="bg-white" />

                              <CopyToClipboard
                                   text={hostName + link}
                                   onCopy={() => {
                                        setCopied(true);
                                        setTimeout(() => {
                                             setCopied(false);
                                        }, 1500);
                                   }}
                              >
                                   <div
                                        className="position-absolute pointer"
                                        style={{
                                             top: '50%',
                                             right: '20px',
                                             transform: 'translateY(-50%)',
                                        }}
                                   >
                                        <CIcon name="cilLinkAlt" />
                                        <CLink className="text-dark ml-2">{copied ? 'Copied' : 'Copy'}</CLink>
                                   </div>
                              </CopyToClipboard>
                         </div>

                         <div className="form-actions mt-4 d-flex justify-content-center">
                              <CButton className="btn btn-light" onClick={onCancel}>
                                   Close
                              </CButton>
                         </div>
                    </CModalBody>
               </CModal>
          </div>
     );
};

export default Confirm;
