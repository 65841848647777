import React, { useContext } from 'react';
import {
	CRow,
	CCol,
	CCard,
	CForm,
	CInput,
	CButton,
	CInvalidFeedback,
} from '@coreui/react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import CIcon from '@coreui/icons-react';
import {
	STEPS,
	AccountDormantBody,
	AccountDormantContext,
} from './AccountDormant';

const validationSchema = (name) => {
	return Yup.object().shape({
		name: Yup.string().trim()
			.required('The name is required')
			.oneOf([name], 'The name is not correct')
		,
	})
}

const CancelAccount = () => {
	const {
		dormantAccount,
		setCurrentStep,
		handleCancelDormantAccount
	} = useContext(AccountDormantContext);
	const initialValues = { name: '' };

	return (
		<AccountDormantBody onBackBtnClicked={() => setCurrentStep(STEPS.accountDormantNotice)}>
			<CCard className="cancel-account-dormant">
				<div className="confirm-save-icon"><CIcon name="icon-delete" className="text-danger" height="38" alt="CoreUI Icons Delete" /></div>
				<h2>Cancel Account</h2>
				<div className="confirm-save-content">
					<p>You can completely cancel this account, but it will delete all settings and data permanently - <strong>we cannot restore it!</strong> This will not delete your user account or login, it will only delete this specific website account: <strong>{dormantAccount.name}</strong>.</p>
					<p>To complete this process, type the name of the account in the field below and select DELETE FOREVER</p>
				</div>
				<Formik
					initialValues={initialValues}
					validationSchema={() => validationSchema(dormantAccount.name)}
					onSubmit={handleCancelDormantAccount}
					validateOnChange={false}
					validateOnBlur={false}
					enableReinitialize
				>
					{({
						errors,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						setFieldError,
					}) => {
						const handleChangeAccountName = (e) => {
							setFieldValue('name', e.target.value)
							setFieldError('name', undefined)
						}
						return (
							<CForm onSubmit={handleSubmit} noValidate>
								<CRow>
									<CCol className="input-edit-account-name">
										<CInput
											size="16"
											placeholder="Enter account name..."
											name="name"
											className="mb-3"
											invalid={!!errors.name}
											required
											disabled={isSubmitting}
											onChange={handleChangeAccountName}
											data-lpignore="true"
										/>
										<CInvalidFeedback>{errors.name}</CInvalidFeedback>
									</CCol>
								</CRow>
								<div className="button-group position-relative d-inline-block">
									<CButton onClick={() => setCurrentStep(STEPS.accountDormantNotice)} className="btn-light">Cancel Changes</CButton>
									<CButton className="btn-danger" disabled={isSubmitting} type="submit">
										{isSubmitting ? (<span className="dots-waiting">Waiting</span>) : "Delete Forever"}
									</CButton>
								</div>
							</CForm>
						)
					}}
				</Formik>
			</CCard>
		</AccountDormantBody>
	);
};

export default CancelAccount;
