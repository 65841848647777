import { CButton } from '@coreui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { callTokenApi } from '../../../../apiCaller';
import {
     API_CLIENT_EXPORT_REPORTING_EXPLORER,
     CHECK_LIST_OPERATOR_OPTION,
     DEFAULT_DATE_FORMAT,
     EXPORT_TYPE,
     REPORT_DATA_TYPES,
     REPORT_NAMES,
} from '../../../../constants';
import { exportToCSV2, exportVisibleReport } from '../../../../utils';
import { getCustomPropertiesReport, handleChangeColSort } from '../../../../utils/UtilReport';
import { EventExplorerContext } from './EventExplorer';

const ExportReport = () => {
     const {
          filterMetric,
          filterDimensions,
          filterProperties,
          reportName,
          sort,
          filterEvent,
          itemsPerPage,
          offSet,
          scoped,
          reportType,
          conversionNameMetric,
          conversionName,
          showData,
          enableFullAnalytics,
          dateRangeConfig,
          customProperties,
          newProperties: propertiesAll,
          recordProfile,
          isLoading,
          // metricDefault,
          // convRate,
          // convName,
          // eCommerce,
          maxStepForm,
          eCoItemCategories,
          weight,
          attributeBeforeReload,
          metricsStepForm,
          reportData,
          tempReport,
          reportCompare,
          utmArray,
     } = useContext(EventExplorerContext);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const customDimensions = useSelector((state) => state.subscriber.customReport.dimensions);
     let { dimensions, metrics } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     if (reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT) {
          dimensions = [...dimensions, ...utmArray];
     }
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const user = useSelector((state) => state.theme.user);
     const [disableExport, setDisableExport] = useState(false);
     const [dimensionEvents, setDimensionEvents] = useState([]);
     const [isWebConversion, setIsWebConversion] = useState(false);

     if (reportName === REPORT_NAMES.ECOMMERCE_ITEMS) {
          dimensions = [...dimensions, ...eCoItemCategories];
     }

     useEffect(() => {
          if (!customDimensions) return;
          const listCustomPropertyEvent = customDimensions.filter((item) =>
               item.availableScopes.some((scope) => scope.isWebConversion && filterProperties.some((option) => option.label === item.name))
          );
          setDimensionEvents(listCustomPropertyEvent);
          if (listCustomPropertyEvent && listCustomPropertyEvent.length > 0) {
               setIsWebConversion(true);
          } else {
               setIsWebConversion(false);
          }
     }, [customDimensions, filterProperties]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          setDisableExport(isLoading);
     }, [isLoading]);
     const handleOrderCol = (arr, arrFilter) => {
          const newArr = [];
          arr.forEach((item) => {
               if (arrFilter.some((el) => el.value === item.key)) {
                    newArr.push(item.key);
               }
          });
          return newArr;
     };

     // const handleOrderColMetric = (arr, arrFilter) => {
     //      const newArr = [];
     //      arr.forEach((item) => {
     //           if (arrFilter.some((el) => el.value === item.key)) {
     //                newArr.push(item.key);
     //           }
     //      });
     //      return newArr;
     // };

     // const handleColMetricStepForm = (arrDefault) => {
     //      const newArr = [];
     //      arrDefault.forEach((item) => {
     //           if (item.value.includes('step')) {
     //                newArr.push(item.value);
     //           }
     //      });
     //      return newArr;
     // };

     // const handleOrderColV2 = (arr, arrFilter) => {
     //      const newArr = [];
     //      arr.forEach((item) => {
     //           if (arrFilter.some((el) => el.value === item.key)) {
     //                newArr.push(item.key);
     //           }
     //      });
     //      return newArr;
     // };
     const handleExportCSV = (typeExport) => {
          if (!enableFullAnalytics && !showData) {
               return;
          }

          const newDateRange = {
               selection: {
                    startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
                    endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
               },
          };

          if (dateRangeReports.compare) {
               newDateRange.compare = {
                    startDate: dateRangeReports.compare.startDate.format(DEFAULT_DATE_FORMAT),
                    endDate: dateRangeReports.compare.endDate.format(DEFAULT_DATE_FORMAT),
               };
          }
          if (dateRangeConfig.dateScope) {
               newDateRange.dateScope = dateRangeConfig.dateScope;
          }
          // const newDimensions = handleOrderCol(dimensions, filterDimensions);
          // const newMetric = handleChangeColSort([...conversionNameMetric, ...metrics], filterMetric).map((i) => i.key);
          // const newProperties = handleOrderCol(propertiesAll, filterProperties);
          const newProperties = attributeBeforeReload.properties;
          const newMetric = attributeBeforeReload.metrics;
          // const newMetricsStepForm = handleColMetricStepForm(metricDefault);
          // const headerColumn = [...filterDimensions, ...filterProperties, ...metricDefault, ...convRate, ...convName, ...eCommerce];
          const headerColumn = [...filterDimensions, ...filterProperties, ...filterMetric];
          const newFilter = filterEvent.map((item) => {
               const newItem = { ...item };

               if (item.operator === CHECK_LIST_OPERATOR_OPTION.value) {
                    newItem.value = item.value && Array.isArray(item.value) ? [...item.value].map((el) => el.value) : [];
               }
               delete newItem['optionsOperator'];
               return newItem;
          });
          const filterFields = {
               metrics: handleChangeColSort([...conversionNameMetric, ...metrics, ...metricsStepForm], filterMetric).map((i) => i.key),
               properties: handleOrderCol(propertiesAll, filterProperties),
               // dimensions: handleOrderCol(dimensions, filterDimensions),
               dimensions: handleOrderCol(dimensions, filterDimensions),
          };

          let bodyData = {
               reportName,
               accountId: activeAccount.id,
               dateRanges: newDateRange,
               metrics: handleChangeColSort([...conversionNameMetric, ...metrics, ...metricsStepForm], filterMetric).map((i) => i.key),
               properties: handleOrderCol(propertiesAll, filterProperties),
               dimensions: handleChangeColSort([...dimensions], filterDimensions).map((i) => i.key),
               filter: newFilter,
               sort: sort,
               headerColumn: headerColumn,
               user: user,
               pathName: window.location.pathname,
               scoped: scoped,
               reportType: reportType,
               // conversionName: conversionName.filter((item) => item.label.includes('Conv')),
               // eCommerceName: conversionName.filter((item) => item.label.includes('Ecommerce')),
               conversionName: conversionName.filter(
                    (conversion) => newMetric.find((metric) => metric.includes(conversion.value)) && conversion.label.includes('Conv')
               ),
               eCommerceName: conversionName.filter((conversion) => newMetric.includes(conversion.value) && conversion.label.includes('Ecommerce')),
               customProperties: getCustomPropertiesReport(customProperties, newProperties),
               isCompare: dateRangeConfig.comparePeriod,
               recordProfile,
               limit: itemsPerPage,
               offset: offSet,
               weight,
               temp: tempReport.temp,
               tempCompare: tempReport.tempCompare,
          };
          if (newDateRange.compare && (!reportType || reportName === REPORT_NAMES.ECOMMERCE_PURCHASE_TRANSACTION)) {
               delete newDateRange.compare;
               bodyData.isCompare = false;
          }
          if (reportName === 'formCategoryReport') {
               bodyData.maxStepForm = maxStepForm;
          }

          if (reportName === 'conversionEvents') {
               bodyData.isWebConversion = isWebConversion;
               bodyData.dimensionEvent = dimensionEvents;
          }

          setDisableExport(true);

          if (typeExport === EXPORT_TYPE.EXPORT_ALL_PAGE) {
               callTokenApi(API_CLIENT_EXPORT_REPORTING_EXPLORER, 'POST', bodyData);
               setTimeout(() => {
                    toast.success('Your file has been emailed, it may take a few minutes to send, please check your email');
                    setDisableExport(false);
               }, 3000);
          } else {
               const tempFileName = `${reportName}.csv`;
               const csvString = exportVisibleReport({
                    data: reportData,
                    filterFields,
                    newProperties,
                    newMetric,
                    headerColumn,
                    reportName,
                    reportCompare,
                    isCompare: bodyData.isCompare,
                    reportType,
               });
               if (csvString) {
                    exportToCSV2(csvString, tempFileName);
               }
               setDisableExport(false);
          }
     };

     return (
          <div className="export-report filter-report d-flex justify-content-end align-items-center">
               <CButton className="filter-button mr-2" disabled={disableExport} onClick={() => handleExportCSV(EXPORT_TYPE.EXPORT_ALL_PAGE)}>
                    {' '}
                    Export the entire data set{' '}
               </CButton>
               <CButton className="filter-button" disabled={disableExport} onClick={() => handleExportCSV(EXPORT_TYPE.EXPORT_A_PAGE)}>
                    {' '}
                    Export Visible Page{' '}
               </CButton>
          </div>
     );
};

export default ExportReport;
