import React from "react";
import {
    CModal,
    CModalBody,
    CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import PropTypes from 'prop-types';
import classNames from "classnames";

const RemovePopup = ({ show, onClose, onAccept, loading, children }) => {
    const finalOnClose = loading ? null : onClose;

    return (
        <div className="remove-popup">
            <CModal show={show} centered={true} onClose={finalOnClose} closeOnBackdrop={!loading}>
                <CModalBody className="text-center">
                    <CIcon
                        name="cil-x"
                        onClick={finalOnClose}
                        className={classNames('icon-close-popup', { disabled: loading })}
                    ></CIcon>
                    <div className="remove-popup-icon"><CIcon name="icon-delete" className="text-danger" height="38" alt="CoreUI Icons Delete" /></div>
                    <h2>Are you sure?</h2>
                    <div className="remove-popup-description">{children}</div>
                    <div className="btn-accept mb-3 position-relative">
                        <CButton color="danger" className="r-3 text-uppercase d-inline-block" onClick={onAccept} disabled={loading}>
                            {loading ? (<>Waiting<span className="dots-waiting"></span></>) : "PROCEED TO DELETE"}
                        </CButton>
                    </div>
                    <div className="mb-3">
                        <CButton
                            className="d-inline-block btn-cancel"
                            onClick={finalOnClose}
                            disabled={loading}
                        >
                            Cancel
                        </CButton>
                    </div>
                </CModalBody>
            </CModal>
        </div>
    );
};

RemovePopup.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onAccept: PropTypes.func,
    loading: PropTypes.bool,
}

export default RemovePopup;
