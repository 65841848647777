// ** React Imports
import React, { useRef, useEffect } from 'react';

// ** CoreUI Imports
import { CButton, CModal, CModalBody, CForm, CInput, CInputRadio, CInvalidFeedback, CFormGroup, CLabel, CTextarea } from '@coreui/react';
import CIcon from '@coreui/icons-react';

// ** Validations
import { Formik } from 'formik';
import * as Yup from 'yup';

// ** Constants Imports
import { VIEW_TYPE } from '../../../../../../constants/reportView';
import { useSelector } from 'react-redux';

const validationSchema = () => {
     return Yup.object().shape({
          name: Yup.string()
               .min(2, `Name has to be at least 2 characters`)
               .max(50, `Name contains at most 50 characters`)
               .required('Name is required'),
     });
};

const CreateNewView = ({ show, data, type, onAccept, onCancel }) => {
     const user = useSelector((state) => state.theme.user);
     const initialValues = {
          name: data ? data.name : '',
          description: data ? data.description : '',
          isAvailable: data ? data.isAvailable : false,
     };

     const nameInputRef = useRef();

     useEffect(() => {
          if (show) {
               setTimeout(() => {
                    nameInputRef.current.focus();
               }, 0);
          }
     }, [show]);

     const isSetPrivate =!data || data.userId === user.id;
     return (
          <div className="confirm-save-popup select-domain popup-create-variable savedview">
               <CModal show={show} onClose={onCancel} color="primary" centered>
                    <CModalBody style={{ padding: '38px 0 30px' }} className='text-center confirm-save'>
                         <CIcon name="cil-x" onClick={onCancel} className="icon-close-popup"></CIcon>

                         <div className="popup-publish-version-description">
                              <h1>{type === VIEW_TYPE.REPORT ? 'Create new Saved Report' : 'Create new Saved View'}</h1>
                              <p>Now give your {type === VIEW_TYPE.REPORT? 'report' : 'view'} a name and description.</p>
                         </div>

                         <Formik
                              initialValues={initialValues}
                              validationSchema={validationSchema}
                              onSubmit={onAccept}
                              onClose={onCancel}
                              validateOnChange={false}
                              validateOnBlur={false}
                              enableReinitialize
                         >
                              {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue, setFieldError }) => {
                                   const handleChangeName = (e) => {
                                        setFieldValue('name', e.target.value);
                                        setFieldError('name', undefined);
                                   };

                                   return (
                                        <CForm onSubmit={handleSubmit} noValidate>
                                             <CFormGroup>
                                                  <CInput
                                                       innerRef={nameInputRef}
                                                       id="name"
                                                       placeholder="Enter a name"
                                                       invalid={!!errors.name}
                                                       required
                                                       disabled={isSubmitting}
                                                       value={values.name}
                                                       onChange={handleChangeName}
                                                  />

                                                  <CInvalidFeedback>{errors.name}</CInvalidFeedback>
                                             </CFormGroup>

                                             <CFormGroup>
                                                  <CTextarea
                                                       id="description"
                                                       placeholder="Provide an optional description."
                                                       disabled={isSubmitting}
                                                       value={values.description}
                                                       onChange={handleChange}
                                                  />
                                             </CFormGroup>

                                             <CFormGroup>
                                                  <CLabel className="mr-5 d-block">
                                                       Who sees this {type === VIEW_TYPE.REPORT ? 'report' : 'view'}?
                                                  </CLabel>
                                                  {isSetPrivate &&
                                                       <CFormGroup variant="custom-radio" className="d-block" inline>
                                                            <CInputRadio
                                                                 custom
                                                                 id="isAvailable1"
                                                                 name="isAvailable"
                                                                 disabled={isSubmitting}
                                                                 value={!values.isAvailable}
                                                                 checked={!values.isAvailable}
                                                                 onChange={(e) => setFieldValue('isAvailable', false)}
                                                            />

                                                            <CLabel variant="custom-checkbox" htmlFor="isAvailable1">
                                                                 Only I can see this {type === VIEW_TYPE.REPORT ? 'report' : 'view'}.
                                                            </CLabel>
                                                       </CFormGroup>
                                                  }
                                                  

                                                  <CFormGroup variant="custom-radio" className="d-block" inline>
                                                       <CInputRadio
                                                            custom
                                                            id="isAvailable2"
                                                            name="isAvailable"
                                                            disabled={isSubmitting}
                                                            value={values.isAvailable}
                                                            checked={!!values.isAvailable}
                                                            onChange={(e) => setFieldValue('isAvailable', true)}
                                                       />

                                                       <CLabel variant="custom-checkbox" htmlFor="isAvailable2">
                                                            All users can see this {type === VIEW_TYPE.REPORT ? 'report' : 'view'}.
                                                       </CLabel>
                                                  </CFormGroup>
                                             </CFormGroup>

                                             <div className="form-actions text-center mt-4">
                                                  <CButton type="submit" className="btn-primary" disabled={isSubmitting}>
                                                       {isSubmitting ? (
                                                            <>
                                                                 Waiting<span className="dots-waiting"></span>
                                                            </>
                                                       ) : (
                                                            'Save'
                                                       )}
                                                  </CButton>

                                                  <CButton className="btn btn-cancel rounded-pill cancel" onClick={onCancel}>
                                                       Cancel
                                                  </CButton>
                                             </div>
                                        </CForm>
                                   );
                              }}
                         </Formik>
                    </CModalBody>
               </CModal>
          </div>
     );
};

export default CreateNewView;
