import React from 'react';
import {
    CModal,
    CModalBody,
} from '@coreui/react';
import PropTypes from 'prop-types';

const UpdatePaymentPopup = ({ show, onClose, children }) => {
    return (
        <div className="popup-update-payment">
            <CModal
                show={show}
                onClose={onClose}
                centered
            >
                <CModalBody>
                    {children}
                </CModalBody>
            </CModal>
        </div>
    )
}
 
UpdatePaymentPopup.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func
}

export default UpdatePaymentPopup
