import CIcon from '@coreui/icons-react';
import { CButton, CCol, CForm, CRow, CLabel } from '@coreui/react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setFlexibleModal, setRuleHaveEditting } from '../../../../../../../actions/common';
import {
     setNewLayout,
     setShowBlockAccountPopup,
     setSubscriberState,
     setUnsavedLookupTableModal,
     setUsedItems,
} from '../../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../../apiCaller';
import {
     API_CLIENT_RULE,
     API_CLIENT_RULE_REVERT,
     API_CLIENT_RULE_UPDATE,
     COMPONENT_NAME,
     OPERATORS_REQUIRE_VALUE,
     REGEX_PATTERNS,
     TYPE_SHOW_UNSAVE_CHANGE,
} from '../../../../../../../constants';
import { subscriberNavKey } from '../../../../../../../containers/_nav/subscriber';
import { deepCopyArray, toastError, useOutsideHandling } from '../../../../../../../utils';
import CenterSpinner from '../../../../../../general/Loadings/CenterSpinner';
import { ConfirmRemovePopup, ConfirmSaveChange, LeaveComponentChangePopup } from '../../../../../../general/popup';
import RemovePopup from '../../../../../../general/popup/RemovePopup';
import { FormRuleTableContext } from './EditFormRuleTable';
import TableHeaderRow from './TableHeaderRow';
import TableBodyRows from './TableBodyRows';
import ImportExportTable from './ImportExportTable';
import { useEventAndVariableAccountOptions } from '../../../../../../../helpers/customHooks';

export const LookupTableContext = React.createContext({});

const FormRuleTable = ({ stepsData, setStepsData, initialStepsData }) => {
     const { fetchRule } = useContext(FormRuleTableContext);
     const history = useHistory();
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const flexibleModal = useSelector((state) => state.theme.flexibleModal);
     const formCategoriesRules = useSelector((state) => state.subscriber.formCategoriesRules);
     const [saveLoading, setSaveLoading] = useState(false);
     const [deleteRuleModal, setDeleteRuleModal] = useState(false);
     const [saveTableModal, setSaveTableModal] = useState(false);
     const [revertModal, setRevertModal] = useState(false);
     const [leaveComponentModal, setLeaveComponentModal] = useState(false);
     const [navigateTo, setNavigateTo] = useState();
     const [btnSaveStatus, setBtnSaveStatus] = useState(isEqual(stepsData, initialStepsData));
     const [removeRowModal, setRemoveRowModal] = useState({
          show: false,
          index: -1,
     });
     const [deleteLoading, setDeleteLoading] = useState(false);
     const [tableRow, setTableRow] = useState();

     const { conditions, customVariables } = stepsData;

     const listCustomCategories = useSelector((state) => state.subscriber.customCategories);
     const [showCheatSheet, setshowCheatSheet] = useState(false);
     const unsavedLookupTableModal = useSelector((state) => state.subscriber.unsavedLookupTableModal);
     const [btnAbandon, setBtnAbandon] = useState(false);
     const { fetchLoading } = useEventAndVariableAccountOptions(true);

     const handleClickEditButton = (id) => {
          dispatch(
               setFlexibleModal({
                    show: true,
                    showLv2: true,
                    ruleId: id,
                    ruleIdLv2: id,
                    component: COMPONENT_NAME.FORM_RULE_TABLE,
                    componentLv2: COMPONENT_NAME.FORM_RULE_SETTING,
               })
          );
          dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
     };

     const getNonTierVariables = useCallback((variables) => {
          let variableData = [];
          if (variables && variables.length > 0) {
               variables.forEach((variable) => {
                    if (variable.status && variable.childs) {
                         variableData.push(...getNonTierVariables(variable.childs));
                    } else {
                         variableData.push(variable);
                    }
               });
          }
          return variableData;
     }, []);

     const { id: activeAccountId, numberOfCusVariablesRules } = activeAccount;

     const defaultRow = () => {
          const initialConditions = conditions[0].map((item) => {
               let newItem = {
                    conjunction: item.conjunction,
                    g0: {
                         type: item.g0.type,
                         id: item.g0.id,
                         key: item.g0.key,
                         operator: item.g0.operator,
                         value: '',
                         isRegex: false,
                    },
               };
               return newItem;
          });
          return { conditions: [...initialConditions], customVariables: [{ id: '' }] };
     };

     const getInitialTableRow = () => {
          let table = [];
          let conditionData = conditions.map((item) => item);
          let customVariableData = customVariables.map((item) => item);

          for (let i = 0; i < conditionData.length; i++) {
               table.push({ conditions: conditionData[i], customVariables: customVariableData[i] });
          }

          const newTable = table.sort((conditionFirst, conditionSecond) => {
               if (conditionFirst.conditions[0].g0.value === '(.*?)') return 1;

               if (conditionFirst.conditions[0].g0.value < conditionSecond.conditions[0].g0.value) return -1;
               if (conditionFirst.conditions[0].g0.value > conditionSecond.conditions[0].g0.value) return 1;

               if (conditionFirst.conditions[1]) {
                    if (conditionFirst.conditions[1].g0.value.length < conditionSecond.conditions[1].g0.value.length) return 1;
                    if (conditionFirst.conditions[1].g0.value.length > conditionSecond.conditions[1].g0.value.length) return -1;
               }

               return 0;
          });

          setTableRow(newTable);
     };

     const fetchData = () => {
          getInitialTableRow();
     };

     useEffect(fetchData, []);

     const beforeunload = function (e) {
          e.preventDefault();
          e.returnValue = '';
     };

     useEffect(() => {
          dispatch(setUnsavedLookupTableModal({ unsaved: false }));

          let unblock = history.block((location) => {
               let url = location.pathname;
               if (!btnSaveStatus) {
                    setBtnSaveStatus(true);
                    setNavigateTo(url);
                    setLeaveComponentModal(true);
                    return false;
               }
               return true;
          });

          if (!btnSaveStatus) {
               document.querySelector('.flexible-modal-body').addEventListener('beforeunload', beforeunload);
          }

          return () => {
               document.querySelector('.flexible-modal-body').removeEventListener('beforeunload', beforeunload);
               unblock();
          };
     }, [btnSaveStatus, history, dispatch]);

     const handleAcceptLeaveComponent = () => {
          if (unsavedLookupTableModal.show) {
               const conditions = JSON.parse(JSON.stringify(initialStepsData.conditions));
               const customVariables = JSON.parse(JSON.stringify(initialStepsData.customVariables));

               let table = [];
               let conditionData = conditions.map((item) => item);
               let customVariableData = customVariables.map((item) => item);
               for (let i = 0; i < conditionData.length; i++) {
                    table.push({ conditions: conditionData[i], customVariables: customVariableData[i] });
               }
               setTableRow(table);
               setBtnSaveStatus(true);
               unsavedLookupTableModal.onAccept();
               dispatch(setUnsavedLookupTableModal({ show: false, onAccept: null }));
          } else {
               setBtnAbandon(true);
               window.location.href = navigateTo;
          }
     };

     const handleCloseLeaveComponent = () => {
          setLeaveComponentModal(false);
          setBtnSaveStatus(false);
          dispatch(setUnsavedLookupTableModal({ show: false }));
          dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS }));
     };

     const handleAddRow = (rows = 1) => {
          let newTableRow = [...tableRow];
          for (let i = 0; i < rows; i++) {
               let newRow = defaultRow();
               let conjunctions = tableRow[0].conditions.map((item) => item.conjunction);

               newRow.conditions.forEach((item, index) => {
                    item.conjunction = conjunctions[index];
               });
               newTableRow.push(newRow);
          }
          setTableRow(newTableRow);
          resetBtnSaveStatus();
     };

     const handleSelectOperator = (evt, colIndex) => {
          let { value, name } = evt.target;
          let fields = [...conditions];
          let table = deepCopyArray(tableRow);

          if (name === 'operator') {
               fields[0][colIndex].g0.operator = value;
               table.forEach((row) => {
                    row.conditions[colIndex].g0.operator = value;
               });
          } else {
               fields[0][colIndex].conjunction = value;
               table.forEach((row) => {
                    row.conditions[colIndex].conjunction = value;
               });
          }
          setTableRow(table);
          setStepsData({ ...stepsData, conditions: fields });
          resetBtnSaveStatus();
     };

     const confirmRemoveRow = () => {
          let data = [...tableRow];
          let { index } = removeRowModal;
          if (data.length > 1) data.splice(index, 1);
          else data = [defaultRow()];
          setTableRow(data);
          setRemoveRowModal({ show: false, index: -1 });
          resetBtnSaveStatus();
     };

     const handleDelete = () => {
          setDeleteLoading(true);
          callTokenApi(`${API_CLIENT_RULE}${stepsData.id}`, 'DELETE').then((response) => {
               if (response.status === 200) {
                    // toast.success('Rule is deleted!');
                    const usedItems = {
                         itemName: 'numberOfCusVariablesRules',
                         quantity: numberOfCusVariablesRules - 1,
                    };

                    dispatch(setUsedItems(usedItems));
                    setBtnSaveStatus(true);

                    if (formCategoriesRules) {
                         dispatch(
                              setSubscriberState({
                                   formCategoriesRules: formCategoriesRules.filter((rule) => rule.id !== stepsData.id),
                              })
                         );
                    }

                    dispatch(setRuleHaveEditting({ show: false, type: '', showLv2: false, typeLv2: '' }));
                    dispatch(
                         setFlexibleModal({
                              show: false,
                              showLv2: false,
                              ruleId: null,
                              ruleIdLv2: null,
                              component: '',
                              componentLv2: '',
                         })
                    );
               } else {
                    if (response.data.accountBlocked) {
                         dispatch(setShowBlockAccountPopup(true));
                    } else {
                         toastError(response);
                    }
               }
          });
     };

     const onSubmit = (e) => {
          e.preventDefault();
          let error = validateSubmited();
          if (error.key !== '') {
               if (error.type === 'Event') {
                    toast.error(`${error.key} is required.`);
               } else {
                    toast.error(`${error.key} cannot be empty.`);
               }
          } else {
               setSaveTableModal(true);
          }
     };

     const validateSubmited = () => {
          let data = tableRow;
          let errorColumn = { type: '', key: '' };
          data.some((row) => {
               let flag = false;
               flag = row.conditions.some((col) => {
                    const { type, value, key, operator } = col.g0;
                    if (value === '') {
                         if (type === 'Event' || OPERATORS_REQUIRE_VALUE.includes(operator)) {
                              errorColumn = { type, key };
                              return true;
                         }
                    }
                    return false;
               });
               return flag;
          });

          return errorColumn;
     };

     const onAcceptSaveModal = () => {
          let data = { ...stepsData };
          let newConditions = [],
               newCustomVariables = [];

          tableRow.forEach((row) => {
               let newConditionRow = [];
               row.conditions.forEach((condition, index) => {
                    if (index === conditions[0].length - 1) {
                         condition.conjunction = '';
                    }
                    newConditionRow.push(condition);
               });

               //update trim formName of customVariables
               if (row.customVariables && Array.isArray(row.customVariables)) {
                    row.customVariables.forEach((variable) => {
                         if (variable.formName) {
                              variable.formName = variable.formName.trim();
                         }
                    });
               }

               newConditions.push(newConditionRow);
               newCustomVariables.push(row.customVariables);
          });
          data.conditions = newConditions;
          data.customVariables = newCustomVariables;

          let dataTableRow = [...tableRow];
          setTableRow(dataTableRow);

          handleSaveLookupTable({
               accountId: data.accountId,
               listenerId: data.listenerId,
               id: data.id,
               conditions: data.conditions,
               customVariables: data.customVariables,
               type: data.type,
               name: data.name,
               description: data.description,
          });
          setBtnSaveStatus(true);
     };

     const handleSaveLookupTable = (data) => {
          setSaveLoading(true);
          callTokenApi(API_CLIENT_RULE_UPDATE, 'POST', data)
               .finally(() => {
                    setSaveLoading(false);
               })
               .then((response) => {
                    if (response.status !== 200) {
                         if (response.data.accountBlocked) {
                              dispatch(setShowBlockAccountPopup(true));
                         } else {
                              toastError(response);
                         }
                    } else {
                         // const { rule } = response.data;
                         // const ruleData = { ...rule[0], ...data };

                         // if (formCategoriesRules) {
                         //      const variableFilter = formCategoriesRules.filter((rule) => rule.id !== ruleData.id);
                         //      const newRules = [...variableFilter];
                         //      newRules.push(ruleData);
                         //      dispatch(
                         //           setSubscriberState({
                         //                formCategoriesRules: newRules,
                         //           }),
                         //      );
                         // }
                         setSaveTableModal(false);
                         dispatch(setRuleHaveEditting({ show: false, type: '', showLv2: false, typeLv2: '' }));
                         dispatch(
                              setFlexibleModal({
                                   show: false,
                                   showLv2: false,
                              })
                         );
                    }
               });
     };
     const handleReverting = () => {
          let data = {
               accountId: activeAccountId,
               ruleId: stepsData.id,
          };
          setSaveLoading(true);
          callTokenApi(API_CLIENT_RULE_REVERT, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         fetchRule();
                    } else {
                         if (response.data.accountBlocked) {
                              dispatch(setShowBlockAccountPopup(true));
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => {
                    setSaveLoading(false);
                    dispatch(setRuleHaveEditting({ show: false, type: '', showLv2: false, typeLv2: '' }));
               });
     };

     const resetBtnSaveStatus = () => {
          if (btnSaveStatus === true) {
               setBtnSaveStatus(false);
          }
          dispatch(setRuleHaveEditting({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.EDIT_TABLE }));
     };

     const onDragEnd = (result) => {
          if (!result.destination) {
               return;
          }

          if (result.destination.index === result.source.index) {
               return;
          }

          const rows = reorder(tableRow, result.source.index, result.destination.index);
          resetBtnSaveStatus();
          setTableRow(rows);
     };

     const reorder = (list, startIndex, endIndex) => {
          const result = Array.from(list);
          const [removed] = result.splice(startIndex, 1);
          result.splice(endIndex, 0, removed);

          return result;
     };

     const handleChangeRowData = (value, arrayName, rowIndex, colIndex) => {
          setTableRow((prevTableRow) => {
               let data = deepCopyArray(prevTableRow);

               if (arrayName === 'conditions') {
                    data[rowIndex].conditions[colIndex].g0.value = value;
               } else if (arrayName === 'formCategory') {
                    data[rowIndex].customVariables[colIndex].id = value;
               } else {
                    data[rowIndex].customVariables[colIndex].formName = value;
               }

               return data;
          });

          resetBtnSaveStatus();
     };

     const headerRowContext = {
          conditions,
          handleSelectOperator,
     };

     const IsExisted = (id) => {
          return listCustomCategories.find((item) => item.id === id);
     };

     const IsExistedWithName = (val) => {
          return listCustomCategories.find((item) => item.name === val);
     };

     const bodyRowContext = {
          onlyView: stepsData.name === 'Categorize Forms',
          tableRow,
          setTableRow,
          resetBtnSaveStatus,
          setRemoveRowModal,
          handleChangeRowData,
          listCustomCategories,
     };

     const importExportContext = {
          tableRow,
          setTableRow,
          setBtnSaveStatus,
          resetBtnSaveStatus,
          IsExisted,
          IsExistedWithName,
     };

     const handleClickCheatSheat = useCallback(() => {
          setTimeout(() => {
               setshowCheatSheet(!showCheatSheet);
          }, 100);
     }, [showCheatSheet]);

     const handleClickOutside = useCallback(() => {
          setshowCheatSheet(false);
     }, []);

     const wrapperRef = useRef(null);
     useOutsideHandling(wrapperRef, handleClickOutside);
     const observedElementsRef = useRef([]);
     useEffect(() => {
          // Handle lockdown table header Start
          let header = document.querySelectorAll('.flexible-modal-header');
          let body = document.querySelectorAll('.flexible-modal-body');
          let table = document.querySelector('.lookup-table-init');
          let stickyTable = document.querySelector('.lookup-table-sticky');
          if (header.length > 0) {
               header = header[!flexibleModal.showLv2 ? 0 : 1];
          }

          if (body.length > 0) {
               body = body[!flexibleModal.showLv2 ? 0 : 1];
          }

          let headerHeight = header.offsetHeight;

          const handleResize = () => {
               let tableHeaderTop = table ? table.offsetTop : 0;
               let headerBottom = headerHeight + body.scrollTop;
               let tableHeader = table ? table.childNodes[0] : [];
               let tableHeader2ndRow = tableHeader.childNodes[1];
               let tableHeaderColumns;
               if (tableHeader2ndRow && tableHeader2ndRow.childNodes) {
                    tableHeaderColumns = tableHeader2ndRow.childNodes;
               }
               let stickyTableHeader = stickyTable.childNodes[0];
               headerHeight = header.offsetHeight;

               const updateStickyTable = () => {
                    stickyTable.style.width = table.parentNode.offsetWidth + 'px';
                    stickyTable.style.top = headerHeight + 'px';
                    for (let i = 0; i < tableHeaderColumns.length; i++) {
                         stickyTableHeader.childNodes[1].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                         stickyTableHeader.childNodes[2].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                    }
               };

               if (headerBottom > tableHeaderTop + 10) {
                    if (window.screen.width === 991) {
                         setTimeout(() => {
                              updateStickyTable();
                         }, 600);
                    } else {
                         updateStickyTable();
                    }
               }
          };
          let modalBody = document.querySelectorAll('.flexible-modal-body');

          if (modalBody.length > 0) {
               modalBody = modalBody[!flexibleModal.showLv2 ? 0 : 1];
          }

          const handleScroll = () => {
               let tableHeaderTop = table ? table.offsetTop : 0;
               let headerBottom = headerHeight + body.scrollTop;
               let tableHeader = table ? table.childNodes[0] : [];
               let tableHeader2ndRow = tableHeader.childNodes ? tableHeader.childNodes[1] : null;
               let tableHeaderColumns;
               if (tableHeader2ndRow && tableHeader2ndRow.childNodes) {
                    tableHeaderColumns = tableHeader2ndRow.childNodes;
               }
               let stickyTableHeader = stickyTable.childNodes[0];
               if (headerBottom > tableHeaderTop + 15) {
                    tableHeader.style.visibility = 'hidden';
                    stickyTable.style.display = 'block';
                    stickyTable.style.width = table.parentNode.offsetWidth + 'px';
                    stickyTable.style.top = headerHeight + 'px';
                    for (let i = 0; i < tableHeaderColumns.length; i++) {
                         stickyTableHeader.childNodes[1].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                         stickyTableHeader.childNodes[2].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                    }
               } else {
                    table.children[0].style.visibility = 'visible';
                    stickyTable.style.display = 'none';
               }
          };

          modalBody.addEventListener('scroll', handleScroll);
          const resizeObserver = new ResizeObserver((entries) => {
               entries.forEach(() => {
                    handleResize();
               });
          });

          const observedElements = document.querySelectorAll(`.ll-flexible-modal`);

          observedElements.forEach((element) => {
               resizeObserver.observe(element);
          });

          observedElementsRef.current = Array.from(observedElements);
          return () => {
               resizeObserver.disconnect();
               modalBody.removeEventListener('scroll', handleScroll);
          };
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     const tableWrapperOnScroll = (e) => {
          let stickyTable = document.querySelector('.lookup-table-sticky');
          let scrollLeft = e.target.scrollLeft;
          stickyTable.scrollLeft = scrollLeft;
     };

     const onlyView = stepsData.name === 'Categorize Forms';

     return (
          <>
               <CForm className="form-edit-lookup" onSubmit={onSubmit}>
                    <CRow>
                         <CCol md="12" className="form-edit-lookup-row">
                              <div className="form-edit-lookup-title">
                                   <h1 className="d-flex">
                                        {!onlyView && 'Edit'} Form Rule: {stepsData.name}
                                   </h1>
                              </div>
                              {!onlyView && (
                                   <div className="form-edit-lookup-button">
                                        <div className="form-edit-lookup-button d-flex justify-content-end">
                                             <div className="form-edit-lookup-button-child">
                                                  <CButton
                                                       onClick={() => setDeleteRuleModal(true)}
                                                       className={`${
                                                            !flexibleModal.showLv2 && 'mr-3'
                                                       } d-inline-block text-uppercase btn-outline-secondary text-dark`}
                                                       disabled={deleteLoading}
                                                  >
                                                       Delete Rule
                                                  </CButton>
                                                  {!flexibleModal.showLv2 && (
                                                       <CButton
                                                            color="primary"
                                                            onClick={() => {
                                                                 handleClickEditButton(stepsData.id);
                                                            }}
                                                            className="d-inline-block text-uppercase"
                                                       >
                                                            Edit Rule Settings
                                                       </CButton>
                                                  )}
                                             </div>
                                             <div>
                                                  {stepsData.hasOldVersion && (
                                                       <CButton
                                                            color="primary"
                                                            onClick={() => {
                                                                 setRevertModal(true);
                                                            }}
                                                            className="mr-3 d-inline-block text-uppercase"
                                                       >
                                                            Revert
                                                       </CButton>
                                                  )}
                                                  <CButton
                                                       color="success"
                                                       type="submit"
                                                       className="d-inline-block text-uppercase"
                                                       disabled={btnSaveStatus}
                                                  >
                                                       Save Changes
                                                  </CButton>
                                             </div>
                                        </div>
                                   </div>
                              )}
                         </CCol>
                         <CCol md="12" className="form-edit-lookup-description">
                              {conditions && conditions.length > 0 && conditions[0].length > 0 && (
                                   <p>
                                        Manage variable values based on the conditions you selected when building this rule. The first{' '}
                                        <b>{conditions && conditions[0].length}</b> columns of this table represent the conditions you selected in the
                                        rule settings. The other <b>{1}</b> columns each represent a variable value. Define condition values and their
                                        subsequent variable values.
                                   </p>
                              )}
                         </CCol>
                    </CRow>

                    <div className="table-wrapper mb-3" onScroll={(e) => tableWrapperOnScroll(e)}>
                         <DragDropContext onDragEnd={onDragEnd}>
                              <Droppable droppableId="table">
                                   {(provided) => (
                                        <>
                                             <table
                                                  className={`table lookup-table lookup-table-init ${onlyView ? 'table-only-view' : ''}`}
                                                  ref={provided.innerRef}
                                             >
                                                  {fetchLoading ? (
                                                       <tbody>
                                                            <tr>
                                                                 <td>
                                                                      <CenterSpinner />
                                                                 </td>
                                                            </tr>
                                                       </tbody>
                                                  ) : (
                                                       <>
                                                            {conditions && conditions.length > 0 ? (
                                                                 <>
                                                                      <thead>
                                                                           <tr className="rule-flow">
                                                                                <td
                                                                                     colSpan={
                                                                                          conditions && conditions.length > 0 && conditions[0].length
                                                                                     }
                                                                                >
                                                                                     If these conditions are met
                                                                                </td>
                                                                                <td colSpan={2}>Send these values to my data layer vaof day</td>
                                                                           </tr>
                                                                           <LookupTableContext.Provider value={headerRowContext}>
                                                                                <TableHeaderRow onlyView={onlyView} />
                                                                           </LookupTableContext.Provider>
                                                                      </thead>
                                                                      <tbody>
                                                                           <LookupTableContext.Provider value={bodyRowContext}>
                                                                                <TableBodyRows
                                                                                     onlyView={onlyView}
                                                                                     innerRef={provided.innerRef}
                                                                                     {...provided.droppableProps}
                                                                                />
                                                                                {provided.placeholder}
                                                                           </LookupTableContext.Provider>
                                                                      </tbody>
                                                                 </>
                                                            ) : (
                                                                 <p className="pt-2">There are no conditions.</p>
                                                            )}
                                                       </>
                                                  )}
                                             </table>
                                             <table className="table lookup-table lookup-table-sticky">
                                                  <thead>
                                                       <tr className="rule-flow">
                                                            <td colSpan={conditions && conditions.length > 0 && conditions[0].length}>
                                                                 If these conditions are met
                                                            </td>
                                                            <td colSpan={2}>Send these values to my data layer</td>
                                                       </tr>
                                                       {conditions && conditions.length > 0 ? (
                                                            <LookupTableContext.Provider value={headerRowContext}>
                                                                 <TableHeaderRow onlyView={onlyView} />
                                                            </LookupTableContext.Provider>
                                                       ) : (
                                                            <></>
                                                       )}
                                                  </thead>
                                             </table>
                                        </>
                                   )}
                              </Droppable>
                         </DragDropContext>
                    </div>

                    {
                         <div className={`${showCheatSheet ? ' add-margin-cheat-sheet' : ''} form-edit-lookup-row`}>
                              <div className={`add-row-button mb-4 d-flex align-items-center `}>
                                   {!onlyView && (
                                        <div className="add-row-button-child d-flex">
                                             <CButton className="btn-add-row" onClick={() => handleAddRow(1)}>
                                                  <CIcon name="iconAddField" className="icon-add" />
                                                  <CLabel className="add-row mb-0">Add Row</CLabel>
                                             </CButton>
                                             <CButton className="btn-add-row" onClick={() => handleAddRow(5)}>
                                                  <CIcon name="iconAddField" className="icon-add" />
                                                  <CLabel className="add-row mb-0">Add 5 Rows</CLabel>
                                             </CButton>
                                        </div>
                                   )}
                                   <div className="d-flex align-items-center">
                                        {!onlyView && (
                                             <div className="regex-wrapper">
                                                  <div className="regex-recommemd">
                                                       <CIcon name="cil-chevron-double-down" height="14" />
                                                       <button type="button" onClick={handleClickCheatSheat}>
                                                            RegEx Cheatsheet
                                                       </button>
                                                  </div>
                                                  <ul ref={wrapperRef} className={showCheatSheet ? '' : 'hidden'}>
                                                       {REGEX_PATTERNS.map((el, index) => (
                                                            <li key={index}>
                                                                 <span>{el.PATTERN}</span>
                                                                 <span>{el.DESCRIPTION}</span>
                                                            </li>
                                                       ))}
                                                  </ul>
                                             </div>
                                        )}

                                        {conditions && conditions.length > 0 && conditions[0].length > 0 && (
                                             <LookupTableContext.Provider value={importExportContext}>
                                                  <ImportExportTable onlyView={onlyView} />
                                             </LookupTableContext.Provider>
                                        )}
                                   </div>
                              </div>

                              {!onlyView && (
                                   <div className="mb-3 form-edit-lookup-button text-right d-flex justify-content-end">
                                        <div className="form-edit-lookup-button-child">
                                             <CButton
                                                  onClick={() => setDeleteRuleModal(true)}
                                                  className={`${
                                                       !flexibleModal.showLv2 && 'mr-3'
                                                  } d-inline-block text-uppercase btn-outline-secondary text-dark`}
                                                  disabled={deleteLoading}
                                             >
                                                  Delete Rule
                                             </CButton>
                                             {!flexibleModal.showLv2 && (
                                                  <CButton
                                                       color="primary"
                                                       onClick={() => {
                                                            handleClickEditButton(stepsData.id);
                                                       }}
                                                       className="d-inline-block text-uppercase"
                                                  >
                                                       Edit Rule Settings
                                                  </CButton>
                                             )}
                                        </div>
                                        <div>
                                             {stepsData.hasOldVersion && (
                                                  <CButton
                                                       color="primary"
                                                       onClick={() => {
                                                            setRevertModal(true);
                                                       }}
                                                       className="mr-3 d-inline-block text-uppercase"
                                                  >
                                                       Revert
                                                  </CButton>
                                             )}
                                             <CButton
                                                  color="success"
                                                  type="submit"
                                                  className="d-inline-block text-uppercase"
                                                  disabled={btnSaveStatus}
                                             >
                                                  Save Changes
                                             </CButton>
                                        </div>
                                   </div>
                              )}
                         </div>
                    }
               </CForm>
               <ConfirmRemovePopup show={deleteRuleModal} onAccept={handleDelete} onClose={() => setDeleteRuleModal(false)} isLoading={deleteLoading}>
                    <p className="mb-4">
                         You are about to delete the rule <strong>"{stepsData.name}"</strong>. Please note{' '}
                         <span className="text-danger">this action is irreversible</span>.
                    </p>
               </ConfirmRemovePopup>
               <RemovePopup
                    show={removeRowModal.show}
                    onAccept={confirmRemoveRow}
                    onClose={() => setRemoveRowModal({ show: false, index: -1 })}
                    loading={deleteLoading}
               >
                    <p>Are you sure to remove this row?</p>
               </RemovePopup>
               <ConfirmSaveChange
                    show={saveTableModal}
                    onAccept={onAcceptSaveModal}
                    onClose={() => setSaveTableModal(false)}
                    title="Save Your Changes?"
                    isLoading={saveLoading}
               >
                    It looks like you have made changes to this lookup table but have not saved them. Would you like to save the changes before
                    navigating away?
               </ConfirmSaveChange>
               <ConfirmSaveChange
                    show={revertModal}
                    onAccept={handleReverting}
                    onClose={() => setRevertModal(false)}
                    title="Revert This Rule?"
                    isLoading={saveLoading}
               >
                    This rule will be reverted to the last version. Would you like to revert it?
               </ConfirmSaveChange>
               <LeaveComponentChangePopup
                    show={leaveComponentModal || unsavedLookupTableModal.show}
                    onAccept={handleAcceptLeaveComponent}
                    onClose={handleCloseLeaveComponent}
                    title="You Have Unsaved Changes"
                    btnAbandon={btnAbandon ? <span className="dots-waiting">Waiting</span> : 'Abandon My Changes & Leave'}
               >
                    You haven’t saved the changes you started making to this lookup table. If you navigate away, you will lose the changes. What do
                    you want to do?
               </LeaveComponentChangePopup>
          </>
     );
};

FormRuleTable.propTypes = {
     stepsData: PropTypes.object,
     initialStepsData: PropTypes.object,
     setStepsData: PropTypes.func,
};
export default FormRuleTable;
