import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import { CButton, CCard, CCardBody } from '@coreui/react';
import useSaveCurrentStep from './useSaveCurrentStep';
import { useSelector } from 'react-redux';
import {
     GOOGLE_LOGIN,
     GOOGLE_ONE_TAP_LOGIN,
     SESSION_STORAGE_GOOGLE_INFO,
     SESSION_STORAGE_GOOGLE_REDIRECT_DATA_USER,
     SESSION_STORAGE_GOOGLE_REDIRECT_LOGIN,
} from '../../../../../constants';
import { callApi } from '../../../../../apiCaller';
import { toastError } from '../../../../../utils';
import { authenticate, isAuth } from '../../../../../helpers/auth';
import { handleLoginPopup } from '../../../../../helpers/cms/subscriber';

const CreateLogin = () => {
     const user = useSelector((state) => state.theme.user);
     const { setCurrentStep, stepsData } = useContext(CreateAccountContext);
     const credential = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_GOOGLE_INFO));
     const loginRedirect = sessionStorage.getItem(SESSION_STORAGE_GOOGLE_REDIRECT_LOGIN);
     const dataUser = JSON.parse(stepsData.dataUser);
     const [ipAddress, setIpAddress] = useState(null);

     const informParent = useCallback((response) => {
          authenticate(response, () => {
               if (isAuth()) {
                    handleLoginPopup(response.data.user.id); // Handle if it'll show setup account again or not
                    window.location.replace('/');
               }
          });
     }, []);

     useEffect(() => {
          const getCloudflareJSON = async () => {
               try {
                    let data = await fetch('https://www.cloudflare.com/cdn-cgi/trace').then((res) => res.text());
                    let arr = data
                         .trim()
                         .split('\n')
                         .map((e) => e.split('='));
                    return Object.fromEntries(arr);
               } catch (error) {
                    console.error('Error fetching data:', error);
                    return null;
               }
          };

          const fetchData = async () => {
               const res = await getCloudflareJSON();
               setIpAddress(res.ip);
          };

          fetchData();
     }, []);

     const goNext = () => {
          if (user) setCurrentStep(STEPS.userReplace, CHANGE_STEP_TYPE.mainStepNext);
          else {
               if (loginRedirect === 'true') {
                    let data = { accessToken: credential, dataUser };
                    if (ipAddress) {
                         data.ipAddress = ipAddress;
                    }

                    callApi(GOOGLE_LOGIN, 'POST', data).then((response) => {
                         if (response.status === 200) {
                              sessionStorage.removeItem(SESSION_STORAGE_GOOGLE_INFO);
                              sessionStorage.removeItem(SESSION_STORAGE_GOOGLE_REDIRECT_LOGIN);
                              sessionStorage.removeItem(SESSION_STORAGE_GOOGLE_REDIRECT_DATA_USER);
                              informParent(response);
                         } else {
                              toastError(response);
                         }
                    });
                    return;
               }

               if (credential) {
                    callApi(GOOGLE_ONE_TAP_LOGIN, 'POST', { oneTapToken: credential, dataUser, ipAddress }).then((response) => {
                         if (response.status === 200) {
                              sessionStorage.removeItem(SESSION_STORAGE_GOOGLE_INFO);
                              informParent(response);
                         } else {
                              toastError(response);
                         }
                    });
                    return;
               }
               setCurrentStep(STEPS.register);
          }
     };

     const onBackBtnClicked = () => {
          setCurrentStep(STEPS.seeHowItWorks);
     };

     useSaveCurrentStep();

     return (
          <CreateAccountBody hideStepProgress={true} onBackBtnClicked={onBackBtnClicked}>
               <CCard className="account-almost-done">
                    <CCardBody>
                         <h3>Create Your Login</h3>
                         <p className="des px-0">Create a ListenLayer account to supercharge your website data</p>

                         <div>
                              <CButton className="btn-save mt-0" onClick={goNext}>
                                   GET STARTED
                              </CButton>
                         </div>
                    </CCardBody>
               </CCard>
          </CreateAccountBody>
     );
};

export default CreateLogin;
