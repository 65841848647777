import { CButton } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { Navigation } from 'swiper';
import 'swiper/modules/navigation/navigation.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { DESTINATION_TYPES, FILTER_OPTIONS, LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS } from '../../../../constants';
import DestinationsItems from './DestinationsItems';
import DestinationsLoading from './DestinationsLoading';
import TipVideo from '../../../general/TipVideo';
import Headline from '../../../general/Headline';
import { TOURSTEPS } from '../../../../containers/subscriber/TheTour';

const DestinationsLayout = () => {
     const destinationsLoading = useSelector((state) => state.subscriber.loadings.destinations);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountDestinations = useSelector((state) => state.subscriber.accountDestinations);
     const { showTour, stepTour } = useSelector(state => state.subscriber.tour);

     const listType = DESTINATION_TYPES.sort((a, b) => a.localeCompare(b));
     const listFilters = [FILTER_OPTIONS.SHOW_ALL, FILTER_OPTIONS.SHOW_ENABLED, ...listType];

     const [destinations, setDestinations] = useState(accountDestinations);
     const [statusShowOnlyEnabled, setStatusShowOnlyEnabled] = useState(JSON.parse(localStorage.getItem(LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS)) || false);
     const [filterType, setFilterType] = useState(statusShowOnlyEnabled ? FILTER_OPTIONS.SHOW_ENABLED : FILTER_OPTIONS.SHOW_ALL);

     useEffect(() => {
          if (statusShowOnlyEnabled) {
               const newDestinations = accountDestinations.filter((listener) => listener.configStatus === true);
               setDestinations(newDestinations);
               setFilterType(FILTER_OPTIONS.SHOW_ENABLED);
          } 
     }, [statusShowOnlyEnabled, accountDestinations]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleFilter = (filter) => {
          setFilterType(filter);

          let newDestinations = [];

          if (filter === FILTER_OPTIONS.SHOW_ALL) {
               newDestinations = accountDestinations;

               setStatusShowOnlyEnabled(false);
               localStorage.setItem(LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS, false);
          } else if (filter === FILTER_OPTIONS.SHOW_ENABLED) {
               newDestinations = accountDestinations.filter((destination) => destination.configStatus === true);

               setStatusShowOnlyEnabled(true);
               localStorage.setItem(LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS, true);
          } else {
               newDestinations = accountDestinations.filter((destination) => destination.type === filter);

               setStatusShowOnlyEnabled(false);
               localStorage.setItem(LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS, false);
          }
          setDestinations(newDestinations);
     };

     return (
          <div className={`listeners destinations ${showTour && stepTour === TOURSTEPS.createDestinationsWizard.orderNumber ? 'step-create-destination' : ''}`}>
               <Headline title='Manage Your Destinations'>
                    <p>
                         Use ListenLayer to send your website data anywhere! Our library of destinations is always growing. Soon, most destinations will support our
                         server-side tagging in addition to sending client-side data using Google Tag Manager.
                    </p>
               </Headline>

               <TipVideo
                    contentText='Configure your conversions once inside of ListenLayer and send them to all destinations.'
                    videoLink='https://www.youtube.com/embed/3sgyMCj1cv4/?rel=0'
                    headlinePopup='What are destinations?'
                    descriptionPopup='Learn about destinations and how to use them on your website.'
               />

               <div className='filter-slider d-flex justify-content-center align-items-center'>
                    <Swiper navigation={true} modules={[Navigation]} width={null} slidesPerView='auto'>
                         {listFilters.map((filter, index) => {
                              return (
                                   <SwiperSlide key={index}>
                                        <CButton className={`btn-filter ${filterType === filter ? 'active' : ''}`} onClick={() => handleFilter(filter)}>
                                             {filter}
                                        </CButton>
                                   </SwiperSlide>
                              );
                         })}
                    </Swiper>
               </div>

               {!destinationsLoading && activeAccount.id ? (
                    <>
                         {destinations.length > 0 ? (
                              <DestinationsItems destinations={destinations} filterType={filterType} />
                         ) : (
                              <div className='no-filtering-result'>
                                   <h2 className='d-flex justify-content-center align-items-center'>
                                        No Destinations <CIcon name='cil-ban' width='30' className='icon-cil-ban' />
                                   </h2>
                              </div>
                         )}
                    </>
               ) : (
                    <DestinationsLoading />
               )}
          </div>
     );
};

export default DestinationsLayout;
