import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormInventoryTableRow from './FormInventoryTableRow';
import { handleFetchChildForm, setSubscriberState } from '../../../../../../actions/subscriber';
import { CButton, CSpinner } from '@coreui/react';

const MultiForms = ({ errors, setErrors, index, formsData, className }) => {
     const dispatch = useDispatch();
     const [showAccordion, setShowAccordion] = useState(false);
     const [fetched, setFetched] = useState(false);
     const [loading, setLoading] = useState(false);
     const [isLoadingLoadMore, setisLoadingLoadMore] = useState(false);
     const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(true);
     const showIgnoredInventoryForm = useSelector((state) => state.subscriber.showIgnoredInventoryForm);
     const listChildInventory = useSelector((state) => state.subscriber[formsData.id]);
     const listChildIgnored = useSelector((state) => state.subscriber[`ignored${formsData.id}`]);
     const listFormsChild = !showIgnoredInventoryForm ? listChildInventory : listChildIgnored;
     const showFormsChild = !showIgnoredInventoryForm ? typeof listChildInventory !== 'undefined' : typeof listChildIgnored !== 'undefined';
     const formInventoryEdit = useSelector((state) => state.subscriber.formInventoryEdit);
     const formIgnoreEdit = useSelector((state) => state.subscriber.formIgnoreEdit);
     const filterFormCategories = useSelector((state) => state.subscriber.filterFormCategories);
     const initialPersonalData = useSelector((state) => state.subscriber.initialPersonalData);
     const itemsPerPage = 12;

     useEffect(() => {
          if (listChildInventory && listChildInventory.length > 0) {
               const newFormInventoryEdit = [...formInventoryEdit];

               listChildInventory.forEach((form) => {
                    if (form.issue === 'isDrafted') {
                         newFormInventoryEdit.push(form);
                    }
               });

               if (newFormInventoryEdit.length !== formInventoryEdit.length) {
                    dispatch(setSubscriberState({ formInventoryEdit: newFormInventoryEdit }));
               }
          }
     }, [listChildInventory]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleClickAccordion = (id) => {
          if (!fetched) {
               const nextDispatch = {
                    setFetched,
                    setLoading,
                    setShowLoadMoreBtn,
               };
               // fetch api get child
               dispatch(handleFetchChildForm(id, nextDispatch, itemsPerPage, false, filterFormCategories));
          }
          setShowAccordion(!showAccordion);
     };

     const onLoadMore = () => {
          const nextDispatch = {
               setisLoadingLoadMore,
               setShowLoadMoreBtn,
          };
          dispatch(handleFetchChildForm(formsData.id, nextDispatch, itemsPerPage, true, filterFormCategories));
     };

     const formEdit = !showIgnoredInventoryForm ? formInventoryEdit : formIgnoreEdit;
     const treeFamilyOfForms = listFormsChild
          ? listFormsChild.map((form) => {
                 const formExisted = formEdit.find((item) => item.id === form.id);

                 if (formExisted) {
                      return { ...form, ...formExisted };
                 }
                 return form;
            })
          : [];

     return (
          <>
               <FormInventoryTableRow
                    className={className}
                    data={formsData}
                    redirect={true}
                    applyChild={false}
                    hasChild={true}
                    showAccordion={showAccordion}
                    toggleDetails={handleClickAccordion}
                    index={index}
                    errors={errors}
                    setErrors={setErrors}
               />
               {showAccordion && (
                    <>
                         {loading ? (
                              <div className={`css-table-row child-form ${(index + 1) % 2 === 0 ? 'even' : 'odd'}`}>
                                   <div className='child-form-loading'>
                                        <div className='center-spinner text-left'>
                                             <CSpinner color='primary' />
                                        </div>
                                   </div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   {!showIgnoredInventoryForm && initialPersonalData && initialPersonalData.status && <div></div>}
                              </div>
                         ) : (
                              <>
                                   {showFormsChild && (
                                        <>
                                             {(treeFamilyOfForms && treeFamilyOfForms.length > 0) || showIgnoredInventoryForm ? (
                                                  <>
                                                       {treeFamilyOfForms.map((item, i) => {
                                                            return (
                                                                 <React.Fragment key={`inventory-child-${item.id}${i}`}>
                                                                      <FormInventoryTableRow
                                                                           className={className}
                                                                           data={item}
                                                                           redirect={true}
                                                                           applyChild={false}
                                                                           isChildForm={true}
                                                                           hasChild={false}
                                                                           parentValue={formsData.categoryId}
                                                                           parentName={formsData.name}
                                                                           parentRedirect={formsData.isRedirect}
                                                                           index={index}
                                                                           errors={errors}
                                                                           setErrors={setErrors}
                                                                      />
                                                                 </React.Fragment>
                                                            );
                                                       })}
                                                       <div className={`css-table-row ${(index + 1) % 2 === 0 ? 'even' : 'odd'}`}>
                                                            {isLoadingLoadMore ? (
                                                                 <>
                                                                      <div className='child-form-loading'>
                                                                           <CSpinner color='primary' />
                                                                      </div>
                                                                      <div></div>
                                                                      <div></div>
                                                                      <div></div>
                                                                      <div></div>
                                                                      <div></div>
                                                                      <div></div>
                                                                      <div></div>
                                                                      <div></div>
                                                                      {!showIgnoredInventoryForm && initialPersonalData && initialPersonalData.status && <div></div>}
                                                                 </>
                                                            ) : (
                                                                 showLoadMoreBtn && (
                                                                      <>
                                                                           <div>
                                                                                <CButton
                                                                                     className='load-more-child'
                                                                                     color='link'
                                                                                     onClick={onLoadMore}
                                                                                >
                                                                                     Load More <i className='fal fa-chevron-right'></i>
                                                                                </CButton>
                                                                           </div>
                                                                           <div></div>
                                                                           <div></div>
                                                                           <div></div>
                                                                           <div></div>
                                                                           <div></div>
                                                                           <div></div>
                                                                           <div></div>
                                                                           <div></div>
                                                                           {!showIgnoredInventoryForm && initialPersonalData && initialPersonalData.status && <div></div>}
                                                                      </>
                                                                 )
                                                            )}
                                                       </div>
                                                  </>
                                             ) : (
                                                  <>
                                                       <div className={`css-table-row ${(index + 1) % 2 === 0 ? 'even' : 'odd'}`}>
                                                            <div>No Forms!</div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            {!showIgnoredInventoryForm && initialPersonalData && initialPersonalData.status && <div></div>}
                                                       </div>
                                                  </>
                                             )}
                                        </>
                                   )}
                              </>
                         )}
                    </>
               )}
          </>
     );
};

export default MultiForms;
