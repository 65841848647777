import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
     CDropdown,
     CDropdownItem,
     CDropdownMenu,
     CDropdownToggle,
     CImg,
} from '@coreui/react'
import CIcon from '@coreui/icons-react';

import { signout } from '../../helpers/auth';
// import { SUPPORT_PATH } from '../../constants';
import { setOverlay } from '../../actions/common';

const SupportAccountDropdown = () => {
     const dispatch = useDispatch();
     const [dropdownProps, setDropdownProps] = useState({
          onBlurEnabled: false,
          isDropdownItemFocusing: false
     });
     const user = useSelector(state => state.theme.user);

     const toggleShow = () => {
          const dropdownClass = document.getElementsByClassName('user-nav-item')[0].className;

          if (dropdownClass.includes('show')) {
               setDropdownProps({ onBlurEnabled: false, isDropdownItemFocusing: false })
               dispatch(setOverlay(false));
          } else {
               setDropdownProps({ onBlurEnabled: true, isDropdownItemFocusing: false })
               dispatch(setOverlay(true));
          }
     }

     const onFocusDropdownMenu = () => {
          setDropdownProps({ onBlurEnabled: true, isDropdownItemFocusing: true })
          dispatch(setOverlay(true));
     }

     return (
          <CDropdown
               inNav
               className="c-header-nav-items user-nav-item"
               direction="down"
          >
               <CDropdownToggle
                    className="c-header-nav-link"
                    caret={false}
                    onClick={toggleShow}
                    onBlur={dropdownProps.onBlurEnabled ? toggleShow : null}
               >
                    <div className="c-avatar">
                         <CImg
                              src='/user-default-avatar.png'
                              className="c-avatar-img"
                              alt="avatar"
                         />
                    </div>
               </CDropdownToggle>
               <CDropdownMenu
                    className="pt-0 account-dropdown"
                    placement="bottom-end"
                    onBlur={dropdownProps.isDropdownItemFocusing ? toggleShow : null}
               >
                    <CDropdownItem
                         header
                         tag="div"
                         onFocus={onFocusDropdownMenu}
                    >
                         <CIcon name="arrow-account" className="arrow-account" />
                         <span>My Account</span>
                         <p className="account-user-email m-0">{user ? user.email : ''}</p>
                    </CDropdownItem>
                    {/* <CDropdownItem to={SUPPORT_PATH.PROFILE}>
                         My Profile
                    </CDropdownItem> */}
                    <CDropdownItem className="sign-out text-danger" onClick={signout}>
                         SIGN OUT
                    </CDropdownItem>
               </CDropdownMenu>
          </CDropdown>
     )
}

export default React.memo(SupportAccountDropdown)
