import CIcon from '@coreui/icons-react';
import {
    CInput,
    CModal,
    CModalBody
} from '@coreui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DATE_FUNCTION_VARIABLES, TYPE_RULE } from '../../../../../../constants';

const InsertVariableModal = (props) => {
    const {
        showVariableModal,
        toggleVariableModal,
        handleChangeRowData,
        variableOptions,
        browserVariableShortCodes = [],
        variablePositionToInsert,
        cursorPosition,
        tableRow,
        typeRule,
    } = props;
    const [variableBoxFilter, setVariableBoxFilter] = useState(''); // Current text in the insert data layer variable box filter
    const [openTypeVariable, setOpenTypeVariable] = useState({});
    const filterInputRef = useRef(null);

    let variableBoxFilterLowerCase = variableBoxFilter.toLowerCase();
    // Automatic variable options which are displayed in insert data layer variable box
    let variableOptionsFound = variableOptions.filter(option => (option.fullName.toLowerCase().includes(variableBoxFilterLowerCase)));

    // Date function variable options which are displayed in insert a function box
    let functionOptionsFound = DATE_FUNCTION_VARIABLES.filter(option => option.value.toLowerCase().includes(variableBoxFilterLowerCase));

    // Browser storage variable options which are displayed in insert a browser storage variable box
    let browserVariableOptionsFound = browserVariableShortCodes.filter(option => option.toLowerCase().includes(variableBoxFilterLowerCase));

    useEffect(() => {
        if (showVariableModal && filterInputRef.current) {
            setTimeout(() => {
                filterInputRef.current.focus();
            }, 0);
        }
    }, [showVariableModal]);

    const handleCloseVariableModal = useCallback(() => {
        toggleVariableModal();
        setVariableBoxFilter('');
        setOpenTypeVariable({})
    }, [toggleVariableModal])

    // Add [automatic variable] to value input
    const handleInsertVariable = ({ textToInsert, rowIndex, colIndex }) => {
        let valueToChange = tableRow[rowIndex].customVariables.userSource; // [automatic variable] will be inserted to this value

        if (cursorPosition === 0) {
            // Cursor position currently is at the start of the text area
            valueToChange = `{{${textToInsert}}}${valueToChange}`;
        } else if (cursorPosition === valueToChange.length || cursorPosition === -1) {
            // Cursor position currently is at the end of the text area
            // or there's no cursor position
            valueToChange += `{{${textToInsert}}}`;
        } else {
            // Cursor position currently is somewhere in the middle of the text area
            let beforeCursorValue = valueToChange.substring(0, cursorPosition);
            let afterCursorValue = valueToChange.substring(cursorPosition);
            valueToChange = `${beforeCursorValue}{{${textToInsert}}}${afterCursorValue}`; // Insert [automatic variable] between beforeCursorValue and afterCursorValue
        }

        handleCloseVariableModal();
        handleChangeRowData(valueToChange, 'userSource', rowIndex);
    }
    const handleOpenTypeVariable = (id) => {
        setOpenTypeVariable(prevState => ({ ...prevState, [id]: !prevState[id] }));
    }
    const handleSearch = (e) => {
        setVariableBoxFilter(e)
        const searchObject = {
            "Data Layer Variable": true,
            "Browser Storage Variable": true,
            "Function": true
        }
        if (Object.keys(searchObject).length > 0) {
            setOpenTypeVariable(searchObject)
        }
    }
    return (
        <div className="insert-variable-modal">
            <CModal
                show={showVariableModal}
                onClose={handleCloseVariableModal}
            >
                <CModalBody>
                    <CIcon name="cil-x" onClick={handleCloseVariableModal} className="icon-close-popup icon-close-popup-insert" />

                    <div className="insert-automatic-variables no-options insert-variables-lookup">
                        <>
                            <div className="variable-filter">
                                <h6>Insert Data Layer Variable</h6>
                                <CInput
                                    type="text"
                                    innerRef={filterInputRef}
                                    value={variableBoxFilter}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    data-lpignore="true"
                                    placeholder="Select, or type to search"
                                />
                            </div>
                            <ul>
                                <li className='d-flex align-items-center justify-content-between border-bottom border-top type-variable' onClick={() => handleOpenTypeVariable('Data Layer Variable')}>
                                    <strong>
                                        Data Layer Variable
                                    </strong>
                                    {openTypeVariable['Data Layer Variable'] ?
                                        <strong>
                                            {variableOptionsFound.length}
                                        </strong>
                                        :
                                        <CIcon name='iconArrowDownStrong' />
                                    }

                                </li>
                                {openTypeVariable['Data Layer Variable'] && (
                                    <>
                                        {
                                            variableOptionsFound.length > 0 ? (
                                                variableOptionsFound.map(option => {
                                                    const re = new RegExp(variableBoxFilter, "gi");
                                                    let newVariable = option.friendlyName || option.fullName;
                                                    if (variableBoxFilter) {
                                                        newVariable = newVariable.replace(re, `<strong>${variableBoxFilter}</strong>`)
                                                    }
                                                    return (
                                                        <li
                                                            className='border-bottom d-flex align-items-center'
                                                            key={option.id}
                                                            onClick={e => handleInsertVariable({
                                                                textToInsert: option.fullName,
                                                                rowIndex: variablePositionToInsert.rowIndex,
                                                                colIndex: variablePositionToInsert.colIndex
                                                            })}
                                                        >
                                                            <span dangerouslySetInnerHTML={{ __html: `${newVariable}` }}>

                                                            </span>
                                                        </li>
                                                    )
                                                })
                                            ) : (
                                                <li className="no-options border-bottom d-flex align-items-center">No options found.</li>
                                            )
                                        }

                                    </>
                                )}
                                {typeRule !== TYPE_RULE.USER_SOURCE_RULE && (
                                    <>
                                        <li className='d-flex align-items-center justify-content-between border-bottom type-variable' onClick={() => handleOpenTypeVariable('Browser Storage Variable')}>
                                            <strong>
                                                Browser Variable
                                            </strong>
                                            {openTypeVariable['Browser Storage Variable'] ?
                                                <strong >
                                                    {browserVariableOptionsFound.length}
                                                </strong>
                                                :
                                                <CIcon name='iconArrowDownStrong' />
                                            }

                                        </li>
                                        {openTypeVariable['Browser Storage Variable'] && (
                                            <>
                                                {
                                                    browserVariableOptionsFound.length > 0 ? (
                                                        browserVariableOptionsFound.map(option => {
                                                            const re = new RegExp(variableBoxFilter, "gi");
                                                            let newVariable = option;
                                                            if (variableBoxFilter) {
                                                                newVariable = newVariable.replace(re, `<strong>${variableBoxFilter}</strong>`)
                                                            }
                                                            return (
                                                                <li
                                                                    className='border-bottom d-flex align-items-center'
                                                                    key={option}
                                                                    onClick={e => handleInsertVariable({
                                                                        textToInsert: option,
                                                                        rowIndex: variablePositionToInsert.rowIndex,
                                                                        colIndex: variablePositionToInsert.colIndex
                                                                    })}
                                                                >
                                                                    <span dangerouslySetInnerHTML={{ __html: `${newVariable}` }}></span>
                                                                </li>
                                                            )
                                                        })
                                                    ) : (
                                                        <li className="no-options border-bottom d-flex align-items-center">No options found.</li>
                                                    )
                                                }
                                            </>
                                        )}
                                    </>
                                )}


                                <li className='d-flex align-items-center justify-content-between border-bottom type-variable' onClick={() => handleOpenTypeVariable('Function')}>
                                    <strong>
                                        Function
                                    </strong>
                                    {openTypeVariable['Function'] ?
                                        <strong>
                                            {functionOptionsFound.length}
                                        </strong>
                                        :
                                        <CIcon name='iconArrowDownStrong' />
                                    }

                                </li>
                                {openTypeVariable['Function'] && (
                                    <>
                                        {
                                            functionOptionsFound.length > 0 ? (
                                                functionOptionsFound.map(option => {
                                                    const re = new RegExp(variableBoxFilter, "gi");
                                                    let newVariable = option.value;
                                                    if (variableBoxFilter) {
                                                        newVariable = newVariable.replace(re, `<strong>${variableBoxFilter}</strong>`)
                                                    }
                                                    return (
                                                        <li
                                                            className='border-bottom d-flex align-items-center'
                                                            key={option.value}
                                                            onClick={e => handleInsertVariable({
                                                                textToInsert: option.value,
                                                                rowIndex: variablePositionToInsert.rowIndex,
                                                                colIndex: variablePositionToInsert.colIndex
                                                            })}
                                                        >
                                                            <span dangerouslySetInnerHTML={{ __html: `${newVariable}` }}></span>
                                                        </li>

                                                    )
                                                })
                                            ) : (
                                                <li className="no-options border-bottom d-flex align-items-center">No options found.</li>
                                            )
                                        }
                                    </>
                                )}
                            </ul>
                            {/* {
                                        (activeBoxType === 'function') && (
                                            (DATE_FUNCTION_VARIABLES.length > 0) ? (
                                                <>
                                                    <div className="variable-filter">
                                                        <h6>Insert a Function</h6>
                                                        <CInput
                                                            type="text"
                                                            value={variableBoxFilter}
                                                            onChange={e => setVariableBoxFilter(e.target.value)}
                                                            data-lpignore="true"
                                                            placeholder="Type here to filter the list…"
                                                        />
                                                    </div>
                                                    <ul>
                                                        {
                                                            functionOptionsFound.length > 0 ? (
                                                                functionOptionsFound.map(option => (
                                                                    <li
                                                                        key={option.value}
                                                                        onClick={e => handleInsertVariable({
                                                                            textToInsert: option.value,
                                                                            rowIndex: variablePositionToInsert.rowIndex,
                                                                            colIndex: variablePositionToInsert.colIndex
                                                                        })}
                                                                    >
                                                                        {option.value}
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li className="no-options">No options found.</li>
                                                            )
                                                        }
                                                    </ul>
                                                </>
                                            ) : (
                                                <>
                                                    <br />
                                                    <span className="no-options">No options available.</span>
                                                </>
                                            )
                                        )
                                    } */}
                            {/* {
                                        (activeBoxType === 'browser storage') && (
                                            (browserVariableShortCodes.length > 0) ? (
                                                <>
                                                    <div className="variable-filter">
                                                        <h6>Insert Browser Storage Variable</h6>
                                                        <CInput
                                                            type="text"
                                                            value={variableBoxFilter}
                                                            onChange={e => setVariableBoxFilter(e.target.value)}
                                                            data-lpignore="true"
                                                            placeholder="Type here to filter the list…"
                                                        />
                                                    </div>
                                                    <ul>
                                                        {
                                                            browserVariableOptionsFound.length > 0 ? (
                                                                browserVariableOptionsFound.map(option => (
                                                                    <li
                                                                        key={option}
                                                                        onClick={e => handleInsertVariable({
                                                                            textToInsert: option,
                                                                            rowIndex: variablePositionToInsert.rowIndex,
                                                                            colIndex: variablePositionToInsert.colIndex
                                                                        })}
                                                                    >
                                                                        {option}
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li className="no-options">No options found.</li>
                                                            )
                                                        }
                                                    </ul>
                                                </>
                                            ) : (
                                                <>
                                                    <br />
                                                    <NoOptionsMessage type={NO_OPTION_MESSAGE_TYPE.CUSTOM_BROWSER_VARIABLE} />
                                                </>
                                            )
                                        )
                                    } */}
                        </>
                    </div>
                </CModalBody>
            </CModal>
        </div>
    )
}

export default InsertVariableModal
