import React, { memo, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
     Chart,
     ChartSeries,
     ChartSeriesItem,
     ChartCategoryAxis,
     ChartCategoryAxisItem,
     ChartLegend,
     ChartValueAxis,
     ChartValueAxisItem,
     ChartTooltip,
} from '@progress/kendo-react-charts';
import CIcon from '@coreui/icons-react';
import { API_CLIENT_REPORTING_CHART, DEFAULT_DATE_FORMAT, REPORT_DATA_TYPES } from '../../../../constants';
import { EventExplorerContext } from './EventExplorer';
import { CustomDropdown, CustomDropdownItem } from '../../../general/customDropdown';
import { useSelector } from 'react-redux';
import { callTokenApiCancel } from '../../../../apiCaller';
import { convertReportDuration } from '../../../../utils';

function ChartReport() {
     const {
          reportName,
          scoped,
          reportType,
          conversionName,
          conversionNameMetric,
          dateRangeConfig,
          showData,
          enableFullAnalytics,
          metricsStepForm,
          filterDimensions,
     } = useContext(EventExplorerContext);
     const { metrics, dimensions } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     const metricsConversionName = [...metrics, ...conversionNameMetric, ...metricsStepForm];
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const handleOrderCol = (arr, arrFilter) => {
          const newArr = [];
          arr.forEach((item) => {
               if (arrFilter.some((el) => el.value === item.key)) {
                    newArr.push(item.key);
               }
          });
          return newArr;
     };
     const newDimensions = handleOrderCol(dimensions, filterDimensions);
     const LineType = 'line';
     const arrColor = ['#3754e6', '#E63946', '#FFD246', '#78D237'];
     const [dateType, setDateType] = useState('DAY');
     const [listMetricSelected, setListMetricSelected] = useState(
          metricsConversionName.length > 1
               ? getMetricSelected([metricsConversionName[0], metricsConversionName[1]])
               : getMetricSelected([metricsConversionName[0]])
     );
     const [series, setSeries] = useState([]);
     const [vertical, setVertical] = useState([]);
     const [categories, setCategories] = useState([]);
     const [loading, setLoading] = useState(false);
     const [labelDate, setLabelDate] = useState([]);
     const [labelDateCompare, setLabelDateCompare] = useState([]);

     function getUnusedColor(arrColor, listMetricSelected) {
          for (const color of arrColor) {
               const colorExists = listMetricSelected.some((metric) => metric.color === color);
               if (!colorExists) {
                    return color;
               }
          }
          return null;
     }

     function getKeyFromLabel(label, arr) {
          const match = arr.find((item) => item.label === label);
          return match ? match.key : null;
     }

     function transSeries(inputData) {
          const transformedData = [];
          for (const key in inputData) {
               if (key !== 'labels' && !key.includes('compare')) {
                    transformedData.push({
                         type: LineType,
                         data: inputData[key],
                         name: listMetricSelected.find((i) => i.key === key).label || key,
                         color: listMetricSelected.find((i) => i.key === key).color || '',
                         axis: listMetricSelected.find((i) => i.key === key).label || key,
                    });
               }

               if (key.includes('compare')) {
                    const value = key.replace('compare', '');
                    transformedData.push({
                         type: LineType,
                         data: inputData[key],
                         name: listMetricSelected.find((i) => i.key === key.replace('compare', '')).label || key,
                         color: arrColor.at(listMetricSelected.map((item) => item.key).indexOf(value) + 2),
                         axis: listMetricSelected.find((i) => i.key === key.replace('compare', '')).label || key,
                    });
               }
          }

          return transformedData;
     }

     function transVertical(inputData) {
          const transformedData = [];
          for (const key in inputData) {
               const compareData = inputData[`compare${key}`] ? inputData[`compare${key}`] : [];
               if (key !== 'labels' && !key.includes('compare')) {
                    transformedData.push({
                         type: LineType,
                         data: [...inputData[key], ...compareData],
                         name: listMetricSelected.find((i) => i.key === key).label || key,
                         color: listMetricSelected.find((i) => i.key === key).color || '',
                         axis: listMetricSelected.find((i) => i.key === key).label || key,
                    });
               }
          }

          return transformedData;
     }

     function getMetricLabel(data) {
          return data.map((i) => {
               return i.label;
          });
     }

     function getMetricKey(data) {
          return data.map((i) => {
               return i.key;
          });
     }

     function getMetricSelected(data) {
          return data.map((i, index) => {
               return { key: i.key, label: i.label, color: arrColor[index] };
          });
     }

     function formatDateRangeByMonth(inputValue, dateRangeReports, compare) {
          const date = dayjs(inputValue, 'YYYY-M');

          const selectionStartDate = !compare
               ? dayjs(dateRangeReports.selection.startDate.toDate())
               : dayjs(dateRangeReports.compare.startDate.toDate());
          const selectionEndDate = !compare ? dayjs(dateRangeReports.selection.endDate.toDate()) : dayjs(dateRangeReports.compare.endDate.toDate());

          const startIsSameMonth = selectionStartDate.isSame(date, 'month');
          const endIsSameMonth = selectionEndDate.isSame(date, 'month');

          const startDay = startIsSameMonth ? selectionStartDate.format('D') : '01';
          const endDay = endIsSameMonth ? selectionEndDate.format('D') : dayjs(date).daysInMonth();

          return `${startDay} ${date.format('MMM')}, ${date.format('YYYY')} - ${endDay} ${date.format('MMM')}, ${date.format('YYYY')}`;
     }

     function getStartAndEndOfWeek(yearWeekValue) {
          const [year, week] = yearWeekValue.split('-');
          const startOfWeek = dayjs()
               .year(year)
               .month(0)
               .date(1)
               .add((week - 1) * 7, 'day')
               .day(1); // Start from Monday
          const endOfWeek = startOfWeek.add(6, 'day'); // End at Sunday
          return { startOfWeek, endOfWeek };
     }

     function formatDateRangeByYearWeek(yearWeekValue, dateRangeReports, compare) {
          const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(yearWeekValue);
          const selectionStartDate = !compare ? dayjs(dateRangeReports.selection.startDate) : dayjs(dateRangeReports.compare.startDate);
          const selectionEndDate = !compare ? dayjs(dateRangeReports.selection.endDate) : dayjs(dateRangeReports.compare.endDate);

          let formattedStartDate = startOfWeek.isAfter(selectionStartDate) ? startOfWeek : selectionStartDate;
          let formattedEndDate = endOfWeek.isBefore(selectionEndDate) ? endOfWeek : selectionEndDate;

          return `${formattedStartDate.format('MMM D, YYYY')} - ${formattedEndDate.format('MMM D, YYYY')}`;
     }

     // Function to format date according to dateType
     const formatDateTitleTooltip = (date, dateType, compare = false) => {
          switch (dateType) {
               case 'DAY':
                    return dayjs(date).format('DD MMM, YY');
               case 'WEEK':
                    return formatDateRangeByYearWeek(date, dateRangeReports, compare);
               case 'MONTH':
                    return formatDateRangeByMonth(date, dateRangeReports, compare);
               default:
                    return date;
          }
     };

     function formatNumberWithCommas(number) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
     }

     function isNumber(value) {
          return !isNaN(Number(value));
     }

     function formatNumber(value) {
          if (!isNumber(value)) {
               return String(value);
          } else {
               return formatNumberWithCommas(Number(value));
          }
     }

     // function countMonthsBetweenDates(startDate, endDate) {
     //      const start = dayjs(startDate);
     //      const end = dayjs(endDate);

     //      const startYear = start.year();
     //      const endYear = end.year();
     //      const startMonth = start.month();
     //      const endMonth = end.month();

     //      const monthsDifference = (endYear - startYear) * 12 + (endMonth - startMonth) + 1;
     //      return monthsDifference;
     // }

     // const countMonth = countMonthsBetweenDates(dateRangeReports.startDate, dateRangeReports.endDate);

     function filterAndRemoveItems(metricCurrent, metrics) {
          const keysInMetrics = metrics.map((item) => item.key);
          const filteredMetricCurrent = metricCurrent.filter((item) => keysInMetrics.includes(item.key));
          return filteredMetricCurrent;
     }
     function checkKeysExist(metricCurrent, metrics) {
          for (const item1 of metricCurrent) {
               if (!metrics.some((item2) => item2.key === item1.key)) {
                    return false;
               }
          }
          return true;
     }

     const newDateRange = {
          selection: {
               startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
               endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
          },
     };

     if (dateRangeReports.compare) {
          newDateRange.compare = {
               startDate: dateRangeReports.compare.startDate.format(DEFAULT_DATE_FORMAT),
               endDate: dateRangeReports.compare.endDate.format(DEFAULT_DATE_FORMAT),
          };
     }

     function fetchDataChart() {
          const bodyData = {
               reportName,
               accountId: activeAccount.id,
               dateRanges: newDateRange,
               dateType: dateType,
               metrics: getMetricKey(listMetricSelected),
               dimensions: newDimensions,
               scoped: scoped,
               conversionName: conversionName.filter((item) => item.label.includes('Conv')),
               eCommerceName: conversionName.filter((item) => item.label.includes('Ecommerce')),
               isCompare: dateRangeConfig.comparePeriod,
               reportType
          };
          setLoading(true);
          callTokenApiCancel(API_CLIENT_REPORTING_CHART, 'POST', bodyData).then((response) => {
               if (response && response.status === 200) {
                    if (dateType === 'DAY') {
                         setCategories(response.data.data.labels.map((item) => new Date(item)));
                    } else {
                         setCategories(response.data.data.labels);
                    }
                    setSeries(transSeries(response.data.data));
                    setLabelDateCompare(response.data.labelCompare);
                    setLabelDate(response.data.labels);
                    setVertical(transVertical(response.data.data));
               }
               if (response && response.status) {
                    setLoading(false);
               }
          });
     }

     useEffect(() => {
          if (checkKeysExist(listMetricSelected, metricsConversionName)) {
               fetchDataChart();
          } else {
               setListMetricSelected(filterAndRemoveItems(listMetricSelected, metricsConversionName));
          }
          //eslint-disable-next-line
     }, [scoped]);

     useEffect(() => {
          fetchDataChart();
          //eslint-disable-next-line
     }, [dateType, listMetricSelected, dateRangeReports]);

     const handleDayType = (type) => {
          if (!enableFullAnalytics && !showData) {
               return;
          }
          setDateType(type);
     };

     const deleteMetricCurrent = (label) => {
          if (!enableFullAnalytics && !showData) {
               return;
          }
          setListMetricSelected(listMetricSelected.filter((item) => item.label !== label));
     };

     const handleAddListCurrentMetric = (item) => {
          if (!enableFullAnalytics && !showData) {
               return;
          }
          setListMetricSelected((prev) => [
               ...prev,
               { key: getKeyFromLabel(item, metricsConversionName), label: item, color: getUnusedColor(arrColor, listMetricSelected) },
          ]);
     };

     const loadingPanel = (
          <div className="k-loading-mask">
               <span className="k-loading-text">Loading</span>
               <div className="k-loading-image"></div>
               <div className="k-loading-color"></div>
          </div>
     );

     const SharedTooltip = (props) => {
          const { points } = props;
          const compareNumber = dateRangeConfig.comparePeriod ? listMetricSelected.length : 0;
          let labelText;
          let labelCompare;
          const formatText = labelDate.at(points[0].point.categoryIx) ? false : true;
          switch (dateType) {
               case 'DAY':
                    labelText = labelDate.at(points[0].point.categoryIx)
                         ? dayjs(labelDate.at(points[0].point.categoryIx))
                         : dayjs(labelDateCompare.at(points[0].point.categoryIx));
                    if (dateRangeConfig.comparePeriod)
                         labelCompare = points.at(compareNumber) ? dayjs(labelDateCompare.at(points.at(compareNumber).point.categoryIx)) : null;
                    break;
               case 'WEEK':
               case 'MONTH':
                    labelText = labelDate.at(points[0].point.categoryIx)
                         ? labelDate.at(points[0].point.categoryIx)
                         : labelDateCompare.at(points[0].point.categoryIx);
                    if (dateRangeConfig.comparePeriod)
                         labelCompare = points.at(compareNumber) ? labelDateCompare.at(points.at(compareNumber).point.categoryIx) : null;
                    break;
               default:
                    break;
          }

          return (
               <div
                    style={{
                         maxWidth: '300px',
                         padding: '10px',
                         background: 'white',
                         borderRadius: '10px',
                         boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    }}
               >
                    <strong style={{ width: 'max-content', display: 'inline-block' }}>
                         {formatDateTitleTooltip(labelText, dateType, formatText)}
                    </strong>
                    {points.map((point, i) => (
                         <div key={i}>
                              {i === compareNumber && dateRangeConfig.comparePeriod && (
                                   <strong style={{ width: 'max-content', display: 'inline-block' }}>
                                        {formatDateTitleTooltip(labelCompare, dateType, true)}
                                   </strong>
                              )}
                              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 15px', gap: '15px' }}>
                                   <div style={{ position: 'relative', width: 'max-content' }}>
                                        <div>{point.series.name}</div>
                                        <i
                                             style={{
                                                  position: 'absolute',
                                                  width: '8px',
                                                  height: '8px',
                                                  background: point.series.color,
                                                  top: '50%',
                                                  left: '-15px',
                                                  borderRadius: '50%',
                                                  transform: 'translate(0,-50%)',
                                             }}
                                        />
                                   </div>
                                   <div>
                                        {point.series.name.includes('Rate') ? (
                                             `${formatNumber(point.value)}%`
                                        ) : (
                                             <>
                                                  {(() => {
                                                       switch (point.series.name) {
                                                            case 'Avg Session Duration':
                                                                 return <span>{convertReportDuration(point.value)}</span>;
                                                            case 'Session Duration':
                                                                 return <span>{convertReportDuration(point.value)}</span>;
                                                            case 'Avg Visible Time on Page':
                                                                 return <span>{convertReportDuration(point.value ? point.value : 0)}</span>;
                                                            case 'Avg Visible Time/Page':
                                                            case 'Avg Visible Time / Page':
                                                                 return <span>{convertReportDuration(point.value)}</span>;
                                                            default:
                                                                 return <span>{`${formatNumber(point.value)}`}</span>;
                                                       }
                                                  })()}
                                             </>
                                        )}
                                   </div>
                              </div>
                         </div>
                    ))}
               </div>
          );
     };
     const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

     const formatAxisValue = (e, nameAxis) => {
          const name = nameAxis;

          if (name.includes('Rate')) {
               return `${e.value}%`;
          } else if (checkMetricTime(name)) {
               return `${convertReportDuration(e.value)}`;
          }

          return `${formatNumber(e.value)}`;
     };

     const checkMetricTime = (name) => {
          const timeMetrics = ['Avg Session Duration', 'Session Duration', 'Avg Visible Time on Page', 'Avg Visible Time/Page'];
          return timeMetrics.includes(name);
     };

     function manipulateNumber(number) {
          const numString = Math.round(number).toString();
          const numLength = numString.length;

          let operation = 0;

          if (numLength === 2) {
               operation = 5;
          } else if (numLength === 3) {
               operation = 50;
          } else if (numLength === 4) {
               operation = 500;
          } else if (numLength >= 5) {
               operation = 5000;
          }

          if (number < 10) {
               operation = 2;
          }

          return number + operation;
     }

     return (
          <div className="wrapperChartReport">
               <div className="headChart">
                    <div className="customMetricChart">
                         {listMetricSelected &&
                              listMetricSelected.map((item, index) => (
                                   <span key={item.label + index}>
                                        <i className="iconColorMetric" style={{ background: item.color }} />
                                        {item.label}
                                        <CIcon
                                             name="iconClose"
                                             width={10}
                                             height={10}
                                             onClick={() => {
                                                  deleteMetricCurrent(item.label);
                                             }}
                                        />
                                   </span>
                              ))}
                         {metricsConversionName.length < 2 && listMetricSelected.length < 1 ? (
                              <CustomDropdown>
                                   <div className={`wrapperIconPlus${listMetricSelected.length > 1 ? ' customDisabled' : ''}`}>
                                        <CIcon name="iconPlus" className="iconPlus" width={13} height={13} />
                                   </div>
                                   <CustomDropdownItem
                                        search
                                        listDropdownItem={getMetricLabel(metricsConversionName)}
                                        itemActive={getMetricLabel(listMetricSelected)}
                                        handleClickListDropdownItem={handleAddListCurrentMetric}
                                   />
                              </CustomDropdown>
                         ) : (
                              metricsConversionName.length > 1 &&
                              listMetricSelected.length < 2 && (
                                   <CustomDropdown>
                                        <div className={`wrapperIconPlus${listMetricSelected.length > 1 ? ' customDisabled' : ''}`}>
                                             <CIcon name="iconPlus" className="iconPlus" width={13} height={13} />
                                        </div>
                                        <CustomDropdownItem
                                             search
                                             listDropdownItem={getMetricLabel(metricsConversionName)}
                                             itemActive={getMetricLabel(listMetricSelected)}
                                             handleClickListDropdownItem={handleAddListCurrentMetric}
                                        />
                                   </CustomDropdown>
                              )
                         )}
                    </div>
                    <div className="actionChart">
                         {/* <CustomDropdown>
                              <span className='dropDownIcon'>
                                   Export <CIcon name='cilArrowThickToBottom' width={16} height={16} />
                              </span>
                              <CustomDropdownItem listDropdownItem={['Excel', 'SVG', 'Another']} handleClickListDropdownItem={handleClick} />
                         </CustomDropdown> */}
                         {/* <span>
                              Export <CIcon name='cilArrowThickToBottom' width={16} height={16} />
                         </span> */}
                         <div className="chartDate">
                              <span className={dateType === 'DAY' ? 'active' : ''} onClick={() => handleDayType('DAY')}>
                                   Day
                              </span>
                              <span className={dateType === 'WEEK' ? 'active' : ''} onClick={() => handleDayType('WEEK')}>
                                   Week
                              </span>
                              <span className={dateType === 'MONTH' ? 'active' : ''} onClick={() => handleDayType('MONTH')}>
                                   Month
                              </span>
                         </div>
                    </div>
               </div>
               {dateRangeConfig.comparePeriod && (
                    <div className="compareChart">
                         <p>
                              <b>
                                   {`${dayjs(newDateRange.selection.startDate).format('MMM D, YYYY')} - ${dayjs(
                                        newDateRange.selection.endDate
                                   ).format('MMM D, YYYY')}`}
                              </b>{' '}
                              :{' '}
                              {listMetricSelected &&
                                   listMetricSelected.map((item, index) => (
                                        <span key={item.label + index}>
                                             <i className="iconColorMetric" style={{ background: item.color }} />
                                             {item.label}
                                        </span>
                                   ))}
                         </p>
                         <p>
                              <b>
                                   {`${dayjs(newDateRange.compare.startDate).format('MMM D, YYYY')} - ${dayjs(newDateRange.compare.endDate).format(
                                        'MMM D, YYYY'
                                   )}`}
                              </b>
                              :
                              {listMetricSelected &&
                                   listMetricSelected.map((item, index) => (
                                        <span key={item.label + index}>
                                             <i className="iconColorMetric" style={{ background: arrColor[index + 2] }} />
                                             {item.label}
                                        </span>
                                   ))}
                         </p>
                    </div>
               )}

               <div className="containerChart" style={{ position: 'relative' }}>
                    {loading && loadingPanel}
                    <Chart className="chart">
                         <ChartTooltip shared={true} render={sharedTooltipRender} />
                         <ChartValueAxis>
                              {vertical &&
                                   vertical.map((item, index) => {
                                        return (
                                             <ChartValueAxisItem
                                                  key={item.name + index}
                                                  name={item.name}
                                                  color={item.color}
                                                  min={0}
                                                  max={manipulateNumber(Math.max(...item.data))}
                                                  labels={{ content: (e) => formatAxisValue(e, item.name) }}
                                             />
                                        );
                                   })}
                         </ChartValueAxis>
                         <ChartLegend position="bottom" orientation="horizontal" visible={false} />
                         <ChartCategoryAxis>
                              <ChartCategoryAxisItem
                                   labels={{
                                        format: 'd MMM, yyyy',
                                        rotation: 'auto',
                                   }}
                                   categories={categories}
                                   axisCrossingValues={[0, categories.length]}
                                   crosshair={{ visible: true, opacity: 0.2, width: 10 }}
                                   maxDivisions={categories.length > 5 ? 6 : 0}
                              />
                         </ChartCategoryAxis>
                         <ChartSeries>
                              {series.map((item, idx) => (
                                   <ChartSeriesItem
                                        key={idx}
                                        axis={item.name}
                                        type={item.type}
                                        data={item.data}
                                        name={item.name}
                                        color={item.color}
                                        width={4}
                                   />
                              ))}
                         </ChartSeries>
                    </Chart>
               </div>
          </div>
     );
}

export default memo(ChartReport);
