import React from 'react';
import {
    CModal,
    CModalBody,
    CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

const LeaveComponentChangePopup = ({ show, onClose, onAccept, children, title, btnKeepEdit, btnAbandon }) => {
    return (
        <div className="confirm-save-popup leave-component">
            <CModal
                show={show}
                onClose={onClose}
                color="primary"
                centered
            >
                <CModalBody className="text-center confirm-save">
                    <div className="confirm-save-icon"><CIcon name="icon-save" height="46" width="46" /></div>
                    <h2>{title}</h2>
                    <div className="confirm-save-content">
                        {children}
                    </div>
                    <div className="position-relative d-inline-block">
                        {
                            <CButton
                                className="btn-primary"
                                onClick={onClose}
                            >
                                {
                                    btnKeepEdit ? btnKeepEdit : "Keep Editing the Table"
                                }
                            </CButton>
                        }
                        <CButton
                            onClick={onAccept}
                            className="btn-light"
                        >
                            {btnAbandon ? btnAbandon : "Abandon My Changes & Leave"}
                        </CButton>
                    </div>
                </CModalBody>
            </CModal>
        </div>
    )
}

export default LeaveComponentChangePopup
