import React from 'react';
import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataSetupGuideDataCompleted, setSetupGuideRoadmap, setShowRoadMap, setShowSetupGuidePopup } from '../../../actions/subscriber';
import { API_CLIENT_ACCOUNT_SETUP_GUIDE_DATA, ROADMAP_GUIDESTEPS } from '../../../constants';
import { callTokenApi } from '../../../apiCaller';
import { toastError } from '../../../utils';

const ToursAndConfigure = () => {
     const dispatch = useDispatch();
     const currentStepRoadmap = useSelector((state) => state.subscriber.set_setup_guide_roadmap);
     const dataStepCompleted = useSelector((state) => state.subscriber.setupGuideDataCompleted);
     const activeAccountId = useSelector((state) => state.subscriber.activeAccount.id);
     const setShowRoadmapMininize = useSelector((state) => state.subscriber.setShowRoadmapMininize);
     const activeListeners = useSelector((state) => state.subscriber.activeListeners);
     const isCustomForm = activeListeners.find((item) => item.alias === 'custom-html-forms');

     const onCLickSetupGuide = () => {
          dispatch(setShowRoadMap(true));
          localStorage.removeItem('showAnimationRoadmap');
          if (currentStepRoadmap < 0 || !dataStepCompleted) {
               callTokenApi(`${API_CLIENT_ACCOUNT_SETUP_GUIDE_DATA}/${activeAccountId}`, 'GET').then((response) => {
                    if (response.status === 200) {
                         const result = response.data.data || {};
                         const completeStepArr = result['completedSteps'];
                         let currentStep = 0;

                         if (completeStepArr && Object.keys(completeStepArr).length > 0) {
                              for (let key in completeStepArr) {
                                   if (ROADMAP_GUIDESTEPS[key] && ROADMAP_GUIDESTEPS[key].orderNumber > currentStep) {
                                        currentStep = ROADMAP_GUIDESTEPS[key].orderNumber;
                                   }
                              }
                              if (!isCustomForm && currentStep === 1) {
                                   currentStep = currentStep + 1;
                              }
                              dispatch(setSetupGuideRoadmap(currentStep + 1));
                         } else {
                              dispatch(setSetupGuideRoadmap(0));
                         }

                         dispatch(setDataSetupGuideDataCompleted(result));
                    } else {
                         toastError(response);
                    }
               });
          } else {
               dispatch(setSetupGuideRoadmap(currentStepRoadmap));
          }

          dispatch(setShowSetupGuidePopup(true));
     };
     return (
          <>
               {setShowRoadmapMininize ? (
                    <CButton className="btn-tour box-minimize" onClick={onCLickSetupGuide}>
                         <span className="text">QUICKSTART</span>
                         <span className={`pulse-status online`}></span>
                    </CButton>
               ) : (
                    <CButton className={`btn-tour`} onClick={onCLickSetupGuide}>
                         <span className="text">QUICKSTART</span>
                         <CIcon name="icon-idea-light" />
                    </CButton>
               )}
          </>
     );
};

export default React.memo(ToursAndConfigure);
