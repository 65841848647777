import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
     CCard,
     CSwitch
} from '@coreui/react';
import classNames from 'classnames';

import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import { API_CLIENT_SAVE_CARD_TO_UPGRADE } from '../../../../../constants';
import { roundTo2DecimalPlaces, toastError } from '../../../../../utils';
import { PaymentInfo } from '../../../../general/payment';
import { setAccounts } from '../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../apiCaller';
import { setPackageToAccount } from '../../../../../helpers/cms/subscriber';

const AccountPayment = () => {
     const dispatch = useDispatch();
     const { stepsData, account, setAccount, setCurrentStep } = useContext(CreateAccountContext);
     const [annualBilling, setAnnualBilling] = useState(false);
     const accounts = useSelector(state => state.subscriber.accounts);
     const packageData = stepsData.package;

     const {
          pricePerMonth,
          pricePerYear,
          amountSavedOnAnnual,
          percentSavedOnAnnual
     } = packageData;

     const toggleAnnualBilling = () => {
          setAnnualBilling(!annualBilling);
     }

     const onBackBtnClicked = () => {
          setCurrentStep(null, CHANGE_STEP_TYPE.subStepPrev);
     }

     const onSubmit = (values, { resetForm }) => {
          const cardNumber = values.stripeCardNumber.replace(/_/gi, '').replace(/ /gi, '');
          const stripeCardExpiration = values.stripeCardExpiration;
          const expiration = stripeCardExpiration.split('/');
          const expMonth = expiration[0];
          const expYear = expiration[1];

          const data = {
               accountId: account.id,
               packageId: packageData.id,
               name: values.stripeCardName,
               cardNumber,
               expMonth,
               expYear,
               cvc: values.stripeCardCVC,
               annualBilling,
               shouldPublish: Object.keys(stepsData.allSelectedListeners).length === 0 // Can rely on ConfiguringAccount to publish later
          };

          window._noPublishToast = true; // Don't toast message in ThePusher
          callTokenApi(API_CLIENT_SAVE_CARD_TO_UPGRADE, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         let foundAccIndex = accounts.findIndex(acc => acc.id === account.id);

                         if (foundAccIndex > -1) {
                              let newAccounts = [...accounts];
                              newAccounts[foundAccIndex] = setPackageToAccount(account, packageData);

                              dispatch(setAccounts(newAccounts));
                              setAccount(newAccounts[foundAccIndex]);
                         }

                         setCurrentStep(STEPS.configuringAccount);
                    } else {
                         resetForm();
                         toastError(response);
                    }
               })
     }

     return (
          <CreateAccountBody onBackBtnClicked={onBackBtnClicked} hideStepProgress>
               <CCard className="choose-how-to-pay choose-pay-header">
                    <h3 className="text-choose">Choose how to pay</h3>
                    <h6 className="text-des">This step takes less than a minute.</h6>
                    <div className="sidebar-popup billing-infomation choose-pay-body">
                         <div className="sidebar-header">
                              <div className="annual-billing">
                                   <CSwitch
                                        className='mb-0'
                                        color={annualBilling ? 'success' : 'light'}
                                        checked={annualBilling}
                                        size="lg"
                                        tabIndex="0"
                                        shape="pill"
                                        onChange={toggleAnnualBilling}
                                   />
                                   <span className="annual-billing-text">Annual Billing</span>
                                   <span className={classNames('cost-per-year', { 'annually': annualBilling })}>
                                        ${annualBilling ? `${roundTo2DecimalPlaces(pricePerYear / 12).toLocaleString('en-US')}` : `${pricePerMonth.toLocaleString('en-US')}`}
                                   </span>
                              </div>
                              {
                                   annualBilling ? (
                                        <div className="description-price">
                                             <span className="annual-description">
                                                  Save {percentSavedOnAnnual}% (${amountSavedOnAnnual.toLocaleString('en-US')}) with annual billing.
                                             </span>
                                             <span className="float-right">
                                                  ${pricePerYear.toLocaleString('en-US')}/yr
                                             </span>
                                        </div>
                                   ) : (
                                        <span className="annual-description">
                                             Save {percentSavedOnAnnual}% (${amountSavedOnAnnual.toLocaleString('en-US')}) with annual billing.
                                        </span>
                                   )
                              }
                              <PaymentInfo
                                   onSubmit={onSubmit}
                                   submitBtnText={'Upgrade'}
                                   isUpgrade
                                   annualBilling={annualBilling}
                                   pricePerYear={pricePerYear}
                                   pricePerMonth={pricePerMonth}
                                   firstUpgrade
                              />
                         </div>
                    </div>
               </CCard>
          </CreateAccountBody>
     )
}

export default AccountPayment
