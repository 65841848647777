import {
    CButton, CCard,
    CCardBody, CCardHeader, CCol, CForm, CFormGroup, CInput, CInvalidFeedback, CLabel, CRow,
    CTextarea
} from '@coreui/react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { setFetchRule, setRuleHaveEditting, setViewedRule } from '../../../../../../actions/common';
import { setSubscriberState } from '../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_RULE_UPDATE, TYPE_SHOW_UNSAVE_CHANGE } from '../../../../../../constants';
import { toastError } from '../../../../../../utils';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { RuleSettingContext } from '../EditLookupSetting';

const EditStep2 = ({ stepsData, setStepsData }) => {
    const dispatch = useDispatch();
    const { activeStep, setActiveStep, setRule, activeListener } = useContext(RuleSettingContext);

    const rulesLoading = useSelector(state => state.subscriber.loadings.allRules);
    const customVariableRules = useSelector((state) => state.subscriber.customVariableRules);
    const [tempData, setTempData] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [saveChangeModal, setSaveChangeModal] = useState(false);
    const rules = customVariableRules && activeListener ? customVariableRules.filter(rule => rule.listenerId === activeListener.id) : [];
    const initialValues = {
        name: stepsData.name,
        description: stepsData.description
    };

    const handleHasChange = () => {
        dispatch(setRuleHaveEditting({ showLv2: true, typeLv2: TYPE_SHOW_UNSAVE_CHANGE.EDIT_LOOKUP }));
    };

    const validationSchema = useCallback(() => {
        let ruleNames = rules.map(rule => rule.name);

        if (stepsData.name) {
            let ruleNameIndex = -1;

            ruleNames.some((ruleName, index) => {
                if (stepsData.name === ruleName) {
                    ruleNameIndex = index;
                    return true;
                }

                return false;
            })

            if (ruleNameIndex !== -1) {
                ruleNames.splice(ruleNameIndex, 1);
            }
        }

        return (
            Yup.object().shape({
                name: Yup.string()
                    .trim()
                    .required('Name is required')
                    .max(200, 'Name cannot be more than 200 characters')
                    .notOneOf(ruleNames, 'Existed name, please choose another name')
                ,
            })
        )
    }, [rules, stepsData.name])

    const onSubmit = ({ name, description }, { setSubmitting }) => {
        setSubmitting(false);
        const data = {
            name: name.trim(),
            description: description.trim()
        }

        if (JSON.stringify(initialValues) === JSON.stringify(data)) {
            setActiveStep(3);
        } else {
            setTempData(data);
            setSaveChangeModal(true);
        }
    }

    const onAccept = () => {
        setSaveLoading(true);
        const newStepsData = { ...stepsData, ...tempData };

        callTokenApi(API_CLIENT_RULE_UPDATE, 'POST', newStepsData)
            .then((response) => {
                setSaveLoading(false);
                setSaveChangeModal(false);

                if (response.status === 200) {
                    if (customVariableRules) {
                        let newRules = [...customVariableRules].map(rule => {
                            if (rule.id === newStepsData.id) {
                                rule = { ...rule, name: newStepsData.name, description: newStepsData.description }
                            }
                            return rule;
                        });
                        dispatch(setSubscriberState({
                            customVariableRules: newRules,
                            initialCustomVariableRules: newRules
                        }));
                    }
                    setRule(newStepsData);
                    setStepsData(newStepsData);
                    dispatch(setViewedRule(null, true));
                    toast.success('Successfully update rule');
                    dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
                    dispatch(setFetchRule());
                    setActiveStep(3);
                } else {
                    toastError(response);
                }
            })
    }

    return (
        <CCard className={`cvr-step-2 ${activeStep !== 2 ? 'difference-step' : 'cvr-step-card'}`}>
            <CCardHeader>
                {activeStep !== 2 ? (
                    <div className="rule-step d-inline-flex justify-content-between w-100">
                        <h5 className="mb-0 inactive">Step 2: Rule Name & Description</h5>
                        {
                            stepsData.name && (
                                <div className="d-inline-flex align-items-center">
                                    <span className="given-data mr-3">{stepsData.name}</span>
                                    <CButton className="btn-edit" onClick={() => setActiveStep(2)}>
                                        Edit
                                    </CButton>
                                </div>
                            )
                        }
                    </div>
                ) : (
                    <h5>Step 2: Rule Name & Description</h5>
                )}
            </CCardHeader>
            {
                activeStep === 2 && (
                    <CCardBody>

                        {
                            rulesLoading ? (
                                <CenterSpinner />
                            ) : (
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                >
                                    {
                                        ({
                                            values,
                                            errors,
                                            handleChange,
                                            handleSubmit,
                                            setErrors,
                                            isSubmitting,
                                        }) => (
                                            <CForm onSubmit={handleSubmit} noValidate>
                                                <CRow>
                                                    <CCol md="7">
                                                        <CFormGroup>
                                                            <CLabel htmlFor="name">Name this rule set</CLabel>
                                                            <CInput
                                                                id="name"
                                                                placeholder="Rule set name"
                                                                invalid={!!errors.name}
                                                                disabled={isSubmitting}
                                                                value={values.name}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    if (errors) {
                                                                        setErrors({});
                                                                    }
                                                                    handleHasChange();
                                                                }}
                                                                data-lpignore="true"
                                                            />
                                                            <CInvalidFeedback>{errors.name}</CInvalidFeedback>
                                                        </CFormGroup>
                                                    </CCol>
                                                    <CCol md="12">
                                                        <CFormGroup >
                                                            <CLabel htmlFor="description">Describe this rule set</CLabel>
                                                            <CTextarea
                                                                id="description"
                                                                placeholder="Enter a description for your own reference..."
                                                                disabled={isSubmitting}
                                                                value={values.description}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    handleHasChange();
                                                                }}
                                                                data-lpignore="true"
                                                            />
                                                        </CFormGroup>
                                                    </CCol>
                                                </CRow>
                                                <CButton
                                                    type="submit"
                                                    className="px-4"
                                                    color="primary"
                                                >
                                                    {`SAVE & NEXT`}
                                                </CButton>
                                            </CForm>
                                        )
                                    }
                                </Formik>
                            )
                        }
                    </CCardBody >
                )
            }
            <ConfirmSaveChange
                show={saveChangeModal}
                onClose={() => setSaveChangeModal(false)}
                onAccept={onAccept}
                title="Are You Sure You Want to Save This Change?"
                isLoading={saveLoading}
            >
                You are about to update the name/description of this rule. This will not effect any other rule settings.
            </ConfirmSaveChange>
        </CCard >
    )
}

EditStep2.propTypes = {
    stepsData: PropTypes.object,
    setStepsData: PropTypes.func
}

export default EditStep2
