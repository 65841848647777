import React, { useState, useCallback } from 'react';
import { CButton, CCard, CImg, CLink } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import { callTokenApi } from '../../../apiCaller';
import { API_CLIENT_BUY_COURSE, API_CLIENT_OFFICE_HOURS_BUY_CREDITS, API_USER_UPDATE_CARD, DEFAULT_DATE_TIME_FORMAT } from '../../../constants';
import { formatDate, toastError } from '../../../utils';
import Waiting from '../../../components/general/Loadings/Waiting';
import PaymentInfo from './PaymentInfo';
import { getStripeCardImage } from '../../../helpers/cms/subscriber';
import { toast } from 'react-toastify';
import { setUser } from '../../../actions/common';
import { setBuyOfficeHours } from '../../../actions/subscriber';

const Payment = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.theme.user);
    const officeHour = useSelector(state => state.subscriber.officeHour);
    const buyOfficeHours = useSelector(state => state.subscriber.buyOfficeHours);
    const {
        stripeCardLast4,
        stripeCardType
    } = user;
    const accountHasPayment = (stripeCardType && stripeCardLast4);
    const masterclassCourse = useSelector(state => state.subscriber.masterclassCourse);
    const [showPaymentForm, setShowPaymentForm] = useState(!accountHasPayment);
    const [isLoading, setIsLoading] = useState(false);

    const toggleShowPaymentForm = useCallback(() => {
        setShowPaymentForm(state => !state);
    }, [])

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        const cardNumber = values.stripeCardNumber.replace(/_/gi, '').replace(/ /gi, '');
        const stripeCardExpiration = values.stripeCardExpiration;
        const expiration = stripeCardExpiration.split('/');
        const expMonth = expiration[0];
        const expYear = expiration[1];

        const data = {
            name: values.stripeCardName,
            cardNumber,
            expMonth,
            expYear,
            cvc: values.stripeCardCVC
        }

        callTokenApi(`${API_USER_UPDATE_CARD}`, 'PUT', data)
            .then(response => {
                if (response.status === 200) {
                    dispatch(setUser({ ...user, ...response.data.user }));
                    resetForm();
                    toggleShowPaymentForm();
                    toast.success('Payment has been updated successfully!');
                    handlePurchase();
                } else {
                    setSubmitting(false);
                    toastError(response);
                }
            })
    }

    const handlePurchase = () => {
        setIsLoading(true);

        if (buyOfficeHours) {
            callTokenApi(API_CLIENT_OFFICE_HOURS_BUY_CREDITS, 'POST', { officeHourId: officeHour.id })
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setUser({ ...user, numberOfCredits: user.numberOfCredits + 1 }));
                        toast.success('Successfully bought 1 office hour credit!');
                    } else {
                        toastError(response);
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                    dispatch(setBuyOfficeHours(false));
                })
        } else {
            const datePurchase = new Date();
            const datePurchaseString = formatDate(datePurchase, DEFAULT_DATE_TIME_FORMAT);

            callTokenApi(`${API_CLIENT_BUY_COURSE}/${masterclassCourse.id}`, 'POST', { datePurchase: datePurchaseString })
                .then(response => {
                    if (response.status === 200) {
                        window.location.reload();
                    } else {
                        setIsLoading(false);
                        toastError(response);
                    }
                })
        }
    }

    return (
        <>
            <div className="payment">
                {
                    showPaymentForm ? (
                        <>
                            {
                                accountHasPayment && (
                                    <div className="use-current-card">
                                        <CLink
                                            onClick={toggleShowPaymentForm}>
                                            <i className="fal fa-angle-left"></i>
                                            Use card on file
                                        </CLink>
                                    </div>
                                )
                            }
                            <PaymentInfo onSubmit={onSubmit} submitBtnText="Gain Access now" />
                        </>
                    ) : (
                        <>
                            <CCard className="current-payment-method-wrapper">
                                <p className="payment-method-title">Payment Method</p>
                                <div className="justify-content-between current-payment-card">
                                    <div className="payment-hidden">
                                        {getStripeCardImage(stripeCardType)}
                                        <div className="hidden-payment-number">
                                            <div className="hidden-circle"></div>
                                            <div className="hidden-circle"></div>
                                            <div className="hidden-circle"></div>
                                            <div className="hidden-circle"></div>
                                        </div>
                                        <div className="hidden-payment-number">
                                            <div className="hidden-circle"></div>
                                            <div className="hidden-circle"></div>
                                            <div className="hidden-circle"></div>
                                            <div className="hidden-circle"></div>
                                        </div>
                                        <div className="hidden-payment-number">
                                            <div className="hidden-circle"></div>
                                            <div className="hidden-circle"></div>
                                            <div className="hidden-circle"></div>
                                            <div className="hidden-circle"></div>
                                        </div>
                                        <div className="payment-number">{stripeCardLast4}</div>
                                    </div>
                                    <CButton
                                        className="btn-change"
                                        onClick={toggleShowPaymentForm}
                                    >
                                        Change
                                    </CButton>
                                </div>
                            </CCard>
                            <div className="button-wrapper">
                                <CButton className="purchase-btn" color="success" onClick={handlePurchase} disabled={isLoading}>
                                    <Waiting isLoading={isLoading}>
                                        Complete Purchase
                                    </Waiting>
                                </CButton>
                            </div>
                        </>
                    )
                }
            </div>
            {!buyOfficeHours && (
                <div className="box-gift">
                    <CImg src="/assets/images/giftbox.png" />
                    <h6>You’ll earn $495 in ListenLayer™ credits</h6>
                </div>
            )}
        </>
    )
}

export default Payment
