import {
     CButton,
     CModal,
     CModalBody,
     CModalFooter
} from '@coreui/react';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fetchAllRulesRequest, setActiveListener } from '../../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../../apiCaller';
import { API_CLIENT_RULE, RULE_GROUP } from '../../../../../../../constants';
import { toastError } from '../../../../../../../utils';
import CenterSpinner from '../../../../../../general/Loadings/CenterSpinner';
import FormRuleTable from './FormRuleTable';
const EditFormRule = ({ rule, initialRule }) => {
     const initialStepsData = cloneDeep(initialRule);
     const [stepsData, setStepsData] = useState(rule);
     return (
          <>
               <FormRuleTable stepsData={stepsData} setStepsData={setStepsData} initialStepsData={initialStepsData} />
          </>
     );
};
export const FormRuleTableContext = React.createContext({});

const EditFormRuleTable = ({ id }) => {
     const dispatch = useDispatch();
     const redirectRules = useSelector((state) => state.subscriber.redirectRules);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const activeListener = useSelector((state) => state.subscriber.activeListener);
     const editingRuleReload = useSelector((state) => state.theme.editingRuleReload);
     const formCategoriesRules = useSelector((state) => state.subscriber.formCategoriesRules);
     const [rule, setRule] = useState();
     const [modal, setModal] = useState(false);
     const [ruleType, setRuleType] = useState('');
     const [activeStep, setActiveStep] = useState(5);
     const [initialRule, setInitialRule] = useState();
     const [fetchRuleLoading, setFetchRuleLoading] = useState();
     const fetchRule = () => {
          setFetchRuleLoading(true);
          callTokenApi(`${API_CLIENT_RULE}${id}`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { rule } = response.data;

                         if (rule.accountId !== activeAccount.id) {
                              window.location.href = '/';
                         }
                         setRuleType(rule.type);
                         setRule(rule);
                         setInitialRule(cloneDeep(rule));

                         const currentListener = accountListeners ? accountListeners.find((listener) => listener.id === rule.listenerId) : null;
                         if (currentListener) {
                              dispatch(setActiveListener(currentListener));
                         }
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setFetchRuleLoading(false);
               });
     };
     useEffect(fetchRule, [editingRuleReload, accountListeners]);

     const fetchAllRules = () => {
          if (!formCategoriesRules) {
               dispatch(fetchAllRulesRequest(activeAccount.id, RULE_GROUP.DATA_LAYER));
          }
     };
     useEffect(fetchAllRules, []);

     return (
          <>
               {fetchRuleLoading ? (
                    <CenterSpinner />
               ) : (
                    <div className="rule-detail">
                         <div className="cvr-create-new custom-rule">
                              {redirectRules.status && <Redirect to={redirectRules.to} />}
                              {rule && (
                                   <>
                                        {ruleType && (
                                             <>
                                                  <FormRuleTableContext.Provider
                                                       value={{
                                                            activeStep,
                                                            setRule,
                                                            setActiveStep,
                                                            fetchRule,
                                                            activeListener,
                                                       }}
                                                  >
                                                       <EditFormRule rule={rule} initialStepsData={initialRule} />
                                                  </FormRuleTableContext.Provider>
                                             </>
                                        )}
                                        <CModal show={modal} centered={true} onClose={() => setModal(false)}>
                                             <CModalBody className="text-center">
                                                  <h3 className="mb-4">Warning! This Will Change Your Rule</h3>
                                                  <p>
                                                       You are about to change your rule type. Doing this will reset and remove all settings inside
                                                       this rule other than the name and description.
                                                  </p>
                                                  <p>Are you sure you want to do this? You cannot undo this once you publish it!</p>
                                             </CModalBody>
                                             <CModalFooter className="text-center justify-content-center">
                                                  <CButton color="primary" className="mr-3 text-uppercase" onClick={() => setModal(false)}>
                                                       Cancel
                                                  </CButton>
                                                  <CButton color="secondary" onClick={() => {}}>
                                                       Save Change and Reset Rule
                                                  </CButton>
                                             </CModalFooter>
                                        </CModal>
                                   </>
                              )}
                         </div>
                    </div>
               )}
          </>
     );
};

export default EditFormRuleTable;
