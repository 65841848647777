import React from 'react';

const BaseTags = ({ items, onClick }) => {
     const handleClick = () => {
          if (typeof onClick === 'function') {
               onClick();
          }
     };

     if (!items || items.length === 0) {
          return <></>;
     }

     return (
          <div className="base-tags">
               {items.map((item, index) => {
                    return (
                         <span index={index} className="tag-item d-flex flex-wrap align-items-center" onClick={handleClick}>
                              {item}
                         </span>
                    );
               })}
          </div>
     );
};

export default BaseTags;
