import React, { useContext } from 'react';
import { CButton, CForm, CFormGroup, CCard, CCardBody, CInput } from '@coreui/react';
import { Formik } from 'formik';

import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';

const VerifyWithInaccessibleDomain = () => {
    const { stepsData, setStepsData, setCurrentStep } = useContext(CreateAccountContext);
    const domainPrefix = stepsData.domain + '/';

    const onSubmit = ({ urlPath }) => {
        setStepsData({ domainUrlPath: urlPath });
        setCurrentStep(STEPS.verifyingScript);
    };

    return (
        <CreateAccountBody>
            <Formik
                initialValues={{ urlPath: '' }}
                onSubmit={onSubmit}
            >
                {({ values, errors, handleChange, handleSubmit }) => (
                    <>
                        <CCard className="account-script cannot-verify alt">
                            <p className="number-of-step">3 of 3 steps</p>
                            <CCardBody>
                                <CForm onSubmit={handleSubmit}>
                                    <CFormGroup>
                                        <h3>We can’t verify your script</h3>
                                        <p>
                                            The website domain you entered was inaccessible, so we cannot easily verify your script. Please provide us
                                            with a child page on the website to verify or skip verification.
                                        </p>
                                        <div className="domain-input">
                                            <label>{domainPrefix}</label>
                                            <CInput
                                                name="urlPath"
                                                placeholder="enter a URL path here"
                                                invalid={!!errors.urlPath}
                                                value={values.urlPath}
                                                onChange={handleChange}
                                                data-lpignore="true"
                                            />
                                        </div>
                                        <CButton type="submit" className="btn-save alt" disabled={!values.urlPath}>
                                            VERIFY WITH THIS PAGE
                                        </CButton>
                                    </CFormGroup>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </>
                )}
            </Formik>
        </CreateAccountBody>
    );
};

export default VerifyWithInaccessibleDomain;
