import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import CenterSpinner from '../../../components/general/Loadings/CenterSpinner';
import { setFlexibleMultiModal } from '../../../actions/common';
import { COMPONENT_NAME } from '../../../constants';
import ContentChild from './ContentChilds';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { resizeSidebarModal } from '../../../utils';

const SidebarMultiModal = () => {
     const dispatch = useDispatch();
     const [offsetWidth, setOffsetWidth] = useState(window.innerWidth);
     const flexibleMultiModal = useSelector((state) => state.theme.flexibleMultiModal);
     const accountsLoading = useSelector((state) => state.subscriber.loadings.accounts);
     const removeScroll = [COMPONENT_NAME.LISTENER_LIBRARY, COMPONENT_NAME.DESTINATION_LIBRARY].includes(flexibleMultiModal.component);
     const [listData, setListData] = useState(cloneDeep(flexibleMultiModal));
     const splitListData = listData.slice(1, listData.length);

     const getOffsetWidth = (osWidth) => {
          return (osWidth / 5) * 3.5;
     };

     useEffect(() => {
          const withNotFullScreen = { width: getOffsetWidth(window.innerWidth) };
          const withFullScreen = { width: window.innerWidth };
          let newListData = [];
          const listModal = cloneDeep(flexibleMultiModal);
          for (let i = 0; i < listModal.length; i++) {
               listModal[i].style = {
                    maxWidth: getOffsetWidth(offsetWidth) + 'px',
               };
               listModal[i].isFullScreen = false;
               if (listModal[i].show) {
                    if (listModal[i - 1] && listModal[i - 1].show) {
                         listModal[i - 1].isFullScreen = true;
                         listModal[i - 1].style = { maxWidth: withFullScreen.width + 'px' };
                         listModal[i].style = { maxWidth: withNotFullScreen.width + 'px' };
                    } else {
                         listModal[i].style = { maxWidth: withNotFullScreen.width + 'px' };
                    }
                    // document.body.classList.add('ll-disable-scroll');
               } else {
                    listModal[i].style = { maxWidth: withNotFullScreen.width + 'px' };
                    // document.body.classList.remove('ll-disable-scroll');
               }
               newListData.push(listModal[i]);
          }
          setListData(newListData);
     }, [flexibleMultiModal]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (flexibleMultiModal[0].show) {
               document.body.classList.add('ll-disable-scroll');
          } else {
               document.body.classList.remove('ll-disable-scroll');
          }
     }, [flexibleMultiModal[0].show]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          const defaultSize = !listData[0].isFullScreen ? getOffsetWidth(window.innerWidth) : null;
          resizeSidebarModal(defaultSize);
     }, [listData[0].show, listData[0].isFullScreen]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleClickFullScreenButton = (data, isFullScreen) => {
          const newListData = listData.map((item) => {
               const withNotFullScreen = { width: getOffsetWidth(window.innerWidth) };
               const withFullScreen = { width: window.innerWidth };
               if (item.component === data.component) {
                    return {
                         ...item,
                         style: isFullScreen ? { maxWidth: withFullScreen.width + 'px' } : { maxWidth: withNotFullScreen.width + 'px' },
                         isFullScreen: isFullScreen,
                    };
               }
               return item;
          });
          setListData(newListData);
     };

     const toggleModal = (data) => {
          const itemModal = listData.find((item) => item.component === data.component);
          if (itemModal && itemModal.component) {
               dispatch(
                    setFlexibleMultiModal({
                         show: false,
                         component: itemModal.component,
                         attributes: {},
                    })
               );
          }
     };

     useLayoutEffect(() => {
          const withNotFullScreen = { width: getOffsetWidth(window.innerWidth) };
          const withFullScreen = { width: window.innerWidth };
          const listModal = cloneDeep(flexibleMultiModal);
          let newListData = [];
          function updateSize() {
               setOffsetWidth(window.innerWidth);
               if (offsetWidth !== window.innerWidth) {
                    for (let i = 0; i < listModal.length; i++) {
                         if (listModal[i].show) {
                              if (listModal[i - 1] && listModal[i - 1].show) {
                                   listModal[i - 1].style = { maxWidth: withFullScreen.width + 'px' };
                                   if (listModal[i].isFullScreen) {
                                        listModal[i].style = { maxWidth: withFullScreen.width + 'px' };
                                   } else {
                                        listModal[i].style = { maxWidth: withNotFullScreen.width + 'px' };
                                   }
                                   listModal[i].style = { maxWidth: withFullScreen.width + 'px' };
                              } else {
                                   if (listModal[i].isFullScreen) {
                                        listModal[i].style = { maxWidth: withFullScreen.width + 'px' };
                                   } else {
                                        listModal[i].style = { maxWidth: withNotFullScreen.width + 'px' };
                                   }
                              }
                         } else {
                              listModal[i].style = { maxWidth: withNotFullScreen.width + 'px' };
                         }
                         newListData.push(listModal[i]);
                    }
               }
          }

          window.addEventListener('resize', updateSize);
          updateSize();
          return () => window.removeEventListener('resize', updateSize);
     }, [flexibleMultiModal[0].show, window.innerWidth]); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <div className={`sidebar-modal ll-flexible-modal--animated`}>
               <div className="ll-flexible-modal-overlay" onClick={() => toggleModal(listData[0])}></div>
               <div style={listData[0].style} className={`ll-flexible-modal ${listData[0].isFullScreen ? 'full-screen' : ''}`}>
                    <div className="flexible-resize"></div>
                    <div className="ll-sheet-wrapper">
                         <div className="flexible-modal-header d-flex align-items-center justify-content-between">
                              {listData[0].isFullScreen ? (
                                   <div className="d-flex align-items-center justify-content-between">
                                        <CIcon className="c-sidebar-brand-full" name="logo-dark" height={35} />
                                        <p className="full-screen-message">
                                             You are viewing this page on full screen. Exit full screen anytime to return back to normal view.
                                        </p>
                                   </div>
                              ) : (
                                   <CButton className="btn-close d-flex align-items-center" onClick={() => toggleModal(listData[0])}>
                                        <CIcon name="icon-close" />
                                        Close
                                   </CButton>
                              )}
                              <CButton
                                   className="btn-full-screen d-flex align-items-center"
                                   onClick={() => handleClickFullScreenButton(listData[0], listData[0].isFullScreen ? false : true)}
                              >
                                   {listData[0].isFullScreen ? (
                                        <>
                                             Exit full screen
                                             <CIcon name="icon-exit-full-screen" />
                                        </>
                                   ) : (
                                        <>
                                             Full screen
                                             <CIcon name="icon-full-screen" />
                                        </>
                                   )}
                              </CButton>
                         </div>
                         <div className={`flexible-modal-body ${removeScroll ? 'remove-scroll' : ''}`}>
                              <div className="flexible-modal-content">
                                   {accountsLoading ? (
                                        <CenterSpinner />
                                   ) : (
                                        <>{<ContentChild component={listData[0].component} attributes={listData[0].attributes} />}</>
                                   )}
                              </div>
                         </div>
                    </div>
               </div>

               {listData.length > 0 &&
                    splitListData.map((item, index) => {
                         const isFullScreen = item.isFullScreen ? false : true;
                         return (
                              <React.Fragment key={index}>
                                   <div className="ll-flexible-modal-overlay" onClick={() => toggleModal(item)}></div>
                                   <div style={item.style} className={`ll-flexible-modal ${item.isFullScreen ? 'full-screen' : ''}`}>
                                        <div className="flexible-resize"></div>
                                        <div className="ll-sheet-wrapper">
                                             <div className="flexible-modal-header d-flex align-items-center justify-content-between">
                                                  {item.isFullScreen ? (
                                                       <div className="d-flex align-items-center justify-content-between">
                                                            <CIcon className="c-sidebar-brand-full" name="logo-dark" height={35} />
                                                            <p className="full-screen-message">
                                                                 You are viewing this page on full screen. Exit full screen anytime to return back to
                                                                 normal view.
                                                            </p>
                                                       </div>
                                                  ) : (
                                                       <CButton className="btn-close d-flex align-items-center" onClick={() => toggleModal(item)}>
                                                            <CIcon name="icon-close" />
                                                            Close
                                                       </CButton>
                                                  )}
                                                  <CButton
                                                       className="btn-full-screen d-flex align-items-center"
                                                       onClick={() => handleClickFullScreenButton(item, isFullScreen)}
                                                  >
                                                       {item.isFullScreen ? (
                                                            <>
                                                                 Exit full screen
                                                                 <CIcon name="icon-exit-full-screen" />
                                                            </>
                                                       ) : (
                                                            <>
                                                                 Full screen
                                                                 <CIcon name="icon-full-screen" />
                                                            </>
                                                       )}
                                                  </CButton>
                                             </div>
                                             <div className={`flexible-modal-body ${removeScroll ? 'remove-scroll' : ''}`}>
                                                  <div className="flexible-modal-content">
                                                       {accountsLoading ? (
                                                            <CenterSpinner />
                                                       ) : (
                                                            <>{<ContentChild component={item.component} attributes={item.attributes} />}</>
                                                       )}
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </React.Fragment>
                         );
                    })}
               {listData.length > 1 && listData[listData.length - 1].show && (
                    <>
                         <div className="ll-flexible-modal-overlay"></div>
                         <div className={`ll-flexible-modal`}></div>
                    </>
               )}
          </div>
     );
};
export default SidebarMultiModal;
