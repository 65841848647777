import React from 'react';
import {
	CModal,
	CModalBody,
	CButton,
} from '@coreui/react';
import PropTypes from 'prop-types';

const PopupPreview = ({ show, onClose, onAccept, children, acceptBtnText = 'Continue', goBackBtnText = 'go back' }) => {
	return (
		<div className="confirm-save-popup begin-test">
			<CModal
				show={show}
				onClose={onClose}
				color="primary"
				centered
			>
				<CModalBody className="text-center confirm-save">
					{children}
					<div className="position-relative d-inline-block">
						<CButton className="btn-info" onClick={() => onAccept()}>{acceptBtnText}</CButton>
						<div className='d-block'>
							<div className="btn-cancel" onClick={onClose}>{goBackBtnText}</div>
						</div>
					</div>
				</CModalBody>
			</CModal>
		</div>
	)
}

PopupPreview.propTypes = {
	show: PropTypes.bool,
	onClose: PropTypes.func,
	onAccept: PropTypes.func,
	children: PropTypes.node,
	acceptBtnText: PropTypes.string,
	goBackBtnText: PropTypes.string,
}

export default PopupPreview
