import React, { useEffect, useState, useCallback } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector, useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { CButton, CModal, CModalBody, CCol } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { setShowUpgradeAccountPopup, setShowUpgradeAccountSchedulePopup } from '../../../../../actions/subscriber';
import PaymentMethod from './PaymentMethod';
import ActionRequired from './ActionRequired';
// import { tryParseJSON } from '../../../../../utils';
import { useActiveReportingFeatures, useActivePopulateFeatures } from '../../../../../helpers/customHooks';
import PublishBeforeDowngradePopup from '../../../../general/popup/PublishBeforeDowngradePopup';
import PackageItem from './PackageItem';
import UpgradeRequired from './UpgradeRequired';
import classNames from 'classnames';
import PopupNotifyDataLayerChanges from '../../../../general/popup/PopupNotifyDataLayerChanges';
import { CUSTOM_PRICING_PACKAGE } from '../../../../../constants';
import TalkToSales from './TalkToSales';
import LargeSandBox from './LargeSandbox';
import VariableInRules from '../../../../general/popup/VariableInRules';

const UpgradeAccount = () => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const showUpgradeAccountPopup = useSelector((state) => state.subscriber.showUpgradeAccountPopup);
     const showUpgradeAccountSchedulePopup = useSelector((state) => state.subscriber.showUpgradeAccountSchedulePopup);
     const packages = useSelector((state) => state.subscriber.packages);
     const isCustomerSupportLoginAs = useSelector((state) => state.subscriber.isCustomerSupportLoginAs);
     const versionPublish = useSelector((state) => state.subscriber.versionPublish);
     const activeReportingFeatures = useActiveReportingFeatures();
     const activePopulateFeatures = useActivePopulateFeatures();
     const [selectedPackage, setSelectedPackage] = useState(false);
     const [showPayment, setShowPayment] = useState(false);
     const [checkDowngrade, setCheckDowngrade] = useState(true);
     const [showPublishBeforeDowngradePopup, setShowPublishBeforeDowngradePopup] = useState(false);
     const [showUpgradeRequired, setShowUpgradeRequired] = useState(false);
     const [showPopupNotify, setShowPopupNotify] = useState(false);
     const [showTalkToSales, setShowTalkToSales] = useState(false);
     const [tempPackage, setTempPackage] = useState(null);
     const [changePackageText, setChangePackageText] = useState('Upgrade');
     const { isScheduleUpgrade: isScheduleDemo, packageIsCustom } = activeAccount;
     const [showPopupLargeSandbox, setShowPopupLargeSandbox] = useState(false);
     const [showErrorModal, setShowErrorModal] = useState(false);
     const {
          packageId: currentPackageId,
          packagePricePerMonth: currentPackagePricePerMonth,
          packageEventReporting: currentPackageEventReporting,
          packageEventPopulateFields: currentPackageEventPopulate,
          packageNumberOfVisitors: currentPackageNumberOfVisitors,
     } = activeAccount;
     // const checkExistingAccount = activeAccount && activeAccount.packageVersion === 1 && !activeAccount.inactiveTemporary && !activeAccount.inactivePermanent ? true : false;
     const checkExistingAccount = activeAccount && activeAccount.packageVersion === 1 ? true : false;
     const oldPackage = packages.filter((item) => item.version === 3).find((item) => item.id === currentPackageId) || packageIsCustom;
     let currentVisitor = 50000;
     if (currentPackagePricePerMonth === 0) {
          currentVisitor = 0;
     } else {
          if (packageIsCustom) {
               currentVisitor = 210000;
          } else {
               if (
                    packages
                         .filter((item) => item.version === 3)
                         .find((item) => item.numberOfVisitors === currentPackageNumberOfVisitors && item.id === currentPackageId)
               ) {
                    currentVisitor = currentPackageNumberOfVisitors;
               }
          }
     }
     const [userValue, setUserValue] = useState(currentVisitor);
     const packagesToDisplay = [
          ...packages.filter(
               (packageData) =>
                    (packageData.friendlyName === 'Sandbox' ||
                         packageData.numberOfVisitors === userValue ||
                         (userValue < 10000 && packageData.numberOfVisitors === 10000) ||
                         (userValue > 200000 && packageData.numberOfVisitors === 200000)) &&
                    packageData.version === 3
          ),
          CUSTOM_PRICING_PACKAGE,
     ];
     const handleBodyScroll = () => {
          if (showUpgradeAccountPopup) {
               document.body.classList.add('no-scroll');
          } else {
               document.body.classList.remove('no-scroll');
          }
     };

     useEffect(handleBodyScroll, [showUpgradeAccountPopup]);

     const onCloseModal = () => {
          dispatch(setShowUpgradeAccountPopup(false));
          dispatch(setShowUpgradeAccountSchedulePopup(false));
     };

     const toggleTalkToSales = useCallback(() => {
          setShowTalkToSales(!showTalkToSales);
     }, [showTalkToSales]);

     const toggleShowPayment = useCallback(() => {
          setShowPayment(!showPayment);
     }, [showPayment]);

     const togglePublishBeforeDowngradePopup = useCallback(() => {
          setShowPublishBeforeDowngradePopup((show) => !show);
     }, []);

     const onCloseNextPublishDownGradePopup = () => {
          dispatch(setShowUpgradeAccountPopup(false));
          dispatch(setShowUpgradeAccountSchedulePopup(false));
     };

     const onClickSelectPackage = (packageData, btnText) => {
          if (packageIsCustom && packageData.id) {
               setShowErrorModal(true);
          } else {
               if (packageData.id) {
                    if (checkExistingAccount) {
                         setShowPopupNotify(true);
                         setTempPackage(packageData);
                         setChangePackageText(btnText);
                    } else {
                         handleChangePackage(packageData, btnText);
                    }
               } else {
                    toggleTalkToSales();
               }
          }
     };

     const handleChangePackage = (packageData, btnText) => {
          if (btnText === 'Downgrade' || btnText === 'Switch') {
               if (versionPublish.isPublish === 'enable') {
                    togglePublishBeforeDowngradePopup();
                    return;
               }

               let eventReportingPassed = true;

               if (currentPackageEventReporting && !packageData.eventReporting && activeReportingFeatures.length > 0) {
                    // Must turn off all reporting features to downgrade to package with event monitoring = false
                    eventReportingPassed = false;
               } else if (currentPackageEventPopulate && !packageData.eventPopulateFields && activePopulateFeatures.length > 0) {
                    // Must turn off all populate features to downgrade to package with event populate fields = false
                    eventReportingPassed = false;
               } else {
                    // if (packageData.numberOfSaveReportingValues !== -1) {
                    //     // All number of save reporting values for each listener must be <= numberOfSaveReportingValues
                    //     // of the package which the user is about to downgrade to
                    //     let accountSaveReportingValues = tryParseJSON(activeAccount.saveReportingValues);
                    //     if (Array.isArray(accountSaveReportingValues)) {
                    //         eventReportingPassed = accountSaveReportingValues.every(el => el.count <= packageData.numberOfSaveReportingValues);
                    //     }
                    // }
               }

               if (
                    (packageData.numberOfListeners === -1 || activeAccount.numberOfListeners <= packageData.numberOfListeners) &&
                    (packageData.numberOfCusDatalayerVariables === -1 ||
                         activeAccount.numberOfCusDatalayerVariables <= packageData.numberOfCusDatalayerVariables) &&
                    (packageData.numberOfCusVariablesRules === -1 ||
                         activeAccount.numberOfCusVariablesRules <= packageData.numberOfCusVariablesRules) &&
                    (packageData.numberOfCusBrowserVariables === -1 ||
                         activeAccount.numberOfCusBrowserVariables <= packageData.numberOfCusBrowserVariables) &&
                    (packageData.numberOfCusBrowserRules === -1 || activeAccount.numberOfCusBrowserRules <= packageData.numberOfCusBrowserRules) &&
                    (packageData.numberOfDomains === -1 || activeAccount.numberOfDomains <= packageData.numberOfDomains) &&
                    (packageData.numberOfPremium === -1 || activeAccount.numberOfPremium <= packageData.numberOfPremium) &&
                    (packageData.numberOfUsers === -1 || activeAccount.numberOfUsers <= packageData.numberOfUsers) &&
                    (packageData.numberOfEventMonitor === -1 || activeAccount.numberOfEventMonitor <= packageData.numberOfEventMonitor) &&
                    eventReportingPassed
               ) {
                    setCheckDowngrade(true);
                    setSelectedPackage(packageData);
                    toggleShowPayment();
               } else {
                    toggleShowPayment();
                    setCheckDowngrade(true);
               }
          } else {
               if (packageData.numberOfPremium === -1 || activeAccount.numberOfPremium <= packageData.numberOfPremium) {
                    setCheckDowngrade(true);
                    setSelectedPackage(packageData);
                    toggleShowPayment();
               } else {
                    setSelectedPackage(packageData);
                    setShowUpgradeRequired(true);
               }
          }
     };

     const fncUpgrade = () => {
          setCheckDowngrade(true);
     };

     const onCloseUpgradeRequired = () => {
          setSelectedPackage(false);
          onCloseModal();
     };

     const handleIgnoreNotify = () => {
          setShowPopupNotify(false);
          handleChangePackage(tempPackage, changePackageText);
     };

     const marks = {
          0: '< 10,000',
          10000: '10,000',
          25000: '25,000',
          50000: '50,000',
          75000: '75,000',
          100000: '100,000',
          150000: '150,000',
          200000: '200,000',
          210000: '> 200,000',
     };

     const handleSliderChange = (newValue) => {
          setUserValue(newValue);
     };

     useEffect(() => {
          const elements = document.querySelectorAll('.user-package-upgrade .rc-slider-mark-text-active');
          const classText = document.querySelectorAll('.user-package-upgrade .rc-slider-mark-text');
          if (elements) {
               for (let i = 0; i < elements.length; i++) {
                    elements[i].style.display = 'none';
               }

               const activeElementIndex = Math.min(userValue, elements.length - 1);
               if (elements[activeElementIndex]) elements[activeElementIndex].style.display = 'block';

               for (let i = 0; i < classText.length; i++) {
                    if (i === activeElementIndex) {
                         classText[i].style.display = 'block';
                    } else {
                         classText[i].style.display = 'none';
                    }
               }
          }
     }); //eslint-disable-line

     let url = process.env.REACT_APP_LISTENLAYER_DOMAIN;

     return (
          <>
               <div className={classNames('full-screen-popup', { 'action-required-popup': checkDowngrade === false || showUpgradeRequired })}>
                    <CModal addContentClass="full-screen-wrapper" show={showUpgradeAccountPopup} onClose={onCloseModal}>
                         <CModalBody className="upgrade-package">
                              {showTalkToSales || showUpgradeAccountSchedulePopup ? (
                                   <TalkToSales url={url} toggleTalkToSales={toggleTalkToSales} setShowTalkToSales={setShowTalkToSales}></TalkToSales>
                              ) : showUpgradeRequired ? (
                                   <UpgradeRequired
                                        onClose={onCloseUpgradeRequired}
                                        clickBack={() => setShowUpgradeRequired(false)}
                                        accountNumberOfPremium={activeAccount.numberOfPremium}
                                        packageNumberOfPremium={selectedPackage.numberOfPremium}
                                   />
                              ) : showPayment ? (
                                   checkDowngrade === false ? (
                                        <ActionRequired
                                             url={url}
                                             toggleShowPayment={toggleShowPayment}
                                             onClose={onCloseModal}
                                             fncUpgrade={fncUpgrade}
                                        ></ActionRequired>
                                   ) : (
                                        <PaymentMethod url={url} selectedPackage={selectedPackage} toggleShowPayment={toggleShowPayment} />
                                   )
                              ) : (
                                   <>
                                        <div className="pricing-header">
                                             <CButton className="btn-back back" onClick={onCloseModal}>
                                                  <i className="fal fa-angle-left"></i>
                                                  Back
                                             </CButton>
                                             <CIcon name="logo-dark" height={34} />
                                             <h1 className="selectpackage">Plans & Pricing</h1>
                                             <h6 className="selectdecription">
                                                  Built for enterprise. Available to everyone.
                                                  <br />
                                                  Open an account or{' '}
                                                  <span className="link" onClick={() => (isCustomerSupportLoginAs ? null : toggleTalkToSales())}>
                                                       schedule a demo
                                                  </span>{' '}
                                                  to get started
                                             </h6>
                                        </div>
                                        <div className="list-package">
                                             <div className="user-package">
                                                  <h4 className="title">Pricing Scales with Your Website Traffic</h4>
                                                  <div className="user-package-main">
                                                       <div className="user-package-content">
                                                            <p>Estimated Monthly Users</p>
                                                            {/* <p>Check with Google Analytics</p> */}
                                                       </div>
                                                       <div className="user-package-form user-package-upgrade">
                                                            <Slider
                                                                 min={0}
                                                                 max={210000}
                                                                 step={null}
                                                                 marks={marks}
                                                                 value={userValue}
                                                                 onChange={handleSliderChange}
                                                            />
                                                       </div>
                                                  </div>
                                             </div>
                                             {!oldPackage && userValue <= 200000 && (
                                                  <div className="user-package-old">
                                                       <p>
                                                            Your current plan is not displayed below because it is a legacy plan that is no longer
                                                            supported. If you’re ready to upgrade and try some of our new features, explore the plans
                                                            below and contact sales with questions.{' '}
                                                       </p>
                                                  </div>
                                             )}
                                             {userValue > 200000 && !packageIsCustom && (
                                                  <div className="custom-schedule">
                                                       <img src="/assets/icons/sliders-up-regular.png" alt="sliders-up-regular" />
                                                       <h4 className="custom-schedule-title">
                                                            Need 200,00+ monthly users? Let's build a custom plan for you
                                                       </h4>
                                                       <CButton
                                                            className="custom-schedule-btn"
                                                            onClick={() => toggleTalkToSales()}
                                                            disabled={isCustomerSupportLoginAs}
                                                       >
                                                            SCHEDULE A DEMO
                                                       </CButton>
                                                       <div className="custom-schedule-info">
                                                            <div className="info-img">
                                                                 <img src="/assets/icons/founder.png" alt="Founder-CEO" />
                                                            </div>
                                                            <p className="custom-schedule-desc">
                                                                 “Schedule a call and I'll join to answer any questions you may have about the
                                                                 platform.”
                                                            </p>
                                                            <div className="custom-schedule-founder">
                                                                 <h5>Zachary Randall</h5>
                                                                 <p>Founder & CEO, ListenLayer</p>
                                                            </div>
                                                       </div>
                                                  </div>
                                             )}
                                             {(userValue <= 200000 || packageIsCustom) && (
                                                  <div className="list-package row">
                                                       {packagesToDisplay.map((packageData) => {
                                                            const { id: packageId, pricePerMonth } = packageData;
                                                            const isCurrentPackage = currentPackageId === packageId;

                                                            let btnText = '';
                                                            let isSchedule = false;
                                                            if (isCurrentPackage) {
                                                                 btnText = 'Current Plan';
                                                            } else {
                                                                 if (packageIsCustom) {
                                                                      btnText = 'Downgrade';
                                                                 } else {
                                                                      if (currentPackagePricePerMonth === pricePerMonth) {
                                                                           btnText = 'Switch';
                                                                      } else if (currentPackagePricePerMonth > pricePerMonth) {
                                                                           btnText = 'Downgrade';
                                                                      } else {
                                                                           btnText = 'Upgrade';
                                                                      }
                                                                 }
                                                            }

                                                            if (!packageId) {
                                                                 btnText = 'Schedule A Demo';
                                                                 if (isScheduleDemo) isSchedule = true;
                                                            }

                                                            return (
                                                                 <CCol key={packageId} lg="4" sm="6" className="package-col">
                                                                      <PackageItem
                                                                           currentPackageId={currentPackageId}
                                                                           packageIsCustom={packageIsCustom}
                                                                           packageData={packageData}
                                                                           btnText={btnText}
                                                                           btnClick={() => onClickSelectPackage(packageData, btnText)}
                                                                           btnDisabled={isCurrentPackage || isCustomerSupportLoginAs}
                                                                           isScheduleDemo={isSchedule}
                                                                           setShowPopupLargeSandbox={setShowPopupLargeSandbox}
                                                                           currentPackagePricePerMonth={currentPackagePricePerMonth}
                                                                      />
                                                                 </CCol>
                                                            );
                                                       })}
                                                  </div>
                                             )}

                                             {/* {isScheduleDemo && (
                                                    <div>
                                                        <CButton color="primary" onClick={() => onCloseModal()} >
                                                            Exist without selecting a plan
                                                        </CButton>
                                                    </div>
                                                )} */}

                                             <div className="enhancement">
                                                  <h4>Enhancements</h4>
                                                  <p className="enhancement-des">
                                                       Enhance your marketing & revenue analytics with custom data and service addons.
                                                  </p>
                                                  <hr />
                                                  <div className="enhancement-content">
                                                       <p className="enhancement-content-title">Revealed Companies*</p>
                                                       <p className="enhancement-content-desc">
                                                            Identify companies interacting with your website. Build a list of target accounts, monitor
                                                            activity, understand buying intent, and gain insights to drive marketing & sales.
                                                       </p>
                                                  </div>
                                                  <div className="enhancement-content">
                                                       <p className="enhancement-content-title">Implementation & Managed Services**</p>
                                                       <p className="enhancement-content-desc">
                                                            Rely on our team for implementation, or collaborate in a fully-managed services
                                                            arrangement that allows you to achieve your goals with minimal time-investment. Annual
                                                            business plans include 2 Success Sessions, and can also be expanded.
                                                       </p>
                                                  </div>
                                                  <div className="enhancement-content">
                                                       <p className="enhancement-content-title">Enterprise Support</p>
                                                       <p className="enhancement-content-desc">
                                                            Some of the worlds largest businesses rely on ListenLayer to power their marketing data.
                                                            Schedule a demo to learn about our Enterprise offerings.
                                                       </p>
                                                  </div>
                                                  <div className="enhancement-schedule">
                                                       <p className="enhancement-schedule-title">Schedule a demo to learn more about these addons</p>
                                                       <CButton
                                                            color="primary"
                                                            className="btn-select-upgrade primary"
                                                            disabled={isCustomerSupportLoginAs}
                                                            onClick={() => toggleTalkToSales()}
                                                       >
                                                            SCHEDULE A DEMO
                                                       </CButton>
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="footer text-center">
                                             <CIcon name="logo-dark" height={34} className="icon-footer" />
                                             <div>
                                                  {/* <ul className="d-flex justify-content-center">
                                                    <li className="text-footer">
                                                        <p>Support</p>
                                                    </li>
                                                    <li className="text-footer">
                                                        <p>Terms of Use</p>
                                                    </li>
                                                    <li className="text-footer">
                                                        <p>Privacy & Data Policy</p>
                                                    </li> 
                                                </ul> */}
                                                  <div className="d-flex justify-content-center">
                                                       <div>
                                                            <a className="text-footer" href={`${url}support/`}>
                                                                 Support
                                                            </a>
                                                       </div>
                                                       <div>
                                                            <a className="text-footer" href={`${url}terms-of-use/`}>
                                                                 Terms of Use
                                                            </a>
                                                       </div>
                                                       <div>
                                                            <a className="text-footer" href={`${url}privacy-policy/`}>
                                                                 Privacy & Data Policy
                                                            </a>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </>
                              )}
                         </CModalBody>
                    </CModal>
               </div>
               <PublishBeforeDowngradePopup
                    show={showPublishBeforeDowngradePopup}
                    onClose={togglePublishBeforeDowngradePopup}
                    onCloseNext={onCloseNextPublishDownGradePopup}
               />
               <PopupNotifyDataLayerChanges
                    show={showPopupNotify}
                    changePackageText={changePackageText}
                    onAccept={handleIgnoreNotify}
                    onClose={() => setShowPopupNotify(false)}
               />
               <div className="popup-package">
                    <LargeSandBox
                         show={showPopupLargeSandbox}
                         onClose={() => setShowPopupLargeSandbox(false)}
                         toggleTalkToSales={toggleTalkToSales}
                         isScheduleDemo={isScheduleDemo}
                    ></LargeSandBox>
                    <VariableInRules show={showErrorModal} onClose={() => setShowErrorModal(false)}>
                         <p>You are running on a Business Contract account plan. Please contact support if you'd like to downgrade</p>
                    </VariableInRules>
               </div>
          </>
     );
};

export default UpgradeAccount;
