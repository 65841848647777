import React from 'react';
import CIcon from '@coreui/icons-react';

const HoverInfoIconTooltip = ({ children, position, iconName = 'icon-info', iconHeight = 14, iconColor = '', tooltipClassName = '', iconWidth }) => {
    const getPositionClass = () => {
        switch (position) {
            case 'bottom-left':
                return 'block-tooltip-bottom-left';
            case 'top-center':
                return 'block-tooltip-top-center';
            case 'bottom-center':
                return 'block-tooltip-bottom-center';
            case 'bottom-right':
                return 'block-tooltip-bottom-right';
            default:
                return 'block-tooltip';
        }
    };


    return (
        <>
            <CIcon name={iconName} className='hover-show-tooltip' height={iconHeight} style={{ color: iconColor }} width={iconWidth}></CIcon>
            <div className={`${getPositionClass()} custom-width ${tooltipClassName}`}>
                <CIcon name="arrow-preview" className="arrow-preview"></CIcon>
                <div className="block-tooltip-text custom-tooltip-text">
                    {children}
                </div>
            </div>
        </>
    )
}

export default HoverInfoIconTooltip
