import React from 'react'
import { useSelector, useDispatch } from 'react-redux'; // useSelector & useDispatch can only be used in functional component
import {
     CSidebar,
     CSidebarClose,
} from '@coreui/react'
import { setAsideShow } from '../../actions/common'

const AdminASide = () => {
     const dispatch = useDispatch();
     const asideShow = useSelector(state => state.theme.asideShow)

     return (
          <CSidebar
               aside
               colorScheme='light'
               size='lg'
               overlaid
               show={asideShow}
               onShowChange={(state) => dispatch(setAsideShow(state))}
               className="side-bar"
          >
               <CSidebarClose onClick={() => dispatch(setAsideShow(false))} />
          </CSidebar>
     )
}

export default React.memo(AdminASide)
