import React from 'react';
import CIcon from '@coreui/icons-react';

import { AUTH_PATH, SESSION_STORAGE_GOOGLE_REDIRECT_DATA_USER, SESSION_STORAGE_GOOGLE_REDIRECT_LOGIN } from '../../constants';

const Google = ({ className, isIconColor, labelButton, isFromLogin, dataUser }) => {
     const url = process.env.REACT_APP_GOOGLE_OAUTH2_URL;
     const options = {
          redirect_uri: `${window.location.origin}${AUTH_PATH.GOOGLE_LOGIN_REDIRECT}`,
          response_type: 'token',
          scope: 'email profile',
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          prompt: 'select_account',
          flowName: 'GeneralOAuthFlow'
     }

     let params = '';
     Object.entries(options).forEach(([key, value], index) => {
          if (index === 0) {
               params = `${params}${key}=${value}`;
          } else {
               params = `${params}&${key}=${value}`;
          }
     })

     const onClickGoogleRedirect = () => {
          sessionStorage.setItem(SESSION_STORAGE_GOOGLE_REDIRECT_LOGIN, JSON.stringify(isFromLogin))
          sessionStorage.setItem(SESSION_STORAGE_GOOGLE_REDIRECT_DATA_USER, JSON.stringify(dataUser))
     }

     return (
          <a className={`btn ${className || 'btn-danger'} btn-lg btn-block`} href={`${url}?${params}`} onClick={onClickGoogleRedirect} >
               {
                    isIconColor ? (
                         <CIcon name="icon-google" />
                    ) : (
                         <i className="fab fa-google pr-2"></i>
                    )
               }
               <span>{labelButton}</span>
          </a>
     );
};

export default Google;

// import React from 'react';
// import GoogleLogin from 'react-google-login';
// import { CButton } from '@coreui/react';

// import { callApi } from '../../apiCaller';
// import { AUTH_PATH, GOOGLE_LOGIN } from '../../constants';
// import { toastError } from '../../utils';

// const Google = ({ informParent = f => f }) => {
//      const responseGoogle = (response) => {
//           const data = { idToken: response.tokenId };

//           callApi(GOOGLE_LOGIN, 'POST', data)
//                .then(response => {
//                     if (typeof response == 'undefined') {
//                          return;
//                     }
//                     if (response.status === 200) {
//                          // inform parent component
//                          informParent(response);
//                     } else {
//                          toastError(response);
//                     }
//                })
//      };

//      return (
//           <GoogleLogin
//                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
//                onSuccess={responseGoogle}
//                // onFailure={responseGoogle}
//                redirectUri={`${window.location.origin}${AUTH_PATH.GOOGLE_LOGIN_REDIRECT}`}
//                scope="openid profile email"
//                cookiePolicy="single_host_origin"
//                uxMode="redirect"
//                render={renderProps => (
//                     <CButton
//                          size="lg"
//                          color="danger"
//                          block
//                          onClick={renderProps.onClick}
//                          disabled={renderProps.disabled}
//                     >
//                          <i className="fab fa-google pr-2"></i> Login with Google
//                     </CButton>
//                )}
//           />
//      );
// };

// export default Google;
