import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Context } from './Dropdown'
import { createPopper } from '@popperjs/core'

//component - CoreUI / CDropdownMenu

const DropdownMenu = props => {
     const {
          className,
          show,
          placement,
          modifiers,
          innerRef,
          setDropdownOpen,
          ...rest
     } = props

     const { reference, isOpen, setIsOpen, setPlacement } = useContext(Context)
     const [popperElement, setPopperElement] = useState(null)
     const [popper, setPopper] = useState(null)

     innerRef && innerRef(popperElement)

     const setShowAndPlacement = () => {
          setIsOpen(show)
          setPlacement(placement)
     }

     useEffect(setShowAndPlacement, [show, placement])

     const classes = classNames(
          className,
          'dropdown-menu',
          {
               'show': isOpen,
          }
     )

     const useLayoutFunc = () => {
          if (!reference) {
               return
          }
          setPopper(createPopper(
               reference,
               popperElement,
               {
                    placement,
                    modifiers: modifiers || []
               }
          ))

          return () => {
               if (popper) {
                    popper.destroy()
               }
          }
     }

     useLayoutEffect(useLayoutFunc, [isOpen])

     const checkClose = (e) => {
          if ([reference, popperElement].every(el => !el.contains(e.target))) {
               setIsOpen(false)
          }
     }

     const checkCloseClick = () => {
          if (isOpen) {
               document.addEventListener('click', checkClose)
          }
          
          setDropdownOpen && setDropdownOpen(isOpen);

          return () => document.removeEventListener('click', checkClose)
     }

     useEffect(checkCloseClick, [isOpen])

     return (
          <div
               className={classes}
               ref={setPopperElement}
               role="menu"
               aria-hidden={!isOpen}
               {...rest}
          />
     )
}

DropdownMenu.propTypes = {
     children: PropTypes.node.isRequired,
     className: PropTypes.string,
     //
     innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
     modifiers: PropTypes.array,
     show: PropTypes.bool,
     placement: PropTypes.oneOf([
          '', 'top-end', 'top', 'top-start',
          'bottom-end', 'bottom', 'bottom-start',
          'right-start', 'right', 'right-end',
          'left-start', 'left', 'left-end'
     ])
}

DropdownMenu.defaultProps = {
     placement: 'bottom-start',
}

export default DropdownMenu
