import React from 'react';
import {
    CModal,
    CModalBody,
    CButton,
    CModalFooter,
} from '@coreui/react';
import PropTypes from 'prop-types';
import CIcon from '@coreui/icons-react';

const InviteUserPopup = ({ show, isLoading, onClose, event, btnText, children, className, closeOnBackdrop = true }) => {
    return (
        <div className={`invite-user-owner-popup ${className}`}>
            <CModal
                show={show}
                onClose={onClose}
                centered
                closeOnBackdrop={closeOnBackdrop}
            >
                <CModalBody>
                    <div className="invite-owner-description">
                        <CIcon name="cil-x" onClick={onClose} className="icon-close-popup" />
                        {children}
                    </div>
                </CModalBody>
                <CModalFooter>
                    <CButton color="light" onClick={onClose}>Cancel Change</CButton>
                    {
                        <CButton color="primary" onClick={event} disabled={isLoading}>
                            {isLoading ? (<>Waiting<span className="dots-waiting"></span></>) : btnText}
                        </CButton>
                    }
                </CModalFooter>
            </CModal>
        </div>
    )
}

InviteUserPopup.propTypes = {
    show: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func,
    event: PropTypes.func,
    btnText: PropTypes.string,
    closeOnBackdrop: PropTypes.bool,
}

export default InviteUserPopup
