// ** React Imports
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';

// ** Redux Imports
import { useSelector } from 'react-redux';

// ** CoreUI Imports
import { CSpinner, CTooltip } from '@coreui/react';
import CIcon from '@coreui/icons-react';

// ** Component Imports
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '../../../../../general/dropdown';
import { EventExplorerContext } from '../../EventExplorer';
import Confirm from '../dialog/Confirm';
import ShareURL from '../dialog/ShareURL';
import CreateNewView from '../dialog/CreateNewView';
import HoverInfoIconTooltip from '../../../../../general/custom-tooltips/HoverInfoIconTooltip.js';

// ** Constants Imports
import { SAVED_VIEW_TYPE, VIEW_TYPE } from '../../../../../../constants/reportView';
import { CLIENT_REPORT_VIEW, CLIENT_SET_VIEW_DEFAULT } from '../../../../../../constants';
import { callTokenApi } from '../../../../../../apiCaller';
import { encodeObj } from '../../../../../../utils/index.js';

const SavedView = () => {
     const location = useLocation();
     const user = useSelector((state) => state.theme.user);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const {
          scoped,
          filterEvent,
          filterMetric,
          filterDimensions,
          filterProperties,
          reportName,
          listView,
          setListView,
          viewDefault,
          setViewDefault,
          setFilterViewDefault,
          isMapView,
          setIsMapView,
          weight,
          viewLoading,
          setSort,
          enableFullAnalytics,
          showData,
          setIsHandleParams,
          listViewByScoped,
     } = useContext(EventExplorerContext);

     const itemDropdown = [
          {
               title: 'Set as default',
               confirmTitle: 'Set View as Default',
               confirmDescription: 'Pin to see this View as the default when visiting this report.',
               type: SAVED_VIEW_TYPE.SET_DEFAULT,
               isSystemView: true,
               show: false,
          },
          {
               title: 'Delete',
               confirmTitle: 'Delete this View',
               confirmDescription: 'You are about to delete this view. Please confirm.',
               type: SAVED_VIEW_TYPE.DELETE,
               show: true,
          },
          {
               title: 'Rename',
               type: SAVED_VIEW_TYPE.RENAME,
               show: true,
          },
          {
               title: 'Share URL',
               confirmTitle: 'Share URL',
               confirmDescription: 'You can bookmark or share the URL below to quickly access this report.',
               type: SAVED_VIEW_TYPE.SHARE_URL,
               isSystemView: false,
               show: true,
          },
          {
               title: 'Override',
               confirmTitle: 'Override this View',
               confirmDescription: 'You are about to Override this view. Please confirm.',
               type: SAVED_VIEW_TYPE.OVERRIDE,
               show: true,
          },
     ];

     // ** State
     const [show, setShow] = useState(false);
     const [data, setData] = useState({});
     const [viewSelect, setViewSelect] = useState({});
     const type = VIEW_TYPE.VIEW;

     // ** Methods
     const handleConfirm = (_item, view, isUnpin = false) => {
          setShow(true);

          const systemView = listView.find((_view) => _view.isSystem);

          const selectedItem = isUnpin ? itemDropdown[0] : _item;
          const selectedView = isUnpin ? systemView : view;

          setData(selectedItem);
          setViewSelect(selectedView);

          if (_item.type === SAVED_VIEW_TYPE.SET_DEFAULT) {
               const payload = {
                    accountId: activeAccount.accountId,
                    reportName,
                    reportViewId: selectedView.id,
                    userId: user.id,
               };

               callTokenApi(CLIENT_SET_VIEW_DEFAULT, 'PUT', payload)
                    .then((response) => {
                         if (response.status === 200) {
                              setIsHandleParams(false);
                              setListView(
                                   listView.map((view) => ({
                                        ...view,
                                        isDefault: view.id === selectedView.id,
                                   }))
                              );
                              setViewDefault({...viewDefault, isDefault: true})

                              if (isUnpin) {
                                   setIsMapView(true);
                              }

                              toast.success(response.data.message);
                         } else {
                              toast.error('An error occurred while processing your request.');
                         }
                    })
                    .finally(() => {
                         handleApiError();
                         setData({});
                    });
          }
     };

     const handleApiResponse = (response, successCallback) => {
          if (response.status === 200) {
               successCallback();
               toast.success(response.data.message);
          } else {
               toast.error(response.data.message);
          }
     };

     const handleApiError = () => {
          setShow(false);
     };

     const onCancel = () => {
          setShow(false);
          setData({});
     };

     const handleCreateNewView = () => {
          if (!enableFullAnalytics && !showData) {
               return;
          }
          setShow(true);
     };
     
     const onAccept = async (values, { setSubmitting, resetForm }) => {
          if (!data.type) {
               if (values.name.trim() === '') {
                    toast.warn('Name not empty!');
               } else if (listViewByScoped.find((view) => view.name === values.name.trim())) {
                    toast.error('Name already exits!');
                    setSubmitting(false);
               } else {
                    const content = {
                         filter: filterEvent,
                         metrics: filterMetric.length ? filterMetric.map((_item) => _item.value) : [],
                         dimensions: filterDimensions.length ? filterDimensions.map((_item) => _item.value) : [],
                         properties: filterProperties.length ? filterProperties.map((_item) => _item.value) : [],
                         weight: weight && Object.keys(weight).length > 0 ? weight : undefined,
                    };

                    const payload = {
                         ...values,
                         reportName,
                         scoped,
                         type,
                         content: JSON.stringify(content),
                         accountId: activeAccount.accountId,
                         userId: user.id,
                         shareLink: `${location.pathname}`,
                    };
                    await callTokenApi(CLIENT_REPORT_VIEW, 'POST', payload)
                         .then((response) => {
                              if (response.status === 200) {
                                   setFilterViewDefault(response.data.reportViews);
                                   setListView([response.data.reportViews, ...listView]);

                                   toast.success(response.data.message);
                              } else {
                                   toast.error(response.data.message);
                              }
                         })
                         .finally(() => {
                              setSubmitting(false);
                              setShow(false);
                              resetForm();
                         });
               }
          } else {
               if (viewSelect.id) {
                    let apiCall;
                    let payload;

                    switch (data.type) {
                         case SAVED_VIEW_TYPE.DELETE:
                              const query = `?id=${viewSelect.id}&accountId=${activeAccount.accountId}&userId=${user.id}&reportName=${reportName}`;
                              apiCall = callTokenApi(`${CLIENT_REPORT_VIEW}/${query}`, 'DELETE');
                              payload = null;
                              break;

                         case SAVED_VIEW_TYPE.RENAME:
                              payload = {
                                   id: viewSelect.id,
                                   accountId: activeAccount.accountId,
                                   type: viewSelect.type,
                                   reportName: viewSelect.reportName,
                                   scoped: viewSelect.scoped,
                                   name: values.name,
                                   description: values.description,
                                   isAvailable: values.isAvailable,
                                   isRename: true,
                              };

                              apiCall = callTokenApi(CLIENT_REPORT_VIEW, 'PUT', payload);
                              break;

                         case SAVED_VIEW_TYPE.OVERRIDE:
                              const content = {
                                   filter: filterEvent,
                                   metrics: filterMetric.length ? filterMetric.map((_item) => _item.value) : [],
                                   dimensions: filterDimensions.length ? filterDimensions.map((_item) => _item.value) : [],
                                   properties: filterProperties.length ? filterProperties.map((_item) => _item.value) : [],
                                   weight: weight && Object.keys(weight).length > 0 ? weight : undefined,
                              };
                              payload = {
                                   id: viewSelect.id,
                                   accountId: activeAccount.accountId,
                                   content: JSON.stringify(content),
                                   shareLink: `${location.pathname}?reportView=${encodeObj({ id: viewSelect.id })}`,
                              };

                              apiCall = callTokenApi(CLIENT_REPORT_VIEW, 'PUT', payload);
                              break;

                         default:
                              return;
                    }

                    try {
                         const response = await apiCall;
                         handleApiResponse(response, () => {
                              const updatedView = response.data.reportViews;
                              switch (data.type) {
                                   case SAVED_VIEW_TYPE.DELETE:
                                        const systemView = listViewByScoped.find((view) => view.isSystem);
                                        setIsHandleParams(false);
                                        setListView(listView.filter((view) => view.id !== viewSelect.id));
                                        if (viewSelect.id === viewDefault.id) {
                                             setFilterViewDefault(systemView);
                                        }
                                        break;

                                   case SAVED_VIEW_TYPE.RENAME:
                                        setIsHandleParams(false);
                                        if (viewSelect.id === viewDefault.id) {
                                             setViewDefault({
                                                  ...viewDefault,
                                                  name: updatedView.name,
                                                  isAvailable: updatedView.isAvailable,
                                             });
                                        }
                                        setListView(
                                             listView.map((view) =>
                                                  view.id === updatedView.id
                                                       ? {
                                                            ...view,
                                                            name: updatedView.name,
                                                            description: updatedView.description,
                                                            isAvailable: updatedView.isAvailable,
                                                       }
                                                       : view
                                             )
                                        );
                                        break;

                                   case SAVED_VIEW_TYPE.OVERRIDE:
                                        setIsHandleParams(false);
                                        setListView(
                                             listView.map((view) => {
                                                  if (view.id === updatedView.id) {
                                                       view.content = updatedView.content;
                                                  }
                                                  return view;
                                             })
                                        );

                                        setFilterViewDefault({ ...updatedView });
                                        setIsMapView(true);
                                        break;

                                   default:
                                        break;
                              }
                              onCancel();
                         });
                    } catch (error) {
                         toast.error('An error occurred while processing your request.');
                         onCancel();
                    } finally {
                         setSubmitting(false);
                    }
               }
          }
     };

     const dropdownName = viewDefault && isMapView ? viewDefault.name : 'No Saved View Selected';

     const checkIsDefault = (view) => {
          if (view.isDefault) {
               return <CIcon name="iconPinned" width={18} height={18} style={{ color: '#20a8d8' }} />;
          }

          return null;
     };

     const handleChangeView = (view) => {
          if (viewDefault.id !== view.id || !isMapView) {
               setFilterViewDefault(view);
               setIsMapView(true);

               if (view.content.metrics.length) {
                    setSort({
                         fieldName: view.content.metrics[0],
                         isDesc: true,
                    });
               }
          }
     };

     const iconClassName = () => {
          const className = ['position-relative'];
     
          if (!isMapView || (viewDefault && viewDefault.isSystem && viewDefault.isDefault)) {
               className.push('d-none');
          }

          return className.join(' ');
     };

     return (
          <>
               {viewLoading ? (
                    <div className="loading text-center scope-dropdown">
                         <CSpinner color="primary" size="sm" />
                    </div>
               ) : (
                    <>
                         <Dropdown className="scope-dropdown">
                              <div className="d-flex align-items-center mr-3">
                                   <DropdownToggle disabled={!enableFullAnalytics && !showData} className="opacity-100">
                                        <span style={{ textTransform: 'initial' }}>{dropdownName}</span>
                                   </DropdownToggle>

                                   {listViewByScoped.find((_view) => _view.id === viewDefault.id && _view.isDefault) ? (
                                        <div
                                             className={iconClassName()}
                                             style={{ border: '1px solid #20a8d8', padding: '0px 4px', borderRadius: '4px' }}
                                             onClick={() =>
                                                  listViewByScoped.length === 1 || viewDefault.isSystem
                                                       ? false
                                                       : handleConfirm(itemDropdown[0], viewDefault, true)
                                             }
                                        >
                                             <HoverInfoIconTooltip
                                                  iconName="iconPinned"
                                                  iconHeight={16}
                                                  iconColor="#20a8d8"
                                                  position="top-center"
                                                  tooltipClassName="min-w-max-content"
                                             >
                                                  <p>Clear default View</p>
                                             </HoverInfoIconTooltip>
                                        </div>
                                   ) : (
                                        <div
                                             className={iconClassName()}
                                             style={{ border: '1px solid #20a8d8', padding: '0px 4px', borderRadius: '4px' }}
                                             onClick={() => handleConfirm(itemDropdown[0], viewDefault)}
                                        >
                                             <HoverInfoIconTooltip
                                                  iconName="iconPin"
                                                  iconHeight={16}
                                                  iconColor="#20a8d8"
                                                  position="top-center"
                                                  tooltipClassName="min-w-max-content"
                                             >
                                                  <p>Set this view as default</p>
                                             </HoverInfoIconTooltip>
                                        </div>
                                   )}
                              </div>

                              <DropdownMenu className="index-99 pb-0">
                                   <div className="dropdown-scrollbar">
                                        {listViewByScoped.map((view, key) => (
                                             <DropdownItem
                                                  key={key}
                                                  tag="div"
                                                  className="d-block pointer saved-viewed-dropdown-item"
                                                  clickDoesNotClose={true}
                                             >
                                                  <div className="d-flex justify-content-between align-items-center">
                                                       <div className="w-100 py-1" onClick={() => handleChangeView(view)}>
                                                            <div className="d-flex align-items-center mr-5" style={{ gap: '8px' }}>
                                                                 {
                                                                      <CIcon
                                                                           name="iconCheck"
                                                                           width={18}
                                                                           height={18}
                                                                           style={{
                                                                                color: '#20a8d8',
                                                                                opacity:
                                                                                     view.id === viewDefault.id && viewDefault && isMapView ? 1 : 0,
                                                                           }}
                                                                      />
                                                                 }

                                                                 <span>{view.name}</span>

                                                                 {view.description && (
                                                                      <CTooltip
                                                                           content={view.description}
                                                                           placement="bottom"
                                                                           advancedOptions={{
                                                                                onShow(instance) {
                                                                                     instance.popper.className = 'dropdown-icon-savedview';
                                                                                },
                                                                                delay: { hide: 0 },
                                                                           }}
                                                                      >
                                                                           <CIcon
                                                                                name="iconInfoCircle"
                                                                                className="icon-outlined-none-focus"
                                                                                height={16}
                                                                           ></CIcon>
                                                                      </CTooltip>
                                                                 )}
                                                                 {checkIsDefault(view)}
                                                            </div>
                                                       </div>
                                                       <Dropdown className={"position-static"}>
                                                            <DropdownToggle caret={false}>
                                                                 <CIcon name="iconEllipsisVertical" />
                                                            </DropdownToggle>

                                                            <DropdownMenu placement="right-start" style={{ minWidth: '110px' }}>
                                                                 {itemDropdown
                                                                      .filter((_d) => _d.show)
                                                                      .filter((_d) => {
                                                                           if (activeAccount.isOwner && !view.isSystem) {
                                                                                return true;
                                                                           }
                                                                           const isTypeFiltered = [
                                                                                SAVED_VIEW_TYPE.DELETE,
                                                                                SAVED_VIEW_TYPE.OVERRIDE,
                                                                                SAVED_VIEW_TYPE.RENAME,
                                                                           ].includes(_d.type);

                                                                           const isDifferentUser = view.userId !== user.id;
                                                                           return !(isTypeFiltered && isDifferentUser);
                                                                      })
                                                                      .map((_item, key) => (
                                                                           <DropdownItem
                                                                                key={key}
                                                                                tag="div"
                                                                                disabled={
                                                                                     _item.type === SAVED_VIEW_TYPE.SET_DEFAULT &&
                                                                                     view.id === viewDefault.id
                                                                                          ? true
                                                                                          : false
                                                                                }
                                                                                className="d-block pointer"
                                                                                onClick={() => handleConfirm(_item, view)}
                                                                           >
                                                                                <div
                                                                                     className="d-flex align-items-center mr-5"
                                                                                     style={{ gap: '8px' }}
                                                                                >
                                                                                     <span>{_item.title}</span>
                                                                                </div>
                                                                           </DropdownItem>
                                                                      ))}
                                                            </DropdownMenu>
                                                       </Dropdown>
                                                  </div>
                                             </DropdownItem>
                                        ))}
                                   </div>
                                   <DropdownItem
                                        tag="div"
                                        className="d-block pointer saved-viewed-dropdown-item created-saved-viewed"
                                        clickDoesNotClose={true}
                                        onClick={() => handleCreateNewView()}
                                   >
                                        <div className="w-100 py-1">
                                             <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                                                  <CIcon name="iconCheck" width={18} height={18} style={{ opacity: 0 }} />
                                                  <span className='text-primary'>Add new view</span>
                                             </div>
                                        </div>
                                   </DropdownItem>
                              </DropdownMenu>
                         </Dropdown>

                         {(data.type === SAVED_VIEW_TYPE.DELETE || data.type === SAVED_VIEW_TYPE.OVERRIDE) && (
                              <Confirm show={show} data={data} onCancel={() => onCancel()} onAccept={onAccept} />
                         )}

                         {data.type === SAVED_VIEW_TYPE.SHARE_URL && (
                              <ShareURL
                                   link={viewSelect.shareLink}
                                   show={show}
                                   data={data}
                                   isSystem={viewSelect.isSystem}
                                   onCancel={() => onCancel()}
                              />
                         )}

                         {data.type === SAVED_VIEW_TYPE.RENAME && (
                              <CreateNewView show={show} data={viewSelect} onAccept={onAccept} onCancel={() => onCancel()} />
                         )}

                         {!data.type && <CreateNewView show={show} type={type} onAccept={onAccept} onCancel={() => onCancel()} />}
                    </>
               )}
          </>
     );
};

export default SavedView;