import React from 'react';
import { CModal, CModalBody, CButton, CInput, CForm } from '@coreui/react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { callTokenApi } from '../../../apiCaller';
import { API_CLIENT_LISTENER_REQUEST } from '../../../constants';
import { toastError } from '../../../utils';

const ListenerRequest = ({ show, onClose, accountId, listenerType, next, description, placeholder }) => {
    const onSubmit = ({ toolName }, { setSubmitting }) => {
        const data = {
            accountId,
            name: toolName,
            type: listenerType
        }

        callTokenApi(API_CLIENT_LISTENER_REQUEST, 'POST', data)
            .then(response => {
                setSubmitting(false);
                if (response.status === 200) {
                    next();
                } else {
                    toastError(response);
                }
            })
    };

    return (
        <div className="more-chat-listeners-popup show">
            <CModal show={show} onClose={onClose} color="primary" centered>
                <CModalBody className="text-center">
                    <h3>We’re developing more Listeners</h3>
                    <p>
                        {description ? description : "We may not have an integration with the chat platform you use. Type the name here and we'll make sure it's on our development roadmap."}
                    </p>
                    <Formik initialValues={{ toolName: '' }} onSubmit={onSubmit}>
                        {({ values, handleSubmit, handleChange, isSubmitting }) => (
                            <CForm onSubmit={handleSubmit}>
                                <CInput
                                    name="toolName"
                                    className="form-chat-tool"
                                    placeholder={placeholder}
                                    value={values.toolName}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    data-lpignore="true"
                                />
                                <CButton type="submit" className="btn-save" color="success" disabled={isSubmitting || !values.toolName}>
                                    NEXT
                                </CButton>
                                <CButton type="button" className="btn-navigation-step" disabled={isSubmitting} onClick={next}>
                                    Skip<i className="fal fa-angle-right"></i>
                                </CButton>
                            </CForm>
                        )}
                    </Formik>
                </CModalBody>
            </CModal>
        </div>
    );
};

ListenerRequest.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    accountId: PropTypes.string,
    listenerType: PropTypes.string,
    next: PropTypes.func,
    placeholder: PropTypes.string
};

ListenerRequest.defaultProps = {
    placeholder: 'Enter the name of your tool'
}

export default ListenerRequest;
