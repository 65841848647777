// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';

// ReactDOM.render(<App />, document.getElementById('root'));

import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from "./store";
import App from './App';
import { icons } from './assets/icons';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

if (process.env.REACT_APP_LOG_ROCKET && process.env.REACT_APP_ENV === 'prod') {
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET);
    setupLogRocketReact(LogRocket);
}

React.icons = icons; // Make @coreui/icons-react & @coreui/icons work
const store = configureStore();

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    hydrate((
        <Provider store={store}>
            <App />
        </Provider>
    ), rootElement);
} else {
    render((
        <Provider store={store}>
            <App />
        </Provider>
    ), rootElement);
}
