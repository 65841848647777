import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CButton, CForm, CFormGroup, CInput, CInvalidFeedback, CCard, CCardBody } from '@coreui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import useSaveCurrentStep from '../useSaveCurrentStep';

const validationSchema = Yup.object().shape({
    domain: Yup.string().trim().max(255, 'Cannot include more than 255 characters!').required('Cannot be empty!'),
});

const Domain = () => {
    const { stepsData, setStepsData, setCurrentStep, prevSteps, setShouldHideStepProgress } = useContext(CreateAccountContext);
    const [displayErrors, setDisplayErrors] = useState(false)
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    const onBackBtnClicked = () => {
        if (prevSteps.length > 0) {
            setCurrentStep(null, CHANGE_STEP_TYPE.subStepPrev);
        } else {
            setCurrentStep(null, CHANGE_STEP_TYPE.mainStepPrev);
        }
    };

    const onDomainChanged = (e, handleChange) => {
        setDisplayErrors(false);
        handleChange(e);
    }

    const validateDomainName = useCallback((string) => {
        let errors = [];
        if (string.match(/^(?!:\/\/)([a-zA-Z0-9-]+\.)*[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,11}?$/) && string.indexOf('-') !== 0) {
            return errors;
        }
        errors.push('Domain format is invalid.');
        if (string.indexOf('http://') === 0 || string.indexOf('https://') === 0) {
            errors.push('Please do not include http:// or https:// on your domain.');
        }
        else if (string.indexOf(' ') > -1) {
            errors.push('It cannot include space character.');
        }
        else if (string.indexOf('-') === 0 || (string.length > 1 && !string.match(/^[a-z0-9](?!.*?[^\na-z0-9]{2}).*?[a-z0-9]$/))) {
            errors.push('It cannot start or end with special character or space.');
        }
        return errors;
    }, []);

    useEffect(() => {
        setShouldHideStepProgress(false);
    }, []);// eslint-disable-line

    const onSubmit = ({ domain }, { setFieldValue, setFieldError }) => {
        setIsFormSubmitting(true);
        setDisplayErrors(true);
        let newDomain = domain.replace(/[/\s]|(https?:)/g, '');
        setFieldValue('domain', newDomain);

        let itemErrors = validateDomainName(newDomain);
        let error = '';
        if (itemErrors.length > 0) {
            itemErrors.forEach(itemError => {
                error += `${itemError} `;
            });
        }

        if (error) {
            setFieldError('domain', error);
            setIsFormSubmitting(false);
        } else {
            setIsFormSubmitting(false);
            setStepsData({ domain: newDomain });
            setCurrentStep(STEPS.domainVerifying);
        }
    }

    useSaveCurrentStep();

    return (
        <CreateAccountBody onBackBtnClicked={onBackBtnClicked} backBtnDisabled={isFormSubmitting}>
            <Formik
                initialValues={{ domain: stepsData.domain }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ values, errors, handleChange, handleSubmit }) => (
                    <CCard className="account-domain parent">
                        <p className="number-of-step">1 of 3 steps</p>
                        <CCardBody>
                            <CForm onSubmit={handleSubmit}>
                                <CFormGroup>
                                    <h3>Where will you install ListenLayer?</h3>
                                    <p>Enter your main domain name… you can enter more inside your account.</p>
                                    <CInput
                                        name="domain"
                                        placeholder="Website domain"
                                        invalid={!!errors.domain && displayErrors}
                                        value={values.domain}
                                        onChange={(e) => onDomainChanged(e, handleChange)}
                                        data-lpignore="true"
                                    />
                                    <CInvalidFeedback>{errors.domain}</CInvalidFeedback>
                                    <CButton className="btn-save" type="submit" disabled={!values.domain}>
                                        NEXT
                                    </CButton>
                                </CFormGroup>
                            </CForm>
                            <div className="notification">
                                <h4>Use Your Live Website Domain</h4>
                                <p>
                                    Using a dev or staging website will also work, but may impact our ability to verify your script. ListenLayer
                                    generates data layer activity that is hidden from your users - it is safe to place it on your production
                                    website.
                                </p>
                            </div>
                        </CCardBody>
                    </CCard>
                )}
            </Formik>
        </CreateAccountBody>
    );
};

export default Domain;
