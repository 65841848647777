import React, { Fragment, useEffect, useRef, useState } from 'react';
import cookie from 'js-cookie';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import cloneDeep from 'lodash/cloneDeep';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { handleFetchFormCategories, handleSetAccordion, handleSetListenerFormsEnabled, setAccountPreview, setAccounts, setActiveAccount, setChildSetupGuideDataCompleted, setDomainPreview, setDomainsPreview, setNewLayout, setSetupGuideRoadmap, setShowPopupDomain, setShowPublishPopup, setShowSetupGuidePopup, setShowStepCustomForm, setShowStepFormCategory, setShowTourCompletedWizard, setTogglePreviewPopup, setTour } from '../../actions/subscriber';
import { ACCORDION_TYPES, API_CLIENT_ACCOUNT_COMPLETE_TOUR, STEPS_CUSTOM_FORM, STEPS_WIZARD_CATEGORIES, COMPONENT_NAME, SUBSCRIBER_PATH, API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, STEPS_CONFIG_CONVERSION } from '../../constants';
import { handleToggleSidebar, toastError, tryParseJSON, useMediaQuery } from '../../utils';
import { toast } from 'react-toastify';
import { callTokenApi } from '../../apiCaller';
import { subscriberNavKey } from '../_nav/subscriber';
import { setFlexibleModal, setUser } from '../../actions/common';
import ChooseDestination from './choose-destination-modal/chooseDestination';
import Cookies from 'js-cookie';

export const LAYOUT = {
     IMAGE_LEFT: 'Image Left',
     IMAGE_RIGHT: 'Image Right',
     IMAGE_TOP: 'Image Top',
};

export const TOURSTEPS = {
     tourGetStarted: {
          orderNumber: 0,
          name: 'Tour Get Started',
          className: 'tour-get-started',
          imageSrc: '/assets/images/larry-greeting.svg',
          imageAlt: 'larry-greeting',
          layout: LAYOUT.IMAGE_LEFT,
          showBackButton: false,
          nextButton: "Let's go",
          html: `
		<div>
			<p>Hi! I’m Larry,</p>
			<p>I’m here to show you around your account.</p>
			<p>This tour will be quick, and will speed up your learning.</p>
		</div>`,
     },
     eventReporting: {
          orderNumber: 1,
          name: 'Event Reporting',
          className: 'event-reporting',
          imageSrc: '/assets/images/larry-point-down.svg',
          imageAlt: 'larry-point-down',
          layout: LAYOUT.IMAGE_RIGHT,
          html: `
		<p>ListenLayer automatically generates data when users interact with your website.</p>
		<p>As soon as we receive data from your website, this chart on your dashboard will begin to update.</p>`,
     },
     scriptNotRunning: {
          orderNumber: 2,
          name: 'Script Not Running',
          className: 'script-not-running',
          imageSrc: '/assets/images/larry-thinking.svg',
          imageAlt: 'larry-thinking',
          layout: LAYOUT.IMAGE_RIGHT,
     },
     accessSettings: {
          orderNumber: 3,
          name: 'Access Settings',
          className: 'access-settings',
          imageSrc: '/assets/images/larry-point-up-right.svg',
          imageAlt: 'larry-point-up-right',
          layout: LAYOUT.IMAGE_RIGHT,
     },
     listenerAccounts: {
          orderNumber: 4,
          name: 'Listener Accounts',
          className: 'listener-accounts',
          imageSrc: '/assets/images/larry-point-up-right.svg',
          imageAlt: 'larry-point-up-right',
          layout: LAYOUT.IMAGE_RIGHT,
          html: `
		<p>Your login allows you to create and access multiple ListenLayer accounts. Use this drop-down to create new accounts and switch between them.</p>
		<p class="sub-text">We recommend using one account for one business/website. This means agencies and consultants might have one login with multiple accounts listed in this drop down. ListenLayer does support crossdomain tracking.</p>`,
     },
     personalLogin: {
          orderNumber: 5,
          name: 'Personal Login',
          className: 'personal-login',
          imageSrc: '/assets/images/larry-point-up-right.svg',
          imageAlt: 'larry-point-up-right',
          layout: LAYOUT.IMAGE_RIGHT,
          content: 'Access and manage your personal login and account here.',
     },
     publish: {
          orderNumber: 6,
          name: 'Publish',
          className: 'publish',
          imageSrc: '/assets/images/larry-point-up-left.svg',
          imageAlt: 'larry-point-up-left',
          layout: LAYOUT.IMAGE_LEFT,
          content: 'Many of the changes you make in ListenLayer will need to be published to go live. This button will become active when changes need to be published.',
     },
     mainMenuDashboard: {
          orderNumber: 7,
          name: 'Main Menu Dashboard',
          className: 'main-menu-dashboard',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          content: "You'll want to become familiar with the main menu. ListenLayer is a complete measurement platform. We allow you to automate, customize, and distribute your marketing data everywhere, and we even have our own analytics suite!",
     },
     mainMenuAnalytics: {
          orderNumber: 8,
          name: 'Main Menu Analytics',
          className: 'main-menu-analytics',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          html: `
		<p>We're also building a full analytics suite with powerful, prebuilt, and customized reporting. Reports will begin to be released soon!</p>
		<p>We're even going to allow you to connect to Salesforce and other down-funnel platforms to integrate offline data with your measurement strategy.</p>`,
     },
     menuFormCategory: {
          orderNumber: 9,
          name: 'Menu Form Category',
          className: 'menu-listener-custom-forms category',
          // imageSrc: "/assets/images/larry-custom-form-target.svg",
          imageSrc: '/assets/images/larry-custom-form.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_TOP,
          nextButton: 'OKAY, GOT IT',
          showSkipButton: false,
          html: `
		<p>The <b>Form Categories</b> screen allows you to manage how forms are grouped for conversion tracking. Complete the wizard to configure your first set of Form Categories<span> (you can always change them later)</span>.</p>`,
     },
     roadMapFormCategory: {
          orderNumber: 10,
          name: 'RoadMap Form Category',
          className: 'road-map-form-category',
          imageSrc: '/assets/images/larry-custom-form-roadmap.svg',
          imageAlt: 'larry-custom-form-roadmap',
          layout: LAYOUT.IMAGE_RIGHT,
          roadMapButton: 'GO TO ROADMAP',
          showButtonRoadMap: true,
          skipButton: 'No thanks, exit',
          showBackButton: false,
          showSkipButton: true,
          hideNextButton: true,
          html: `
		<p>We've saved your place in this configuration. Before you can take the next step to complete your Conversion strategy, you'll need to finish the Form Categorization process.</p>
		<p>Let's jump back to the roadmap.</p>`,
     },
     requiringActionFormCategoryWizard: {
          orderNumber: 11,
          name: 'requiring Action Form Category',
          className: 'requiring-action-form-category',
          imageSrc: '/assets/images/larry-custom-form-target.svg',
          imageAlt: 'larry-target-custom-form',
          layout: LAYOUT.IMAGE_RIGHT,
          showSkipButton: false,
          html: `
		<p>Stay focused on keeping things up to date by taking quick action on the forms requiring changes here.</p>`,
     },
     editFormCategoriesWizard: {
          orderNumber: 12,
          name: 'Edit Forms Categories',
          className: 'edit-forms-categories',
          imageSrc: '/assets/images/larry-custom-form-target.svg',
          imageAlt: 'larry-target-custom-form',
          layout: LAYOUT.IMAGE_RIGHT,
          showSkipButton: false,
          html: `
		<p>You can edit, change or add new categories here.</p>`,
     },
     formsInventoryWizard: {
          orderNumber: 13,
          name: 'Forms Inventory',
          className: 'forms-inventory',
          imageSrc: '/assets/images/larry-custom-form-target.svg',
          imageAlt: 'larry-target-custom-form',
          layout: LAYOUT.IMAGE_RIGHT,
          showSkipButton: false,
          html: `
		<p>Use the <b>Form Inventory</b> section to view a list of all the forms on your website and to easily manage the categories.</p>`,
     },
     formsRuleWizard: {
          orderNumber: 14,
          name: 'Forms Rule',
          className: 'forms-rule',
          imageSrc: '/assets/images/larry-custom-form-target.svg',
          imageAlt: 'larry-target-custom-form',
          layout: LAYOUT.IMAGE_RIGHT,
          showSkipButton: true,
          roadMapButton: 'GO TO ROADMAP',
          hideNextButton: true,
          showButtonRoadMap: true,
          skipButton: 'No thanks, exit',
          html: `
		<p>The underlying rule that controls your form categorization appears here. You can edit this to add new forms that our system hasn’t found.</p>`,
     },
     // notifications: {
     // 	orderNumber: 15,
     // 	name: "Notifications",
     // 	className: "notifications",
     // 	imageSrc: "/assets/images/larry-custom-form-roadmap.svg",
     // 	imageAlt: "larry-custom-form-roadmap",
     // 	layout: LAYOUT.IMAGE_RIGHT,
     // 	roadMapButton: 'GO TO ROADMAP',
     // 	hideNextButton: true,
     // 	showButtonRoadMap: true,
     // 	skipButton: 'No thanks, exit',
     // 	showBackButton: false,
     // 	html: `
     // 	<p>You'll also see notifications appear when new forms require your attention!</p>`,
     // },
     menuListenerLibrary: {
          orderNumber: 15,
          name: 'Menu Listener Library',
          className: 'menu-listeners',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          content: 'Listeners automatically generate data when user’s interact with your website, and tools you use on your website. Our automated data is powerful, comprehensive, and available for you to use (no programming required).',
     },
     menuActiveListeners: {
          orderNumber: 16,
          name: 'Menu Active Listeners',
          className: 'menu-active-listeners',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          html: `
		<p>We've activated some of our most commonly used Listeners, as well as those you selected during your account creation.</p>`,
     },
     menuListenerCustomForms: {
          orderNumber: 17,
          name: 'Menu Listener Custom Forms',
          className: 'menu-listener-custom-forms',
          // imageSrc: "/assets/images/larry-custom-form-target.svg",
          imageSrc: '/assets/images/larry-custom-form.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          nextButton: 'OKAY, GOT IT',
          showSkipButton: false,
          html: `
		<p>Let’s configure the Custom Forms Listener to track your specific forms. Close this message and click <b>Get Start</b>.</p>`,
     },
     roadMapCustomForms: {
          orderNumber: 18,
          name: 'RoadMap Custom Forms',
          className: 'road-map-custom-forms',
          imageSrc: '/assets/images/larry-custom-form-roadmap.svg',
          imageAlt: 'larry-custom-form-roadmap',
          layout: LAYOUT.IMAGE_RIGHT,
          roadMapButton: 'GO TO ROADMAP',
          hideNextButton: true,
          showButtonRoadMap: true,
          skipButton: 'No thanks, exit',
          showBackButton: false,
          html: `
		<p>We've saved your place in this configuration. Certain features of ListenLayer may not work if you don't complete this setup. Let's jump back to the roadmap to see what's next.</p>`,
     },
     targetCustomForms: {
          orderNumber: 19,
          name: 'Target Custom Forms',
          className: 'target-custom-forms',
          imageSrc: '/assets/images/larry-custom-form-target.svg',
          imageAlt: 'larry-target-custom-form',
          layout: LAYOUT.IMAGE_RIGHT,
          showSkipButton: false,
          html: `
		<p>Use the Target Forms section to manage the rules you just wrote to target your forms.</p>`,
     },
     iFrameCustomForms: {
          orderNumber: 20,
          name: 'iFrame Custom Forms',
          className: 'iframe-custom-forms',
          imageSrc: '/assets/images/larry-custom-form-target.svg',
          imageAlt: 'larry-target-custom-form',
          layout: LAYOUT.IMAGE_RIGHT,
          showSkipButton: true,
          roadMapButton: 'GO TO ROADMAP',
          hideNextButton: true,
          showButtonRoadMap: true,
          skipButton: 'No thanks, exit',
          html: `
		<p>Additional settings and implementation for forms inside iFrames.</p>`,
     },
     menuCustomData: {
          orderNumber: 21,
          name: 'Menu Custom Data',
          className: 'menu-custom-data',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          content: 'Use simple IF/THEN statements and powerful lookup tables to generate custom data within the data layer and browser storage.',
     },
     mainMenuDestination: {
          orderNumber: 22,
          name: 'Main Menu Destination',
          className: 'main-menu-destination',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          html: `
		<p>ListenLayer allows you to send Measurement data to other platforms like Google Ads, Google Analytics, Google Tag Manager, Facebook, and more.</p>
		<p>We currently support data distribution using Google Tag Manager, but soon you'll be able to easily configure server-side tagging using ListenLayer!</p>`,
     },
     menuTriggerAndConversions: {
          orderNumber: 23,
          name: 'Menu Trigger And Conversions',
          className: 'menu-trigger-and-conversions',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          html: `
		<p>To manage how data is distributed, we recommend configuring Triggers and Conversions inside ListenLayer.  By doing this inside ListenLayer, you'll centralize the definition for your most important KPIs.</p>
		<p>This also powers ListenLayer's analytics and enables automated monitoring of critical data.</p>`,
     },
     menuDestinationLibrary: {
          orderNumber: 24,
          name: 'Menu Destinations',
          className: 'menu-destinations',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          content: "Our library of data destinations is always growing. Let us know what's missing and check back regularly!",
     },
     menuActiveDestinations: {
          orderNumber: 25,
          name: 'Menu Google Tag Manager',
          className: 'menu-google-tag-manager',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          html: `
		<p>By default, all accounts have Google Tag Manager enabled as a destination. That's because GTM reads the data layer (which is where ListenLayer's data goes!).</p>
		<p>Connect to your GTM account and you'll be able to easily create and manage critical elements from inside ListenLayer.</p>`,
     },
     popupDestinationsWizard: {
          orderNumber: 26,
          name: 'Popup Destination Wizard',
          className: 'popup-destination-wizard',
          imageSrc: '/assets/images/larry-custom-form.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_TOP,
          // showBackButton: false,
          showSkipButton: false,
          html: `
		<p>Destinations allow you to send data to all your marketing & analytics platforms from a single source of truth.</p>
		<p>Oh, and you can implement Server Side Tagging with the flip of a switch (whenever you're ready).</p>`,
     },
     menuCreateDestinationsWizard: {
          orderNumber: 27,
          name: 'Menu Create Destination Wizard',
          className: 'menu-add-destination-wizard',
          imageSrc: '/assets/images/larry-custom-form.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_TOP,
          showSkipButton: false,
          html: `
		<p>Prebuilt Destinations are perfectly architected to align your measurement strategy with each platform's requirements.</p>
		<p>You simply flip a few switches on rather than spend hours figuring out the "exact right tags" to use for each Destination.</p>`,
     },
     centerPopupDestinationsWizard: {
          orderNumber: 28,
          name: 'Center Popup Destination Wizard',
          className: 'center-popup-destination-wizard',
          imageSrc: '/assets/images/larry-thinking.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_TOP,
          showSkipButton: false,
          html: `
		<p>We also automate advanced tracking features, like sending your ecommerce data to destinations or providing hashed emails to power enhanced conversion tracking and audience creation - yes, we can do that automatically!</p>`,
     },
     createDestinationsWizard: {
          orderNumber: 29,
          name: 'Create Destination Wizard',
          className: 'create-destination-wizard',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          showSkipButton: false,
          html: `
		<p>Create a Custom Destination for platforms you need that we don't yet have - you can trigger and execute any javascript function using a Custom Destination.</p>`,
     },
     activeGTMDestinationsWizard: {
          orderNumber: 30,
          name: 'Active GTM Destination Wizard',
          className: 'active-gtm-destination-wizard',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          showSkipButton: false,
          html: `
		<p>Prebuilt Destinations support <b>Server Side Tagging!</b> And for all client-side needs, we integrated directly with Google Tag Manager.</p>`,
     },
     accordionGTMDestinationsWizard: {
          orderNumber: 31,
          name: 'Accordion GTM Destination Wizard',
          className: 'accordion-gtm-destination-wizard',
          imageSrc: '/assets/images/larry-thinking.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          showSkipButton: false,
          html: `
		<p>Our GTM integration allows you to streamline client-side tracking needs while bridging over to Server Side. Use it to automatically configure tags and variables that align with your ListenLayer Conversions.</p>
		<p>ListenLayer will create a perfectly organized GTM structure and can even provide advice on cleaning up your old tags.</p>`,
     },
     contentGTMDestinationsWizard: {
          orderNumber: 32,
          name: 'Content GTM Destination Wizard',
          className: 'content-gtm-destination-wizard',
          imageSrc: '/assets/images/larry-thinking.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          showSkipButton: false,
          html: `
		<p>Now anyone can configure a robust measurement strategy without being an expert in Tag Manager and all of the Destinations where you use the data!</p>
		<p>And we keep meticulous records of changes in your GTM account in the event you want to revert something!</p>`,
     },
     addMoreDestinationsWizard: {
          orderNumber: 33,
          name: 'Add More Destination Wizard',
          className: 'add-more-destination-wizard',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          nextButton: 'Add Destinations',
          dropoutButton: 'No thanks',
          showSkipButton: false,
          showDropoutButton: true,
          html: `
		<p>It looks like you may need to turn on more Destinations.</p>
		<p>Some common Destinations many marketers use are GA4, Google Ads, Facebook Ads, Linkedin Ads, Microsoft Ads, Twitter, Reddit, etc.</p>
		<p>Do you want to turn on a few more Destinations? You won't need to configure them right away.</p>`,
     },
     chooseDestinationWizard: {
          orderNumber: 34,
          name: 'Choose Destination Wizard',
          className: 'choose-destination-wizard',
          imageSrc: '',
          imageAlt: '',
          layout: '',
     },
     // endDestinationWizard: {
     // 	orderNumber: 36,
     // 	name: "End Destination Wizard",
     // 	className: "end-destination-wizard",
     // 	imageSrc: "/assets/images/larry-showing-left.svg",
     // 	imageAlt: "larry-showing-left",
     // 	layout: LAYOUT.IMAGE_LEFT,
     // 	nextButton: "Let's Do It",
     // 	skipButton: "No thanks, back to the roadmap",
     // 	html: `
     // 	<p>Now we need to dive in and configure a few Destinations.</p>
     // 	<p>We’ll walk you through a step-by-step process to make things really simple. You can pause at any time without affecting your current tracking configuration.</p>
     // 	<p>First up: Google Analytics 4!</p>`,
     // },
     mainMenuMonitoring: {
          orderNumber: 35,
          name: 'Main Menu Monitoring',
          className: 'main-menu-monitoring',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          content: 'Because ListenLayer centralizes all of your data, it can be monitored for anomalies and changes, allowing you to increase the quality and integrity of your data.',
     },
     menuCustomMonitoring: {
          orderNumber: 36,
          name: 'Menu Custom Monitoring',
          className: 'menu-custom-monitoring',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          html: `
		<p>Right now, you can write custom monitoring rules based on data layer activity that occurs (or that you expect to occur) on your website.</p>
		<p>Soon, we'll release Automatic Monitoring of critical events for Conversions, Listeners, and Destinations.</p>`,
     },
     menuNotifications: {
          orderNumber: 37,
          name: 'Menu Notifications',
          className: 'menu-notifications',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          html: `
		<p>Notifications are saved to your account so you can review them and address them as needed.</p>
		<p>You can also receive notifications via email, and Slack (soon!).</p>`,
     },
     menuConsent: {
          orderNumber: 38,
          name: 'Menu Consent',
          className: 'menu-consent',
          imageSrc: '/assets/images/larry-showing-left.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          html: `
		<p>Write rules to govern how users are identified and tracked based on region and consent - use our default rules as a starting place.</p>
		<p>Consent & Privacy is available with all paid accounts.</p>`,
     },
     configureConversion: {
          orderNumber: 39,
          name: 'Configure Conversion',
          className: 'configure-conversion',
          // imageSrc: "/assets/images/larry-custom-form-target.svg",
          imageSrc: '/assets/images/larry-custom-form.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_TOP,
          nextButton: 'OKAY, GOT IT',
          showSkipButton: false,
          showBackButton: false,
          html: `
		<p>Building Conversions is critical to experiencing the power of ListenLayer.</p>
		<p>We allow you to define conversions once, in a centralized source of truth, and distribute them to your marketing & analytics platforms (and you'll use the data inside the ListenLayer data engine).</p>
		<p>Close this message and click <b>Get Started</b>.</p>
		`,
     },
     previewConfigureConversion: {
          orderNumber: 40,
          name: 'Preview Configure Conversion',
          className: 'preview-configure-conversion',
          imageSrc: '/assets/images/larry-custom-form-target.svg',
          imageAlt: 'larry-showing-left',
          showSkipButton: false,
          showBackButton: false,
          html: `
		<p>Your Conversion rules are listed and can be managed here.</p>
		<p>Conversions are centrally defined so they can be easily sent to your Destinations.</p>
		`,
     },
     previewTrigger: {
          orderNumber: 41,
          name: 'Preview Trigger',
          className: 'preview-trigger',
          imageSrc: '/assets/images/larry-custom-form-target.svg',
          imageAlt: 'larry-showing-left',
          showSkipButton: false,
          html: `
		<p>You can also write rules to define Triggers. Triggers are also used to send data to your Destinations - typically, they are used for sending event data that is less important than Conversions.</p>
		`,
     },
     previewTriggerSecond: {
          orderNumber: 42,
          name: 'Preview Trigger Second',
          className: 'preview-trigger-second',
          imageSrc: '/assets/images/larry-custom-form-target.svg',
          imageAlt: 'larry-showing-left',
          showSkipButton: false,
          html: `
		<p>Default Rules contain Triggers for common actions that many marketers wish to track based on the Listeners they have enabled.</p>
		<p>As you configure Destinations, you'll save a tremendous amount of time using Conversions and pre-built Triggers.</p>
		`,
     },
     learnDestination: {
          orderNumber: 43,
          name: 'Learn Destination',
          className: 'learn-destination',
          imageSrc: '/assets/images/larry-thinking.svg',
          imageAlt: 'larry-showing-left',
          layout: LAYOUT.IMAGE_LEFT,
          nextButton: 'LEARN MORE',
          showButtonRoadMap: true,
          showDropoutButton: false,
          showSkipButton: false,
          html: `
		<p>Centralizing Triggers and Conversions in ListenLayer allows you to easily and consistently distribute data to your Destinations. You might think of these as "Tags," but we call them "Data Actions" because data can be sent client side or server side.</p>
		<p>Let's take a couple of minutes to learn about Destinations.</p>
		`,
     },
     tourOver: {
          orderNumber: 44,
          name: 'Tour Over',
          className: 'tour-over',
          imageSrc: '/assets/images/larry-point-up-left.svg',
          imageAlt: 'larry-point-up-left',
          layout: LAYOUT.IMAGE_LEFT,
          nextButton: 'Close Tour & Launch Test',
          // showCloseButton: true,
     },
};

export const TOUR_FORCE_OPEN = {
     header: [TOURSTEPS.accessSettings.orderNumber],
     headerChild: [TOURSTEPS.listenerAccounts.orderNumber, TOURSTEPS.personalLogin.orderNumber, TOURSTEPS.publish.orderNumber, TOURSTEPS.tourOver.orderNumber],
     settings: [TOURSTEPS.accessSettings.orderNumber],
     account: [TOURSTEPS.listenerAccounts.orderNumber],
     personal: [TOURSTEPS.personalLogin.orderNumber],
     menuLv1: [TOURSTEPS.mainMenuDashboard.orderNumber, TOURSTEPS.mainMenuDestination.orderNumber, TOURSTEPS.mainMenuMonitoring.orderNumber, TOURSTEPS.mainMenuAnalytics.orderNumber, TOURSTEPS.requiringActionFormCategoryWizard.orderNumber, TOURSTEPS.editFormCategoriesWizard.orderNumber, TOURSTEPS.formsInventoryWizard.orderNumber, TOURSTEPS.formsRuleWizard.orderNumber, TOURSTEPS.roadMapCustomForms.orderNumber, TOURSTEPS.targetCustomForms.orderNumber, TOURSTEPS.iFrameCustomForms.orderNumber, TOURSTEPS.roadMapFormCategory.orderNumber, TOURSTEPS.configureConversion.orderNumber, TOURSTEPS.previewConfigureConversion.orderNumber, TOURSTEPS.previewTrigger.orderNumber, TOURSTEPS.previewTriggerSecond.orderNumber],
     menuLv2: [
          TOURSTEPS.menuConsent.orderNumber,
          TOURSTEPS.menuListenerLibrary.orderNumber,
          TOURSTEPS.menuActiveListeners.orderNumber,
          TOURSTEPS.menuCustomData.orderNumber,
          TOURSTEPS.menuTriggerAndConversions.orderNumber,
          TOURSTEPS.menuDestinationLibrary.orderNumber,
          TOURSTEPS.menuActiveDestinations.orderNumber,
          // TOURSTEPS.endDestinationWizard.orderNumber,
          TOURSTEPS.menuCustomMonitoring.orderNumber,
          TOURSTEPS.menuNotifications.orderNumber,
          TOURSTEPS.menuFormCategory.orderNumber,
          TOURSTEPS.menuListenerCustomForms.orderNumber,
     ],
     menuLv2Highlight: [
          TOURSTEPS.menuConsent.orderNumber,
          TOURSTEPS.menuListenerLibrary.orderNumber,
          // TOURSTEPS.menuActiveListeners.orderNumber,
          // TOURSTEPS.menuCustomData.orderNumber,
          TOURSTEPS.menuTriggerAndConversions.orderNumber,
          TOURSTEPS.menuDestinationLibrary.orderNumber,
          TOURSTEPS.menuActiveDestinations.orderNumber,
          // TOURSTEPS.endDestinationWizard.orderNumber,
          TOURSTEPS.menuCustomMonitoring.orderNumber,
          TOURSTEPS.menuNotifications.orderNumber,
          TOURSTEPS.requiringActionFormCategoryWizard.orderNumber,
          TOURSTEPS.editFormCategoriesWizard.orderNumber,
          TOURSTEPS.formsInventoryWizard.orderNumber,
          TOURSTEPS.formsRuleWizard.orderNumber,
          TOURSTEPS.roadMapCustomForms.orderNumber,
          TOURSTEPS.targetCustomForms.orderNumber,
          TOURSTEPS.iFrameCustomForms.orderNumber,
          TOURSTEPS.roadMapFormCategory.orderNumber,
          TOURSTEPS.configureConversion.orderNumber,
          TOURSTEPS.previewConfigureConversion.orderNumber,
          TOURSTEPS.previewTrigger.orderNumber,
          TOURSTEPS.previewTriggerSecond.orderNumber,
     ],
     scriptNotInstall: [TOURSTEPS.scriptNotRunning.orderNumber, TOURSTEPS.accessSettings.orderNumber],
};

export const TourBody = ({
     show = false,
     children,
     attribute: {
          className = '',
          layout = LAYOUT.IMAGE_LEFT,
          imageSrc = '',
          imageAlt = '',
          nextButton = 'Next',
          skipButton = 'Skip the tour',
          showBackButton = true,
          // showCloseButton = false,
          showSkipButton = true,
          showCloseButton = false,
          dropoutButton = 'No, thanks',
          showDropoutButton = false,
          roadMapButton = 'GO TO ROADMAP',
          showButtonRoadMap = false,
          hideNextButton = false,
     },
     backTour,
     nextTour,
     skipTour,
     // closeTour
     closeTour,
     dropoutTour,
     roadMapTour,
}) => {
     return (
          <div className={`tour-guide ${className}${show ? ' show' : ''}${layout === LAYOUT.IMAGE_TOP ? ' layout-top' : ''}`}>
               {show && (layout === LAYOUT.IMAGE_LEFT || layout === LAYOUT.IMAGE_TOP) && !!imageSrc && (
                    <div className='tour-guide-image'>
                         <img className='larry' src={imageSrc} alt={imageAlt} />
                    </div>
               )}

               {show && children && (
                    <div className='tour-guide-content'>
                         {showBackButton && (
                              <CButton className='back-button' onClick={() => backTour()}>
                                   <CIcon name='icon-chevron-left-alt' />
                                   Go back
                              </CButton>
                         )}
                         {children}
                         <div className='buttons'>
                              {showButtonRoadMap && (
                                   <CButton className={'next-button'} onClick={() => roadMapTour()}>
                                        {roadMapButton}
                                   </CButton>
                              )}
                              {!hideNextButton && (
                                   <CButton className='next-button' onClick={() => nextTour()}>
                                        {nextButton}
                                   </CButton>
                              )}
                              {!!showDropoutButton && (
                                   <CButton className='skip-button' onClick={() => dropoutTour()}>
                                        {dropoutButton}
                                   </CButton>
                              )}
                              {!!showSkipButton && (
                                   <CButton className='skip-button' onClick={() => skipTour()}>
                                        {skipButton}
                                   </CButton>
                              )}
                              {showCloseButton && (
                                   <CButton className='close-button' onClick={() => closeTour()}>
                                        Close tour
                                   </CButton>
                              )}
                         </div>
                    </div>
               )}

               {show && layout === LAYOUT.IMAGE_RIGHT && !!imageSrc && (
                    <div className='tour-guide-image'>
                         <img className='larry' src={imageSrc} alt={imageAlt} />
                    </div>
               )}
          </div>
     );
};

const TheTour = () => {
     const history = useHistory();
     const dispatch = useDispatch();
     const user = useSelector((state) => state.theme.user);
     const accounts = useSelector((state) => state.subscriber.accounts);
     const { minimizeNav } = useSelector((state) => state.subscriber.newLayout);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const { stepTour, showTour } = useSelector((state) => state.subscriber.tour);
     const showPublishPopup = useSelector((state) => state.subscriber.showPublishPopup);
     const { running: previewRunning } = useSelector((state) => state.subscriber.preview);
     const numberOfHistories = useSelector((state) => state.subscriber.numberOfHistories);
     const activeDestinations = useSelector((state) => state.subscriber.activeDestinations);
     const isCustomerSupportLoginAs = useSelector((state) => state.subscriber.isCustomerSupportLoginAs);
     const dataStepCompleted = useSelector((state) => state.subscriber.setupGuideDataCompleted);
     const { currentStepFormCategories, currentStepCustomForms, currentStepConversion } = activeAccount;
     // const stepFormCategories = useSelector((state) => state.subscriber.stepFormCategories);
     const activeListeners = useSelector((state) => state.subscriber.activeListeners);
     const skipWizardFormCategory = useSelector((state) => state.subscriber.skipWizardFormCategory);

     const flexibleModal = useSelector((state) => state.theme.flexibleModal);
     const [offsetTop, setOffsetTop] = useState(0);
     const [accountOffsetTop, setAccountOffsetTop] = useState(0);
     const [showDestination, setShowDestination] = useState(false);
     const currentStepConversionRef = useRef(currentStepConversion);

     // Tour category from wizard
     const [foundForm, setFoundForm] = useState(false);
     const listFromCategory = useSelector((state) => state.subscriber.fromCategory);
     const listFormRequiringAction = useSelector((state) => state.subscriber.fromRequiring);
     const listFromRequiringChild = listFormRequiringAction && listFormRequiringAction.length > 0 && listFormRequiringAction.filter((item) => !item.hasChild);
     const listenerFormsEnabled = useSelector((state) => state.subscriber.listenerFormsEnabled);
     // const showTourFromRoadMap = useSelector(state => state.subscriber.showTourFromRoadMap);
     const finishRoadMap = currentStepFormCategories && currentStepFormCategories.trim() !== STEPS_WIZARD_CATEGORIES.yourDone && listFormRequiringAction && listFormRequiringAction.length > 0 && listFromRequiringChild.length > 0;

     const isSmallScreen = useMediaQuery(1431);

     const fetchData = () => {
          if (!listFromCategory) {
               const nextDispatch = {
                    setFoundForm,
               };
               dispatch(handleFetchFormCategories(nextDispatch));
          }
     };

     useEffect(fetchData, [showTour]);

     useEffect(() => {
          if (stepTour === TOURSTEPS.activeGTMDestinationsWizard.orderNumber && showTour) {
               setTimeout(() => {
                    const el = document.querySelector('.c-active').offsetTop;
                    document.querySelector('.active-gtm-destination-wizard').setAttribute('style', `top:${Number(el) + 80}px`);
               }, 100);
          }
     }, [showTour, stepTour]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          dispatch(handleSetListenerFormsEnabled());
          if (listFromCategory && listFromCategory.length > 0) {
               setFoundForm(true);
          }
     }, [listFromCategory]); // eslint-disable-line react-hooks/exhaustive-deps

     const getCaseFromCategory = () => {
          const tag = document.querySelector('.menu-listener-custom-forms') || '';
          if (!listenerFormsEnabled || !listenerFormsEnabled.length) {
               if (tag) {
                    tag.classList.add('no-listener');
               }
               return 'NoListener';
          }
          if (foundForm && listenerFormsEnabled.length > 0) {
               if (tag) {
                    tag.classList.add('get-started');
               }
               return 'GetStarted';
          }
          if (!foundForm && listenerFormsEnabled.length > 0) {
               if (tag) {
                    tag.classList.add('categoriesForms');
               }
               return 'CategoriesForms';
          }

          return '';
     };

     const { id: accountId, domains } = activeAccount;
     const initialDomains = domains ? tryParseJSON(domains) : [];
     const accountDomain = initialDomains && initialDomains.length > 0 ? initialDomains[0].name : '';
     const scriptInstalled = initialDomains && initialDomains.length > 0 && initialDomains.some((domain) => domain.scriptInstalled);
     const handleSetFlexibleModal = (component, category) => {
          dispatch(
               setFlexibleModal({
                    show: true,
                    component,
                    category,
               })
          );
     };

     useEffect(() => {
          if (showTour && stepTour === TOURSTEPS.menuFormCategory.orderNumber) {
               getCaseFromCategory();
          }

          const listStepFormCategory = [TOURSTEPS.requiringActionFormCategoryWizard.orderNumber, TOURSTEPS.editFormCategoriesWizard.orderNumber, TOURSTEPS.formsInventoryWizard.orderNumber, TOURSTEPS.formsRuleWizard.orderNumber];

          if (showTour && listStepFormCategory.includes(stepTour) && finishRoadMap) {
               if (stepTour === TOURSTEPS.requiringActionFormCategoryWizard.orderNumber) {
                    const el = document.querySelector('.tour-guide.requiring-action-form-category');
                    if (el) {
                         el.style.top = `calc(var(--fixTop) + 21%)`;
                    }
               }
               if (stepTour === TOURSTEPS.editFormCategoriesWizard.orderNumber) {
                    const el = document.querySelector('.tour-guide.edit-forms-categories');
                    if (el) {
                         el.style.top = `calc(var(--fixTop) + 27%)`;
                    }
               }
               if (stepTour === TOURSTEPS.formsInventoryWizard.orderNumber) {
                    const el = document.querySelector('.tour-guide.forms-inventory');
                    if (el) {
                         el.style.top = `calc(var(--fixTop) + 33%)`;
                    }
               }
               if (stepTour === TOURSTEPS.formsRuleWizard.orderNumber) {
                    const el = document.querySelector('.tour-guide.forms-rule');
                    if (el) {
                         el.style.top = `calc(var(--fixTop) + 40%)`;
                    }
               }
          }

          return () => {
               if (stepTour === TOURSTEPS.menuFormCategory.orderNumber) {
                    document.body.classList.add('no-scroll');
               }
          };
     }, [showTour, stepTour]); // eslint-disable-line react-hooks/exhaustive-deps

     const fetchPosition = () => {
          if (showTour) {
               setTimeout(() => {
                    const headerNav = document.querySelector('header > .c-header-nav');
                    if (headerNav) {
                         var headerRect = headerNav.getBoundingClientRect();
                         setOffsetTop(`${headerRect.top}px`);
                    }
               }, 350);
          }
     };
     useEffect(fetchPosition, [showTour, stepTour, accounts, activeAccount.id]);

     const fetchUserAccountsPosition = () => {
          if (stepTour === TOURSTEPS.listenerAccounts.orderNumber) {
               setTimeout(() => {
                    const createAccountButton = document.querySelector('.switch-account-nav .account-select .add-account');
                    if (createAccountButton) {
                         var buttonRect = createAccountButton.getBoundingClientRect();
                         setAccountOffsetTop(`${buttonRect.top}px`);
                    }
               }, 350);
          } else if (accountOffsetTop === 0 && accounts && accounts.length > 0) {
               const numberAccount = accounts.length;
               if (numberAccount < 8) {
                    setAccountOffsetTop(`${numberAccount * 37 + 53}px`);
               } else if (numberAccount < 11) {
                    setAccountOffsetTop(`${54 + numberAccount * 37 + 53}px`);
               } else {
                    setAccountOffsetTop('502px');
               }
          }
     };
     useEffect(fetchUserAccountsPosition, [showTour, stepTour, accounts, activeAccount.id]);

     const disableScrollbar = () => {
          if (showTour) {
               setTimeout(() => document.body.classList.add('no-scroll'));
          } else {
               document.body.classList.remove('no-scroll');
          }
     };
     useEffect(disableScrollbar, [showTour]);

     const fetchComponent = () => {
          if (isCustomerSupportLoginAs) {
               dispatch(setTour({ showTour: false }));
          } else if (showTour) {
               handleShowTour();
          }
     };
     useEffect(fetchComponent, [showTour, isCustomerSupportLoginAs]);

     const handleScrollToEventReporting = () => {
          const dashboardEvent = document.querySelector('.global-reporting .dashboard-event');
          if (dashboardEvent) {
               var dashboardEventRect = dashboardEvent.getBoundingClientRect();
               // if (dashboardEventRect.bottom > window.innerHeight) {
               // const scrollTop = window.scrollY + (dashboardEventRect.bottom - window.innerHeight) + 20;
               const scrollTop = dashboardEventRect.top + window.scrollY - 300;
               window.scroll({ top: scrollTop, left: 0, behavior: 'smooth' });
               // }
          }
          // dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.INSIGHTS }));
     };

     const handleScrollTriggerDefault = () => {
          const triggerDefault = document.querySelector('.moveable-rules.default-rules');
          if (triggerDefault) {
               var triggerDefaultRect = triggerDefault.getBoundingClientRect();
               const scrollTop = triggerDefaultRect.top + window.scrollY - 300;
               window.scrollTo({ top: scrollTop, behavior: 'smooth' });
          }
     };

     const handleScrollTop = () => {
          if (window) {
               window.scrollTo({ top: 0, behavior: 'smooth' });
          }
     };

     const handleShowTour = (currentStepTour = null) => {
          if (!currentStepTour) {
               currentStepTour = stepTour;
          }

          if ([...TOUR_FORCE_OPEN.menuLv1, ...TOUR_FORCE_OPEN.menuLv2].includes(currentStepTour)) {
               if (minimizeNav) {
                    dispatch(setNewLayout({ minimizeNav: false, forceShowNavChild: false }));
               } else {
                    dispatch(setNewLayout({ forceShowNavChild: false }));
               }
          }

          switch (currentStepTour) {
               case TOURSTEPS.eventReporting.orderNumber:
                    if (history.location.pathname !== '/') {
                         history.push('/');
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.INSIGHTS, forceShowNavChild: false }));
                         setTimeout(() => {
                              handleScrollToEventReporting();
                         }, 1100);
                    } else {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.INSIGHTS, forceShowNavChild: false }));
                         handleScrollToEventReporting();
                    }
                    break;
               case TOURSTEPS.publish.orderNumber:
                    if (history.location.pathname !== '/') {
                         history.push('/');
                    }
                    dispatch(setNewLayout({ activeMainNavItem: null, minimizeNav: false }));
                    break;
               case TOURSTEPS.mainMenuDashboard.orderNumber:
                    if (history.location.pathname !== '/') {
                         history.push('/');
                    }
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.INSIGHTS, minimizeNav: false }));
                    break;
               case TOURSTEPS.mainMenuDestination.orderNumber:
                    if (history.location.pathname !== '/') {
                         history.push('/');
                    }
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
                    break;
               case TOURSTEPS.mainMenuMonitoring.orderNumber:
                    if (history.location.pathname !== SUBSCRIBER_PATH.MONITORING_DASHBOARD) {
                         history.push(SUBSCRIBER_PATH.MONITORING_DASHBOARD);
                    }
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.MONITORING, forceShowNavChild: true }));
                    break;
               case TOURSTEPS.mainMenuAnalytics.orderNumber:
                    if (history.location.pathname !== '/') {
                         history.push('/');
                    }
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.INSIGHTS, forceShowNavChild: true }));
                    break;
               case TOURSTEPS.menuConsent.orderNumber:
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.CONSENT_PRIVACY }));
                    history.push(SUBSCRIBER_PATH.CONSENT_RULES);
                    break;
               case TOURSTEPS.menuListenerLibrary.orderNumber:
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS }));
                    break;
               case TOURSTEPS.menuActiveListeners.orderNumber:
                    if (history.location.pathname !== '/') {
                         history.push('/');
                    }
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS, forceShowNavChild: true }));
                    break;
               case TOURSTEPS.menuCustomData.orderNumber:
                    if (history.location.pathname !== '/') {
                         history.push('/');
                    }
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS, forceShowNavChild: true }));
                    break;
               case TOURSTEPS.menuFormCategory.orderNumber: {
                    if (history.location.pathname !== SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId)) {
                         history.push(SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId));
                    }
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                    break;
               }

               case TOURSTEPS.roadMapFormCategory.orderNumber: {
                    const accordion = null;
                    const pathName = history.location.pathname;
                    const typeAccordion = accordion !== ACCORDION_TYPES.FORM_REQUIRING_ACTION && accordion !== ACCORDION_TYPES.FORM_CATEGORIES && accordion !== ACCORDION_TYPES.FORM_INVENTORY && accordion !== ACCORDION_TYPES.FORM_RULES;
                    handleScrollTop();
                    if (currentStepFormCategories !== STEPS_WIZARD_CATEGORIES.defineYourFormCategories && finishRoadMap && typeAccordion) {
                         if (history.location.pathname !== SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId)) {
                              history.push(SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId));
                              dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                         } else {
                              dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                         }
                         dispatch(handleSetAccordion({ accordion, pathName }));
                         break;
                    }
               }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.requiringActionFormCategoryWizard.orderNumber: {
                    const accordion = ACCORDION_TYPES.FORM_REQUIRING_ACTION;
                    const pathName = history.location.pathname;

                    if (history.location.pathname !== SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId)) {
                         history.push(SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId));
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                         dispatch(handleSetAccordion({ accordion, pathName }));
                    } else {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                         dispatch(handleSetAccordion({ accordion, pathName }));
                    }
                    break;
               }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.editFormCategoriesWizard.orderNumber: {
                    const accordion = ACCORDION_TYPES.FORM_CATEGORIES;
                    const pathName = history.location.pathname;

                    if (history.location.pathname !== SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId)) {
                         history.push(SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId));
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                         dispatch(handleSetAccordion({ accordion, pathName }));
                    } else {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                         dispatch(handleSetAccordion({ accordion, pathName }));
                    }
                    dispatch(setShowTourCompletedWizard({ wizardFormCategory: false }));
                    break;
               }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.formsInventoryWizard.orderNumber: {
                    const accordion = ACCORDION_TYPES.FORM_INVENTORY;
                    const pathName = history.location.pathname;

                    if (history.location.pathname !== SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId)) {
                         history.push(SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId));
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                         dispatch(handleSetAccordion({ accordion, pathName }));
                    } else {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                         dispatch(handleSetAccordion({ accordion, pathName }));
                    }
                    break;
               }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.formsRuleWizard.orderNumber: {
                    const accordion = ACCORDION_TYPES.FORM_RULES;
                    const pathName = history.location.pathname;

                    if (history.location.pathname !== SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId)) {
                         history.push(SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId));
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                         dispatch(handleSetAccordion({ accordion, pathName }));
                    } else {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                         dispatch(handleSetAccordion({ accordion, pathName }));
                    }
                    break;
               }
               // eslint-disable-next-line no-fallthrough
               // case TOURSTEPS.notifications.orderNumber: {
               // 	const accordion = null;
               // 	const pathName = history.location.pathname;
               // 	if (history.location.pathname !== SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId)) {
               // 		history.push(SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId));
               // 		dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
               // 		dispatch(handleSetAccordion({ accordion, pathName }));
               // 		break;
               // 	} else {
               // 		dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
               // 		dispatch(handleSetAccordion({ accordion, pathName }));
               // 		break;
               // 	}
               // }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.menuListenerCustomForms.orderNumber: {
                    let customForms = null;

                    const stepStartCustomForms = currentStepCustomForms === STEPS_CUSTOM_FORM.configureCustomForms || currentStepCustomForms === '' || !currentStepCustomForms;

                    if (activeListeners && activeListeners.length > 0) {
                         customForms = activeListeners.find((customForm) => customForm.code === 'customForms');
                    }

                    if (stepStartCustomForms && customForms) {
                         if (history.location.pathname !== SUBSCRIBER_PATH.CUSTOM_HTML_FORMS.replace(':secondId', activeAccount.secondId)) {
                              history.push(SUBSCRIBER_PATH.CUSTOM_HTML_FORMS.replace(':secondId', activeAccount.secondId));
                         }
                         if (isSmallScreen) {
                              dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS, forceShowNavChild: true, forceMinimizeNav: true }));
                         } else {
                              dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS, forceShowNavChild: true }));
                         }
                         break;
                    }
               }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.configureConversion.orderNumber: {
                    if (history.location.pathname !== SUBSCRIBER_PATH.GOALS_WEB_CONVERSIONS.replace(':secondId', activeAccount.secondId)) {
                         history.push(SUBSCRIBER_PATH.GOALS_WEB_CONVERSIONS.replace(':secondId', activeAccount.secondId));
                    }
                    if (isSmallScreen) {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true, forceMinimizeNav: true }));
                    } else {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                    }
                    break;
               }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.previewConfigureConversion.orderNumber: {
                    if (history.location.pathname !== SUBSCRIBER_PATH.GOALS_WEB_CONVERSIONS.replace(':secondId', activeAccount.secondId)) {
                         history.push(SUBSCRIBER_PATH.GOALS_WEB_CONVERSIONS.replace(':secondId', activeAccount.secondId));
                    }

                    if (isSmallScreen) {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true, forceMinimizeNav: true }));
                    } else {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                    }

                    currentStepConversionRef.current = currentStepConversion;

                    break;
               }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.previewTrigger.orderNumber: {
                    if (history.location.pathname !== SUBSCRIBER_PATH.GOALS_WEB_TRIGGERS.replace(':secondId', activeAccount.secondId)) {
                         setTimeout(() => {
                              handleScrollTriggerDefault();
                         }, 1000);
                         history.push(SUBSCRIBER_PATH.GOALS_WEB_TRIGGERS.replace(':secondId', activeAccount.secondId));
                    }
                    if (isSmallScreen) {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true, forceMinimizeNav: true }));
                    } else {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                    }
                    break;
               }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.previewTriggerSecond.orderNumber: {
                    if (history.location.pathname !== SUBSCRIBER_PATH.GOALS_WEB_TRIGGERS.replace(':secondId', activeAccount.secondId)) {
                         history.push(SUBSCRIBER_PATH.GOALS_WEB_TRIGGERS.replace(':secondId', activeAccount.secondId));
                    }
                    if (isSmallScreen) {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true, forceMinimizeNav: true }));
                    } else {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                    }
                    break;
               }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.learnDestination.orderNumber: {
                    if (history.location.pathname !== SUBSCRIBER_PATH.GOALS_WEB_TRIGGERS.replace(':secondId', activeAccount.secondId)) {
                         history.push(SUBSCRIBER_PATH.GOALS_WEB_TRIGGERS.replace(':secondId', activeAccount.secondId));
                    }
                    if (isSmallScreen) {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true, forceMinimizeNav: true }));
                    } else {
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                    }
                    break;
               }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.roadMapCustomForms.orderNumber: {
                    let roadMap = null;
                    const accordion = null;
                    const pathName = history.location.pathname;
                    const leaveStepCustomForms = localStorage.getItem('LeaveStepCustomForms');
                    const typeAccordion = accordion !== ACCORDION_TYPES.TARGET_FORMS && accordion !== ACCORDION_TYPES.IFRAME_FORMS;
                    if (activeListeners && activeListeners.length > 0) {
                         roadMap = activeListeners.find((customForm) => customForm.code === 'customForms');
                    }
                    if (currentStepCustomForms !== STEPS_CUSTOM_FORM.configureCustomForms && roadMap && leaveStepCustomForms && leaveStepCustomForms === 'true' && typeAccordion) {
                         if (history.location.pathname !== SUBSCRIBER_PATH.CUSTOM_HTML_FORMS.replace(':secondId', activeAccount.secondId)) {
                              history.push(SUBSCRIBER_PATH.CUSTOM_HTML_FORMS.replace(':secondId', activeAccount.secondId));
                              dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS, forceShowNavChild: true }));
                         } else {
                              dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS, forceShowNavChild: true }));
                         }
                         dispatch(handleSetAccordion({ accordion, pathName }));
                         break;
                    }
               }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.targetCustomForms.orderNumber: {
                    let targetCustomForms = null;
                    const accordion = ACCORDION_TYPES.TARGET_FORMS;
                    const pathName = history.location.pathname;
                    const leaveStepCustomForms = localStorage.getItem('LeaveStepCustomForms');
                    if (activeListeners && activeListeners.length > 0) {
                         targetCustomForms = activeListeners.find((customForm) => customForm.code === 'customForms');
                    }
                    if (currentStepCustomForms !== STEPS_CUSTOM_FORM.manageCustomForms && targetCustomForms) {
                         if (history.location.pathname !== SUBSCRIBER_PATH.CUSTOM_HTML_FORMS.replace(':secondId', activeAccount.secondId)) {
                              history.push(SUBSCRIBER_PATH.CUSTOM_HTML_FORMS.replace(':secondId', activeAccount.secondId));
                              if (isSmallScreen) {
                                   dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS, forceShowNavChild: true, forceMinimizeNav: true }));
                              } else {
                                   dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS, forceShowNavChild: true }));
                              }
                         } else {
                              dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS, forceShowNavChild: true }));
                              if (!leaveStepCustomForms || leaveStepCustomForms === 'true') {
                                   dispatch(handleSetAccordion({ accordion, pathName }));
                              }
                         }
                    }
                    dispatch(handleSetAccordion({ accordion, pathName }));
                    break;
               }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.iFrameCustomForms.orderNumber: {
                    let iFrameCustomForms = null;
                    const accordion = ACCORDION_TYPES.IFRAME_FORMS;
                    const pathName = history.location.pathname;
                    if (activeListeners && activeListeners.length > 0) {
                         iFrameCustomForms = activeListeners.find((customForm) => customForm.code === 'customForms');
                    }
                    if (currentStepCustomForms !== STEPS_CUSTOM_FORM.manageCustomForms && iFrameCustomForms) {
                         history.push(SUBSCRIBER_PATH.CUSTOM_HTML_FORMS.replace(':secondId', activeAccount.secondId));
                    }
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS, forceShowNavChild: true }));
                    dispatch(handleSetAccordion({ accordion, pathName }));
                    dispatch(setShowTourCompletedWizard({ wizardCustomForm: true }));
                    break;
               }
               // eslint-disable-next-line no-fallthrough
               case TOURSTEPS.menuTriggerAndConversions.orderNumber:
                    if (history.location.pathname !== SUBSCRIBER_PATH.GOALS_WEB_TRIGGERS.replace(':secondId', activeAccount.secondId)) {
                         history.push(SUBSCRIBER_PATH.GOALS_WEB_TRIGGERS.replace(':secondId', activeAccount.secondId));
                    }
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.GOALS, forceShowNavChild: true }));
                    break;
               case TOURSTEPS.menuDestinationLibrary.orderNumber:
                    if (history.location.pathname !== '/') {
                         history.push('/');
                    }
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
                    break;
               case TOURSTEPS.menuActiveDestinations.orderNumber: {
                    let gtm = null;
                    if (activeDestinations && activeDestinations.length > 0) {
                         gtm = activeDestinations.find((destination) => destination.code === 'googleTagManager');
                    }
                    if (gtm) {
                         if (history.location.pathname !== `/${activeAccount.secondId}/destinations/${gtm.alias}`) {
                              history.push(`/${activeAccount.secondId}/destinations/${gtm.alias}`);
                         }
                    } else {
                         if (history.location.pathname !== '/') {
                              history.push('/');
                         }
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
                    }
                    break;
               }
               case TOURSTEPS.popupDestinationsWizard.orderNumber: {
                    let gtmWizard = null;
                    if (activeDestinations && activeDestinations.length > 0) {
                         gtmWizard = activeDestinations.find((destination) => destination.code === 'googleTagManager');
                    }
                    if (gtmWizard) {
                         if (history.location.pathname !== `/${activeAccount.secondId}/destinations/${gtmWizard.alias}`) {
                              history.push(`/${activeAccount.secondId}/destinations/${gtmWizard.alias}`);
                         }
                    } else {
                         if (history.location.pathname !== '/') {
                              history.push('/');
                         }
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
                    }
                    handleSetFlexibleModal(COMPONENT_NAME.DESTINATION_LIBRARY);
                    break;
               }
               case TOURSTEPS.menuCreateDestinationsWizard.orderNumber: {
                    let gtmWizard = null;
                    if (activeDestinations && activeDestinations.length > 0) {
                         gtmWizard = activeDestinations.find((destination) => destination.code === 'googleTagManager');
                    }
                    if (gtmWizard) {
                         if (history.location.pathname !== `/${activeAccount.secondId}/destinations/${gtmWizard.alias}`) {
                              history.push(`/${activeAccount.secondId}/destinations/${gtmWizard.alias}`);
                         }
                    } else {
                         if (history.location.pathname !== '/') {
                              history.push('/');
                         }
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
                    }
                    handleSetFlexibleModal(COMPONENT_NAME.DESTINATION_LIBRARY);
                    break;
               }
               case TOURSTEPS.centerPopupDestinationsWizard.orderNumber: {
                    let centerWizard = null;
                    if (activeDestinations && activeDestinations.length > 0) {
                         centerWizard = activeDestinations.find((destination) => destination.code === 'googleTagManager');
                    }
                    if (centerWizard) {
                         if (history.location.pathname !== `/${activeAccount.secondId}/destinations/${centerWizard.alias}`) {
                              history.push(`/${activeAccount.secondId}/destinations/${centerWizard.alias}`);
                         }
                    } else {
                         if (history.location.pathname !== '/') {
                              history.push('/');
                         }
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
                    }
                    handleSetFlexibleModal(COMPONENT_NAME.DESTINATION_LIBRARY);
                    break;
               }
               case TOURSTEPS.createDestinationsWizard.orderNumber: {
                    let createWizard = null;

                    if (activeDestinations && activeDestinations.length > 0) {
                         createWizard = activeDestinations.find((destination) => destination.code === 'googleTagManager');
                    }
                    if (createWizard) {
                         if (history.location.pathname !== `/${activeAccount.secondId}/destinations/${createWizard.alias}`) {
                              history.push(`/${activeAccount.secondId}/destinations/${createWizard.alias}`);
                         }
                    } else {
                         if (history.location.pathname !== '/') {
                              history.push('/');
                         }
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
                    }
                    handleSetFlexibleModal(COMPONENT_NAME.DESTINATION_LIBRARY);
                    break;
               }
               case TOURSTEPS.activeGTMDestinationsWizard.orderNumber: {
                    let activeGTMDWizard = null;
                    if (activeDestinations && activeDestinations.length > 0) {
                         activeGTMDWizard = activeDestinations.find((destination) => destination.code === 'googleTagManager');
                    }
                    if (activeGTMDWizard) {
                         if (history.location.pathname !== `/${activeAccount.secondId}/destinations/${activeGTMDWizard.alias}`) {
                              history.push(`/${activeAccount.secondId}/destinations/${activeGTMDWizard.alias}`);
                         }
                         if (isSmallScreen) {
                              dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true, forceMinimizeNav: true }));
                         } else {
                              dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
                         }
                    } else {
                         if (history.location.pathname !== '/') {
                              history.push('/');
                         }
                         if (isSmallScreen) {
                              dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true, forceMinimizeNav: true }));
                         } else {
                              dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
                         }
                    }
                    handleSetFlexibleModal(COMPONENT_NAME.DESTINATION_LIBRARY);
                    break;
               }
               case TOURSTEPS.accordionGTMDestinationsWizard.orderNumber: {
                    let activeGTMWizard = null;
                    if (activeDestinations && activeDestinations.length > 0) {
                         activeGTMWizard = activeDestinations.find((destination) => destination.code === 'googleTagManager');
                    }

                    if (flexibleModal && flexibleModal.component !== '') {
                         dispatch(
                              setFlexibleModal({
                                   show: false,
                                   component: '',
                                   category: '',
                              })
                         );
                    }

                    if (activeGTMWizard) {
                         if (history.location.pathname !== `/${activeAccount.secondId}/destinations/${activeGTMWizard.alias}`) {
                              history.push(`/${activeAccount.secondId}/destinations/${activeGTMWizard.alias}`);
                         }
                    } else {
                         if (history.location.pathname !== '/') {
                              history.push('/');
                         }
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
                    }
                    break;
               }
               case TOURSTEPS.contentGTMDestinationsWizard.orderNumber: {
                    let contentGTMD = null;
                    if (activeDestinations && activeDestinations.length > 0) {
                         contentGTMD = activeDestinations.find((destination) => destination.code === 'googleTagManager');
                    }

                    if (contentGTMD) {
                         if (history.location.pathname !== `/${activeAccount.secondId}/destinations/${contentGTMD.alias}`) {
                              history.push(`/${activeAccount.secondId}/destinations/${contentGTMD.alias}`);
                         }
                    } else {
                         if (history.location.pathname !== '/') {
                              history.push('/');
                         }
                    }
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
                    break;
               }
               case TOURSTEPS.addMoreDestinationsWizard.orderNumber: {
                    let addMoreDestination = null;
                    if (activeDestinations && activeDestinations.length > 0) {
                         addMoreDestination = activeDestinations.find((destination) => destination.code === 'googleTagManager');
                    }

                    if (addMoreDestination) {
                         if (history.location.pathname !== `/${activeAccount.secondId}/destinations/${addMoreDestination.alias}`) {
                              history.push(`/${activeAccount.secondId}/destinations/${addMoreDestination.alias}`);
                         }
                    } else {
                         if (history.location.pathname !== '/') {
                              history.push('/');
                         }
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
                    }
                    break;
               }
               case TOURSTEPS.chooseDestinationWizard.orderNumber: {
                    let chooseDestination = null;
                    if (activeDestinations && activeDestinations.length > 0) {
                         chooseDestination = activeDestinations.find((destination) => destination.code === 'googleTagManager');
                    }

                    if (chooseDestination) {
                         if (history.location.pathname !== `/${activeAccount.secondId}/destinations/${chooseDestination.alias}`) {
                              history.push(`/${activeAccount.secondId}/destinations/${chooseDestination.alias}`);
                         }
                    } else {
                         if (history.location.pathname !== '/') {
                              history.push('/');
                         }
                         dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
                    }
                    setShowDestination(true);
                    break;
               }
               // case TOURSTEPS.endDestinationWizard.orderNumber: {
               // 	let endDestination = null;
               // 	if (activeDestinations && activeDestinations.length > 0) {
               // 		endDestination = activeDestinations.find(destination => destination.code === "googleTagManager");
               // 	}

               // 	if (endDestination) {
               // 		if (history.location.pathname !== `/${activeAccount.secondId}/destinations/${endDestination.alias}`) {
               // 			history.push(`/${activeAccount.secondId}/destinations/${endDestination.alias}`);
               // 		}
               // 		dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
               // 	} else {
               // 		if (history.location.pathname !== '/') {
               // 			history.push('/');
               // 		}
               // 		dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.DESTINATIONS, forceShowNavChild: true }));
               // 	}
               // 	break;
               // }
               case TOURSTEPS.menuCustomMonitoring.orderNumber:
                    history.push(SUBSCRIBER_PATH.CUSTOM_MONITORING_RULES);
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.MONITORING, forceShowNavChild: true }));
                    break;
               case TOURSTEPS.menuNotifications.orderNumber:
                    history.push(SUBSCRIBER_PATH.MONITORING_NOTIFICATIONS_V2);
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.MONITORING, forceShowNavChild: true }));
                    break;
               case TOURSTEPS.tourOver.orderNumber:
                    if (history.location.pathname !== '/') {
                         history.push('/');
                    }
                    dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.INSIGHTS, forceShowNavChild: false }));
                    break;
               default:
                    break;
          }
     };
     const roadMapWizard = JSON.parse(localStorage.getItem(`roadMapWizard${activeAccount.id}`)) || {};

     const nextTour = () => {
          const currentStepTour = parseInt(stepTour);
          // built tour Custom Forms Wizard
          const stepStartCustomForms = currentStepCustomForms === STEPS_CUSTOM_FORM.configureCustomForms || currentStepCustomForms === '' || !currentStepCustomForms;

          const stepStartFormCategory = currentStepFormCategories === STEPS_WIZARD_CATEGORIES.manageFormCategories || currentStepFormCategories === STEPS_WIZARD_CATEGORIES.yourDone || currentStepFormCategories === STEPS_WIZARD_CATEGORIES.categorizeAFewForms;
          // eslint-disable-next-line
          if (currentStepTour === TOURSTEPS.accordionGTMDestinationsWizard.orderNumber && roadMapWizard.type === 'Destination') {
               const newData = {
                    accountId: accountId,
                    step: 'sendingData',
                    data: {
                         learnAboutDestinations: true,
                    },
               };
               callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                    if (response.status === 200) {
                         dispatch(
                              setChildSetupGuideDataCompleted({
                                   key: 'sendingData',
                                   data: {
                                        learnAboutDestinations: true,
                                   },
                              })
                         );
                         //   dispatch(setSetupGuideRoadmap(5));
                         //   dispatch(setShowSetupGuidePopup(true));
                         //   dispatch(setTour({ showTour: false }));
                    } else {
                         toastError(response);
                    }
               });
          }

          if (currentStepTour === TOURSTEPS.previewTriggerSecond.orderNumber ) {
               const newData = {
                    accountId: accountId,
                    step: 'forCategorization',
                    data: {
                         buildWebConversions: true,
                    },
               };
               callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                    if (response.status === 200) {
                         dispatch(
                              setChildSetupGuideDataCompleted({
                                   key: 'forCategorization',
                                   data: {
                                        buildWebConversions: true,
                                   },
                              })
                         );
                         cookie.set(`stepTour${accountId}`, currentStepTour, { expires: 365 });
                    } else {
                         toastError(response);
                    }
               });
          }
          
          if(currentStepTour === TOURSTEPS.previewTriggerSecond.orderNumber && roadMapWizard && roadMapWizard.type !== 'Conversion'){
               dispatch(setTour({ showTour: false }));
               return;
          }

          if (currentStepTour < TOURSTEPS.tourOver.orderNumber) {
               let customForms = null;

               if (activeListeners && activeListeners.length > 0) {
                    customForms = activeListeners.find((customForm) => customForm.code === 'customForms');
               }

               const skipConfigCustomForms = currentStepTour === TOURSTEPS.menuListenerCustomForms.orderNumber && stepStartCustomForms && typeof customForms !== 'undefined';
               const skipConfigFormCategory = currentStepTour === TOURSTEPS.menuFormCategory.orderNumber;

               const skipConfigConversion = currentStepTour === TOURSTEPS.configureConversion.orderNumber;

               if (skipConfigFormCategory) {
                    const nextStepTour = TOURSTEPS.menuFormCategory.orderNumber;
                    dispatch(setTour({ stepTour: nextStepTour }));
                    cookie.set(`stepTour${accountId}`, nextStepTour, { expires: 365 });
                    document.querySelector('.tour-guide.menu-listener-custom-forms.show').setAttribute('style', `display: none`);
                    document.body.className = ''; //Removes all classes
                    return;
               } else if (skipConfigCustomForms) {
                    const nextStepTour = TOURSTEPS.menuListenerCustomForms.orderNumber;
                    dispatch(setTour({ stepTour: nextStepTour }));
                    cookie.set(`stepTour${accountId}`, nextStepTour, { expires: 365 });
                    document.querySelector('.tour-guide.menu-listener-custom-forms.show').setAttribute('style', `display: none`);
                    return;
               } else if (skipConfigConversion) {
                    const nextStepTour = TOURSTEPS.configureConversion.orderNumber;
                    dispatch(setTour({ stepTour: nextStepTour }));
                    cookie.set(`stepTour${accountId}`, nextStepTour, { expires: 365 });
                    document.querySelector('.tour-guide.configure-conversion.show').setAttribute('style', `display: none`);
                    return;
               }

               if (roadMapWizard && roadMapWizard.type === 'Category' && currentStepTour === TOURSTEPS.formsRuleWizard.orderNumber) {
                    const newData = {
                         accountId: accountId,
                         step: 'forCategorization',
                         data: {
                              categorizeYourForms: true,
                         },
                    };
                    callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setChildSetupGuideDataCompleted({
                                        key: 'forCategorization',
                                        data: {
                                             categorizeYourForms: true,
                                        },
                                   })
                              );
                              dispatch(setSetupGuideRoadmap(3));
                              dispatch(setShowSetupGuidePopup(true));
                              const nextStep = TOURSTEPS.menuListenerLibrary.orderNumber;
                              dispatch(setTour({ showTour: false, stepTour: nextStep }));
                              Cookies.set(`stepTour${activeAccount.id}`, nextStep, { expires: 365 });
                         } else {
                              toastError(response);
                         }
                    });
               } else if (roadMapWizard && roadMapWizard.type === 'CustomForm' && currentStepTour === TOURSTEPS.iFrameCustomForms.orderNumber) {
                    const newData = {
                         accountId: accountId,
                         step: 'customImplementation',
                         data: {
                              configureCustomForms: true,
                         },
                    };
                    callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setChildSetupGuideDataCompleted({
                                        key: 'customImplementation',
                                        data: {
                                             configureCustomForms: true,
                                        },
                                   })
                              );
                              dispatch(setSetupGuideRoadmap(2));
                              dispatch(setShowSetupGuidePopup(true));
                              dispatch(setTour({ showTour: false }));
                         } else {
                              toastError(response);
                         }
                    });
               }

               let nextStepTour = currentStepTour + 1;

               if (currentStepTour === TOURSTEPS.mainMenuAnalytics.orderNumber && stepStartFormCategory) {
                    nextStepTour = TOURSTEPS.menuListenerLibrary.orderNumber;
               }

               if (currentStepTour === TOURSTEPS.menuConsent.orderNumber) {
                    nextStepTour = TOURSTEPS.tourOver.orderNumber;
               }

               if (roadMapWizard && roadMapWizard.type === 'Conversion' && currentStepTour === TOURSTEPS.learnDestination.orderNumber) {
                    const newData = {
                         accountId: accountId,
                         step: 'forCategorization',
                         data: {
                              buildWebConversions: true,
                         },
                    };
                    callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setChildSetupGuideDataCompleted({
                                        key: 'forCategorization',
                                        data: {
                                             buildWebConversions: true,
                                        },
                                   })
                              );
                         } else {
                              toastError(response);
                         }
                    });
                    nextStepTour = TOURSTEPS.popupDestinationsWizard.orderNumber;
                    const stepData = { stepPause: 5, type: 'Destination' };
                    localStorage.setItem(`roadMapWizard${accountId}`, JSON.stringify(stepData));
               }

               if (currentStepTour === TOURSTEPS.contentGTMDestinationsWizard.orderNumber && activeDestinations && activeDestinations.length >= 3 && roadMapWizard.type === 'Destination') {
                    const newData = {
                         accountId: accountId,
                         step: 'sendingData',
                         data: {
                              learnAboutDestinations: true,
                         },
                    };
                    callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setChildSetupGuideDataCompleted({
                                        key: 'sendingData',
                                        data: {
                                             learnAboutDestinations: true,
                                        },
                                   })
                              );
                         } else {
                              toastError(response);
                         }
                    });
               }

               handleShowTour(nextStepTour);
               dispatch(setTour({ stepTour: nextStepTour }));
               cookie.set(`stepTour${accountId}`, nextStepTour, { expires: 365 });
          } else {
               closeTour();
               previewOnClick();
          }
     };

     const backToRoadMap = (currentStepTour) => {
          switch (currentStepTour) {
               case TOURSTEPS.menuFormCategory.orderNumber: {
                    dispatch(setSetupGuideRoadmap(3));
                    dispatch(setShowSetupGuidePopup(true));
                    dispatch(setTour({ showTour: false }));
                    break;
               }
               case TOURSTEPS.menuListenerCustomForms.orderNumber: {
                    dispatch(setSetupGuideRoadmap(2));
                    dispatch(setShowSetupGuidePopup(true));
                    dispatch(setTour({ showTour: false }));
                    break;
               }
               case TOURSTEPS.popupDestinationsWizard.orderNumber: {
                    dispatch(setSetupGuideRoadmap(5));
                    dispatch(setShowSetupGuidePopup(true));
                    dispatch(setTour({ showTour: false }));
                    dispatch(
                         setFlexibleModal({
                              show: false,
                              component: '',
                              category: '',
                         })
                    );
                    break;
               }
               case TOURSTEPS.requiringActionFormCategoryWizard.orderNumber: {
                    // if(showTourFromRoadMap) {
                    dispatch(setSetupGuideRoadmap(3));
                    dispatch(setShowSetupGuidePopup(true));
                    dispatch(setTour({ showTour: false }));
                    // } else {
                    // 	let previousStepTour = currentStepTour - 1;

                    // 	if (TOUR_FORCE_OPEN.scriptNotInstall.includes(previousStepTour)) {
                    // 		if (scriptInstalled) {
                    // 			previousStepTour = TOURSTEPS.eventReporting.orderNumber;
                    // 		}
                    // 	}
                    // 	handleShowTour(previousStepTour);
                    // 	dispatch(setTour({ stepTour: previousStepTour }));
                    // 	cookie.set(`stepTour${accountId}`, previousStepTour, { expires: 365 });
                    // }
                    break;
               }
               case TOURSTEPS.targetCustomForms.orderNumber: {
                    dispatch(setSetupGuideRoadmap(2));
                    dispatch(setShowSetupGuidePopup(true));
                    dispatch(setTour({ showTour: false }));
                    break;
               }
               case TOURSTEPS.previewConfigureConversion.orderNumber: {
                    dispatch(setSetupGuideRoadmap(3));
                    dispatch(setShowSetupGuidePopup(true));
                    dispatch(setTour({ showTour: false }));
                    break;
               }
               default:
                    break;
          }
     };

     const backTour = () => {
          const currentStepTour = cloneDeep(stepTour);
          if (currentStepTour === TOURSTEPS.menuFormCategory.orderNumber || currentStepTour === TOURSTEPS.menuListenerCustomForms.orderNumber || (currentStepTour === TOURSTEPS.popupDestinationsWizard.orderNumber && roadMapWizard.type !== 'Conversion') || currentStepTour === TOURSTEPS.requiringActionFormCategoryWizard.orderNumber || currentStepTour === TOURSTEPS.targetCustomForms.orderNumber || currentStepTour === TOURSTEPS.previewConfigureConversion.orderNumber) {
               backToRoadMap(currentStepTour);
          }
          let previousStepTour = currentStepTour - 1;

          const stepStartFormCategory = currentStepFormCategories === STEPS_WIZARD_CATEGORIES.manageFormCategories || currentStepFormCategories === STEPS_WIZARD_CATEGORIES.yourDone || currentStepFormCategories === STEPS_WIZARD_CATEGORIES.categorizeAFewForms;

          if (currentStepTour === TOURSTEPS.menuListenerLibrary.orderNumber) {
               previousStepTour = TOURSTEPS.mainMenuAnalytics.orderNumber;
          }

          if (currentStepTour === TOURSTEPS.menuCustomData.orderNumber) {
               previousStepTour = TOURSTEPS.menuListenerLibrary.orderNumber;
          }

          if (currentStepTour === TOURSTEPS.mainMenuMonitoring.orderNumber) {
               previousStepTour = TOURSTEPS.contentGTMDestinationsWizard.orderNumber;
          }

          if (currentStepTour === TOURSTEPS.popupDestinationsWizard.orderNumber && roadMapWizard.type === 'Conversion') {
               previousStepTour = TOURSTEPS.learnDestination.orderNumber;
               dispatch(setFlexibleModal({ show: false, component: '', category: '' }));
          }

          if (currentStepTour === TOURSTEPS.requiringActionFormCategoryWizard.orderNumber) {
               if (typeof finishRoadMap !== 'undefined' && finishRoadMap) {
                    previousStepTour = TOURSTEPS.roadMapFormCategory.orderNumber;
               } else if (!finishRoadMap && !skipWizardFormCategory && !stepStartFormCategory) {
                    previousStepTour = TOURSTEPS.menuFormCategory.orderNumber;
               } else {
                    previousStepTour = TOURSTEPS.mainMenuAnalytics.orderNumber;
               }
          }

          if (TOUR_FORCE_OPEN.scriptNotInstall.includes(previousStepTour)) {
               if (scriptInstalled) {
                    previousStepTour = TOURSTEPS.eventReporting.orderNumber;
               }
          }
          handleShowTour(previousStepTour);
          dispatch(setTour({ stepTour: previousStepTour }));
          cookie.set(`stepTour${accountId}`, previousStepTour, { expires: 365 });
     };

     const skipTour = () => {
          let customForms = null;

          if (activeListeners && activeListeners.length > 0) {
               customForms = activeListeners.find((customForm) => customForm.code === 'customForms');
          }

          const currentStepTour = stepTour >= TOURSTEPS.menuListenerCustomForms.orderNumber && stepTour < TOURSTEPS.menuCustomData.orderNumber;
          const currentTourFormCategory = stepTour >= TOURSTEPS.menuFormCategory.orderNumber && stepTour < TOURSTEPS.formsRuleWizard.orderNumber;

          const stepStartCustomForms = currentStepCustomForms === STEPS_CUSTOM_FORM.configureCustomForms || currentStepCustomForms === '' || !currentStepCustomForms;

          const stepStartFormCategory = currentStepFormCategories === STEPS_WIZARD_CATEGORIES.manageFormCategories || currentStepFormCategories === STEPS_WIZARD_CATEGORIES.yourDone || currentStepFormCategories === STEPS_WIZARD_CATEGORIES.categorizeAFewForms;

          if (stepTour === TOURSTEPS.roadMapFormCategory.orderNumber && customForms && stepStartCustomForms) {
               const nextStepTour = TOURSTEPS.requiringActionFormCategoryWizard.orderNumber;
               cookie.set(`stepTour${activeAccount.id}`, nextStepTour, { expires: 365 });
          } else if (stepTour === TOURSTEPS.roadMapCustomForms.orderNumber && customForms && !stepStartCustomForms) {
               const nextStepTour = TOURSTEPS.targetCustomForms.orderNumber;
               cookie.set(`stepTour${activeAccount.id}`, nextStepTour, { expires: 365 });
          }

          if (history.location.pathname === SUBSCRIBER_PATH.CUSTOM_HTML_FORMS.replace(':secondId', activeAccount.secondId)) {
               // Skip Tour Custom Form Wizard
               handleToggleSidebar({ toggleMinimizeNav: false, forceMinimizeNav: false, toggleShowNavChild: false, forceShowNavChild: false });
               dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS, forceShowNavChild: false }));
               dispatch(setTour({ showTour: false }));
               if (stepStartCustomForms && currentStepTour) {
                    const isShow = true;
                    dispatch(setShowStepCustomForm(isShow));
               }
          } else if (history.location.pathname === SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId)) {
               // Skip Tour Form Category Wizard
               handleToggleSidebar({ toggleMinimizeNav: false, forceMinimizeNav: false, toggleShowNavChild: false, forceShowNavChild: false });
               dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS, forceShowNavChild: false }));
               dispatch(setTour({ showTour: false }));
               if (stepStartFormCategory && currentTourFormCategory) {
                    const isShow = true;
                    dispatch(setShowStepFormCategory(isShow));
               }
          } else if (history.location.pathname !== '/' && history.location.pathname !== SUBSCRIBER_PATH.CUSTOM_HTML_FORMS.replace(':secondId', activeAccount.secondId) && history.location.pathname === SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId)) {
               history.push('/');
               handleToggleSidebar({ toggleMinimizeNav: true, forceMinimizeNav: false, toggleShowNavChild: true, forceShowNavChild: false });
               dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.INSIGHTS, forceShowNavChild: false }));
               dispatch(setTour({ showTour: false }));
          }
          if (history.location.pathname !== SUBSCRIBER_PATH.CUSTOM_HTML_FORMS.replace(':secondId', activeAccount.secondId) && history.location.pathname !== SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES.replace(':secondId', activeAccount.secondId) && stepTour !== TOURSTEPS.menuListenerCustomForms && stepTour !== TOURSTEPS.menuFormCategory) {
               handleToggleSidebar({ toggleMinimizeNav: false, forceMinimizeNav: false, toggleShowNavChild: false, forceShowNavChild: false });
               dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.INSIGHTS, forceShowNavChild: false }));
               dispatch(setTour({ showTour: false }));
          }

          if (flexibleModal && flexibleModal.component !== '') {
               dispatch(
                    setFlexibleModal({
                         show: false,
                         component: '',
                         category: '',
                    })
               );
          }

          handleToggleSidebar({ toggleMinimizeNav: true, forceMinimizeNav: false, toggleShowNavChild: true, forceShowNavChild: false });
          // dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.INSIGHTS, forceShowNavChild: false }));
          dispatch(setTour({ showTour: false }));
     };

     const closeTour = () => {
          if (history.location.pathname !== '/') {
               history.push('/');
          }
          handleToggleSidebar({ toggleMinimizeNav: true, forceMinimizeNav: false, toggleShowNavChild: true, forceShowNavChild: false });
          dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.INSIGHTS, forceShowNavChild: false }));
          cookie.set(`stepTour${accountId}`, 0, { expires: 365 });
          dispatch(setTour({ showTour: false, stepTour: 0 }));

          if (accountId) {
               callTokenApi(API_CLIENT_ACCOUNT_COMPLETE_TOUR, 'PUT', { userId: user.id, accountId }).then((response) => {
                    if (response.status === 200) {
                         if (!activeAccount.completedTour) {
                              dispatch(
                                   setActiveAccount({
                                        ...activeAccount,
                                        completedTour: true,
                                   })
                              );
                         }

                         if (!user.completedTour) {
                              dispatch(
                                   setUser({
                                        ...user,
                                        completedTour: true,
                                   })
                              );
                         }

                         const newAccounts = [...accounts].map((account) => {
                              if (account.id === activeAccount.id) {
                                   account.completedTour = true;
                              }
                              return account;
                         });
                         dispatch(setAccounts(newAccounts));
                    } else {
                         toastError(response);
                    }
               });
          }
     };

     const roadMapTour = () => {
          const currentStepTour = parseInt(cloneDeep(stepTour));
          const roadMapWizard = JSON.parse(localStorage.getItem(`roadMapWizard${activeAccount.id}`));

          if (roadMapWizard && roadMapWizard.type === 'Category' && (currentStepTour === TOURSTEPS.roadMapFormCategory.orderNumber || currentStepTour === TOURSTEPS.formsRuleWizard.orderNumber)) {
               dispatch(setSetupGuideRoadmap(3));
               dispatch(setShowSetupGuidePopup(true));
               dispatch(setTour({ showTour: false }));
               // Cookies.set(`stepTour${activeAccount.id}`, nextStep, { expires: 365 });
          } else if (roadMapWizard && roadMapWizard.type === 'CustomForm' && (currentStepTour === TOURSTEPS.roadMapCustomForms.orderNumber || currentStepTour === TOURSTEPS.iFrameCustomForms.orderNumber)) {
               dispatch(setSetupGuideRoadmap(2));
               dispatch(setShowSetupGuidePopup(true));
               dispatch(setTour({ showTour: false }));
               // Cookies.set(`stepTour${activeAccount.id}`, nextStep, { expires: 365 });
          } else if (roadMapWizard && roadMapWizard.type === 'Destination') {
               dispatch(setSetupGuideRoadmap(5));
               dispatch(setShowSetupGuidePopup(true));
               dispatch(setTour({ showTour: false }));
          } else if (roadMapWizard && roadMapWizard.type === 'Conversion' && currentStepTour === TOURSTEPS.learnDestination.orderNumber) {
               dispatch(setSetupGuideRoadmap(3));
               dispatch(setShowSetupGuidePopup(true));
               dispatch(setTour({ showTour: false }));
          } else {
               if (currentStepTour === TOURSTEPS.formsRuleWizard.orderNumber || currentStepTour === TOURSTEPS.iFrameCustomForms.orderNumber) {
                    dispatch(setTour({ showTour: false }));
               }
          }
     };

     const dropoutTour = () => {
          const currentStepTour = parseInt(cloneDeep(stepTour));
          const roadMapWizard = JSON.parse(localStorage.getItem(`roadMapWizard${activeAccount.id}`));

          const newData = {
               accountId: activeAccount.id,
               step: 'sendingData',
               data: {
                    learnAboutDestinations: true,
               },
          };
          if (roadMapWizard.type === 'Destination') {
               callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
                    if (response.status === 200) {
                         dispatch(
                              setChildSetupGuideDataCompleted({
                                   key: 'sendingData',
                                   data: {
                                        learnAboutDestinations: true,
                                   },
                              })
                         );
                    } else {
                         toastError(response);
                    }
               });
               dispatch(setSetupGuideRoadmap(5));
               dispatch(setShowSetupGuidePopup(true));
               dispatch(setTour({ showTour: false }));
          } else {
               if (currentStepTour < TOURSTEPS.tourOver.orderNumber) {
                    let nextStepTour = currentStepTour;

                    if (currentStepTour === TOURSTEPS.addMoreDestinationsWizard.orderNumber) {
                         nextStepTour = TOURSTEPS.mainMenuMonitoring.orderNumber;
                    }

                    handleShowTour(nextStepTour);
                    dispatch(setTour({ stepTour: nextStepTour }));
                    cookie.set(`stepTour${accountId}`, nextStepTour, { expires: 365 });
               } else {
                    closeTour();
                    previewOnClick();
               }
          }
     };

     const previewOnClick = () => {
          if (activeAccount.numberOfDomains < 1 && !showPublishPopup) {
               toast.warn('Domain settings incomplete. This is vital for your website tracking to work.');
               history.push(SUBSCRIBER_PATH.WEBSITE);
          } else {
               if (numberOfHistories > 0) {
                    dispatch(setShowPublishPopup(!showPublishPopup));
               } else {
                    if (previewRunning) {
                         document.body.classList.add('no-scroll');
                         dispatch(setTogglePreviewPopup(true));
                    } else if (activeAccount.numberOfDomains === 1) {
                         previewOnAccept();
                    } else {
                         dispatch(setShowPopupDomain(true));
                    }
               }
          }
     };

     const previewOnAccept = () => {
          document.body.classList.add('no-scroll');
          dispatch(setTogglePreviewPopup(true));
          dispatch(setShowPopupDomain(false));
          dispatch(setAccountPreview({ accountId: activeAccount.id }));

          const newInitDomains = activeAccount.domains ? JSON.parse(activeAccount.domains) : [];
          dispatch(setDomainsPreview(newInitDomains));
          if (newInitDomains && newInitDomains.length === 1) {
               dispatch(setDomainPreview(newInitDomains[0].name));
          }
     };
     const getComponent = (tourStepData) => {
          if (tourStepData.content) {
               return (
                    <div className='content'>
                         <p>{tourStepData.content}</p>
                    </div>
               );
          } else if (tourStepData.html) {
               return <div className='content' dangerouslySetInnerHTML={{ __html: tourStepData.html }} />;
          } else {
               switch (tourStepData.orderNumber) {
                    case TOURSTEPS.chooseDestinationWizard.orderNumber: {
                         return <ChooseDestination showDestination={showDestination} setShowDestination={setShowDestination} />;
                    }
                    case TOURSTEPS.scriptNotRunning.orderNumber:
                         return (
                              <div className='content'>
                                   <p className='notice-text'>It looks like the ListenLayer script isn’t running on your website {accountDomain}.</p>
                                   <p>After this tour is finished, you can click Get Started to complete your script placement.</p>
                              </div>
                         );
                    case TOURSTEPS.accessSettings.orderNumber:
                         if (scriptInstalled) {
                              return (
                                   <div className='content'>
                                        <p>Use this menu to access settings for this ListenLayer account - get your main script, add domains, manage account access, see your billing, and more.</p>
                                   </div>
                              );
                         }
                         return (
                              <div className='content'>
                                   <p>You can also get your script and place it manually by selecting Website in this menu.</p>
                                   <p className='sub-text'>This menu has links to other account settings that you should become familiar with. For example, configure ListenLayer to work across multiple domains, manage user access, and see your billing and invoice settings.</p>
                              </div>
                         );
                    case TOURSTEPS.tourOver.orderNumber:
                         if (scriptInstalled) {
                              return (
                                   <div className='content'>
                                        <p>And last but not least, ListenLayer allows you to test your website's data strategy directly in the browser (no add-ons required). You can interact with your website and see detailed data generated and collected in real-time.</p>
                                        <p>The tour is over, so I'd recommend launching a test right now!</p>
                                   </div>
                              );
                         }
                         return (
                              <div className='content'>
                                   <p>And last but not least, ListenLayer allows you to test your website's data strategy directly in the browser (no add-ons required). You can interact with your website and see detailed data generated and collected in real time.</p>
                                   <p>If you're confident that your script is placed on your website, go ahead and launch a test now, otherwise I'd recommend getting your script implemented first.</p>
                              </div>
                         );
                    default:
                         return <></>;
               }
          }
     };
     return (
          <>
               {showTour && <div className={`tour-overlay step-${stepTour}`} />}
               <div className='image-preload'>
                    <img className='larry' src='/assets/images/larry-greeting.svg' alt='larry-greeting' />
                    <img className='larry' src='/assets/images/larry-point-down.svg' alt='larry-point-down' />
                    <img className='larry' src='/assets/images/larry-thinking.svg' alt='larry-thinking' />
                    <img className='larry' src='/assets/images/larry-point-up-right.svg' alt='larry-point-up-right' />
                    <img className='larry' src='/assets/images/larry-point-up-left.svg' alt='larry-point-up-left' />
                    <img className='larry' src='/assets/images/larry-showing-left.svg' alt='larry-showing-left' />
               </div>
               <div className={`tour step-${stepTour}${showTour ? ' show' : ''}`} style={{ '--fixTop': offsetTop, '--accountFixTop': accountOffsetTop }}>
                    {showTour && (
                         <>
                              {Object.keys(TOURSTEPS).map((key) => {
                                   let tourStepData = {};

                                   if (key !== 'contentGTMDestinationsWizard' && key !== 'menuListenerCustomForms' && key !== 'menuFormCategory' && key !== 'createDestinationsWizard') {
                                        tourStepData = TOURSTEPS[key];
                                   }

                                   if (key === 'contentGTMDestinationsWizard') {
                                        if (activeDestinations && activeDestinations.length >= 3 && key === 'contentGTMDestinationsWizard') {
                                             tourStepData = {
                                                  orderNumber: 32,
                                                  name: 'Content GTM Destination Wizard',
                                                  className: 'content-gtm-destination-wizard',
                                                  imageSrc: '/assets/images/larry-thinking.svg',
                                                  imageAlt: 'larry-showing-left',
                                                  layout: LAYOUT.IMAGE_LEFT,
                                                  roadMapButton: 'GO TO ROADMAP',
                                                  hideNextButton: true,
                                                  showButtonRoadMap: true,
                                                  skipButton: 'No thanks, exit',
                                                  showBackButton: false,
                                                  html: `
										<p>Now anyone can configure a robust measurement strategy without being an expert in Tag Manager and all of the Destinations where you use the data!</p>
										<p>And we keep meticulous records of changes in your GTM account in the event you want to revert something!</p>`,
                                             };
                                        } else {
                                             tourStepData = TOURSTEPS[key];
                                        }
                                   }

                                   if (key === 'previewConfigureConversion') {
                                        if (currentStepConversionRef.current === STEPS_CONFIG_CONVERSION.completeCreation || currentStepConversionRef.current === STEPS_CONFIG_CONVERSION.selectConversions) {
                                             tourStepData = { ...TOURSTEPS[key], showBackButton: false };
                                        } else if (currentStepConversionRef.current !== STEPS_CONFIG_CONVERSION.completeCreation || currentStepConversionRef.current !== STEPS_CONFIG_CONVERSION.selectConversions) {
                                             tourStepData = { ...TOURSTEPS[key], showBackButton: true };
                                        }
                                   }

                                   if (key === 'previewTriggerSecond') {
                                        if (roadMapWizard && roadMapWizard.type === 'Conversion') {
                                             tourStepData = { ...TOURSTEPS[key] };
                                        } else {
                                             tourStepData = { ...TOURSTEPS[key], nextButton: 'Finish' };
                                        }
                                   }

                                   if (key === 'menuListenerCustomForms') {
                                        if (isSmallScreen) {
                                             tourStepData = { ...TOURSTEPS[key], imageSrc: '/assets/images/larry-custom-form-target.svg' };
                                        } else {
                                             tourStepData = { ...TOURSTEPS[key] };
                                        }
                                   }

                                   if (key === 'menuFormCategory') {
                                        if (isSmallScreen) {
                                             tourStepData = { ...TOURSTEPS[key], imageSrc: '/assets/images/larry-custom-form-target.svg' };
                                        } else {
                                             tourStepData = { ...TOURSTEPS[key] };
                                        }
                                   }

                                   if (key === 'configureConversion') {
                                        if (isSmallScreen) {
                                             tourStepData = { ...TOURSTEPS[key], imageSrc: '/assets/images/larry-custom-form-target.svg' };
                                        } else {
                                             tourStepData = { ...TOURSTEPS[key] };
                                        }
                                   }

                                   if (key === 'createDestinationsWizard') {
                                        if (isSmallScreen) {
                                             tourStepData = { ...TOURSTEPS[key], imageSrc: '/assets/images/larry-custom-form-target.svg' };
                                        } else {
                                             tourStepData = { ...TOURSTEPS[key] };
                                        }
                                   }

                                   if (key === 'learnDestination') {
                                        if (dataStepCompleted && dataStepCompleted['sendingData'] && dataStepCompleted['sendingData']['learnAboutDestinations']) {
                                             tourStepData = { ...TOURSTEPS[key], roadMapButton: 'GO TO ROADMAP', skipButton: 'No thanks, exit',showSkipButton: true, hideNextButton: true };
                                        } else {
                                             tourStepData = { ...TOURSTEPS[key] };
                                        }
                                   }

                                   // tourStepData.showSkipButton = showTourSkip;
                                   if (key === 'eventReporting') {
                                        return <Fragment key={key}></Fragment>;
                                   }
                                   return (
                                        <TourBody key={key} show={stepTour === tourStepData.orderNumber} attribute={tourStepData} nextTour={nextTour} backTour={backTour} skipTour={skipTour} closeTour={closeTour} dropoutTour={dropoutTour} roadMapTour={roadMapTour}>
                                             {getComponent(tourStepData)}
                                        </TourBody>
                                   );
                              })}
                         </>
                    )}
               </div>
          </>
     );
};

export default TheTour;
