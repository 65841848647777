import React, { useState, useContext } from 'react';
import { CCard, CCardBody, CButton, CRow, CCol, CInput, CTextarea, CForm, CLabel, CInvalidFeedback, CFormGroup, CCardHeader } from '@coreui/react';
import PropTypes from 'prop-types';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { RuleContext } from '../EditRule';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CONSENT_RULE, TYPE_SHOW_UNSAVE_CHANGE } from '../../../../../../constants';
import { toastError } from '../../../../../../utils';
import { setSubscriberState } from '../../../../../../actions/subscriber';
import { toast } from 'react-toastify';
import findIndex from 'lodash/findIndex';
import { setRuleHaveEditting } from '../../../../../../actions/common';
import SingleRow from './SingleRow';
import CIcon from '@coreui/icons-react';

const EditStep1 = ({ stepsData, setStepsData }) => {
     const dispatch = useDispatch();
     const { activeStep, setActiveStep, setRule } = useContext(RuleContext);
     const [saveChangeModal, setSaveChangeModal] = useState(false);
     const [validateOnChange, setValidateOnChange] = useState(false);
     const [tempData, setTempData] = useState(false);
     const [saveLoading, setSaveLoading] = useState(false);
     const rules = useSelector((state) => state.subscriber.consentRules);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const initialValues = {
          name: stepsData.name,
          description: stepsData.description,
          geographicRegions: stepsData.geographicRegions,
     };
     const initialItem = { regionType: '', continent: '', country: '', stateProvinces: '' };
     // const accountFree = activeAccount.isFree;

     const validationSchema = (validateOnChange, setValidateOnChange) => {
          // useCallback(() => {
          let ruleNames = rules.map((rule) => rule.name);

          // stepsData.name has value when editting rule
          if (stepsData.name) {
               // Find stepsData.name in ruleNames and remove it from ruleNames
               let ruleNameIndex = -1;

               ruleNames.some((ruleName, index) => {
                    if (stepsData.name === ruleName) {
                         ruleNameIndex = index;
                         return true;
                    }

                    return false;
               });

               if (ruleNameIndex !== -1) {
                    ruleNames.splice(ruleNameIndex, 1);
               }
          }

          if (!validateOnChange) {
               setValidateOnChange(true);
          }

          return Yup.object().shape({
               name: Yup.string()
                    .trim()
                    .required('Name is required')
                    .max(200, 'Name cannot be more than 200 characters')
                    .notOneOf(ruleNames, 'Existed name, please choose another name'),
               geographicRegions: Yup.array()
                    .of(
                         Yup.object().shape({
                              regionType: Yup.string().required('Required'),
                              continent: Yup.string().when('regionType', {
                                   is: 'continent',
                                   then: Yup.string().required('Please select an option'),
                              }),
                              country: Yup.string().when('regionType', {
                                   is: 'country',
                                   then: Yup.string().required('Please select an option'),
                              }),
                              countryOfState: Yup.string().when('regionType', {
                                   is: 'stateProvinces',
                                   then: Yup.string().required('Please select an option'),
                              }),
                              stateProvinces: Yup.string().when('regionType', {
                                   is: 'stateProvinces',
                                   then: Yup.string().required('Please select an option'),
                              }),
                         })
                    )
                    .required('Field is required!'),
          });
     };

     const onSubmit = ({ name, description, geographicRegions }, { setErrors, setSubmitting }) => {
          let isDuplicate = false;
          let errors = [];

          for (let i = 0; i < geographicRegions.length - 1; i++) {
               errors.push(undefined);
          }

          for (let i = 0; i < geographicRegions.length - 1; i++) {
               for (let j = i + 1; j < geographicRegions.length; j++) {
                    if (JSON.stringify(geographicRegions[i]) === JSON.stringify(geographicRegions[j])) {
                         isDuplicate = true;
                         const _error = { regionType: 'Duplicate' };

                         if (geographicRegions[i].continent) {
                              _error.continent = 'Duplicate';
                         }
                         if (geographicRegions[i].country) {
                              _error.country = 'Duplicate';
                         }
                         if (geographicRegions[i].stateProvinces) {
                              _error.stateProvinces = 'Duplicate';
                         }

                         errors[i] = _error;
                         errors[j] = _error;
                         break;
                    }
               }
               if (!geographicRegions[i].regionType) {
                    isDuplicate = true;
                    const _error = { regionType: 'Required' };

                    errors[i] = _error;
                    break;
               }
          }

          setSubmitting(false);
          if (isDuplicate) {
               setErrors({ geographicRegions: errors });
          } else {
               const data = {
                    name: name.trim(),
                    description: description.trim(),
                    geographicRegions: geographicRegions,
               };

               const newStepsData = { ...stepsData, ...data };
               setStepsData(newStepsData);
               if (
                    initialValues.name === newStepsData.name &&
                    initialValues.description === newStepsData.description &&
                    JSON.stringify(initialValues.geographicRegions) === JSON.stringify(newStepsData.geographicRegions)
               ) {
                    setActiveStep(2);
               } else {
                    setTempData(newStepsData);
                    setSaveChangeModal(true);
               }
          }

          
     };

     const onAccept = () => {
          setSaveLoading(true);
          const newStepsData = {
               id: stepsData.id,
               accountId: activeAccount.id,
               data: {
                    ...stepsData,
                    ...tempData,
               },
          };

          callTokenApi(`${API_CONSENT_RULE}update`, 'POST', newStepsData).then((response) => {
               setSaveLoading(false);
               setSaveChangeModal(false);

               if (response.status === 200) {
                    // const { rule } = response.data;
                    let newRules = [...rules];
                    let editedRuleIndex = findIndex(rules, { id: newStepsData.id });

                    if (newRules[editedRuleIndex].name !== newStepsData.data.name) {
                         // Update name for custom rules so we can validate rule name correctly
                         newRules[editedRuleIndex].name = newStepsData.data.name;
                    }

                    if (newRules[editedRuleIndex].description !== newStepsData.data.description) {
                         // Update description for custom rules so we can validate rule description correctly
                         newRules[editedRuleIndex].description = newStepsData.data.description;
                    }

                    if (newRules[editedRuleIndex].type === 'default') {
                         newRules[editedRuleIndex].hasOldVersion = true;
                    }

                    setRule(newStepsData);
                    setStepsData(newStepsData.data);
                    dispatch(
                         setSubscriberState({
                              consentRules: newRules,
                              initialConsentRules: newRules,
                         })
                    );
                    dispatch(setRuleHaveEditting({ show: false, type: '' }));
                    toast.success('Successfully update rule');
                    setActiveStep(2);
               } else {
                    toastError(response);
               }
          });
     };

     const handleHasChange = () => {
          // if (accountFree) return;
          dispatch(setRuleHaveEditting({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.EDIT_SIMPLE }));
     };

     return (
          <CCard className={`cvr-step-2 ${activeStep !== 1 ? 'difference-step' : 'cvr-step-card'}`}>
               <CCardHeader>
                    {activeStep !== 1 ? (
                         <div className="rule-step d-inline-flex justify-content-start align-center w-100">
                              <h5 className="mb-0 inactive title">Step 1: Rule Name & Region</h5>
                              <p className="description mr-auto">Define rules to manage consent based on a website user's region.</p>
                              {stepsData.name && (
                                   <div className="d-inline-flex align-items-center">
                                        <span className="given-data mr-3">{stepsData.name}</span>
                                        <CButton className="btn-edit" onClick={() => setActiveStep(1)}>
                                             {/* {accountFree ? 'View' : 'Edit'} */}
                                             Edit
                                        </CButton>
                                   </div>
                              )}
                         </div>
                    ) : (
                         <div className="d-flex justify-content-start align-center">
                              <h5 className="title">Step 1: Rule Name & Region</h5>
                              <p className="description">Define rules to manage consent based on a website user's region.</p>
                         </div>
                    )}
               </CCardHeader>
               {activeStep === 1 && (
                    <CCardBody>
                         <Formik
                              initialValues={initialValues}
                              // validationSchema={validationSchema, validateOnChange, setValidateOnChange}
                              validationSchema={() => validationSchema(validateOnChange, setValidateOnChange)}
                              onSubmit={onSubmit}
                              validateOnChange={validateOnChange}
                              validateOnBlur={false}
                              validateOnMount
                         >
                               {({ values, errors, handleChange, handleSubmit, setErrors, setFieldValue, isSubmitting }) => {
                                   console.log(values)
                                   return (
                                        <CForm onSubmit={handleSubmit} noValidate>
                                             <CRow>
                                                  <CCol md='7'>
                                                       <CFormGroup>
                                                            <CLabel htmlFor='name'>Name this consent rule</CLabel>
                                                            <CInput
                                                                 id='name'
                                                                 placeholder='Rule set name'
                                                                 invalid={!!errors.name}
                                                                 disabled={isSubmitting}
                                                                 value={values.name}
                                                                 onChange={(e) => {
                                                                      handleChange(e);
                                                                      if (errors) {
                                                                           setErrors({});
                                                                      }
                                                                      handleHasChange();
                                                                 }}
                                                                 data-lpignore='true'
                                                            />
                                                            <CInvalidFeedback>{errors.name}</CInvalidFeedback>
                                                       </CFormGroup>
                                                  </CCol>
                                                  <CCol md='12'>
                                                       <CFormGroup>
                                                            <CLabel htmlFor='description'>Write a description so you remember what this rule does.</CLabel>
                                                            <CTextarea
                                                                 id='description'
                                                                 placeholder='Enter a description for your own reference...'
                                                                 disabled={isSubmitting}
                                                                 value={values.description}
                                                                 onChange={(e) => {
                                                                      handleChange(e);
                                                                      if (errors) {
                                                                           setErrors({});
                                                                      }
                                                                      handleHasChange();
                                                                 }}
                                                            />
                                                       </CFormGroup>
                                                  </CCol>
                                                  <CCol md='12'>
                                                       <label className='label-row'>Apply this rule to users arriving from the following regions</label>
                                                       <FieldArray name='geographicRegions'>
                                                            {({ remove, push }) =>
                                                                 values.geographicRegions.map((row, index) => {
                                                                      return (
                                                                           <CRow key={index} className='row-region'>
                                                                                <SingleRow
                                                                                     props={{
                                                                                          row,
                                                                                          index,
                                                                                          setValidateOnChange,
                                                                                          handleHasChange,
                                                                                     }}
                                                                                />
                                                                                <div className='actions'>
                                                                                     {values.geographicRegions.length > 1 && (
                                                                                          <CButton
                                                                                               className='mr-2'
                                                                                               onClick={() => {
                                                                                                    remove(index);
                                                                                               }}
                                                                                          >
                                                                                               <CIcon name='iconDeleteField' className='icon-delete' />
                                                                                          </CButton>
                                                                                     )}
                                                                                </div>
                                                                           </CRow>
                                                                      );
                                                                 })
                                                            }
                                                       </FieldArray>
                                                       <CRow>
                                                            <CCol>
                                                                 <CButton
                                                                      className='btn-add-row mb-3'
                                                                      onClick={() => {
                                                                           const newGeoGraphicRegions = [...values.geographicRegions];
                                                                           newGeoGraphicRegions.push(initialItem);
                                                                           setFieldValue('geographicRegions', newGeoGraphicRegions);
                                                                           setValidateOnChange(false);
                                                                           handleHasChange();
                                                                           setTimeout(() => {
                                                                                setErrors({});
                                                                           });
                                                                      }}
                                                                 >
                                                                      <CIcon name='iconAddField' className='icon-add' />
                                                                      <CLabel className='add-row'>ADD ROW</CLabel>
                                                                 </CButton>
                                                            </CCol>
                                                       </CRow>
                                                  </CCol>
                                             </CRow>

                                             <CButton type='submit' className='px-4' color='primary'>
                                                  NEXT STEP
                                             </CButton>
                                        </CForm>
                                   )
                               }}
                         </Formik>
                    </CCardBody>
               )}
               <ConfirmSaveChange
                    show={saveChangeModal}
                    onClose={() => setSaveChangeModal(false)}
                    onAccept={onAccept}
                    title="Are You Sure You Want to Save This Change?"
                    isLoading={saveLoading}
               >
                    You are about to update the name/description of this rule. This will not effect any other rule settings.
               </ConfirmSaveChange>
          </CCard>
     );
};

EditStep1.propTypes = {
     stepsData: PropTypes.object,
     setStepsData: PropTypes.func,
};

export default EditStep1;
