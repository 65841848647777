import React from 'react';
import {
    CModal,
    CModalBody,
    CButton,
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import PropTypes from 'prop-types';

const VariableInRules = ({ show, onClose, title, children }) => {
    return (
        <div className="remove-popup variable-in-rules">
            <CModal
                show={show}
                onClose={onClose}
                color="primary"
                centered
            >
                <CModalBody className="text-center">
                    <CIcon
                        name="cil-x"
                        onClick={onClose}
                        className="icon-close-popup"
                    ></CIcon>
                    <div className="remove-popup-icon"><CIcon name="icon-delete" className="text-danger" height="38" alt="CoreUI Icons Delete" /></div>
                    <h2>{title ? title : 'Error!'}</h2>
                    <div className="remove-popup-description variable-in-rules-description">{children}</div>
                    <div className="mb-3">
                        <CButton
                            className="d-inline-block btn-cancel"
                            onClick={onClose}
                        >
                            Close
                        </CButton>
                    </div>
                </CModalBody>
            </CModal>
        </div>
    )
}

VariableInRules.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
}

export default VariableInRules
