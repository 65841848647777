import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody } from '@coreui/react';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';

const GTMConnection = () => {
     const { closeWizard } = useContext(SwitchTypeWizardContext);
     const activeAccountId = useSelector((state) => state.subscriber.activeAccount.id);

     const onBackBtnClicked = () => {
          closeWizard();
     };

     const connectToGTM = () => {
          const scopes = [
               'profile',
               'email',
               'https://www.googleapis.com/auth/tagmanager.edit.containers',
               'https://www.googleapis.com/auth/tagmanager.publish',
               'https://www.googleapis.com/auth/tagmanager.manage.users',
               'https://www.googleapis.com/auth/tagmanager.edit.containerversions',
          ];
          const url = process.env.REACT_APP_GOOGLE_OAUTH2_URL;

          const stateObj = {
               switchTypeWizard: { show: true, initialStep: SWITCH_TYPE_WIZARD_STEP.gtmSelectAccount },
               isFromSwitchTypeWizard: true,
               pathname: window.location.pathname,
               accountId: activeAccountId,
          };

          const state = btoa(encodeURIComponent(JSON.stringify(stateObj)));

          const options = {
               redirect_uri: window.location.origin,
               response_type: 'code',
               access_type: 'offline',
               scope: scopes.join(' '),
               client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
               prompt: 'consent', // https://stackoverflow.com/a/14393492
               flowName: 'GeneralOAuthFlow',
               state,
          };

          let params = '';

          Object.entries(options).forEach(([key, value], index) => {
               if (index === 0) {
                    params = `${params}${key}=${value}`;
               } else {
                    params = `${params}&${key}=${value}`;
               }
          });

          const fullURL = `${url}?${params}`;
          // const urlToPush = `${window.location.pathname}?state=${state}&isBackFromGTM=true`;
          // window.history.pushState({}, '', urlToPush);
          window.location.href = fullURL;
     };

     return (
          <SwitchTypeWizardBody onBackBtnClicked={onBackBtnClicked}>
               <CCard className='account-gtm connect'>
                    <CCardBody>
                         <div className='icon-gtm'>
                              <CIcon name='iconGTM' width={50} height={50} />
                         </div>
                         <h3>Connect to Google Tag Manager</h3>
                         <p>
                              ListenLayer pushes structured data into the dataLayer, which is read by GTM. Let's connect to your GTM account so we can quickly place your
                              ListenLayer script.
                         </p>
                         <CButton className='btn-save btn-connect-gtm' onClick={connectToGTM}>
                              <CIcon name='googleLightNormal' width={18} height={18} />
                              Sign in with Google
                         </CButton>
                         <span className='foot-note'>
                              This opens a window to authenticate your GTM account. You'll have a chance to review any changes we propose. Learn more about our{' '}
                              <a href='https://www.listenlayer.com/google-oauth-google-tag-manager/' target='_blank' rel='noopener noreferrer'>
                                   Google Integration
                              </a>{' '}
                              and our{' '}
                              <a href='https://www.listenlayer.com/privacy-policy/#special-applications' target='_blank' rel='noopener noreferrer'>
                                   Privacy Policy
                              </a>
                              .
                         </span>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default GTMConnection;
