import React, { useContext, useEffect, useState } from 'react';
import { CButton, CFormGroup, CCard, CCardBody, CInput } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { fetchGTMAccounts } from '../../../../../actions/subscriber';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import { GTMWizardBody, GTMWizardContext } from '../gtm-wizard';
import { tryParseJSON } from '../../../../../utils';
import { useScrollFaded } from '../../../../../helpers/customHooks';
import { GTM_WIZARD_STEPS } from '../../../../../constants';

const AccountList = ({ filterGTMAccounts, selectedGTMAccount, handleSelectAccount, displayNoOptions, optionDisabled }) => (
    <div className="gtm-container-list">
        {
            filterGTMAccounts.length === 0 && displayNoOptions ? (
                <p>No filtering results</p>
            ) : (
                filterGTMAccounts.map((gtmAccount) => {
                    const classes = classNames('btn-select', { active: selectedGTMAccount && selectedGTMAccount.accountId === gtmAccount.accountId })

                    return (
                        <CButton
                            type="button"
                            className={classes}
                            onClick={() => handleSelectAccount(gtmAccount)}
                            key={gtmAccount.accountId}
                            disabled={optionDisabled}
                        >
                            {gtmAccount.accountName}
                        </CButton>
                    );
                })
            )
        }
    </div>
)

const GTMSelectAccount = () => {
    const dispatch = useDispatch();
    const { stepsData, setStepsData, setCurrentStep } = useContext(GTMWizardContext);
    const gtmAccounts = useSelector((state) => state.subscriber.gtmAccounts);
    const activeAccount = useSelector((state) => state.subscriber.activeAccount);
    const accountDomains = tryParseJSON(activeAccount.domains);
    const [filterText, setFilterText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [rateLimitExceeded, setRateLimitExceeded] = useState(false);
    const { selectedGTMAccount } = stepsData;
    const domain = Array.isArray(accountDomains) ? accountDomains[0].name : '';

    const filterGTMAccounts = gtmAccounts
        .map((el) => ({ ...el, accountName: `${el.name} (account ID: ${el.accountId})` }))
        .filter(el => el.accountName.toLowerCase().includes(filterText.toLowerCase()));

    const displayFilter = gtmAccounts.length > 8;
    const { containerListClass, onScrollContainerList } = useScrollFaded({ hasScroll: filterGTMAccounts.length > 3 });

    const handleFirstLoad = () => {
        if (gtmAccounts.length === 0) {
            const rateLimitExceededNext = () => {
                setRateLimitExceeded(true);
            };

            dispatch(fetchGTMAccounts({ accountId: activeAccount.id, setIsLoading, rateLimitExceededNext }));
        } else {
            setIsLoading(false);
        }
    };

    useEffect(handleFirstLoad, []);

    const onBackBtnClicked = () => {
        setCurrentStep(GTM_WIZARD_STEPS.gtmConnection);
    };

    const handleSelectAccount = (account) => {
        setStepsData({ selectedGTMAccount: account });
    };

    const goNext = () => {
        setCurrentStep(GTM_WIZARD_STEPS.gtmSelectContainer);
    };

    const onFilterChange = (e) => {
        setFilterText(e.target.value);
    };

    return (
        <GTMWizardBody onBackBtnClicked={onBackBtnClicked} backBtnDisabled={isLoading}>
            <CCard className={classNames('account-container', { 'no-container': gtmAccounts.length === 0 })}>
                <CCardBody>
                    {
                        isLoading ? (
                            <CenterSpinner />
                        ) : (
                            <CFormGroup>
                                <h3>Select Your account</h3>
                                {
                                    rateLimitExceeded ? (
                                        <p>Sorry for the inconvenience. We have hit a 60-second API limit with Google Tag Manager. We're working with them to increase the limit.</p>
                                    ) : (
                                        gtmAccounts.length === 0 ? (
                                            <p>You have no GTM account, please create one on your GTM.</p>
                                        ) : (
                                            <>
                                                {
                                                    gtmAccounts.length === 1 ? (
                                                        <p>
                                                            You have 1 GTM account.
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            You have multiple GTM accounts. Select the one that you use on <b>{domain}</b>
                                                        </p>
                                                    )
                                                }
                                                {
                                                    displayFilter && (
                                                        <>
                                                            <CInput
                                                                className="filter"
                                                                placeholder="Type to filter list..."
                                                                value={filterText}
                                                                onChange={onFilterChange}
                                                            />
                                                            <div className={classNames("list-container", { "d-none": filterGTMAccounts.length > 3 })}>
                                                                <AccountList
                                                                    filterGTMAccounts={filterGTMAccounts}
                                                                    selectedGTMAccount={selectedGTMAccount}
                                                                    handleSelectAccount={handleSelectAccount}
                                                                    displayNoOptions
                                                                />
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                <div
                                                    className={classNames("list-container", containerListClass, { "d-none": displayFilter && filterGTMAccounts.length <= 3 })}
                                                    onScroll={onScrollContainerList}
                                                >
                                                    <AccountList
                                                        filterGTMAccounts={filterGTMAccounts}
                                                        selectedGTMAccount={selectedGTMAccount}
                                                        handleSelectAccount={handleSelectAccount}
                                                    />
                                                </div>
                                                <CButton
                                                    className="btn-save"
                                                    disabled={!selectedGTMAccount}
                                                    onClick={goNext}
                                                >
                                                    NEXT
                                                </CButton>
                                            </>
                                        )
                                    )
                                }
                                <span className="foot-note">
                                    {
                                        rateLimitExceeded ? 'The integration still works, we are just experiencing heavy volume.  Click Go Back, wait a few seconds, and try to connect to GTM again.' :
                                            'Don\'t see your account? You might not have the correct access. Go back and choose manual, or choose to skip this step for now.'
                                    }
                                </span>
                                <CButton className="btn-navigation-step" onClick={onBackBtnClicked}>
                                    <i className="fal fa-angle-left"></i>Go Back
                                </CButton>
                            </CFormGroup>
                        )
                    }
                </CCardBody>
            </CCard>
        </GTMWizardBody>
    );
};

export default GTMSelectAccount;
