import CIcon from '@coreui/icons-react';
import { CBadge } from '@coreui/react';
import React from 'react';

const TooltipTag = ({ name, description, type, className }) => {
     let badgeType;

     switch (type) {
          case 'info':
               badgeType = 'bg-info';
               break;
          case 'primary':
               badgeType = 'bg-primary';
               break;
          case 'success':
               badgeType = 'bg-success';
               break;
          case 'secondary':
               badgeType = 'bg-secondary';
               break;
          default:
               break;
     }

     return (
          <>
               {badgeType && (
                    <div className={`tooltip-tag ${className ? className : ''}`}>
                         <CBadge className={badgeType}>{name}</CBadge>
                         {description && (
                              <div className='block-tooltip-tag'>
                                   <CIcon name='arrowTooltip' className='icon-arrow-area' />
                                   <div className='block-tooltip-text'>
                                        <p>{description}</p>
                                   </div>
                              </div>
                         )}
                    </div>
               )}
          </>
     );
};

export default TooltipTag;
