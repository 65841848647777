import React from 'react';
import { connect } from 'react-redux';
import {
     CHeader,
     CHeaderBrand,
     CHeaderNav,
     CHeaderNavItem,
     CHeaderNavLink,
     CSubheader,
     CToggler,
     CBreadcrumbRouter,
     // CButton
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

import {
     toggleTheme,
     setAsideShow,
     setSidebarShow
} from '../../actions/common';
import AdminAccountDropdown from './TheAccountDropdown';
// import TheHeaderDropdownNotif from './TheHeaderDropdownNotif';
// import TheHeaderDropdownTasks from './TheHeaderDropdownTasks';

// routes config
import { adminRoutes } from '../../routes';

const AdminHeader = (props) => {
     const { theme, setAsideShow, setSidebarShow } = props;
     const { asideShow, sidebarShow } = theme;

     // const { versionWaiting } = admin;

     const toggleSidebar = () => {
          const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive';
          setSidebarShow(val);
     }

     const toggleSidebarMobile = () => {
          const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive';
          setSidebarShow(val);
     }

     // const togglePublishModal = () => {
     //      if (activeAccount.numberOfDomains < 1) {
     //           toast.warn("Domain settings incomplete. This is vital for your website tracking to work.");
     //           history.push('/website');
     //      } else {
     //           setPublishModal(!publishModal);
     //      }
     // }

     // const toggleThemeMode = () => {
     //      localStorage.setItem('theme', JSON.stringify({ darkMode: !darkMode }));
     //      toggleTheme();
     // }
     return (
          <CHeader className={theme.overlay ? 'open-dropdown' : ''} withSubheader>
               <CToggler
                    inHeader
                    className="ml-md-3 d-lg-none"
                    onClick={toggleSidebarMobile}
               />
               <CToggler
                    inHeader
                    className="ml-3 d-md-down-none"
                    onClick={toggleSidebar}
               />
               <CHeaderBrand className="mx-auto d-lg-none" to="/">
                    <CIcon name="logo" height="48" alt="Logo" />
               </CHeaderBrand>
               <CHeaderNav className="d-md-down-none mr-auto">
                    <CHeaderNavItem className="px-3" >
                         <CHeaderNavLink to="/">Dashboard</CHeaderNavLink>
                    </CHeaderNavItem>
                    {/* <CHeaderNavItem className="px-4">
                    {
                         versionWaiting.isPublish === 'enable' ?
                              <CButton className="btn-brand" color="primary" onClick={togglePublishModal}><span>PUBLISH</span><CIcon name="cil-send" className="ml-2" /></CButton>
                              : <CButton className="btn-brand" color="secondary" disabled><span>PUBLISH</span><CIcon name="cil-send" className="ml-2" /></CButton>
                    }
                    </CHeaderNavItem>
                    <CHeaderNavItem>
                         {
                              <div className="my-0">
                                   {
                                        parseInt(versionWaiting.numberOfHistories) > 0 ? (
                                             <span className="text-unpublished text-danger">{parseInt(versionWaiting.numberOfHistories) + ' unpublished changes '}</span>
                                        ) : (
                                                  <span className="text-unpublished text-secondary">0 unpublished changes</span>
                                             )
                                   }
                                   <CIcon name='icon-info' className="ml-1 hover-show-tooltip" height="14"></CIcon>
                                   <div className="block-tooltip">
                                        <CIcon name="arrow-preview" className="arrow-preview"></CIcon>
                                        <div className="block-tooltip-text">
                                             <p>Changes you make in the interface are accumulated and must be published to go live on your website.</p>
                                        </div>
                                   </div>
                              </div>
                         }
                    </CHeaderNavItem> */}
               </CHeaderNav>
               <CHeaderNav className="px-3">
                    {/* <CToggler
                         inHeader
                         className="ml-3 d-md-down-none"
                         onClick={toggleThemeMode}
                         title="Toggle Light/Dark Mode"
                    >
                         <CIcon name="cil-moon" className="c-d-dark-none" alt="CoreUI Icons Moon" />
                         <CIcon name="cil-sun" className="c-d-default-none" alt="CoreUI Icons Sun" />
                    </CToggler> */}
                    {/* <TheHeaderDropdownNotif />
                    <TheHeaderDropdownTasks /> */}
                    <AdminAccountDropdown />
                    <CToggler
                         inHeader
                         className="d-md-down-none d-none"
                         onClick={() => setAsideShow(!asideShow)}
                    >
                         <CIcon className="mr-2" size="lg" name="cil-applications-settings" />
                    </CToggler>
               </CHeaderNav>
               <CSubheader className="px-3 justify-content-between">
                    <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3" routes={adminRoutes} />
               </CSubheader>
          </CHeader>
     )
}

const mapStateToProps = (state) => {
     return {
          theme: state.theme,
          admin: state.admin
     }
}

const mapDispatchToProps = {
     toggleTheme,
     setAsideShow,
     setSidebarShow,
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(AdminHeader))
