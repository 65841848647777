import { CModal } from "@coreui/react";
import React from "react";

const SupportTicketPopup = ({ show, onClose, children }) => {
    return (
        <>
            <div className="support-ticket-popup">
                <CModal show={show} onClose={onClose}>
                    {children}
                </CModal>
            </div>
        </>
    );
};

export default SupportTicketPopup;
