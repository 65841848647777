import React, { useContext, useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import NextArrow from '../../../components/cms/subscriber/account/upgrade-account/NextArrow';
import PreArrow from '../../../components/cms/subscriber/account/upgrade-account/PreArrow';
import Slider from "react-slick";
import { AsideContext, ASIDE_COMPONENTS } from './TheAside';
import { useActivePopulateFeatures, useActiveReportingFeatures } from '../../../helpers/customHooks';
import PackageItem from '../../../components/cms/subscriber/account/upgrade-account/PackageItem';
// import { tryParseJSON } from '../../../utils';
import PopupNotifyDataLayerChanges from '../../../components/general/popup/PopupNotifyDataLayerChanges';
import GetHelpLabel from '../../../components/general/popup/support-ticket/GetHelpLabel';

const ComparePlans = () => {
     const { setPackageSelected, setCurrentComponents, setTempPackage, togglePublishBeforeDowngradePopup } = useContext(AsideContext);
     const packages = useSelector(state => state.subscriber.packages);
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const versionPublish = useSelector(state => state.subscriber.versionPublish);

     const [showPopupNotify, setShowPopupNotify] = useState(false);
     const [tempPackageChange, setTempPackageChange] = useState(null);
     const [changePackageText, setChangePackageText] = useState('Upgrade');

     const activeReportingFeatures = useActiveReportingFeatures();
     const activePopulateFeatures = useActivePopulateFeatures();
     const {
          packageId: currentPackageId,
          packagePricePerMonth: currentPackagePricePerMonth,
          packageEventReporting: currentPackageEventReporting,
          packageEventPopulateFields: currentPackageEventPopulate,
          packageFriendlyName: currentPackageFriendlyName
     } = activeAccount;
     const displayEnterprise = ['Pro', 'Business', 'Enterprise'].includes(currentPackageFriendlyName);
     const packagesToDisplay = packages.filter(packageData =>
          (displayEnterprise && packageData.pricePerMonth > 0 && (!packageData.accountsToDisplay || packageData.accountsToDisplay.includes(activeAccount.id))) ||
          (!displayEnterprise && packageData.friendlyName !== 'Enterprise')
     );
     // const checkExistingAccount = activeAccount && activeAccount.packageVersion === 1 && !activeAccount.inactiveTemporary && !activeAccount.inactivePermanent ? true : false;
     const checkExistingAccount = activeAccount && activeAccount.packageVersion === 1 ? true : false;

     useEffect(() => {
          document.body.classList.add('no-scroll');
          return () => document.body.classList.remove('no-scroll');
     }, [])

     const handleSelectPackage = useCallback((packageData) => {
          setPackageSelected(packageData);
          setCurrentComponents(ASIDE_COMPONENTS.SLIDE_OUT_PAYMENT);
     }, [setPackageSelected, setCurrentComponents])

     const goBack = useCallback(() => {
          setCurrentComponents(ASIDE_COMPONENTS.LIMITATION_HIT);
     }, [setCurrentComponents])

     const settings = {
          arrows: true,
          speed: 500,
          slidesToShow: 4,
          initialSlide: packagesToDisplay.findIndex(({ id, pricePerMonth, friendlyName }) => (currentPackageId === id || currentPackagePricePerMonth === pricePerMonth || currentPackageFriendlyName === friendlyName)),
          nextArrow: <NextArrow />,
          prevArrow: <PreArrow />,
          responsive: [
               {
                    breakpoint: 1024,
                    settings: {
                         slidesToShow: 3,
                         slidesToScroll: 3,
                         infinite: true,
                         dots: true
                    }
               },
               {
                    breakpoint: 600,
                    settings: {
                         slidesToShow: 2,
                         slidesToScroll: 2,
                         initialSlide: 2
                    }
               },
               {
                    breakpoint: 480,
                    settings: {
                         slidesToShow: 1,
                         slidesToScroll: 1
                    }
               }
          ]
     };

     const handleClickSelectPackage = (packageData, btnText) => {
          if (checkExistingAccount) {
               setShowPopupNotify(true)
               setTempPackageChange(packageData)
               setChangePackageText(btnText)
          } else {
               handleSelectPackage(packageData)
          }
     }

     const handleClickCreateTicket = () => {
          setPackageSelected(tempPackageChange);
     }

     const handleIgnoreNotify = () => {
          setShowPopupNotify(false)
          handleSelectPackage(tempPackageChange, changePackageText)
     }

     return (
          <div className="full-screen-wrapper">
               <div className="modal-body">
                    <div className="pricing-header">
                         <CButton
                              className="btn-back back"
                              onClick={goBack}
                         >
                              <i className="fal fa-angle-left"></i>
                              Back
                         </CButton>
                         <CIcon
                              name="logo-dark"
                              height={34}
                         />
                         <h1 className="selectpackage">Select your package</h1>
                         <h6 className="selectdecription">Choose a pricing plan for your new account. Paid plans include <strong> 15 free days </strong>so you can try all the features!</h6>

                    </div>
                    <div className="list-package">
                         <Slider {...settings}>
                              {
                                   packagesToDisplay.map((packageData) => {
                                        const {
                                             id: packageId,
                                             pricePerMonth,
                                        } = packageData;

                                        const isCurrentPackage = (currentPackageId === packageId);

                                        let btnText = '';
                                        let btnClicked = f => f;

                                        if (isCurrentPackage) {
                                             btnText = 'Current Plan';
                                        } else {
                                             if (currentPackagePricePerMonth >= pricePerMonth) {
                                                  if (currentPackagePricePerMonth === pricePerMonth) {
                                                       btnText = 'Switch';
                                                  } else {
                                                       btnText = 'Downgrade';
                                                  }

                                                  btnClicked = () => {
                                                       if (versionPublish.isPublish === 'enable') {
                                                            togglePublishBeforeDowngradePopup();
                                                            return;
                                                       }

                                                       let eventReportingPassed = true;

                                                       if (currentPackageEventReporting && !packageData.eventReporting && activeReportingFeatures.length > 0) {
                                                            // Must turn off all reporting features to downgrade to package with event monitoring = false
                                                            eventReportingPassed = false;
                                                       } else if (currentPackageEventPopulate && !packageData.eventPopulateFields && activePopulateFeatures.length > 0) {
                                                            // Must turn off all populate features to downgrade to package with event populate fields = false
                                                            eventReportingPassed = false;
                                                       } else {
                                                            // if (packageData.numberOfSaveReportingValues !== -1) {
                                                            //      // All number of save reporting values for each listener must be <= numberOfSaveReportingValues
                                                            //      // of the package which the user is about to downgrade to
                                                            //      let accountSaveReportingValues = tryParseJSON(activeAccount.saveReportingValues);

                                                            //      if (Array.isArray(accountSaveReportingValues)) {
                                                            //           eventReportingPassed = accountSaveReportingValues.every(el => el.count <= packageData.numberOfSaveReportingValues);
                                                            //      }
                                                            // }
                                                       }

                                                       if (
                                                            (packageData.numberOfListeners === -1 || activeAccount.numberOfListeners <= packageData.numberOfListeners) &&
                                                            (packageData.numberOfCusDatalayerVariables === -1 || activeAccount.numberOfCusDatalayerVariables <= packageData.numberOfCusDatalayerVariables) &&
                                                            (packageData.numberOfCusVariablesRules === -1 || activeAccount.numberOfCusVariablesRules <= packageData.numberOfCusVariablesRules) &&
                                                            (packageData.numberOfCusBrowserVariables === -1 || activeAccount.numberOfCusBrowserVariables <= packageData.numberOfCusBrowserVariables) &&
                                                            (packageData.numberOfCusBrowserRules === -1 || activeAccount.numberOfCusBrowserRules <= packageData.numberOfCusBrowserRules) &&
                                                            (packageData.numberOfDomains === -1 || activeAccount.numberOfDomains <= packageData.numberOfDomains) &&
                                                            (packageData.numberOfPremium === -1 || activeAccount.numberOfPremium <= packageData.numberOfPremium) &&
                                                            (packageData.numberOfUsers === -1 || activeAccount.numberOfUsers <= packageData.numberOfUsers) &&
                                                            (packageData.numberOfEventMonitor === -1 || activeAccount.numberOfEventMonitor <= packageData.numberOfEventMonitor) &&
                                                            eventReportingPassed
                                                       ) {
                                                            if (currentPackagePricePerMonth === pricePerMonth) {
                                                                 btnText = 'Switch';
                                                                 handleClickSelectPackage(packageData, btnText);
                                                            } else {
                                                                 setTempPackage(packageData);
                                                                 setCurrentComponents(ASIDE_COMPONENTS.DOWNGRADE_CONFIRMATION);
                                                            }
                                                       } else {
                                                            setCurrentComponents(ASIDE_COMPONENTS.ACTION_REQUIRED);
                                                       }
                                                  }
                                             } else {
                                                  btnText = 'Upgrade';

                                                  if (packageData.numberOfPremium === -1 || activeAccount.numberOfPremium <= packageData.numberOfPremium) {
                                                       btnClicked = () => handleClickSelectPackage(packageData, btnText);
                                                  } else {
                                                       btnClicked = () => {
                                                            setPackageSelected(packageData);
                                                            setCurrentComponents(ASIDE_COMPONENTS.UPGRADE_REQUIRED);
                                                       }
                                                  }
                                             }
                                        }
                                        return (
                                             <div key={packageId} >
                                                  <PackageItem
                                                       currentPackageId={currentPackageId}
                                                       packageData={packageData}
                                                       btnText={btnText}
                                                       btnClick={btnClicked}
                                                       btnDisabled={isCurrentPackage}
                                                  />
                                             </div>
                                        )
                                   })
                              }
                         </Slider>
                    </div>
                    <div className="footer texrt-center">
                         <CIcon
                              name="logo-dark"
                              height={34}
                         />
                         <div className="d-flex justify-content-center">
                              <div className="text-footer">Support</div>
                              <div className="text-footer">Terms of Use</div>
                              <div className="text-footer">Privacy & Data Policy</div>
                         </div>
                    </div>
               </div>
               <PopupNotifyDataLayerChanges
                    show={showPopupNotify}
                    changePackageText={changePackageText}
                    onClickCreateTicket={handleClickCreateTicket}
                    onAccept={handleIgnoreNotify}
                    onClose={() => setShowPopupNotify(false)}
               />
               <GetHelpLabel />
          </div>
     )
}

export default ComparePlans
