import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CInvalidFeedback,
    CLink,
    CRow
} from '@coreui/react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { setFlexibleModal, setRuleHaveEditting, setViewedRule } from '../../../../../../actions/common';
import { setCustomVariableOptions, setReloadLookupTable, setShowBlockAccountPopup, setShowCreateVariable } from '../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_CHECK_RULE_BEFORE_SAVE, API_CLIENT_CUSTOM_VARIABLE, API_CLIENT_RULE_UPDATE, COMPONENT_NAME, NO_OPTION_MESSAGE_TYPE, TYPE_SHOW_UNSAVE_CHANGE } from '../../../../../../constants';
import { handleShowPopupCreateVariable } from '../../../../../../helpers/cms/subscriber';
import { deepCopyArray, toastError } from '../../../../../../utils';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { NoOptionsMessage } from '../../../../../general/rules';
import { RuleSettingContext } from '../EditLookupSetting';
import ModalCreateVarible from '../ModalCreateVarible';
import SelectField from './SelectField';

const validationSchema = () => (
    Yup.object().shape({
        customVariables: Yup.array()
            .min(1, "Select at least one custom variable!")
            .required("Select at least one custom variable!")
            .nullable(),
    })
)

const EditStep4 = ({ stepsData, setStepsData }) => {
    const { activeStep, setActiveStep, activeListener } = useContext(RuleSettingContext);
    const dispatch = useDispatch();
    const [fetchLoading, setFetchLoading] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [warningModal, setWarningModal] = useState(false);
    const activeAccount = useSelector(state => state.subscriber.activeAccount)
    const customVariables = useSelector(state => state.subscriber.customVariableOptions);
    const showPopupVariable = useSelector(state => state.subscriber.showPopupVariable);
    const { showLv2 } = useSelector(state => state.theme.flexibleModal);
    const [newCustomVariables, setNewCustomVariables] = useState([]);
    const [sharedVariables, setSharedVariables] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);
    const [savePopupLoading, setSavePopupLoading] = useState(false);
    const [customVariableNext, setCustomVariableNext] = useState(f => f);
    const customVariableOptions = customVariables.map(variable => {
        return { label: variable.name, value: variable.name, id: variable.id }
    });

    const handleHasChange = () => {
        dispatch(setRuleHaveEditting({ showLv2: true, typeLv2: TYPE_SHOW_UNSAVE_CHANGE.EDIT_LOOKUP }));
    };
    const fetchCustomVariables = () => {
        if (activeStep === 4) {
            setFetchLoading(true);
            const urlToCall = `${API_CLIENT_CUSTOM_VARIABLE}${activeAccount.id}?group=rule`;

            callTokenApi(urlToCall, 'GET', null)
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setCustomVariableOptions(response.data.variables));
                    }
                })
                .finally(() => setFetchLoading(false))
        }
    }
    useEffect(fetchCustomVariables, [activeStep]);

    const toggleWarningModal = useCallback(() => {
        setWarningModal(!warningModal);
    }, [warningModal]);

    const getCustomVariable = (id) => {
        let newItem = { id, value: '' }
        return newItem;
    }

    const getInitialCustomVariables = () => {
        var customVars = [];
        let customVariableList = [];
        if (stepsData.customVariables.length > 0) {
            stepsData.customVariables[0].forEach(item => { customVars.push(item.id) });
            customVariableOptions.forEach(item => {
                let index = customVars.indexOf(item.id);
                if (index !== -1) {
                    customVariableList[index] = { ...item };
                }
            });
        }
        return customVariableList;
    }
    const initialValues = {
        customVariables: getInitialCustomVariables()
    }

    const onSubmit = (values) => {
        let customVariables = [], customVariable = [];
        values.customVariables.forEach(item => {
            customVariable.push(getCustomVariable(item.id));
        })
        customVariables.push(customVariable);

        if (compareTwoVariables(stepsData.customVariables[0], customVariables[0])) {
            setSaveLoading(false);
            setUpdateModal(false);
            dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
            setTimeout(() => {
                if (!showLv2) {
                    dispatch(
                        setFlexibleModal({
                            show: true,
                            showLv2: true,
                            ruleId: stepsData.id,
                            ruleIdLv2: stepsData.id,
                            component: COMPONENT_NAME.CUSTOM_DATA_LOOKUP_SETTING,
                            componentLv2: COMPONENT_NAME.CUSTOM_DATA_LOOKUP_TABLE
                        })
                    );
                } else {
                    dispatch(
                        setFlexibleModal({
                            show: true,
                            showLv2: false,
                            ruleId: stepsData.id,
                            ruleIdLv2: '',
                            component: COMPONENT_NAME.CUSTOM_DATA_LOOKUP_TABLE,
                            componentLv2: ''
                        })
                    );
                }
            }, 0);
        } else {
            setSaveLoading(true);
            setNewCustomVariables([...customVariables]);
            handleCheckRuleBeforeSave(customVariables);
        }
    }

    const handleCheckRuleBeforeSave = (customVariables) => {
        setSavePopupLoading(true);
        const data = {
            accountId: activeAccount.id,
            listenerId: activeListener.id,
            type: 'Lookup Rule',
            id: stepsData.id,
            customVariables
        }
        callTokenApi(API_CLIENT_CHECK_RULE_BEFORE_SAVE, 'POST', data)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.warning) {
                        setSharedVariables(response.data.listSharedVariables);
                        toggleWarningModal();
                    } else {
                        setUpdateModal(true);
                    }
                } else {
                    toastError(response)
                }
            })
            .finally(() => {
                setSavePopupLoading(false);
            })
    }

    const handleAcceptPopup = () => {
        let customVars = [...newCustomVariables];
        let newStepsDataVariables = [...stepsData.customVariables], newList = [];
        let newStepsData = {};

        newList = newStepsDataVariables.map((stepCustomVariable) => {
            let newVars = [];
            newVars = customVars[0].map(customVar => {
                let findIndex = checkInArray(customVar.id, stepCustomVariable);
                if (findIndex !== -1) {
                    customVar = stepCustomVariable[findIndex];
                }
                return customVar;
            });
            return deepCopyArray(newVars);
        });

        newStepsData = { ...stepsData, customVariables: newList };

        setStepsData(newStepsData);
        handleSaveSettings(newList);
    }

    const handleSaveSettings = (customVariables) => {
        setSavePopupLoading(true);
        let data = {
            accountId: activeAccount.id,
            listenerId: activeListener.id,
            type: 'Lookup Rule',
            ...stepsData,
            customVariables
        }
        callTokenApi(API_CLIENT_RULE_UPDATE, 'POST', data)
            .then((response) => {
                setSavePopupLoading(false);

                if (response.status === 200) {
                    dispatch(setViewedRule(null, true));
                    dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
                    dispatch(setReloadLookupTable(true));
                    setTimeout(() => {
                        if (!showLv2) {
                            dispatch(
                                setFlexibleModal({
                                    show: true,
                                    showLv2: true,
                                    ruleId: stepsData.id,
                                    ruleIdLv2: stepsData.id,
                                    component: COMPONENT_NAME.CUSTOM_DATA_LOOKUP_SETTING,
                                    componentLv2: COMPONENT_NAME.CUSTOM_DATA_LOOKUP_TABLE
                                })
                            );
                        } else {
                            dispatch(
                                setFlexibleModal({
                                    show: true,
                                    showLv2: false,
                                    ruleId: stepsData.id,
                                    ruleIdLv2: '',
                                    component: COMPONENT_NAME.CUSTOM_DATA_LOOKUP_TABLE,
                                    componentLv2: ''
                                })
                            );
                        }
                    }, 0);
                } else {
                    if (response.data.accountBlocked) {
                        dispatch(setShowBlockAccountPopup(true))
                    } else {
                        toastError(response)
                    }
                }
            })
    }

    const compareTwoVariables = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let index in arr1) {
            if (arr1[index].id !== arr2[index].id)
                return false;
        }
        return true;
    }

    const checkInArray = (value, array) => {
        let index = -1
        array.some((item, itemIndex) => {
            if (item.id.indexOf(value) !== -1) {
                index = itemIndex;
                return true;
            }
            return false;
        });
        return index;
    }
    const toggleClosePopupVariable = () => {

        dispatch(setShowCreateVariable(false));

    }
    return (
        <>
            {showPopupVariable && <ModalCreateVarible show={showPopupVariable} onCancel={() => toggleClosePopupVariable()} customVariableNext={customVariableNext} />}

            <CCard className={`cvr-step-4 ${activeStep !== 4 ? 'difference-step' : 'cvr-step-card'}`}>
                <CCardHeader>
                    {activeStep !== 4 ? (
                        <div className="rule-step d-inline-flex justify-content-between w-100">
                            <h5 className="mb-0 inactive">Step 4: Select Custom Variables</h5>
                            {
                                stepsData.customVariables.length > 0 &&
                                <CButton className="btn-edit" onClick={() => setActiveStep(4)}>
                                    Edit
                                </CButton>
                            }
                        </div>
                    ) : (
                        <h5>Step 4: Select Custom Variables</h5>
                    )}
                </CCardHeader>
                {
                    activeStep === 4 && (
                        <>
                            <CCardBody>
                                <CRow>
                                    <CCol md="7">
                                        {
                                            fetchLoading ? (
                                                <CenterSpinner />
                                            ) : (
                                                <>
                                                    <Formik
                                                        initialValues={initialValues}
                                                        validationSchema={validationSchema}
                                                        onSubmit={onSubmit}
                                                        validateOnChange={false}
                                                        validateOnBlur={false}
                                                    >
                                                        {
                                                            ({
                                                                values,
                                                                errors,
                                                                touched,
                                                                setErrors,
                                                                setFieldValue,
                                                                setFieldTouched,
                                                                handleSubmit,
                                                            }) => (
                                                                <Form onSubmit={handleSubmit} className="rule-step">
                                                                    <p>Select the custom data layer variables you would like to define values for based on the condition fields.</p>

                                                                    <div className="events-multiselect ">
                                                                        <SelectField
                                                                            id="customVariables"
                                                                            name="customVariables"
                                                                            label="Variables"
                                                                            placeholder="Select one or more custom variables"
                                                                            options={customVariableOptions}
                                                                            value={values.customVariables}
                                                                            invalid={!!errors.customVariables}
                                                                            isMulti={true}
                                                                            onChange={setFieldValue}
                                                                            hasChange={handleHasChange}
                                                                            onBlur={setFieldTouched}
                                                                            touched={touched.customVariables}
                                                                            error={customVariables.length > 0 ? errors.customVariables : ''}
                                                                            isClearable={true}
                                                                            backspaceRemovesValue={true}
                                                                            disabled={saveLoading}
                                                                            noOptionsMessage={() => <NoOptionsMessage type={NO_OPTION_MESSAGE_TYPE.CUSTOM_VARIABLE} options={customVariableOptions} />}
                                                                            setErrors={setErrors}
                                                                            noInvalid={true}
                                                                        />
                                                                    </div>

                                                                    <div className={`create-custom-rule mt-1 ${errors.customVariables && 'create-custom-rule-invalid'}`}>

                                                                        <CInvalidFeedback className={errors.customVariables && 'd-block'}>
                                                                            {errors.customVariables}
                                                                        </CInvalidFeedback>
                                                                        <CLink className='create-custom-rule-link' href='#' onClick={() => {
                                                                            handleShowPopupCreateVariable({ values, setFieldValue, setCustomVariableNext, dispatch, type: 'LookUp Rule', handleHasChange })
                                                                            handleHasChange()
                                                                        }}>Create new custom variable</CLink>
                                                                    </div>

                                                                    <div className="text-left mt-1">
                                                                        <CButton
                                                                            className="px-4"
                                                                            color="primary"
                                                                            shape="square"
                                                                            type="submit"
                                                                            disabled={saveLoading}
                                                                        >
                                                                            Save & Manage Values
                                                                        </CButton>
                                                                    </div>
                                                                </Form>
                                                            )
                                                        }
                                                    </Formik>
                                                </>
                                            )
                                        }
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </>
                    )
                }
            </CCard>
            <ConfirmSaveChange
                show={updateModal}
                onClose={() => {
                    setUpdateModal(false);
                    setSaveLoading(false);
                }}
                onAccept={handleAcceptPopup}
                isLoading={savePopupLoading}
                title="Warning! This Will Change Your Rule"
            >
                You are changing Custom Variables. This will make some changes to this rule.
                You will need to review your lookup table settings and adjust them as needed.
            </ConfirmSaveChange>
            <ConfirmSaveChange
                show={warningModal}
                onClose={() => {
                    toggleWarningModal();
                    setSaveLoading(false);
                }}
                onAccept={() => {
                    toggleWarningModal();
                    setUpdateModal(true);
                }}
                title={`Warning!`}
                isLoading={savePopupLoading}
                btnTextSave={`Save Now`}
                btnTextCancel={`Go Back`}
            >
                <p>
                    It looks like you have another rule in this Listener that affects the same variable you are affecting with this rule.  You can proceed with saving, but we recommend you review all rules that affect the following variable:
                </p>
                <p>{sharedVariables.toString()}</p>
                <p>
                    This can cause some conflicts if both rules match positively. In that scenario we will use the order of your rules to display a value for the variable (the first rule to generate a value will be used).
                </p>
            </ConfirmSaveChange>
        </>
    )
}

EditStep4.propTypes = {
    stepsData: PropTypes.object,
    setStepsData: PropTypes.func
}
export default EditStep4
