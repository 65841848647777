import React, { useContext, useState, useCallback, useRef } from 'react';
import CIcon from '@coreui/icons-react';
import {
    CButton,
    CFormGroup,
    CInputFile,
    CLabel,
    CModal,
    CModalBody,
    CModalTitle,
    CProgress,
    CSelect
} from '@coreui/react';
import XLSX from 'xlsx';

import { LookupTableContext } from './LookupTable';

const ImportTableModal = ({ show, toggleModal, exportToFile }) => {
    const { setTableRow, tableRow, setBtnSaveStatus } = useContext(LookupTableContext);
    const [importedFileName, setImportedFileName] = useState('');
    const [importedValues, setImportedValues] = useState([]);
    const [importMethod, setImportMethod] = useState('');
    const [isUploadedError, setIsUploadedError] = useState(false);
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const inputFileRef = useRef(null);

    const IMPORT_OPTIONS = [
        { LABEL: 'Replace entire existing table with new values', VALUE: 'replace', OPTIONAL_TEXT: 'The new records will replace your entire existing table.' },
        { LABEL: 'Insert new values at bottom of existing table', VALUE: 'insert bottom', OPTIONAL_TEXT: 'The new records will be added to the bottom of your existing table.' },
        { LABEL: 'Insert new values at the top of the exist table', VALUE: 'insert top', OPTIONAL_TEXT: 'The new records will be added to the top of your existing table. ' }
    ]

    const resetState = () => {
        setImportedFileName('');
        setImportedValues([]);
        setImportMethod('');
        setIsUploadedError(false);
        setIsUploadSuccess(false);
    }

    const handleSelectImportMethod = useCallback((e) => {
        setImportMethod(e.target.value);
    }, [])

    const clickCancelBtn = () => {
        if (isUploadedError) {
            inputFileRef.current.value = ''; // Set input file value = '', so the user can upload the same file again
            resetState();
        }

        toggleModal();
    }

    const onClose = () => {
        toggleModal();
    }

    const uploadFile = (e) => {
        const { files } = e.target;

        if (files && files.length > 0) {
            var reader = new FileReader();

            reader.onload = function (_) {
                // Use reader.result
                var workbook = XLSX.read(reader.result, {
                    type: 'binary',
                    raw: true // Plain text parsing will not parse values ** (for example: Aug 2019 will not be parsed to 8/1/2019)
                });

                setImportedFileName(files[0].name);

                // Range: 1 => Skip first row => Second row becomes the header
                // defval: '' => All null and undefined points will be filled with defval
                let jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { range: 0, raw: true, defval: '' });

                if (jsonData.length > 0) {
                    setImportedValues(jsonData);
                }
            }

            reader.readAsBinaryString(files[0]);
        }
    }

    const uploadTable = () => {
        setIsUploadedError(false);

        if (!importedValues[0]) {
            return setIsUploadedError(true);
        }

        let newImportedValues = [];
        let isConditionValueValid = true;

        importedValues.some(val => {
            if (typeof val['Click URL Element'] === 'undefined' || typeof val['Match Type'] === 'undefined' || typeof val['Value'] === 'undefined' || typeof val['Yes/No'] === 'undefined') {
                isConditionValueValid = false;
                return true;
            }
            newImportedValues.push({
                conditions: {
                    clickUrlElement: val['Click URL Element'],
                    matchType: val['Match Type'],
                    value: val['Value']
                },
                customVariables: { trackAsClicks: val['Yes/No'].toLowerCase() }
            });
            return false;
        });

        if (isConditionValueValid) {
            setImportedValues(newImportedValues);
            setIsUploadSuccess(true);
        } else {
            setIsUploadedError(true);
        }
    }

    const applyChanges = () => {
        switch (importMethod) {
            case 'replace':
                setTableRow(importedValues);
                break;
            case 'insert bottom':
                setTableRow([...tableRow, ...importedValues]);
                break;
            case 'insert top':
                setTableRow([...importedValues, ...tableRow]);
                break;
            default:
        }

        inputFileRef.current.value = ''; // Set input file value = '', so the user can upload the same file again
        resetState();
        setBtnSaveStatus(false); // Enable Save Changes button 
        toggleModal();
    }

    const cancelChange = () => {
        inputFileRef.current.value = ''; // Set input file value = '', so the user can upload the same file again
        resetState();
    }

    return (
        <div className="import-modal">
            <CModal
                show={show}
                onClose={onClose}
                centered
            // closeOnBackdrop={false}
            >
                <CModalBody>
                    <div className="import-modal-body">
                        <CIcon name="cil-x" onClick={onClose} className="icon-close-popup" />
                        <CModalTitle>Import Table Values</CModalTitle>
                        <p>
                            Use this feature to import new values for your table. You can replace all existing values or add new values to the
                            {" "}top or bottom of your table. To begin, export your Table Template below. Then populate the values and import it.
                        </p>
                        <div className={`import-modal-step${isUploadSuccess ? ' import-modal-step-disable' : ''}`}>
                            <p>Step 1: Download my table's template:</p>
                            <CButton color="light" onClick={() => exportToFile('csv', 'table-template', true)} disabled={isUploadSuccess}>DOWNLOAD</CButton>
                        </div>
                        <p>Upload my new values:</p>
                        <CFormGroup className={`col-12${isUploadSuccess ? ' import-modal-file-disable' : ''}`}>
                            <CInputFile innerRef={inputFileRef} custom id="custom-file-input" onChange={uploadFile} disabled={isUploadSuccess} />
                            <CLabel htmlFor="custom-file-input" variant="custom-file">
                                {importedFileName || 'Choose file...'}
                            </CLabel>
                        </CFormGroup>
                        <CFormGroup className={`${isUploadSuccess ? ' import-modal-select-disable' : ''}`}>
                            <CSelect value={importMethod} onChange={handleSelectImportMethod} disabled={isUploadSuccess}>
                                <option value="" disabled hidden>Select an option...</option>
                                {
                                    IMPORT_OPTIONS.map(importOption => (
                                        <option key={importOption.VALUE} value={importOption.VALUE}>{importOption.LABEL}</option>
                                    ))
                                }
                            </CSelect>
                        </CFormGroup>
                        {
                            !isUploadSuccess && (
                                <div className="import-modal-button">
                                    <CButton color="primary" disabled={!importedFileName || !importMethod} onClick={uploadTable}>
                                        UPLOAD
                                    </CButton>
                                    <CButton color="light" type="button" onClick={clickCancelBtn}>
                                        CANCEL
                                    </CButton>
                                </div>
                            )
                        }
                        {/* <div className="import-modal-progress">
                            <CProgress
                                color='success'
                                value={100}
                                size="md"
                            />
                            <p>Processing your file...</p>
                        </div> */}
                        {
                            isUploadedError && (
                                <div className="import-modal-error">
                                    <p>
                                        File upload error. Please make sure you are using your table{" "}
                                        template and saving the file as the same format it was downloaded in.
                                    </p>
                                </div>
                            )

                        }
                        {
                            isUploadSuccess && (
                                <div className="import-modal-progress">
                                    <CProgress
                                        color='success'
                                        value={100}
                                        size="md"
                                    />
                                    <p>
                                        File processed successfully. We found {importedValues.length} rows of data in your upload.{" "}
                                        {IMPORT_OPTIONS.find(importOption => importOption.VALUE === importMethod).OPTIONAL_TEXT}
                                    </p>
                                    <div className="import-modal-button">
                                        <CButton color="primary" disabled={!importedValues || !importMethod} onClick={applyChanges}>
                                            APPLY CHANGES
                                        </CButton>
                                        <CButton color="light" type="button" onClick={cancelChange}>
                                            CANCEL CHANGES
                                        </CButton>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </CModalBody>
            </CModal>
        </div>
    )
}

export default ImportTableModal;
