import React, { useContext, useState } from 'react';
import { CButton, CFormGroup, CInput, CCard, CCardBody } from '@coreui/react';
import { useDispatch } from 'react-redux';
import uniq from 'lodash/uniq';

import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { addFirstDomain } from '../../../../../../actions/subscriber';

const AccountDomainRedirect = () => {
    const dispatch = useDispatch();
    const { account, setAccount, stepsData, setStepsData, setCurrentStep } = useContext(CreateAccountContext);
    const [isLoading, setIsLoading] = useState(false);
    const { domain, redirectDomain, domainStatus } = stepsData;

    const onBackBtnClicked = () => {
        setCurrentStep(STEPS.domain);
    };

    const handleGoNext = () => {
        const successNext = () => {
            const newAccount = {
                ...account,
                domains: [{ name: redirectDomain, scriptInstalled: false, status: domainStatus}],
                completedSteps: uniq([...account.completedSteps, STEPS.domain]),
                numberOfDomains: 1
            };

            setAccount(newAccount);
            setStepsData({ domain: redirectDomain, redirectDomain: '' });
            setCurrentStep(STEPS.gtmConnection);
        };

        setIsLoading(true);
        dispatch(addFirstDomain({ accountId: account.id, domain: redirectDomain, domainStatus, successNext, setIsLoading }));
    };

    return (
        <CreateAccountBody onBackBtnClicked={onBackBtnClicked}>
            <CCard className="account-domain redirect">
                <p className="number-of-step">1 of 3 steps</p>
                <CCardBody>
                    {
                        isLoading ? (
                            <CenterSpinner />
                        ) : (
                            <CFormGroup>
                                <h3>We’ll adjust your domain</h3>
                                <p>We noticed that the website you entered redirects.</p>
                                <span className="foot-note">You entered this</span>
                                <CInput
                                    className="adjust-domain"
                                    value={domain}
                                    readOnly
                                />
                                <span className="foot-note">which redirects to</span>
                                <CInput
                                    className="adjust-domain navigation"
                                    value={redirectDomain}
                                    readOnly
                                />
                                <p className="mt-4 domain-setting">
                                    We’ll use <strong>{redirectDomain}</strong> in your domain settings.
                                </p>
                                <CButton className="btn-save" onClick={handleGoNext}>
                                    NEXT
                                </CButton>
                                <CButton className="btn-navigation-step" onClick={onBackBtnClicked}>
                                    <i className="fal fa-angle-left"></i>I want to enter a new website
                                </CButton>
                            </CFormGroup>
                        )
                    }
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default AccountDomainRedirect;
