import React, { useEffect, useState } from "react";
import { CModal, CModalBody, CEmbed } from "@coreui/react";
import PropTypes from 'prop-types';
import CIcon from "@coreui/icons-react";

const LearnVideoPopup = ({ show, onCancel, videoLink, videoName, headline, description }) => {
    const [showEmbed, setShowEmbed] = useState(false)

    useEffect(() => {
        if (show === false) {
            setTimeout(() => {
                setShowEmbed(false)
            }, 150);
        } else {
            setShowEmbed(true)
        }
    }, [show])

    return (
        <div className="learn-video-popup">
            <CModal show={show} onClose={onCancel} color="primary" centered>
                <CModalBody className="learn-video-popup-body">
                    {
                        headline && (
                            <div className="title">
                                <h2>{headline}</h2>
                            </div>
                        )
                    }
                    {
                        description && (
                            <div className="desc">
                                <p>{description}</p>
                            </div>
                        )
                    }
                    <CIcon name="cil-x" onClick={onCancel} className="icon-close-popup" />
                    {
                        showEmbed && (
                            <CEmbed ratio="16by9">
                                <iframe
                                    src={videoLink}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title={`${videoName} video`}
                                />
                            </CEmbed>
                        )
                    }
                </CModalBody>
            </CModal>
        </div>
    );
};

LearnVideoPopup.propTypes = {
    show: PropTypes.bool,
    videoLink: PropTypes.string,
    featureName: PropTypes.string,
    onCancel: PropTypes.func
}

export default LearnVideoPopup;
