import React, { useContext, useState, useCallback, useRef } from 'react';
import CIcon from '@coreui/icons-react';
import {
    CButton,
    CFormGroup,
    CInputFile,
    CLabel,
    CModal,
    CModalBody,
    CModalTitle,
    CProgress,
    CSelect
} from '@coreui/react';
import XLSX from 'xlsx';

import { LookupTableContext } from './LookupTable';
import { OPERATORS_REQUIRE_VALUE } from '../../../../../../constants';

const ImportTableModal = ({ show, toggleModal, exportToFile, getHeaderValues }) => {
    const { setTableRow, tableRow, resetBtnSaveStatus } = useContext(LookupTableContext);
    const [importedFileName, setImportedFileName] = useState('');
    const [importedValues, setImportedValues] = useState([]);
    const [importMethod, setImportMethod] = useState('');
    const [isUploadedError, setIsUploadedError] = useState(false);
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const inputFileRef = useRef(null);

    const IMPORT_OPTIONS = [
        { LABEL: 'Replace entire existing table with new values', VALUE: 'replace', OPTIONAL_TEXT: 'The new records will replace your entire existing table.' },
        { LABEL: 'Insert new values at bottom of existing table', VALUE: 'insert bottom', OPTIONAL_TEXT: 'The new records will be added to the bottom of your existing table.' },
        { LABEL: 'Insert new values at the top of the exist table', VALUE: 'insert top', OPTIONAL_TEXT: 'The new records will be added to the top of your existing table. ' }
    ]

    const resetState = () => {
        setImportedFileName('');
        setImportedValues([]);
        setImportMethod('');
        setIsUploadedError(false);
        setIsUploadSuccess(false);
    }

    const handleSelectImportMethod = useCallback((e) => {
        setImportMethod(e.target.value);
    }, [])

    const clickCancelBtn = () => {
        if (isUploadedError) {
            inputFileRef.current.value = ''; // Set input file value = '', so the user can upload the same file again
            resetState();
        }

        toggleModal();
    }

    const onClose = () => {
        toggleModal();
    }

    const uploadFile = (e) => {
        const { files } = e.target;

        if (files && files.length > 0) {
            var reader = new FileReader();

            reader.onload = function (_) {
                // Use reader.result
                var workbook = XLSX.read(reader.result, {
                    type: 'binary',
                    raw: true // Plain text parsing will not parse values ** (for example: Aug 2019 will not be parsed to 8/1/2019)
                });

                setImportedFileName(files[0].name);

                // Range: 1 => Skip first row => Second row becomes the header
                // defval: '' => All null and undefined points will be filled with defval
                let jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { range: 1, raw: false, defval: '' });

                if (jsonData.length > 0) {
                    setImportedValues(jsonData);
                }
            }

            reader.readAsBinaryString(files[0]);
        }
    }

    const uploadTable = () => {
        setIsUploadedError(false);

        if (!importedValues[0]) {
            return setIsUploadedError(true);
        }

        let { secondRowValues } = getHeaderValues();
        let importedHeaderValues = Object.entries(importedValues[0]).map(([key, _]) => key.trim());

        // Make sure all keys of first element match with second row values, the order isn't matter
        let hasError = secondRowValues.some(secondRowValue => {
            return importedHeaderValues.indexOf(secondRowValue) === -1; // Not found, break
        });

        if (hasError) {
            setIsUploadedError(true);
        } else {
            // Convert to an array of arrays of values from importedValues in order of secondRowValues
            let aoaImportedValues = importedValues.map(importedValue => secondRowValues.map(secondRowValue => importedValue[secondRowValue]));

            let newImportedValues = [];
            let isConditionValueValid = true;

            for (let i = 0; i < aoaImportedValues.length; i++) {
                let valuePosition = 0;
                let newRowConditions = [];

                // eslint-disable-next-line
                tableRow[0].conditions.some((condition) => {
                    // Set like this, if wants to set directly to g0 object, we will have to clone deep to assign new value correctly
                    let newValue = '';
                    let isRegex = false;

                    if (condition.g0.type === 'Variable') {
                        newValue = aoaImportedValues[i][valuePosition];

                        // If the user leaves empty value on a column whose operator existes in OPERATORS_REQUIRE_VALUE
                        if (OPERATORS_REQUIRE_VALUE.includes(condition.g0.operator) && !newValue) {
                            isConditionValueValid = false;
                            return true; // Invalid value, break;
                        }

                        if (aoaImportedValues[i][valuePosition + 1] === '1') {
                            isRegex = true;
                        } else if (aoaImportedValues[i][valuePosition + 1] === '0' | aoaImportedValues[i][valuePosition + 1] === '') {
                            isRegex = false;
                        } else {
                            isConditionValueValid = false;
                            return true; // Invalid value, break;
                        }

                        valuePosition += 2;
                    } else {
                        if (aoaImportedValues[i][valuePosition] === '1') {
                            newValue = 'true';
                        } else if (aoaImportedValues[i][valuePosition] === '0') {
                            newValue = 'false';
                        } else {
                            isConditionValueValid = false;
                            return true; // Invalid value, break;
                        }

                        valuePosition++;
                    }

                    newRowConditions.push({ ...condition, g0: { ...condition.g0, value: newValue, isRegex } });
                    return false;
                })

                let newCustomVariables = tableRow[0].customVariables.map(customVariable => {
                    let newValue = aoaImportedValues[i][valuePosition];
                    valuePosition++;

                    return { ...customVariable, value: newValue };
                })

                newImportedValues.push({ conditions: newRowConditions, customVariables: newCustomVariables });
            }

            if (isConditionValueValid) {
                setImportedValues(newImportedValues);
                setIsUploadSuccess(true);
            } else {
                setIsUploadedError(true);
            }
        }
    }

    const applyChanges = () => {
        let isTableEmpty = tableRow.length === 0;

        if (tableRow.length === 1) {
            // Check if each value in the first row is empty
            isTableEmpty = tableRow[0].conditions.some(condition => condition.g0.value === '');

            if (isTableEmpty) {
                isTableEmpty = tableRow[0].customVariables.some(customVariable => customVariable.value === '');
            }
        }

        if (isTableEmpty) {
            // Table is empty, replace table row with imported rows
            setTableRow(importedValues);
        } else {
            switch (importMethod) {
                case 'replace':
                    setTableRow(importedValues);
                    break;
                case 'insert bottom':
                    setTableRow([...tableRow, ...importedValues]);
                    break;
                case 'insert top':
                    setTableRow([...importedValues, ...tableRow]);
                    break;
                default:
            }
        }

        inputFileRef.current.value = ''; // Set input file value = '', so the user can upload the same file again
        resetState();
        resetBtnSaveStatus(false); // Enable Save Changes button 
        toggleModal();
    }

    const cancelChange = () => {
        inputFileRef.current.value = ''; // Set input file value = '', so the user can upload the same file again
        resetState();
    }

    return (
        <div className="import-modal">
            <CModal
                show={show}
                onClose={onClose}
                centered
            // closeOnBackdrop={false}
            >
                <CModalBody>
                    <div className="import-modal-body">
                        <CIcon name="cil-x" onClick={onClose} className="icon-close-popup" />
                        <CModalTitle>Import Table Values</CModalTitle>
                        <p>
                            Use this feature to import new values for your table. You can replace all existing values or add new values to the
                            {" "}top or bottom of your table. To begin, export your Table Template below. Then populate the values and import it.
                        </p>
                        <div className={`import-modal-step${isUploadSuccess ? ' import-modal-step-disable' : ''}`}>
                            <p>Step 1: Download my table's template:</p>
                            <CButton color="light" onClick={() => exportToFile('csv', 'table-template', true)} disabled={isUploadSuccess}>DOWNLOAD</CButton>
                        </div>
                        <p>Upload my new values:</p>
                        <CFormGroup className={`col-12${isUploadSuccess ? ' import-modal-file-disable' : ''}`}>
                            <CInputFile innerRef={inputFileRef} custom id="custom-file-input" onChange={uploadFile} disabled={isUploadSuccess} />
                            <CLabel htmlFor="custom-file-input" variant="custom-file">
                                {importedFileName || 'Choose file...'}
                            </CLabel>
                        </CFormGroup>
                        <CFormGroup className={`${isUploadSuccess ? ' import-modal-select-disable' : ''}`}>
                            <CSelect value={importMethod} onChange={handleSelectImportMethod} disabled={isUploadSuccess}>
                                <option value="" disabled hidden>Select an option...</option>
                                {
                                    IMPORT_OPTIONS.map(importOption => (
                                        <option key={importOption.VALUE} value={importOption.VALUE}>{importOption.LABEL}</option>
                                    ))
                                }
                            </CSelect>
                        </CFormGroup>
                        {
                            !isUploadSuccess && (
                                <div className="import-modal-button">
                                    <CButton color="primary" disabled={!importedFileName || !importMethod} onClick={uploadTable}>
                                        UPLOAD
                                    </CButton>
                                    <CButton color="light" type="button" onClick={clickCancelBtn}>
                                        CANCEL
                                    </CButton>
                                </div>
                            )
                        }
                        {/* <div className="import-modal-progress">
                            <CProgress
                                color='success'
                                value={100}
                                size="md"
                            />
                            <p>Processing your file...</p>
                        </div> */}
                        {
                            isUploadedError && (
                                <div className="import-modal-error">
                                    <p>
                                        File upload error. Please make sure you are using your table{" "}
                                        template and saving the file as the same format it was downloaded in.
                                    </p>
                                </div>
                            )

                        }
                        {
                            isUploadSuccess && (
                                <div className="import-modal-progress">
                                    <CProgress
                                        color='success'
                                        value={100}
                                        size="md"
                                    />
                                    <p>
                                        File processed successfully. We found {importedValues.length} rows of data in your upload.{" "}
                                        {IMPORT_OPTIONS.find(importOption => importOption.VALUE === importMethod).OPTIONAL_TEXT}
                                    </p>
                                    <div className="import-modal-button">
                                        <CButton color="primary" disabled={!importedValues || !importMethod} onClick={applyChanges}>
                                            APPLY CHANGES
                                        </CButton>
                                        <CButton color="light" type="button" onClick={cancelChange}>
                                            CANCEL CHANGES
                                        </CButton>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </CModalBody>
            </CModal>
        </div>
    )
}

export default ImportTableModal;
