import CIcon from '@coreui/icons-react';
import { CButton, CInput } from '@coreui/react';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useOutsideHandling } from '../../../../utils';

const Dropdown = ({ children, isOpen, target, onClose, fieldFull }) => (
     <div className="select-wrapper">
          {target}
          {fieldFull ? isOpen ? <Menu1>{children}</Menu1> : null : isOpen ? <Menu>{children}</Menu> : null}
          {isOpen ? <Blanket onClick={onClose} /> : null}
     </div>
);

const Menu1 = (props) => {
     return (
          <div
               style={{
                    // backgroundColor: 'white',
                    borderRadius: 4,
                    marginTop: 8,
                    position: 'absolute',
                    zIndex: 2,
                    cursor: 'default',
               }}
               {...props}
          />
     );
};
const Menu = (props) => {
     return (
          <div
               style={{
                    // backgroundColor: 'white',
                    borderRadius: 4,
                    marginTop: 8,
                    position: 'absolute',
                    zIndex: 2,
                    width: '100%',
                    cursor: 'default',
               }}
               {...props}
          />
     );
};

const Blanket = (props) => (
     <div
          style={{
               bottom: 0,
               left: 0,
               top: 0,
               right: 0,
               position: 'absolute',
               zIndex: 1,
          }}
          {...props}
     />
);
const SelectInsertVariable = (props) => {
     const {
          id,
          isBlur,
          placeholder,
          options,
          onChange,
          value,
          isDisabled,
          className,
          classNameWrapper,
          selectInputType,
          displayPlaceholder,
          fieldFull,
          isMulti,
          error,
          dataVariable = true,
          setShowPopupCreateVariables,
          setStorageType,
          setVariableKeyId,
          valueFrom,
     } = props;
     const wrapperRef = useRef(null);
     const filterInputRef = useRef(null);
     const valueInputRef = useRef(null);
     const [isOpen, setIsOpen] = useState(false);
     const [initValue, setInitValue] = useState(value);
     let inputValue;
     if (isMulti) {
          inputValue = initValue && initValue.label && !displayPlaceholder ? initValue.label : placeholder;
     } else {
          inputValue = initValue && initValue.label && !displayPlaceholder ? initValue.label : placeholder;
     }
     const [variableFilter, setVariableFilter] = useState(''); // Current text in the insert data layer variable box filter
     const [openTypeVariable, setOpenTypeVariable] = useState({});
     const [showScroll, setShowScroll] = useState(false);
     let variableFilterLowerCase = variableFilter.toLowerCase();
     // Automatic variable options which are displayed in insert data layer variable box
     let variableOptionsFound = options.filter((option) => option.label !== '' && option.label.toLowerCase().includes(variableFilterLowerCase));

     const valueField = value && value.label ? value.label : '';
     useEffect(() => {
          if (wrapperRef.current) {
               if (wrapperRef.current.scrollHeight > wrapperRef.current.clientHeight) {
                    setShowScroll(true);
               } else {
                    setShowScroll(false);
               }
          }
     }, [openTypeVariable]);
     useEffect(() => {
          if (value) {
               setInitValue(value);
          }
     }, [value]);

     const clickOutside = useCallback(() => {
          setIsOpen(false);
          setOpenTypeVariable({});
          setShowScroll(false);
     }, []);

     useOutsideHandling(wrapperRef, clickOutside, 'mouseup');

     useEffect(() => {
          if (!isMulti) {
               if (isOpen && filterInputRef.current) {
                    setTimeout(() => {
                         filterInputRef.current.focus();
                    }, 0);
               }
               if (!isOpen && valueInputRef.current) valueInputRef.current.focus();
          }
     }, [isMulti, isOpen]);

     const toggleOpen = (e) => {
          const disableInput = e ? !e.target.classList.contains('disable-select') : true;
          if (disableInput)
               setTimeout(() => {
                    setIsOpen(!isOpen);
                    if (valueInputRef.current) valueInputRef.current.readOnly = false;
               }, 1);
          if (!dataVariable) {
               // eslint-disable-next-line
               setOpenTypeVariable((prevState) => ({ ...prevState, ['Data Layer Variable']: !prevState['Data Layer Variable'] }));
          }
     };

     const onSelectChange = (e) => {
          if (isMulti) {
               let newValue = [];
               if (value && value.length > 0) {
                    newValue = [...value, e];
               } else {
                    newValue = [e];
               }
               const { onChange, name, hasChange } = props;
               onChange(name, newValue);
               if (hasChange) {
                    hasChange();
               }
          } else {
               valueInputRef.current.focus();
               toggleOpen();
               setInitValue(e);
               onChange(e);
          }
     };
     const handleOpenTypeVariable = (id) => {
          setOpenTypeVariable((prevState) => ({ ...prevState, [id]: !prevState[id] }));
     };
     const handleSearch = (e) => {
          setVariableFilter(e);
          const searchObject = {};

          const findDatalayerVariable = variableOptionsFound.find((i) => {
               if (i.label.toLowerCase().includes(e.toLowerCase())) {
                    return true;
               }
               return false;
          });
          if (findDatalayerVariable) {
               searchObject['Data Layer Variable'] = true;
          }
          if (Object.keys(searchObject).length > 0) {
               setOpenTypeVariable(searchObject);
          }
     };

     const handleRemoveVariable = (i) => {
          const newValue = [...value];
          newValue.splice(i, 1);
          const { onChange, name, hasChange } = props;
          onChange(name, newValue);
          if (hasChange) {
               hasChange();
          }
          setTimeout(() => {
               setIsOpen(false);
          }, 2);
     };
     const handleCheckShowData = (val, option) => {
          const checkVal = val.find((item) => {
               if (item && item.label && item.label === option.label) {
                    return true;
               }
               return false;
          });
          return checkVal;
     };
     const handleCheckLength = (val, variableOptions) => {
          let length = 0;
          variableOptions.forEach((el) => {
               const checkVal = val.find((item) => {
                    if (item && item.label && item.label === el.label) {
                         return true;
                    }
                    return false;
               });
               if (!checkVal) {
                    length = length + 1;
               }
          });
          return length;
     };
     const handleClick = (option) => {
          if (option.isCustom) {
               setShowPopupCreateVariables(true);
               setStorageType(valueFrom);
               setVariableKeyId(id);
          } else {
               onSelectChange(option);
          }
     }
     return (
          <div
               className={`select-insert-variable ${classNameWrapper} ${isBlur ? ' select-react-customize blur' : ' select-react-customize'}${
                    !value || !value.value ? ' not-selected' : ''
               }`}
               ref={wrapperRef}
          >
               <Dropdown
                    fieldFull={fieldFull}
                    className={className}
                    isOpen={isOpen}
                    onClose={toggleOpen}
                    target={
                         selectInputType ? (
                              <CInput
                                   onClick={() => toggleOpen()}
                                   readOnly
                                   value={inputValue}
                                   disabled={isDisabled}
                                   className={`${isDisabled ? 'disable-select' : ''}`}
                              />
                         ) : (
                              <>
                                   {isMulti ? (
                                        <>
                                             <div
                                                  className={`stretch-container select-multiple-variable-field form-control ${
                                                       isDisabled ? 'disabled' : ''
                                                  } ${isDisabled ? 'disable-select' : ''} `}
                                                  onClick={toggleOpen}
                                             >
                                                  {value && value.length > 0 ? (
                                                       <ul>
                                                            {value.map((v, i) => {
                                                                 return (
                                                                      <Fragment key={i}>
                                                                           {v && v.value && (
                                                                                <li>
                                                                                     {v.value}{' '}
                                                                                     <CButton
                                                                                          onClick={() => handleRemoveVariable(i)}
                                                                                          className="icon-remove-multi"
                                                                                     >
                                                                                          <CIcon
                                                                                               name="iconRemoveMultiSelect"
                                                                                               width={10}
                                                                                               height={10}
                                                                                          />
                                                                                     </CButton>{' '}
                                                                                </li>
                                                                           )}
                                                                      </Fragment>
                                                                 );
                                                            })}
                                                       </ul>
                                                  ) : (
                                                       <div className="place-holder-select">{placeholder}</div>
                                                  )}
                                             </div>
                                        </>
                                   ) : (
                                        <input
                                             type="text"
                                             className={`stretch-container form-control ${isDisabled ? 'disable-select' : ''} `}
                                             onClick={toggleOpen}
                                             ref={valueInputRef}
                                             value={inputValue}
                                             readOnly
                                        ></input>
                                   )}
                              </>
                         )
                    }
               >
                    <div className={`insert-variable-dropdown`}>
                         <ul
                              ref={wrapperRef}
                              className={`${options.length === 0 ? 'value-no-options-available no-options' : ''} ${showScroll ? 'have-scroll' : ''}`}
                         >
                              <li className="variable-filter">
                                   <CInput
                                        innerRef={filterInputRef}
                                        type="text"
                                        value={variableFilter}
                                        onChange={(e) => handleSearch(e.target.value)}
                                        placeholder="Select, or type to search"
                                   />
                              </li>
                              {dataVariable && (
                                   <li
                                        className="d-flex align-items-center justify-content-between border-bottom border-top type-variable"
                                        onClick={() => handleOpenTypeVariable('Data Layer Variable')}
                                   >
                                        <strong>Data Layer Variable</strong>
                                        {openTypeVariable['Data Layer Variable'] ? (
                                             <strong>
                                                  {isMulti ? (
                                                       <>{handleCheckLength(value, variableOptionsFound)}</>
                                                  ) : (
                                                       <>{variableOptionsFound.length}</>
                                                  )}
                                             </strong>
                                        ) : (
                                             <CIcon name="iconArrowDownStrong" />
                                        )}
                                   </li>
                              )}
                              {(openTypeVariable['Data Layer Variable'] || !dataVariable) && (
                                   <>
                                        {variableOptionsFound.length > 0 ? (
                                             <>
                                                  {variableOptionsFound.map((option) => {
                                                       const activeValue = valueField === option.label;
                                                       const re = new RegExp(variableFilter, 'gi');
                                                       let newVariable = option.label;
                                                       if (variableFilter) {
                                                            newVariable = newVariable.replace(re, `<strong>${variableFilter}</strong>`);
                                                       }
                                                       let checkShowValue = false;
                                                       if (isMulti) {
                                                            checkShowValue = handleCheckShowData(value, option);
                                                       }
                                                       return (
                                                            <>
                                                                 {!checkShowValue ? (
                                                                      <li
                                                                           className={`border-bottom d-flex align-items-center ${
                                                                                activeValue ? 'active-variable' : ''
                                                                           } ${option.isCustom ? 'custom' : ''}`}
                                                                           key={option.label}
                                                                           onClick={() => handleClick(option)}
                                                                      >
                                                                           <span dangerouslySetInnerHTML={{ __html: `${newVariable}` }}></span>
                                                                      </li>
                                                                 ) : (
                                                                      ''
                                                                 )}
                                                            </>
                                                       );
                                                  })}
                                             </>
                                        ) : (
                                             <li className="no-options border-bottom d-flex align-items-center">No options found.</li>
                                        )}
                                   </>
                              )}
                         </ul>
                    </div>
                    {/* <ul>
                         <li className='d-flex align-items-center justify-content-between border-bottom type-variable' onClick={() => handleOpenTypeVariable('Data Layer Variable')}>
                              <strong>
                                   Data Layer Variable
                              </strong>
                              {openTypeVariable['Data Layer Variable'] ?
                                   <strong>
                                        {options.length}
                                   </strong>
                                   :
                                   <CIcon name='iconArrowDownStrong' />
                              }

                         </li>
                    </ul> */}
                    {/* <Select
                         id={id}
                         autoFocus
                         backspaceRemovesValue={false}
                         components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                         controlShouldRenderValue={false}
                         hideSelectedOptions={false}
                         isClearable={false}
                         menuIsOpen
                         onChange={(e) => onSelectChange(e)}
                         options={options}
                         filterOption={finalFilterOption}
                         placeholder="Type here to filter the list…"
                         styles={styles}
                         tabSelectsValue={false}
                         value={initValue}
                         className={className}
                         classNamePrefix={classNamePrefix}
                         menuShouldScrollIntoView
                    /> */}
               </Dropdown>
               {error && typeof error === 'string' ? <p style={{ color: '#e55353', fontSize: '80%' }}>{error}</p> : null}
          </div>
     );
};
SelectInsertVariable.propTypes = {
     id: PropTypes.string,
     isBlur: PropTypes.bool,
     placeholder: PropTypes.string,
     options: PropTypes.array,
     onChange: PropTypes.func,
     value: PropTypes.object,
     isDisabled: PropTypes.bool,
     className: PropTypes.string,
     classNamePrefix: PropTypes.string,
     classNameWrapper: PropTypes.string,
     selectInputType: PropTypes.bool,
     displayPlaceholder: PropTypes.bool,
     filterOption: PropTypes.func,
     selectStyles: PropTypes.object,
     isMulti: PropTypes.bool,
};

SelectInsertVariable.defaultProps = {
     selectInputType: true,
};
export default SelectInsertVariable;
