import React, { useState } from 'react';
import Select, { components, createFilter } from 'react-select';
// import { useOutsideHandling } from '../../../utils';

const menuList = (props) => {
     return (
          <components.MenuList {...props} className={`${!Array.isArray(props.children) ? 'no-options' : ''}`}>
               {props.children}
          </components.MenuList>
     );
};

const SelectField = (props) => {
     // const [detailMessage, setDetailMessage] = useState(false);
     // const clickOutside = useCallback(() => {
     //      setDetailMessage(false);
     // }, []);

     // const wrapperRef = useRef(null);
     // useOutsideHandling(wrapperRef, clickOutside);

     // const handleClickBubble = () => {
     //      setTimeout(() => {
     //           if (!detailMessage) {
     //                setDetailMessage(true);
     //           }
     //      }, 100);
     // };
     const handleChange = (value) => {
          const { onChange, name, hasChange , isCustomReport, lvl2GroupIndex, lvl1GroupIndex} = props;
          if(isCustomReport){
               onChange({
                    lvl1GroupIndex,
                    lvl2GroupIndex,
                    propName: 'value',
                    propValue: value
               })
          }
          else {
               onChange(name, value);

          }
          if (hasChange) {
               hasChange();
          }
     };

     const handleBlur = () => {
          const { onBlur, name } = props;
          if(onBlur){
               onBlur(name, true);
          }
     };

     const {
          id,
          name,
          label,
          // tooltip,
          placeholder,
          options,
          value,
          isMulti,
          isDisabled,
          touched,
          error,
          isClearable,
          backspaceRemovesValue,
          noOptionsMessage,
          setErrors,
          className,
     } = props;

     const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

     return (
          <div className={`input-field-wrapper select-field-custom ${className}`}>
               {label && (
                    <>
                         <label className="input-label" htmlFor={name} error={error}>
                              {label}
                         </label>
                         {/* {tooltip && (
                              <span className="position-relative pointer">
                                   <CIcon name="icon-info" className="ml-1" height="14" onClick={handleClickBubble} />
                                   {detailMessage && (
                                        <div className="block-tooltip rule-tooltips" ref={wrapperRef}>
                                             <CIcon name="arrow-preview" className="arrow-preview"></CIcon>
                                             <div className="block-tooltip-text">
                                                  <span>Leave this empty to apply rules to any {label} in this Listener.</span>
                                             </div>
                                        </div>
                                   )}
                              </span>
                         )} */}
                    </>
               )}

               <Select
                    className={`text-dark ${className}`}
                    id={id}
                    placeholder={defaultPlaceholder}
                    options={options}
                    value={value}
                    onChange={(e) => {
                         handleChange(e);
                         if (error) {
                              setErrors({});
                         }
                    }}
                    onBlur={handleBlur}
                    touched={touched}
                    error={error}
                    isMulti={isMulti}
                    isDisabled={isDisabled}
                    isClearable={isClearable}
                    backspaceRemovesValue={backspaceRemovesValue}
                    components={{ ClearIndicator: null, MenuList: menuList }}
                    noOptionsMessage={noOptionsMessage}
                    isSearchable={defaultPlaceholder === placeholder ? false : true}
                    onMenuOpen={() => setDefaultPlaceholder('Type here to filter the list…')}
                    onMenuClose={() => setDefaultPlaceholder(placeholder)}
                    filterOption={createFilter({
                         matchFrom: 'any',
                         stringify: (option) => `${option.label}`,
                    })}
               />
               {touched && error ? <p style={{ color: '#e55353', fontSize: '80%' }}>{error}</p> : null}
          </div>
     );
};

export default SelectField;
