import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CButton, CInput, CLabel, CEmbed, CLink, CRow, CCol } from '@coreui/react';
import { useSelector, useDispatch } from 'react-redux';
import CIcon from '@coreui/icons-react';
import findIndex from 'lodash/findIndex';

import {
     setShowCreateAccountPopup,
     setActiveAccount,
     resetSubscriberReducer,
     fetchListeners,
     fetchAnAccount,
     setCreatedAccount,
} from '../../../../../actions/subscriber';
import { setUser } from '../../../../../actions/common';
import { CreateAccountContext, STEPS } from './CreateAccount';
import {
     LOCAL_SETUP_INVITED_ACCOUNT,
     LOCAL_SHOW_ONLY_ENABLED_LISTENERS,
     LOCAL_SHOW_SETUP_ACCOUNTS,
     AUTH_PATH,
     WELCOME_VIDEO_NEW,
     SCHEDULE_DEMO,
} from '../../../../../constants';
import { getLocalItem } from '../../../../../utils';
import { urlParams } from '../../../../../helpers/cms/subscriber';

const AccountSetup = () => {
     const dispatch = useDispatch();
     const history = useHistory();
     const { account, stepsData, setCurrentStep } = useContext(CreateAccountContext);
     const user = useSelector((state) => state.theme.user);
     const userId = user.id;
     const defaultPackageId = user.defaultPackageId;
     const defaultPackageIdParam = urlParams('defaultPackageId');
     const [showAgain, setShowAgain] = useState(false);
     const [isInvited, setIsInvited] = useState(false); // If true user comes to this component from creating invited user account
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const isFromGetStarted = useSelector((state) => state.subscriber.isFromGetStarted);
     const accounts = useSelector((state) => state.subscriber.accounts);
     const accountIDParam = urlParams('accountID');

     const accountCreatedId = localStorage['accountCreatedId'];

     // Check if user comes to this component from creating invited user account or not
     const checkIfInvited = () => {
          // !stepData.name: Initial current step is step 4
          if (!stepsData.name) {
               let localSetupInvitedAccount = localStorage.getItem(LOCAL_SETUP_INVITED_ACCOUNT);

               // Remove to show this component when logging in from local storage
               localStorage.removeItem(LOCAL_SETUP_INVITED_ACCOUNT);
               setIsInvited(localSetupInvitedAccount === 'yes');
          }
     };

     useEffect(checkIfInvited, []);

     // Save show this setup account again to local
     const saveShowAgain = () => {
          if (showAgain) {
               let localShowSetupAccounts = getLocalItem(LOCAL_SHOW_SETUP_ACCOUNTS);
               let localIsInvited = isInvited ? 'yes' : 'no';

               if (localShowSetupAccounts && localShowSetupAccounts.length > 0) {
                    // localShowSetupAccounts must be an array with length > 0
                    let existedIndex = findIndex(localShowSetupAccounts, { userId });

                    if (existedIndex === -1) {
                         localShowSetupAccounts.push({ userId, isInvited: localIsInvited });
                         localStorage.setItem(LOCAL_SHOW_SETUP_ACCOUNTS, JSON.stringify(localShowSetupAccounts));
                    } else {
                         localShowSetupAccounts[existedIndex].isInvited = localIsInvited;
                         localStorage.setItem(LOCAL_SHOW_SETUP_ACCOUNTS, JSON.stringify(localShowSetupAccounts));
                    }
               } else {
                    // localShowSetupAccounts === null || localShowSetupAccounts === []
                    localStorage.setItem(LOCAL_SHOW_SETUP_ACCOUNTS, JSON.stringify([{ userId, isInvited: localIsInvited }]));
               }
          }
     };

     const setIfHasName = () => {
          if (stepsData.name) {
               account.reachTheEndOfSetup = true;
               // Go to this case if creating a new account or from "Get Started"
               if (!isFromGetStarted) {
                    dispatch(resetSubscriberReducer());
                    dispatch(setActiveAccount(account));
                    localStorage.setItem('activeAccountId', account.id);
                    localStorage.setItem('accountId', account.id);
                    localStorage.removeItem(LOCAL_SHOW_ONLY_ENABLED_LISTENERS);
                    history.push('/');
               } else {
                    dispatch(setActiveAccount(account));
               }

               if (stepsData.shouldFetchListeners) {
                    dispatch(fetchAnAccount(account, accounts)); // Mainly fetch new used items for selected account
                    dispatch(fetchListeners(account.id, activeAccount.secondId));
               }
          }
     };

     // const closeBtnClicked = () => {
     //      setIfHasName();
     //      dispatch(setShowCreateAccountPopup(false));
     //      saveShowAgain();
     // }

     const continueBtnClicked = () => {
          setIfHasName();
          dispatch(setShowCreateAccountPopup(false));
          saveShowAgain();
          dispatch(setCreatedAccount(null));
     };

     const saveShowAgainInstall = () => {
          // const accountCreated = accounts[accounts.length - 1];
          // dispatch(resetSubscriberReducer());
          // dispatch(setActiveAccount(accountCreated));
          // localStorage.setItem('accountId', accountCreated.id);
          // localStorage.removeItem(LOCAL_SHOW_ONLY_ENABLED_LISTENERS);
          setIfHasName();
          dispatch(setShowCreateAccountPopup(false));
          if (showAgain) {
               let localShowInstallation = getLocalItem('showInstallationScreen');

               if (localShowInstallation && localShowInstallation.length > 0) {
                    const existedIndex = localShowInstallation.indexOf(userId);
                    if (existedIndex === -1) {
                         localShowInstallation.push(userId);

                         localStorage.setItem('showInstallationScreen', JSON.stringify(localShowInstallation));
                    }
               } else {
                    localStorage.setItem('showInstallationScreen', JSON.stringify([userId]));
               }
          } else {
               let localShowInstallation = getLocalItem('showInstallationScreen');

               if (localShowInstallation && localShowInstallation.length > 0) {
                    const existedIndex = localShowInstallation.indexOf(userId);
                    if (existedIndex !== -1) {
                         let newLocalShowInstallation = [...localShowInstallation];
                         newLocalShowInstallation.splice(existedIndex, 1);
                         localStorage.setItem('showInstallationScreen', JSON.stringify(newLocalShowInstallation));
                    }
               }
          }
     };

     const continueBtnClickedInstall = () => {
          saveShowAgainInstall();

          const createAccount = accounts.find(_v => _v.id === accountCreatedId);
          const secondId = createAccount ? `/${createAccount.secondId}` : '/'

          if (accountIDParam) {
               history.push('/?accountID=' + accountCreatedId ? accountCreatedId : activeAccount.accountId);
          } else {
               history.push(secondId);
          }
          if (defaultPackageId) {
               delete user.defaultPackageId;
               dispatch(setUser(user));
          }
          if (defaultPackageIdParam) {
               history.push(secondId);
          }
          dispatch(setCreatedAccount(null));
     };

     // const closeBtnClickedInstall = () => {
     //      saveShowAgainInstall();
     //      if (accountIDParam) {
     //           history.push('/?accountID=' + accountCreatedId ? accountCreatedId : activeAccount.accountId);
     //      } else {
     //           history.push('/');
     //      }
     //      if (defaultPackageId) {
     //           delete user.defaultPackageId
     //           dispatch(setUser(user))
     //      }
     //      if (defaultPackageIdParam) {
     //           history.push('/');
     //      }
     // }

     // const toggleShowAgain = () => {
     //      setShowAgain(!showAgain);
     // };

     let url = process.env.REACT_APP_LISTENLAYER_DOMAIN;

     const onClickScheduleDemo = () => {
          setCurrentStep(STEPS.scheduleDemo);
     };

     return (
          <>
               {/* {
                    isInvited ? (
                         <CIcon name="cil-x" onClick={closeBtnClicked} className="icon-close-popup 1"></CIcon>
                    ) : (<CIcon name="cil-x" onClick={closeBtnClickedInstall} className="icon-close-popup 2"></CIcon>)
               } */}
               <CIcon name="logo-dark" height={30} />
               <div className="setup-new-account">
                    {isInvited ? (
                         <>
                              <h3>Welcome to ListenLayer</h3>
                              <p>Watch the overview video to become familiar with the ListenLayer interface.</p>
                         </>
                    ) : (
                         <>
                              <h3>Setup your new account</h3>
                              {/* <p>Watch this video to get the most out of ListenLayer and speed up your configuration.</p> */}
                              <p>Watch this 40 second video to jumpstart your implementation.</p>
                         </>
                    )}
                    <div className="open-new-screen">
                         {isInvited ? (
                              <CLink
                                   href={`${AUTH_PATH.WELCOME_SCREEN}?accountID=${accountCreatedId ? accountCreatedId : activeAccount.accountId}`}
                                   target="_blank"
                              >
                                   open screen in new tab
                              </CLink>
                         ) : (
                              <CLink
                                   href={`/installation?accountID=${accountCreatedId ? accountCreatedId : activeAccount.accountId}`}
                                   target="_blank"
                              >
                                   open screen in new tab
                              </CLink>
                         )}
                    </div>
                    <div className="video-wrapper d-flex justify-content-center align-items-center">
                         {/* <div className="icon-play"><CIcon name="arrow-play" className="text-primary" height={30} /></div> */}
                         <CEmbed ratio="16by9">
                              <iframe
                                   src={WELCOME_VIDEO_NEW}
                                   frameBorder="0"
                                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                   allowFullScreen
                                   title="Welcome to ListenLayer"
                              />
                         </CEmbed>
                    </div>
                    {/* {
                         isInvited ? (
                              <CLink href={`/installation?accountID=${accountCreatedId ? accountCreatedId : activeAccount.accountId}`}>watch the full installation video instead</CLink>
                         ) : (<></>)
                    } */}
                    <div className="button-row">
                         <div className="checkbox-show-again">
                              <CInput type="checkbox" id="showAgain" checked={showAgain} onChange={(e) => setShowAgain(e.target.checked)} />
                              {/* <CButton onClick={toggleShowAgain} className="check-login">{showAgain &&  <img src="./assets/icons/play-icon.svg" className="play-icon"></img>}</CButton> */}
                              <CLabel htmlFor="showAgain">Show this again on my next login.</CLabel>
                         </div>
                         <div className="btn-wrapper">
                              <CRow>
                                   <CCol lg="12">
                                        {!(localStorage.getItem(SCHEDULE_DEMO) === 'true') && (
                                             <CButton className="btn btn-success text-uppercase" onClick={() => onClickScheduleDemo()}>
                                                  SCHEDULE A DEMO
                                             </CButton>
                                        )}
                                   </CCol>
                                   <CCol lg="12">
                                        {isInvited ? (
                                             <CButton className="btn-save" onClick={continueBtnClicked}>
                                                  GO TO MY ACCOUNT
                                             </CButton>
                                        ) : (
                                             <CButton className="btn-save" onClick={continueBtnClickedInstall}>
                                                  GO TO MY ACCOUNT
                                             </CButton>
                                        )}
                                   </CCol>
                              </CRow>
                         </div>
                    </div>
                    <div className="footer text-center new-account-footer">
                         <CIcon name="logo-dark" height={34} />
                         <div className="d-flex justify-content-center">
                              <div>
                                   <a className="text-footer" href={`${url}support/`}>
                                        Support
                                   </a>
                              </div>
                              <div>
                                   <a className="text-footer" href={`${url}terms-of-use/`}>
                                        Terms of Use
                                   </a>
                              </div>
                              <div>
                                   <a className="text-footer" href={`${url}privacy-policy/`}>
                                        Privacy & Data Policy
                                   </a>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
};

export default AccountSetup;
