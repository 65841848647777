import React from 'react'
import CIcon from '@coreui/icons-react';
const PreArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={`${className} faleft`} onClick={onClick}>
          <CIcon name="fa-left"></CIcon>
        </div>
    )
}

export default PreArrow