import React, { useContext } from 'react';
import { CButton, CCard, CCardBody, CInput } from '@coreui/react';
import { useSelector } from 'react-redux';

import { CHANGE_STEP_TYPE, GTMWizardBody, GTMWizardContext } from '.';
import { GTM_WIZARD_STEPS, GTM_WIZARD_TYPE } from '../../../../../constants';

const GTMPublishData = () => {
     const { setCurrentStep } = useContext(GTMWizardContext);
     const gtmWizard = useSelector(state => state.subscriber.gtmWizard);

     const getDisplayData = () => {
          let displayData = {};

          switch (gtmWizard.type) {
               case GTM_WIZARD_TYPE.VARIABLE:
                    displayData = {
                         headline: `Publish inside GTM`,
                         subHeadline: `We're going to publish your GTM for the following Variable inside ListenLayer:`,
                         inputValues: [gtmWizard.data.name],
                    };

                    break;
               case GTM_WIZARD_TYPE.TRIGGER:
               case GTM_WIZARD_TYPE.TRIGGER_CONVERSION:
                    displayData = {
                         headline: `Publish inside GTM`,
                         subHeadline: `We're going to publish your GTM for the following Trigger inside ListenLayer:`,
                         inputValues: [gtmWizard.data.name],
                    };

                    if (gtmWizard.type === GTM_WIZARD_TYPE.TRIGGER_CONVERSION) {
                         displayData.subHeadline = displayData.subHeadline.replace('Trigger', 'Conversion');
                    }

                    break;
               case GTM_WIZARD_TYPE.DATA_ACTION:
                    displayData = {
                         headline: `Publish inside GTM`,
                         subHeadline: `We're going to publish your GTM for the following Data Action inside ListenLayer:`,
                         inputValues: [gtmWizard.data.name],
                    };
                    break;
               case GTM_WIZARD_TYPE.DESTINATION_CONVERSION:
                    const conversionNames = gtmWizard.data.conversions.map(el => el.name);

                    displayData = {
                         headline: `Publish inside GTM`,
                         subHeadline: `We're going to publish your GTM for the following Conversions inside ListenLayer:`,
                         inputValues: conversionNames,
                    };
                    break;
               case GTM_WIZARD_TYPE.DESTINATION_ECOMMERCE:
                    const eventEcommerceName = gtmWizard.data.ecommerceEvent.map(el => el.name);
                    
                    displayData = {
                         headline: `Publish inside GTM`,
                         subHeadline: `We're going to publish your GTM for the following Conversions inside ListenLayer:`,
                         inputValues: eventEcommerceName,
                    };
                    break;
               default:
          }

          return displayData;
     };

     const displayData = getDisplayData();

     const onBackBtnClicked = () => {
          setCurrentStep(null, CHANGE_STEP_TYPE.PREV);
     };

     const onContinueBtnClicked = () => {
          setCurrentStep(GTM_WIZARD_STEPS.gtmMakeChanges);
     };

     return (
          <GTMWizardBody onBackBtnClicked={onBackBtnClicked} >
               <CCard className="account-domain checking">
                    <CCardBody>
                         <h3>{displayData.headline}</h3>
                         <p className="sub-headline">{displayData.subHeadline}</p>
                         {
                              displayData.inputValues && displayData.inputValues.map(el => (
                                   <CInput key={el} className="mb-3" value={el} readOnly />
                              ))
                         }
                         <CButton className="btn-save mb-3" onClick={onContinueBtnClicked}>
                              Continue
                         </CButton>
                         <div>
                              <span className="note">
                                   We'll publish the entire workspace.
                              </span>
                         </div>
                    </CCardBody>
               </CCard>
          </GTMWizardBody>
     )
};

export default GTMPublishData;