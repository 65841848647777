import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleFetchFormsIgnored, handleFetchFormsRequiring, setSubscriberState } from '../../../../../../actions/subscriber';
import FormRequiringLayout from './FormRequiringLayout';
import FormIgnoredLayout from './FormIgnoredLayout';

const AllRowFormRequiringAction = () => {
     const dispatch = useDispatch();
     const allFormsIgnored = useSelector((state) => state.subscriber.allFormsIgnored);
     const allFormsRequiring = useSelector((state) => state.subscriber.allFormsRequiring);
     const countFormRequiring = useSelector((state) => state.subscriber.countFormRequiring);
     const showIgnoredForm = useSelector((state) => state.subscriber.showIgnoredForm);
     const requiringCountLoading = useSelector((state) => state.subscriber.requiringCountLoading);
     const requiringNumberPerpage = useSelector((state) => state.subscriber.requiringNumberPerpage);
     const ignoredNumberPerpage = useSelector((state) => state.subscriber.ignoredNumberPerpage);
     const itemsPerPage = 20;

     const onLoadMoreIgnored = (numberPage) => {
          dispatch(handleFetchFormsIgnored(itemsPerPage, true, numberPage));
     };

     const onLoadMoreRequiring = (numberPage) => {
          dispatch(handleFetchFormsRequiring(itemsPerPage, true, numberPage));
     };

     const fetchData = () => {
          if (!allFormsRequiring) {
               dispatch(handleFetchFormsRequiring(itemsPerPage, false, 0));
          }
          if (!allFormsIgnored) {
               dispatch(handleFetchFormsIgnored(itemsPerPage, false, 0));
          }
     };

     useEffect(fetchData, [showIgnoredForm]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (allFormsRequiring && allFormsRequiring.length === 0) {
               let newNumberPage = 0;
     
               if (requiringNumberPerpage > 0) {
                    newNumberPage = requiringNumberPerpage - 1;
               }
               dispatch(handleFetchFormsRequiring(20, true, newNumberPage));

               if (requiringNumberPerpage === 1) {
                    dispatch(setSubscriberState({ requiringShowPaging: false }));
               }
          }

          if (allFormsIgnored && allFormsIgnored.length === 0) {
               let newNumberPage = 0;

               if (ignoredNumberPerpage > 0) {
                    newNumberPage = ignoredNumberPerpage - 1;
               }
               dispatch(handleFetchFormsIgnored(20, true, newNumberPage));

               if (ignoredNumberPerpage === 1) {
                    dispatch(setSubscriberState({ ignoredShowPaging: false }));
               }
          }
     }, [allFormsRequiring, allFormsIgnored]); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <>
               <div className='form-inventory-header mb-4'>
                    <p className='mb-0 subtitle'>Manage Form Categories:</p>
                    <h1>Form Requiring Action</h1>
                    <p>View and easily categorize all of the forms on your website.</p>
                    <p>
                         We’ve found and are Listening to {requiringCountLoading ? '...' : countFormRequiring} {+countFormRequiring === 1 ? ' form' : ' forms'} on your website. Make sure
                         all of them are categorized below based on the purpose of the forms.
                    </p>
               </div>

               {showIgnoredForm ? (
                    <FormIgnoredLayout
                         className='viewAllRequiringAction'
                         tableClassName='table-requiring-action-all mt-0'
                         onLoadMore={onLoadMoreIgnored}
                         formsIgnored={allFormsIgnored}
                    />
               ) : (
                    <FormRequiringLayout
                         className='viewAllRequiringAction'
                         tableClassName='table-requiring-action-all mt-0'
                         forms={allFormsRequiring}
                         onLoadMore={onLoadMoreRequiring}
                    />
               )}
          </>
     );
};

export default AllRowFormRequiringAction;
