import { CButton, CCard, CCardBody, CCardHeader, CCol, CLabel, CRow } from '@coreui/react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { setFlexibleModal, setRuleHaveEditting, setViewedRule } from '../../../../../../actions/common';
import { callTokenApi } from '../../../../../../apiCaller';
import {
     API_CLIENT_ACCOUNT_RULE,
     NATIVE_BROWSER_CONDITIONS,
     NO_OPTION_MESSAGE_TYPE,
     TYPE_SHOW_UNSAVE_CHANGE,
     VARIABLE_OPERATORS,
} from '../../../../../../constants';
import { useEventAndVariableAccountOptions } from '../../../../../../helpers/customHooks';
import { clearConversionDraft, deepCopyArray, toastError } from '../../../../../../utils';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { NoOptionsMessage } from '../../../../../general/rules';
import SelectInsertVariable from '../../../listener-settings-v2/SelectInsertVariable';
import { RuleSettingContext } from '../EditLookupSetting';
import SelectField from './SelectField';

const validationSchema = () => {
     const isArrayEmpty = (array) => {
          return !array || array.length === 0;
     };

     return Yup.object().shape(
          {
               events: Yup.array()
                    .when(['variables', 'eventsNative'], {
                         is: (variables, eventsNative) => isArrayEmpty(variables) && isArrayEmpty(eventsNative),
                         then: Yup.array()
                              .min(1, 'Please choose at least one option of these fields!')
                              .required('Please choose at least one option of these fields!'),
                    })
                    .nullable(true),

               eventsNative: Yup.array()
                    .when(['events', 'variables'], {
                         is: (events, variables) => isArrayEmpty(events) && isArrayEmpty(variables),
                         then: Yup.array()
                              .min(1, 'Please choose at least one option of these fields!')
                              .required('Please choose at least one option of these fields!'),
                    })
                    .nullable(true),

               variables: Yup.array()
                    .when(['events', 'eventsNative'], {
                         is: (events, eventsNative) => isArrayEmpty(events) && isArrayEmpty(eventsNative),
                         then: Yup.array()
                              .min(1, 'Please choose at least one option of these fields!')
                              .required('Please choose at least one option of these fields!'),
                    })
                    .nullable(true),
          },
          [
               ['events', 'eventsNative'],
               ['events', 'variables'],
               ['eventsNative', 'variables'],
          ]
     );
};

const EditStep3 = ({ stepsData, setStepsData }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const { activeStep, setActiveStep } = useContext(RuleSettingContext);
     const [updateModal, setUpdateModal] = useState(false);
     const { conditions } = stepsData;
     const [newConditions, setNewConditions] = useState([]);
     const [stepSaveLoading, setStepSaveLoading] = useState(false);
     const [saveLoading, setSaveLoading] = useState(false);
     const { fetchLoading, variables, events, nativeBrowserStorage, nativeBrowserStorageOpen } = useEventAndVariableAccountOptions(activeStep === 3);
     const eventTypes = ['Event', 'NativeBrowserStorage'];
     const initialGroup = {
          type: 'Event',
          key: '',
          operator: '',
          value: '',
          isRegex: false,
     };

     const getEventOptions = () => {
          var eventData = [];
          if (events && events.length > 0) {
               eventData = events.map((item) => {
                    return {
                         label: item.code,
                         value: item.code,
                    };
               });
          }

          return eventData;
     };

     const getNativeBrowserStorageOptions = () => {
          var eventData = [];

          function capitalizeFirstLetter(string) {
               return string[0].toUpperCase() + string.slice(1);
          }

          if (nativeBrowserStorage && nativeBrowserStorage.length > 0) {
               eventData = nativeBrowserStorage.map((item) => {
                    return {
                         label: `${capitalizeFirstLetter(item.type)}: ${item.name}, Format: ${capitalizeFirstLetter(item.format)}`,
                         value: JSON.stringify(item),
                    };
               });
          }

          return eventData;
     };

     const getVariableOption = (item) => {
          return {
               label: item.fullName,
               value: item.fullName,
          };
     };

     const getVariableOptions = (variables) => {
          let variableData = [];
          if (variables && variables.length > 0) {
               variables.forEach((variable) => {
                    if (variable.status && variable.childs) {
                         variableData.push(...getVariableOptions(variable.childs));
                    } else {
                         variableData.push(getVariableOption(variable));
                    }
               });
          }
          return variableData;
     };

     const getConjunctions = (type, key) => {
          let operator = eventTypes.includes(type) ? 'eq' : VARIABLE_OPERATORS[0].VALUE;

          if (type === eventTypes[1] && key.includes(`"format":"date function"`)) {
               operator = NATIVE_BROWSER_CONDITIONS.DATE_FUNCTION[0].VALUE; // Value of the first option
          }

          let newGroup = {
               conjunction: 'and',
               g0: {
                    ...initialGroup,
                    operator,
                    type,
                    key,
               },
          };
          return newGroup;
     };

     const getInitialConditions = () => {
          let eventList = [],
               variableList = [];
          let eventOptions = getEventOptions();
          let variableOptions = getVariableOptions(variables);
          let initialValues = {
               events: [],
               eventsNative: [],
               variables: [],
          };
          if (conditions && conditions.length > 0) {
               conditions[0].forEach((item) => {
                    if (eventTypes.includes(item.g0.type)) {
                         eventList.push(item.g0.key);
                    } else {
                         variableList.push(item.g0.key);
                    }
               });

               const getLastChosenOptions = (arr, list, key) => {
                    arr.forEach((item) => {
                         let index = list.indexOf(item.value);
                         if (index !== -1) {
                              initialValues[key][index] = { ...item };
                         }
                    });
               };
               getLastChosenOptions(eventOptions, eventList, 'events');
               let nativeBrowserStorageOptions = getNativeBrowserStorageOptions();
               getLastChosenOptions(nativeBrowserStorageOptions, eventList, 'eventsNative');
               getLastChosenOptions(variableOptions, variableList, 'variables');
          }

          return initialValues;
     };

     const initialValues = getInitialConditions();

     const onSubmit = (values) => {
          let { events, variables } = values;
          let conditions = [];
          let condition = [];

          if (events) {
               events.forEach((event) => {
                    condition.push(getConjunctions('Event', event.value));
               });
          }
          if (variables) {
               variables.forEach((variable) => {
                    condition.push(getConjunctions('Variable', variable.value));
               });
          }

          conditions.push(condition);
          setNewConditions(conditions);

          if (compareTwoConditions(stepsData.conditions[0], conditions[0])) {
               setActiveStep(4);
          } else {
               setUpdateModal(true);
               setStepSaveLoading(true);
          }
     };

     const handleAcceptPopup = () => {
          setSaveLoading(true);
          let conditions = [...newConditions];
          let newStepsDataConditions = [...stepsData.conditions];
          let newList = [],
               newStepsData = {};
          newList = newStepsDataConditions.map((stepCondition) => {
               let newCons = [];
               newCons = conditions[0].map((condition) => {
                    let findIndex = checkInArray(condition.g0.key, stepCondition);
                    if (findIndex !== -1) {
                         condition = stepCondition[findIndex];
                    }
                    return condition;
               });
               return deepCopyArray(newCons);
          });

          newList.forEach((condition) => {
               condition.forEach((item) => {
                    if (item.conjunction === '') {
                         item.conjunction = 'and';
                    }
               });
               condition[condition.length - 1].conjunction = '';
          });

          newStepsData = { ...stepsData, conditions: newList };
          callTokenApi(API_CLIENT_ACCOUNT_RULE, 'PUT', newStepsData)
               .then((response) => {
                    if (response.status === 200) {
                         toast.success('Successfully update rule');
                         dispatch(setViewedRule({ ...newStepsData }, true));
                         dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
                         if (activeAccount.id) {
                              clearConversionDraft(activeAccount.id);
                         }
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setSaveLoading(false);
                    setStepSaveLoading(false);
                    setStepsData(newStepsData);
                    setUpdateModal(false);
                    setActiveStep(4);
               });
     };

     const compareTwoConditions = (arr1, arr2) => {
          if (arr1.length !== arr2.length) {
               return false;
          }

          for (let index in arr1) {
               if (arr1[index].g0.key !== arr2[index].g0.key) return false;
          }
          return true;
     };

     const checkInArray = (value, array) => {
          let index = -1;
          array.some((item, itemIndex) => {
               if (item.g0.key.indexOf(value) !== -1) {
                    index = itemIndex;
                    return true;
               }
               return false;
          });
          return index;
     };

     const handleHasChange = () => {
          dispatch(setRuleHaveEditting({ showLv2: true, typeLv2: TYPE_SHOW_UNSAVE_CHANGE.EDIT_LOOKUP }));
     };

     const handleClickLinkAction = () => {
          dispatch(
               setFlexibleModal({
                    show: false,
                    showLv2: false,
                    ruleId: '',
                    ruleIdLv2: '',
                    component: '',
                    componentLv2: '',
               })
          );
     };
     return (
          <>
               <CCard className={`cvr-step-3 ${activeStep !== 3 ? 'difference-step' : 'cvr-step-card'}`}>
                    <CCardHeader>
                         {activeStep !== 3 ? (
                              <div className="rule-step d-inline-flex justify-content-between align-items-center w-100">
                                   <h5 className="mb-0 inactive">Step 3: Select Condition Fields</h5>
                                   {stepsData.conditions.length > 0 && (
                                        <CButton className="btn-edit" onClick={() => setActiveStep(3)}>
                                             Edit
                                        </CButton>
                                   )}
                              </div>
                         ) : (
                              <h5>Step 3: Select Condition Fields</h5>
                         )}
                    </CCardHeader>
                    {activeStep === 3 ? (
                         <>
                              <CCardBody>
                                   <CRow>
                                        <CCol md="12">
                                             {fetchLoading ? (
                                                  <CenterSpinner />
                                             ) : (
                                                  <Formik
                                                       initialValues={initialValues}
                                                       validationSchema={validationSchema}
                                                       onSubmit={onSubmit}
                                                       validateOnChange={false}
                                                       validateOnBlur={false}
                                                       enableReinitialize
                                                  >
                                                       {({ values, errors, touched, setFieldValue, setFieldTouched, handleSubmit, setErrors }) => (
                                                            <Form onSubmit={handleSubmit} className="rule-step">
                                                                 <CRow>
                                                                      <CCol lg="6" xl="9">
                                                                           <p>
                                                                                Select the data layer events, variables, or browser storage you would
                                                                                like to use as conditions in the rules that determine these Triggers
                                                                           </p>
                                                                      </CCol>
                                                                      <CCol lg="6" xl="9">
                                                                           <div className="events-multiselect mb-3">
                                                                                <SelectField
                                                                                     id="events"
                                                                                     name="events"
                                                                                     label={'Events'}
                                                                                     tooltip={true}
                                                                                     placeholder="Select Events"
                                                                                     options={getEventOptions()}
                                                                                     value={values.events}
                                                                                     // invalid={!!errors.events}
                                                                                     isMulti={true}
                                                                                     onChange={setFieldValue}
                                                                                     handleHasChange={handleHasChange}
                                                                                     onBlur={setFieldTouched}
                                                                                     touched={touched.events}
                                                                                     error={errors.events}
                                                                                     isClearable={true}
                                                                                     backspaceRemovesValue={true}
                                                                                     disabled={stepSaveLoading}
                                                                                     // noOptionsMessage={() => <NoOptionsMessage type={NO_OPTION_MESSAGE_TYPE.EVENT} options={getEventOptions()} />}
                                                                                     setErrors={setErrors}
                                                                                />
                                                                           </div>
                                                                           {nativeBrowserStorageOpen && (
                                                                                <div className="events-multiselect mb-3">
                                                                                     <SelectField
                                                                                          id="eventsNative"
                                                                                          name="eventsNative"
                                                                                          label="Native Browser Storage"
                                                                                          tooltip={true}
                                                                                          placeholder="Select Native Browser Storage"
                                                                                          options={getNativeBrowserStorageOptions()}
                                                                                          value={values.eventsNative}
                                                                                          // invalid={!!errors.events}
                                                                                          isMulti={true}
                                                                                          isNative={true}
                                                                                          onChange={setFieldValue}
                                                                                          onBlur={setFieldTouched}
                                                                                          handleHasChange={handleHasChange}
                                                                                          touched={touched.events}
                                                                                          error={errors.events}
                                                                                          isClearable={true}
                                                                                          backspaceRemovesValue={true}
                                                                                          disabled={stepSaveLoading}
                                                                                          noOptionsMessage={() => (
                                                                                               <NoOptionsMessage
                                                                                                    handleClickLinkAction={handleClickLinkAction}
                                                                                                    type={
                                                                                                         NO_OPTION_MESSAGE_TYPE.CUSTOM_BROWSER_VARIABLE
                                                                                                    }
                                                                                                    options={getNativeBrowserStorageOptions()}
                                                                                               />
                                                                                          )}
                                                                                          setErrors={setErrors}
                                                                                     />
                                                                                </div>
                                                                           )}
                                                                           <div className="variables-multiselect mb-3">
                                                                                <CLabel>Variables</CLabel>
                                                                                <SelectInsertVariable
                                                                                     name="variables"
                                                                                     placeholder="Select Variables"
                                                                                     options={getVariableOptions(variables)}
                                                                                     value={values.variables}
                                                                                     onChange={setFieldValue}
                                                                                     hasChange={handleHasChange}
                                                                                     isMulti={true}
                                                                                     selectInputType={false}
                                                                                     error={errors.variables}
                                                                                />
                                                                           </div>
                                                                      </CCol>
                                                                 </CRow>
                                                                 <div className="text-left">
                                                                      <CButton
                                                                           className="px-4"
                                                                           color="primary"
                                                                           shape="square"
                                                                           type="submit"
                                                                           disabled={stepSaveLoading}
                                                                      >
                                                                           {`Save & Next`}
                                                                      </CButton>
                                                                 </div>
                                                            </Form>
                                                       )}
                                                  </Formik>
                                             )}
                                        </CCol>
                                   </CRow>
                              </CCardBody>
                         </>
                    ) : (
                         ''
                    )}
               </CCard>
               <ConfirmSaveChange
                    show={updateModal}
                    onClose={() => {
                         setUpdateModal(false);
                         setStepSaveLoading(false);
                    }}
                    onAccept={handleAcceptPopup}
                    title="Warning! This Will Change Your Rule"
                    isLoading={saveLoading}
               >
                    You are changing Conditions. This will make some changes to this rule. You will need to review your lookup table settings and
                    adjust them as needed.
               </ConfirmSaveChange>
          </>
     );
};

EditStep3.propTypes = {
     stepsData: PropTypes.object,
     setStepsData: PropTypes.func,
};
export default EditStep3;
