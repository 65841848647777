import React, { useContext, useState } from 'react';
import {
     CButton,
     CCard,
     CCardBody,
     CForm,
     CFormGroup,
     CInput,
     CLabel,
     CTextarea,
     CCol,
     CInvalidFeedback
} from '@coreui/react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { CustomDestinationBody, CustomDestinationContext } from '.';
import { API_CLIENT_ACCOUNT_DESTINATIONS, API_CLIENT_DESTINATIONS_CUSTOM, CUSTOM_DESTINATION_MODAL_STEPS, SUBSCRIBER_PATH } from '../../../../../constants';
import { callTokenApi } from '../../../../../apiCaller';
import { toastError } from '../../../../../utils';
import Waiting from '../../../../general/Loadings/Waiting';
import { getDestinationRoutes, getEnabledListeners } from '../../../../../helpers/cms/subscriber';
import { setSubscriberState } from '../../../../../actions/subscriber';
import { HoverInfoIconTooltip } from '../../../../general/custom-tooltips';
import { useHistory } from 'react-router-dom';

const validationSchema = (setValidateOnChange) => {
     setValidateOnChange(true);

     return Yup.object().shape({
          name: Yup.string().trim().required('Required.').matches(/^[a-zA-Z0-9 ]+$/g, 'Allow only alphabet, numeric and spacing characters.')
     })
}

const NameDestination = () => {
     const dispatch = useDispatch();
     const history = useHistory();
     const { setCurrentStep, closeModal } = useContext(CustomDestinationContext);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const activeAccountId = useSelector((state) => state.subscriber.activeAccount.id);
     const { data } = useSelector((state) => state.subscriber.customDestinationModal);
     const accountRoutes = useSelector((state) => state.subscriber.routes);
     const reducerAccountDestinations = useSelector((state) => state.subscriber.accountDestinations);
     const [validateOnChange, setValidateOnChange] = useState(false);
     let isUpdating = false, name = '', description = '';

     if (data) {
          isUpdating = data.isUpdating;
          name = data.name;
          description = data.description;
     }

     const initialValues = { name, description };

     const goBack = () => {
          if (isUpdating) {
               closeModal();
          } else {
               setCurrentStep(CUSTOM_DESTINATION_MODAL_STEPS.INTRO);
          }
     };

     const handleChangeActiveDestinations = ({ destinationId, accountDestinations, accountDestination, isNewDestination }) => {
          let newAccountDestination = [...accountDestinations];

          if (isNewDestination) {
               let newDestination = {};
               let destinationIndex = 0;

               accountDestinations.some((destination, index) => {
                    if (destinationId === destination.id) {
                         destinationIndex = index;
                         newDestination = destination;
                         newDestination.accountDestinationId = accountDestination.id;

                         return true;
                    }

                    return false;
               });
               newAccountDestination[destinationIndex] = newDestination;
          }

          const enabledDestinations = getEnabledListeners(newAccountDestination);
          const destinationRoutes = getDestinationRoutes(enabledDestinations, activeAccount.secondId);
          const newAccountRoutes = [...accountRoutes].filter(route => !destinationRoutes.some(destinationRoute => destinationRoute.path === route.path));
          const newRoutes = [...newAccountRoutes, ...destinationRoutes];

          dispatch(
               setSubscriberState({
                    routes: newRoutes,
                    accountDestinations: newAccountDestination,
                    activeDestinations: enabledDestinations,
               })
          );
     }

     const enableDestination = async (destinationId, accountDestinations) => {
          const data = {
               accountId: activeAccountId,
               destinationId,
               status: true
          };

          await callTokenApi(`${API_CLIENT_ACCOUNT_DESTINATIONS}update`, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         const { accountDestination } = response.data;

                         handleChangeActiveDestinations({ destinationId, accountDestinations, accountDestination, isNewDestination: true });
                         closeModal();
                    } else {
                         toastError(response);
                    }
               })
     };

     const createDestination = (values, { setSubmitting }) => {
          const body = {
               name: values.name,
               description: values.description,
               accountId: activeAccountId
          };

          callTokenApi(API_CLIENT_DESTINATIONS_CUSTOM, 'POST', body)
               .then(async response => {
                    if (response.status === 200) {
                         const { destination } = response.data;
                         let newAccountDestinations = [...reducerAccountDestinations];

                         newAccountDestinations.push({
                              ...destination,
                              configStatus: true
                         })

                         await enableDestination(destination.id, newAccountDestinations);

                         history.replace(`${SUBSCRIBER_PATH.DESTINATIONS.replace(':secondId', activeAccount.secondId)}/${destination.alias}`);
                    } else {
                         setSubmitting(false);
                         toastError(response);
                    }
               })
     };

     const editDestination = (values, { setSubmitting }) => {
          let body = {
               accountId: activeAccountId,
               id: data.id
          };
          let nameChanged = false;

          if (data.name !== values.name) {
               body.name = values.name;
               nameChanged = true;
          } else {
               body.name = data.name;
          }

          body.description = values.description;

          callTokenApi(`${API_CLIENT_DESTINATIONS_CUSTOM}/${data.id}`, 'PUT', body)
               .then(response => {
                    if (response.status === 200) {
                         if (nameChanged) {
                              history.replace('/'); // Redirect to this to avoid the current page with old alias will case issue due to new routes doesn't include it
                         }

                         const { destination } = response.data;
                         let newAccountDestinations = [...reducerAccountDestinations];
                         let foundAccountDestinationIndex = newAccountDestinations.findIndex(el => el.id === data.id);

                         newAccountDestinations[foundAccountDestinationIndex] = { ...newAccountDestinations[foundAccountDestinationIndex], ...destination };
                         handleChangeActiveDestinations({
                              destinationId: data.id,
                              accountDestinations: newAccountDestinations,
                              accountDestination: newAccountDestinations[foundAccountDestinationIndex]
                         });

                         closeModal();

                         if (nameChanged) {
                              history.replace(`${SUBSCRIBER_PATH.DESTINATIONS.replace(':secondId', activeAccount.secondId)}/${destination.alias}`);
                         }
                    } else {
                         setSubmitting(false);
                         toastError(response);
                    }
               })
     };

     const onSubmit = (values, actions) => {
          if (isUpdating) {
               editDestination(values, actions);
          } else {
               createDestination(values, actions);
          }
     };

     return (
          <CustomDestinationBody onBackBtnClicked={goBack}>
               <CCard className="name-destination">
                    <CCardBody>
                         <Formik
                              initialValues={initialValues}
                              validationSchema={() => validationSchema(setValidateOnChange)}
                              onSubmit={onSubmit}
                              validateOnChange={validateOnChange}
                              validateOnBlur={false}
                         >
                              {
                                   ({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
                                        <CForm noValidate onSubmit={handleSubmit}>
                                             <h3>Name Your Destination</h3>
                                             <p>
                                                  Provide a name and description for your destination so you can manage it easily inside ListenLayer.
                                             </p>
                                             <CFormGroup className="text-left">
                                                  <CFormGroup row className="align-items-center">
                                                       <CCol md="3" className="d-flex align-items-center">
                                                            <CLabel className="name">Name</CLabel>
                                                            <div className="tooltip-wrapper">
                                                                 <HoverInfoIconTooltip>
                                                                      <p>We recommend using the name of the destination or something you will easily recognize.</p>
                                                                 </HoverInfoIconTooltip>
                                                            </div>
                                                       </CCol>
                                                       <CCol md="9">
                                                            <CInput
                                                                 name="name"
                                                                 invalid={!!errors.name}
                                                                 value={values.name}
                                                                 onChange={handleChange}
                                                                 disabled={isSubmitting}
                                                            />
                                                            <CInvalidFeedback>{errors.name}</CInvalidFeedback>
                                                       </CCol>
                                                  </CFormGroup>
                                                  <CFormGroup row className="align-items-center">
                                                       <CLabel className="col-md-3">Description</CLabel>
                                                       <CCol md="9">
                                                            <CTextarea
                                                                 name="description"
                                                                 invalid={!!errors.description}
                                                                 value={values.description}
                                                                 onChange={handleChange}
                                                                 disabled={isSubmitting}
                                                            />
                                                            <CInvalidFeedback>{errors.description}</CInvalidFeedback>
                                                       </CCol>
                                                  </CFormGroup>
                                             </CFormGroup>
                                             <CButton type="submit" className="btn-save mb-3" disabled={isSubmitting}>
                                                  <Waiting isLoading={isSubmitting}>SAVE CHANGES</Waiting>
                                             </CButton>
                                             {!isUpdating && <div>You can edit all settings for your destination after it is created.</div>}
                                        </CForm>
                                   )
                              }
                         </Formik>
                    </CCardBody>
               </CCard>
          </CustomDestinationBody>
     );
};

export default NameDestination;
