import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CIcon } from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import { useOutsideHandling } from '../../../utils';

const ClickInfoIconTooltip = ({
     children,
     showIcon = true,
     text,
     button = false,
     btnText = '',
     btnColor = '',
     showTooltip = false,
     showSelect = false,
     toggleShow,
     placement,
     showPopupCreateCategories,
     showTooltipCancel = false,
}) => {
     const [detailMessage, setDetailMessage] = useState(false);
     const clickOutside = useCallback(() => {
          if (!showTooltip) setDetailMessage(false);
          if (showPopupCreateCategories) {
               setDetailMessage(false);
          }
     }, [showTooltip, showPopupCreateCategories]);

     const wrapperRef = useRef(null);

     useOutsideHandling(wrapperRef, clickOutside, showPopupCreateCategories);

     const handleClickBubble = () => {
          setTimeout(() => {
               if (!detailMessage) {
                    setDetailMessage(true);
               }
          }, 100);
     };

     useEffect(() => {
          if (typeof toggleShow !== 'undefined') {
               setDetailMessage((s) => !s);
          }
     }, [toggleShow]);

     useEffect(() => {
          if (showTooltipCancel) {
               setDetailMessage((s) => !s);
          }
     }, [showTooltipCancel]);

     return (
          <>
               {button ? (
                    <CButton color={btnColor} onClick={handleClickBubble}>
                         {btnText}
                    </CButton>
               ) : (
                    <>
                         {!showIcon ? (
                              text && <span onClick={handleClickBubble}>{text}</span>
                         ) : (
                              <CIcon name="icon-info" className="pointer" onClick={handleClickBubble}></CIcon>
                         )}
                    </>
               )}

               {detailMessage && (
                    <div className={`${placement === 'bottom' ? 'block-tooltip-bottom  type-click' : 'block-tooltip type-click'}`} ref={wrapperRef}>
                         <CIcon name="arrow-preview" className="arrow-preview"></CIcon>
                         <div className="block-tooltip-text">{children}</div>
                    </div>
               )}
          </>
     );
};

export default ClickInfoIconTooltip;
