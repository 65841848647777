import React, { useContext } from "react";
import CIcon from "@coreui/icons-react";
import {
    SupportTicketPopupContext,
    DECISION_STEPS,
    CREATE_SUPPORT_TICKET,
    HELP_DATA_LAYERS,
} from "../GetHelpLabel";
import { INTRO_TO_DATA_LAYERS, STEP_PREVIOUS_CREATE_TICKET } from "../../../../../constants";

import TicketVideoIframe from "../TicketVideoIframe";

const HelpDataLayers = () => {
    const { setCurrentStep } = useContext(SupportTicketPopupContext);

    const dataVideoIframe = {
        title: `We're Here to Help!`,
        description: `Watch this introduction to Data Layers to ensure that your foundational knowledge is solid.`,
        urlIframe: INTRO_TO_DATA_LAYERS
    }

    const buttonVideo = () => {
        setCurrentStep(CREATE_SUPPORT_TICKET.step1);
        localStorage.setItem(STEP_PREVIOUS_CREATE_TICKET, HELP_DATA_LAYERS.step1)
    }

    return (
        <div className="ticket-popup-content ticket-video-content">
            <div className="btn-back-ticket">
                <CIcon name="icon-chevron-left" width="6" />
                <span onClick={() => setCurrentStep(DECISION_STEPS.step3)}>
                    Back
                </span>
            </div>
            <TicketVideoIframe
                title={dataVideoIframe.title}
                description={dataVideoIframe.description}
                urlIframe={dataVideoIframe.urlIframe}
                buttonVideo={buttonVideo}
            />
        </div>
    );
};

export default HelpDataLayers;
