import React, { useContext, useState } from 'react';
import { CCard, CCardBody, CButton, CRow, CCol, CInput, CTextarea, CForm, CLabel, CInvalidFeedback, CFormGroup, CCardHeader } from '@coreui/react';
import PropTypes from 'prop-types';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { RuleContext } from '../CreateNewRule';
import { setRuleHaveEditting } from '../../../../../../actions/common';
import { TYPE_SHOW_UNSAVE_CHANGE } from '../../../../../../constants';
import SingleRow from './SingleRow';
import CIcon from '@coreui/icons-react';

const CreateStep1 = ({ stepsData, setStepsData }) => {
     const { activeStep, setActiveStep } = useContext(RuleContext);
     const [validateOnChange, setValidateOnChange] = useState(false);
     const rules = useSelector((state) => state.subscriber.consentRules);
     const initialItem = { regionType: '', continent: '', country: '', stateProvinces: '' };
     const initialValues = {
          name: stepsData.name,
          description: stepsData.description,
          geographicRegions: stepsData.geographicRegions,
     };
     const dispatch = useDispatch();

     const validationSchema = (validateOnChange, setValidateOnChange) => {
          // useCallback(() => {
          let ruleNames = rules.map((rule) => rule.name);

          // stepsData.name has value when editting rule
          if (stepsData.name) {
               // Find stepsData.name in ruleNames and remove it from ruleNames
               let ruleNameIndex = -1;

               ruleNames.some((ruleName, index) => {
                    if (stepsData.name === ruleName) {
                         ruleNameIndex = index;
                         return true;
                    }

                    return false;
               });

               if (ruleNameIndex !== -1) {
                    ruleNames.splice(ruleNameIndex, 1);
               }
          }

          // return Yup.object().shape({
          //      name: Yup.string()
          //           .trim()
          //           .required('Name is required')
          //           .max(200, 'Name cannot be more than 200 characters')
          //           .notOneOf(ruleNames, 'Existed name, please choose another name'),
          // });
          // }, [rules, stepsData.name]);

          if (!validateOnChange) {
               setValidateOnChange(true);
          }

          return Yup.object().shape({
               name: Yup.string()
                    .trim()
                    .required('Name is required')
                    .max(200, 'Name cannot be more than 200 characters')
                    .notOneOf(ruleNames, 'Existed name, please choose another name'),
               geographicRegions: Yup.array()
                    .of(
                         Yup.object().shape({
                              regionType: Yup.string().required('Required'),
                              continent: Yup.string().when('regionType', {
                                   is: 'continent',
                                   then: Yup.string().required('Please select an option'),
                              }),
                              country: Yup.string().when('regionType', {
                                   is: 'country',
                                   then: Yup.string().required('Please select an option'),
                              }),
                              countryOfState: Yup.string().when('regionType', {
                                   is: 'stateProvinces',
                                   then: Yup.string().required('Please select an option'),
                              }),
                              stateProvinces: Yup.string().when('regionType', {
                                   is: 'stateProvinces',
                                   then: Yup.string().required('Please select an option'),
                              }),
                         })
                    )
                    .required('Field is required!'),
          });
     };

     const onSubmit = ({ name, description, geographicRegions }, { setErrors, setSubmitting }) => {
          let isDuplicate = false;
          let errors = [];

          for (let i = 0; i < geographicRegions.length - 1; i++) {
               errors.push(undefined);
          }

          for (let i = 0; i < geographicRegions.length - 1; i++) {
               for (let j = i + 1; j < geographicRegions.length; j++) {
                    if (JSON.stringify(geographicRegions[i]) === JSON.stringify(geographicRegions[j])) {
                         isDuplicate = true;
                         const _error = { regionType: 'Duplicate' };

                         if (geographicRegions[i].continent) {
                              _error.continent = 'Duplicate';
                         }
                         if (geographicRegions[i].country) {
                              _error.country = 'Duplicate';
                         }
                         if (geographicRegions[i].stateProvinces) {
                              _error.stateProvinces = 'Duplicate';
                         }

                         errors[i] = _error;
                         errors[j] = _error;
                         break;
                    }
               }
               if (!geographicRegions[i].regionType) {
                    isDuplicate = true;
                    const _error = { regionType: 'Required' };

                    errors[i] = _error;
                    break;
               }
          }

          setSubmitting(false);
          if (isDuplicate) {
               setErrors({ geographicRegions: errors });
          } else {
               const data = {
                    name: name.trim(),
                    description: description.trim(),
                    geographicRegions: geographicRegions,
               };

               const newStepsData = { ...stepsData, ...data };
               setStepsData(newStepsData);
               setActiveStep(2);
          }
     };

     const handleHasChange = () => {
          dispatch(setRuleHaveEditting({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.CREATE_SIMPLE }));
     };

     return (
          <CCard className={`cvr-step-2 ${activeStep !== 1 ? 'difference-step' : 'cvr-step-card'}`}>
               <CCardHeader>
                    {activeStep !== 1 ? (
                         <div className='rule-step d-inline-flex justify-content-between w-100'>
                              <h5 className='mb-0 inactive'>Step 1: Rule Name & Description</h5>
                              {stepsData.name && (
                                   <div className='d-inline-flex align-items-center'>
                                        <span className='given-data mr-3'>{stepsData.name}</span>
                                        <CButton className='btn-edit' onClick={() => setActiveStep(1)}>
                                             Edit
                                        </CButton>
                                   </div>
                              )}
                         </div>
                    ) : (
                         <h5>Step 1: Rule Name & Description</h5>
                    )}
               </CCardHeader>
               {activeStep === 1 && (
                    <CCardBody>
                         <Formik
                              initialValues={initialValues}
                              // validationSchema={validationSchema, validateOnChange, setValidateOnChange}
                              validationSchema={() => validationSchema(validateOnChange, setValidateOnChange)}
                              onSubmit={onSubmit}
                              validateOnChange={validateOnChange}
                              validateOnBlur={false}
                              validateOnMount
                         >
                              {({ values, errors, handleChange, handleSubmit, setErrors, setFieldValue, isSubmitting }) => (
                                   <CForm onSubmit={handleSubmit} noValidate>
                                        <CRow>
                                             <CCol md='7'>
                                                  <CFormGroup>
                                                       <CLabel htmlFor='name'>Name this consent rule</CLabel>
                                                       <CInput
                                                            id='name'
                                                            placeholder='Rule set name'
                                                            invalid={!!errors.name}
                                                            disabled={isSubmitting}
                                                            value={values.name}
                                                            onChange={(e) => {
                                                                 handleChange(e);
                                                                 if (errors) {
                                                                      setErrors({});
                                                                 }
                                                                 handleHasChange();
                                                            }}
                                                            data-lpignore='true'
                                                       />
                                                       <CInvalidFeedback>{errors.name}</CInvalidFeedback>
                                                  </CFormGroup>
                                             </CCol>
                                             <CCol md='12'>
                                                  <CFormGroup>
                                                       <CLabel htmlFor='description'>Write a description so you remember what this rule does.</CLabel>
                                                       <CTextarea
                                                            id='description'
                                                            placeholder='Enter a description for your own reference...'
                                                            disabled={isSubmitting}
                                                            value={values.description}
                                                            onChange={(e) => {
                                                                 handleChange(e);
                                                                 if (errors) {
                                                                      setErrors({});
                                                                 }
                                                                 handleHasChange();
                                                            }}
                                                       />
                                                  </CFormGroup>
                                             </CCol>
                                             <CCol md='12'>
                                                  <label className='label-row'>Apply this rule to users arriving from the following regions</label>
                                                  <FieldArray name='geographicRegions'>
                                                       {({ remove, push }) =>
                                                            values.geographicRegions.map((row, index) => {
                                                                 return (
                                                                      <CRow key={index} className='row-region'>
                                                                           <SingleRow
                                                                                props={{
                                                                                     row,
                                                                                     index,
                                                                                     setValidateOnChange,
                                                                                     handleHasChange,
                                                                                }}
                                                                           />
                                                                           <div className='actions'>
                                                                                {values.geographicRegions.length > 1 && (
                                                                                     <CButton
                                                                                          className='mr-2'
                                                                                          onClick={() => {
                                                                                               remove(index);
                                                                                          }}
                                                                                     >
                                                                                          <CIcon name='iconDeleteField' className='icon-delete' />
                                                                                     </CButton>
                                                                                )}
                                                                           </div>
                                                                      </CRow>
                                                                 );
                                                            })
                                                       }
                                                  </FieldArray>
                                                  <CRow>
                                                       <CCol>
                                                            <CButton
                                                                 className='btn-add-row mb-3'
                                                                 onClick={() => {
                                                                      const newGeoGraphicRegions = [...values.geographicRegions];
                                                                      newGeoGraphicRegions.push(initialItem);
                                                                      setFieldValue('geographicRegions', newGeoGraphicRegions);
                                                                      setValidateOnChange(false);
                                                                      handleHasChange();
                                                                      setTimeout(() => {
                                                                           setErrors({});
                                                                      });
                                                                 }}
                                                            >
                                                                 <CIcon name='iconAddField' className='icon-add' />
                                                                 <CLabel className='add-row'>ADD ROW</CLabel>
                                                            </CButton>
                                                       </CCol>
                                                  </CRow>
                                             </CCol>
                                        </CRow>

                                        <CButton type='submit' className='px-4' color='primary'>
                                             NEXT STEP
                                        </CButton>
                                   </CForm>
                              )}
                         </Formik>
                    </CCardBody>
               )}
          </CCard>
     );
};

CreateStep1.propTypes = {
     stepsData: PropTypes.object,
     setStepsData: PropTypes.func,
};

export default CreateStep1;
