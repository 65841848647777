import React, { useState, useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    CSwitch,
    CButton,
    CCard,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { toast } from 'react-toastify';

import { AsideContext, ASIDE_COMPONENTS } from './TheAside';
import { PaymentInfo } from '../../../components/general/payment';
import { API_CLIENT_ACCOUNT_UPGRADE, API_CLIENT_ACCOUNT_DOWNGRADE, API_CLIENT_SAVE_CARD, TRIAL_DAYS } from '../../../constants';
// import { setAsideShow } from '../../../actions/common';
import {
    getStripeCardImage,
    findAccountIndex,
    // setPackageToAccount 
} from '../../../helpers/cms/subscriber';
import { handleCompleteOwnershipTransfer, resetAllPreview, setSubscriberState } from '../../../actions/subscriber';
import { callTokenApi } from '../../../apiCaller';
import { toastError, roundTo2DecimalPlaces, dateDiffIndays } from '../../../utils';
import classNames from 'classnames';

const SlideOutPayment = () => {
    const { packageSelected, resetPackageSelected, setCurrentComponents } = useContext(AsideContext);
    const dispatch = useDispatch();
    const accounts = useSelector(state => state.subscriber.accounts);
    const activeAccount = useSelector(state => state.subscriber.activeAccount);
    const nextPackage = useSelector(state => state.subscriber.packages[state.subscriber.nextPackageIndex]); // This package is more than 1 level of current account's package
    const [annualBilling, setAnnualBilling] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const packageToUse = packageSelected || nextPackage;

    const {
        id: packageToUpgradeId,
        friendlyName,
        pricePerMonth,
        pricePerYear,
        amountSavedOnAnnual,
        percentSavedOnAnnual
    } = packageToUse;

    const {
        id: activeAccountId,
        balance,
        stripeCardType,
        stripeCardLast4,
        packagePricePerMonth: currentPackagePricePerMonth,
        created_at: dateCreated,
        trialEndTime,
        confirmPayment,
    } = activeAccount;
    const firstUpgrade = (dateDiffIndays(dateCreated, trialEndTime) === 0 && currentPackagePricePerMonth === 0) ? true : false;

    const isSwitchPackage = currentPackagePricePerMonth === pricePerMonth;
    const isSwitchFreePackage = isSwitchPackage && pricePerMonth === 0;

    // Did this account already add a payment method
    const accountHasPayment = (stripeCardType && stripeCardLast4);

    const isUpgrade = currentPackagePricePerMonth <= pricePerMonth;
    const doNeedPayment = !accountHasPayment && !isSwitchFreePackage;
    const [showPaymentForm, setShowPaymentForm] = useState(doNeedPayment);

    const toggleShowPaymentForm = useCallback(() => {
        setShowPaymentForm(!showPaymentForm)
    }, [showPaymentForm])

    const toggleAnnualBilling = useCallback(() => {
        setAnnualBilling(!annualBilling);
    }, [annualBilling])

    // const onDownOrUpgradeSuccess = (response) => {
    //     const { account } = response.data;
    //     const newAccount = setPackageToAccount(account, packageToUse, isOwner);
    //     const activeAccountIndex = findAccountIndex(accounts, activeAccountId);
    //     let newAccounts = [...accounts];
    //     newAccounts[activeAccountIndex] = { ...newAccount };

    //     dispatch(setSubscriberState({
    //         accounts: newAccounts,
    //         activeAccount: newAccount
    //     }));
    //     setCurrentComponents(ASIDE_COMPONENTS.LIMITATION_HIT);
    //     dispatch(setAsideShow(false));
    // }

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        const cardNumber = values.stripeCardNumber.replace(/_/gi, '').replace(/ /gi, '');
        const stripeCardExpiration = values.stripeCardExpiration;
        const expiration = stripeCardExpiration.split('/');
        const expMonth = expiration[0];
        const expYear = expiration[1];

        const data = {
            accountId: activeAccountId,
            name: values.stripeCardName,
            cardNumber,
            expMonth,
            expYear,
            cvc: values.stripeCardCVC
        }

        callTokenApi(`${API_CLIENT_SAVE_CARD}`, 'POST', data)
            .then(response => {
                if (response.status === 200) {
                    resetForm();

                    const { account } = response.data;
                    const activeAccountIndex = findAccountIndex(accounts, activeAccountId);
                    let newAccounts = [...accounts];
                    newAccounts[activeAccountIndex] = { ...newAccounts[activeAccountIndex], ...account };

                    dispatch(setSubscriberState({
                        accounts: newAccounts,
                        activeAccount: newAccounts[activeAccountIndex]
                    }));
                    toggleShowPaymentForm();

                    let finallyNext = () => toast.success("Payment has been updated successfully");
                    if (confirmPayment) {
                        dispatch(handleCompleteOwnershipTransfer({ accountId: activeAccountId, userId: activeAccount.userId }, finallyNext));
                    } else {
                        finallyNext();
                    }
                } else {
                    setSubmitting(false);
                    toastError(response);
                }
            })
    }

    const handleUpgrade = () => {
        setIsLoading(true);

        const data = {
            accountId: activeAccountId,
            packageId: packageToUpgradeId,
            annualBilling
        }

        callTokenApi(`${API_CLIENT_ACCOUNT_UPGRADE}`, 'POST', data)
            .then(response => {
                if (response.status === 200) {
                    // onDownOrUpgradeSuccess(response);
                    // toast.success('Your account has been successfully upgraded!');
                    window.location.reload();
                } else {
                    toastError(response);
                }
            })
            .finally(() => {
                setIsLoading(false);
                dispatch(resetAllPreview(activeAccountId));
            })
    }

    const handleDowngrade = () => {
        setIsLoading(true);

        const data = {
            accountId: activeAccountId,
            packageId: packageToUpgradeId,
            annualBilling
        }

        callTokenApi(API_CLIENT_ACCOUNT_DOWNGRADE, 'POST', data)
            .then(response => {
                if (response.status === 200) {
                    // onDownOrUpgradeSuccess(response);
                    // toast.success('Your account has been downgraded!');
                    window.location.reload();
                } else {
                    toastError(response);
                }
            })
            .finally(() => {
                setIsLoading(false);
                dispatch(resetAllPreview(activeAccountId));
            })
    }

    const goBack = () => {
        resetPackageSelected();
        setCurrentComponents(ASIDE_COMPONENTS.LIMITATION_HIT);
    }

    return (
        <>
            <CButton className="btn-back" onClick={goBack}>
                {/* <i className="fal fa-angle-left"></i> */}
                {/* <CIcon name="icon-back" className="" /> */}
                <img src="/assets/icons/backicon.svg" alt="play-icon" className="i"></img>
                Back
            </CButton>
            <div className="choose-how-to-pay aside-version">
                <div className="sidebar-popup" >
                    <div className="sidebar-header">
                        <div className="text-center">
                            <CIcon name="icon-upgrade-blue" className="logo-how-to-pay" />
                        </div>
                        {/* <h3 className="text-center mb-0">Switch to a {friendlyName} Plan</h3> */}
                        <div className="mb-5">
                            <h3 className="text-center mb-0 title">Switch to {friendlyName} Plan</h3>
                            {/* {activeAccount.packagePricePerMonth === 0 && <p className="text-center mb-0">Upgrade today and get 15 days free</p>} */}
                        </div>
                        {/* {currentPackagePricePerMonth === 0 && (
                            <p className="text-center subtitle">
                                Upgrade today and receive {TRIAL_DAYS} days free.
                            </p>
                        )} */}
                        <div className="annual-billing">
                            <CSwitch
                                className='mb-0'
                                color={annualBilling ? 'success' : 'light'}
                                checked={annualBilling}
                                size="lg"
                                tabIndex="0"
                                shape="pill"
                                onChange={toggleAnnualBilling}
                                disabled={isSwitchFreePackage}
                            />
                            <span className="annual-billing-text">Annual Billing</span>
                            <span className={classNames('cost-per-year', { 'annually': annualBilling })}>
                                ${annualBilling ? `${roundTo2DecimalPlaces(pricePerYear / 12).toLocaleString('en-US')}` : `${pricePerMonth.toLocaleString('en-US')}`}
                            </span>
                        </div>
                        {
                            annualBilling ? (
                                <div className="description-price">
                                    <span className="annual-description">
                                        Save {percentSavedOnAnnual}% (${amountSavedOnAnnual.toLocaleString('en-US')}) with annual billing.
                                    </span>
                                    <span className="float-right">
                                        ${pricePerYear.toLocaleString('en-US')}/yr
                                    </span>
                                </div>
                            ) : (
                                <span className="annual-description">
                                    Save {percentSavedOnAnnual}% (${amountSavedOnAnnual.toLocaleString('en-US')}) with annual billing.
                                </span>
                            )
                        }
                        {
                            (showPaymentForm) ? (
                                <>
                                    {accountHasPayment && (
                                        <React.Fragment>
                                            <CButton
                                                className="use-current-card"
                                                onClick={toggleShowPaymentForm}>
                                                <i className="fal fa-angle-left"></i>
                                                Use card on file
                                            </CButton>
                                            <h4 className="use-current-card__title">Change or update card details</h4>
                                        </React.Fragment>
                                    )}
                                    <PaymentInfo onSubmit={onSubmit} submitBtnText="Upgrade Now" isUpgrade={isUpgrade} annualBilling={annualBilling} pricePerYear={pricePerYear} pricePerMonth={pricePerMonth} firstUpgrade={firstUpgrade} balance={balance} />
                                </>
                            ) : (
                                <>
                                    {
                                        !isSwitchFreePackage && (
                                            <CCard className="current-payment-method-wrapper">
                                                <p className="payment-method-title">Payment Method</p>
                                                <div className="justify-content-between current-payment-card">
                                                    <div className="payment-hidden">
                                                        {getStripeCardImage(stripeCardType)}
                                                        <div className="hidden-payment-number">
                                                            <div className="hidden-circle"></div>
                                                            <div className="hidden-circle"></div>
                                                            <div className="hidden-circle"></div>
                                                            <div className="hidden-circle"></div>
                                                        </div>
                                                        <div className="hidden-payment-number">
                                                            <div className="hidden-circle"></div>
                                                            <div className="hidden-circle"></div>
                                                            <div className="hidden-circle"></div>
                                                            <div className="hidden-circle"></div>
                                                        </div>
                                                        <div className="hidden-payment-number">
                                                            <div className="hidden-circle"></div>
                                                            <div className="hidden-circle"></div>
                                                            <div className="hidden-circle"></div>
                                                            <div className="hidden-circle"></div>
                                                        </div>
                                                        <div className="payment-number">{stripeCardLast4}</div>
                                                    </div>
                                                    <CButton
                                                        className="btn-change"
                                                        onClick={toggleShowPaymentForm}
                                                    >
                                                        Change
                                                    </CButton>
                                                </div>
                                            </CCard>
                                        )
                                    }
                                    {
                                        isUpgrade ? (
                                            activeAccount.packagePricePerMonth > 0 ? (
                                                <p className="payment-description">
                                                    If you are in a current billing cycle, we will reduce the amount charged today by the pro-rated, unused time on your current package.
                                                </p>
                                            ) : (
                                                <p className="payment-description">
                                                    Your subscription is paid {annualBilling ? 'annually' : 'monthly'}. We'll validate your credit card now and{" "}
                                                    will charge your card ${annualBilling ? pricePerYear.toLocaleString('en-US') : pricePerMonth.toLocaleString('en-US')} {firstUpgrade ? `after your ${TRIAL_DAYS} free days` : `today`}. If you are in a current billing cycle, we will reduce the amount charged today by the pro-rated, unused time on your current package.
                                                </p>
                                            )
                                        ) : (
                                            <p className="payment-description p-0">
                                                We will refund you credit card ${balance} for the time you have paid for and not used on your current plan.
                                                {(pricePerMonth !== 0) && ` We will also then bill your credit card for $${annualBilling ? pricePerYear.toLocaleString('en-US') : pricePerMonth.toLocaleString('en-US')} for the new plan that you have chosen.`}
                                            </p>
                                        )
                                    }

                                    <CButton
                                        color="success"
                                        className="btn-save"
                                        disabled={doNeedPayment || isLoading}
                                        onClick={isUpgrade ? handleUpgrade : handleDowngrade}
                                    >
                                        {
                                            isLoading ? (
                                                <span className="dots-waiting">Waiting</span>
                                            )
                                                : (
                                                    isUpgrade ? (isSwitchPackage ? "Switch Now" : "Upgrade Now") : "Downgrade Now"
                                                )
                                        }
                                    </CButton>
                                </>
                            )
                        }

                        {/* {
                        isUpgrade ? (

                            // <p className="payment-description">
                            //     Your subscription is paid {annualBilling ? 'annually' : 'monthly'}. We'll validate your credit card now and{" "}
                            //     will charge your card ${annualBilling ? pricePerYear.toLocaleString('en-US') : pricePerMonth.toLocaleString('en-US')} {firstUpgrade ? `after your ${TRIAL_DAYS} free days` : `today`}. If you are in a current billing cycle, we will reduce the amount charged today by the pro-rated, unused time on your current package.
                            //     { {currentPackagePricePerMonth === 0 ? `in ${TRIAL_DAYS} days after your free period ends.` : "today."} }
                            // </p>

                            <p className="payment-description p-0">
                                Your subscription is paid {annualBilling ? 'annually' : 'monthly'}. We'll validate your credit card now and{" "}
                                will charge your card ${annualBilling ? pricePerYear.toLocaleString('en-US') : pricePerMonth.toLocaleString('en-US')} {firstUpgrade ? `after your ${TRIAL_DAYS} free days` : `today`}.
                            </p>
                        ) : (
                            <p className="payment-description p-0">
                                We will refund you credit card ${balance} for the time you have paid for and not used on your current plan.
                                {(pricePerMonth !== 0) && ` We will also then bill your credit card for $${annualBilling ? pricePerYear.toLocaleString('en-US') : pricePerMonth.toLocaleString('en-US')} for the new plan that you have chosen.`}
                            </p>
                        )
                    } */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SlideOutPayment
