import React from 'react';
import { formatNumber, formatNumberWithCommas } from '../../../../../utils/UtilReport';
import { REPORT_NAMES } from '../../../../../constants';
import CIcon from '@coreui/icons-react';
import { convertReportDuration, filterObjectValuesNotNull } from '../../../../../utils';
import Tippy from '@tippyjs/react';
import { Skeleton } from '@progress/kendo-react-indicators';

export const Tag = ({ item, onClick, tagUserSourceDetail }) => {
     return (
          <span
               className={`tag d-flex flex-wrap align-items-center ${typeof onClick !== 'function' && !tagUserSourceDetail ? 'no-click' : ''}`}
               onClick={onClick}
          >
               {item}
          </span>
     );
};

export const SessionCount = (data, { style, className, id }, handleEnterToLink, handleLinkToOtherReport) => {
     const { sessionCount } = data;
     if (sessionCount > 0)
          return (
               <td
                    style={style}
                    className={`clickable ${className}`}
                    tabIndex={0}
                    data-keyboardnavid={id}
                    onKeyPress={(e) => handleEnterToLink(e)}
                    onClick={() => {
                         return handleLinkToOtherReport('sessionCount', { ...data });
                    }}
               >
                    {`${formatNumber(sessionCount)}`}
               </td>
          );
     else
          return (
               <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                    {`${formatNumber(sessionCount)}`}{' '}
               </td>
          );
};

export const UserCount = (data, { style, className, id }, handleEnterToLink, handleLinkToOtherReport) => {
     const { userCount } = data;
     if (userCount > 0) {
          return (
               <td
                    style={style}
                    className={`clickable ${className}`}
                    tabIndex={0}
                    data-keyboardnavid={id}
                    onKeyPress={(e) => handleEnterToLink(e)}
                    onClick={() => {
                         return handleLinkToOtherReport('userCount', { ...data });
                    }}
               >
                    {`${formatNumber(userCount)}`}
               </td>
          );
     } else {
          return (
               <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                    {`${formatNumber(userCount)}`}{' '}
               </td>
          );
     }
};

export const RevealedCompany = (data, { style, className, id }, handleEnterToLink, handleLinkToOtherReport) => {
     const { revealedCompany } = data;
     if (revealedCompany > 0) {
          return (
               <td
                    style={style}
                    className={`clickable ${className}`}
                    tabIndex={0}
                    data-keyboardnavid={id}
                    onKeyPress={(e) => handleEnterToLink(e)}
                    onClick={() => {
                         return handleLinkToOtherReport('revealedCompany', { ...data });
                    }}
               >
                    {`${formatNumber(revealedCompany)}`}
               </td>
          );
     } else {
          return (
               <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                    {`${formatNumber(revealedCompany)}`}{' '}
               </td>
          );
     }
};

export const PeopleCount = (data, { style, className, id }, handleEnterToLink, handleLinkToOtherReport) => {
     const { peopleCount } = data;
     if (peopleCount > 0) {
          return (
               <td
                    style={style}
                    className={`clickable ${className}`}
                    tabIndex={0}
                    data-keyboardnavid={id}
                    onKeyPress={(e) => handleEnterToLink(e)}
                    onClick={() => {
                         return handleLinkToOtherReport('peopleCount', { ...data });
                    }}
               >
                    {`${formatNumber(peopleCount)}`}
               </td>
          );
     } else {
          return (
               <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                    {`${formatNumber(peopleCount)}`}{' '}
               </td>
          );
     }
};

export const EventCount = (data, { style, className, id }, reportName) => {
     const { eventCount } = data;
     if (
          reportName === REPORT_NAMES.EVENT_DETAILS ||
          reportName === REPORT_NAMES.PAGE_VIEW_DETAILS ||
          reportName === REPORT_NAMES.CONVERSION_EVENTS ||
          reportName === REPORT_NAMES.FORM_SUBMISSION_EVENTS ||
          reportName === REPORT_NAMES.CONVERSION_EVENTS
     ) {
          return (
               <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                    {' '}
                    {formatNumber(eventCount)}{' '}
               </td>
          );
     }
     // if (eventCount > 0) {
     //      return (
     //           <td
     //                style={style}
     //                className={`${className}`}
     //                tabIndex={0}
     //                data-keyboardnavid={id}
     //                onKeyPress={(e) => handleEnterToLink(e)}
     //                onClick={() => {
     //                     return handleLinkToOtherReport('eventCount', { ...data });
     //                }}
     //           >
     //                {formatNumber(eventCount)}
     //           </td>
     //      );
     // }
     return (
          <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
               {' '}
               {formatNumber(eventCount)}{' '}
          </td>
     );
};

export const PersonCreated = ({ personCreatedCount, personId }, { style, className, id }, handleEnterToLink, handleLinkToOtherReport) =>
     personCreatedCount === 1 ? (
          <td
               style={style}
               className={`clickable ${className}`}
               tabIndex={0}
               data-keyboardnavid={id}
               onKeyPress={(e) => handleEnterToLink(e)}
               onClick={() => handleLinkToOtherReport('personCreatedCount', { id: personId })}
          >
               {formatNumber(personCreatedCount)}
          </td>
     ) : (
          <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
               {formatNumber(personCreatedCount)}
          </td>
     );

export const ActiveUser = ({ activeUser, landingPage, exitPage }, { style, className, id }, handleEnterToLink, handleLinkToOtherReport) =>
     activeUser > 0 ? (
          <td
               style={style}
               className={`clickable ${className}`}
               tabIndex={0}
               data-keyboardnavid={id}
               onKeyPress={(e) => handleEnterToLink(e)}
               onClick={() => handleLinkToOtherReport('activeUser', { landingPage, exitPage })}
          >
               {activeUser}
          </td>
     ) : (
          <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
               {activeUser}
          </td>
     );

export const ActiveUserCount = (
     { activeUserCount, userSourceFirst, userSourceLast, userSource, conversionName },
     { style, className, id },
     handleEnterToLink,
     handleLinkToOtherReport,
     reportName
) =>
     activeUserCount > 0 ? (
          <td
               style={style}
               className={`clickable ${className}`}
               tabIndex={0}
               data-keyboardnavid={id}
               onKeyPress={(e) => handleEnterToLink(e)}
               onClick={() => {
                    if (reportName === REPORT_NAMES.CONVERSION_NAME_DETAILS) {
                         return handleLinkToOtherReport('activeUserCount', { conversionName });
                    } else {
                         return handleLinkToOtherReport('activeUserCount', {
                              userSourceFirst,
                              userSourceLast,
                              userSource,
                         });
                    }
               }}
          >
               {formatNumber(activeUserCount)}
          </td>
     ) : (
          <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
               {formatNumber(activeUserCount)}{' '}
          </td>
     );

export const ViewDetail = (
     data,
     { style, className, id },
     filterDimensions,
     onClickShowDetailEvent,
     onClickShowDetailEventECommerce,
     onClickShowDetailUser,
     onClickShowDetailSession,
     onClickShowDetailPerson,
     onClickShowDetailForm,
     onClickShowDetailCompany
) => {
     switch (filterDimensions[0].value) {
          case 'eventId':
               return (
                    <td
                         style={style}
                         className={`${className} view-detail`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onClick={() => onClickShowDetailEvent({ ...data })}
                    >
                         View
                    </td>
               );
          case 'eventECommerceId':
               return (
                    <td
                         style={style}
                         className={`${className} view-detail`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onClick={() => onClickShowDetailEventECommerce({ ...data })}
                    >
                         View
                    </td>
               );
          case 'userId':
               return (
                    <td
                         style={style}
                         className={`${className} view-detail`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onClick={() => onClickShowDetailUser({ ...data })}
                    >
                         View
                    </td>
               );
          case 'sessionId':
               return (
                    <td
                         style={style}
                         className={`${className} view-detail`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onClick={() => onClickShowDetailSession({ ...data })}
                    >
                         View
                    </td>
               );
          case 'personId':
               return (
                    <td
                         style={style}
                         className={`${className} view-detail`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onClick={() => onClickShowDetailPerson({ ...data })}
                    >
                         View
                    </td>
               );
          case 'formId':
          case 'formHostNamePathName':
               return (
                    <td
                         style={style}
                         className={`${className} view-detail`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onClick={() => onClickShowDetailForm({ ...data })}
                    >
                         View
                    </td>
               );
          case 'companyId':
               return (
                    <td
                         style={style}
                         className={`${className} view-detail`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onClick={() => onClickShowDetailCompany({ ...data })}
                    >
                         View
                    </td>
               );
          default:
               return <td>No Data</td>;
     }
};

export const ViewProfileById = (
     data,
     { style, className, id },
     filterDimensions,
     onClickShowDetailUser,
     onClickShowDetailSession,
     onClickShowDetailPerson,
     onClickShowDetailForm,
     onClickShowDetailCompany,
     property
) => {
     const { userId, sessionId, personId, companyId, formId } = data;
     if (data[property].toLowerCase() === 'none' || !data[property])
          return (
               <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                    {''}
                    {`${formatNumber(data[property] || 'none')}`}
               </td>
          );
     switch (property) {
          case filterDimensions[0].value:
               return (
                    <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                         {' '}
                         {`${formatNumber(data[property])}`}
                    </td>
               );
          case 'userId':
               if (filterDimensions[0].value === 'personId') {
                    const userList = userId.split(',');
                    return (
                         <td {...{ style, className }} className={`view-detail list-tags ${className}`} tabIndex={0} data-keyboardnavid={id}>
                              <div className="tags-wrapper">
                                   {userList.map((item) => (
                                        <Tag item={item} onClick={() => onClickShowDetailUser({ userId: item })} />
                                   ))}
                              </div>
                         </td>
                    );
               }
               return (
                    <td style={style} className={`${className} view-detail list-tags`} tabIndex={0} data-keyboardnavid={id}>
                         <div className="tags-wrapper">
                              <Tag item={userId} onClick={() => onClickShowDetailUser({ ...data })} />
                         </div>
                    </td>
               );
          case 'sessionId':
               return (
                    <td style={style} className={`${className} view-detail list-tags`} tabIndex={0} data-keyboardnavid={id}>
                         <div className="tags-wrapper">
                              <Tag item={sessionId} onClick={() => onClickShowDetailSession({ ...data })} />
                         </div>
                    </td>
               );
          case 'personId':
               return (
                    <td style={style} className={`${className} view-detail list-tags`} tabIndex={0} data-keyboardnavid={id}>
                         <div className="tags-wrapper">
                              {personId === 'None' ? (
                                   <Tag item={personId} />
                              ) : (
                                   <Tag item={personId} onClick={() => onClickShowDetailPerson({ ...data })} />
                              )}
                         </div>
                    </td>
               );
          case 'formId':
          case 'formHostNamePathName':
               return (
                    <td style={style} className={`${className} view-detail list-tags`} tabIndex={0} data-keyboardnavid={id}>
                         <div className="tags-wrapper">
                              <Tag item={formId} onClick={() => onClickShowDetailForm({ ...data })} />
                         </div>
                    </td>
               );
          case 'companyId':
               return (
                    <td style={style} className={`${className} view-detail list-tags`} tabIndex={0} data-keyboardnavid={id}>
                         <div className="tags-wrapper">
                              <Tag item={companyId} onClick={() => onClickShowDetailCompany({ ...data })} />
                         </div>
                    </td>
               );
          default:
               return <td>No Data</td>;
     }
};

//----------------------------------------------------------------
function transformObject(obj) {
     const result = {};

     for (const key in obj) {
          if (key !== 'name' && key !== 'createdAt') {
               const value = obj[key];

               if (Array.isArray(value)) {
                    value.forEach((nestedObj) => {
                         const nestedKey = Object.keys(nestedObj)[0];
                         const nestedValue = nestedObj[nestedKey];
                         result[nestedKey] = nestedValue;
                    });
               } else {
                    result[key] = value;
               }
          }
     }

     return result;
}

const changeNameKeyDetailUserSource = {
     referrer: 'Referrer:',
     landingPage: 'Landing Page:',
     channel: 'Channel:',
};

const renderTooltipUserSource = (item, key) => {
     return (
          <div className="tags-wrapper" key={key}>
               <Tippy
                    content={
                         <div className="tooltip-content">
                              <table>
                                   <tbody>
                                        {Object.keys(item).map(
                                             (key) =>
                                                  key !== 'name' &&
                                                  key !== 'createdAt' &&
                                                  (key !== 'sourceUTMs' && key !== 'clickIds' && key !== 'timestamp' ? (
                                                       item[key] !== null && item[key] !== undefined ? (
                                                            <tr key={`${key}`}>
                                                                 <td className="title">
                                                                      {changeNameKeyDetailUserSource[key]
                                                                           ? changeNameKeyDetailUserSource[key]
                                                                           : key + ':'}
                                                                      &nbsp;
                                                                 </td>
                                                                 <td className="content">
                                                                      {`${item[key]}`}
                                                                      {key === 'landingPage' || key === 'referrer' ? (
                                                                           <a
                                                                                href={item[key]}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                style={{ paddingLeft: '10px' }}
                                                                           >
                                                                                <CIcon name="icon-open-website-white" size="sm" />
                                                                           </a>
                                                                      ) : (
                                                                           ''
                                                                      )}
                                                                 </td>
                                                            </tr>
                                                       ) : null
                                                  ) : key === 'sourceUTMs' || key === 'clickIds' ? (
                                                       item[key] !== null && item[key].length > 0 ? (
                                                            item[key].map((i, index) => {
                                                                 return (
                                                                      i['value'] !== null && (
                                                                           <tr key={`${key}${index}`}>
                                                                                {Object.keys(i).map((key, keyIndex) => {
                                                                                     return keyIndex ? (
                                                                                          <td
                                                                                               key={`${key}${index}`}
                                                                                               className="content"
                                                                                          >{`${i[key]}`}</td>
                                                                                     ) : (
                                                                                          <td key={`${key}${index}`} className="title">
                                                                                               {i[key] + ':'}&nbsp;
                                                                                          </td>
                                                                                     );
                                                                                })}
                                                                           </tr>
                                                                      )
                                                                 );
                                                            })
                                                       ) : null
                                                  ) : key === 'timestamp' ? (
                                                       item[key] !== null && item[key] !== undefined ? (
                                                            <tr key={`${key}`}>
                                                                 <td className="title">Timestamp:&nbsp;</td>
                                                                 <td className="content">{item[key].value ? item[key].value : item[key]}</td>
                                                            </tr>
                                                       ) : null
                                                  ) : item[key] !== null ? (
                                                       <tr key={`${key}`}>
                                                            {Object.keys(item[key]).map((i, index) => {
                                                                 return index ? (
                                                                      <td key={`${key}${index}`} className="content">{`${item[key][i]}`}</td>
                                                                 ) : (
                                                                      <td key={`${key}${index}`} className="title">
                                                                           {item[key][i] + ':'}&nbsp;
                                                                      </td>
                                                                 );
                                                            })}
                                                       </tr>
                                                  ) : null)
                                        )}
                                   </tbody>
                              </table>
                         </div>
                    }
                    placement="top"
                    maxWidth="850px"
                    theme="user-source"
                    hideOnClick="false"
                    delay={[200, null]}
                    interactive="true"
                    appendTo={() => document.body}
               >
                    <span>
                         <Tag item={item.name} tagUserSourceDetail={true} />
                    </span>
               </Tippy>
          </div>
     );
};

const renderTagEmptyDetail = (label) => {
     return (
          <div className="tags-wrapper">
               <span>
                    <Tag item={label} />
               </span>
          </div>
     );
};

export const UserSourceDetails = (data, { style, className, id }, path = {}, showTooltip) => {
     const { isPath, refCell } = path;
     if (data === null || data.length === 0 || typeof data === 'string') {
          if (isPath) {
               const newData = typeof data === 'string' ? data.replace(/,/g, ', ') : '';
               return (
                    <td
                         ref={refCell}
                         onClick={showTooltip ? () => showTooltip(refCell, true): ()=>{}}
                         {...{ className }}
                         style={{ ...style, cursor: 'pointer' }}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         title={' '}
                         data-title={newData}
                         className={`${className}`}
                    >
                         <span style={{ pointerEvents: 'none' }}>{!newData || newData.length === 0 ? 'none' : newData}</span>
                    </td>
               );
          } else {
               return (
                    <td style={style} className={`${className} view-detail list-tags`} tabIndex={0} data-keyboardnavid={id}>
                         <span>{!data || data.length === 0 ? 'none' : data}</span>
                    </td>
               );
          }
     }

     return (
          <td style={style} className={`${className} view-detail list-tags`} tabIndex={0} data-keyboardnavid={id}>
               {
                    <div style={{ display: 'flex', gap: '5px' }}>
                         {data && data.length
                              ? data.slice(0, 100).map((item, key) => {
                                     const newItem = filterObjectValuesNotNull(item);
                                     return Object.keys(newItem).length ? renderTooltipUserSource(newItem, key) : renderTagEmptyDetail(item.name);
                                })
                              : !Object.keys(transformObject(filterObjectValuesNotNull(data))).length
                              ? renderTagEmptyDetail(data.name)
                              : renderTooltipUserSource(data)}
                    </div>
               }
          </td>
     );
};
//----------------------------------------------------------------

export const TableCellValueUndefined = (isLoading) => {
     return isLoading ? (
          <td>
               <Skeleton />
          </td>
     ) : (
          <td>{'none'}</td>
     );
};

export const ReportDuration = (value, { style, className, id }) => {
     return (
          <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
               {convertReportDuration(value)}
          </td>
     );
};

export const ChannelPath = (value, { style, className, id }, refCell, showTooltip) => {
     const newData = value.replace(/,/g, ', ');
     return (
          <td
               ref={refCell}
               onClick={() => showTooltip(refCell, true)}
               {...{ className }}
               style={{ ...style, cursor: 'pointer' }}
               tabIndex={0}
               data-keyboardnavid={id}
               title={' '}
               data-title={newData}
               className={`${className}`}
          >
               <span style={{ pointerEvents: 'none' }}>{!newData || newData.length === 0 ? 'none' : newData}</span>
          </td>
     );
};

export const Industries = (value, props) => {
     const { style, className, id } = props;
     const industries = props.dataItem.industries;
     const newIndustries = industries ? industries.split(',') : [];

     if (industries.toLowerCase() === 'none') {
          return (
               <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                    <span>{value}</span>
               </td>
          );
     }
     return (
          <td {...{ style, className }} className={`view-detail list-tags ${className}`} tabIndex={0} data-keyboardnavid={id}>
               <div className="tags-wrapper">
                    {newIndustries.map((item, index) => (
                         <Tag key={index} item={item} />
                    ))}
               </div>
          </td>
     );
};

export const ConversionValue = (value, { style, className, id }) => {
     return (
          <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
               {' '}
               {`${value.toLowerCase() !== 'none' && value.length > 3 ? formatNumberWithCommas(value) : value}`}
          </td>
     );
};

export const CustomConversionValue = (value, { style, className, id }) => {
     return (
          <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
               {' '}
               {`${value.toLowerCase() !== 'none' && value.length > 3 ? formatNumberWithCommas(value) : value}`}
          </td>
     );
};

export const SecondaryConversionValueCount = (value, { style, className, id }) => {
     return (
          <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
               {' '}
               {`${formatNumberWithCommas(Number(value))}`}
          </td>
     );
};

export const TableCellDefault = (value, {style, className, id}, refCell, showTooltip) => {
     return typeof value === 'boolean' ? (
          <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
               <span>{String(value)}</span>
          </td>
     ) : value && typeof value !== 'number' && value !== 'no' && value !== 'none' && value.length > 60 ? (
          <td
               ref={refCell}
               onClick={() => showTooltip(refCell, false)}
               {...{ style, className }}
               tabIndex={0}
               data-keyboardnavid={id}
               title={' '}
               data-title={value}
          >
               <span style={{ pointerEvents: 'none' }}>{value}</span>
          </td>
     ) : (
          <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
               {' '}
               {`${formatNumber(value)}`}
          </td>
     );
};
