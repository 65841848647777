import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toastError } from '../../../../utils';
import { API_CLIENT_REPORTING_EVENT_VARIABLE } from '../../../../constants';
import { callTokenApi } from '../../../../apiCaller';
import Chart from 'react-apexcharts';
import { setShowBlockAccountPopup, setVariableValueChart } from '../../../../actions/subscriber';
import { CSpinner } from '@coreui/react';

const ReportingChart = ({ variableValueToShow, filter, fromDay, endDay, selected }) => {
    const dispatch = useDispatch();
    const variableValueChart = useSelector(state => state.subscriber.variableValueChart);
    const activeAccount = useSelector(state => state.subscriber.activeAccount);
    const [chartLoading, setChartLoading] = useState(false);

    const getChartSeries = () => {
        let series = [];
        if (variableValueChart.chartData.charts) {
            let chartName = variableValueToShow.value.length <= 75 ? variableValueToShow.value : `${variableValueToShow.value.substr(0, 75)}...`;;

            if (chartName === '') {
                chartName = '  '; // Make '' on chart shown as empty string
            }

            variableValueChart.chartData.charts.forEach((item) => {
                let chart = {
                    name: chartName,
                    data: item.map(value => { return { x: value.date, y: value.number } })
                };
                series.push(chart);
            })
        }
        return series;
    }

    const getMax = () => {
        const charts = variableValueChart.chartData.charts;
        let max = 1;
        charts.forEach(chart => {
            let maxOfChart = Math.max(...chart.map(value => parseInt(value.number)));
            if (maxOfChart > max) {
                max = maxOfChart;
            }
        });

        return Math.ceil(max / 10) * 10;
    }

    const chartOptions = {
        series: getChartSeries(),
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            markers: {
                size: [5],
                strokeColors: '#f3f3f3',
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                showNullDataPoints: true,
                hover: {
                    size: [6],
                    sizeOffset: 3
                }
            },
            xaxis: {
                categories: variableValueChart.chartData.labels,
                tickAmount: 15,
                tickPlacement: 'between',
                labels: {
                    trim: true,
                    rotate: -80,
                    maxHeight: 180
                }
            },
            yaxis: {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#008FFB'
                },
                labels: {
                    style: {
                        colors: '#008FFB',
                    },
                    formatter: (value) => value ? value.toLocaleString() : value
                },
                min: 0,
                max: getMax()
            }
        },
    }

    const fetchData = () => {
        setChartLoading(true);
        let apiURL = `${API_CLIENT_REPORTING_EVENT_VARIABLE}`;
        let selectedParam = `selected=${selected}`;
        let date00 = `date00=${fromDay}`;
        let date01 = `date01=${endDay}`;
        let accountIdParam = `accountId=${activeAccount.id}`;
        let keysConcept = `keysConcept=${variableValueToShow.id}`;
        apiURL += `?${accountIdParam}&${date00}&${date01}&${selectedParam}&${keysConcept}`;

        callTokenApi(`${apiURL}`, 'GET', null)
            .then(response => {
                if (response.status === 200) {
                    let data = response.data.data;
                    const chartLabels = data.labels ? [...data.labels] : [];
                    delete data.labels;
                    data = Object.values(data);

                    //  When choosing hourly and not select date, server returned 25 hours so need to display 24 hours
                    const chartData = {
                        charts: selected === 'nthHour' ? data.map(el => el.slice(1)) : [...data],
                        labels: selected === 'nthHour' ? chartLabels.slice(1) : [...chartLabels]
                    }

                    dispatch(setVariableValueChart({
                        selected,
                        filter: '',
                        keysConcept: [variableValueToShow.id],
                        chartData
                    }));
                } else {
                    if (response.data.accountBlocked) {
                        dispatch(setShowBlockAccountPopup(true))
                    } else {
                        toastError(response)
                    }
                }
            })
            .finally(() => {
                setChartLoading(false);
            })
    }

    const fetchDataFilter = () => {
        setChartLoading(true);
        let apiURL = `${API_CLIENT_REPORTING_EVENT_VARIABLE}`;
        let filterParam = `filter=custom`;
        let selectedParam = `selected=${selected}`;
        let date00 = `date00=${fromDay}`;
        let date01 = `date01=${endDay}`;
        let accountIdParam = `accountId=${activeAccount.id}`;
        let keysConcept = `keysConcept=${variableValueToShow.id}`;
        apiURL += `?${accountIdParam}&${filterParam}&${date00}&${date01}&${selectedParam}&${keysConcept}`;

        callTokenApi(`${apiURL}`, 'GET', null)
            .then(response => {
                if (response.status === 200) {
                    let data = response.data.data;
                    const chartLabels = data.labels ? [...data.labels] : [];
                    delete data.labels;
                    data = Object.values(data);
                    const chartData = {
                        charts: [...data],
                        labels: [...chartLabels]
                    }

                    dispatch(setVariableValueChart({
                        selected,
                        filter: 'custom',
                        keysConcept: [variableValueToShow.id],
                        chartData
                    }));
                } else {
                    if (response.data.accountBlocked) {
                        dispatch(setShowBlockAccountPopup(true))
                    } else {
                        toastError(response)
                    }
                }
            })
            .finally(() => {
                setChartLoading(false);
            })
    }

    const fetchEventChart = () => {
        if (variableValueToShow.id) {
            if (filter === 'custom') {
                fetchDataFilter();
            } else {
                fetchData();
            }
        }
    }

    useEffect(fetchEventChart, [variableValueToShow, filter, fromDay, endDay, selected]);

    // render
    return (
        <div className="apex-chart">
            {
                chartLoading === true && (
                    <div className="loading-overlay text-center">
                        <CSpinner color="primary" className="loading-spinner" />
                    </div>
                )
            }
            {
                variableValueToShow.id && (
                    <Chart options={chartOptions.options} series={chartOptions.series} type="line" height={450} />
                )
            }
        </div>
    )
}

export default ReportingChart