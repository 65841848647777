import {
     ADMIN_INCREMENT_NUMBER_HISTORIES,
     ADMIN_SET_PUSHER_CHANNEL_NAME,
     ADMIN_SET_PUBLISHED_ACCOUNTS,
     PUSHER_UDPATE_PUBLISHED_ACCOUNTS,
     ADMIN_SET_PUBLISHING_ACCOUNTS,
     ADMIN_SET_ACCOUNT,
     SET_COUNT_FORM_INVENTORY,
     SET_FORM_INVENTORY,
     SET_SHOW_IGNORED_INVENTORY_FORM,
     SET_FORM_IGNORED,
     FORM_CATEGORY_SET_ALL_FORMS_INVENTORY,
     FORM_CATEGORY_SET_FORM_EDIT,
     SET_LISTENER_FORMS_ENABLED,
     SET_INITIAL_PERSONAL_DATA,
     SET_ACTIVE_LISTENERS,
     FORM_CATEGORY_SET_LIST_FORM_CHILDS,
     FORM_CATEGORY_SET_FILTERS,
     SET_SUBSCRIBER_STATE,
     SET_VERSION_PUBLISH,
     SET_VERSIONS,
     SET_NUMBER_HISTORIES,
     SET_SHOW_PUBLISH_POPUP,
} from '../actions/types';
import { adminPusher } from '../helpers/cms/admin';

const INITIAL_STATE = {
     versionWaiting: {
          version: [],
          isPublish: 'disable',
          numberOfHistories: 0,
     },
     pusherChannelName: '',
     publishedAccounts: [],
     publishedCount: null,
     accountAdmin: null,
     countFormInventory: 0,
     formInventory: null,
     showIgnoredInventoryForm: false,
     formIgnored: null,
     allFormsInventory: null,
     formInventoryEdit: [],
     listenerFormsEnabled: [],
     filterFormCategories: [],
     initialPersonalData: null,
     inventoryShowPaging: true,
     inventoryCountLoading: false,
     countFormRequiring: 0,
     inventoryFormsLoading: false,
     inventoryNumberPerpage: 0,
     inventoryDisableNextPage: false,
     inventoryLoading: false,
     showPublishPopup: false,
     activeListeners: [],
};

export default (state = INITIAL_STATE, action) => {
     switch (action.type) {
          case ADMIN_INCREMENT_NUMBER_HISTORIES:
               return { ...state, versionWaiting: { numberOfHistories: action.payload } };
          case ADMIN_SET_PUSHER_CHANNEL_NAME:
               return { ...state, pusherChannelName: action.payload };
          case ADMIN_SET_PUBLISHED_ACCOUNTS:
               return { ...state, publishedAccounts: action.payload };
          case ADMIN_SET_PUBLISHING_ACCOUNTS:
               return { ...state, publishedCount: 0 };
          case PUSHER_UDPATE_PUBLISHED_ACCOUNTS:
               return {
                    ...state,
                    publishedAccounts: adminPusher.updatePublishedAccounts(state.publishedAccounts, action.payload),
                    publishedCount: state.publishedCount + 1,
               };
          case ADMIN_SET_ACCOUNT:
               return { ...state, accountAdmin: action.payload };
          case SET_COUNT_FORM_INVENTORY:
               return { ...state, countFormInventory: action.payload };
          case SET_FORM_INVENTORY:
               return { ...state, formInventory: action.payload };
          case SET_SHOW_IGNORED_INVENTORY_FORM:
               return { ...state, showIgnoredInventoryForm: action.payload };
          case SET_FORM_IGNORED:
               return { ...state, formIgnored: action.payload };
          case FORM_CATEGORY_SET_ALL_FORMS_INVENTORY:
               return { ...state, allFormsInventory: action.payload };
          case FORM_CATEGORY_SET_FORM_EDIT:
               if (!action.payload) return { ...state, formInventoryEdit: [] };
               let formsEdit = [...state.formInventoryEdit];

               if (formsEdit.length > 0) {
                    const findIndex = formsEdit.findIndex((form) => form.id === action.payload.id);

                    if (findIndex > -1) {
                         formsEdit[findIndex] = action.payload;
                    } else {
                         formsEdit.push(action.payload);
                    }
               } else {
                    formsEdit = [action.payload];
               }

               return { ...state, formInventoryEdit: formsEdit };
          case SET_LISTENER_FORMS_ENABLED:
               return { ...state, listenerFormsEnabled: action.payload };
          case SET_INITIAL_PERSONAL_DATA:
               return { ...state, initialPersonalData: action.payload };
          case SET_ACTIVE_LISTENERS:
               return { ...state, activeListeners: action.payload };
          case FORM_CATEGORY_SET_LIST_FORM_CHILDS:
               if (!action.payload) return state;
               return { ...state, ...action.payload };
          case FORM_CATEGORY_SET_FILTERS:
               return { ...state, filterFormCategories: action.payload };
          case SET_SUBSCRIBER_STATE:
               return { ...state, ...action.payload };
          case SET_VERSION_PUBLISH:
               return { ...state, versionPublish: action.payload };
          case SET_VERSIONS:
               return { ...state, versions: action.payload };
          case SET_NUMBER_HISTORIES:
               return { ...state, numberOfHistories: action.payload };
          case SET_SHOW_PUBLISH_POPUP:
               return { ...state, showPublishPopup: action.payload };
          default:
               return state;
     }
};
