import React, { useContext, useEffect, useState } from 'react';
import { CCard, CCardBody } from '@coreui/react';

import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import { callTokenApi, makeRequest } from '../../../../../../apiCaller';
import {
    API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE,
    API_CLIENT_ACCOUNT_COMPLETED_STEPS,
    API_CLIENT_VALIDATE_SCRIPT_ON_DOMAIN,
    // API_VERSION_PUBLISH,
    // API_VERSION_WAITING
} from '../../../../../../constants';
import wwwAnimationData from '../../../../../../assets/lottie-files/www.json';
import CommonLottie from '../../../../../general/lottie/CommonLottie';
import { getStaticJSFile, toastError } from '../../../../../../utils';
import { setChildSetupGuideDataCompleted} from '../../../../../../actions/subscriber';
import { useDispatch } from 'react-redux';

const VerifyingScript = () => {
    const { account, stepsData, setCurrentStep, updateAccountCompletedSteps } = useContext(CreateAccountContext);
    const [showStillWorking, setShowStillWorking] = useState(false);
    const urlToValidate = stepsData.domainUrlPath ? `${stepsData.domain}/${stepsData.domainUrlPath}` : stepsData.domain;
    
    const dispatch = useDispatch();
    // Publish if the user has never published this account before
    const handlePublish = async () => {
        const scriptJsonRes = await makeRequest(getStaticJSFile(account.id));

        if (scriptJsonRes.status !== 200) {
            // const versionPostData = {
            //     accountId: account.id,
            //     status: "waiting"
            // };
            // let waitingVersion = await callTokenApi(`${API_VERSION_WAITING}`, 'POST', versionPostData)

            // if (waitingVersion.status === 200) {
            //     const publishPostData = {
            //         accountId: account.id,
            //         versionId: waitingVersion.data.version.id,
            //         name: 'Initialize Script',
            //         description: ''
            //     }

            //     await callTokenApi(API_VERSION_PUBLISH, 'POST', publishPostData);
            //     window._noPublishToast = true; // Don't toast message in ThePusher
            await new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 2000)
            }); // Wait 2s
            // }
        }
    }

    const verifyScript = () => {
        const process = async () => {
            let nextStep = STEPS.verificationFailed;

            if (!stepsData.domainUrlPath && (stepsData.domainStatus < 200 || stepsData.domainStatus >= 400)) {
                nextStep = STEPS.verifyWithInaccessibleDomain;
            } else {
                await handlePublish();
                let retryCount = 0;

                const verify = async () => {
                    const validateData = { accountId: account.id, domain: stepsData.domain, domainUrlPath: stepsData.domainUrlPath };
                    const domainValidationRes = await callTokenApi(API_CLIENT_VALIDATE_SCRIPT_ON_DOMAIN, 'PUT', validateData)

                    if (domainValidationRes && domainValidationRes.status === 200) {
                        const { scriptInstalled } = domainValidationRes.data;
                        
                            if (scriptInstalled) {
                                callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_STEPS, 'PUT', { accountId: account.id, step: STEPS.gtmConnection })
                                .then(respponse => {
                                    if(respponse.status === 200){
                                        const newData = {
                                            accountId: account.id,
                                            step: 'installAndLearn',
                                            data: {
                                                installListenlayer: true
                                            }
                                        };
            
                                        callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData)
                                        .then(response => {
                                            if (response.status === 200) {
                                                dispatch(setChildSetupGuideDataCompleted({
                                                    key: 'installAndLearn',
                                                    data: {
                                                        installListenlayer: true
                                                    }
                                                }));
                                            } else {
                                                toastError(response);
                                            }
                                        })
                                    }
                                });
                                const newData = {
                                    accountId: account.id,
                                    step: 'installAndLearn',
                                    data: {
                                        installListenlayer: true
                                    }
                                };
    
                                callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData)
                                .then(response => {
                                    if (response.status === 200) {
                                        dispatch(setChildSetupGuideDataCompleted({
                                            key: 'installAndLearn',
                                            data: {
                                                installListenlayer: true
                                            }
                                        }));
                                    } else {
                                        toastError(response);
                                    }
                                })
                                updateAccountCompletedSteps(STEPS.gtmConnection);
                                nextStep = STEPS.verificationSuccess;
                            } else if (window._retryVerifyScriptOnce && retryCount === 0) {
                                // There's a case the user make changes on user's GTM and then verify script right after.
                                // The GTM container still doesn't have new script tag, it leads to verify script on domain failed. If user make a check again,
                                // it will return verify successfully, so it needs to retry once to make sure if user's domain is actually doesn't have the new LL script
                                retryCount++;
                                window._retryVerifyScriptOnce = false;
                                await verify();
                            }
                        
                    }
                }

                await verify();
            }

            setCurrentStep(nextStep);
        }

        process();

        const verifyingTimeout = setTimeout(() => {
            setShowStillWorking(true);
        }, 12000);

        return () => clearTimeout(verifyingTimeout);
    };
    
    useEffect(verifyScript, []);
    return (
        <CreateAccountBody>
            <CCard className="account-script verify">
                <p className="number-of-step">3 of 3 steps</p>
                <CCardBody>
                    <h3>We are verifying your script</h3>
                    <CommonLottie animationData={wwwAnimationData} />
                    <p>Just to make sure that it's successfully placed on {urlToValidate}</p>
                    {
                        showStillWorking && (
                            <div className="blue-notice">
                                <h4>We're still working...</h4>
                                <p>
                                    We're still working on verifying your script. This screen will change as soon as we are done, please don't navigate away!
                                </p>
                            </div>
                        )
                    }
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default VerifyingScript;
