import React from 'react';
import { CBadge, CLink } from '@coreui/react';
import { useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { NOTIFICATION_STATUS, SUBSCRIBER_PATH } from '../../../constants';

const TheHeaderMonitoringNotification = () => {
     const { showNewLayout } = useSelector(state => state.subscriber.newLayout);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const monitoringNotifications = useSelector(state => state.subscriber.monitoringNotifications);
     const itemsCount = monitoringNotifications ? monitoringNotifications.filter(item => item.status === NOTIFICATION_STATUS.UNREAD).length : 0;
     return (
          <CLink to={showNewLayout ? SUBSCRIBER_PATH.MONITORING_NOTIFICATIONS_V2.replace(':secondId', activeAccount.secondId) : SUBSCRIBER_PATH.MONITORING_NOTIFICATIONS.replace(':secondId', activeAccount.secondId)} className='notification-bell'>
               <CIcon name="cil-bell" />
               {itemsCount > 0 && (
                    <CBadge shape="pill" color="danger" className={itemsCount > 99 ? 'big-number' : ''}>{itemsCount}</CBadge>
               )}
          </CLink>
     )
}

export default TheHeaderMonitoringNotification
