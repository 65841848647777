import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
     CImg,
     CBadge
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import Cookies from 'js-cookie';

import { signout } from '../../../helpers/auth';
import { API_SUPPORT_EXIT, SUBSCRIBER_MY_ACCOUNT_PATH } from '../../../constants';
import {
     Dropdown,
     DropdownItem,
     DropdownMenu,
     DropdownToggle,
} from '../../../components/general/dropdown';
import { callTokenApi } from '../../../apiCaller';
// import { toastError } from '../../../utils';
import { getSupportRedirectChannelId } from '../../../helpers/cms/support';
import { TOURSTEPS } from '../TheTour';

const SubscriberAccountDropdown = () => {
     const user = useSelector(state => state.theme.user);
     const { showTour, stepTour } = useSelector(state => state.subscriber.tour);
     const isCustomerSupportLoginAs = useSelector(state => state.subscriber.isCustomerSupportLoginAs);
     const numberOfCommentsTickets = useSelector(state => state.subscriber.numberOfCommentsTickets);
     const [isExiting, setIsExiting] = useState(false);

     const handleExit = () => {
          setIsExiting(true);

          callTokenApi(`${API_SUPPORT_EXIT}?redirectChannelId=${getSupportRedirectChannelId()}`, 'GET')
               .then(response => {
                    setIsExiting(false);
                    const { token, expirationDate } = response.data;

                    if (response.status === 200) {
                         // Set token of CS to cookie and redirect to home
                         let tokenOptions = { expires: new Date(expirationDate) };

                         if (window.location.hostname !== 'localhost') {
                              tokenOptions.domain = 'listenlayer.com';
                         }

                         Cookies.set('token', token, tokenOptions);
                         window.location.replace('/accounts');
                    } else {
                         // if (isTokenExpired) {
                         //      signout(true);
                         // } else {
                         //      toastError(response);
                         // }
                         signout(true);
                    }
               })
     }

     return (
          <Dropdown
               isHeaderDropdown
               inNav
               className={`c-header-nav-items user-nav-item${showTour && stepTour === TOURSTEPS.personalLogin.orderNumber ? ' highlight-item' : ''}`}
               direction="down"
               forceOpen={showTour && stepTour === TOURSTEPS.personalLogin.orderNumber}
          >
               <DropdownToggle
                    className="c-header-nav-link"
                    caret={false}
               >
                    <div className="c-avatar avatar1">
                         <CImg
                              src={user.avatar && user.avatar !== 'false' ? user.avatar : '/user-default-avatar.png'}
                              className="c-avatar-img"
                              alt="avatar"
                         />
                         <img src="/assets/icons/Shapeavatar.svg" alt="shape-avatar" className="shape-avatar-icon"></img>

                    </div>
               </DropdownToggle>
               <DropdownMenu
                    className="pt-0 account-dropdown setting-dropdown"
                    placement="bottom-end"
               >
                    <CIcon name="arrow-account" className="arrow-account arrow-setting" />
                    <DropdownItem
                         header
                         tag="div"
                    >
                         {/* <CIcon name="arrow-account" className="arrow-account" /> */}
                         <span>My Account</span>
                         <p className="account-user-email m-0">{user ? user.email : ''}</p>
                    </DropdownItem>
                    {
                         isCustomerSupportLoginAs ? (
                              <DropdownItem className="sign-out text-danger" onClick={handleExit} disabled={isExiting} clickDoesNotClose={true}>
                                   <span>
                                        EXIT TO CUSTOMER SUPPORT CMS{isExiting && <span className="dots-waiting"></span>}
                                   </span>
                              </DropdownItem>
                         ) : (
                              <>
                                   <DropdownItem to={SUBSCRIBER_MY_ACCOUNT_PATH.PROFILE}>
                                        My Profile
                                   </DropdownItem>
                                   <DropdownItem to={SUBSCRIBER_MY_ACCOUNT_PATH.EMAIL_NOTIFICATIONS}>
                                        Email Notifications
                                   </DropdownItem>
                                   {/* <DropdownItem className="affiliate" to={SUBSCRIBER_MY_ACCOUNT_PATH.AFFILIATE}>
                                        Affiliate
                                        <CBadge className="label-share">SHARE & EARN</CBadge>
                                   </DropdownItem> */}
                                   <DropdownItem className="my-ticket" to={SUBSCRIBER_MY_ACCOUNT_PATH.SUPPORT_TICKETS}>
                                        My Tickets
                                        {numberOfCommentsTickets > 0 && <CBadge color="info" className="mfs-auto">{numberOfCommentsTickets}</CBadge>}
                                   </DropdownItem>
                              </>
                         )
                    }
                    <DropdownItem className="sign-out text-danger" onClick={signout}>
                         SIGN OUT
                    </DropdownItem>
               </DropdownMenu>
          </Dropdown>
     )
}

export default React.memo(SubscriberAccountDropdown)
