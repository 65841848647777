import { CButton, CLabel, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { setFlexibleModal, setRuleHaveEditting, setViewedRule } from '../../../../../../../../actions/common';
import { setSubscriberState } from '../../../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../../../apiCaller';
import {
     API_CLIENT_RULE_UPDATE,
     COMPONENT_NAME,
     NATIVE_BROWSER_CONDITIONS,
     TYPE_SHOW_UNSAVE_CHANGE,
     VARIABLE_OPERATORS,
} from '../../../../../../../../constants';
import { deepCopyArray, toastError } from '../../../../../../../../utils';
import CenterSpinner from '../../../../../../../general/Loadings/CenterSpinner';
import { ConfirmSaveChange } from '../../../../../../../general/popup';
import { FormRuleSettingContext } from '../EditFormRuleSetting';
import SelectInsertVariable from '../../../../SelectInsertVariable';
import { useEventAndVariableAccountOptions } from '../../../../../../../../helpers/customHooks';
import { setReloadLookupTable } from '../../../../../../../../actions/subscriber';

const validationSchema = () => {
     return Yup.object().shape({
          variables: Yup.array().required('Please choose at least one option of these fields!').nullable(),
     });
};

const EditStepSelectConditionFields = ({ stepsData }) => {
     const dispatch = useDispatch();
     const { conditions } = stepsData;
     const { activeStep, setActiveStep } = useContext(FormRuleSettingContext);
     const { showLv2 } = useSelector((state) => state.theme.flexibleModal);
     const formCategoriesRules = useSelector((state) => state.subscriber.formCategoriesRules);
     const [updateModal, setUpdateModal] = useState(false);
     const [newConditions, setNewConditions] = useState([]);
     const [saveRuleLoading, setSaveRuleLoading] = useState(false);
     const [saveLoading, setSaveLoading] = useState(false);

     const { fetchLoading, formRuleVariableOptions: variables } = useEventAndVariableAccountOptions(activeStep === 3);

     const initialGroup = {
          type: '',
          key: '',
          operator: '',
          value: '',
          isRegex: false,
     };

     const toggleSaveModal = useCallback(() => {
          setUpdateModal(!updateModal);
     }, [updateModal]);

     const getVariableOption = (item) => {
          return {
               label: item.fullName,
               value: item.fullName,
          };
     };

     const getVariableOptions = (variables) => {
          let variableData = [];
          if (variables && variables.length > 0) {
               variables.forEach((variable) => {
                    if (variable.status && variable.childs) {
                         variableData.push(...getVariableOptions(variable.childs));
                    } else {
                         variableData.push(getVariableOption(variable));
                    }
               });
          }
          return variableData;
     };

     const getConjunctions = (type, key) => {
          let operator = VARIABLE_OPERATORS[0].VALUE;

          if (type === key.includes(`"format":"date function"`)) {
               operator = NATIVE_BROWSER_CONDITIONS.DATE_FUNCTION[0].VALUE; // Value of the first option
          }

          let newGroup = {
               conjunction: 'and',
               g0: {
                    ...initialGroup,
                    operator,
                    type,
                    key,
               },
          };
          return newGroup;
     };

     const getInitialConditions = () => {
          let variableList = [];
          let variableOptions = getVariableOptions(variables);
          let initialValues = {
               variables: [],
          };
          if (conditions && conditions.length > 0) {
               conditions[0].forEach((item) => {
                    variableList.push(item.g0.key);
               });

               const getLastChosenOptions = (arr, list, key) => {
                    arr.forEach((item) => {
                         let index = list.indexOf(item.value);
                         if (index !== -1) {
                              initialValues[key][index] = { ...item };
                         }
                    });
               };

               getLastChosenOptions(variableOptions, variableList, 'variables');
          }

          return initialValues;
     };

     const initialValues = getInitialConditions();

     const onSubmit = (values) => {
          let { variables } = values;
          let conditions = [];
          let condition = [];

          if (variables) {
               variables.forEach((variable) => {
                    condition.push(getConjunctions('Variable', variable.value));
               });
          }

          conditions.push(condition);
          setNewConditions(conditions);
          setSaveRuleLoading(true);
          toggleSaveModal();
     };

     const handleAcceptPopup = () => {
          setSaveLoading(true);
          let conditions = [...newConditions];
          let newStepsDataConditions = [...stepsData.conditions];

          let newList = [],
               newStepsData = {};
          newList = newStepsDataConditions.map((stepCondition) => {
               let newCons = [];
               newCons = conditions[0].map((condition) => {
                    let findIndex = checkInArray(condition.g0.key, stepCondition);
                    if (findIndex !== -1) {
                         condition = stepCondition[findIndex];
                    }
                    return condition;
               });
               return deepCopyArray(newCons);
          });

          newList.forEach((condition) => {
               condition.forEach((item) => {
                    if (item.conjunction === '') {
                         item.conjunction = 'and';
                    }
               });
               condition[condition.length - 1].conjunction = '';
          });

          newStepsData = { ...stepsData, conditions: newList };

          callTokenApi(API_CLIENT_RULE_UPDATE, 'POST', newStepsData)
               .then((response) => {
                    if (response.status === 200) {
                         // toast.success('Successfully update rule');
                         toggleSaveModal();

                         const updateRules = formCategoriesRules ? formCategoriesRules.filter((rule) => rule.id !== newStepsData.id) : [];
                         updateRules.push(newStepsData);
                         dispatch(
                              setSubscriberState({
                                   formCategoriesRules: updateRules,
                              }),
                         );
                         dispatch(setViewedRule(null, true));
                         dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
                         dispatch(setReloadLookupTable(true));
                         setTimeout(() => {
                              if (!showLv2) {
                                   dispatch(
                                        setFlexibleModal({
                                             show: true,
                                             showLv2: true,
                                             ruleId: stepsData.id,
                                             ruleIdLv2: stepsData.id,
                                             component: COMPONENT_NAME.FORM_RULE_SETTING,
                                             componentLv2: COMPONENT_NAME.FORM_RULE_TABLE,
                                        }),
                                   );
                              } else {
                                   dispatch(
                                        setFlexibleModal({
                                             show: true,
                                             showLv2: false,
                                             ruleId: stepsData.id,
                                             ruleIdLv2: '',
                                             component: COMPONENT_NAME.FORM_RULE_TABLE,
                                             componentLv2: '',
                                        }),
                                   );
                              }
                         }, 0);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setSaveLoading(false);
                    setSaveRuleLoading(false);
               });
     };

     const checkInArray = (value, array) => {
          let index = -1;
          array.some((item, itemIndex) => {
               if (item.g0.key.indexOf(value) !== -1) {
                    index = itemIndex;
                    return true;
               }
               return false;
          });
          return index;
     };

     const handleHasChange = () => {
          dispatch(setRuleHaveEditting({ showLv2: true, typeLv2: TYPE_SHOW_UNSAVE_CHANGE.EDIT_LOOKUP }));
     };

     return (
          <>
               <CCard className={`cvr-step-3 ${activeStep !== 3 ? 'difference-step' : 'cvr-step-card'}`}>
                    <CCardHeader>
                         {activeStep !== 3 ? (
                              <div className="rule-step d-inline-flex justify-content-between align-items-center w-100">
                                   <h5 className="mb-0 inactive">Step 2: Select Condition Fields</h5>
                                   {stepsData.conditions.length > 0 && (
                                        <CButton className="btn-edit" onClick={() => setActiveStep(3)}>
                                             Edit
                                        </CButton>
                                   )}
                              </div>
                         ) : (
                              <h5>Step 2: Select Condition Fields</h5>
                         )}
                    </CCardHeader>
                    {activeStep === 3 && (
                         <>
                              <CCardBody>
                                   <CRow>
                                        <CCol md="9">
                                             {fetchLoading ? (
                                                  <CenterSpinner />
                                             ) : (
                                                  <Formik
                                                       initialValues={initialValues}
                                                       validationSchema={validationSchema}
                                                       onSubmit={onSubmit}
                                                       validateOnChange={true}
                                                       validateOnBlur={false}
                                                       enableReinitialize
                                                  >
                                                       {({ values, errors, touched, setFieldValue, setFieldTouched, handleSubmit, setErrors }) => {
                                                            return (
                                                                 <Form onSubmit={handleSubmit} className="rule-step">
                                                                      <p>
                                                                           Select the data layer events or variables you would like to use as
                                                                           conditions to define other data layer values.
                                                                      </p>
                                                                      <div className="variables-multiselect mb-3">
                                                                           <CLabel>Variables</CLabel>
                                                                           <SelectInsertVariable
                                                                                name="variables"
                                                                                placeholder="Select Variables"
                                                                                options={getVariableOptions(variables)}
                                                                                value={values.variables}
                                                                                onChange={setFieldValue}
                                                                                hasChange={handleHasChange}
                                                                                isMulti={true}
                                                                                selectInputType={false}
                                                                                error={errors.variables}
                                                                           />
                                                                      </div>
                                                                      <div className="text-left">
                                                                           <CButton
                                                                                className="px-4"
                                                                                color="primary"
                                                                                shape="square"
                                                                                type="submit"
                                                                                disabled={saveRuleLoading}
                                                                           >
                                                                                Save & Manage Values
                                                                           </CButton>
                                                                      </div>
                                                                 </Form>
                                                            );
                                                       }}
                                                  </Formik>
                                             )}
                                        </CCol>
                                   </CRow>
                              </CCardBody>
                         </>
                    )}
               </CCard>
               <ConfirmSaveChange
                    show={updateModal}
                    onClose={() => {
                         toggleSaveModal();
                         setSaveRuleLoading(false);
                    }}
                    onAccept={handleAcceptPopup}
                    title="Warning! This Will Change Your Rule"
                    isLoading={saveLoading}
               >
                    You are changing Conditions. This will make some changes to this rule. You will need to review your lookup table settings and
                    adjust them as needed.
               </ConfirmSaveChange>
          </>
     );
};

EditStepSelectConditionFields.propTypes = {
     stepsData: PropTypes.object,
     setStepsData: PropTypes.func,
};
export default EditStepSelectConditionFields;
