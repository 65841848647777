import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { callApi } from '../../../../../apiCaller';
import { API_CLIENT_ACCOUNT_RULE } from '../../../../../constants';
import { getTokenCookie, toastError } from '../../../../../utils';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import Step1 from './Step1';
import LookupRuleStep2 from './lookup/EditStep2';
import LookupRuleStep3 from './lookup/EditStep3';
import LookupRuleStep4 from './lookup/EditStep4';

const EditLookupRule = ({ rule }) => {
     const [stepsData, setStepsData] = useState(cloneDeep(rule));

     return (
          <>
               <LookupRuleStep2 stepsData={stepsData} setStepsData={setStepsData} />
               <LookupRuleStep3 stepsData={stepsData} setStepsData={setStepsData} />
               <LookupRuleStep4 stepsData={stepsData} setStepsData={setStepsData} />
          </>
     );
};

export const RuleSettingContext = React.createContext({});

const EditLookupSetting = ({ id }) => {
     const token = getTokenCookie();
     const [rule, setRule] = useState();
     const [initialRule, setInitialRule] = useState();
     const [modal, setModal] = useState(false);
     const [ruleType, setRuleType] = useState('');
     const [activeStep, setActiveStep] = useState(2);
     const [fetchRuleLoading, setFetchRuleLoading] = useState();
     const editingRuleReload = useSelector((state) => state.subscriber.editingRuleReload);
     const redirectRules = useSelector((state) => state.subscriber.redirectRules);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const lookupActiveStep = useSelector(state => state.subscriber.lookupRuleActiveStep);

     const fetchRule = () => {
          setFetchRuleLoading(true);

          callApi(`${API_CLIENT_ACCOUNT_RULE}/${id}`, 'GET', null, token)
               .then((response) => {
                    if (response.status === 200) {
                         const { rule } = response.data;
                         if (rule.accountId !== activeAccount.id) {
                              window.location.href = '/';
                         }
                         setRuleType(rule.type);
                         setRule(rule);
                         setInitialRule(cloneDeep(rule));
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setFetchRuleLoading(false);
               });
     };
     // editingRuleReload meaning: Editing rule pusher will make editingRuleReload = !editingRuleReload,
     // so other users are editing the same rule will fetch rule again
     useEffect(fetchRule, [editingRuleReload, id]);

     function capitalizeFirstLetter(string) {
          return string.charAt(0).toUpperCase() + string.slice(1);
     }

     const ruleTypeName = capitalizeFirstLetter(ruleType);

     const fetchActiveStep = () => {
          if (lookupActiveStep) {
               setActiveStep(lookupActiveStep);
          }
     }
     useEffect(fetchActiveStep, [lookupActiveStep]);

     return (
          <>
               {fetchRuleLoading ? (
                    <CenterSpinner />
               ) : (
                    <div className='rule-detail'>
                         <div className='cvr-create-new custom-rule'>
                              {redirectRules.status && <Redirect to={redirectRules.to} />}
                              {rule && ruleType && (
                                   <>
                                        <h1>Edit {ruleTypeName} Trigger Rule : {rule.name}</h1>
                                        <p>Use the steps below to configure your rule.</p>
                                        <Step1
                                             activeStep={activeStep}
                                             setActiveStep={setActiveStep}
                                             ruleType={ruleType}
                                             setRuleType={setRuleType}
                                             actionType='edit'
                                        />
                                        <RuleSettingContext.Provider
                                             value={{
                                                  activeStep,
                                                  setRule,
                                                  setActiveStep,
                                                  fetchRule,
                                             }}
                                        >
                                             <EditLookupRule rule={rule} initialRule={initialRule} />
                                        </RuleSettingContext.Provider>
                                        <CModal show={modal} centered={true} onClose={() => setModal(false)}>
                                             <CModalBody className='text-center'>
                                                  <h3 className='mb-4'>Warning! This Will Change Your Rule</h3>
                                                  <p>
                                                       You are about to change your rule type. Doing this will reset and remove all settings inside this rule other than the
                                                       name and description.
                                                  </p>
                                                  <p>Are you sure you want to do this? You cannot undo this once you publish it!</p>
                                             </CModalBody>
                                             <CModalFooter className='text-center justify-content-center'>
                                                  <CButton color='primary' className='mr-3 text-uppercase' onClick={() => setModal(false)}>
                                                       Cancel
                                                  </CButton>
                                                  <CButton color='secondary' onClick={() => { }}>
                                                       Save Change and Reset Rule
                                                  </CButton>
                                             </CModalFooter>
                                        </CModal>
                                   </>
                              )}
                         </div>
                    </div>
               )}
          </>
     );
};

export default EditLookupSetting;
