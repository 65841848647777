import {
     CButton,
     CModal,
     CModalBody,
     CModalFooter
} from '@coreui/react';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { callApi } from '../../../../../apiCaller';
import { API_CLIENT_RULE } from '../../../../../constants';
import { getTokenCookie, toastError } from '../../../../../utils';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import LookupRuleCustomStep3 from './lookup-custom/LookupTable';

const EditLookupRule = ({ rule, initialRule }) => {
     const [stepsData, setStepsData] = useState(cloneDeep(rule));
     const initialStepsData = cloneDeep(initialRule);

     return (
          <LookupRuleCustomStep3
               stepsData={stepsData}
               setStepsData={setStepsData}
               initialStepsData={initialStepsData}
          />
     )
}

export const RuleContext = React.createContext({});

const EditRule = ({ id }) => {
     const token = getTokenCookie();
     const [rule, setRule] = useState();
     const [initialRule, setInitialRule] = useState();
     const [modal, setModal] = useState(false);
     const [ruleType, setRuleType] = useState('');
     const [activeStep, setActiveStep] = useState(3);
     const [fetchRuleLoading, setFetchRuleLoading] = useState();
     const editingRuleReload = useSelector(state => state.subscriber.editingRuleReload);
     const redirectRules = useSelector(state => state.subscriber.redirectRules);
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const reloadUserSource = useSelector(state => state.subscriber.reloadUserSource);

     const fetchRule = () => {
          setFetchRuleLoading(true);

          callApi(`${API_CLIENT_RULE}${id}`, 'GET', null, token)
               .then(response => {
                    if (response.status === 200) {
                         const { rule } = response.data;
                         if (rule.accountId !== activeAccount.id) {
                              window.location.href = '/';
                         }
                         setRuleType(rule.type);
                         setRule(rule);
                         setInitialRule(cloneDeep(rule));
                    } else {
                         toastError(response)
                    }
               })
               .finally(() => {
                    setFetchRuleLoading(false);
               });
     }

     // editingRuleReload meaning: Editing rule pusher will make editingRuleReload = !editingRuleReload,
     // so other users are editing the same rule will fetch rule again
     useEffect(fetchRule, [editingRuleReload, reloadUserSource]);

     return (
          <>
               {
                    fetchRuleLoading ? (
                         <CenterSpinner />
                    ) : (
                         <div className='rule-detail'>
                              <div className="cvr-create-new">
                                   {
                                        redirectRules.status && <Redirect to={redirectRules.to} />
                                   }
                                   {
                                        rule && ruleType && (
                                             <>
                                                  <RuleContext.Provider
                                                       value={{
                                                            activeStep,
                                                            setRule,
                                                            setActiveStep,
                                                            fetchRule
                                                       }}
                                                  >
                                                       <EditLookupRule
                                                            rule={rule}
                                                            initialRule={initialRule}
                                                       />
                                                  </RuleContext.Provider>
                                                  <CModal
                                                       show={modal}
                                                       centered={true}
                                                       onClose={() => setModal(false)}
                                                  >
                                                       <CModalBody className="text-center">
                                                            <h3 className="mb-4">Warning! This Will Change Your Rule</h3>
                                                            <p>
                                                                 You are about to change your rule type. Doing this will reset and remove all settings inside this rule other than the name and description.
                                                            </p>
                                                            <p>Are you sure you want to do this? You cannot undo this once you publish it!</p>
                                                       </CModalBody>
                                                       <CModalFooter className="text-center justify-content-center">
                                                            <CButton
                                                                 color="primary"
                                                                 className="mr-3 text-uppercase"
                                                                 onClick={() => setModal(false)}>Cancel</CButton>
                                                            <CButton
                                                                 color="secondary"
                                                                 onClick={() => { }}
                                                            >Save Change and Reset Rule</CButton>
                                                       </CModalFooter>
                                                  </CModal>
                                             </>
                                        )
                                   }
                              </div>
                         </div>
                    )
               }
          </>
     )
}

export default EditRule;