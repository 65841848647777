import React from 'react'
import CIcon from '@coreui/icons-react';
const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={`${className} faright`} onClick={onClick}>
          <CIcon name="fa-right"></CIcon>
        </div>
    )
}

export default NextArrow