import CIcon from '@coreui/icons-react';
import {
    CInput,
    CInputCheckbox,
    CSelect,
    CTooltip,
} from '@coreui/react';
import debounce from 'lodash/debounce';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { deepCopyArray, jsonAndCompare } from '../../../../../../utils';
import { LookupTableContext } from './LookupTable';
import { VARIABLE_OPERATORS_NAME } from '../../../../../../constants';

const CustomInput = ({ value, onChangeInput, disableCell, ...rest }) => {
    
    const [inputValue, setInputValue] = useState(value);

    const onChange = (e) => {
        const { value } = e.target;
        setInputValue(value);
        onChangeInput(value);
    }

    useMemo(() => setInputValue(value), [value]);

    return (
        <CInput
            value={inputValue}
            onChange={onChange}
            autoComplete="off"
            data-lpignore="true"
            disabled = {disableCell} 
            style={disableCell ? { backgroundColor: '#c8ced3' } : {}}
            {...rest}
        />
    )
}

const areEqual = (prevProps, nextProps) => {
    const compare = (key) => {
        return prevProps[key].length === nextProps[key].length;
    }

    let shouldNotRender = jsonAndCompare(prevProps.row, nextProps.row) &&
        compare('customVariableOptions') &&
        compare('eventNativeOptions') &&
        compare('eventOptions') &&
        compare('nativeBrowserStorageOptions') &&
        compare('reactSelectSelectorValueOptions') &&
        compare('variableOptions');

    return shouldNotRender;
}

const Row = React.memo((props) => {
    const {
        row,
        rowIndex,
        setTableRow,
        setRemoveRowModal,
        IsExisted,
        resetBtnSaveStatus,
        handleChangeRowData,
        setCursorPosition,
        setVariablePositionToInsert,
        toggleVariableModal
    } = props;
    const eventTypes = ["Event", "MyEventNative"];
    const variableTypes = ["Variable", "Selector"];
    const [hiddenClose, setHiddenClose] = useState(false);

    const handleCheckRegexBox = (rowIndex, colIndex) => {
        setTableRow(prevTableRow => {
            let data = deepCopyArray(prevTableRow);
            let rowConditions = data[rowIndex].conditions;
            let condition = rowConditions[colIndex];
            condition.g0.isRegex = !condition.g0.isRegex;

            return data;
        });
        resetBtnSaveStatus();
    }

    const handleRemoveRow = (index) => {
        setTableRow(prevTableRow => {
            let data = [...prevTableRow];
            let flag = false;
            flag = data[index].conditions.some(condition => {
                if (condition.g0.value !== "") {
                    return true;
                }
                return false;
            });
            if (flag === false) {
                flag = data[index].customVariables.some(customVariable => {
                    if (customVariable.value !== "") {
                        return true;
                    }
                    return false;
                });
            }
            if (flag === true) {
                setRemoveRowModal({ show: true, index });
            } else {
                if (data.length > 1) {
                    data.splice(index, 1);
                    return data;
                }
            }

            return prevTableRow;
        });

        resetBtnSaveStatus();
    }

    const onClickInsertVariable = useCallback((rowIndex, colIndex) => {
        setVariablePositionToInsert({ rowIndex, colIndex });
        toggleVariableModal();
    }, [toggleVariableModal, setVariablePositionToInsert])

    const onHoverInsertVariable = () => {
        setHiddenClose(true);
    };

    const onLeaveInsertVariable = () => {
        setHiddenClose(false);
    }

    const handleChangeCursorPosition = debounce((position) => {
        setCursorPosition(position);
    }, 200)

    let conditionRow = row.conditions;
    let cusVariableRow = row.customVariables;

    const onChangeInput = debounce((value, type, rowIndex, colIndex) => {
        handleChangeRowData(value, type, rowIndex, colIndex)
    }, 200);

    return (
        <Draggable key={rowIndex} draggableId={rowIndex.toString()} index={rowIndex}>
            {
                (provided, snapshot) => (
                    <tr className={`table-body ${snapshot.isDragging ? 'isDragging' : ''}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        {
                            conditionRow && conditionRow.map((col, colIndex) => {
                                let conditionValues = col.g0;
                                let find = IsExisted(conditionValues.id, conditionValues.type);
                                let disableCell = [VARIABLE_OPERATORS_NAME.EXISTS_WITH_ANY_VALUE,VARIABLE_OPERATORS_NAME.DOES_NOT_EXIST].includes(conditionValues.operator)
                                return (
                                    <React.Fragment key={colIndex}>
                                        {
                                            find && (
                                                <>
                                                    {
                                                        eventTypes.indexOf(conditionValues.type) > -1 && (
                                                            <td className="event-variable-value event-variable-value-ellipsis">
                                                                {
                                                                    colIndex === 0 && <span>
                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                    </span>
                                                                }
                                                                <CSelect
                                                                    name="event"
                                                                    value={conditionValues.value}
                                                                    className="rounded-0"
                                                                    onChange={(e) => { handleChangeRowData(e.target.value, 'conditions', rowIndex, colIndex) }}
                                                                >
                                                                    <option value="" disabled hidden>Select</option>
                                                                    <option value="false">false</option>
                                                                    <option value="true">true</option>
                                                                </CSelect>
                                                            </td>
                                                        )
                                                    }
                                                    {
                                                        (variableTypes.indexOf(conditionValues.type) > -1 || conditionValues.type === "NativeBrowserStorage") && (
                                                            <td className="event-variable-value variable-column event-variable-value-ellipsis">
                                                                {
                                                                    colIndex === 0 && <span>
                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                    </span>
                                                                }
                                                                <CustomInput
                                                                    name="variable"
                                                                    className="rounded-0"
                                                                    value={conditionValues.value}
                                                                    onChangeInput={(value) => onChangeInput(value, 'conditions', rowIndex, colIndex)}
                                                                    disableCell={disableCell}
                                                                />
                                                                {/* <CInput
                                                                    name="variable"
                                                                    value={conditionValues.value}
                                                                    // defaultValue={conditionValues.value}
                                                                    className="rounded-0"
                                                                    onChange={(e) => onChangeInput(e.target.value, 'conditions', rowIndex, colIndex)}
                                                                    autoComplete="off"
                                                                    data-lpignore="true"
                                                                /> */}
                                                                <div className={`regex-group-wrapper ${conditionValues.isRegex ? 'checked' : ''}`}>
                                                                    <CInputCheckbox
                                                                        name="regexCheckbox"
                                                                        id={`regexCheckbox-${rowIndex}-${colIndex}`}
                                                                        checked={conditionValues.isRegex}
                                                                        onChange={() => handleCheckRegexBox(rowIndex, colIndex)}
                                                                    />
                                                                    <label htmlFor={`regexCheckbox-${rowIndex}-${colIndex}`}>Use RegEx</label>
                                                                </div>
                                                            </td>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                        {
                            cusVariableRow && cusVariableRow.map((col, colIndex) => {
                                let find = IsExisted(col.id);
                                let disableCell = [VARIABLE_OPERATORS_NAME.EXISTS_WITH_ANY_VALUE,VARIABLE_OPERATORS_NAME.DOES_NOT_EXIST].includes(col.operator)
                                return (
                                    <React.Fragment key={colIndex}>
                                        {
                                            find && (
                                                <td className="custom-variable-value">
                                                    <CustomInput
                                                        name="customVariable"
                                                        className="rounded-0"
                                                        value={col.value}
                                                        onChangeInput={(value) => onChangeInput(value, 'customVariables', rowIndex, colIndex)}
                                                        onClick={e => handleChangeCursorPosition(e.target.selectionStart)}
                                                        // onKeyUp: Pick the next caret position, onKeyDown: Pick the previous caret position
                                                        onKeyUp={e => handleChangeCursorPosition(e.target.selectionStart)}
                                                        disableCell={disableCell}
                                                    />
                                                    {/* <CInput
                                                        name="customVariable"
                                                        value={col.value}
                                                        className="rounded-0"
                                                        onChange={(e) => { handleChangeRowData(e.target.value, 'customVariables', rowIndex, colIndex) }}
                                                        onClick={e => handleChangeCursorPosition(e.target.selectionStart)}
                                                        // onKeyUp: Pick the next caret position, onKeyDown: Pick the previous caret position
                                                        onKeyUp={e => handleChangeCursorPosition(e.target.selectionStart)}
                                                        autoComplete="off"
                                                        data-lpignore="true"
                                                    /> */}
                                                    <div className="insert-variable">
                                                        <CIcon name={hiddenClose ? 'iconInsertSortCodeActive' : 'iconInsertSortCode'} height="14" onClick={() => onClickInsertVariable(rowIndex, colIndex)}
                                                            onMouseEnter={() => onHoverInsertVariable()}
                                                            onMouseLeave={() => onLeaveInsertVariable()}
                                                        />
                                                    </div>
                                                </td>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                        <td key={rowIndex} className={`btn-remove-row ${hiddenClose ? 'icon-close-hidden' : ''}`}>
                            <CTooltip
                                content="Remove row"
                                placement="bottom-start"
                            >
                                <i
                                    className="fal fa-times text-danger"
                                    onClick={() => handleRemoveRow(rowIndex)}
                                >
                                </i>
                            </CTooltip>
                        </td>
                    </tr>
                )
            }
        </Draggable >
    )
}, areEqual);

const TableBodyRows = () => {
    const theContext = useContext(LookupTableContext);
    const { tableRow } = theContext;
    return (
        <>
            {
                tableRow && tableRow.map((row, rowIndex) => (
                    <Row
                        key={rowIndex}
                        row={row}
                        rowIndex={rowIndex}
                        {...theContext}
                    />
                ))
            }
        </>
    )
}

export default TableBodyRows
