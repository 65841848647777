import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
     CModal,
     CModalBody,
     CInput,
     CForm,
     CFormGroup,
     CButton,
     CInvalidFeedback
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { validateFormik, getTokenCookie, toastError } from '../../../utils';
import { callApi } from '../../../apiCaller';
import { API_SET_PASSWORD } from '../../../constants';
import { setUser } from '../../../actions/common';

const initialValues = {
     code: '',
     newPassword: '',
     newPasswordRepeat: ''
}

const validationSchema = (values) => (
     Yup.object().shape({
          code: Yup.string()
               .trim()
               .required('Verification code is required')
          ,
          newPassword: Yup.string()
               .required('New password is required')
               .min(8, 'New password must be at least 8 characters')
          ,
          newPasswordRepeat: Yup.string()
               .required('Verify new password is required')
               .oneOf([values.newPassword], 'Passwords must match')
     })
)

const SetPasswordPopup = ({ show, onCancel, children }) => {
     const token = getTokenCookie();
     const dispatch = useDispatch();
     const [validateOnChange, setValidateOnChange] = useState(false);
     const user = useSelector(state => state.theme.user);

     const onSubmit = ({ code, newPassword }, { resetForm }) => {
          const data = {
               email: user.email,
               code,
               newPassword
          }

          callApi(API_SET_PASSWORD, 'PUT', data, token)
               .then(response => {
                    if (response.status === 200) {
                         const newUser = { ...user, setPassword: true };
                         dispatch(setUser(newUser));
                         toast.success('Successfully change password');
                    } else {
                         setValidateOnChange(false);
                         resetForm();
                         toastError(response);
                    }
               })
          // .finally(() => {
          //      setValidateOnChange(false);
          //      onCancel();
          //      resetForm();
          // })
     }

     const handleCancelChanges = (resetForm) => {
          resetForm();
          onCancel();
     }

     const next = () => {
          if (!validateOnChange) {
               setValidateOnChange(true);
          }
     }

     return (
          <div className="set-password-popup">
               <CModal
                    show={show}
                    onClose={onCancel}
                    color="primary"
                    centered
               >
                    <CModalBody>
                         {/* <CSidebarClose onClick={onCancel} /> */}
                         <CIcon name="cil-x" onClick={onCancel} className="icon-close-popup"></CIcon>
                         <div className="icon-locked"><CIcon name="cil-lock-locked" height="67" /></div>
                         <div className="set-password-popup-description">
                              <h2>Set Direct Password</h2>
                              {children}
                         </div>
                         <Formik
                              initialValues={initialValues}
                              validate={validateFormik(validationSchema, next)}
                              onSubmit={onSubmit}
                              validateOnChange={validateOnChange}
                              validateOnBlur={false}
                         >
                              {
                                   ({
                                        values,
                                        errors,
                                        isSubmitting,
                                        handleChange,
                                        handleSubmit,
                                        resetForm
                                   }) => (
                                        <CForm onSubmit={handleSubmit} noValidate className="form-set-password">
                                             <CFormGroup>
                                                  <CInput
                                                       name="code"
                                                       placeholder="Verification Code"
                                                       autoComplete="code"
                                                       invalid={!!errors.code}
                                                       disabled={isSubmitting}
                                                       value={values.code}
                                                       onChange={handleChange}
                                                       maxLength={4}
                                                  />
                                                  <CInvalidFeedback>{errors.code}</CInvalidFeedback>
                                             </CFormGroup>
                                             <CFormGroup>
                                                  <CInput
                                                       type="password"
                                                       name="newPassword"
                                                       autoComplete="new-password"
                                                       placeholder="New Password"
                                                       invalid={!!errors.newPassword}
                                                       disabled={isSubmitting}
                                                       value={values.newPassword}
                                                       onChange={handleChange}
                                                  />
                                                  <CInvalidFeedback>{errors.newPassword}</CInvalidFeedback>
                                             </CFormGroup>
                                             <CFormGroup>
                                                  <CInput
                                                       type="password"
                                                       name="newPasswordRepeat"
                                                       autoComplete="new-password"
                                                       placeholder="Verify New Password"
                                                       invalid={!!errors.newPasswordRepeat}
                                                       disabled={isSubmitting}
                                                       value={values.newPasswordRepeat}
                                                       onChange={handleChange}
                                                  />
                                                  <CInvalidFeedback>{errors.newPasswordRepeat}</CInvalidFeedback>
                                             </CFormGroup>
                                             <div className="form-actions">
                                                  <CButton onClick={() => handleCancelChanges(resetForm)} className="btn-primary">CANCEL CHANGES</CButton>
                                                  <CButton type="submit" disabled={isSubmitting} className="btn-light">
                                                       {isSubmitting ? (<>Waiting<span className="dots-waiting"></span></>) : 'SET NEW PASSWORD'}
                                                  </CButton>
                                             </div>
                                        </CForm>
                                   )
                              }
                         </Formik>
                    </CModalBody>
               </CModal>
          </div>
     )
}

SetPasswordPopup.propTypes = {
     show: PropTypes.bool,
     onCancel: PropTypes.func,
}

export default SetPasswordPopup
