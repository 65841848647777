import React, { useCallback, useContext, useState } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmAreYourSure from '../../../../../general/popup/ConfirmAreYourSure';
import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
// import { makeRequest } from '../../../../../../apiCaller';
import { LOCAL_GTM_ACCESS_TOKEN, LOCAL_GTM_USER_EMAIL } from '../../../../../../constants';
import { setShowCreateAccountPopup } from '../../../../../../actions/subscriber';

const GTMConnection = () => {
    const dispatch = useDispatch();
    const { account, stepsData, prevSteps, setCurrentStep } = useContext(CreateAccountContext);
    const [showPopup, setShowPopup] = useState(false);
    const isFromGetStarted = useSelector(state => state.subscriber.isFromGetStarted);

    const toggleShowPopup = useCallback(() => {
        setShowPopup(s => !s)
    }, []);

    const onBackBtnClicked = () => {
        if (!isFromGetStarted || !account.reachTheEndOfSetup) {
            toggleShowPopup();
            return;
        }

        dispatch(setShowCreateAccountPopup(false));
    };

    const acceptGoBack = () => {
        setCurrentStep(STEPS.domain);
    };

    const onPlacingScriptManually = () => {
        setCurrentStep(STEPS.placeScriptManually);
    };

    const handleGTMAuthentication = () => {
        localStorage.removeItem(LOCAL_GTM_ACCESS_TOKEN);
        localStorage.removeItem(LOCAL_GTM_USER_EMAIL);
        const scopes = [
            'profile',
            'email',
            'https://www.googleapis.com/auth/tagmanager.edit.containers',
            'https://www.googleapis.com/auth/tagmanager.publish',
            'https://www.googleapis.com/auth/tagmanager.manage.users',
            'https://www.googleapis.com/auth/tagmanager.edit.containerversions'
        ]
        const url = process.env.REACT_APP_GOOGLE_OAUTH2_URL;
        let stateObj = { accountId: account.id, isFromGetStarted };

        if (stepsData.defaultPackageId) {
            stateObj.defaultPackageId = stepsData.defaultPackageId;
        }

        if (!isFromGetStarted) {
            stateObj.stepsData = { ...stepsData, selectedGTMContainer: null, gtmWorkspace: null, countryTimeZones: [], timeZoneLabel: '' };
            stateObj.prevSteps = prevSteps;
        }

        const state = btoa(encodeURIComponent(JSON.stringify(stateObj)));

        const options = {
            redirect_uri: window.location.origin,
            response_type: 'code',
            access_type: 'offline',
            scope: scopes.join(' '),
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            prompt: 'consent', // https://stackoverflow.com/a/14393492
            flowName: 'GeneralOAuthFlow',
            state
        }

        let params = '';

        Object.entries(options).forEach(([key, value], index) => {
            if (index === 0) {
                params = `${params}${key}=${value}`;
            } else {
                params = `${params}&${key}=${value}`;
            }
        })

        const fullURL = `${url}?${params}`;
        // window.open(fullURL, "", "width=640,height=699")
        const urlToPush = `${window.location.pathname}?state=${state}&isBackFromGTM=true`;
        window.history.pushState({}, '', urlToPush);
        window.location.href = fullURL;
    }

    const connectToGTM = () => {
        handleGTMAuthentication();
        // const accessToken = localStorage.getItem(LOCAL_GTM_ACCESS_TOKEN);

        // if(accessToken) {
        //     // Check access token expiration time
        //     makeRequest(`${API_GOOGLE_TOKEN_INFO}?access_token=${accessToken}`, 'GET', null)
        //         .then(response => {
        //             console.log('response', response)
        //             if (response.status === 200) {
        //                 const { expires_in } = response.data;

        //                 if (expires_in > 300) {
        //                     setCurrentStep(STEPS.gtmSelectContainer);
        //                 } else {
        //                     handleGTMAuthentication();
        //                 }
        //             } else {
        //                 handleGTMAuthentication();
        //             }
        //         });
        // } else {
        //     handleGTMAuthentication();
        // }
    };

    return (
        <CreateAccountBody onBackBtnClicked={onBackBtnClicked}>
            <CCard className="account-gtm connect">
                <p className="number-of-step">2 of 3 steps</p>
                <CCardBody>
                    <div className="icon-gtm">
                        <CIcon name="iconGTM" width={50} height={50} />
                    </div>
                    <h3>Connect to Google Tag Manager</h3>
                    <p>
                        ListenLayer pushes structured data into the dataLayer, which is read by GTM. Let's connect to your GTM account so we can
                        quickly place your ListenLayer script.
                    </p>
                    {/* <span className="foot-note foot-note__mb ">
                        Sign in with Google to connect to GTM
                    </span> */}
                    <CButton className="btn-save btn-connect-gtm" onClick={connectToGTM}>
                        <CIcon name="googleLightNormal" width={18} height={18} />
                        Sign in with Google
                    </CButton>
                    <span className="foot-note">
                        This opens a window to authenticate your GTM account. You'll have a chance to review any changes we propose.{" "}
                        Learn more about our <a href="https://www.listenlayer.com/google-oauth-google-tag-manager/" target="_blank" rel="noopener noreferrer">Google Integration</a>{" "}
                        and our <a href="https://www.listenlayer.com/privacy-policy/#special-applications" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                    </span>
                    <CButton className="btn-navigation-step" onClick={onPlacingScriptManually}>
                        I'll place the script manually<i className="fal fa-angle-right"></i>
                    </CButton>
                </CCardBody>
            </CCard>
            <ConfirmAreYourSure
                show={showPopup}
                onClose={toggleShowPopup}
                onAccept={acceptGoBack}
                btnTextSave="YES, TAKE ME BACK"
                btnTextCancel="NO, DO NOT GO BACK"
            />
        </CreateAccountBody>
    );
};

export default GTMConnection;
