import React from 'react';
import DatePicker from "react-datepicker";
import { CSelect } from '@coreui/react';
import PropTypes from 'prop-types';

import "react-datepicker/dist/react-datepicker.css";
import { TODAY } from '../../../constants';

const filterOptions = [
     { value: '', text: 'Select an option' },
     { value: 'eq', text: 'is' },
     { value: 'neq', text: 'is not' },
     { value: 'lt', text: 'is less than' },
     { value: 'lte', text: 'is less than or equals to' },
     { value: 'gt', text: 'is greater than' },
     { value: 'gte', text: 'is greater than or equals to' },
]

const DateFilter = ({ filterData, onSelectOperatorChange, onDateFilterChange }) => {
     if (filterData.key === "activity_created_at" || filterData.key === "activity_updated_at") {
          if (filterData.value === null) {
               filterData.value = TODAY;
          }
     }
     return (
          <>
               <CSelect
                    name={filterData.key}
                    className="mb-1"
                    onChange={onSelectOperatorChange}
                    value={filterData.operator}
               >
                    {filterOptions.map(option => (
                         <option key={option.text} className="text-dark" value={option.value}>{option.text}</option>
                    ))}
               </CSelect>
               <DatePicker
                    dateFormat="yyyy-MM-dd"
                    className="form-control form-control-sm"
                    selected={new Date(filterData.value)} // Must be date object
                    onChange={date => onDateFilterChange(filterData.key, date)}
               />
          </>
     )
}

DateFilter.propTypes = {
     filterData: PropTypes.object,
     onSelectOperatorChange: PropTypes.func,
     onDateFilterChange: PropTypes.func
}

export default React.memo(DateFilter);

