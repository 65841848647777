import React, { useContext, useEffect, useState } from 'react';
import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody, CForm, CFormGroup, CInput, CSpinner } from '@coreui/react';
import { Formik } from 'formik';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import { API_CLIENT_ACCOUNT_DESTINATIONS } from '../../../../../../constants';
import { callTokenApi } from '../../../../../../apiCaller';
import { getSessionKeySignupDestinations, toastError } from '../../../../../../utils';
import Waiting from '../../../../../general/Loadings/Waiting';
import { getListenerColorIcon } from '../../../../../../helpers/cms/subscriber';
import { useScrollFaded } from '../../../../../../helpers/customHooks';
import useSaveCurrentStep from '../useSaveCurrentStep';
import useSaveListenerProgress from '../listener-configuration/useSaveListenerProgress';
import { useListeners } from '../listener-configuration/ConfigureMeasurement';
import uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';

const useDestinations = () => {
     const { account, setAccount, setStepsData } = useContext(CreateAccountContext);
     const activeAccount = account.id;
     const [isLoading, setIsLoading] = useState(true)
     const handleFirstLoad = () => {
          const fetchDestinations = async () => {
               let newDestinations
               if (!newDestinations || (Array.isArray(newDestinations) && newDestinations.length === 0)) {
                    await callTokenApi(`${API_CLIENT_ACCOUNT_DESTINATIONS}${activeAccount}`, 'GET', null)
                         .then(response => {
                              if (response.status === 200) {
                                   newDestinations = response.data.accountDestinations;
                              } else {
                                   toastError(response);
                              }
                         })
               }
               let newStepsData = { destinations: newDestinations }
               setIsLoading(false)
               setStepsData(newStepsData);
          }
          fetchDestinations();

          const newAccount = {
               ...account,
               completedSteps: uniq([...account.completedSteps, STEPS.configureMeasurement]),
          };

          setAccount(newAccount);
     };
     useEffect(handleFirstLoad, []);

     return isLoading
};

const ChooseDestination = () => {
     const { account, stepsData, setCurrentStep, setStepsData, updateAccountCompletedSteps } = useContext(CreateAccountContext);
     const { destinations, allSelectedDestinations, selectedListenerTopics } = stepsData;

     const [searchText, setSearchText] = useState('');
     const isLoading = useDestinations()
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const { reachTheEndOfSetup } = activeAccount

     useListeners()

     const initialValues = {
          selectedDestinations: allSelectedDestinations || []
     }

     const comingSoonDestinationAlias = ['zapier', 'slack', 's3', 'google-tag-manager'];
     const listDestinations = destinations ? destinations.filter(item => !comingSoonDestinationAlias.includes(item.alias)) : []
     let destinationsToDisplay = listDestinations.filter(destination => destination.name.toLowerCase().includes(searchText.toLowerCase()));
     const { containerListClass, onScrollContainerList } = useScrollFaded({ hasScroll: destinationsToDisplay.length > 9 });
     const checkIfChecked = (selectedDestinations, destination) => {
          return !!selectedDestinations.find(selectedDestinations => selectedDestinations.id === destination.id);
     }

     const onBackBtnClicked = () => {
          if (selectedListenerTopics.length > 0) {
               setCurrentStep(selectedListenerTopics[selectedListenerTopics.length - 1]);
          }
          else {
               setCurrentStep(null, CHANGE_STEP_TYPE.mainStepPrev);
          }
     };

     const onSubmit = (values) => {
          updateAccountCompletedSteps(STEPS.chooseDestination)
          const destination = values.selectedDestinations
          const sessionData = JSON.stringify(destination);
          sessionStorage.setItem(getSessionKeySignupDestinations(account.id), sessionData);
          setStepsData({ allSelectedDestinations: values.selectedDestinations })

          if (reachTheEndOfSetup && account.id === activeAccount.id) {
               setCurrentStep(STEPS.configuringAccount)
          }
          else {
               setCurrentStep(null, CHANGE_STEP_TYPE.mainStepNext);
          }

     }

     useSaveCurrentStep();
     useSaveListenerProgress();

     return (
          <Formik
               initialValues={initialValues}
               onSubmit={onSubmit}
          >
               {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
                    const handleSelect = (destination) => {
                         let newSelectedDestinations = [...values.selectedDestinations];
                         const foundDestinationIndex = values.selectedDestinations.findIndex(selectedDestination => selectedDestination.id === destination.id);

                         if (foundDestinationIndex > -1) {
                              newSelectedDestinations.splice(foundDestinationIndex, 1);
                         } else {
                              newSelectedDestinations.push(destination);
                         }
                         const newDestination = allSelectedDestinations ? [...allSelectedDestinations, destination] : [destination]

                         setStepsData({ allSelectedDestinations: newDestination })
                         setFieldValue('selectedDestinations', newSelectedDestinations);
                    };

                    const handleCreateDestination = () => {
                         setCurrentStep(STEPS.customDestination)
                    }

                    return (
                         <CreateAccountBody onBackBtnClicked={onBackBtnClicked}>
                              <CCard className='account-form'>
                                   <CCardBody>
                                        <CForm onSubmit={handleSubmit}>
                                             <CFormGroup>
                                                  <h3>Choose Your Destinations</h3>
                                                  <p>Select the destinations you're interested in sending data to. We'll add them to your implementation road map</p>
                                                  <div className='filter-search'>
                                                       <CInput
                                                            name='search'
                                                            className='search'
                                                            type='text'
                                                            placeholder='Enter destination name to filter'
                                                            value={searchText}
                                                            onChange={(e) => setSearchText(e.target.value)}
                                                            data-lpignore='true'
                                                       />
                                                       <CIcon name='iconFilterSearch' height={16} />
                                                  </div>
                                                  <div className={`forms-listener `} >
                                                       <div className={`list-form ${containerListClass}`} onScroll={onScrollContainerList}>
                                                            {destinationsToDisplay.length > 0 ? (
                                                                 destinationsToDisplay.map((destination) => {
                                                                      const iconSrc = getListenerColorIcon(destination)
                                                                      return (
                                                                           <label key={destination.id}>
                                                                                <input
                                                                                     type='checkbox'
                                                                                     checked={checkIfChecked(values.selectedDestinations, destination)}
                                                                                     onChange={() => handleSelect(destination)}
                                                                                     disabled={isSubmitting}
                                                                                />
                                                                                <div className='icon-box'>
                                                                                     <div className='tickmark'></div>
                                                                                     <img className='listener-logo w-auto' src={iconSrc} alt={`${destination.name} Icon`} />
                                                                                     <span>{destination.name}</span>
                                                                                </div>
                                                                           </label>
                                                                      )
                                                                 })
                                                            ) : (
                                                                 isLoading ? <CSpinner color='primary' /> : <p className='item-not-found'>No items found.</p>
                                                            )}
                                                       </div>
                                                  </div>
                                                  <CButton className='btn-save' type='submit' disabled={isSubmitting}>
                                                       <Waiting isLoading={isSubmitting}>NEXT</Waiting>
                                                  </CButton>
                                                  <div className='notification'>
                                                       <div className='custom-form-group mb-2'>
                                                            <h4 className='m-0'>Create a Custom Destination</h4>
                                                            <div>
                                                                 <CButton className='btn-primary' onClick={handleCreateDestination}>CREATE</CButton>
                                                            </div>
                                                       </div>
                                                       <p className='mb-4'>
                                                            Build a custom destination if our library is missing a destination you need. This will allow you to run any javascript tag on triggers, conversions, or page views that you choose while keeping your work organized.
                                                       </p>
                                                  </div>
                                             </CFormGroup>
                                        </CForm>
                                   </CCardBody>
                              </CCard>
                         </CreateAccountBody>
                    );
               }}
          </Formik>
     );
};

export default ChooseDestination;
