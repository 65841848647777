import React, { useCallback } from 'react';
import { COMPONENT_NAME } from '../../../constants';
import RecordProfileUser from '../../../components/cms/subscriber/analytics/record-profile/UserDetail';
import RecordProfileSession from '../../../components/cms/subscriber/analytics/record-profile/SessionDetail';
import RecordProfileEvent from '../../../components/cms/subscriber/analytics/record-profile/EventDetail';
import RecordProfileEventECommerce from '../../../components/cms/subscriber/analytics/record-profile/EventECommerceDetail';
import RecordProfilePerson from '../../../components/cms/subscriber/analytics/record-profile/PersonDetail';
import RecordProfileCompany from '../../../components/cms/subscriber/analytics/record-profile/CompanyDetail';
import RecordProfileForm from '../../../components/cms/subscriber/analytics/record-profile/FormDetail';

const ContentChild = ({ component, attributes }) => {
     const getComponentFlexibleMultiModal = useCallback(() => {
          switch (component) {
               case COMPONENT_NAME.RECORD_PROFILE_USER:
                    return <RecordProfileUser id={attributes.id} linkedData={attributes.data} />;
               case COMPONENT_NAME.RECORD_PROFILE_SESSION:
                    return <RecordProfileSession id={attributes.id} linkedData={attributes.data} />;
               case COMPONENT_NAME.RECORD_PROFILE_EVENT:
                    return <RecordProfileEvent id={attributes.id} />;
               case COMPONENT_NAME.RECORD_PROFILE_EVENT_E_COMMERCE:
                    return <RecordProfileEventECommerce id={attributes.id} />;
               case COMPONENT_NAME.RECORD_PROFILE_PERSON:
                    return <RecordProfilePerson id={attributes.id} linkedData={attributes.data} />;
               case COMPONENT_NAME.RECORD_PROFILE_COMPANY:
                    return <RecordProfileCompany id={attributes.id} linkedData={attributes.data} />;
               case COMPONENT_NAME.RECORD_PROFILE_FORM:
                    return <RecordProfileForm id={attributes.id} locationForm={attributes.locationForm} />;
               default:
                    return <></>;
          }
     }, [component, attributes]); // eslint-disable-line react-hooks/exhaustive-deps

     return <>{getComponentFlexibleMultiModal()}</>;
};
export default ContentChild;
