import { useEffect, useState } from 'react';
import { API_CLIENT_REPORTING_CONVERSION_NAME, REPORT_NAMES } from '../../../../constants';
import { callTokenApi } from '../../../../apiCaller';
import { useSelector } from 'react-redux';
// import { handleGetOption } from './EventExplorer';

const useConversionName = (props) => {
     const {
          isConversionNameRate,
          isConversionName,
          isConversionNameValue,
          setConversionName,
          setConversionNameMetric,
          reportName,
     } = props;
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const [conversionNameLoading, setConversionNameLoading] = useState(true);
     const bodyData = {
          accountId: activeAccount.id,
          isConversionName,
          isConversionNameRate,
          isConversionNameValue,
     };

     let conversionNameMetric = [];
     const conversionNameRateMetric = [];
     const conversionName = [];
     const conversionNameValue = [];

     const fetchData = () => {
          if (isConversionName) {
               callTokenApi(API_CLIENT_REPORTING_CONVERSION_NAME, 'POST', bodyData)
                    .then((response) => {
                         if (response && response.status === 200) {
                              const conversionMetric = response.data.conversionName;
                              const { eCommerceName } = response.data
                              conversionMetric.forEach((item) => {
                                   conversionNameMetric.push({ key: item.value, label: item.label, type: item.type });
                                   conversionName.push({ label: item.label, value: item.value, type: item.type });
                              });

                              if (reportName !== REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT) {

                                   const conversionValueMetric = response.data.conversionNameValue;
                                   conversionValueMetric.forEach((item) => {
                                        conversionNameMetric.push({key: item.value, label: item.label})
                                        conversionName.push({label: item.label, value: item.value, convId: item.convId})
                                   });

                                   eCommerceName.forEach((item) => {
                                        conversionNameMetric.push({ key: item.value, label: item.label, type: item.type });
                                        conversionName.push({ label: item.label, value: item.value, type: item.type });
                                   });
                              }

                              if (isConversionNameRate) {
                                   const conversionRateMetric = response.data.conversionNameRate;
                                   conversionRateMetric.forEach((item) => {
                                        conversionNameRateMetric.push({ key: item.value, label: item.label, type: item.type });
                                   });
                              }
                              if (isConversionNameValue) {
                                   const conversionValueMetric = response.data.conversionNameValue;
                                   conversionValueMetric.forEach((item) => {
                                        conversionNameValue.push({ key: item.value, label: item.label, type: item.type });
                                   });
                              }

                              conversionNameMetric = [...conversionNameMetric, ...conversionNameRateMetric, ...conversionNameValue];
                         }
                    })
                    .finally(() => {
                         setConversionNameMetric(conversionNameMetric);
                         setConversionName(conversionName);
                         // setFilterMetric(handleGetOption({ option: [...metrics] }));
                         // setFilterConversionName(handleGetOption({ option: [...metrics] }));
                         setConversionNameLoading(false);
                    });
          } else {
               setConversionNameMetric([]);
               setConversionName([]);
               // setFilterConversionName(handleGetOption({ option: [...metrics]}));
               // setFilterMetric(handleGetOption({ option: [...metrics, ...[]] }));
               setConversionNameLoading(false);
          }
     };

     useEffect(fetchData, []);

     return conversionNameLoading;
};

export default useConversionName;
