import {
     PUSHER_NEW_TICKET,
     GET_TICKETS,
     PUSHER_NEW_COMMENT,
     SET_TICKET_DETAIL,
     RESET_NUMBER_COMMENT,
     SET_NUMBER_OF_TICKETS,
     SUPPORT_SET_ACCOUNTS,
     UPDATE_STATUS_TICKET
} from '../actions/types';

import { supportPusher } from '../helpers/cms/support';

const INITIAL_STATE = {
     ticketLoading: false,
     tickets: [],
     ticket: {
          comments: []
     },
     numberOfTickets: 0,
     accounts: [],
}

export default (state = INITIAL_STATE, action) => {
     switch (action.type) {
          case GET_TICKETS:
               return { ...state, tickets: action.payload };
          case SUPPORT_SET_ACCOUNTS:
               return { ...state, accounts: action.payload };
          case SET_NUMBER_OF_TICKETS:
               return { ...state, numberOfTickets: action.payload };
          case PUSHER_NEW_TICKET:
               let newTicket = [...state.tickets];
               action.payload.isNew = true;
               newTicket.unshift(action.payload);
               return { ...state, tickets: newTicket };
          case PUSHER_NEW_COMMENT:
               return { ...state, ...supportPusher.setNewComment(state, action.payload) };
          case SET_TICKET_DETAIL:
               return { ...state, ticket: action.payload };
          case RESET_NUMBER_COMMENT:
               return { ...state, ...supportPusher.resetNumberCommnet(state, action.payload) };
          case UPDATE_STATUS_TICKET:
               return { ...state, ...supportPusher.updateStatusTicket(state, action.payload) };
          default:
               return state;
     }
}