import React, { useState } from 'react';
import get from 'lodash/get';
import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ConfirmRemovePopup, ConfirmSaveChange, InstructionPopup } from '../popup';
import RuleCard from './RuleCard';
import RuleCardDefault from './RuleCardDefault';
import { HoverInfoIconTooltip } from '../custom-tooltips';
import { useActiveFeatureV2 } from '../../../helpers/customHooks';
import {
     setBrowserRules,
     setClickRules,
     setConsentRules,
     setCustomVariableRules,
     setPopulateRules,
     setUserSourceRules,
} from '../../../actions/subscriber';
import CenterSpinner from '../Loadings/CenterSpinner';

const ListRules = ({
     rules,
     allRules,
     fetchLoading,
     defaultRules = null,
     defaultRuleDescription = null,
     initialRules = null,
     handleDelete = null,
     onClickCreate = null,
     onClickEdit = null,
     handleClickDelete = null,
     handleToggleStatus = null,
     handleSaveReorderRules = null,
     showDragDropGuide = true,
     featureType = '',
     saveLoading,
     additionalActionComponent,
     hideNoRuleMessage = true,
     // allowCreate = true,
     // allowEdit = true,
     setMenuIsOpen
}) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const activeFeature = useActiveFeatureV2(featureType);

     const [modal, setModal] = useState(false);
     const [newOrder, setNewOrder] = useState();
     const [tempRule, setTempRule] = useState({});
     const [reorderModal, setReorderModal] = useState(false);
     const [saveOrderBtn, setSaveOrderBtn] = useState(false);
     const [toggleStatusModal, setToggleStatusModal] = useState(false);
     const [instructionPopupContent, setInstructionPopupContent] = useState(null);

     const onClickDelete = (index) => {
          setModal(true);
          setTempRule(rules[index]);
     };

     const onToggleStatus = (index) => {
          setToggleStatusModal(true);
          setTempRule(rules[index]);
     };

     const onClickSaveOrderChange = () => {
          const dataSave = {
               accountId: activeAccount.id,
               rulesOrder: [],
          };

          if (initialRules && initialRules.length > 0) {
               rules.forEach((rule, index) => {
                    if (initialRules[index]) {
                         if (initialRules[index].order) {
                              dataSave.rulesOrder.push({
                                   id: rule.id,
                                   order: initialRules[index].order,
                              });
                         } else {
                              dataSave.rulesOrder.push({
                                   id: rule.id,
                                   ruleOrder: initialRules[index].ruleOrder,
                              });
                         }
                    }
               });
          }
          setNewOrder(dataSave);
          setReorderModal(true);
     };

     const reorder = (list, startIndex, endIndex) => {
          const result = Array.from(list);
          const [removed] = result.splice(startIndex, 1);
          result.splice(endIndex, 0, removed);

          return result;
     };

     const onDragStart = () => {
          if (setMenuIsOpen) {
               setMenuIsOpen(false);
          }
     };

     const onDragEnd = (result) => {
          if (!result.destination) {
               return;
          }

          if (result.destination.index === result.source.index) {
               return;
          }

          const newReorderRules = reorder(rules, result.source.index, result.destination.index);

          let newRules = [];

          if (defaultRules && defaultRules.length > 0) {
               newRules = [...newReorderRules, ...defaultRules];
          } else if (allRules && allRules.length > 0) {
               const listRuleIds = rules.map((el) => el.id);
               const allRulesFilter = allRules.filter((rule) => !listRuleIds.includes(rule.id));
               newRules = [...allRulesFilter, ...newReorderRules];
          } else {
               newRules = newReorderRules;
          }

          setSaveOrderBtn(true);
          switch (featureType) {
               case 'consent':
                    dispatch(setConsentRules(newRules));
                    break;
               case 'storage':
                    dispatch(setBrowserRules(newRules));
                    break;
               case 'customization':
                    dispatch(setCustomVariableRules(newRules));
                    break;
               case 'user sources':
                    dispatch(setUserSourceRules(newRules));
                    break;
               case 'populate':
                    dispatch(setPopulateRules(newRules));
                    break;
               case 'event':
                    dispatch(setClickRules(newRules));
                    break;
               default:
                    break;
          }
     };

     const noRuleMessage = () => {
          switch (featureType) {
               case 'consent':
                    return 'There are no consent rules.';

               default:
                    return 'There are no rules for this Listener.';
          }
     };

     return (
          <>
               {rules && rules.length > 1 && handleSaveReorderRules && showDragDropGuide && (
                    <div className='drag-drop-guide'>
                         <CIcon name='iconStraightFlag' height={12} />
                         <strong>Drag and drop your rules to change the order in which they apply. Rules are read from top to bottom until a match is found.</strong>
                    </div>
               )}

               {fetchLoading ? (
                    <CenterSpinner />
               ) : (
                    <div className='list-rules'>
                         {rules && rules.length > 0 ? (
                              <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                                   <Droppable droppableId='list'>
                                        {(provided) => (
                                             <div ref={provided.innerRef} {...provided.droppableProps} className='moveable-rules'>
                                                  {rules.map((rule, index) => {
                                                       return (
                                                            <React.Fragment key={index}>
                                                                 <RuleCard
                                                                      rule={rule}
                                                                      index={index}
                                                                      onClickDelete={() => {
                                                                           if (handleClickDelete) {
                                                                                handleClickDelete(index);
                                                                           } else {
                                                                                onClickDelete(index);
                                                                           }
                                                                      }}
                                                                      onToggleStatus={onToggleStatus}
                                                                      additionalActionComponent={additionalActionComponent}
                                                                      // allowEdit={allowEdit}
                                                                      onClickEdit={() => onClickEdit(rule.id, rule.type)}
                                                                 />
                                                            </React.Fragment>
                                                       );
                                                  })}
                                                  {provided.placeholder}
                                             </div>
                                        )}
                                   </Droppable>
                              </DragDropContext>
                         ) : (
                              <>{!hideNoRuleMessage && <div className='no-rule text-center'>{noRuleMessage()}</div>}</>
                         )}
                    </div>
               )}

               {defaultRules && defaultRules.length > 0 && (
                    <div className='moveable-rules default-rules'>
                         <div className='headline'>
                              <h5>System Default Rules</h5>
                              {defaultRuleDescription && (
                                   <div className='tooltip-wrapper'>
                                        <HoverInfoIconTooltip>
                                             <p>{defaultRuleDescription}</p>
                                        </HoverInfoIconTooltip>
                                   </div>
                              )}
                         </div>
                         {defaultRules.map((rule) => {
                              return (
                                   <React.Fragment key={rule.id}>
                                        <RuleCardDefault
                                             rule={rule}
                                             // allowEdit={allowEdit}
                                             onClickEdit={() => onClickEdit(rule.id, rule.type)}
                                        />
                                   </React.Fragment>
                              );
                         })}
                    </div>
               )}

               {/* {allowCreate && ( */}
               <div className='drag-drop-actions d-flex align-items-center justify-content-start'>
                    {saveOrderBtn === true && (
                         <CButton className='btn-save-change' color='success' onClick={onClickSaveOrderChange}>
                              <CIcon name='icon-check' />
                              SAVE ORDER
                         </CButton>
                    )}

                    <CButton className='btn-create-rule' color='primary' onClick={onClickCreate}>
                         <i className='fal fa-plus mr-1'></i>
                         CREATE A NEW RULE
                    </CButton>

                    {get(activeFeature, 'featureContent.ruleInstruction.isOn') && (
                         <CButton
                              className='learn-how-to-write'
                              color='link'
                              onClick={
                                   activeFeature.featureContent.ruleInstruction.popup
                                        ? () => setInstructionPopupContent(activeFeature.featureContent.ruleInstruction.popup)
                                        : null
                              }
                         >
                              {activeFeature.featureContent.ruleInstruction.buttonText
                                   ? activeFeature.featureContent.ruleInstruction.buttonText
                                   : 'Learn how to write rules'}
                         </CButton>
                    )}
                    {['consent', 'triggers', 'conversions'].includes(featureType) && (
                         <CButton className='learn-how-to-write' color='link'>
                              Learn how to write rules
                         </CButton>
                    )}
               </div>
               {/* )} */}

               <InstructionPopup show={!!instructionPopupContent} onClose={() => setInstructionPopupContent(null)} {...instructionPopupContent} />

               {handleDelete && (
                    <ConfirmRemovePopup
                         show={modal}
                         onAccept={() => {
                              setModal(false);
                              handleDelete(tempRule);
                         }}
                         onClose={() => {
                              setModal(false);
                              setTempRule({});
                         }}
                         isLoading={saveLoading}
                    >
                         <p>
                              You are about to delete the rule <strong>"{tempRule ? tempRule.name : ''}"</strong>. Please note{' '}
                              <span className='text-danger'>this action is irreversible</span>.
                         </p>
                    </ConfirmRemovePopup>
               )}

               {handleToggleStatus && (
                    <ConfirmSaveChange
                         show={toggleStatusModal}
                         onAccept={() => {
                              setToggleStatusModal(false);
                              handleToggleStatus(tempRule);
                         }}
                         onClose={() => {
                              setToggleStatusModal(false);
                              setTempRule({});
                         }}
                         isLoading={saveLoading}
                         title='Are you sure?'
                    >
                         <p>
                              You are about to {tempRule && tempRule.status ? 'pause' : 'active'} the rule <strong>"{tempRule ? tempRule.name : ''}"</strong>.
                         </p>
                    </ConfirmSaveChange>
               )}

               {handleSaveReorderRules && (
                    <ConfirmSaveChange
                         show={reorderModal}
                         onAccept={() => {
                              setReorderModal(false);
                              setSaveOrderBtn(false);
                              handleSaveReorderRules(newOrder);
                         }}
                         onClose={() => setReorderModal(false)}
                         isLoading={saveLoading}
                         title='Are you sure you want to save?'
                    >
                         Change order list {featureType} rules.
                    </ConfirmSaveChange>
               )}
          </>
     );
};

export default ListRules;
