import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react';
import React, { useState } from 'react';
import Headline from '../../../../../../general/Headline';

const CreateStepRuleName = React.lazy(() => import('./steps/CreateStepRuleName'));
const CreateStepSelectConditionFields = React.lazy(() => import('./steps/CreateStepSelectConditionFields'));

const CreateNewFormRuleSteps = () => {
     const [stepsData, setStepsData] = useState({
          name: '',
          description: '',
          conditions: [],
          customVariables: [[{ id: '' }]],
          group: 'formRule',
     });

     return (
          <>
               <CreateStepRuleName stepsData={stepsData} setStepsData={setStepsData} />
               <CreateStepSelectConditionFields stepsData={stepsData} setStepsData={setStepsData} />
          </>
     );
};

export const FormRuleContext = React.createContext({});

const CreateNewFormRule = () => {
     const [modal, setModal] = useState(false);
     const [activeStep, setActiveStep] = useState(2);

     return (
          <div className="rule-detail">
               <Headline title="Create New Form Rule">
                    <p>Write rules to push custom data into your data layer.</p>
               </Headline>

               <div className="cvr-create-new custom-rule">
                    <FormRuleContext.Provider value={{ activeStep, setActiveStep }}>
                         <CreateNewFormRuleSteps />
                    </FormRuleContext.Provider>

                    <CModal show={modal} centered={true} onClose={() => setModal(false)}>
                         <CModalBody className="text-center">
                              <h3 className="mb-4">Warning! This Will Change Your Rule</h3>
                              <p>
                                   You are about to change your rule type. Doing this will reset and remove all settings inside this rule other than
                                   the name and description.
                              </p>
                              <p>Are you sure you want to do this? You cannot undo this once you publish it!</p>
                         </CModalBody>
                         <CModalFooter className="text-center justify-content-center">
                              <CButton color="primary" className="mr-3 text-uppercase" onClick={() => setModal(false)}>
                                   Cancel
                              </CButton>
                              <CButton color="secondary" onClick={() => { }}>
                                   Save Change and Reset Rule
                              </CButton>
                         </CModalFooter>
                    </CModal>
               </div>
          </div>
     );
};

export default CreateNewFormRule;
