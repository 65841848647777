import CIcon from "@coreui/icons-react";
import {
     CSidebar
} from '@coreui/react';
import className from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // useSelector & useDispatch can only be used in functional component

import { setAsideShow } from '../../../actions/common';
import { setLimitationType, setMaxPackage, setNextPackageIndex, setPackages } from '../../../actions/subscriber';
import { callTokenApi } from '../../../apiCaller';
import CenterSpinner from '../../../components/general/Loadings/CenterSpinner';
import PublishBeforeDowngradePopup from '../../../components/general/popup/PublishBeforeDowngradePopup';
import { CLIENT_PACKAGE, LIMITATION_TYPE, LOCAL_TEMP_LIMITATION } from '../../../constants';
import { toastError } from '../../../utils';
import ActionRequired from './ActionRequired';
import ComparePlans from './ComparePlans';
import DowngradeConfirmation from './DowngradeConfirmation';
import SlideOutPayment from './SlideOutPayment';
import UpgradeRequired from './UpgradeRequired';
import LimitationHit from './limitation-hit/LimitationHit';

export const AsideContext = React.createContext({});

export const ASIDE_COMPONENTS = {
     LIMITATION_HIT: 'LimitationHit',
     SLIDE_OUT_PAYMENT: 'SlideOutPayment',
     COMPARE_PLANS: 'ComparePlan',
     DOWNGRADE_CONFIRMATION: 'DowngradeConfirmation',
     ACTION_REQUIRED: 'ActionRequired',
     UPGRADE_REQUIRED: 'UpgradeRequired'
}

const usePackages = () => {
     const dispatch = useDispatch();
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const [packagesFetching, setPackagesFetching] = useState(false);
     const packages = useSelector(state => state.subscriber.packages);

     const fetchNextPackageIndex = () => {
          if (packages.length > 0) {
               // Need to check package price per month due to accounts with free package version 1
               if (activeAccount.packagePricePerMonth === 0) {
                    dispatch(setNextPackageIndex(packages.findIndex(pk => pk.pricePerMonth !== 0)));
                    dispatch(setMaxPackage(false));
                    return;
               }

               packages.some((element, index) => {
                    // Need to check friendly name due to accounts with package version 1
                    if (element.id === activeAccount.packageId || (activeAccount.packageVersion === 1 && element.friendlyName === activeAccount.packageFriendlyName)) {
                         if (index === packages.length - 1) {
                              dispatch(setMaxPackage(true));
                         } else {
                              dispatch(setNextPackageIndex(index + 1));
                              dispatch(setMaxPackage(false));
                         }
                         return true;
                    }
                    return false;
               });
          }
     }

     const fetchPackages = () => {
          if (packages.length === 0) {
               setPackagesFetching(true);

               callTokenApi(CLIENT_PACKAGE, 'GET', null)
                    .then(response => {
                         if (response.status === 200) {
                              dispatch(setPackages(response.data.packages));
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => setPackagesFetching(false))
          }
     }

     useEffect(fetchPackages, []);
     useEffect(fetchNextPackageIndex, [packages, activeAccount]);

     return packagesFetching;
}

const SubscriberASide = () => {
     const dispatch = useDispatch();
     const asideShow = useSelector(state => state.theme.asideShow);
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const accountsLoading = useSelector(state => state.subscriber.loadings.accounts);
     const limitationType = useSelector(state => state.subscriber.limitationType);
     const asideLimitationHit = useSelector(state => state.subscriber.asideLimitationHit);
     const [currentComponents, setCurrentComponents] = useState(ASIDE_COMPONENTS.LIMITATION_HIT);
     const [packageSelected, setPackageSelected] = useState(null); // Package user selected on compare plans
     const [tempPackage, setTempPackage] = useState(null);
     const [showPublishBeforeDowngradePopup, setShowPublishBeforeDowngradePopup] = useState(false);
     const packagesFetching = usePackages();
     const asideFullScreenComponents = [ASIDE_COMPONENTS.COMPARE_PLANS, ASIDE_COMPONENTS.DOWNGRADE_CONFIRMATION, ASIDE_COMPONENTS.ACTION_REQUIRED, ASIDE_COMPONENTS.UPGRADE_REQUIRED];
     const isFullScreen = asideFullScreenComponents.includes(currentComponents);;
     const sidebarClassNames = className(
          'side-bar',
          { 'compare-plans': asideFullScreenComponents.includes(currentComponents) },
     );

     const resetPackageSelected = useCallback(() => {
          if (packageSelected) {
               setPackageSelected(null);
          }
     }, [packageSelected])

     const resetComponentLimitationHit = useCallback(() => {
          if (currentComponents !== ASIDE_COMPONENTS.LIMITATION_HIT) {
               // Show Limitation Hit
               setCurrentComponents(ASIDE_COMPONENTS.LIMITATION_HIT);
          }
     }, [currentComponents])

     useEffect(resetPackageSelected, [limitationType]);

     // Every action to show the aside will show Limitation Hit component
     // except showing aside from clicking hamburger icon
     useEffect(resetComponentLimitationHit, [asideLimitationHit]);

     const getCurrentComponents = () => {
          switch (currentComponents) {
               case ASIDE_COMPONENTS.LIMITATION_HIT:
                    return <LimitationHit />;
               case ASIDE_COMPONENTS.SLIDE_OUT_PAYMENT:
                    return <SlideOutPayment />;
               case ASIDE_COMPONENTS.COMPARE_PLANS:
                    return <ComparePlans />;
               case ASIDE_COMPONENTS.DOWNGRADE_CONFIRMATION:
                    return <DowngradeConfirmation />;
               case ASIDE_COMPONENTS.ACTION_REQUIRED:
                    return <ActionRequired onClose={toggleSidebar} />;
               case ASIDE_COMPONENTS.UPGRADE_REQUIRED:
                    return <UpgradeRequired onClose={toggleSidebar} />;
               default:
                    return <></>
          }
     }

     const resetWhenChangingAccount = () => {
          resetPackageSelected();
          setCurrentComponents(ASIDE_COMPONENTS.LIMITATION_HIT);
     }

     useEffect(resetWhenChangingAccount, [activeAccount.id]);

     const toggleSidebar = () => {
          if (limitationType === LIMITATION_TYPE.EVENT || limitationType === LIMITATION_TYPE.UPGRADE_REPORTING) {
               dispatch(setLimitationType(localStorage.getItem(LOCAL_TEMP_LIMITATION), false));
          }

          dispatch(setAsideShow(!asideShow));
     }

     const togglePublishBeforeDowngradePopup = useCallback(() => {
          setShowPublishBeforeDowngradePopup(show => !show)
     }, [])

     const onCloseNextPublishDownGradePopup = () => {
          dispatch(setAsideShow(false))
     }

     return (
          <CSidebar
               aside
               colorScheme='light'
               size='lg'
               overlaid
               show={asideShow}
               onShowChange={toggleSidebar}
               className={sidebarClassNames}
          >
               {
                    (!isFullScreen) && (
                         <CIcon
                              name="cil-x"
                              onClick={toggleSidebar}
                              className="icon-close-popup"
                         ></CIcon>
                    )
               }
               {
                    (accountsLoading || packagesFetching) ? (
                         <CenterSpinner />
                    ) : (
                         <AsideContext.Provider
                              value={{
                                   packageSelected,
                                   tempPackage,
                                   setPackageSelected,
                                   setTempPackage,
                                   resetPackageSelected,
                                   setCurrentComponents,
                                   togglePublishBeforeDowngradePopup
                              }}
                         >
                              {getCurrentComponents()}
                         </AsideContext.Provider>
                    )
               }
               <PublishBeforeDowngradePopup
                    show={showPublishBeforeDowngradePopup}
                    onClose={togglePublishBeforeDowngradePopup}
                    onCloseNext={onCloseNextPublishDownGradePopup}
               />
          </CSidebar>
     )
}

export default React.memo(SubscriberASide)
