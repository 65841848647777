import { CButton, CForm, CFormGroup, CInput, CInvalidFeedback, CLabel } from '@coreui/react';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useActiveDestination } from '../../../../../../../helpers/customHooks';
import Waiting from '../../../../../../general/Loadings/Waiting';
import { ClickInfoIconTooltip } from '../../../../../../general/custom-tooltips';

const regex = /^[a-zA-Z0-9]*$/; //eslint-disable-line
const validationSchema = () =>
     Yup.object().shape({
          measurementId: Yup.string()
               .required('Required!')
               .test('is-measurementIdOverride', 'Please enter a valid measurement ID, e.g. G-1234', (measurementIdOverride) => {
                    if (!measurementIdOverride) {
                         return false;
                    }
                    if (measurementIdOverride.search('G-') === 0) {
                         let str = measurementIdOverride.replace('G-', '');
                         if (measurementIdOverride.search('G-') !== 0) {
                              return false;
                         }
                         if (measurementIdOverride !== measurementIdOverride.toUpperCase()) {
                              return false;
                         }
                         if (regex.test(str) !== true) {
                              return false;
                         }
                         if (Array.from(measurementIdOverride).length <= 2) {
                              return false;
                         }
                         return true;
                    }
               }),
          apiSecret: Yup.string().required('Required!'),
     });

const GoogleAnalytics = ({ onSubmit, initialData }) => {
     const activeDestination = useActiveDestination();
     const initialValues = { measurementId: initialData.measurementId || '', apiSecret: initialData.apiSecret || '' };

     return (
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnChange={false} validateOnBlur={false}>
               {({ values, errors, handleSubmit, isSubmitting, handleChange }) => {
                    return (
                         <CForm noValidate onSubmit={handleSubmit} className={`general-setting-form ${activeDestination.alias}`}>
                              <CFormGroup>
                                   {!initialData.measurementId && (
                                        <div className='form-group-general'>
                                             <div className='label-wrapper'>
                                                  <CLabel>
                                                       Measurement ID <span className='text-danger'>*</span>
                                                  </CLabel>
                                                  <div className='tooltip-wrapper'>
                                                       <ClickInfoIconTooltip>
                                                            <p>
                                                                 Enter the measurement ID from your Google Analytics 4 stream. You can obtain this inside GA4 &gt; Admin
                                                                 &gt; Data Streams &gt; and then select your Data Stream. The measurement ID matches this pattern:
                                                                 G-ABC1234567
                                                            </p>
                                                       </ClickInfoIconTooltip>
                                                  </div>
                                             </div>
                                             <div className='input-field'>
                                                  <CInput
                                                       name='measurementId'
                                                       value={values.measurementId}
                                                       onChange={handleChange}
                                                       invalid={!!errors.measurementId}
                                                       disabled={isSubmitting}
                                                  />
                                                  <CInvalidFeedback>{errors.measurementId}</CInvalidFeedback>
                                             </div>
                                        </div>
                                   )}
                                   {!initialData.apiSecret && (
                                        <div className='form-group-general'>
                                             <div className='label-wrapper'>
                                                  <CLabel>
                                                       API Secret <span className='text-danger'>*</span>
                                                  </CLabel>
                                                  <div className='tooltip-wrapper'>
                                                       <ClickInfoIconTooltip>
                                                            <p>
                                                                 Enter the API secret from your Google Analytics 4 stream. You can obtain this inside GA4 &gt; Admin &gt;
                                                                 Data Streams &gt; and then select your Measurement Protocol API secrets.
                                                            </p>
                                                       </ClickInfoIconTooltip>
                                                  </div>
                                             </div>
                                             <div className='input-field'>
                                                  <CInput
                                                       name='apiSecret'
                                                       value={values.apiSecret}
                                                       onChange={handleChange}
                                                       invalid={!!errors.apiSecret}
                                                       disabled={isSubmitting}
                                                  />
                                                  <CInvalidFeedback>{errors.apiSecret}</CInvalidFeedback>
                                             </div>
                                        </div>
                                   )}
                              </CFormGroup>
                              <div className='save-changes-button-wrapper'>
                                   <CButton type='submit' color='primary' disabled={isSubmitting}>
                                        <Waiting isLoading={isSubmitting}>Continue</Waiting>
                                   </CButton>
                              </div>
                         </CForm>
                    );
               }}
          </Formik>
     );
};

export default GoogleAnalytics;
