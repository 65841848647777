import React, { useState, useEffect } from 'react'
import {
     CButton,
     CModal,
     CModalBody,
     CModalFooter,
} from '@coreui/react';
import { useDispatch } from 'react-redux';
import { setShowHamburgerIcon } from '../../../../../actions/subscriber';

import SimpleRuleStep1 from './simple/CreateStep1';
import SimpleRuleStep2 from './simple/CreateStep2';
// import SimpleRuleStep3 from './simple/CreateStep3';
import SimpleRuleStep4 from './simple/CreateStep4';

const CreateNewSimpleRule = () => {
     const [stepsData, setStepsData] = useState({
          name: '',
          description: '',
          consentMethod: '',
          geographicRegions: [
               { regionType: '', continent: '', country: '', countryOfState: '', stateProvinces: '' }
          ],
          visitorIdentification: {
               notAllow: '',
               allow: '',
               level: ''
          },
          dataStorageRetention: {
               timeRemove: '',
               region: ''
          }
     });

     return (
          <>
               <SimpleRuleStep1
                    stepsData={stepsData}
                    setStepsData={setStepsData}
               />
               <SimpleRuleStep2
                    stepsData={stepsData}
                    setStepsData={setStepsData}
               />
               {/* <SimpleRuleStep3
                    stepsData={stepsData}
                    setStepsData={setStepsData}
               /> */}
               <SimpleRuleStep4
                    stepsData={stepsData}
                    setStepsData={setStepsData}
               />
          </>
     )
}

export const RuleContext = React.createContext({});

const CreateNewRule = () => {
     const dispatch = useDispatch();
     const [modal, setModal] = useState(false);
     const [activeStep, setActiveStep] = useState(1);

     // Check if user've hit a limitation of custom variable rules or not
     const checkLimitation = () => {
          return () => dispatch(setShowHamburgerIcon(false));
     }

     useEffect(checkLimitation, []);

     return (
          <>
               <div className="cvr-create-new custom-rule consent-privacy">
                    <h1>Create a Regional Consent Rule</h1>
                    <p>Build & manage your regional consent rule by completing the steps below. You can move back and forth between steps freely to refine the rule.</p>

                    <RuleContext.Provider value={{ activeStep, setActiveStep }}>
                         <CreateNewSimpleRule />
                    </RuleContext.Provider>

                    <CModal
                         show={modal}
                         centered={true}
                         onClose={() => setModal(false)}
                    >
                         <CModalBody className="text-center">
                              <h3 className="mb-4">Warning! This Will Change Your Rule</h3>
                              <p>
                                   You are about to change your rule type. Doing this will reset and remove all settings inside this rule other than the name and description.
                              </p>
                              <p>Are you sure you want to do this? You cannot undo this once you publish it!</p>
                         </CModalBody>
                         <CModalFooter className="text-center justify-content-center">
                              <CButton
                                   color="primary"
                                   className="mr-3 text-uppercase"
                                   onClick={() => setModal(false)}>Cancel</CButton>
                              <CButton
                                   color="secondary"
                                   onClick={() => { }}
                              >Save Change and Reset Rule</CButton>
                         </CModalFooter>
                    </CModal>
               </div>
          </>
     )
}

export default CreateNewRule
