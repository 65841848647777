import React, { useContext, useEffect } from 'react';
import { CCard, CCardBody } from '@coreui/react';
import llConnectToGTMAnimationData from '../../../../../../assets/lottie-files/ll-connect-to-gtm.json';

import {
    API_GTM_CREATE_VERSION,
    API_GTM_FOLDERS,
    API_GTM_MOVE_ENTITIES_TO_FOLDER,
    API_GTM_TAGS,
    API_GTM_WORKSPACES,
    LOCAL_GTM_ACCESS_TOKEN,
    API_GTM_PUBLISH,
    LOCAL_GTM_USER_EMAIL,
    API_GTM,
    API_CLIENT_DESTINATIONS_GTM_CHANGES_LOG
} from '../../../../../../constants';
import { callTokenApi, makeRequest } from '../../../../../../apiCaller';
import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import CommonLottie from '../../../../../general/lottie/CommonLottie';
import { getDLScript } from '../../../../../../utils';
import { useSelector } from 'react-redux';

const GTMMakeChanges = () => {
    const {
        account,
        stepsData,
        setStepsData,
        setCurrentStep,
        gtmWorkspaceName,
        gtmTagName,
        gtmFolderName
    } = useContext(CreateAccountContext);
    const activeAccount = useSelector(state => state.subscriber.activeAccount);
    const accessToken = localStorage.getItem(LOCAL_GTM_ACCESS_TOKEN);
    const { selectedGTMContainer, gtmWorkspace, gtmHasUnpublishedChanges, workspaceLength } = stepsData;
    const dlScript = getDLScript(account.id);
    const createWorkspace = async () => {
        return await makeRequest(API_GTM_WORKSPACES.replace('+parent', selectedGTMContainer.path), 'POST', { name: gtmWorkspaceName }, accessToken)
            .then(response => {
                if (response.status === 200) {
                    return response;
                } else {
                    if (workspaceLength === 3) {
                        // eslint-disable-next-line
                        throw ({ isRunOutOfWorkspace: true });
                    }

                    throw new Error('Create workspace error!');
                }
            });
    };

    const getFolders = async (workspacePath) => {
        return await makeRequest(API_GTM_FOLDERS.replace('+parent', workspacePath), 'GET', null, accessToken)
            .then(response => {
                if (response.status === 200) {
                    return response;
                } else {
                    throw new Error('Get folders error!');
                }
            });
    };

    const createFolder = async (workspacePath) => {
        const body = {
            name: gtmFolderName
        }
        return await makeRequest(API_GTM_FOLDERS.replace('+parent', workspacePath), 'POST', body, accessToken)
            .then(response => {
                if (response.status === 200) {
                    const postData={
                        containerId: selectedGTMContainer.containerId,
                        publicId:selectedGTMContainer.publicId,
                        accountId: activeAccount.id,
                        name:gtmFolderName,
                        type: 'Folder',
                        action: 'Create'
                    }
                    callTokenApi(API_CLIENT_DESTINATIONS_GTM_CHANGES_LOG, 'POST', postData)
                    return response;
                } else {
                    throw new Error('Create folder error!');
                }
            });
    };

    const moveTagToFolder = async (folderPath, tagId) => {
        return await makeRequest(`${API_GTM_MOVE_ENTITIES_TO_FOLDER.replace('+path', folderPath)}?tagId=${tagId}`, 'POST', null, accessToken)
            .then(response => {
                if (response.status === 200) {
                    return response;
                } else {
                    throw new Error('Move tag to folder error!');
                }
            });
    };

    const getTags = async (workspacePath) => {
        return await makeRequest(API_GTM_TAGS.replace('+parent', workspacePath), 'GET', null, accessToken)
            .then(response => {
                if (response.status === 200) {
                    return response;
                } else {
                    throw new Error('Get tags error!');
                }
            });
    }

    const updateTag = async (path, body) => {
        return await makeRequest(API_GTM.replace('path', path), 'PUT', body, accessToken)
            .then(response => {
                if (response.status === 200) {
                    const postData={
                        containerId: selectedGTMContainer.containerId,
                        publicId:selectedGTMContainer.publicId,
                        accountId: activeAccount.id,
                        name:body.name,
                        type: 'Tag',
                        action: 'Update',
                        gtmReferenceData:response.data
                    }
                    callTokenApi(API_CLIENT_DESTINATIONS_GTM_CHANGES_LOG, 'POST', postData)
                    return response;
                } else {
                    throw new Error('Update tag error!');
                }
            });
    };

    const createTag = async (workspacePath) => {
        const body = {
            name: gtmTagName,
            type: 'html',
            parameter: [
                {
                    type: 'template',
                    key: 'html',
                    value: dlScript
                },
                {
                    type: 'boolean',
                    key: 'supportDocumentWrite',
                    value: 'false'
                }
            ],
            tagFiringOption: 'oncePerEvent',
            // firingTriggerId: ['2147479553'] // 2147479553 is trigger ID of default trigger named "All Pages"
            firingTriggerId: ['2147479572'] // 2147479553 is trigger ID of default trigger named "Consent Initialization - All Pages"
        }

        return await makeRequest(API_GTM_TAGS.replace('+parent', workspacePath), 'POST', body, accessToken)
            .then(response => {
                if (response.status === 200) {
                    const postData={
                        containerId: selectedGTMContainer.containerId,
                        publicId:selectedGTMContainer.publicId,
                        accountId: activeAccount.id,
                        name:gtmTagName,
                        type: 'Tag',
                        action: 'Create',
                        gtmReferenceData:response.data
                    }
                    callTokenApi(API_CLIENT_DESTINATIONS_GTM_CHANGES_LOG, 'POST', postData)
                    return response;
                } else {
                    throw new Error('Create tag error!');
                }
            });
    };

    const createVersion = async (workspacePath) => {
        const body = {
            name: 'ListenLayer'
        }

        return await makeRequest(API_GTM_CREATE_VERSION.replace('+path', workspacePath), 'POST', body, accessToken)
            .then(response => {
                if (response.status === 200) {
                    return response;
                } else {
                    throw new Error('Create version error!');
                }
            });
    };

    const publishGTM = async (versionPath) => {
        return await makeRequest(API_GTM_PUBLISH.replace('+path', versionPath), 'POST', null, accessToken)
            .then(response => {
                if (response.status === 200) {
                    return response;
                } else {
                    throw new Error('Publish error!');
                }
            });
    };

    const checkIfHasPublishPermission = () => {
        let hasPermission = false;

        if (Array.isArray(selectedGTMContainer.userPermissions)) {
            const gtmUserEmail = localStorage.getItem(LOCAL_GTM_USER_EMAIL);
            const foundUserPermission = selectedGTMContainer.userPermissions.find(permission => permission.emailAddress === gtmUserEmail);

            if (foundUserPermission) {
                const foundContainerPermission = foundUserPermission.containerAccess.find(containerAccess => containerAccess.containerId === selectedGTMContainer.containerId);

                if (foundContainerPermission) {
                    hasPermission = (foundContainerPermission.permission === 'publish');
                }
            }
        }

        return hasPermission;
    };

    const handleFirstLoad = () => {
        const process = async () => {
            if (gtmWorkspace) {
                try {
                    let workspacePath = '';

                    if (gtmHasUnpublishedChanges) {
                        const createWorkspaceRes = await createWorkspace();
                        workspacePath = createWorkspaceRes.data.path;
                    } else {
                        workspacePath = gtmWorkspace.path;
                    }

                    if (workspacePath) {
                        const allTagsRes = await getTags(workspacePath, accessToken);
                        let tagId;

                        if (Array.isArray(allTagsRes.data.tag) && allTagsRes.data.tag.length > 0) {
                            let newTagName = gtmTagName;
                            let duplicateTagName = allTagsRes.data.tag.find(t => t.name === newTagName);

                            let duplicateTagByHTML = allTagsRes.data.tag.find(tag => {
                                let foundTag;

                                if (Array.isArray(tag.parameter)) {
                                    foundTag = tag.parameter.find(({ key, value }) => key === 'html' && typeof value === 'string' && value.includes(dlScript));
                                }

                                return !!foundTag;
                            });

                            if (duplicateTagName && (!duplicateTagByHTML || (duplicateTagName.tagId !== duplicateTagByHTML.tagId))) {
                                // Handle if duplicate tag name
                                for (let i = 1; i <= 50; i++) {
                                    const newName = (i === 1) ? `${newTagName} Old` : `${newTagName} Old - ${i}`;

                                    if (!allTagsRes.data.tag.find(t => t.name === newName)) { // Didn't find that newName from all tags
                                        newTagName = newName;
                                        break;
                                    }
                                }

                                const updateTagBody = { ...duplicateTagName, name: newTagName, paused: true };
                                await updateTag(duplicateTagName.path, updateTagBody);
                            }

                            if (duplicateTagByHTML) {
                                // Handle if duplicate tag HTML
                                tagId = duplicateTagByHTML.tagId;

                                if (duplicateTagByHTML.name !== gtmTagName || duplicateTagByHTML.paused) {
                                    const updateTagBody = { ...duplicateTagByHTML, name: gtmTagName, paused: false };
                                    await updateTag(duplicateTagByHTML.path, updateTagBody);
                                }
                            }
                        }
                        if (typeof tagId === 'undefined') {
                            const createTagRes = await createTag(workspacePath, accessToken);
                            tagId = createTagRes.data.tagId;
                        }
                        const getFoldersRes = await getFolders(workspacePath);
                        const llFolder = Array.isArray(getFoldersRes.data.folder) ? getFoldersRes.data.folder.find(el => el.name === gtmFolderName) : null;
                        let folderPath = '';
                        if (llFolder) {
                            folderPath = llFolder.path;
                        } else {
                            const createFolderRes = await createFolder(workspacePath);

                            folderPath = createFolderRes.data.path;
                        }

                        await moveTagToFolder(folderPath, tagId);

                        const hasPublishPermission = checkIfHasPublishPermission();

                        if (hasPublishPermission) {
                            const createVersionRes = await createVersion(workspacePath);

                            await publishGTM(createVersionRes.data.containerVersion.path);

                            // Set timeout to make sure GTM new ListenLayer tag has published and then it can find ListenLayer script on the user's domain when verifying
                            setTimeout(() => {
                                window._retryVerifyScriptOnce = true;
                                setCurrentStep(STEPS.gtmPublishSuccess);
                            }, 3000);
                        } else {
                            setCurrentStep(STEPS.gtmNoPublishingRights);
                        }
                    }
                } catch (error) {
                    if (error.isRunOutOfWorkspace) {
                        setStepsData({
                            gtmFailedMessages: {
                                headline: 'Your GTM account has no available workspaces.',
                                description: `Publish any changes and open up your workspaces before we can make changes in your GTM account.`
                            }
                        });
                    }

                    setCurrentStep(STEPS.gtmUnknownFailure);
                }
            } else {
                setCurrentStep(STEPS.gtmUnknownFailure);
            }
        }

        process();
    }

    useEffect(handleFirstLoad, []);

    return (
        <CreateAccountBody>
            <CCard className="account-gtm making-changes">
                <p className="number-of-step">2 of 3 steps</p>
                <CCardBody>
                    <h3>Making changes in GTM</h3>
                    <CommonLottie animationData={llConnectToGTMAnimationData} />
                    <p>We're working on making these changes; please wait on this screen (it will only take a few seconds).</p>
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default GTMMakeChanges;
