import React, { useContext } from 'react'
import {
    CInput,
    CSelect,
    CTooltip,
} from '@coreui/react';
import { LookupTableContext } from './LookupTable';
import { Draggable } from 'react-beautiful-dnd';
import { useActiveListener } from '../../../../../../helpers/customHooks';
import { VARIABLE_OPERATORS_NAME } from '../../../../../../constants';

const TableBodyRows = () => {
    const {
        tableRow,
        handleChangeRowData,
        hasError,
        setRemoveRowModal,
        resetBtnSaveStatus,
        setTableRow
    } = useContext(LookupTableContext);
    const activeListener = useActiveListener();

    let urlElements = [
        { text: 'url', value: 'url' },
        { text: 'tld', value: 'tld' },
        { text: 'hostname', value: 'hostname' },
        { text: 'pathname', value: 'pathname' },
        { text: 'queryString', value: 'queryString' },
        { text: 'fragment', value: 'fragment' },
        { text: 'protocol', value: 'protocol' },
        { text: 'originPathName', value: 'originPathName' }
    ];

    if (activeListener.code === 'pdfClicks') {
        urlElements = [
            { text: 'fileName', value: 'fileName' },
            { text: 'fileExtension', value: 'fileExtension' },
            ...urlElements
        ];
    }

    const handleRemoveRow = (index) => {
        let data = [...tableRow];
        const { clickUrlElement, matchType, value } = data[index].conditions;
        const { trackAsClicks } = data[index].customVariables;

        if (clickUrlElement || matchType || value || trackAsClicks) {
            setRemoveRowModal({ show: true, index });
        } else {
            if (data.length > 1) {
                data.splice(index, 1);
                setTableRow(data);
            }
        }
        resetBtnSaveStatus();
    }

    return (
        <>
            {
                tableRow && tableRow.map((row, rowIndex) => {
                    let conditionRow = row.conditions;
                    let cusVariableRow = row.customVariables;
                    let disableCell = [VARIABLE_OPERATORS_NAME.EXISTS_WITH_ANY_VALUE,VARIABLE_OPERATORS_NAME.DOES_NOT_EXIST].includes(row.conditions.matchType)
                    return (
                        <Draggable key={rowIndex} draggableId={rowIndex.toString()} index={rowIndex}>
                            {
                                (provided, snapshot) => (
                                    <tr className={`table-body${snapshot.isDragging ? ' isDragging' : ''}${hasError.includes(rowIndex) ? ' hasError' : ''}`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <td className="event-variable-value event-variable-value-ellipsis">
                                            <span>
                                                <i className="fas fa-ellipsis-v"></i>
                                            </span>
                                            <CSelect
                                                name="variableclickUrlElement"
                                                value={conditionRow.clickUrlElement}
                                                className="rounded-0"
                                                onChange={(e) => { handleChangeRowData(e.target.value, 'clickUrlElement', rowIndex) }}
                                            >
                                                <option value="" hidden disabled>Select</option>
                                                {
                                                    urlElements.map(el => (
                                                        <option key={el.value} value={el.value}>{el.text}</option>
                                                    ))
                                                }
                                            </CSelect>
                                        </td>
                                        <td className="event-variable-value">
                                            <CSelect
                                                name="matchType"
                                                value={conditionRow.matchType}
                                                className="rounded-0"
                                                onChange={(e) => { handleChangeRowData(e.target.value, 'matchType', rowIndex) }}
                                            >
                                                <option value="" hidden disabled>Select</option>
                                                <option value="ct">Contains</option>
                                                <option value="nct">Not Contain</option>
                                                <option value="eq">Equals To</option>
                                                <option value="neq">Not Equals To</option>
                                                <option value="exav">Exists with any value</option>
                                                <option value="nex">Does not exist</option>
                                            </CSelect>
                                        </td>
                                        <td className="event-variable-value value">
                                            <CInput
                                                name="value"
                                                value={conditionRow.value}
                                                className="rounded-0"
                                                onChange={(e) => { handleChangeRowData(e.target.value, 'value', rowIndex) }}
                                                autoComplete="off"
                                                data-lpignore="true"
                                                disabled={disableCell}
                                                style={disableCell ? { backgroundColor: '#c8ced3' } : {}}
                                            />
                                        </td>
                                        <td className="custom-variable-value user-source">
                                            <CSelect
                                                name="trackAsClicks"
                                                value={cusVariableRow.trackAsClicks}
                                                className="rounded-0"
                                                onChange={(e) => { handleChangeRowData(e.target.value, 'trackAsClicks', rowIndex) }}
                                            >
                                                <option value="" hidden disabled>Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </CSelect>
                                        </td>
                                        {
                                            <td key={rowIndex} className="btn-remove-row">
                                                <CTooltip
                                                    content="Remove row"
                                                    placement="bottom-start"
                                                >
                                                    <i
                                                        className="fal fa-times text-danger"
                                                        onClick={() => handleRemoveRow(rowIndex)}
                                                    >
                                                    </i>
                                                </CTooltip>
                                            </td>
                                        }
                                    </tr>
                                )
                            }
                        </Draggable >)
                })
            }
        </>
    )
}

export default TableBodyRows
