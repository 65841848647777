import React, { useState } from 'react';
import { CButton, CButtonGroup, CCard, CTooltip } from '@coreui/react';
import Calendar from 'react-calendar';
import CIcon from '@coreui/icons-react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const Filters = (
    {
        filterType,
        setFilterType,
        customFilterType,
        setCustomFilterType,
        filterBeginDate,
        setFilterBeginDate,
        filterEndDate,
        setFilterEndDate
    }
) => {
    const activeAccountTimeZone = useSelector(state => state.subscriber.activeAccount.timeZone);
    const [customDate, setCustomDate] = useState(false);
    const dateFormat = 'DD / MMM / YYYY';
    const maxDate = activeAccountTimeZone ? dayjs().tz(activeAccountTimeZone)['$d'] : new Date();

    const handleFilterType = (type) => {
        setFilterType(type);
    }

    const handleClickCustom = () => {
        // If user doesn't pick a date yet, it will automatically choose today when opening the calender
        // if (customFilterType === "") {
        //     setCustomFilterType("custom");
        //     if (filterBeginDate === "") {
        //         setFilterBeginDate(maxDate);
        //         setFilterEndDate(maxDate);
        //     }
        // }

        setTimeout(() => {
            if (customDate) {
                setCustomDate(false);
            } else {
                setCustomDate(true);
            }
        }, 100);
    }
    const handleCloseDateRange = () => {
        setCustomDate(false);
    }
    const onChangeDate = (value) => {
        let start = value[0];
        let end = value[1] || start;

        // Set customFilterType = custom when selecting a date on the calender
        if (customFilterType === "") {
            setCustomFilterType("custom");
        }

        setFilterBeginDate(start);
        setFilterEndDate(end);
    }

    const onClickRemoveDateRange = () => {
        setCustomFilterType('');
        setFilterBeginDate('');
        setFilterEndDate('');
    }

    const getFormatedDate = (date) => dayjs(date).format(dateFormat);

    const onClickLastTime = (time) => {
        if (customFilterType === "") {
            setCustomFilterType("custom");
        }

        let now = activeAccountTimeZone ? dayjs().tz(activeAccountTimeZone) : dayjs();
        let beginDate, endDate;

        switch (time) {
            case '7 days':
                beginDate = now.subtract(7, 'day')['$d'];
                endDate = now.subtract(1, 'day')['$d'];
                break;
            case '28 days':
                beginDate = now.subtract(28, 'day')['$d'];
                endDate = now.subtract(1, 'day')['$d'];
                break;
            case '30 days':
                beginDate = now.subtract(30, 'day')['$d'];
                endDate = now.subtract(1, 'day')['$d'];
                break;
            case '90 days':
                beginDate = now.subtract(90, 'day')['$d'];
                endDate = now.subtract(1, 'day')['$d'];
                break;
            case 'week':
                beginDate = now.subtract(1, 'week').startOf('week').day(1)['$d'];
                endDate = now.subtract(1, 'week').endOf('week').add(1, 'day')['$d'];
                break;
            case 'month':
                beginDate = now.subtract(1, 'month').startOf('month')['$d'];
                endDate = now.subtract(1, 'month').endOf('month')['$d'];
                break;
            case 'year':
                beginDate = now.subtract(1, 'year').startOf('year')['$d'];
                endDate = now.subtract(1, 'year').endOf('year')['$d'];
                break;
            default:
                break;
        }

        if (beginDate && endDate) {
            setFilterBeginDate(beginDate);
            setFilterEndDate(endDate);
        } else {
            setFilterBeginDate(now['$d']);
            setFilterEndDate(now['$d']);
        }
    }

    return (
        <>
            <div className="date-range-wrapper ml-3">
                {customDate && <div className="date-range-overlay" onClick={handleCloseDateRange}></div>}
                <CButton
                    className="date-range"
                    onClick={handleClickCustom}
                >
                    {
                        filterBeginDate !== '' ? `${getFormatedDate(filterBeginDate)} - ` : 'From - '
                    }
                    {
                        filterEndDate !== '' ? `${getFormatedDate(filterEndDate)}` : 'To'
                    }
                </CButton>
                {
                    customFilterType === "custom" && (
                        <CButton className="remove-custom-date">
                            <CTooltip
                                content="Reset Date Range"
                            >
                                <CIcon name="cil-delete" onClick={onClickRemoveDateRange} />
                            </CTooltip>
                        </CButton>
                    )
                }
                {
                    customDate && (
                        <CCard
                            className="custom-date-wrapper"
                        >
                            {/* <CIcon name="arrow-account" className="arrow-account" height={30}></CIcon> */}
                            <CIcon name="cil-x" onClick={() => { setCustomDate(false) }} className="icon-close-popup" />
                            <div className="calendar-wrapper">
                                <Calendar
                                    maxDate={maxDate}
                                    // defaultActiveStartDate={maxDate} // If opening the calender default choose a date, enable this
                                    value={(filterBeginDate || filterBeginDate) ? [filterBeginDate, filterEndDate] : null}
                                    allowPartialRange={true}
                                    selectRange={true}
                                    onChange={onChangeDate}
                                    returnValue="range"
                                />
                            </div>
                            <div className="date-buttons">
                                <CButton
                                    className="text-capitalize"
                                    color={customFilterType === "7 days" ? "info" : 'light'}
                                    onClick={() => onClickLastTime('7 days')}
                                >
                                    Last 7 days
                                                </CButton>
                                <CButton
                                    className="text-capitalize"
                                    color={customFilterType === "28 days" ? "info" : 'light'}
                                    onClick={() => onClickLastTime('28 days')}
                                >
                                    Last 28 days
                                                </CButton>
                                <CButton
                                    className="text-capitalize"
                                    color={customFilterType === "90 days" ? "info" : 'light'}
                                    onClick={() => onClickLastTime('90 days')}
                                >
                                    Last 90 days
                                                </CButton>
                            </div>
                        </CCard>
                    )
                }
            </div>
            <CButtonGroup>
                <CButton
                    className="text-capitalize"
                    color={filterType === "nthHour" ? "info" : 'light'}
                    onClick={() => handleFilterType('nthHour')}
                >
                    Hourly
                            </CButton>
                <CButton
                    className="text-capitalize"
                    color={filterType === "nthDay" ? "info" : 'light'}
                    onClick={() => handleFilterType('nthDay')}
                >
                    Daily
                            </CButton>
                <CButton
                    className="text-capitalize"
                    color={filterType === "nthWeek" ? "info" : 'light'}
                    onClick={() => handleFilterType('nthWeek')}
                >
                    Weekly
                            </CButton>
                <CButton
                    className="text-capitalize"
                    color={filterType === "nthMonth" ? "info" : 'light'}
                    onClick={() => handleFilterType('nthMonth')}
                >
                    Monthly
                </CButton>
            </CButtonGroup>
        </>
    )
}

export default Filters
