import {
     CRAWL_FROM_ACTION,
     LOAD_VERSION_PUBLISHING,
     PUSHER_ADD_NEW_TICKET_COMMENT,
     PUSHER_ADD_RULE,
     PUSHER_CHANGE_ACCOUNT_EVENTS,
     PUSHER_CHANGE_ACCOUNT_NAME,
     PUSHER_CHANGE_ACCOUNT_TIMESTAMPS,
     PUSHER_CHANGE_ACCOUNT_VARIABLES,
     PUSHER_CHANGE_CHILD_FEATURE_STATUS,
     PUSHER_CHANGE_EVENT_CHART,
     PUSHER_CHANGE_FEATURE_STATUS,
     PUSHER_CHANGE_LISTENER_STATUS,
     PUSHER_CHANGE_NUMBER_SESSIONS_CHART,
     PUSHER_CHANGE_NUMBER_VISITORS_CHART,
     PUSHER_CHANGE_VISITORS_AND_SESSIONS_PER_MONTH,
     PUSHER_CONSENT_RULE_CHANGED,
     PUSHER_CREATE_INVOICES,
     PUSHER_CREATE_NEW_USER_MANAGER,
     PUSHER_INCREASE_NUMBER_OF_EVENTS,
     PUSHER_INCREASE_NUMBER_OF_FAKE_VISITORS_BY_1,
     PUSHER_INCREASE_NUMBER_OF_HISTORIES,
     PUSHER_INVITE_MANAGER_USER,
     PUSHER_INVOICE_FINALIZATION,
     PUSHER_NATIVE_BROWSER_STORAGE_CHANGE,
     PUSHER_REMOVE_MANAGER_USER,
     PUSHER_REMOVE_RULE,
     PUSHER_REORDER_RULES,
     PUSHER_RESET_CMT_NUMBER_OF_CLIENT,
     PUSHER_RESET_FEATURE_SUPPORT,
     PUSHER_RESET_NUMBER_OF_EVENTS,
     PUSHER_SAVE_CARD_TO_CHANGE,
     PUSHER_SAVE_CREDIT_CARD,
     PUSHER_SET_ACCOUNT_COMPLETED_STEPS,
     PUSHER_SET_RELOAD_OFFICE_HOUR_CALENDAR,
     PUSHER_SET_RELOAD_PARTNER,
     PUSHER_UDPATE_PUBLISHED_ACCOUNTS,
     PUSHER_UPDATE_ACCOUNT,
     PUSHER_UPDATE_ACCOUNT_CONSOLE_STATUS,
     PUSHER_UPDATE_ACCOUNT_USER,
     PUSHER_UPDATE_AVAILABLE_HOURS,
     PUSHER_UPDATE_CUSTOM_FORM_RULES,
     PUSHER_UPDATE_DAYS_LENGTH,
     PUSHER_UPDATE_ELEMENT_CLICK_RULES,
     PUSHER_UPDATE_EVENT_TABLE,
     PUSHER_UPDATE_FEATURE_SUPPORT,
     PUSHER_UPDATE_FEATURE_SUPPORT_CHILD,
     PUSHER_UPDATE_FEATURE_SUPPORT_UPDATE_VIDEO_PROGRESSES,
     PUSHER_UPDATE_INVOICE,
     PUSHER_UPDATE_INVOICE_INFORMATION,
     PUSHER_UPDATE_LISTENER_FOR_ROUTES,
     PUSHER_UPDATE_LIST_DATALAYER_VARIABLES,
     PUSHER_UPDATE_LIST_EVENTS,
     PUSHER_UPDATE_NOTIFICATION,
     PUSHER_UPDATE_PERSONAL_DATA,
     PUSHER_UPDATE_PRECISE_LOCATION_CONFIG,
     PUSHER_UPDATE_RULE,
     PUSHER_UPDATE_RULE_CONDITIONS,
     PUSHER_UPDATE_RULE_STATUS,
     PUSHER_UPDATE_SELECTOR_VALUES,
     PUSHER_UPDATE_SELECTOR_VARIABLES,
     PUSHER_UPDATE_USER_MANAGER_INFO,
     PUSHER_UPDATE_VARIABLE_VALUES,
     PUSHER_UPGRADE_OR_DOWNGRADE_ACCOUNT,
     SET_INCREMENT_NUMBER_EVENT,
     SET_NUMBER_HISTORIES,
     SET_PUBLISHED_VERSION,
} from './types';

export const setPublishingVersion = (show) => {
     return {
          type: LOAD_VERSION_PUBLISHING,
          payload: show
     }
}

export const setPublishedVersion = (cid) => {
     return {
          type: SET_PUBLISHED_VERSION,
          payload: cid
     }
}

export const increaseNumberOfHistories = (options) => {
     return {
          type: PUSHER_INCREASE_NUMBER_OF_HISTORIES,
          payload: options
     }
}

export const inviteManagerUser = (data) => {
     return {
          type: PUSHER_INVITE_MANAGER_USER,
          payload: data
     }
}

export const removeManagerUser = (data) => {
     return {
          type: PUSHER_REMOVE_MANAGER_USER,
          payload: data
     }
}

export const addRule = (data) => {
     return {
          type: PUSHER_ADD_RULE,
          payload: data
     }
}

export const updateRule = (cid) => {
     return {
          type: PUSHER_UPDATE_RULE,
          payload: cid
     }
}

export const updateRuleStatus = (cid) => {
     return {
          type: PUSHER_UPDATE_RULE_STATUS,
          payload: cid
     }
}

export const removeRule = (data) => {
     return {
          type: PUSHER_REMOVE_RULE,
          payload: data
     }
}

export const reorderRule = (data) => {
     return {
          type: PUSHER_REORDER_RULES,
          payload: data
     }
}

export const setIncrementNumberOfEvent = () => {
     return {
          type: SET_INCREMENT_NUMBER_EVENT,
     }
}

export const changeListenerStatus = (data) => {
     return {
          type: PUSHER_CHANGE_LISTENER_STATUS,
          payload: data
     }
}

export const setNumberOfHistories = (options) => {
     return {
          type: SET_NUMBER_HISTORIES,
          payload: options
     }
}

export const changeFeatureStatus = (data) => {
     return {
          type: PUSHER_CHANGE_FEATURE_STATUS,
          payload: data
     }
}

export const changeChildFeatureStatus = (data) => {
     return {
          type: PUSHER_CHANGE_CHILD_FEATURE_STATUS,
          payload: data
     }
}

export const changeAccountName = (account) => {
     return {
          type: PUSHER_CHANGE_ACCOUNT_NAME,
          payload: account
     }
}

export const changeAccountTimestamps = (account) => {
     return {
          type: PUSHER_CHANGE_ACCOUNT_TIMESTAMPS,
          payload: account
     }
}

export const upgradeOrDowngradeAccount = (data) => {
     return {
          type: PUSHER_UPGRADE_OR_DOWNGRADE_ACCOUNT,
          payload: data
     }
}

export const updateInvoiceInformation = (account) => {
     return {
          type: PUSHER_UPDATE_INVOICE_INFORMATION,
          payload: account
     }
}

export const saveCreditCard = (account) => {
     return {
          type: PUSHER_SAVE_CREDIT_CARD,
          payload: account
     }
}

export const saveCardToChange = (data) => {
     return {
          type: PUSHER_SAVE_CARD_TO_CHANGE,
          payload: data
     }
}

export const invoiceFinalization = () => {
     return {
          type: PUSHER_INVOICE_FINALIZATION
     }
}

export const changeAccountEvents = (data) => {
     return {
          type: PUSHER_CHANGE_ACCOUNT_EVENTS,
          payload: data
     }
}

export const changeAccountVariables = (data) => {
     return {
          type: PUSHER_CHANGE_ACCOUNT_VARIABLES,
          payload: data
     }
}

export const createInvoices = (data) => {
     return {
          type: PUSHER_CREATE_INVOICES,
          payload: data
     }
}

export const updateInvoice = (data) => {
     return {
          type: PUSHER_UPDATE_INVOICE,
          payload: data
     }
}

export const updateAccount = (data) => {
     return {
          type: PUSHER_UPDATE_ACCOUNT,
          payload: data
     }
}

export const updateFeatureSupport = (data) => {
     return {
          type: PUSHER_UPDATE_FEATURE_SUPPORT,
          payload: data
     }
}

export const updateFeatureSupportChildChange = (data) => {
     return {
          type: PUSHER_UPDATE_FEATURE_SUPPORT_CHILD,
          payload: data
     }
}

export const updateFeatureSupportVideoProgresses = (data) => {
     return {
          type: PUSHER_UPDATE_FEATURE_SUPPORT_UPDATE_VIDEO_PROGRESSES,
          payload: data
     }
}

export const resetFeatureSupport = (data) => {
     return {
          type: PUSHER_RESET_FEATURE_SUPPORT,
          payload: data
     }
}

export const increaseNumberOfEvents = () => {
     return {
          type: PUSHER_INCREASE_NUMBER_OF_EVENTS
     }
}

export const changeEventChart = (data) => {
     return {
          type: PUSHER_CHANGE_EVENT_CHART,
          payload: data
     }
}

export const changeNumberOfVisitorChart = (data) => {
     return {
          type: PUSHER_CHANGE_NUMBER_VISITORS_CHART,
          payload: data
     }
}

export const changeNumberOfSessionsChart = (data) => {
     return {
          type: PUSHER_CHANGE_NUMBER_SESSIONS_CHART,
          payload: data
     }
}

export const resetNumberOfEvents = () => {
     return {
          type: PUSHER_RESET_NUMBER_OF_EVENTS
     }
}

export const createNewUserManager = (user) => {
     return {
          type: PUSHER_CREATE_NEW_USER_MANAGER,
          payload: user
     }
}

export const setListRuleConditions = (data) => {
     return {
          type: PUSHER_UPDATE_RULE_CONDITIONS,
          payload: data
     }
}

export const updateListEvents = (data) => {
     return {
          type: PUSHER_UPDATE_LIST_EVENTS,
          payload: data
     }
}

export const updateListDatalayerVariables = (data) => {
     return {
          type: PUSHER_UPDATE_LIST_DATALAYER_VARIABLES,
          payload: data
     }
}

export const updateUserManagerInfo = (data) => {
     return {
          type: PUSHER_UPDATE_USER_MANAGER_INFO,
          payload: data
     }
}

export const updatePublisedAccounts = (data) => {
     return {
          type: PUSHER_UDPATE_PUBLISHED_ACCOUNTS,
          payload: data
     }
}

export const updateEventTable = (data) => {
     return {
          type: PUSHER_UPDATE_EVENT_TABLE,
          payload: data
     }
}

export const updateVariableValues = (data) => {
     return {
          type: PUSHER_UPDATE_VARIABLE_VALUES,
          payload: data
     }
}

export const updateSelectorVariables = (data) => {
     return {
          type: PUSHER_UPDATE_SELECTOR_VARIABLES,
          payload: data
     }
}

export const updateSelectorValues = (data) => {
     return {
          type: PUSHER_UPDATE_SELECTOR_VALUES,
          payload: data
     }
}

export const nativeBrowserStorageChange = (data) => {
     return {
          type: PUSHER_NATIVE_BROWSER_STORAGE_CHANGE,
          payload: data
     }
}

export const updateAccountConsoleStatus = (data) => {
     return {
          type: PUSHER_ADD_NEW_TICKET_COMMENT,
          payload: data
     }
}

export const addNewTicketComment = (data) => {
     return {
          type: PUSHER_UPDATE_ACCOUNT_CONSOLE_STATUS,
          payload: data
     }
}

export const resetCmtNumberOfClient = (data) => {
     return {
          type: PUSHER_RESET_CMT_NUMBER_OF_CLIENT,
          payload: data
     }
}

export const updateAvailableHours = (data) => {
     return {
          type: PUSHER_UPDATE_AVAILABLE_HOURS,
          payload: data
     }
}

export const approvePartner = (data) => {
     return {
          type: PUSHER_SET_RELOAD_PARTNER,
          payload: data
     }
}

export const reloadOfficeHourCalendar = (data) => {
     return {
          type: PUSHER_SET_RELOAD_OFFICE_HOUR_CALENDAR,
          payload: data
     }
}


export const updateDaysLength = (data) => {
     return {
          type: PUSHER_UPDATE_DAYS_LENGTH,
          payload: data
     }
}

export const reloadCustomFormRules = (data) => {
     return {
          type: PUSHER_UPDATE_CUSTOM_FORM_RULES,
          payload: data
     }
};

export const reloadElementClickRules = (data) => {
     return {
          type: PUSHER_UPDATE_ELEMENT_CLICK_RULES,
          payload: data
     }
};

export const updatePreciseLocationConfig = (data) => {
     return {
          type: PUSHER_UPDATE_PRECISE_LOCATION_CONFIG,
          payload: data
     }
}

export const updateListenerForRoutes = (data) => {
     return {
          type: PUSHER_UPDATE_LISTENER_FOR_ROUTES,
          payload: data
     }
}

export const updateNotification = (data) => {
     return {
          type: PUSHER_UPDATE_NOTIFICATION,
          payload: data
     }
}

export const changeVisitorsAndSessionsPerMonth = (data) => {
     return {
          type: PUSHER_CHANGE_VISITORS_AND_SESSIONS_PER_MONTH,
          payload: data
     }
}

export const consentRuleChanged = (data) => {
     return {
          type: PUSHER_CONSENT_RULE_CHANGED,
          payload: data
     }
}

export const increaseNumberOfFakeVisitorsBy1 = (data) => {
     return {
          type: PUSHER_INCREASE_NUMBER_OF_FAKE_VISITORS_BY_1,
          payload: data
     }
}

export const setAccountCompletedSteps = (steps) => {
     return {
          type: PUSHER_SET_ACCOUNT_COMPLETED_STEPS,
          payload: steps
     }
}

export const updatePersonalData = (data) => {
     return {
          type: PUSHER_UPDATE_PERSONAL_DATA,
          payload: data
     }
}

export const crawlFormsAction = (data) => {
     return {
          type: CRAWL_FROM_ACTION,
          payload: data,
     }
}

export const updateUserAccount = (data) => {
     return {
          type: PUSHER_UPDATE_ACCOUNT_USER,
          payload: data
     }
}