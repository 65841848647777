import CIcon from '@coreui/icons-react';
import {
    CButton, CCol, CForm,
    CLabel,
    CRow
} from '@coreui/react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setFlexibleModal, setRuleHaveEditting } from '../../../../../../actions/common';
import { setNewLayout, setShowBlockAccountPopup, setSubscriberState, setUnsavedLookupTableModal, setUsedItems } from '../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../apiCaller';
import {
    API_CLIENT_RULE,
    API_CLIENT_RULE_REVERT, API_CLIENT_RULE_UPDATE, COMPONENT_NAME, OPERATORS_REQUIRE_VALUE, REGEX_PATTERNS, TYPE_RULE, TYPE_SHOW_UNSAVE_CHANGE
} from '../../../../../../constants';
import { subscriberNavKey } from '../../../../../../containers/_nav/subscriber';
import { getListenerColorIcon } from '../../../../../../helpers/cms/subscriber.js';
import { useBrowserVariables, useEventAndVariableOptionsV2 } from '../../../../../../helpers/customHooks';
import { deepCopyArray, toastError, useOutsideHandling } from '../../../../../../utils';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { ConfirmRemovePopup, ConfirmSaveChange, LeaveComponentChangePopup } from '../../../../../general/popup';
import RemovePopup from '../../../../../general/popup/RemovePopup';
import InsertVariableModal from '../../InsertVariableModalV2';
import { RuleTableContext } from '../EditLookupTable';
import ImportExportTable from './ImportExportTable';
import TableBodyRows from './TableBodyRows';
import TableHeaderRow from './TableHeaderRow';

export const LookupTableContext = React.createContext({});

const LookupTable = ({ stepsData, setStepsData, initialStepsData }) => {
    const { activeStep, fetchRule, activeListener } = useContext(RuleTableContext);
    const history = useHistory();
    const dispatch = useDispatch();
    const activeAccount = useSelector(state => state.subscriber.activeAccount);
    const customVariableOptions = useSelector(state => state.subscriber.customVariableOptions);
    const customVariableRules = useSelector(state => state.subscriber.customVariableRules);
    const initialCustomVariableRules = useSelector(state => state.subscriber.initialCustomVariableRules);
    const { variables: variableOptions, events, eventsNative, nativeBrowserStorage, reactSelectSelectorValueOptions, fetchLoading } = useEventAndVariableOptionsV2({ isFetchData: activeStep === 5, activeListener });
    const { browserVariableShortCodes } = useBrowserVariables(true);
    const flexibleModal = useSelector(state => state.theme.flexibleModal);

    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteRuleModal, setDeleteRuleModal] = useState(false);
    const [saveTableModal, setSaveTableModal] = useState(false);
    const [revertModal, setRevertModal] = useState(false);
    const [leaveComponentModal, setLeaveComponentModal] = useState(false);
    const [navigateTo, setNavigateTo] = useState();
    const [btnSaveStatus, setBtnSaveStatus] = useState(isEqual(stepsData, initialStepsData));
    const [removeRowModal, setRemoveRowModal] = useState({
        show: false,
        index: -1
    });
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [tableRow, setTableRow] = useState();



    const handleClickEditButton = (id) => {
        dispatch(
            setFlexibleModal({
                show: true,
                showLv2: true,
                ruleId: id,
                ruleIdLv2: id,
                component: COMPONENT_NAME.CUSTOM_DATA_LOOKUP_TABLE,
                componentLv2: COMPONENT_NAME.CUSTOM_DATA_LOOKUP_SETTING
            })
        );
        dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
    };

    let _eventsNative = [];
    if (activeListener && activeListener.isNative) {
        if (activeListener.code === 'nativeBrowserStorage') {
            if (nativeBrowserStorage.length > 0) {
                nativeBrowserStorage.forEach(event => {
                    _eventsNative.push({
                        id: event.nativeId,
                        key: event,
                        value: event
                    })
                });
            }
        } else {
            if (eventsNative.length > 0) {
                eventsNative.forEach(event => {
                    _eventsNative.push({
                        id: event,
                        key: event,
                        value: event
                    })
                });
            }
        }
    }
    const eventOptions = events;
    const eventNativeOptions = _eventsNative;
    const nativeBrowserStorageOptions = _eventsNative;

    const { conditions, customVariables } = stepsData;
    const [showCheatSheet, setshowCheatSheet] = useState(false);
    const [variablePositionToInsert, setVariablePositionToInsert] = useState({ rowIndex: -1, colIndex: -1 });
    const [cursorPosition, setCursorPosition] = useState(-1);
    const [showVariableModal, setShowVariableModal] = useState(false);
    const unsavedLookupTableModal = useSelector(state => state.subscriber.unsavedLookupTableModal);
    const [btnAbandon, setBtnAbandon] = useState(false)

    let listenerIconSrc = activeListener ? getListenerColorIcon(activeListener) : '';

    const getNonTierVariables = useCallback((variables) => {
        let variableData = [];
        if (variables && variables.length > 0) {
            variables.forEach(variable => {
                if (variable.status && variable.childs) {
                    variableData.push(...getNonTierVariables(variable.childs));
                }
                else {
                    variableData.push(variable);
                }
            })
        }
        return variableData;
    }, [])

    const nonTierVariables = useMemo(() => getNonTierVariables(variableOptions), [getNonTierVariables, variableOptions]);

    const pusherCondition = conditions[0].filter(col => {
        switch (col.g0.type) {
            case "Event":
                if (eventOptions.find(event => event.id === col.g0.id)) {
                    return col;
                }
                break;
            case "MyEventNative":
                if (eventNativeOptions.find(event => event.id === col.g0.id)) {
                    return col;
                }
                break;
            case "NativeBrowserStorage":
                let findNativeBS = nativeBrowserStorageOptions.find(event => event.id === col.g0.id);
                if (findNativeBS) {
                    col.g0.key = JSON.stringify(findNativeBS.key);
                    return col;
                }
                break;
            case "Variable":
                if (nonTierVariables.find(variable => variable.id === col.g0.id)) {
                    return col;
                }
                break;
            case "Selector":
                if (reactSelectSelectorValueOptions.find(selector => selector.id === col.g0.id)) {
                    return col;
                }
                break;
            default:
        }

        return null;
    });

    const pusherCustomVariable = customVariables[0].filter(
        (col) => customVariableOptions.find(variable => variable.id === col.id)
    );

    const {
        id: activeAccountId,
        numberOfCusVariablesRules
    } = activeAccount;

    const defaultRow = () => {
        const initialConditions = conditions[0].map(item => {
            let newItem = {
                conjunction: item.conjunction,
                g0: { type: item.g0.type, id: item.g0.id, key: item.g0.key, operator: item.g0.operator, value: "", isRegex: false }
            };
            return newItem;
        });
        const initialCustomVariables = customVariables[0].map(item => {
            let newItem = { id: item.id, value: "" };
            return newItem;
        });
        return { conditions: [...initialConditions], customVariables: [...initialCustomVariables] };
    }

    const getInitialTableRow = () => {
        let table = [];
        let conditionData = conditions.map(item => item);
        let customVariableData = customVariables.map(item => item);
        for (let i = 0; i < conditionData.length; i++) {
            table.push({ conditions: conditionData[i], customVariables: customVariableData[i] });
        }
        setTableRow(table);
    }

    // const fetchCustomVariables = () => {
    //     if (customVariableOptions.length === 0) {
    //         const urlToCall = `${API_CLIENT_CUSTOM_VARIABLE}${activeAccountId}?group=rule`;

    //         callTokenApi(urlToCall, 'GET', null)
    //             .then(response => {
    //                 if (response.status === 200) {
    //                     dispatch(setCustomVariableOptions(response.data.variables));
    //                 }
    //             })
    //     }
    // }

    const fetchData = () => {
        getInitialTableRow();
        // fetchCustomVariables();
    }

    useEffect(fetchData, []);

    const beforeunload = function (e) {
        e.preventDefault();
        e.returnValue = '';
    }

    useEffect(() => {
        dispatch(setUnsavedLookupTableModal({ unsaved: false }));

        let unblock = history.block((location, action) => {
            let url = location.pathname;
            if (!btnSaveStatus) {
                setBtnSaveStatus(true)
                setNavigateTo(url)
                setLeaveComponentModal(true)
                return false
            }
            return true
        });

        if (!btnSaveStatus) {
            document.querySelector('.flexible-modal-body').addEventListener('beforeunload', beforeunload);
        }

        return () => {
            document.querySelector('.flexible-modal-body').removeEventListener('beforeunload', beforeunload);
            unblock();
        };
    }, [btnSaveStatus, history, dispatch])

    const handleAcceptLeaveComponent = () => {
        if (unsavedLookupTableModal.show) {
            const conditions = JSON.parse(JSON.stringify(initialStepsData.conditions));
            const customVariables = JSON.parse(JSON.stringify(initialStepsData.customVariables));

            let table = [];
            let conditionData = conditions.map(item => item);
            let customVariableData = customVariables.map(item => item);
            for (let i = 0; i < conditionData.length; i++) {
                table.push({ conditions: conditionData[i], customVariables: customVariableData[i] });
            }
            setTableRow(table);
            setBtnSaveStatus(true);
            unsavedLookupTableModal.onAccept();
            dispatch(setUnsavedLookupTableModal({ show: false, onAccept: null }));
        } else {
            setBtnAbandon(true)
            window.location.href = navigateTo
        }
    }

    const handleCloseLeaveComponent = () => {
        setLeaveComponentModal(false)
        setBtnSaveStatus(false)
        dispatch(setUnsavedLookupTableModal({ show: false }));
        dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS }));
    }

    const handleAddRow = (rows = 1) => {
        let newTableRow = [...tableRow];
        for (let i = 0; i < rows; i++) {
            let newRow = defaultRow();
            let conjunctions = tableRow[0].conditions.map(item => item.conjunction);

            newRow.conditions.forEach((item, index) => {
                item.conjunction = conjunctions[index];
            });
            newTableRow.push(newRow);
        }
        setTableRow(newTableRow);
        resetBtnSaveStatus();
    }

    const handleSelectOperator = (evt, colIndex) => {
        let { value, name } = evt.target;
        let fields = deepCopyArray(conditions);
        let table = deepCopyArray(tableRow);

        if (name === 'operator') {
            fields[0][colIndex].g0.operator = value;
            table.forEach((row) => {
                row.conditions[colIndex].g0.operator = value;
            })
        } else {
            fields[0][colIndex].conjunction = value;
            table.forEach((row) => {
                row.conditions[colIndex].conjunction = value;
            })
        }
        setTableRow(table);
        setStepsData({ ...stepsData, conditions: fields });
        resetBtnSaveStatus();
    }

    const confirmRemoveRow = () => {
        let data = [...tableRow];
        let { index } = removeRowModal;
        if (data.length > 1) data.splice(index, 1);
        else data = [defaultRow()];
        setTableRow(data);
        setRemoveRowModal({ show: false, index: -1 });
        resetBtnSaveStatus();
    }

    const handleDelete = () => {
        setDeleteLoading(true);
        callTokenApi(`${API_CLIENT_RULE}${stepsData.id}`, 'DELETE')
            .then(response => {
                if (response.status === 200) {
                    toast.success('Rule is deleted!');
                    const usedItems = {
                        itemName: 'numberOfCusVariablesRules',
                        quantity: numberOfCusVariablesRules - 1
                    }

                    dispatch(setUsedItems(usedItems));
                    setBtnSaveStatus(true);

                    if (customVariableRules) {
                        dispatch(setSubscriberState({
                            customVariableRules: customVariableRules.filter((rule) => rule.id !== stepsData.id),
                            initialCustomVariableRules: initialCustomVariableRules.filter((rule) => rule.id !== stepsData.id),
                        }));
                    }

                    dispatch(setRuleHaveEditting({ show: false, type: '', showLv2: false, typeLv2: '' }));
                    dispatch(setFlexibleModal({
                        show: false,
                        showLv2: false,
                        ruleId: null,
                        ruleIdLv2: null,
                        component: '',
                        componentLv2: '',
                    }));
                } else {
                    if (response.data.accountBlocked) {
                        dispatch(setShowBlockAccountPopup(true));
                    } else {
                        toastError(response);
                    }
                }
            })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        let error = validateSubmited();
        if (error.key !== "") {
            if (error.type === "Event") {
                toast.error(`${error.key} is required.`);
            } else {
                toast.error(`${error.key} cannot be empty.`);
            }
        } else {
            setSaveTableModal(true);
        }
    }

    const validateSubmited = () => {
        let data = tableRow;
        let errorColumn = { type: '', key: '' };
        data.some(row => {
            let flag = false;
            flag = row.conditions.some(col => {
                const { type, value, key, operator } = col.g0;
                if (value === "") {
                    if (type === "Event" || OPERATORS_REQUIRE_VALUE.includes(operator)) {
                        errorColumn = { type, key };
                        return true;
                    }
                }
                return false;
            });
            return flag;
        });

        return errorColumn;
    }

    const onAcceptSaveModal = () => {
        let data = { ...stepsData };
        let newConditions = [], newCustomVariables = [];

        tableRow.forEach((row) => {
            let newConditionRow = [];
            row.conditions.forEach((condition, index) => {
                if (index === pusherCondition.length - 1) {
                    condition.conjunction = "";
                }
                newConditionRow.push(condition);
            });

            newConditions.push(newConditionRow);
            newCustomVariables.push(row.customVariables);
        })
        data.conditions = newConditions;
        data.customVariables = newCustomVariables;

        handleSaveLookupTable({
            accountId: data.accountId,
            listenerId: data.listenerId,
            id: data.id,
            conditions: data.conditions,
            customVariables: data.customVariables,
            type: data.type,
            name: data.name,
            description: data.description
        });
        setBtnSaveStatus(true)
    }

    const handleSaveLookupTable = (data) => {
        setSaveLoading(true);
        callTokenApi(API_CLIENT_RULE_UPDATE, 'POST', data)
            .finally(() => {
                setSaveLoading(false);
            })
            .then((response) => {
                if (response.status !== 200) {
                    if (response.data.accountBlocked) {
                        dispatch(setShowBlockAccountPopup(true));
                    } else {
                        toastError(response)
                    }
                } else {
                    setSaveTableModal(false);
                    dispatch(setRuleHaveEditting({ show: false, type: '', showLv2: false, typeLv2: '' }));
                    dispatch(setFlexibleModal({
                        show: false,
                        showLv2: false,
                    }));
                }
            })
    }
    const handleReverting = () => {
        let data = {
            accountId: activeAccountId,
            ruleId: stepsData.id
        }
        setSaveLoading(true);
        callTokenApi(API_CLIENT_RULE_REVERT, 'POST', data)
            .then((response) => {
                if (response.status === 200) {
                    fetchRule();
                } else {
                    if (response.data.accountBlocked) {
                        dispatch(setShowBlockAccountPopup(true));
                    } else {
                        toastError(response);
                    }
                }
            })
            .finally(() => {
                setSaveLoading(false);
                dispatch(setRuleHaveEditting({ show: false, type: '', showLv2: false, typeLv2: '' }));
            });

    }

    const IsExisted = (id, type) => {
        let find = null;
        switch (type) {
            case 'Event':
                if (eventOptions && eventOptions.length > 0) {
                    find = eventOptions.find(item => item.id === id);
                }
                break;
            case 'MyEventNative':
                if (eventNativeOptions && eventNativeOptions.length > 0) {
                    find = eventNativeOptions.find(item => item.id === id);
                }
                break;
            case 'NativeBrowserStorage':
                if (nativeBrowserStorageOptions && nativeBrowserStorageOptions.length > 0) {
                    find = nativeBrowserStorageOptions.find(item => item.id === id);
                }
                break;
            case 'Variable':
                if (variableOptions && variableOptions.length > 0) {
                    find = nonTierVariables.find(item => item.id === id);
                }

                break;
            case 'Selector':
                if (reactSelectSelectorValueOptions && reactSelectSelectorValueOptions.length > 0) {
                    find = reactSelectSelectorValueOptions.find(item => item.id === id);
                }
                break;
            default:
                if (customVariableOptions && customVariableOptions.length > 0) {
                    find = customVariableOptions.find(item => item.id === id);
                }
                break;
        }
        return find;
    }

    const resetBtnSaveStatus = () => {
        if (btnSaveStatus === true) {
            setBtnSaveStatus(false);
        }
        dispatch(setRuleHaveEditting({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.EDIT_TABLE }));
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const rows = reorder(
            tableRow,
            result.source.index,
            result.destination.index
        );
        resetBtnSaveStatus();
        setTableRow(rows);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleChangeRowData = (value, arrayName, rowIndex, colIndex) => {
        setTableRow(prevTableRow => {
            let data = deepCopyArray(prevTableRow);

            if (arrayName === "conditions") {
                data[rowIndex].conditions[colIndex].g0.value = value;
            } else {
                data[rowIndex].customVariables[colIndex].value = value;
            }

            return data;
        })
        resetBtnSaveStatus();
    }

    const toggleVariableModal = useCallback(() => {
        setShowVariableModal(!showVariableModal);
    }, [showVariableModal])

    const headerRowContext = {
        pusherCondition, pusherCustomVariable, conditions,
        IsExisted, handleSelectOperator
    };
    const bodyRowContext = {
        tableRow, setTableRow, resetBtnSaveStatus,
        setRemoveRowModal, IsExisted,
        handleChangeRowData,
        setCursorPosition,
        setVariablePositionToInsert,
        toggleVariableModal,
        customVariableOptions, eventNativeOptions, eventOptions,
        nativeBrowserStorageOptions, reactSelectSelectorValueOptions, variableOptions
    }
    const importExportContext = {
        tableRow,
        setTableRow,
        IsExisted,
        resetBtnSaveStatus
    }

    const handleClickCheatSheat = useCallback(() => {
        setTimeout(() => {
            setshowCheatSheet(!showCheatSheet)
        }, 100);
    }, [showCheatSheet])

    const handleClickOutside = useCallback(() => {
        setshowCheatSheet(false)
    }, [])

    const wrapperRef = useRef(null);
    useOutsideHandling(wrapperRef, handleClickOutside);
    const observedElementsRef = useRef([]);
    useEffect(() => {
        // Handle lockdown table header Start
        let header = document.querySelectorAll('.flexible-modal-header');
        let body = document.querySelectorAll('.flexible-modal-body');
        let table = document.querySelector(".lookup-table-init");
        let stickyTable = document.querySelector(".lookup-table-sticky");
        if (header.length > 0) {
            header = header[!flexibleModal.showLv2 ? 0 : 1];
        }

        if (body.length > 0) {
            body = body[!flexibleModal.showLv2 ? 0 : 1];
        }

        let headerHeight = header.offsetHeight;
        
        const handleResize = () => {
            let tableHeaderTop = table ? table.offsetTop : 0;
            let headerBottom = headerHeight + body.scrollTop;
            let tableHeader = table ? table.childNodes[0] : [];
            let tableHeader2ndRow = tableHeader.childNodes[1];
            let tableHeaderColumns;
            if (tableHeader2ndRow && tableHeader2ndRow.childNodes) {
                tableHeaderColumns = tableHeader2ndRow.childNodes;
            }
            let stickyTableHeader = stickyTable.childNodes[0];
            headerHeight = header.offsetHeight;

            const updateStickyTable = () => {
                stickyTable.style.width = table.parentNode.offsetWidth + 'px';
                stickyTable.style.top = headerHeight + 'px';
                for (let i = 0; i < tableHeaderColumns.length; i++) {
                    stickyTableHeader.childNodes[1].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                    stickyTableHeader.childNodes[2].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                }
            }

            if (headerBottom > tableHeaderTop + 10) {
                if (window.screen.width === 991) {
                    setTimeout(() => {
                        updateStickyTable();
                    }, 600);
                } else {
                    updateStickyTable();
                }
            }
        }
        let modalBody = document.querySelectorAll('.flexible-modal-body');

        if (modalBody.length > 0) {
            modalBody = modalBody[!flexibleModal.showLv2 ? 0 : 1];
        }

        const handleScroll = () => {
            let tableHeaderTop = table ? table.offsetTop : 0;
            let headerBottom = headerHeight + body.scrollTop;
            let tableHeader = table ? table.childNodes[0] : [];
            let tableHeader2ndRow = tableHeader.childNodes ? tableHeader.childNodes[1] : null;
            let tableHeaderColumns;
            if (tableHeader2ndRow && tableHeader2ndRow.childNodes) {
                tableHeaderColumns = tableHeader2ndRow.childNodes;
            }
            let stickyTableHeader = stickyTable.childNodes[0];
            if (headerBottom > tableHeaderTop + 15) {
                tableHeader.style.visibility = 'hidden';
                stickyTable.style.display = 'block';
                stickyTable.style.width = table.parentNode.offsetWidth + 'px';
                stickyTable.style.top = headerHeight + 'px';
                for (let i = 0; i < tableHeaderColumns.length; i++) {
                    stickyTableHeader.childNodes[1].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                    stickyTableHeader.childNodes[2].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                }
            } else {
                table.children[0].style.visibility = 'visible';
                stickyTable.style.display = 'none';
            }
        }
        
        modalBody.addEventListener('scroll', handleScroll);
        const resizeObserver = new ResizeObserver(entries => {
            entries.forEach(() => {
                handleResize();
            });
        });

        const observedElements = document.querySelectorAll(`.ll-flexible-modal`);

        observedElements.forEach(element => {
            resizeObserver.observe(element);
        });

        observedElementsRef.current = Array.from(observedElements);
        return () => {
            resizeObserver.disconnect();
            modalBody.removeEventListener('scroll', handleScroll);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const tableWrapperOnScroll = (e) => {
        let stickyTable = document.querySelector('.lookup-table-sticky');
        let scrollLeft = e.target.scrollLeft;
        stickyTable.scrollLeft = scrollLeft;
    }
    useEffect(() => {
        if (leaveComponentModal || unsavedLookupTableModal.show) {
            setShowVariableModal(false);
        }
    }, [leaveComponentModal, unsavedLookupTableModal])
    return (
        <>
            <CForm className="form-edit-lookup" onSubmit={onSubmit}>
                <CRow>
                    <CCol md='12' className="form-edit-lookup-row">
                        <div className="form-edit-lookup-title">
                            <h1 className="d-flex">
                                {listenerIconSrc && <img className="listener-icon-headline" src={listenerIconSrc} alt={activeListener.name + ' icon'} />}
                                Edit {stepsData.type}: {stepsData.name}
                            </h1>
                        </div>
                        <div className="form-edit-lookup-button">
                            <div className='form-edit-lookup-button d-flex justify-content-end'>
                                <div className='form-edit-lookup-button-child'>
                                    <CButton
                                        onClick={() => setDeleteRuleModal(true)}
                                        className={`${!flexibleModal.showLv2 && 'mr-3'} d-inline-block text-uppercase btn-outline-secondary text-dark`}
                                        disabled={deleteLoading}
                                    >
                                        Delete Rule
                                    </CButton>
                                    {
                                        !flexibleModal.showLv2 && (
                                            <CButton
                                                color="primary"
                                                onClick={() => { handleClickEditButton(stepsData.id) }}
                                                className="d-inline-block text-uppercase"
                                            >
                                                Edit Rule Settings
                                            </CButton>
                                        )
                                    }
                                </div>
                                <div>
                                    {
                                        stepsData.hasOldVersion && (
                                            <CButton
                                                color="primary"
                                                onClick={() => { setRevertModal(true) }}
                                                className="mr-3 d-inline-block text-uppercase"
                                            >
                                                Revert
                                            </CButton>
                                        )
                                    }
                                    <CButton
                                        color="success"
                                        type="submit"
                                        className="d-inline-block text-uppercase"
                                        disabled={btnSaveStatus}
                                    >
                                        Save Changes
                                    </CButton>
                                </div>
                            </div>

                        </div>
                    </CCol>
                    <CCol md='12' className="form-edit-lookup-description">
                        <p>
                            Manage variable values based on the conditions you selected when building this rule.
                            The first <b>{pusherCondition.length}</b> columns of this table represent the conditions you selected in the rule settings.
                            The other <b>{pusherCustomVariable.length}</b> columns each represent a variable value.
                            Define condition values and their subsequent variable values.
                        </p>
                    </CCol>
                </CRow>
                <div className="table-wrapper mb-3" onScroll={(e) => tableWrapperOnScroll(e)}>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="table">
                                        {
                                            provided => (
                                                <>
                                                    <table className="table lookup-table lookup-table-init 123"
                                                        ref={provided.innerRef}
                                                    >
                                                        {
                                                        fetchLoading ? (
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <CenterSpinner/>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        ) : (
                                                            <>
                                                                <thead>
                                                                    <tr className="rule-flow">
                                                                        <td colSpan={pusherCondition.length}>If these conditions are true</td>
                                                                        <td colSpan={pusherCustomVariable.length}>Send these values to my data layer</td>
                                                                    </tr>
                                                                    <LookupTableContext.Provider value={headerRowContext} >
                                                                        <TableHeaderRow />
                                                                    </LookupTableContext.Provider>
                                                                </thead>
                                                                <tbody>
                                                                    <LookupTableContext.Provider value={bodyRowContext} >
                                                                        <TableBodyRows innerRef={provided.innerRef} {...provided.droppableProps} />
                                                                        {provided.placeholder}
                                                                    </LookupTableContext.Provider>
                                                                </tbody>
                                                                </>
                                                        )
                                                    }
                                                    </table>
                                                    <table className="table lookup-table lookup-table-sticky">
                                                        <thead>
                                                            <tr className="rule-flow">
                                                                <td colSpan={pusherCondition.length}>If these conditions are true</td>
                                                                <td colSpan={pusherCustomVariable.length}>Send these values to my data layer</td>
                                                            </tr>
                                                            <LookupTableContext.Provider value={headerRowContext} >
                                                                <TableHeaderRow />
                                                            </LookupTableContext.Provider>
                                                        </thead>
                                                    </table>
                                                </>
                                            )
                                        }
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <div className={`${showCheatSheet ? ' add-margin-cheat-sheet' : ''} form-edit-lookup-row`}>
                                <div className={`add-row-button mb-4 d-flex align-items-center `}>
                                    <div className='add-row-button-child d-flex'>
                                        <CButton
                                            className='btn-add-row'
                                            onClick={() => handleAddRow(1)}
                                        >
                                            <CIcon name='iconAddField' className='icon-add' />
                                            <CLabel className='add-row mb-0'>Add Row</CLabel>
                                        </CButton>
                                        <CButton
                                            className='btn-add-row'
                                            onClick={() => handleAddRow(5)}
                                        >
                                            <CIcon name='iconAddField' className='icon-add' />
                                            <CLabel className='add-row mb-0'>Add 5 Rows</CLabel>
                                        </CButton>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className="regex-wrapper">
                                            <div className="regex-recommemd">
                                                <CIcon name="cil-chevron-double-down" height="14" />
                                                <button type="button" onClick={handleClickCheatSheat}>RegEx Cheatsheet</button>
                                            </div>
                                            <ul ref={wrapperRef} className={showCheatSheet ? '' : 'hidden'}>
                                                {
                                                    REGEX_PATTERNS.map((el, index) => (
                                                        <li key={index}>
                                                            <span>{el.PATTERN}</span>
                                                            <span>{el.DESCRIPTION}</span>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                        <LookupTableContext.Provider value={importExportContext}>
                                            <ImportExportTable />
                                        </LookupTableContext.Provider>
                                    </div>
                                </div>
                                <div className="mb-3 form-edit-lookup-button text-right d-flex justify-content-end">
                                    <div className='form-edit-lookup-button-child'>
                                        <CButton
                                            onClick={() => setDeleteRuleModal(true)}
                                            className={`${!flexibleModal.showLv2 && 'mr-3'} d-inline-block text-uppercase btn-outline-secondary text-dark`}
                                            disabled={deleteLoading}
                                        >
                                            Delete Rule
                                        </CButton>
                                        {
                                            !flexibleModal.showLv2 && (
                                                <CButton
                                                    color="primary"
                                                    onClick={() => { handleClickEditButton(stepsData.id) }}
                                                    className="d-inline-block text-uppercase"
                                                >
                                                    Edit Rule Settings
                                                </CButton>
                                            )
                                        }
                                    </div>
                                    <div>
                                        {
                                            stepsData.hasOldVersion && (
                                                <CButton
                                                    color="primary"
                                                    onClick={() => { setRevertModal(true) }}
                                                    className="mr-3 d-inline-block text-uppercase"
                                                >
                                                    Revert
                                                </CButton>
                                            )
                                        }
                                        <CButton
                                            color="success"
                                            type="submit"
                                            className="d-inline-block text-uppercase"
                                            disabled={btnSaveStatus}
                                        >
                                            Save Changes
                                        </CButton>

                                    </div>
                                </div>
                            </div>
            </CForm>
            <ConfirmRemovePopup
                show={deleteRuleModal}
                onAccept={handleDelete}
                onClose={() => setDeleteRuleModal(false)}
                isLoading={deleteLoading}
            >
                <p className="mb-4">
                    You are about to delete the rule <strong>"{stepsData.name}"</strong>.
                    Please note <span className="text-danger">this action is irreversible</span>.
                </p>
            </ConfirmRemovePopup>
            <RemovePopup
                show={removeRowModal.show}
                onAccept={confirmRemoveRow}
                onClose={() => setRemoveRowModal({ show: false, index: -1 })}
                loading={deleteLoading}
            >
                <p>
                    Are you sure to remove this row?
                </p>
            </RemovePopup>
            <ConfirmSaveChange
                show={saveTableModal}
                onAccept={onAcceptSaveModal}
                onClose={() => setSaveTableModal(false)}
                title="Save Your Changes?"
                isLoading={saveLoading}
            >
                It looks like you have made changes to this lookup table but have not saved them. Would you like to save the changes before navigating away?
            </ConfirmSaveChange>
            <ConfirmSaveChange
                show={revertModal}
                onAccept={handleReverting}
                onClose={() => setRevertModal(false)}
                title="Revert This Rule?"
                isLoading={saveLoading}
            >
                This rule will be reverted to the last version. Would you like to revert it?
            </ConfirmSaveChange>
            <LeaveComponentChangePopup
                show={leaveComponentModal || unsavedLookupTableModal.show}
                onAccept={handleAcceptLeaveComponent}
                onClose={handleCloseLeaveComponent}
                title="You Have Unsaved Changes"
                btnAbandon={btnAbandon ? (<span className="dots-waiting">Waiting</span>) : "Abandon My Changes & Leave"}
            >
                You haven’t saved the changes you started making to this lookup table. If you navigate away, you will lose the changes. What do you want to do?
            </LeaveComponentChangePopup>
            <InsertVariableModal
                showVariableModal={showVariableModal}
                toggleVariableModal={toggleVariableModal}
                handleChangeRowData={handleChangeRowData}
                variableOptions={variableOptions}
                browserVariableShortCodes={browserVariableShortCodes}
                variablePositionToInsert={variablePositionToInsert}
                cursorPosition={cursorPosition}
                tableRow={tableRow}
                listener={activeListener}
                typeRule={TYPE_RULE.CUSTOM_RULE}
            />
        </>
    )
}

LookupTable.propTypes = {
    stepsData: PropTypes.object,
    initialStepsData: PropTypes.object,
    setStepsData: PropTypes.func
}
export default LookupTable
