import React from 'react';
import CIcon from '@coreui/icons-react';
import { CLink } from '@coreui/react';
import { HoverInfoIconTooltip } from '../custom-tooltips';
import TooltipTag from '../TooltipTag';
import { getTagNameTooltip, getTagTypeTooltip } from '../../../helpers/cms/subscriber';

const RuleCardDefault = ({ rule, allowEdit = true, onClickEdit }) => {
     return (
          <div className='row-rule d-flex justify-content-between'>
               <div className='info-wrap d-flex justify-content-between align-items-center'>
                    <div className='info-title'>
                         <h4>{rule.name}</h4>
                         <div className='d-inline-flex'>
                              {rule.description && (
                                   <div className='tooltip-wrapper'>
                                        <HoverInfoIconTooltip>
                                             <p>{rule.description}</p>
                                        </HoverInfoIconTooltip>
                                   </div>
                              )}
                              <TooltipTag name={getTagNameTooltip(rule.type)} description={getTagNameTooltip(rule.type)} type={getTagTypeTooltip(rule.type)} />
                         </div>
                    </div>
               </div>
               <div className='options-wrapper d-flex'>
                    {allowEdit ? (
                         <div className='option-item hover-show-tooltip'>
                              <CLink className='option' onClick={onClickEdit}>
                                   <CIcon name='icon-pencil' height='14' />
                              </CLink>
                              <div className='block-tooltip'>
                                   <CIcon name='arrowTooltip' className='arrow-tooltip-preview' />
                                   <div className='block-tooltip-text'>
                                        <p>Edit Rule</p>
                                   </div>
                              </div>
                         </div>
                    ) : (
                         <div className='option-item hover-show-tooltip'>
                              <CLink className='option' onClick={onClickEdit}>
                                   <CIcon name='icon-eye' height='14' />
                              </CLink>
                              <div className='block-tooltip'>
                                   <CIcon name='arrowTooltip' className='arrow-tooltip-preview' />
                                   <div className='block-tooltip-text'>
                                        <p>View Rule</p>
                                   </div>
                              </div>
                         </div>
                    )}
               </div>
          </div>
     );
};

export default RuleCardDefault;
