import React, { useContext } from 'react';
import CIcon from '@coreui/icons-react';
import { EventExplorerContext } from '../../EventExplorer';

function CustomHeaderCell({
     props,
     dimensions,
     arrShowShortIds,
     showLock,
     lock,
     showShortId,
     sort,
     disableSort,
     setLock,
     setShowShortId,
}) {
     const { field, title } = props;
     const showEyeIcon = dimensions[0] && dimensions.some((item) => arrShowShortIds.includes(item.key) && item.key === field);
     const { setSort } = useContext(EventExplorerContext);

     const handleClickSort = (field) => {
          if (field === 'viewDetail' || disableSort) {
               return;
          }
          if (sort.fieldName !== field) {
               setSort({ fieldName: field, isDesc: true });
          } else {
               if (sort.isDesc) setSort({ fieldName: field, isDesc: false });
               else {
                    setSort({});
               }
          }
     };

     const handleClickLock = (field, event) => {
          event.stopPropagation();
          if (lock[field]) {
               setLock((old) => {
                    return { ...old, [field]: !lock[field] };
               });
          } else {
               setLock((old) => {
                    return { ...old, [field]: true };
               });
          }
     };

     const handleShowShortId = (e, field) => {
          let newShowShortId = [...showShortId];

          if (showShortId.includes(field)) {
               newShowShortId = [...showShortId].filter((item) => item !== field);
          } else {
               newShowShortId.push(field);
          }

          e.stopPropagation();
          setShowShortId(newShowShortId);
     };

     return (
          <span className="k-cell-inner table-head">
               <span className="k-link">
                    <span onClick={() => handleClickSort(field)}>{title}</span>
                    <div
                         className={`lock-column ${showLock[field] && 'show'} ${lock[field] && 'active'}`}
                         onClick={(event) => handleClickLock(field, event)}
                    >
                         <CIcon name="iconLock" className="icon-lock"></CIcon>
                    </div>
                    {showEyeIcon && (
                         <div onClick={(e) => handleShowShortId(e, field)}>
                              <CIcon name={showShortId.includes(field) ? 'iconEyeRegularSlash' : 'iconEyeRegular'}></CIcon>
                         </div>
                    )}

                    {sort.fieldName === field && <CIcon name="iconDropdown" className={`icon-sort ${sort.isDesc ? 'desc' : 'asc'}`}></CIcon>}
               </span>
          </span>
     );
}

export default CustomHeaderCell;
