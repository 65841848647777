import React, { useContext } from 'react';
import { CButton, CCard } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
     AccountDormantBody,
     AccountDormantContext,
} from './AccountDormant';
import { DORMANT_STATUSES, PLATFORM_ACCOUNT_STATUSES } from '../../../../../constants';

const AccountDormantNotice = () => {
     const {
          isExiting, handleExit,
          activeAccount, accounts,
          dormantAccount,
          activatingAccount,
          isCancelingAccount,
          isCustomerSupportLoginAs,
          closeModal,
          handleActiveDormantAccount, showCancelDormantAccount,
          handleCancelDormantPermanentAccount,
          handleCreateAccount,
     } = useContext(AccountDormantContext);

     const activeAccountFilters = accounts ? accounts.filter(account => !DORMANT_STATUSES.includes(account.platformAccountStatus)) : [];
     const showBackButton = activeAccountFilters && activeAccountFilters.length > 0 && (activeAccount.id !== dormantAccount.id || activeAccount.reachTheEndOfSetup)

     return (
          <AccountDormantBody showBackBtn={showBackButton} onBackBtnClicked={closeModal}>
               <CCard className="account-dormant">
                    <h3>This Account is Dormant</h3>
                    {dormantAccount.platformAccountStatus === PLATFORM_ACCOUNT_STATUSES.DORMANT_PERMANENT ? (
                         <>
                              <p className="des">The ListenLayer website account {dormantAccount.name} has been set to dormant because it has not been actively used. The account was created prior to major platform updates, so it can no longer be re-activated.</p>
                              <CButton className="btn-save" disabled={isCancelingAccount || isCustomerSupportLoginAs} onClick={handleCancelDormantPermanentAccount}>
                                   {isCancelingAccount ? (<span className="dots-waiting">Waiting</span>) : (
                                        "DELETE ACCOUNT"
                                   )}
                              </CButton>
                              <span className="foot-note">Only Sandbox accounts or accounts that were not fully configured are set to dormant when they have not been used for a long period of time. You'll need to delete this account and you will then be allowed to create a new one.</span>
                         </>
                    ) : (
                         <>
                              <p className="des">The ListenLayer website account {dormantAccount.name} has been set to dormant because it has not been actively used. You can continue to use this account by activating it, or you can permanently close it.</p>
                              <CButton className="btn-save" disabled={activatingAccount} onClick={handleActiveDormantAccount}>{activatingAccount ? (<span className="dots-waiting">Waiting</span>) : "ACTIVATE ACCOUNT"}</CButton>
                              <CButton className="btn-cancel" onClick={showCancelDormantAccount} disabled={activatingAccount || isCustomerSupportLoginAs}>
                                   Permanently cancel account <CIcon name="cil-arrow-right" />
                              </CButton>
                              <span className="foot-note">Only Sandbox accounts or accounts that were not fully configured are set to dormant when they have not been used for a long period of time. Activating the account will allow you you use it in its most recent state. Canceling the account will remove it permanently and allow you to create a brand new account.</span>
                         </>
                    )}
                    {!isCustomerSupportLoginAs && (
                         <CButton className="btn-success btn-create" onClick={handleCreateAccount} disabled={activatingAccount}>
                              CREATE A NEW ACCOUNT
                         </CButton>
                    )}
               </CCard>
               {isCustomerSupportLoginAs && (
                    <CButton className="btn-sign-out" onClick={handleExit}>
                         <span>
                              EXIT TO CUSTOMER SUPPORT CMS{isExiting && <span className="dots-waiting"></span>}
                         </span>
                    </CButton>
               )}
          </AccountDormantBody>
     );
};

export default AccountDormantNotice;
