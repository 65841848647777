import React, { useState, useCallback, useRef, useContext } from 'react';
import CIcon from '@coreui/icons-react';
import XLSX from 'xlsx';

import { LookupTableContext } from './LookupTable';
import ImportTableModal from './ImportTableModal';
import { useOutsideHandling } from '../../../../../../utils';

const ImportExportTable = () => {
    const { tableRow } = useContext(LookupTableContext);
    const [showImportModal, setShowImportModal] = useState(false);
    const [showImportExport, setShowImportExport] = useState(false);

    const exportToFile = (type = 'csv', fileName = 'table-import-export', onlyRow1And2) => {
        let dataToExport = [['Click URL Element', 'Match Type', 'Value', 'Yes/No']];

        if (!onlyRow1And2) {
            if (Array.isArray(tableRow)) {
                tableRow.forEach(({ conditions, customVariables }) => {
                    dataToExport.push([conditions.clickUrlElement, conditions.matchType, conditions.value, customVariables.trackAsClicks === 'yes' ? 'Yes' : 'No']);
                })
            }
        }

        if (type === 'csv') {
            import('../../../../../../utils').then(utils => {
                utils.exportToCSV(`${fileName}.${type}`, dataToExport);
            });
        } else {
            var workSheet = XLSX.utils.aoa_to_sheet(dataToExport);

            let workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');

            XLSX.writeFile(workBook, `${fileName}.${type}`);
        }
    }

    const handClickImportExport = useCallback(() => {
        setTimeout(() => {
            setShowImportExport(!showImportExport)
        }, 100);
    }, [showImportExport])

    const handleClickOutside = useCallback(() => {
        if (!showImportModal) {
            setShowImportExport(false)
        }
    }, [showImportModal])

    const wrapperRef = useRef(null);
    useOutsideHandling(wrapperRef, handleClickOutside);

    const toggleImportModal = useCallback(() => {
        if (!showImportModal) {
            setShowImportExport(false);
        }

        setShowImportModal(!showImportModal);
    }, [showImportModal])

    return (
        <>
            <div className="import-export-wrapper">
                <div className="import-export">
                    <CIcon name="cil-chevron-double-down" height="14" />
                    <button type="button" onClick={handClickImportExport}>Import/Export</button>
                </div>
                <ul ref={wrapperRef} className={showImportExport ? '' : 'hidden'}>
                    <li>
                        <button color="primary" type="button" onClick={() => exportToFile('csv')}><CIcon name="cil-cloud-download" height="14"></CIcon><span>Export existing table values</span></button>
                    </li>
                    <li>
                        <button color="primary" type="button" onClick={toggleImportModal}><CIcon name="cil-cloud-upload" height="14"></CIcon><span>Import new table values</span></button>
                    </li>
                </ul>
            </div>
            <ImportTableModal
                show={showImportModal}
                toggleModal={toggleImportModal}
                exportToFile={exportToFile}
            />
        </>
    )
}

export default ImportExportTable
