import { CSpinner } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LogRocket from 'logrocket';

import { setUser, setUserRole } from '../actions/common';
import { callTokenApi } from '../apiCaller';
import { API_USER_DATA } from '../constants';
import { signout, isAuth } from '../helpers/auth';
import { gtmTrackingIdentifyLLUser, toastError } from '../utils';

import AdminLayout from './admin/TheLayout';
import SubscriberLayout from './subscriber/TheLayout';
import SupportLayout from './support/TheLayout';
import { setIsCustomerSupportLoginAs } from '../actions/subscriber';
import PasswordUpdate from './admin/PasswordUpdate';

const TheLayout = () => {
     const dispatch = useDispatch();
     const userRole = useSelector((state) => state.theme.userRole);
     const [fetchLoading, setFetchLoading] = useState(true);

     const fetchUserInfo = () => {
          callTokenApi(API_USER_DATA, 'GET', null)
               .then(async (response) => {
                    if (response.status === 200) {
                         const { id, role, firstName, lastName, email, forceChangePassword } = response.data.user;

                         let logRocketData = {
                              email,
                              // Custom user variables
                              role,
                         };
                         let logRocketName = `${firstName || ''} ${lastName || ''}`.trim();

                         if (logRocketName) {
                              logRocketData.name = logRocketName;
                         }

                         // LogRocket identifies the current user
                         LogRocket.identify(id, logRocketData);
                         localStorage.setItem(
                              'user',
                              JSON.stringify({
                                   id,
                                   name: firstName + ' ' + lastName,
                                   email,
                                   role,
                              })
                         );

                         if (role !== 'subscriber') {
                              const userDetails = {
                                   email,
                                   userid: id,
                              };

                              gtmTrackingIdentifyLLUser(userDetails);
                         }

                         dispatch(setIsCustomerSupportLoginAs(!!response.data.isCustomerSupportLoginAs));
                         dispatch(setUser(response.data.user));
                         dispatch(setUserRole({ role, forceChangePassword, id, firstName, lastName }));
                    } else if (response.status === 401) {
                         signout();
                         toastError(response);
                    } else if (response.status === 404) {
                         signout();
                         toastError(response);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => setFetchLoading(false));
     };

     useEffect(fetchUserInfo, []);

     const LayoutComponent = (user) => {
          let content = '';
          if (user.forceChangePassword) {
               return <PasswordUpdate user={user} />;
          }
          switch (user.role) {
               case 'admin':
                    content = <AdminLayout />;
                    break;
               case 'support':
                    content = <SupportLayout />;
                    break;
               case 'subscriber':
                    content = <SubscriberLayout />;
                    break;
               default:
                    content = (
                         <div className="page-loading-container">
                              <CSpinner color="primary" />
                         </div>
                    );
                    break;
          }
          return content;
     };

     return (
          <>
               {isAuth() ? (
                    <>
                         {fetchLoading ? (
                              <div className="page-loading-container">
                                   <CSpinner color="primary" />
                              </div>
                         ) : (
                              LayoutComponent(userRole)
                         )}
                    </>
               ) : (
                    <>{signout()}</>
               )}
          </>
     );
};

export default TheLayout;
