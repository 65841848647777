
import React, { useEffect, useRef } from 'react';
import {
     CButton,
     CModal,
     CModalBody,
     CForm,
     CInput,
     CInvalidFeedback,
     CFormGroup,
     CLabel,
     CTextarea,
} from '@coreui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CIcon from '@coreui/icons-react';

const validationSchema = () => {
     return Yup.object().shape({
          name: Yup.string()
               .min(2, `Name has to be at least 2 characters`)
               .max(50, `Name contains at most 50 characters`)
               .required('Name is required')
          ,
     })
}

const PublishVersionModal = ({ show, onAccept, onCancel }) => {
     const initialValues = {
          name: '',
          description: '',
     };
     const nameInputRef = useRef();

     useEffect(() => {
          if (show) {
               setTimeout(() => {
                    nameInputRef.current.focus();
               }, 0);
          }
     }, [show]);

     return (
          <div className="popup-publish-version">
               <CModal
                    show={show}
                    onClose={onCancel}
                    color="primary"
               >
                    <CModalBody>
                         <CIcon name="cil-x" onClick={onCancel} className="icon-close-popup"></CIcon>
                         <div className="popup-publish-version-description">
                              <h2>Publish Your Changes</h2>
                              <p>Changes that affect your data layer activity must be published before they will appear on your website.</p>
                         </div>
                         <Formik
                              initialValues={initialValues}
                              validationSchema={validationSchema}
                              onSubmit={onAccept}
                              onClose={onCancel}
                              validateOnChange={false}
                              validateOnBlur={false}
                         >
                              {
                                   ({
                                        values,
                                        errors,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                        setFieldError,
                                   }) => {
                                        const handleChangeName = (e) => {
                                             setFieldValue('name', e.target.value)
                                             setFieldError('name', undefined)
                                        }
                                        return (
                                             <CForm onSubmit={handleSubmit} noValidate>
                                                  <CFormGroup>
                                                       <CLabel htmlFor="name">
                                                            Name<span className="text-danger">*</span>
                                                       </CLabel>
                                                       <CInput
                                                            innerRef={nameInputRef}
                                                            id="name"
                                                            placeholder="Name"
                                                            invalid={!!errors.name}
                                                            required
                                                            disabled={isSubmitting}
                                                            value={values.name}
                                                            onChange={handleChangeName}
                                                       />
                                                       <CInvalidFeedback>{errors.name}</CInvalidFeedback>
                                                  </CFormGroup>
                                                  <CFormGroup>
                                                       <CLabel htmlFor="description">Description</CLabel>
                                                       <CTextarea
                                                            id="description"
                                                            placeholder="Description"
                                                            required
                                                            disabled={isSubmitting}
                                                            value={values.description}
                                                            onChange={handleChange}
                                                       />
                                                  </CFormGroup>
                                                  <div className="form-actions">
                                                       <CButton type="submit" color="primary" className="mr-3" disabled={isSubmitting}>
                                                            {isSubmitting ? (<>Waiting<span className="dots-waiting"></span></>) : 'Publish'}
                                                       </CButton>
                                                       <CButton className="btn btn-light" onClick={onCancel}>Cancel</CButton>
                                                  </div>
                                             </CForm>
                                        )
                                   }
                              }
                         </Formik>
                    </CModalBody>
               </CModal>
          </div>
     )
}

export default PublishVersionModal;
