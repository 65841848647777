import React from 'react';
import { CModal, CModalBody, CEmbed } from '@coreui/react';
import PropTypes from 'prop-types';
import CIcon from '@coreui/icons-react';
import YouTubePlayer from 'react-player/youtube';
import VimeoPlayer from 'react-player/vimeo';
import throttle from 'lodash/throttle';

const VideoPopupSetupGuide = ({ show, onCancel, videoLink, vimeoLink, videoName, headline, videoRef, onSuccess, onSetTime }) => {
     const onVideoProgress = throttle(
          (e) => {
               let percentage = Math.round(e.played * 100);
               percentage = percentage === 0 && e.played > 0 ? 1 : percentage;
               if (percentage >= 95) {
                    onSuccess()
               }
          },
          3000,
          { trailing: false }
     );
     const onVideoEnd = () => {
          onSuccess()
     };

     return (
          <div className='learn-video-popup'>
               <CModal show={show} onClose={show} color='primary' centered>
                    <CModalBody className='learn-video-popup-body'>

                         <CIcon name='cil-x' onClick={onCancel} className='icon-close-popup' />
                         {videoLink && (
                              <CEmbed ratio='16by9'>
                                   <YouTubePlayer
                                        ref={videoRef}
                                        className='the-player'
                                        url={videoLink}
                                        controls={true}
                                        onProgress={onVideoProgress}
                                        onEnded={onVideoEnd}
                                        width='100%'
                                        height='100%'
                                        title={`${videoName}`}
                                        config={{
                                             youtube: {
                                                  // https://developers.google.com/youtube/player_parameters?playerVersion=HTML5
                                                  playerVars: {
                                                       rel: 0,
                                                  },
                                             },
                                        }}
                                   />
                              </CEmbed>
                         )}
                         {vimeoLink && (
                              <CEmbed ratio="16by9">
                                   <VimeoPlayer
                                        ref={videoRef}
                                        className='the-player'
                                        url={vimeoLink}
                                        controls={true}
                                        onProgress={onVideoProgress}
                                        onEnded={onVideoEnd}
                                        width='100%'
                                        height='100%'
                                        title={`${videoName}`}
                                   />
                              </CEmbed>
                         )}
                         {headline && (
                              <div className='title roadmap'>
                                   <p>QUICKSTART GUIDE: {headline}</p>
                              </div>
                         )}
                    </CModalBody>
               </CModal>
          </div>
     );
};

VideoPopupSetupGuide.propTypes = {
     show: PropTypes.bool,
     videoLink: PropTypes.string,
     featureName: PropTypes.string,
     onCancel: PropTypes.func,
};

export default VideoPopupSetupGuide;
