import React from 'react';
import {
     CModal,
     CModalBody,
     CButton,
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setShowPublishPopup } from '../../../actions/subscriber';

const PublishBeforeDowngradePopup = ({ show, onClose, onCloseNext }) => {
     const dispatch = useDispatch();

     const onPublishBtnClicked = () => {
          onClose();
          onCloseNext();
          dispatch(setShowPublishPopup(true));
     }

     return (
          <div className="remove-popup variable-in-rules publish-downgrade">
               <CModal
                    show={show}
                    onClose={onClose}
                    color="primary"
                    centered
               >
                    <CModalBody className="text-center">
                         <CIcon
                              name="cil-x"
                              onClick={onClose}
                              className="icon-close-popup"
                         ></CIcon>
                         <div className="remove-popup-icon"><CIcon name="icon-delete" className="text-danger" height="38" alt="CoreUI Icons Delete" /></div>
                         <h2>Warning!</h2>
                         <div className="remove-popup-description variable-in-rules-description">
                              Please publish your account's latest version
                         </div>
                         <div className="mb-3">
                              <CButton
                                   className="d-inline-block"
                                   color='primary'
                                   onClick={onPublishBtnClicked}
                              >
                                   Publish
                              </CButton>
                         </div>
                    </CModalBody>
               </CModal>
          </div>
     )
}

PublishBeforeDowngradePopup.defaultProps = {
     onCloseNext: f => f
}

PublishBeforeDowngradePopup.propTypes = {
     show: PropTypes.bool, 
     onClose: PropTypes.func,
     onCloseNext: PropTypes.func
}

export default PublishBeforeDowngradePopup
