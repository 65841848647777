import React, { useContext, useCallback } from 'react';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';

import { AsideContext, ASIDE_COMPONENTS } from './TheAside';

const DowngradeConfirmation = () => {
     const { tempPackage, setPackageSelected, setCurrentComponents } = useContext(AsideContext);

     const goBack = useCallback(() => {
          setCurrentComponents(ASIDE_COMPONENTS.COMPARE_PLANS);
     }, [setCurrentComponents])

     const onAccept = useCallback(() => {
          setPackageSelected(tempPackage);
          setCurrentComponents(ASIDE_COMPONENTS.SLIDE_OUT_PAYMENT);
     }, [tempPackage, setPackageSelected, setCurrentComponents])

     return (
          <div className="full-screen-wrapper">
               <CButton
                    className="btn-back"
                    onClick={goBack}
               >
                    <i className="fal fa-angle-left"></i>
                    Back
               </CButton>
               <CIcon
                    name="logo-dark"
                    height={25}
               />
               <h3>Are You Sure?</h3>
               <h6>If you downgrade, you will lose many of the settings and customizations in your account.</h6>
               <div className="confirmation-buttons">
                    <CButton
                         className="mr-4"
                         color="dark"
                         onClick={goBack}
                    >
                         Do Not Downgrade
                    </CButton>
                    <CButton
                         color="light"
                         onClick={onAccept}
                    >
                         Yes, Please Downgrade
                    </CButton>
               </div>
          </div>
     )
}

export default DowngradeConfirmation
