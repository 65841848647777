import React from 'react';
import CIcon from '@coreui/icons-react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import { SUBSCRIBER_PATH } from '../../../constants';
import { TOURSTEPS } from '../TheTour';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../../components/general/dropdown';
import { handleToggleSidebar } from '../../../utils';

const Thesetting = () => {
    const history = useHistory();
    const { showTour, stepTour } = useSelector(state => state.subscriber.tour);
    const activeAccount = useSelector(state => state.subscriber.activeAccount);

    const handleClickLink = (url, showNavchild = false) => {
        handleToggleSidebar({ toggleShowNavChild: true, forceShowNavChild: showNavchild });
        setTimeout(() => {
            history.push(url.replace(':secondId', activeAccount.secondId));
        }, 350);
    }

    return (
        <Dropdown
            isHeaderDropdown
            inNav
            className="c-header-nav-items user-nav-item"
            direction="down"
            forceOpen={showTour && stepTour === TOURSTEPS.accessSettings.orderNumber}
        >
            <DropdownToggle
                className="c-header-nav-link"
                caret={false}
            >
                <div className="c-avatar cil-setting">
                    <CIcon name="cil-settings" />
                </div>

            </DropdownToggle>
            <DropdownMenu
                className="pt-0 setting-dropdown"
                placement="bottom-end"
            >
                <CIcon name="arrow-account" className="arrow-account arrow-setting" />
                <DropdownItem
                    className={`setting ${stepTour === TOURSTEPS.accessSettings.orderNumber ? "tour-active" : ""}`}
                    header
                    tag="div"
                    to={SUBSCRIBER_PATH.WEBSITE}
                >
                    {/* <CIcon name="arrow-account" className="arrow-account arrow-setting" /> */}
                </DropdownItem>
                <DropdownItem onClick={() => handleClickLink(SUBSCRIBER_PATH.WEBSITE)}>
                    <div>
                        <p className='settingtille'>
                            Website
                            <CIcon name="logo-right" width="6" className='logo-right' />
                        </p>
                        <p className='settingdescription'>Domains & Script Placement</p>
                    </div>
                </DropdownItem>
                <DropdownItem onClick={() => handleClickLink(SUBSCRIBER_PATH.USERS)}>
                    <div>
                        <p className='settingtille'>Users
                            <CIcon name="logo-right" width="6" className='logo-right' />
                        </p>
                        <p className='settingdescription'>Manage Access</p>
                    </div>
                </DropdownItem >
                <DropdownItem onClick={() => handleClickLink(SUBSCRIBER_PATH.ACCOUNT_SETTINGS)}>
                    <div>
                        <p className='settingtille'>Account Settings
                            <CIcon name="logo-right" width="6" className='logo-right' />
                        </p>
                        <p className='settingdescription'>Name, Timezone & Timestamps</p>
                    </div>

                </DropdownItem>
                <DropdownItem onClick={() => handleClickLink(SUBSCRIBER_PATH.PLAN_USAGE)}>
                    <div>
                        <p className='settingtille'>Plans & Usage
                            <CIcon name="logo-right" width="6" className='logo-right' />
                        </p>
                        <p className='settingdescription'>Subscription & Data Use</p>
                    </div>
                </DropdownItem>
                <DropdownItem onClick={() => handleClickLink(SUBSCRIBER_PATH.BILLING_INVOICES)}>
                    <div>
                        <p className='settingtille'>Billing & Invoices
                            <CIcon name="logo-right" width="6" className='logo-right' />
                        </p>
                        <p className='settingdescription'>Payments & Terms</p>
                    </div>
                </DropdownItem >
                {/* <DropdownItem onClick={() => handleClickLink(SUBSCRIBER_PATH.USERS)}>
                    <div>
                        <p className='settingtille'>Users
                            <CIcon name="logo-right" width="6" className='logo-right' />
                        </p>
                        <p className='settingdescription'>Manage Access</p>
                    </div>
                </DropdownItem > */}
            </DropdownMenu>
        </Dropdown>
    )
}

export default React.memo(Thesetting)
