import React, { useContext, useCallback } from 'react';
import { CButton, CCard } from '@coreui/react';
import CIcon from '@coreui/icons-react';

import { AsideContext, ASIDE_COMPONENTS } from './TheAside';

const ActionRequired = ({ onClose }) => {
    const { setCurrentComponents } = useContext(AsideContext);

    const goBack = useCallback(() => {
        setCurrentComponents(ASIDE_COMPONENTS.COMPARE_PLANS);
    }, [setCurrentComponents])

    const onAccept = useCallback(() => {
        setCurrentComponents(ASIDE_COMPONENTS.LIMITATION_HIT);
    }, [setCurrentComponents])

    const onClosePopup = () => {
        onAccept()
        onClose()
    }

    return (
        <div className='full-screen-wrapper'>
            <div className="action-required-popup">
                <div className="account-header">
                    <CButton className="btn-back btn-back-choose" onClick={goBack}>
                        <i className="fal fa-angle-left"></i>
                        Back
                    </CButton>
                    <CIcon
                        name="logo-dark"
                        height={34}
                    />
                    <CCard className="action-required">
                        <div className="confirm-save-icon"><CIcon name="icon-save" height="46" width="46" /></div>
                        <div className='action-required-title'>
                            <h2>Action is Required</h2>
                        </div>
                        <div className='action-required-description'>
                            <p>It is not currently possible to downgrade to this plan because your account is using features not supported at this level. Before you can downgrade, please disable specific listeners, features, and other elements of your account and publish those changes. We require you to do this so that we are not assuming what features should be disabled in your lower account level.</p>
                        </div>
                        <div className='action-required-button'>
                            <CButton
                                className="btn-primary"
                                onClick={onClosePopup}
                            >
                                Go to my account
                            </CButton>
                        </div>
                    </CCard>
                    <div className="footer text-center">
                        <CIcon
                            name="logo-dark"
                            height={34}
                        />
                        <div className="d-flex justify-content-center">
                            <div className="text-footer">Support</div>
                            <div className="text-footer text-terms">Terms of Use</div>
                            <div className="text-footer text-privacy">Privacy & Data Policy</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActionRequired
