import React, { useEffect, useState } from 'react';
import { CCol, CModal, CModalBody, CRow } from '@coreui/react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import Filters from './Filters';
import ReportingChart from './ReportingChart';
import { DEFAULT_DATE_FORMAT } from '../../../../constants';
import CIcon from '@coreui/icons-react';
import { setVariableValueChart } from '../../../../actions/subscriber';

const VariableValueModal = ({ variableValueToShow, setVariableValueToShow, parentCustomFilterType, parentFilterType, parentFilterBeginDate, parentFilterEndDate }) => {
    const dispatch = useDispatch();
    const variableValueChart = useSelector(state => state.subscriber.variableValueChart);
    const activeAccountTimeZone = useSelector(state => state.subscriber.activeAccount.timeZone);
    const [filterType, setFilterType] = useState(parentFilterType);
    const [customFilterType, setCustomFilterType] = useState(parentCustomFilterType);
    const [filterBeginDate, setFilterBeginDate] = useState(parentFilterBeginDate);
    const [filterEndDate, setFilterEndDate] = useState(parentFilterEndDate);

    let fromDay, endDay;

    if (filterBeginDate) {
        fromDay = dayjs(filterBeginDate).format(DEFAULT_DATE_FORMAT);
    } else {
        fromDay = activeAccountTimeZone ? dayjs().tz(activeAccountTimeZone).format(DEFAULT_DATE_FORMAT) : dayjs().format(DEFAULT_DATE_FORMAT);
    }

    if (filterEndDate) {
        endDay = dayjs(filterEndDate).format(DEFAULT_DATE_FORMAT);
    } else {
        endDay = activeAccountTimeZone ? dayjs().tz(activeAccountTimeZone).format(DEFAULT_DATE_FORMAT) : dayjs().format(DEFAULT_DATE_FORMAT);
    }

    const setData = () => {
        setFilterType(parentFilterType);
        setCustomFilterType(parentCustomFilterType);
        setFilterBeginDate(parentFilterBeginDate);
        setFilterEndDate(parentFilterEndDate);
    };

    useEffect(setData, [parentCustomFilterType, parentFilterType, parentFilterBeginDate, parentFilterEndDate]);

    const onClose = () => {
        setData();
        setVariableValueToShow({ id: '', value: '' });

        if (variableValueChart.keysConcept.length > 0) {
            dispatch(setVariableValueChart({
                chartData: {
                    charts: [],
                    labels: []
                },
                filter: '',
                keysConcept: [],
                selected: ''
            }));
        }
    }

    return (
        <div className="variable-value-chart dashboard-event">
            <CModal
                show={!!variableValueToShow.id}
                onClose={onClose}
                centered
            >
                <CModalBody>
                    <CIcon
                        name="cil-x"
                        onClick={onClose}
                        className="icon-close-popup"
                    ></CIcon>
                    <CRow>
                        <CCol sm="3">
                            <h4 className="card-title mb-0">Event</h4>
                        </CCol>
                        <CCol sm="9 text-right">
                            <Filters
                                filterType={filterType}
                                setFilterType={setFilterType}
                                customFilterType={customFilterType}
                                setCustomFilterType={setCustomFilterType}
                                filterBeginDate={filterBeginDate}
                                setFilterBeginDate={setFilterBeginDate}
                                filterEndDate={filterEndDate}
                                setFilterEndDate={setFilterEndDate}
                            />
                        </CCol>
                    </CRow>
                    <ReportingChart
                        variableValueToShow={variableValueToShow}
                        filter={customFilterType}
                        selected={filterType}
                        fromDay={fromDay}
                        endDay={endDay}
                    />
                </CModalBody>
            </CModal>
        </div>
    )
}

export default VariableValueModal;
