import CIcon from '@coreui/icons-react';
import { CButton, CForm, CFormGroup, CInput, CInvalidFeedback, CModal, CModalBody } from '@coreui/react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { handleUpdateCustomCategories, handleUpdateCustomCategoriesDetails } from '../../../../../../actions/subscriber';
import { validateFormik } from '../../../../../../utils';

const ModalCreateCategories = ({
     show,
     onCancel,
     fewForms,
     itemChoose,
     setFewForms,
     setFewFormsEdit,
     setFormRequiringEdit,
     setFormInventoryEdit,
     setFormIgnoreEdit,
}) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const listCustomCategories = useSelector((state) => state.subscriber.customCategories);
     const [formLoading, setFormLoading] = useState(false);
     let code = (Math.floor(Math.random() * 5555) + 1111).toString().substring(0, 4);
     const initialValues = {
          id: `categories${code}`,
          name: '',
          flg_add: true,
          isChecked: true,
          accountId: activeAccount.id,
     };

     const onSubmit = (values, { setErrors }) => {
          const checkVariable = listCustomCategories && listCustomCategories.find((item) => item.name === values.name.trim());
          if (checkVariable) {
               setErrors({ name: 'Name category already existed.' });
          } else {
               if (values.name) {
                    let customCategories = [values];

                    customCategories.map((item)=>{
                         item.name = item.name.trim();
                         return item;
                    })

                    const nextDispatch = {
                         setIsLoading: setFormLoading,
                         onClose: onCancel,
                         fewForms,
                         itemChoose,
                         setFewForms,
                         setFormRequiringEdit,
                         setFormInventoryEdit,
                         setFormIgnoreEdit,
                    };

                    if (setFormRequiringEdit || setFormInventoryEdit) {
                         dispatch(handleUpdateCustomCategoriesDetails(customCategories, nextDispatch));
                    } else {
                         dispatch(handleUpdateCustomCategories(customCategories, nextDispatch));
                    }
               }
          }
     };
     return (
          <div className="confirm-save-popup popup-create-categories">
               <CModal show={show} onClose={onCancel} color="primary" centered>
                    <CModalBody className="text-center confirm-save">
                         <div className="popup-publish-version-description">
                              <div className="confirm-save-icon">
                                   <CIcon name="icon-save" height="46" width="46" />
                              </div>
                              <h1>Create New Custom Category</h1>
                         </div>
                         <Formik
                              initialValues={initialValues}
                              validate={validateFormik(validationSchema)}
                              validateOnChange={false}
                              validateOnBlur={false}
                              onSubmit={onSubmit}
                         >
                              {({ values, errors, handleChange, handleSubmit, handleBlur }) => {
                                   return (
                                        <CForm onSubmit={handleSubmit} noValidate>
                                             <CFormGroup className="mb-4 mt-3">
                                                  <CInput
                                                       name="name"
                                                       placeholder="enter category name"
                                                       autoComplete="name"
                                                       invalid={!!errors.name}
                                                       value={values.name}
                                                       onChange={handleChange}
                                                       onBlur={handleBlur}
                                                  />
                                                  <CInvalidFeedback>{errors.name}</CInvalidFeedback>
                                             </CFormGroup>
                                             <div className="form-actions">
                                                  <CButton type="submit" className="btn-primary" disabled={formLoading}>
                                                       {formLoading ? (
                                                            <>
                                                                 Waiting<span className="dots-waiting"></span>
                                                            </>
                                                       ) : (
                                                            'Save'
                                                       )}
                                                  </CButton>
                                                  <CButton onClick={onCancel} className=" btn btn-cancel rounded-pill cancel">
                                                       CANCEL
                                                  </CButton>
                                             </div>
                                        </CForm>
                                   );
                              }}
                         </Formik>
                    </CModalBody>
               </CModal>
          </div>
     );
};
const validationSchema = () => {
     return Yup.object().shape({
          name: Yup.string().trim().required('This field name cannot be empty!').max(120, 'This field name cannot be more than 120 characters!'),
     });
};
export default ModalCreateCategories;
