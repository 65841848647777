import React, { useContext, useState } from 'react';
import { CCard, CCardBody, CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import { useDispatch, useSelector } from 'react-redux';
import { callTokenApi } from '../../../../../apiCaller';
import { API_CLIENT_USER_INFO, DORMANT_STATUSES } from '../../../../../constants';
import { setUser } from '../../../../../actions/common';
import { toastError } from '../../../../../utils';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import { useHistory } from 'react-router-dom';
import { urlParams } from '../../../../../helpers/cms/subscriber';
import { setShowAccountDormantPopup, setShowCreateAccountPopup } from '../../../../../actions/subscriber';

const UserCompliant = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.theme.user);
    const { stepsData, setStepsData, setCurrentStep, prevSteps } = useContext(CreateAccountContext);
    const accounts = useSelector((state) => state.subscriber.accounts);
    const dormantAccount = useSelector((state) => state.subscriber.dormantAccount);
    const [isLoading, setIsLoading] = useState(false);
    const dataUser = (user && JSON.parse(user.registerUserInfo)) || JSON.parse(stepsData.dataUser)

    const _arrSlug = [
        'masterclass',
        'certification',
        'office-hours',
        'become-a-partner',
        'support-tickets',
        'profile',
        'email-notifications',
        'payment-methods',
    ];

    const defaultPackageIdParam = urlParams('defaultPackageId');
    const _pathName = window.location.pathname.replace('/', '');

    const activeAccounts = accounts ? accounts.filter(account => !DORMANT_STATUSES.includes(account.platformAccountStatus)) : [];
    const showBackBtn = (activeAccounts.length > 0 || dormantAccount || (user && user.registerMasterClass) || !!_arrSlug.find(slug => _pathName.includes(slug)));

    const onBackBtnClicked = () => {
        if (user) {
            if (showBackBtn && prevSteps.length <= 0) {
                if (dormantAccount) {
                    dispatch(setShowAccountDormantPopup(true));
                }
                dispatch(setShowCreateAccountPopup(false));
                if (defaultPackageIdParam) {
                    history.push('/');
                }
            }
            else {
                setCurrentStep(null, CHANGE_STEP_TYPE.subStepPrev);
            }
        }
        else {
            setCurrentStep(STEPS.userIdentify)
        }

    };

    const goToNextStep = () => {
        setCurrentStep(null, CHANGE_STEP_TYPE.mainStepNext);
    };

    const saveData = (dataUser) => {
        setIsLoading(true)
        callTokenApi(API_CLIENT_USER_INFO, 'PUT', { registerUserInfo: dataUser })
            .then(response => {
                if (response.status === 200) {
                    setStepsData({ ...stepsData, dataUser: JSON.stringify(dataUser) })
                    dispatch(setUser({ ...user, registerUserInfo: JSON.stringify(dataUser) }));
                    goToNextStep();
                } else {
                    setIsLoading(false)
                    toastError(response);
                }
            })
    };

    const goNext = (value) => {
        if (user) {
            if (dataUser.compliant) {
                if (dataUser.compliant === value) {
                    goToNextStep();
                } else {
                    dataUser.compliant = value;
                    saveData(dataUser);
                }
            } else {
                dataUser.compliant = value;
                saveData(dataUser);
            }
        } else {
            const dataUser = JSON.parse(stepsData.dataUser)
            dataUser.compliant = value
            setStepsData({ ...stepsData, dataUser: JSON.stringify(dataUser) });
            setCurrentStep(STEPS.userReplace)
        }
    };

    return (
        <CreateAccountBody hideStepProgress={true} onBackBtnClicked={onBackBtnClicked}>
            <CCard className="account-almost-done account-dyn ">
                <CCardBody>
                    <h3>Did You Know?</h3>
                    <p className="des">
                        ListenLayer is cookieless and works with GDPR, CCPA, and other regulations.
                    </p>
                    {isLoading ? (
                        <CenterSpinner />
                    ) : (
                        <div>
                            <div className="notice">
                                <div className='d-flex'>
                                    <div className="icon-container">
                                        <CIcon name="iconCheckCircleBlack" width={22} height={22} />
                                    </div>
                                    <p>Allowing you to simplify compliance.</p>
                                </div>
                            </div>
                            <p className='des-revenue'>
                                Is that valuable to you?
                            </p>
                            <div>
                                <CButton className="btn-save" onClick={() => goNext(true)}>
                                    Yes
                                </CButton>
                            </div>
                            <CButton className="btn-navigation-step" onClick={() => goNext(false)}>
                                No, that's not valuable
                            </CButton>
                        </div>
                    )}
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default UserCompliant;
