import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
     CCreateElement,
     CSidebar,
     CSidebarBrand,
     CSidebarNav,
     CSidebarNavDivider,
     CSidebarNavTitle,
     CSidebarNavDropdown,
     CSidebarNavItem,
     CNavItem,
     // CProgress,
     CButton,
     CLink,
     // CSwitch
     // CSpinner,
     // CSidebarMinimizer
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useHistory, useLocation } from 'react-router-dom';

import { setSidebarShow, setAsideShow, setFlexibleModal } from '../../actions/common';
import { getListenerNav } from '../../helpers/cms/subscriber';
import { signout } from '../../helpers/auth';
import { SUBSCRIBER_PATH, LIMITATION_TYPE, SUBSCRIBER_MY_ACCOUNT_PATH, LOWERCASE_CLICK_FEATURES, COMPONENT_NAME } from '../../constants';

// sidebar nav config
import { defaultSubscriberNav, subscriberMyAccountNav } from '../_nav';
import { setShowUpgradeAccountPopup, setSubscriberState, setLimitationType, toggleAsideLimitationHit, setUnsavedLookupTableModal } from '../../actions/subscriber';

// const infinityIcon = <i className="fal fa-infinity"></i>

const SubscriberMyAccountSidebar = () => {
     return (
          <>
               <CSidebarNav className="my-account-nav">
                    {/* <CNavItem className="c-sidebar-nav-item">
                         <div className="c-sidebar-nav-link nav-link-my-account">My Account</div>
                    </CNavItem> */}
                    <CCreateElement
                         items={subscriberMyAccountNav}
                         components={{
                              CSidebarNavDivider,
                              CSidebarNavDropdown,
                              CSidebarNavItem,
                              CSidebarNavTitle
                         }}
                    />
                    <CNavItem className="c-sidebar-nav-item" onClick={signout}>
                         <div className="c-sidebar-nav-link" >
                              <CIcon name="cil-account-logout" className="c-sidebar-nav-icon" />
                              Sign out
                         </div>
                    </CNavItem>
               </CSidebarNav>
          </>
     )
}

const FeatureSubNav = ({ to }) => {
     return (
          <li className="feature-note">
               <p><CLink to={to}>Turn on features</CLink> for this Listener to generate data.</p>
          </li>
     )
}

const SubscriberSidebar = () => {
     const location = useLocation();
     const history = useHistory();
     const dispatch = useDispatch();
     const sidebarRef = useRef(null);
     const show = useSelector(state => state.theme.sidebarShow);
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const activeListeners = useSelector(state => state.subscriber.activeListeners);
     const activeListener = useSelector(state => state.subscriber.activeListener);
     const activeFeatures = useSelector(state => state.subscriber.activeFeatures);
     // const { showNewLayout } = useSelector(state => state.subscriber.newLayout);
     const [isScrollEnd, setIsScrollEnd] = useState(false);
     let activeCustomValuesFeatures = activeFeatures.filter(feature => {
          let strToCompare = feature.code.toLowerCase();
          return strToCompare.includes('myvalues') || strToCompare.includes('customvalues');
     })
     let activeBrowserStorageFeatures = activeFeatures.filter(feature => feature.code.toLowerCase().includes('browserstorage'));
     let activeMonitoringFeatures = activeFeatures.filter(feature => feature.code.toLowerCase().includes('monitoring'));
     let activeReportingFeatures = activeFeatures.filter(feature => feature.code.toLowerCase().includes('reporting'));
     let activePopulateFeatures = activeFeatures.filter(feature => feature.code.toLowerCase().includes('populate'));
     let activeSelectorFeatures = activeFeatures.filter(feature => feature.code.toLowerCase().includes('selector'));
     let activeUserSource = activeFeatures.filter(feature => feature.code.toLowerCase() === 'usersource');
     let activeClickFeatures = activeFeatures.filter(feature => LOWERCASE_CLICK_FEATURES.includes(feature.code.toLowerCase()));
     // Show custom values first, then browser storage, then monitoring and then reporting
     let specificFeatures = [
          ...activeUserSource,
          ...activeClickFeatures,
          ...activeCustomValuesFeatures,
          ...activeBrowserStorageFeatures,
          ...activeMonitoringFeatures,
          ...activeReportingFeatures,
          ...activePopulateFeatures,
          ...activeSelectorFeatures
     ];
     const listenersLoading = useSelector(state => state.subscriber.loadings.listeners);
     const isLookupTableUnsaved = useSelector(state => state.subscriber.unsavedLookupTableModal.unsaved);
     let showMyAccount = Object.entries(SUBSCRIBER_MY_ACCOUNT_PATH).some(([_, value]) => location.pathname.includes(value));

     const executeSidebarScroll = () => {
          if (sidebarRef !== null && sidebarRef.current !== null) {
               sidebarRef.current.scrollIntoView();
          }
     }

     const handleClickNavMenu = () => {
          dispatch(setSubscriberState({
               activeListener: {}
          }))
     }

     const handleClickUpgradeReporting = () => {
          dispatch(toggleAsideLimitationHit());
          dispatch(setLimitationType(LIMITATION_TYPE.UPGRADE_REPORTING, false));
          dispatch(setAsideShow(true));
     }

     useEffect(() => {
          const unlisten = history.listen(() => {
               let pathName = history.location.pathname;
               if (pathName && pathName === `/${activeAccount.secondId}`) {
                    executeSidebarScroll();
               }
          });

          return () => unlisten();
     }, []) // eslint-disable-line react-hooks/exhaustive-deps

     const listenerNav = getListenerNav(activeListeners, specificFeatures, activeFeatures, activeListener, handleClickNavMenu);
     const listenerSettingsNav = [
          {
               _tag: 'CSidebarNavTitle',
               _children: ['LISTENER SETTINGS']
          },
          ...listenerNav
     ];

     const getNewDefaultSubscriberNav = useCallback((activeAccount) => {
          const consentNav = {
               _tag: 'CSidebarNavDropdown',
               name: 'Consent & Personal Data',
               route: SUBSCRIBER_PATH.CONSENT_LISTENER,
               icon: 'cil-layers',
               _children: [
                    {
                         _tag: 'CSidebarNavItem',
                         name: 'Consent Rules',
                         to: '/consent-rule',
                         exact: false
                    },
               ],
          };

          if (activeAccount.isFree === false) {
               consentNav._children.unshift({
                    _tag: 'CSidebarNavItem',
                    name: 'Consent Listener',
                    to: '/consent-listener',
                    exact: false
               });
          }

          if (activeAccount.id) {
               return [...defaultSubscriberNav, consentNav];
          }

          return defaultSubscriberNav;
     }, [])

     const newDefaultSubscriberNav = useMemo(() => getNewDefaultSubscriberNav(activeAccount), [activeAccount, getNewDefaultSubscriberNav]);

     // const {
     //      numberOfEventsPerMonth
     // } = activeAccount;

     // const eventNav = [
     //      {
     //           _tag: 'CSidebarNavTitle',
     //           _children: ['EVENT REPORTING']
     //      },
     //      {
     //           _tag: 'CSidebarNavItem',
     //           name: 'Coming Soon',
     //           icon: 'cil-graph'
     //      },
     // ];

     // if (activeAccount.id) {
     // const {
     //      packageNumberOfEventsPerMonth,
     // } = activeAccount;

     // var usedEvents = numberOfEventsPerMonth;
     // var totalEvents = packageNumberOfEventsPerMonth;
     // var usedEventsPerMonthPercent = ((usedEvents / totalEvents) * 100);
     // }

     // const onClickEventContainer = () => {
     //      history.push(`${SUBSCRIBER_PATH.ACCOUNT_SETTINGS}`)

     //      // Scroll to Account Plang & Usage section
     //      setTimeout(() => {
     //           const planUsageContainer = document.getElementsByClassName('account-plan-usage')[0];
     //           const header = document.getElementsByTagName('header')[0];

     //           if (planUsageContainer && header) {
     //                window.scrollTo({
     //                     // Header position is sticky, if scrolling to planUsageContainer.offsetTop, header will overflow a part of planUsageContainer,
     //                     // so subtract header's height to fix it
     //                     top: planUsageContainer.offsetTop - header.offsetHeight,
     //                     behavior: 'smooth',
     //                })
     //           } else {
     //                window.scrollTo({
     //                     top: 481,
     //                     behavior: 'smooth',
     //                })
     //           }
     //      }, 100);
     // }

     const onClickUpgrade = () => {
          if (isLookupTableUnsaved) {
               dispatch(setUnsavedLookupTableModal({ show: true, onAccept: () => dispatch(setShowUpgradeAccountPopup(true)) }));
          } else {
               dispatch(setShowUpgradeAccountPopup(true));
          }
     }

     const scrollEnd = (e) => {
          const isAtTheEnd = e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight;
          setIsScrollEnd(isAtTheEnd);
     }

     const handleClickListenerLink = () => {
          dispatch(
               setFlexibleModal({
                    show: true,
                    component: COMPONENT_NAME.LISTENER_LIBRARY,
               })
          );
     };

     // const switchLayout = () => {
     //      dispatch(setNewLayout({ showNewLayout: !showNewLayout }));
     //      history.push('/');
     // }

     return (
          <CSidebar
               show={show}
               unfoldable
               onShowChange={(val) => dispatch(setSidebarShow(val))}
               className={`c-sidebar-left ${showMyAccount ? "subscriber-my-account-sidebar" : ""}`}
          >
               <CSidebarBrand className="d-md-down-none" to={`/${activeAccount.secondId}`}>
                    {
                         showMyAccount ? (
                              <CIcon
                                   className="c-sidebar-brand-full"
                                   name="logo-dark"
                                   height={35}
                              />
                         ) : (
                              <CIcon
                                   className="c-sidebar-brand-full"
                                   name="logo-light"
                                   height={35}
                              />
                         )
                    }
                    <CIcon
                         className="c-sidebar-brand-minimized"
                         name="sygnet"
                         height={35}
                    />
               </CSidebarBrand>
               {
                    showMyAccount ? (
                         <SubscriberMyAccountSidebar />
                    ) : (
                         <>
                              {
                                   activeAccount.id && (
                                        <>
                                             <CSidebarNav onScroll={scrollEnd}>
                                                  <div ref={sidebarRef}></div>
                                                  <CCreateElement
                                                       items={newDefaultSubscriberNav}
                                                       components={{
                                                            CSidebarNavDivider,
                                                            CSidebarNavDropdown,
                                                            CSidebarNavItem,
                                                            CSidebarNavTitle
                                                       }}
                                                  />
                                                  <CSidebarNavDivider />
                                                  {
                                                       listenersLoading ? (
                                                            <div className="text-center">
                                                                 {/* <CSpinner color="primary" /> */}
                                                            </div>
                                                       ) : (
                                                            <>
                                                                 <CCreateElement
                                                                      items={listenerSettingsNav}
                                                                      components={{
                                                                           CSidebarNavDivider,
                                                                           CSidebarNavDropdown,
                                                                           CSidebarNavItem,
                                                                           CSidebarNavTitle,
                                                                           FeatureSubNav
                                                                      }}
                                                                 />
                                                                 {
                                                                      listenerNav.length === 0 && (
                                                                           <CNavItem className="px-3 d-compact-none c-d-minimized-none nav-listener-setting">
                                                                                <CLink onClick={handleClickListenerLink} className="text-primary">Turn on listeners</CLink>{' '} under integrations and then manage them here.
                                                                           </CNavItem>
                                                                      )
                                                                 }
                                                            </>
                                                       )
                                                  }
                                                  <li className="c-sidebar-nav-title">EVENT REPORTING</li>

                                                  <li className="c-sidebar-nav-item">
                                                       {activeAccount.packageEventReporting ? (
                                                            <CLink className="nav-item c-sidebar-nav-link" to={SUBSCRIBER_PATH.GLOBAL_REPORTING}>
                                                                 <CIcon name="cil-graph" className="c-sidebar-nav-icon" />Global Reporting
                                                            </CLink>
                                                            // <div className="nav-item c-sidebar-nav-link disabled">
                                                            //      <CIcon name="cil-graph" className="c-sidebar-nav-icon" />Coming Soon
                                                            // </div>
                                                       )
                                                            : (
                                                                 <div className="upgrade-reporting">
                                                                      <p><button onClick={handleClickUpgradeReporting}>Upgrade</button> your account for data layer reporting</p>
                                                                 </div>
                                                            )
                                                       }
                                                  </li>

                                                  {/* <CSidebarNavItem disabled name="Coming Soon" icon="cil-graph" /> */}

                                                  {/* <CCreateElement
                                                            items={eventNav}
                                                            components={{
                                                                 CSidebarNavDivider,
                                                                 CSidebarNavDropdown,
                                                                 CSidebarNavItem,
                                                                 CSidebarNavTitle
                                                            }}
                                                       />
                                                       <CSidebarNavDivider /> */}
                                             </CSidebarNav>
                                             <CSidebarNav className={`system-utilization ${isScrollEnd ? '' : 'scroll-footer'}`}>
                                                  {/* <div>
                                                       <CSidebarNavTitle>SYSTEM UTILIZATION</CSidebarNavTitle>
                                                       <CNavItem className="statistics-item d-compact-none c-d-minimized-none" onClick={onClickEventContainer}>
                                                            <p className="title-statistics">Events</p>
                                                            <CProgress size="xs" value={totalEvents === -1 ? 0 : usedEventsPerMonthPercent} color="success" />
                                                            <small className="text-muted">{usedEvents.toLocaleString('en-US')} of {totalEvents === -1 ? infinityIcon : totalEvents.toLocaleString('en-US')} monthly events used</small>
                                                       </CNavItem>
                                                  </div> */}
                                                  <div>
                                                       <CNavItem className="p-3 btn-packages">
                                                            <CButton className="rounded-pill"
                                                                 color="success w-100"
                                                                 onClick={onClickUpgrade}
                                                            >
                                                                 Upgrade
                                                            </CButton>
                                                            <CButton
                                                                 className="btn-compare text-capitalize"
                                                                 onClick={onClickUpgrade}
                                                            >
                                                                 Compare Plans
                                                            </CButton>
                                                       </CNavItem>
                                                  </div>
                                             </CSidebarNav>
                                             {/* <CSidebarNav className="switch-layout">
                                                  <CSwitch
                                                       className='mb-0 mr-2'
                                                       color={showNewLayout ? 'success' : 'light'}
                                                       checked={showNewLayout}
                                                       size="sm"
                                                       tabIndex="0"
                                                       shape="pill"
                                                       onChange={switchLayout}
                                                  />
                                                  <span className="switch-layout-text">Switch Layout</span>
                                             </CSidebarNav> */}
                                        </>
                                   )
                              }
                         </>
                    )
               }
               {/* <CSidebarMinimizer className="c-d-md-down-none" /> */}
          </CSidebar>
     )
}

export default React.memo(SubscriberSidebar);
