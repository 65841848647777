import React, { useContext, useEffect, useRef, useState } from 'react';
import { EventExplorerContext } from '../EventExplorer';
import {
     API_CLIENT_GET_MAX_STEP_FORM_CATEGORY,
     API_CLIENT_REPORTING_EXPLORER,
     API_CLIENT_REPORTING_EXPLORER_PROFILE,
     CHECK_LIST_OPERATOR_OPTION,
     DEFAULT_DATE_FORMAT,
     REPORT_DATA_TYPES,
     REPORT_NAMES,
} from '../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { handleChangeColSort, getCustomPropertiesReport } from '../../../../../utils/UtilReport';
import { fetchDimensionsRequest, setReloadReportData } from '../../../../../actions/subscriber';
import { callTokenApiCancel, callTokenApi } from '../../../../../apiCaller';
import { toastError } from '../../../../../utils';

const useFetchData = (props) => {
     const {
          reportName,
          filterMetric,
          filterDimensions,
          filterProperties,
          filterEvent,
          setReportData,
          filterEventReport,
          setOffSet,
          scoped,
          reportType,
          conversionName,
          conversionNameMetric,
          dateRangeConfig,
          setReportCompare,
          setChangeData,
          recordProfile,
          setMetricsStepForm,
          customProperties,
          newProperties: propertiesAll,
          handleGetOption,
          weight,
          utmArray,
          setMaxStepForm,
          eCoItemCategories,
          offSet,
          setAttributeBeforeReload,
          setTempReport,
     } = useContext(EventExplorerContext);
     const {
          setIsLoading,
          itemsPerPage,
          activePage,
          reloadTable,
          sort,
          setShowLongLoading,
          finalActivePage,
          pagination,
          reloadReportData,
          setIsLoadingFetch,
          setReportIsCancel,
     } = props;
     let { dimensions, metrics } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     if (reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT) {
          dimensions = [...dimensions, ...utmArray];
          metrics = handleGetOption({ option: metrics, type: 'weight', reportName, weight });
     }
     if (reportName === REPORT_NAMES.ECOMMERCE_ITEMS) {
          dimensions = [...dimensions, ...eCoItemCategories];
     }
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const customDimensions = useSelector((state) => state.subscriber.customReport.dimensions);
     const [skipFetch, setSkipFetch] = useState(false);
     const jobId = useRef('');
     const dependencies = [
          itemsPerPage,
          activePage,
          reloadTable,
          filterEventReport,
          sort.fieldName,
          sort.isDesc,
          dateRangeReports,
          pagination,
          weight,
     ];
     const [cancelApi, setCancelApi] = useState({});
     const [dimensionEvents, setDimensionEvents] = useState([]);
     const [isWebConversion, setIsWebConversion] = useState(false);
     const dispatch = useDispatch();

     const useQuery = () => {
          const { search } = useLocation();

          return React.useMemo(() => new URLSearchParams(search), [search]);
     };
     const query = useQuery();
     const dataConversionID = query.get('conversionID');

     const newDateRange = {
          selection: {
               startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
               endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
          },
     };
     if (dateRangeReports.compare) {
          newDateRange.compare = {
               startDate: dateRangeReports.compare.startDate.format(DEFAULT_DATE_FORMAT),
               endDate: dateRangeReports.compare.endDate.format(DEFAULT_DATE_FORMAT),
          };
     }
     if (dateRangeConfig.dateScope) {
          newDateRange.dateScope = dateRangeConfig.dateScope;
     }

     let filterConversionID;
     if (dataConversionID) {
          filterConversionID = [
               {
                    type: 'conversionId',
                    value: dataConversionID,
                    operator: 'eq',
               },
          ];
     }

     const handleOrderCol = (arr, arrFilter) => {
          const newArr = [];
          arr.forEach((item) => {
               if (arrFilter.some((el) => el.value === item.key)) {
                    newArr.push(item.key);
               }
          });
          return newArr;
     };
     const newDimensions = handleOrderCol(dimensions, filterDimensions);
     const newMetric = handleChangeColSort([...conversionNameMetric, ...metrics], filterMetric).map((i) => i.key);
     const newProperties = handleOrderCol(propertiesAll, filterProperties);
     const filter = dataConversionID ? filterConversionID : filterEvent;
     const newFilter = filter.map((item) => {
          const newItem = { ...item };

          if (item.operator === CHECK_LIST_OPERATOR_OPTION.value) {
               newItem.value = item.value && Array.isArray(item.value) ? [...item.value].map((el) => el.value) : [];
          }
          delete newItem['optionsOperator'];
          return newItem;
     });
     const fetchDimensions = () => {
          const accountId = activeAccount.id;
          dispatch(fetchDimensionsRequest(accountId));
     };

     useEffect(fetchDimensions, [activeAccount.id]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (!customDimensions) return;
          const listCustomPropertyEvent = customDimensions.filter((item) =>
               item.availableScopes.some((scope) => scope.isWebConversion && filterProperties.some((option) => option.label === item.name))
          );
          setDimensionEvents(listCustomPropertyEvent);
          if (listCustomPropertyEvent && listCustomPropertyEvent.length > 0) {
               setIsWebConversion(true);
          } else {
               setIsWebConversion(false);
          }
     }, [customDimensions, filterProperties]); // eslint-disable-line react-hooks/exhaustive-deps

     let bodyData = {
          reportName,
          accountId: activeAccount.id,
          dateRanges: newDateRange,
          limit: itemsPerPage,
          offset: offSet,
          metrics: newMetric,
          properties: newProperties,
          dimensions: newDimensions,
          filter: newFilter,
          sort: sort,
          reportType: reportType,
          scoped: scoped,
          conversionName: conversionName.filter(
               (conversion) => newMetric.find((metric) => metric.includes(conversion.value)) && conversion.label.includes('Conv')
          ),
          eCommerceName: conversionName.filter((conversion) => newMetric.includes(conversion.value) && conversion.label.includes('Ecommerce')),
          isCompare: dateRangeConfig.comparePeriod,
          recordProfile,
          customProperties: getCustomPropertiesReport(customProperties, newProperties),
          utmArray,
          weight,
     };

     if (reportName === 'conversionEvents') {
          bodyData.isWebConversion = isWebConversion;
          bodyData.dimensionEvent = dimensionEvents;
     }

     const handleGenerationMetricsStepForm = (number) => {
          const metricsStepForm = [];
          for (let i = 1; i <= number; i++) {
               metricsStepForm.push(
                    { key: `step${i}View`, label: `Step ${i} - View` },
                    { key: `step${i}Start`, label: `Step ${i} - Start` },
                    { key: `step${i}Complete`, label: `Step ${i} - Complete` }
               );
          }
          return metricsStepForm;
     };

     const callApi = ({ API_NAME, apiCancel = false, timeout }) => {
          const apiFunction = apiCancel ? callTokenApiCancel : callTokenApi;
          jobId.current = '';
          apiFunction(API_NAME, 'POST', bodyData).then((response) => {
               if (response && response.status === 200) {
                    const {
                         overallRowCount,
                         rows,
                         event,
                         totalsRow,
                         compareData,
                         totalRows,
                         message,
                         jobId: newJobId,
                         temp,
                         tempCompare,
                    } = response.data;

                    if (message) {
                         if (newJobId) {
                              jobId.current = newJobId;
                         }

                         setIsLoadingFetch(true);
                    } else {
                         setReportData({ overallRowCount, rows, event, totalsRow });
                         setReportCompare({ rows: compareData, totalsRow: totalRows });

                         setIsLoadingFetch(false);
                         setIsLoading(false);
                         setChangeData(false);

                         setAttributeBeforeReload({
                              dimensions: bodyData.dimensions,
                              properties: bodyData.properties,
                              metrics: bodyData.metrics,
                         });
                         if (temp || tempCompare) {
                              setTempReport({ temp, tempCompare });
                         }
                         if (timeout) {
                              clearTimeout(timeout);
                         }

                         setShowLongLoading(false);
                         dispatch(
                              setReloadReportData({
                                   ...reloadReportData,
                                   tableName: '',
                              })
                         );
                    }
               } else {
                    response && toastError(response);
               }
          });
     };

     const callApiFormReport = ({ API_NAME, apiCancel = false, timeout }) => {
          const apiFunction = apiCancel ? callTokenApiCancel : callTokenApi;
          if (cancelApi[API_CLIENT_REPORTING_EXPLORER]) {
               cancelApi[API_CLIENT_REPORTING_EXPLORER].cancel('Request cancelled due to a new request.');
          }
          jobId.current = '';
          apiFunction(API_NAME, 'POST', bodyData, setCancelApi)
               .then((response) => {
                    if (response && response.status === 200) {
                         const dataMetricFormStep = {};

                         dataMetricFormStep.metricsStepForm = handleGenerationMetricsStepForm(response.data.maxStepForm);
                         setMetricsStepForm(dataMetricFormStep.metricsStepForm);
                         dataMetricFormStep.maxStepForm = response.data.maxStepForm;

                         return dataMetricFormStep;
                    } else {
                         // setIsLoading(false);
                         // setChangeData(false);
                         // if (id) {
                         //      clearTimeout(id);
                         //      setShowLongLoading(false);
                         // }
                         response && toastError(response);
                    }
               })
               .then((res) => {
                    if (res) {
                         const valueFilterMetric = filterMetric.map((item) => item.value);
                         // const stepMetricsAdded = res.metricsStepForm.filter((item) => valueFilterMetric.includes(item.key));
                         // const newMetricStepForm = handleChangeColSort([...stepMetricsAdded], [...handleGetOption({ option: stepMetricsAdded })]).map(
                         //      (i) => i.key
                         // );
                         // console.log('stepMetricsAdded', stepMetricsAdded);
                         bodyData.metrics = [...valueFilterMetric];

                         setAttributeBeforeReload({
                              dimensions: bodyData.dimensions,
                              properties: bodyData.properties,
                              metrics: bodyData.metrics,
                         });

                         bodyData.maxStepForm = res.maxStepForm;
                         setMaxStepForm(res.maxStepForm);
                         callTokenApiCancel(API_CLIENT_REPORTING_EXPLORER, 'POST', bodyData, setCancelApi).then((response) => {
                              if (response && response.status === 200) {
                                   const {
                                        overallRowCount,
                                        rows,
                                        event,
                                        totalsRow,
                                        compareData,
                                        totalRows,
                                        message,
                                        jobId: newJobId,
                                        temp,
                                   } = response.data;
                                   if (temp) {
                                        setTempReport({ temp, tempCompare: '' });
                                   }
                                   if (message) {
                                        if (newJobId) {
                                             jobId.current = newJobId;
                                        }
                                        setIsLoadingFetch(true);
                                   } else {
                                        setIsLoadingFetch(false);
                                        setReportData({ overallRowCount, rows, event, totalsRow });
                                        setReportCompare({ rows: compareData, totalsRow: totalRows });

                                        setIsLoading(false);
                                        setChangeData(false);
                                        setShowLongLoading(false);

                                        if (timeout) {
                                             clearTimeout(timeout);
                                        }

                                        dispatch(
                                             setReloadReportData({
                                                  ...reloadReportData,
                                                  tableName: '',
                                             })
                                        );
                                   }
                              } else {
                                   response && toastError(response);
                              }
                         });
                    }
               });
     };

     // .finally(() => {
     //      if (returnToPage1) {
     //           setSkipFetch(true);
     //           setActivePage(1);
     //      }
     // });

     let timeout;

     const fetchData = (refresh) => {
          if (activePage === 1 && skipFetch) {
               setSkipFetch(false);
               return;
          }
          if (!refresh) {
               setOffSet((finalActivePage - 1) * itemsPerPage);
          }
          if (filterDimensions.length === 0) return;

          setIsLoading(true);
          setReportIsCancel(false);

          if (!refresh) {
               timeout = setTimeout(() => {
                    setChangeData(false);
                    setShowLongLoading(true);
               }, 6000);
          } else {
               if (timeout) {
                    clearTimeout(timeout);
               }
          }

          if (recordProfile) {
               callApi({ API_NAME: API_CLIENT_REPORTING_EXPLORER_PROFILE.replace(':reportName', reportName), apiCancel: true, timeout });
          } else {
               if (reportName === REPORT_NAMES.FORM_CATEGORY_REPORT) {
                    callApiFormReport({ API_NAME: API_CLIENT_GET_MAX_STEP_FORM_CATEGORY, apiCancel: true, timeout });
               } else {
                    callApi({ API_NAME: API_CLIENT_REPORTING_EXPLORER, apiCancel: true, timeout });
               }
          }

          return () => {
               if (timeout) {
                    clearTimeout(timeout);
               }
          };
     };

     useEffect(fetchData, dependencies);

     // refresh api reporting explorer
     useEffect(() => {
          if (jobId.current === reloadReportData.jobId) {
               if (reloadReportData.status === 'done') {
                    if (reloadReportData && reloadReportData.reportName === reportName) {
                         if (reloadReportData.tableName) {
                              bodyData.tableName = reloadReportData.tableName;
                         }

                         if (reloadReportData.tableNameCompare) {
                              bodyData.tableNameCompare = reloadReportData.tableNameCompare;
                         }
                    }
                    fetchData(true);
               } else {
                    setReportData({ overallRowCount: 0, rows: [], totalsRow: 0 });
                    setReportCompare({ rows: null, totalsRow: 0 });
                    setIsLoading(false);
                    setChangeData(false);
                    setShowLongLoading(false);
                    dispatch(
                         setReloadReportData({
                              ...reloadReportData,
                              tableName: '',
                         })
                    );
                    setIsLoadingFetch(false);
                    if (timeout) {
                         clearTimeout(timeout);
                    }

                    if (reloadReportData.status === 'cancel') {
                         setReportIsCancel(true);
                    }
               }
          }
     }, [reloadReportData.refreshRequest]); // eslint-disable-line
};

export default useFetchData;
