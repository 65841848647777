import React, { useContext, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'; // useSelector & useDispatch can only be used in functional component

import { AsideContext } from '../TheAside';
import { roundTo2DecimalPlaces } from '../../../../utils';
import { useIsLatestPlan } from '../../../../helpers/customHooks';
import LegacyPlanRecommendation from './LegacyPlanRecommendation';

const UpgradeMonitoring = () => {
     const { packageSelected, setPackageSelected } = useContext(AsideContext);
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const packages = useSelector(state => state.subscriber.packages);
     const nextPackageIndex = useSelector(state => state.subscriber.nextPackageIndex);
     const nextPackage = packages[nextPackageIndex];
     const isLatestPlan = useIsLatestPlan();

     const {
          packageNumberOfEventMonitor: currentMaxEventMonitor
     } = activeAccount;

     const {
          numberOfEventMonitor: recommendationMaxEventMonitor,
          friendlyName: recommendationFriendlyName,
          pricePerYear: recommendationPricePerYear,
     } = packageSelected || nextPackage;

     const findRecommendationPackage = useCallback(() => {
          let recommendationPackage;

          if (currentMaxEventMonitor === recommendationMaxEventMonitor) {
               recommendationPackage = packages.find(el => (el.numberOfEventMonitor === -1 || el.numberOfEventMonitor > currentMaxEventMonitor));
          }

          if (recommendationPackage) {
               setPackageSelected(recommendationPackage);
          }
     }, [currentMaxEventMonitor, recommendationMaxEventMonitor, packages, setPackageSelected])

     useEffect(findRecommendationPackage, []);

     return (
          <>
               <div className="description">
                    <p className="text-center fw-4">
                         {
                              currentMaxEventMonitor === 0 ?
                                   "Upgrade your account to gain access to monitoring, which allows you to write rules to receive notifications based on data layer events, " +
                                   "or the lack of events. Use this to ensure you know if your tracking breaks, or use it to easily monitor specific activity on your website." :
                                   `Your current account level allows you to have ${currentMaxEventMonitor} monitoring rules. Upgrade to create additional rules.`
                         }
                    </p>
               </div>
               <div className="sidebar-body">
                    <h6 className="text-success text-center recommendation">Recommendation</h6>
                    {
                         isLatestPlan ? (
                              <p className="text-center recommendation-text fw-4">
                                   Upgrade to the {recommendationFriendlyName} plan for ${roundTo2DecimalPlaces(recommendationPricePerYear / 12).toLocaleString('en-US')}/month (paid annually) and{" "}
                                   gain access to create {recommendationMaxEventMonitor === -1 ? 'unlimited' : recommendationMaxEventMonitor}{" "}
                                   monitoring {(recommendationMaxEventMonitor === -1 || recommendationMaxEventMonitor > 1) ? 'rules' : 'rule'}, along with other great features!
                              </p>
                         ) : <LegacyPlanRecommendation />
                    }
               </div>
          </>
     )
}

export default UpgradeMonitoring;