import React, { useState, useEffect, useCallback } from 'react';
import {
     useSelector,
     useDispatch
} from 'react-redux';
import {
     CModal,
     CButton,
     CContainer,
} from '@coreui/react';
import Cookies from 'js-cookie';
import CIcon from '@coreui/icons-react';
import { useHistory } from 'react-router-dom';

import {
     toastError,
     gtmTrackingChangeLLAccount,
} from '../../../../../utils';
import {
     AUTH_PATH,
     DORMANT_STATUSES,
     API_SUPPORT_EXIT,
     API_CLIENT_CANCEL_ACCOUNT,
     LOCAL_SETUP_INVITED_ACCOUNT,
     API_CLIENT_FIND_USER_ACCOUNT,
     API_CLIENT_ACTIVE_DORMANT_ACCOUNT,
     LOCAL_SHOW_ONLY_ENABLED_LISTENERS,
     LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS,
     PLATFORM_ACCOUNT_STATUSES,
} from '../../../../../constants';
import {
     setRoutes,
     setAccounts,
     fetchAnAccount,
     fetchUserAccounts,
     setDormantAccount,
     setActiveListener,
     resetSubscriberReducer,
     setShowCreateAccountPopup,
     setShowAccountDormantPopup,
     fetchMonitoringNotifications,
     setIsFromGetStarted,
} from '../../../../../actions/subscriber';
import CancelAccount from './CancelAccount';
import AccountCanceled from './AccountCanceled';
import { signout } from '../../../../../helpers/auth';
import { callTokenApi } from '../../../../../apiCaller';
import AccountDormantNotice from './AccountDormantNotice';
import { getSupportRedirectChannelId } from '../../../../../helpers/cms/support';
import { useCompletedPercentage } from '../../../../../helpers/customHooks';

export const AccountDormantContext = React.createContext({});
export const STEPS = {
     accountDormantNotice: 'This Account is Dormant',
     cancelAccount: 'Cancel Account',
     accountCanceled: 'Account Canceled',
};

export const AccountDormantBody = ({ showBackBtn = true, onBackBtnClicked, children, mainContentClassName = '' }) => {
     return (
          <CContainer className="main-container">
               <div className="d-flex align-items-center m-0 header">
                    {showBackBtn && (
                         <CButton className='btn-back m-0' onClick={onBackBtnClicked}>
                              Back
                         </CButton>
                    )}
                    <div className="hero-logo">
                         <CIcon name="logo-dark" height={23} />
                    </div>
               </div>
               <div className={`main-content ${mainContentClassName}`}>
                    {children}
               </div>
               <div className="footer-create-account">
                    <div className="logo-footer">
                         <CIcon name="logo-dark" height={23} />
                    </div>
               </div>
          </CContainer>
     );
}

const AccountDormant = () => {
     const history = useHistory();
     const dispatch = useDispatch();
     const user = useSelector(state => state.theme.user);
     const accounts = useSelector(state => state.subscriber.accounts);
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const dormantAccount = useSelector((state) => state.subscriber.dormantAccount);
     const showPopup = useSelector((state) => state.subscriber.showAccountDormantPopup);
     const isCustomerSupportLoginAs = useSelector(state => state.subscriber.isCustomerSupportLoginAs);
     const [isExiting, setIsExiting] = useState(false);
     const [activatingAccount, setActivatingAccount] = useState(false);
     const [isCancelingAccount, setCancelingAccount] = useState(false);
     const [currentStep, setCurrentStep] = useState(STEPS.accountDormantNotice);
     const percentage = useCompletedPercentage({ completedSteps: activeAccount.completedSteps });
     const displayGetStartedBar = (!activeAccount.isComplete && percentage > 0 && percentage < 100);
     const { id: userId } = user;

     const checkActiveAccountDormant = () => {
          if (activeAccount && (DORMANT_STATUSES.includes(activeAccount.platformAccountStatus))) {
               dispatch(setDormantAccount(activeAccount));
               dispatch(setShowAccountDormantPopup(true));
          }
     }
     useEffect(checkActiveAccountDormant, [activeAccount]);

     const handleWhenHideAndShowPopup = () => {
          if (showPopup) {
               setTimeout(() => document.body.classList.add('no-scroll'), 0);
          } else {
               document.body.classList.remove('no-scroll');
          }
     };
     useEffect(handleWhenHideAndShowPopup, [showPopup]);

     const closeModal = () => {
          dispatch(setDormantAccount(null));
          dispatch(setShowAccountDormantPopup(false));
          setCurrentStep(STEPS.accountDormantNotice);
     };

     const handleActiveDormantAccount = () => {
          setActivatingAccount(true);
          callTokenApi(API_CLIENT_ACTIVE_DORMANT_ACCOUNT, 'POST', { accountId: dormantAccount.id }).then(response => {
               if (response.status === 200) {
                    if (activeAccount.id === dormantAccount.id && displayGetStartedBar && !activeAccount.reachTheEndOfSetup) {
                         closeModal();
                         dispatch(setIsFromGetStarted(true));
                         dispatch(setShowCreateAccountPopup(true));
                    } else {
                         selectAccount(dormantAccount);
                    }
               } else {
                    toastError(response);
               }
          }).finally(() => setActivatingAccount(false))
     }

     const showCancelDormantAccount = () => {
          setCurrentStep(STEPS.cancelAccount);
     }

     const handleCancelDormantAccount = (_, { setSubmitting }) => {
          callTokenApi(API_CLIENT_CANCEL_ACCOUNT, 'DELETE', { accountId: dormantAccount.id, isCancelAccount: true }).then(response => {
               if (response.status === 200) {
                    const newAccounts = accounts.filter(account => {
                         return account.id !== dormantAccount.id;
                    })
                    dispatch(setAccounts(newAccounts));
                    setCurrentStep(STEPS.accountCanceled);
               } else {
                    toastError(response);
               }
          }).finally(() => setSubmitting(false))
     }

     const handleCancelDormantPermanentAccount = () => {
          setCancelingAccount(true);
          callTokenApi(API_CLIENT_CANCEL_ACCOUNT, 'DELETE', { accountId: dormantAccount.id, isCancelAccount: true }).then(response => {
               if (response.status === 200) {
                    const newAccounts = accounts.filter(account => {
                         return account.id !== dormantAccount.id;
                    })
                    dispatch(setAccounts(newAccounts));
                    setCurrentStep(STEPS.accountCanceled);
               } else {
                    toastError(response);
               }
          }).finally(() => setCancelingAccount(false))
     }

     const selectAccount = (newAccount) => {
          if (newAccount && newAccount.id !== activeAccount.id) {
               dispatch(setRoutes([]));
               dispatch(resetSubscriberReducer());
               dispatch(fetchAnAccount(newAccount, accounts));
               dispatch(fetchMonitoringNotifications(newAccount.id));
               dispatch(setActiveListener(null));

               localStorage.setItem('accountId', newAccount.id);
               localStorage.removeItem(LOCAL_SHOW_ONLY_ENABLED_LISTENERS);
               localStorage.removeItem(LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS);
               history.push('/');

               let localSetupInvitedAccount = localStorage.getItem(LOCAL_SETUP_INVITED_ACCOUNT);
               callTokenApi(`${API_CLIENT_FIND_USER_ACCOUNT}${userId}/${newAccount.id}`, 'GET', null).then(response => {
                    if (response.status === 200) {
                         const { userAccount } = response.data;
                         const userDetails = {
                              userId: user.id,
                              email: user.email
                         };

                         const _activeAccount = accounts.find(acc => acc.id === userAccount.accountId);
                         const accountDetails = {
                              accountid: _activeAccount.id,
                              accountname: _activeAccount.name
                         };
                         gtmTrackingChangeLLAccount(userDetails, accountDetails);
                         if (localSetupInvitedAccount === 'yes' || (userAccount && !userAccount.firstLoginAccount && typeof userAccount.firstLoginAccount !== undefined && userAccount.firstLoginAccount !== 'undefined')) {
                              localStorage.removeItem(LOCAL_SETUP_INVITED_ACCOUNT);
                              const hash = history.location.hash;
                              if (!hash || hash !== '#payment-method') {
                                   history.push(AUTH_PATH.WELCOME_SCREEN);
                              }
                         }
                         closeModal();
                    }
               });
          } else if (newAccount) {
               dispatch(fetchUserAccounts(newAccount.id, true, () => { }));
               dispatch(setDormantAccount(null));
               closeModal();
          }
     }

     const handleCreateAccount = () => {
          // dispatch(setDormantAccount(null));
          dispatch(setShowAccountDormantPopup(false));
          dispatch(setShowCreateAccountPopup(true));
          setCurrentStep(STEPS.accountDormantNotice);
     }

     const handleManagerAccount = () => {
          const accountNotDormantPermanent = accounts.filter(account => account.platformAccountStatus !== PLATFORM_ACCOUNT_STATUSES.DORMANT_PERMANENT);
          if (accountNotDormantPermanent.length > 0) {
               if (activeAccount.id === dormantAccount.id) {
                    selectAccount(accountNotDormantPermanent[0])
               } else {
                    dispatch(setDormantAccount(null));
                    dispatch(setShowAccountDormantPopup(false));
                    setCurrentStep(STEPS.accountDormantNotice);
               }
          } else {
               closeModal();
               handleCreateAccount();
          }
     }

     const handleExit = () => {
          setIsExiting(true);
          callTokenApi(`${API_SUPPORT_EXIT}?redirectChannelId=${getSupportRedirectChannelId()}`, 'GET').then(response => {
               setIsExiting(false);
               const { token, expirationDate } = response.data;
               if (response.status === 200) {
                    let tokenOptions = { expires: new Date(expirationDate) };
                    if (window.location.hostname !== 'localhost') {
                         tokenOptions.domain = 'listenlayer.com';
                    }
                    Cookies.set('token', token, tokenOptions);
                    window.location.replace('/accounts');
               } else {
                    signout(true);
               }
          })
     }

     const getCurrentComponent = useCallback(() => {
          switch (currentStep) {
               case STEPS.accountDormantNotice:
                    return dormantAccount ? <AccountDormantNotice /> : <></>;
               case STEPS.cancelAccount:
                    return dormantAccount ? <CancelAccount /> : <></>;
               case STEPS.accountCanceled:
                    return <AccountCanceled />;
               default:
                    return <></>;
          }
     }, [currentStep, dormantAccount]);

     const contextValue = {
          currentStep, setCurrentStep,
          isExiting, handleExit,
          activeAccount, accounts,
          dormantAccount,
          activatingAccount,
          isCancelingAccount,
          isCustomerSupportLoginAs,
          closeModal,
          handleActiveDormantAccount, showCancelDormantAccount,
          handleCancelDormantAccount, handleCancelDormantPermanentAccount,
          handleCreateAccount, handleManagerAccount,
     }

     return (
          <div className={`create-account-popup account-dormant-popup ${showPopup ? '' : 'd-none'}`}>
               <AccountDormantContext.Provider value={contextValue}>
                    <CModal show={showPopup} onClose={closeModal} closeOnBackdrop={false}>
                         <div className="modal-body">
                              {getCurrentComponent()}
                         </div>
                    </CModal>
               </AccountDormantContext.Provider>
          </div>
     );
};

export default AccountDormant;
