import React, {useEffect} from "react";
import {
    CModal,
    CModalBody,
    CCarousel,
    CCarouselInner,
    CCarouselItem,
    CCarouselControl,
    CModalHeader
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import PropTypes from 'prop-types';

const ShowImage = ({ show, onClose, imgList, imageActive }) => {

     const setActionVideo = () => {
          setTimeout(() => {
               let listCarouselItem = document.querySelectorAll('.carousel-item')
               for (let i = 0; i < listCarouselItem.length; i++) {
                    if (listCarouselItem[i].classList.contains("active")) {
                         if (listCarouselItem[i].querySelector('video')) {
                              listCarouselItem[i].querySelector('video').play();
                         }
                    } else {
                         if (listCarouselItem[i].querySelector('video')) {
                              listCarouselItem[i].querySelector('video').pause();
                         }
                    }
               }
          }, 100)
     }

     useEffect(() => {
          setActionVideo()
     }, [])

     const changeSlider = () => {
          setActionVideo()
     }
     
     return (
          <div className="attachments-popup">
               <CModal className="image-ticket" size="lg" show={show} centered={true} onClose={onClose}>
                    <CModalHeader>
                         <CIcon
                         name="cil-x"
                         onClick={onClose}
                         className="icon-close-popup"
                         ></CIcon>
                    </CModalHeader>
                    <CModalBody className="text-center">
                         <div className="slider-box">
                              <CCarousel activeIndex={imageActive}>
                                   <CCarouselInner>
                                        {
                                             imgList.map((data, index) => {
                                                  if (data.type.includes('video')) {
                                                       return (
                                                            <CCarouselItem key={index}>
                                                                 <video className="d-block" controls>
                                                                      <source src={data.url} type={data.type} />
                                                                 </video>
                                                            </CCarouselItem>
                                                       )
                                                  } else {
                                                       return (
                                                            <CCarouselItem key={index}>
                                                                 <img className="d-block" src={data.url} alt={data.url}/>
                                                            </CCarouselItem>
                                                       )
                                                  }
                                             })
                                        }
                                   </CCarouselInner>
                                   {
                                        imgList.length > 1 && (
                                             <>
                                                  <CCarouselControl direction="prev">
                                                       <CIcon name="icon-chevron-left" width="20" onClick={() => changeSlider()} />
                                                  </CCarouselControl>
                                                  <CCarouselControl direction="next">
                                                       <CIcon name="icon-chevron-left" width="20" onClick={() => changeSlider()} />
                                                  </CCarouselControl>
                                             </>
                                        )
                                   }
                                   
                              </CCarousel>
                         </div>
                    </CModalBody>
               </CModal>
          </div>
     );
};

ShowImage.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
}

export default ShowImage;
