import React, { useState, useCallback, useRef, useContext } from 'react';
import CIcon from '@coreui/icons-react';
import XLSX from 'xlsx';

import { LookupTableContext } from './LookupTable';
import ImportTableModal from './ImportTableModal';
import { useOutsideHandling } from '../../../../../../utils';

const automaticVariableText = '// required (optional if selecting exists with any value or does not exist operator), any string'
const optionalText = '// optional, any string';
const regexHeaderText = '// required, accepts a 1 or a 0';
const requiredText = '// required, accepts a 1 or a 0';

const ImportExportTable = () => {
    const { tableRow, IsExisted } = useContext(LookupTableContext);
    const [showImportModal, setShowImportModal] = useState(false);
    const [showImportExport, setShowImportExport] = useState(false);

    const getHeaderValues = () => {
        let firstRowValues = [];
        let secondRowValues = [];

        // Convert conditions to arrays which are compatible with XLSX
        if (Array.isArray(tableRow) && Array.isArray(tableRow[0].conditions)) {
            tableRow[0].conditions.forEach(condition => {
                let conditionValue = condition['g0'];
                let found = IsExisted(conditionValue.id, conditionValue.type);

                if (found) {
                    let secondRowName = found.friendlyName;

                    switch (conditionValue.type) {
                        case 'Variable':
                            secondRowName = secondRowName || found.fullName;
                            firstRowValues.push(automaticVariableText, regexHeaderText);
                            secondRowValues.push(secondRowName, secondRowName + '.regex');
                            break;
                        default:
                            secondRowName = secondRowName || found.code || '';
                            firstRowValues.push(requiredText);
                            secondRowValues.push(secondRowName);
                    }
                }
            })
        }

        // Convert custom variables to arrays which are compatible with XLSX
        if (Array.isArray(tableRow) && Array.isArray(tableRow[0].customVariables)) {
            tableRow[0].customVariables.forEach((customVariable) => {
                firstRowValues.push(optionalText);
                secondRowValues.push(customVariable.name || '');
            })
        }

        return { firstRowValues, secondRowValues };
    }

    const exportToFile = (type = 'csv', fileName = 'table-import-export', onlyRow1And2) => {
        let { firstRowValues, secondRowValues } = getHeaderValues();

        // Many next rows
        let manyRowValues = [];

        if (!onlyRow1And2) {
            // Convert table rows, which begin from row fourth to arrays which are compatible with XLSX
            if (Array.isArray(tableRow)) {
                tableRow.forEach(({ conditions, customVariables }) => {
                    let rowValues = [];

                    if (Array.isArray(conditions)) {
                        conditions.forEach(condition => {
                            let conditionValue = condition['g0'];

                            switch (conditionValue.type) {
                                case 'Variable':
                                    rowValues.push(conditionValue.value, conditionValue.isRegex ? '1' : '0');
                                    break;
                                default:
                                    rowValues.push(conditionValue.value);
                            }
                        })
                    }

                    if (Array.isArray(customVariables)) {
                        customVariables.forEach(customVariable => {
                            rowValues.push(customVariable.value);
                        })
                    }

                    manyRowValues.push(rowValues);
                })
            }
        }

        let dataToExport = [
            firstRowValues,
            secondRowValues,
            ...manyRowValues
        ];

        if (type === 'csv') {
            // https://reactjs.org/docs/code-splitting.html#import
            import('../../../../../../utils').then(utils => {
                // csv type will use this function to export correctly
                utils.exportToCSV(`${fileName}.${type}`, dataToExport);
            });
        } else {
            var workSheet = XLSX.utils.aoa_to_sheet(dataToExport);

            // Add to workbook
            let workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');

            // If uses XLSX.writeFile to export csv file, open that file then Ctrl + S to save,
            // then close file without saving changes, open that file again all values 
            // of each rows will merge into the first column of that row, so use another function to export csv file
            XLSX.writeFile(workBook, `${fileName}.${type}`);
        }
    }

    const handClickImportExport = useCallback(() => {
        setTimeout(() => {
            setShowImportExport(!showImportExport)
        }, 100);
    }, [showImportExport])

    const handleClickOutside = useCallback(() => {
        if (!showImportModal) {
            setShowImportExport(false)
        }
    }, [showImportModal])

    const wrapperRef = useRef(null);
    useOutsideHandling(wrapperRef, handleClickOutside);

    const toggleImportModal = useCallback(() => {
        if (!showImportModal) {
            setShowImportExport(false);
        }

        setShowImportModal(!showImportModal);
    }, [showImportModal])

    return (
        <>
            <div className="import-export-wrapper">
                <div className="import-export">
                    <CIcon name="cil-chevron-double-down" height="14" />
                    <button type="button" onClick={handClickImportExport}>Import/Export</button>
                </div>
                <ul ref={wrapperRef} className={showImportExport ? '' : 'hidden'}>
                    <li>
                        <button color="primary" type="button" onClick={() => exportToFile('csv')}><CIcon name="cil-cloud-download" height="14"></CIcon><span>Export existing table values</span></button>
                    </li>
                    <li>
                        <button color="primary" type="button" onClick={toggleImportModal}><CIcon name="cil-cloud-upload" height="14"></CIcon><span>Import new table values</span></button>
                    </li>
                </ul>
            </div>
            <ImportTableModal
                show={showImportModal}
                toggleModal={toggleImportModal}
                exportToFile={exportToFile}
                getHeaderValues={getHeaderValues}
            />
        </>

    )
}

export default ImportExportTable
