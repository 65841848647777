import { CButton, CCard, CCardBody } from '@coreui/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { setSetupGuideRoadmap, setShowCreateAccountPopup, setShowSetupGuidePopup } from '../../../../../../actions/subscriber';
import { getDLScript } from '../../../../../../utils';
import GetScript from '../../../../../general/popup/GetScript';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';

const VerificationFailed = () => {
    const { account, stepsData, setStepsData, setCurrentStep } = useContext(CreateAccountContext);
    const [showGetScriptPopup, setShowGetScriptPopup] = useState(false);
    const theScript = getDLScript(account.id, account.isAmd);
    // const user = useSelector(state => state.theme.user);
    // const dataUser = (user && JSON.parse(user.registerUserInfo)) || JSON.parse(stepsData.dataUser)
    const isRoadmap = window.localStorage.getItem('isRoadmap' + account.id);
    const dispatch = useDispatch()
    const { completedSteps } = account

    const toggleGetScriptPopup = useCallback(() => {
        setShowGetScriptPopup(show => !show);
    }, [])

    const onBackBtnClicked = () => {
        setCurrentStep(STEPS.placeScriptManually);
    };

    const checkScriptAgain = () => {
        setCurrentStep(STEPS.verifyingScript);
    };

    const skipVerification = () => {
        if (isRoadmap) {
            dispatch(setShowCreateAccountPopup(false));
            dispatch(setSetupGuideRoadmap(1))
            dispatch(setShowSetupGuidePopup(true));
            return;
        }
        if (completedSteps.includes(STEPS.chooseDestination) && completedSteps.includes(STEPS.configureMeasurement)) {
            setCurrentStep(STEPS.configuringAccount)
        }
        else {
            setCurrentStep(STEPS.gtmConnection, CHANGE_STEP_TYPE.mainStepNext);
        }
    };

    const handleFirstLoad = () => {
        setStepsData({ domainUrlPath: undefined });
    }

    useEffect(handleFirstLoad, [])

    return (
        <CreateAccountBody onBackBtnClicked={onBackBtnClicked}>
            <CCard className="account-script verification-failed">
                <p className="number-of-step">3 of 3 steps</p>
                <CCardBody>
                    <h3>Verification Failed</h3>
                    <p>We're having issues finding the script on {stepsData.domain}</p>
                    <div className="notice mb-3">
                        <h4>Before you try again, please check</h4>
                        <ul>
                            <li>If you placed the script manually, make sure you can see it in the code of your website</li>
                            <li>If you used GTM, make sure it's published, try debugging and make sure the tag is firing on all pages.</li>
                            <li>Clear your website or firewall's cache.</li>
                            <li>
                                If you are using a staging website or server and it’s inaccessible (blocked by a VPN or firewall) we won’t be able to
                                verify your script. We recommend using your production website!
                            </li>
                        </ul>
                    </div>
                    <CButton className="btn-save" onClick={checkScriptAgain}>
                        CHECK FOR THE SCRIPT AGAIN
                    </CButton>
                    <div className="btn-step-navigation">
                        <CButton onClick={toggleGetScriptPopup}>
                            Get my script again
                        </CButton>
                        <CButton onClick={skipVerification}>
                            Skip verification<i className="fal fa-angle-right"></i>
                        </CButton>
                    </div>
                </CCardBody>
            </CCard>
            <GetScript show={showGetScriptPopup} script={theScript} onClose={toggleGetScriptPopup} />
        </CreateAccountBody>
    );
};

export default VerificationFailed;
