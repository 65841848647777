import React, { useEffect, useMemo, useRef, useState } from 'react';
import SelectTakeAction from './SelectTakeAction';
// import CIcon from '@coreui/icons-react';
import { CButton, CFormGroup, CInputCheckbox, CInput, CLabel, CSwitch, CInvalidFeedback } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { ClickInfoIconTooltip } from '../../../../../general/custom-tooltips';
import CopyToClipboard from 'react-copy-to-clipboard';
import cloneDeep from 'lodash/cloneDeep';
import { getFromValueNameKey, getIssueFromCategories, toastError } from '../../../../../../utils';
import {
     ACCORDION_TYPES,
     API_CLIENT_CHILD_FORM_INHERIT_PARENT,
     API_CLIENT_IGNORED_FORM_CATEGORIES,
     API_CLIENT_SAVE_DRAFT_FORM_CATEGORIES,
     CLIENT_LISTENER_ENABLE,
     CLIENT_LISTENER_ENABLE_ALL_FEATURES,
     SPECIAL_FEATURE_LIST,
} from '../../../../../../constants';
import {
     handleFetchFormCategories,
     setConversionRules,
     setCustomFormTargetRules,
     setNewLayout,
     setShowBlockAccountPopup,
     setSubscriberState,
     setTriggerRules,
     handleFetchCountFormsInventory,
     setFormInventory,
     setFormIgnored,
     setFormRequiringAction,
     setFormRedirect,
     setAllFormsInventory,
     setFormRequiringEdit,
     setAllFormsIgnore,
     setFormIgnoreEdit,
     setAllFormsRequiring,
     handleSetAccordion,
     handleIgnoredFormChildRequiring,
     handleUpdateIsRedirectIgnored,
     handleUpdateIsRedirectRequiring,
     handleFetchCountFormsIgnored,
     handleFetchCountFormsRequiring,
     setFilterFormCategories,
     setFilterEditFormCategories,
     setFormInventoryEdit,
     setFormsDraft,
     handleUpdateCustomCategories,
     handleFetchChildFormRequiring,
     setLoadingFormIgnore,
     handleCancelChildFormRequiringSuggest,
} from '../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../apiCaller';
import { getEnabledListeners, getListenerRoutes } from '../../../../../../helpers/cms/subscriber';
import TargetRulesModal from './target-rules/TargetRulesModal';
import Waiting from '../../../../../general/Loadings/Waiting';
// import { toast } from 'react-toastify';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { useHistory, useParams } from 'react-router-dom';
import ModalCreateCategories from '../form-categories-wizard/ModalCreateCategories';
import { setListFormsChild } from '../../../../../../actions/admin';

const FormRequiringTableRow = ({
     errors,
     setErrors,
     index,
     data,
     className,
     hasChild,
     toggleDetails,
     // parentValue,
     length,
     // parentName,
     showAccordion,
     redirectTempFormData,
}) => {
     const history = useHistory();
     const dispatch = useDispatch();
     const { secondId } = useParams()
     const listFormDraft = useSelector((state) => state.subscriber.formDraft);
     const requiringNumberPerpage = useSelector((state) => state.subscriber.requiringNumberPerpage);
     const formRequiringEdit = useSelector((state) => state.subscriber.formRequiringEdit);
     const formIgnoreEdit = useSelector((state) => state.subscriber.formIgnoreEdit);
     const showIgnoredForm = useSelector((state) => state.subscriber.showIgnoredForm);
     const listFormIgnored = useSelector((state) => state.subscriber.formIgnored);
     const allFormsIgnored = useSelector((state) => state.subscriber.allFormsIgnored);
     const allFormsRequiring = useSelector((state) => state.subscriber.allFormsRequiring);
     const formsRequiring = useSelector((state) => state.subscriber.formsRequiring);
     const listCustomCategories = useSelector((state) => state.subscriber.customCategories);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const newLayout = useSelector((state) => state.subscriber.newLayout);
     const accountRoutes = useSelector((state) => state.subscriber.routes);
     const activeFeatures = useSelector((state) => state.subscriber.activeFeatures);
     const flexibleModal = useSelector((state) => state.theme.flexibleModal);
     const { showNewLayout } = newLayout;
     const CUSTOM_FORM_CODE = 'customForms';
     const { numberOfListeners, numberOfPremium } = activeAccount;
     const pathName = history.location.pathname;

     let specificFeatures = activeFeatures.filter((feature) => {
          let strToCompare = feature.code.toLowerCase();

          return SPECIAL_FEATURE_LIST.some((specialItem) => strToCompare.includes(specialItem));
     });

     const listenerFormsEnabled = useSelector((state) => state.subscriber.listenerFormsEnabled);
     const customFormListenerEnabled = listenerFormsEnabled.find((listener) => listener.code === CUSTOM_FORM_CODE);

     const [firstCopy, setFirstCopy] = useState(false);
     const [copyElement, setCopyElement] = useState(null);
     const [showTooltip, setShowTooltip] = useState(false);
     const [showIgnoreModal, setShowIgnoreModal] = useState(false);
     const [submitLoading, setSubmitLoading] = useState(false);
     const [popupEnableListener, setPopupEnableListener] = useState(false);
     const [showTargetFormModal, setShowTargetFormModal] = useState(false);
     const [showUpdateRulesModal, setShowUpdateRulesModal] = useState(false);
     const [saveDraftLoading, setSaveDraftLoading] = useState(false);
     const [toggleShowFormSettingsTooltip, setToggleShowFormSettingsTooltip] = useState();
     const [showPopupCreateCategories, setShowPopupCreateCategories] = useState(false);
     const [isLoading, setLoading] = useState(false);
     const itemsPerPage = 12;
     const formParentIgnore = showIgnoredForm && allFormsIgnored.some((form) => form.id === data.parentId && form.issue === 'isIgnored');

     const options = useMemo(() => {
          const newListCategories =
               listCustomCategories && listCustomCategories.length > 0
                    ? [{ id: null, name: 'Select a category', value: null }, ...listCustomCategories]
                    : [];

          return newListCategories ? newListCategories.map((category) => ({ value: category.id, label: category.name })) : [];
     }, [listCustomCategories]);

     useEffect(() => {
          const existed = options.length === 0 || options.some((category) => category.value !== 'createNewCategories');
          if (existed) {
               options.push({
                    value: 'createNewCategories',
                    label: (
                         <CButton className="option-create-categories" color="link" onClick={() => setShowPopupCreateCategories(true)}>
                              <i className="fal fa-plus"></i>
                              Create new category
                         </CButton>
                    ),
               });
          }
     }, [options]);

     const disableListener = listenerFormsEnabled.every((listener) => listener.id !== data.listenerId);

     const tempFormData = useRef({ ...data });
     const errorGroup = errors && errors.find((item) => item.id === data.id);
     const showDraftButton = data.issue !== 'isIgnored' && !data.isUnknown && !disableListener;
     const formsEdit = tempFormData.current && tempFormData.current.categoryId === data.categoryId && tempFormData.current.name === data.name;
     const formsDraftInit = allFormsRequiring
          ? allFormsRequiring.map((form) => {
                 const formExisted = listFormDraft && listFormDraft.find((item) => item.id === form.id);

                 if (formExisted) {
                      return { ...form, ...formExisted };
                 }
                 return form;
            })
          : [];
     const formsDraft =
          formRequiringEdit && formRequiringEdit.length > 0
               ? formRequiringEdit.every((item) => {
                      const initForm = formsDraftInit && formsDraftInit.find((form) => form.id === item.id);

                      if (initForm && initForm.id === data.id) {
                           return item.categoryId === initForm.categoryId && item.name === initForm.name;
                      }
                      return true;
                 })
               : true;
     const saveDraftDisabled = showDraftButton && formsEdit && formsDraft;
     const flagSuggestCategories =  data.proposeCategory && data.proposeName && !data.name && !data.categoryId && !showIgnoredForm && showDraftButton;

     useEffect(() => {
          if (tempFormData.current) {
               tempFormData.current = data;
          }
     }, [redirectTempFormData]); // eslint-disable-line react-hooks/exhaustive-deps

     const findFormParent = (formData, parentId) => {
          const result = formData.find((item) => {
               return item.id === parentId;
          });
          return result;
     };

     const handleChangeInheritParent = (event) => {
          const inheritParent = event.target.value === 'on' ? false : true;
          // Find Form Parent Ignored Edit => Set New Value form Child
          const newForms = showIgnoredForm ? cloneDeep(allFormsIgnored) : cloneDeep(allFormsRequiring);
          const listParentEdit = showIgnoredForm ? cloneDeep(formIgnoreEdit) : cloneDeep(formRequiringEdit);
          const formParent = findFormParent(newForms, data.parentId);
          const newData = { ...data };
          const formParentEdit = listParentEdit && listParentEdit.find((item) => item.id === formParent.id);

          newData.inheritParent = inheritParent;

          if (formParentEdit) {
               newData.name = !inheritParent ? '' : formParentEdit.name;
               newData.categoryId = !inheritParent ? null : formParentEdit.categoryId;
               newData.isRedirect = !inheritParent ? false : formParentEdit.isRedirect;
               newData.isUnknown = formParentEdit.isUnknown
          } else {
               newData.name = !inheritParent ? '' : formParent.name;
               newData.categoryId = !inheritParent ? null : formParent.categoryId;
               newData.isRedirect = !inheritParent ? false : formParent.isRedirect;
               newData.isUnknown = formParent.isUnknown
          }

          if (!showIgnoredForm) {
               newData.issue = 'isDrafted';
          }

          if (showIgnoredForm) {
               dispatch(setFormIgnoreEdit(newData));
          } else {
               dispatch(setFormRequiringEdit(newData));
          }

          setErrors && setErrors(errors.filter((item) => item.id !== data.id));
     };

     const handleOnChangePushEvents = (value) => {
          const isRedirect = value === 'on' ? false : true;

          const dataRequest = {
               accountId: activeAccount.id,
               data: {
                    id: data.id,
                    hasChild: data.hasChild,
                    isRedirect: isRedirect,
               },
          };

          const parentId = data.parentId;
          const nextDispatch = {
               setLoading,
          };

          if (showIgnoredForm) {
               dispatch(handleUpdateIsRedirectIgnored(dataRequest, parentId, nextDispatch));
               dispatch(setFormRequiringAction(null));
               dispatch(setAllFormsRequiring(null));
          } else {
               dispatch(handleUpdateIsRedirectRequiring(dataRequest, parentId, nextDispatch));
          }
          dispatch(setFormInventory(null));
          dispatch(setAllFormsInventory(null));
     };

     const handleChangeFormCategory = (option) => {
          let newDataEdit = { ...data, categoryId: option, inheritParent: option ? data.inheritParent : false };

          if (showIgnoredForm) {
               if (newDataEdit.hasChild) {
                    formIgnoreEdit.forEach((form) => {
                         if (form.parentId && form.parentId === newDataEdit.id && form.inheritParent) {
                              form.categoryId = newDataEdit.categoryId;
                              dispatch(setFormIgnoreEdit(form));
                         }
                    });
               }
               dispatch(setFormIgnoreEdit(newDataEdit));
          } else {
               if (newDataEdit.hasChild) {
                    formRequiringEdit.forEach((form) => {
                         if (form.parentId && form.parentId === newDataEdit.id && form.inheritParent) {
                              form.categoryId = newDataEdit.categoryId;
                              dispatch(setFormRequiringEdit(form));
                         }
                    });
               }
               dispatch(setFormRequiringEdit(newDataEdit));
          }

          if ((option && data.name) || (!option && !data.name)) {
               setErrors && setErrors(errors.filter((item) => item.id !== data.id));
          }
     };
     const handleChangeFormName = (e) => {
          const name = e.target.value.trim() === '' ? null : e.target.value;
          const newDataEdit = { ...data, name: name };

          if (showIgnoredForm) {
               if (newDataEdit.hasChild) {
                    formIgnoreEdit.forEach((form) => {
                         if (form.parentId && form.parentId === newDataEdit.id && form.inheritParent) {
                              form.name = newDataEdit.name;
                              dispatch(setFormIgnoreEdit(form));
                         }
                    });
               }
               dispatch(setFormIgnoreEdit(newDataEdit));
          } else {
               if (newDataEdit.hasChild) {
                    formRequiringEdit.forEach((form) => {
                         if (form.parentId && form.parentId === newDataEdit.id && form.inheritParent) {
                              form.name = newDataEdit.name;
                              dispatch(setFormRequiringEdit(form));
                         }
                    });
               }
               dispatch(setFormRequiringEdit(newDataEdit));
          }

          if ((name && data.categoryId) || (!name && !data.categoryId)) {
               setErrors && setErrors(errors.filter((item) => item.id !== data.id));
          }
     };

     const [initialPropose, setInitialPropose] = useState({
          proposeCategory: data.proposeCategory,
          proposeName: data.proposeName,
     });

     const handleChangeProposeCategory = (e, type) => {
          const valueProposeCategory = e.target.value.trim() === '' ? null : e.target.value;
          setInitialPropose((prev) => ({ ...prev, [type]: valueProposeCategory }));
     };

     const copyFirstScript = (element) => {
          setFirstCopy(true);
          setCopyElement(element);
     };

     useEffect(() => {
          if (firstCopy) {
               setTimeout(() => {
                    setFirstCopy(false);
                    setCopyElement(null);
               }, 2000);
          }
     }, [firstCopy]);

     const defaultValue = options.find((item) => item.value === data.categoryId);
     const listenerName = data.isUnknown ? 'Unknown' : data.listenerName;

     useEffect(() => {
          if (defaultValue && defaultValue.value === 'createNewCategories') {
               setShowPopupCreateCategories(true);
          }
     }, [defaultValue]);

     useEffect(() => {
          setShowPopupCreateCategories(false);
     }, [flexibleModal]);

     const onClosePopup = () => {
          setPopupEnableListener(false);
          setShowTooltip(false);
     };

     const handleOpenListenerForms = () => {
          setShowTooltip(true);
          setPopupEnableListener(true);
     };

     const handleIgnoreForms = async  () => {
          setSubmitLoading(true);

          let formsCategoriesId = null;

          if (data.parentId) {
               formsCategoriesId = data.parentId;
          } else {
               formsCategoriesId = data.id;
          }
          const payload = {
               accountId: data.accountId,
               parentId: data.id
          }
          let stopIgnore = false;
          if(data.hasChild) {
               try {
                    const res = await callTokenApi(API_CLIENT_CHILD_FORM_INHERIT_PARENT, 'POST', payload);
                    const { formInheritParent } = res.data;
                    stopIgnore = !formInheritParent;
               } catch (error) {
                    console.error('Error calling API:', error);
               } finally {
                    setShowTooltip(false);
                    setToggleShowFormSettingsTooltip((s) => !s); // Close tooltip
                    setShowIgnoreModal(false);
                    setSubmitLoading(false);
               }
          }

          if(stopIgnore) return;

          const newForms = showIgnoredForm ? cloneDeep(listFormIgnored) : cloneDeep(formsRequiring);
          const newFormUpdated = [];

          for (let i = 0; i < newForms.length; i++) {
               if (newForms[i].id === formsCategoriesId || newForms[i].parentId === formsCategoriesId) {
                    if (
                         (!newForms[i].hasChild && !newForms[i].categoryId && !newForms[i].name) ||
                         newForms[i].issue === 'isDrafted' ||
                         newForms[i].hasChild
                    ) {
                         newForms[i].issue = 'isIgnored';
                         newForms[i].inheritParent = false;
                         // newForms[i].isRedirect = false;
                         newForms[i].categoryId = null;
                         newForms[i].name = null;

                         newFormUpdated.push(newForms[i]);
                    }
               }
          }

          const newAllForms = showIgnoredForm ? cloneDeep(allFormsIgnored) : cloneDeep(allFormsRequiring);
          const newAllFormUpdate = [];

          for (let i = 0; i < newAllForms.length; i++) {
               if (newAllForms[i].id === formsCategoriesId || newAllForms[i].parentId === formsCategoriesId) {
                    if (
                         (!newAllForms[i].hasChild && !newAllForms[i].categoryId && !newAllForms[i].name) ||
                         newAllForms[i].issue === 'isDrafted' ||
                         newAllForms[i].hasChild
                    ) {
                         newAllForms[i].issue = 'isIgnored';
                         newAllForms[i].inheritParent = false;
                         // newAllForms[i].isRedirect = false;
                         newAllForms[i].categoryId = null;
                         newAllForms[i].name = null;

                         newAllFormUpdate.push(newAllForms[i]);
                    }
               }
          }

          // const dataPushEvents = {
          //      accountId: activeAccount.id,
          //      isPushEvents: false,
          // };

          // callTokenApi(API_CLIENT_UPDATE_PUSH_EVENTS, 'PUT', dataPushEvents).then((response) => {
          //      if (response.status === 200) {

          //      } else {
          //           toastError(response);
          //      }
          // });

          const newDataRequest = {
               accountId: activeAccount.id,
               data: {
                    id: data.id,
                    parentId: data.parentId,
                    hasChild: data.hasChild,
                    inheritParent: data.inheritParent,
               },
          };

          callTokenApi(API_CLIENT_IGNORED_FORM_CATEGORIES, 'PUT', newDataRequest)
               .then((response) => {
                    if (response.status === 200) {
                         const { numberFormChildRequiring } = response.data;

                         if (data.hasChild) {
                              // const newListForms = newForms.filter((item) => item.id !== formsCategoriesId);
                              let newAllForms = allFormsRequiring.map((form) => {
                                   if(form.id === formsCategoriesId) {
                                        form.issue = 'isIgnored';
                                        form.count = numberFormChildRequiring;
                                   }
                                   return form;
                              })

                              if(numberFormChildRequiring === 0) {
                                   newAllForms = allFormsRequiring.filter((item) => item.id !== formsCategoriesId);
                              } else {
                                   dispatch(setLoadingFormIgnore(true))
                                   dispatch(
                                        setListFormsChild({
                                             [`requiring${formsCategoriesId}`]: [],
                                             [`numberOffset${formsCategoriesId}`]: 0,
                                        })
                                   );

                                   dispatch(
                                        setListFormsChild({
                                             [`ignored${formsCategoriesId}`]: [],
                                             [`numberOffset${formsCategoriesId}`]: 0,
                                        })
                                   );

                                   // fetch api get child
                                   const id = formsCategoriesId;
                                   const nextDispatch = {
                                        setLoading,
                                   };
                                   dispatch(handleFetchChildFormRequiring(id, nextDispatch, itemsPerPage));
                              }

                              if (requiringNumberPerpage === 0) {
                                   dispatch(setFormRequiringAction(newAllForms.slice(0, itemsPerPage)));
                              }
                              dispatch(setAllFormsRequiring(newAllForms));

                              const newFormRequiringEdit =
                                   formRequiringEdit && formRequiringEdit.filter((item) => item.id !== data.id && item.parentId !== data.id);
                              dispatch(setSubscriberState({ formRequiringEdit: newFormRequiringEdit }));
                         } else {
                              dispatch(
                                   handleIgnoredFormChildRequiring(formsCategoriesId, data.id, data.parentId ? true : false, numberFormChildRequiring)
                              );

                              const newFormRequiringEdit = formRequiringEdit && formRequiringEdit.filter((item) => item.id !== data.id);
                              dispatch(setSubscriberState({ formRequiringEdit: newFormRequiringEdit }));
                              if(data.parentId) {
                                   dispatch(
                                        setListFormsChild({
                                             [`ignored${data.parentId}`]: [],
                                             [`numberOffset${data.parentId}`]: 0,
                                        })
                                   );
                              }

                         }

                         // reset form ignored
                         dispatch(setFormIgnored(null));
                         dispatch(setAllFormsIgnore(null));
                         // reset form inventory
                         dispatch(setFormInventory(null));
                         dispatch(setAllFormsInventory(null));
                         dispatch(setFormRedirect(false));
                         // reset form edit
                         dispatch(setFormInventoryEdit(null));
                         // fetch count
                         dispatch(handleFetchCountFormsRequiring());
                         dispatch(handleFetchCountFormsIgnored());
                         dispatch(handleFetchCountFormsInventory());
                         //reset filters
                         dispatch(setFilterFormCategories([]));
                         dispatch(setFilterEditFormCategories(null));

                         // toast.success('Form ignored!');
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setShowTooltip(false);
                    setToggleShowFormSettingsTooltip((s) => !s); // Close tooltip
                    setShowIgnoreModal(false);
                    setSubmitLoading(false);
               });
     };

     const activeAllFeatures = async ({ newActiveAccount, newAccountListeners, listenerId }) => {
          if (showNewLayout) {
               const data = {
                    accountId: activeAccount.id,
                    listenerId,
               };
               await callTokenApi(CLIENT_LISTENER_ENABLE_ALL_FEATURES, 'POST', data).then((response) => {
                    if (response.status === 200) {
                         const accountFeatureActive = response.data.accountFeatureActive;
                         if (accountFeatureActive) {
                              const enabledListeners = getEnabledListeners(newAccountListeners);
                              dispatch(
                                   setSubscriberState({
                                        activeAccount: newActiveAccount,
                                        accountListeners: newAccountListeners,
                                        activeListeners: enabledListeners,
                                        activeFeatures: accountFeatureActive,
                                   })
                              );
                         }
                    } else {
                         toastError(response);
                    }
               });
          }
     };

     const handleShowEnableListener = (listenerId) => {
          setPopupEnableListener(true);
          let usedListeners = numberOfListeners;
          let usedPremium = numberOfPremium;

          const tempListener = accountListeners.find((listener) => listener.id === listenerId);
          let urlToCall = '';
          urlToCall = CLIENT_LISTENER_ENABLE;
          usedListeners = usedListeners + 1;
          usedPremium = tempListener.premium ? usedPremium + 1 : usedPremium;

          const data = {
               accountId: activeAccount.id,
               listenerId,
          };

          setSubmitLoading(true);

          callTokenApi(urlToCall, 'POST', data)
               .then(async (response) => {
                    if (response.status === 200) {
                         let newListener = {};
                         let listenerIndex = 0;

                         accountListeners.some((listener, index) => {
                              if (tempListener.id === listener.id) {
                                   listenerIndex = index;
                                   newListener = listener;
                                   newListener.configStatus = !newListener.configStatus;

                                   return true;
                              }

                              return false;
                         });

                         let newAccountListeners = [...accountListeners];
                         newAccountListeners[listenerIndex] = newListener;
                         let newActiveAccount = { ...activeAccount };
                         newActiveAccount.numberOfListeners = usedListeners;
                         let newSpecificFeatures = null;

                         newActiveAccount.numberOfPremium = usedPremium;

                         // Reset custom form target rules when enabling custom form listener
                         if (tempListener.code === 'customForms') {
                              dispatch(setCustomFormTargetRules(null));
                         }

                         await activeAllFeatures({ newActiveAccount, newAccountListeners, listenerId });
                         dispatch(setNewLayout({ activeSubNavItem: tempListener.type, showNavChild: true })); // Show Active Listeners dropdown when enable listener

                         // Wizard Form Categories
                         const newListenerFormsEnabled = [...listenerFormsEnabled];
                         newListenerFormsEnabled.push(newListener);

                         const enabledListeners = getEnabledListeners(newAccountListeners);
                         const listenerRoutes = getListenerRoutes(enabledListeners, newSpecificFeatures || specificFeatures, secondId);
                         const newAccountRoutes = [...accountRoutes].filter(
                              (route) => !listenerRoutes.some((listenerRoute) => listenerRoute.path === route.path)
                         );
                         const newRoutes = [...newAccountRoutes, ...listenerRoutes];

                         dispatch(
                              setSubscriberState({
                                   activeAccount: newActiveAccount,
                                   routes: newRoutes,
                                   accountListeners: newAccountListeners,
                                   activeListeners: enabledListeners,
                                   eventOptions: {
                                        listenerId: '',
                                        events: [],
                                   },
                              })
                         );

                         if (newListener.configStatus === true) {
                              // High light listener nav in sidebar when turn on
                              dispatch(
                                   setSubscriberState({
                                        activeListener: newListener,
                                        elementJustActivated: {
                                             id: newListener.id,
                                             type: newListener.type,
                                        },
                                   })
                              );
                              setTimeout(() => {
                                   dispatch(
                                        setSubscriberState({
                                             elementJustActivated: null,
                                        })
                                   );
                              }, 500);
                         }

                         dispatch(setTriggerRules([]));
                         dispatch(setConversionRules([]));
                         dispatch(handleFetchFormCategories());

                         // toast.success(`Listener turning on!`);
                         onClosePopup();
                    } else {
                         if (response.data.accountBlocked) {
                              dispatch(setShowBlockAccountPopup(true));
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => {
                    setSubmitLoading(false);
                    setShowTooltip(false);
               });
     };

     const handleTargetForm = () => {
          setShowTooltip(true);
          setShowTargetFormModal(true);
     };

     const [showTooltipCancel, setShowTooltipCancel] = useState(false); // Don't close tooltip when clicking outside
     const clickCancel = () => {
          const newFormData = { ...data, proposeCategory: null, proposeName: null };
          if (newFormData.parentId){ //form has children
               const parentId = newFormData.parentId;
               dispatch(handleCancelChildFormRequiringSuggest(parentId, newFormData ));
          } else {
               dispatch(setFormRequiringEdit(newFormData));
          }
          setShowTooltipCancel(true);
          setInitialPropose({
               proposeCategory: data.proposeCategory,
               proposeName: data.proposeName,
          });
     };

     // const clickCancelIgnored = () => {
     //      const newFormData = { ...data, proposeCategory: null, proposeName: null };
     //      dispatch(setFormIgnoreEdit(newFormData));
     //      setShowTooltipCancel(true);
     //      setInitialPropose({
     //           proposeCategory: data.proposeCategory,
     //           proposeName: data.proposeName,
     //      });
     // };

     //click saveAccept Ignored
     // const saveAcceptIgnored = async () => {
     //      if (setErrors) {
     //           // Remove the error message for this form if exists
     //           setErrors(errors.filter((item) => item.id !== data.id));
     //      }
     //      setShowTooltip(true); // Don't close tooltip when clicking outside
     //      setSaveDraftLoading(true);
     //      const newFormData = { ...data, issue: 'isIgnored', saveAccept: true, initialProposeCategory: initialPropose.proposeCategory };

     //      if (newFormData.name === null || newFormData.name === '') {
     //           newFormData.name = initialPropose.proposeName;
     //      }
     //      const newDataRequest = {
     //           accountId: activeAccount.id,
     //           data: [newFormData],
     //      };

     //      const functionSaveAccept = async (newDataRequest, newFormData) => {
     //           await callTokenApi(API_CLIENT_SAVE_DRAFT_FORM_CATEGORIES, 'PUT', newDataRequest)
     //                .then((response) => {
     //                     if (response.status === 200) {
     //                          const dataResponse = response.data.forms[0];
     //                          dataResponse.proposeCategory = null;
     //                          dataResponse.proposeName = null;
     //                          tempFormData.current = dataResponse;
     //                          dispatch(setFormInventory(null));
     //                          dispatch(setAllFormsInventory(null));
     //                          dispatch(setFormInventoryEdit(null));
     //                          dispatch(handleFetchCountFormsInventory(null, activeAccount));

     //                          // set set form ignore edit
     //                          dispatch(setFormIgnoreEdit(dataResponse));
     //                          //reset filters
     //                          dispatch(setFilterFormCategories([]));
     //                          dispatch(setFilterEditFormCategories(null));
     //                     } else {
     //                          toastError(response);
     //                     }
     //                })
     //                .finally(() => {
     //                     setShowTooltip(false);
     //                     setSaveDraftLoading(false);
     //                });
     //      };

     //      //tạo custom categories và name
     //      const checkOptionCategories = listCustomCategories.some(
     //           (item) => item.name === initialPropose.proposeCategory && item.accountId === data.accountId,
     //      );

     //      if (!checkOptionCategories) {
     //           let code = (Math.floor(Math.random() * 5555) + 1111).toString().substring(0, 4);
     //           const values = {
     //                id: `categories${code}`,
     //                name: initialPropose.proposeCategory,
     //                flg_add: true,
     //                isChecked: true,
     //                accountId: activeAccount.id,
     //           };

     //           if (values.name) {
     //                let customCategories = [values];

     //                customCategories.map((item) => {
     //                     item.name = item.name.trim();
     //                     return item;
     //                });

     //                const nextDispatch = {
     //                     setFormRequiringEdit,
     //                     setFormInventoryEdit,
     //                     setFormIgnoreEdit,
     //                     functionSaveAccept: () => functionSaveAccept(newDataRequest, newFormData),
     //                };
     //                dispatch(handleUpdateCustomCategories(customCategories, nextDispatch));
     //           }
     //      } else {
     //           functionSaveAccept(newDataRequest, newFormData);
     //      }
     // };

     //click saveAccept
     const saveAccept = async () => {
          if (setErrors) {
               // Remove the error message for this form if exists
               setErrors(errors.filter((item) => item.id !== data.id));
          }
          setShowTooltip(true); // Don't close tooltip when clicking outside
          setSaveDraftLoading(true);
          const newFormData = { ...data, issue: 'isDrafted', saveAccept: true, initialProposeCategory: initialPropose.proposeCategory };

          // initialPropose
          if (newFormData.name === null || newFormData.name === '') {
               newFormData.name = initialPropose.proposeName;
          }
          const newDataRequest = {
               accountId: activeAccount.id,
               data: [newFormData],
          };

          const functionSaveAccept = async (newDataRequest, newFormData) => {
               await callTokenApi(API_CLIENT_SAVE_DRAFT_FORM_CATEGORIES, 'PUT', newDataRequest)
                    .then((response) => {
                         if (response.status === 200) {
                              const dataResponse = response.data.forms[0];
                              dataResponse.proposeCategory = null;
                              dataResponse.proposeName = null;
                              tempFormData.current = dataResponse;
                              dispatch(setFormRequiringEdit(dataResponse));
                              dispatch(setFormsDraft(dataResponse));
                              dispatch(setFormInventory(null));
                              dispatch(setAllFormsInventory(null));
                              dispatch(setFormInventoryEdit(null));
                              dispatch(handleFetchCountFormsInventory(null, activeAccount));
                              //reset filters
                              dispatch(setFilterFormCategories([]));
                              dispatch(setFilterEditFormCategories(null));

                              if (allFormsIgnored && allFormsIgnored.length > 0) {
                                   const draftFormIgnored = allFormsIgnored.find((form) => form.id === newFormData.id);
                                   // reset form ignored
                                   if (draftFormIgnored) {
                                        dispatch(setFormIgnored(null));
                                        dispatch(setAllFormsIgnore(null));
                                   }
                              }
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         setShowTooltip(false);
                         // setToggleShowFormSettingsTooltip((s) => !s); // Close tooltip
                         setSaveDraftLoading(false);
                    });
          };

          // tạo custom categories và name
          const checkOptionCategories = listCustomCategories.some(
               (item) => item.name === initialPropose.proposeCategory && item.accountId === data.accountId,
          );

          if (!checkOptionCategories) {
               let code = (Math.floor(Math.random() * 5555) + 1111).toString().substring(0, 4);
               const values = {
                    id: `categories${code}`,
                    name: initialPropose.proposeCategory,
                    flg_add: true,
                    isChecked: true,
                    accountId: activeAccount.id,
               };

               if (values.name) {
                    let customCategories = [values];

                    customCategories.map((item) => {
                         item.name = item.name.trim();
                         return item;
                    });

                    const nextDispatch = {
                         setFormRequiringEdit,
                         setFormInventoryEdit,
                         setFormIgnoreEdit,
                         functionSaveAccept: () => functionSaveAccept(newDataRequest, newFormData),
                    };
                    dispatch(handleUpdateCustomCategories(customCategories, nextDispatch));
               } else {
                    functionSaveAccept(newDataRequest, newFormData);
               }

          } else {
               functionSaveAccept(newDataRequest, newFormData);
          }
     };

     // Currently only apply for Forms Requiring Action
     const saveDraft = async () => {
          if (setErrors) {
               // Remove the error message for this form if exists
               setErrors(errors.filter((item) => item.id !== data.id));
          }
          setShowTooltip(true); // Don't close tooltip when clicking outside
          setSaveDraftLoading(true);
          const newFormData = { ...data, issue: 'isDrafted' };

          if (newFormData.name) {
               newFormData.name = newFormData.name.trim();
          }

          const newDataRequest = {
               accountId: activeAccount.id,
               data: [newFormData],
          };

          await callTokenApi(API_CLIENT_SAVE_DRAFT_FORM_CATEGORIES, 'PUT', newDataRequest)
               .then((response) => {
                    if (response.status === 200) {
                         tempFormData.current = newFormData;

                         dispatch(setFormRequiringEdit(newFormData));
                         dispatch(setFormsDraft(newFormData));
                         dispatch(setFormInventory(null));
                         dispatch(setAllFormsInventory(null));
                         dispatch(setFormInventoryEdit(null));
                         dispatch(handleFetchCountFormsInventory(null, activeAccount));
                         //reset filters
                         dispatch(setFilterFormCategories([]));
                         dispatch(setFilterEditFormCategories(null));

                         if (allFormsIgnored && allFormsIgnored.length > 0) {
                              const draftFormIgnored = allFormsIgnored.find((form) => form.id === newFormData.id);
                              // reset form ignored
                              if (draftFormIgnored) {
                                   dispatch(setFormIgnored(null));
                                   dispatch(setAllFormsIgnore(null));
                              }
                         }
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setShowTooltip(false);
                    setToggleShowFormSettingsTooltip((s) => !s); // Close tooltip
                    setSaveDraftLoading(false);
               });
     };

     const handleClickLinkNoOptions = () => {
          dispatch(handleSetAccordion({ accordion: ACCORDION_TYPES.FORM_CATEGORIES, pathName }));
     };

     // const checkNewChildForm = (child) => {
     //      const findFormParentCategorized =
     //           allFormsRequiring &&
     //           allFormsRequiring.find((form) => {
     //                return form.id === child.parentId && form.hasChild && form.issue !== 'isDrafted' && form.issue !== 'isIgnored' && form.categoryId;
     //           });

     //      if (findFormParentCategorized && child.issue !== 'isDrafted' && child.issue !== 'isIgnored' && child.inheritParent && !child.categoryId) {
     //           return true;
     //      }

     //      return false;
     // };

     // const handleAcceptNewChildForm = () => {
     //      const formParent = findFormParent(allFormsRequiring, data.parentId);
     //      const newData = { ...data };
     //      const formParentEdit = formRequiringEdit && formRequiringEdit.find((item) => item.id === formParent.id);

     //      if (formParentEdit) {
     //           newData.name = formParentEdit.name;
     //           newData.categoryId = formParentEdit.categoryId;
     //      } else {
     //           newData.name = formParent.name;
     //           newData.categoryId = formParent.categoryId;
     //      }

     //      if (!showIgnoredForm) {
     //           newData.issue = 'isDrafted';
     //      }

     //      dispatch(setFormRequiringEdit(newData));
     // };
     const handleCancelCreateCategory = () => {
          if (showPopupCreateCategories) {
               setShowPopupCreateCategories(false);
          }
          if (!showIgnoredForm) {
               const dataEdit = formRequiringEdit.find((item) => item.categoryId === 'createNewCategories');
               const oldForm = allFormsRequiring.find((form) => form.id === dataEdit.id);
               if (typeof oldForm !== 'undefined') {
                    dispatch(setFormRequiringEdit({ ...dataEdit, categoryId: oldForm.categoryId || null }));
               } else {
                    dispatch(setFormRequiringEdit({ ...dataEdit, categoryId: null }));
               }
          } else {
               const dataEdit = formIgnoreEdit.find((item) => item.categoryId === 'createNewCategories');
               const oldForm = allFormsIgnored.find((form) => form.id === dataEdit.id);
               if (typeof oldForm !== 'undefined') {
                    dispatch(setFormIgnoreEdit({ ...dataEdit, categoryId: oldForm.categoryId }));
               } else {
                    dispatch(setFormIgnoreEdit({ ...dataEdit, categoryId: null }));
               }
          }
     };

     return (
          <>
               <div className={`css-table-row ${(index + 1) % 2 === 0 ? 'even' : 'odd'} ${index >= +length - 3 ? 'table-last-row' : ''}`}>
                    {/* Listener Name */}
                    <div className="listener-name table-body-row">
                         {(typeof hasChild === 'undefined' || hasChild) && (
                              <>
                                   {hasChild && !data.hiddenButton && (
                                        <CButton
                                             color="light"
                                             size="sm"
                                             onClick={() => toggleDetails(data.id)}
                                             className={`accordion-btn ${showAccordion ? ' show' : ''}`}
                                        ></CButton>
                                   )}
                                   {hasChild ? (
                                        <span className="listener-name-parent" onClick={() => toggleDetails(data.id)}>
                                             {listenerName}
                                        </span>
                                   ) : (
                                        <span>{listenerName}</span>
                                   )}
                              </>
                         )}
                    </div>

                    {/* Form ID */}
                    <div className="form-id table-body-row">
                         {/* <span>{data.formValue}</span> */}
                         {!hasChild ? (
                              <div className="tooltip-wrapper">
                                   <ClickInfoIconTooltip showIcon={false} text={data.formValue}>
                                        <div className="tooltip-copy">
                                             <div>Unique Identifiers</div>
                                        </div>
                                        <div className="tooltip-copy">
                                             <div className="tooltip-label">
                                                  {listenerName.includes('ActiveCampaign') ? 'class' : getFromValueNameKey(data)}: {data.formValue}{' '}
                                             </div>
                                             <CopyToClipboard text={data.formValue} onCopy={() => copyFirstScript('id')}>
                                                  <div className="icon-copy">{firstCopy && copyElement === 'id' ? 'Copied' : 'Copy'}</div>
                                             </CopyToClipboard>{' '}
                                        </div>
                                        <div className="tooltip-copy">
                                             <div className="tooltip-label">
                                                  Slug:{' '}
                                                  <a href={data.formLocation} target="_blank" rel="noopener noreferrer">{`${data.formLocation}`}</a>
                                             </div>
                                             <a className="tooltip-slug" href={data.formLocation} target="_blank" rel="noopener noreferrer">
                                                  <img
                                                       src="/assets/icons/icon-arrow-up-right.svg"
                                                       alt="icon-arrow-up-right"
                                                       className="icon-arrow-up-right"
                                                  />
                                             </a>
                                             {/* <CopyToClipboard text={data.formLocation} onCopy={() => copyFirstScript('slug')}>
                                                  <div className='icon-copy'>{firstCopy && copyElement === 'slug' ? 'Copied' : 'Copy'}</div>
                                             </CopyToClipboard>{' '} */}
                                        </div>
                                   </ClickInfoIconTooltip>
                              </div>
                         ) : (
                              <div className="tooltip-wrapper">
                                   <span style={{ cursor: 'auto' }}>{data.formValue}</span>
                              </div>
                         )}
                    </div>

                    {/* Form Location */}
                    <div className="form-location table-body-row">
                         {!hasChild && (
                              <div className="tooltip-wrapper">
                                   <ClickInfoIconTooltip>
                                        <div className="tooltip-copy">
                                             <div className="tooltip-label">
                                                  Slug:{' '}
                                                  <a href={data.formLocation} target="_blank" rel="noopener noreferrer">{`${data.formLocation}`}</a>
                                             </div>
                                             <CopyToClipboard text={data.formLocation} onCopy={() => copyFirstScript('slug')}>
                                                  <div className="icon-copy">{firstCopy && copyElement === 'slug' ? 'Copied' : 'Copy'}</div>
                                             </CopyToClipboard>
                                        </div>
                                   </ClickInfoIconTooltip>
                              </div>
                         )}
                    </div>

                    <>
                         {/* Status */}
                         <div className="status table-body-row">
                              <span className={data.issue === 'isDrafted' ? 'is-drafted' : ''}>{getIssueFromCategories(data.issue)}</span>
                         </div>

                         {/* Manage */}
                         <div className="manage-settings table-body-row">
                              <div className={`tooltip-wrapper ${errorGroup ? 'is-invalid' : ''}`}>
                                   <ClickInfoIconTooltip
                                        button={true}
                                        btnText={data.inheritParent ? 'Inherit from parent' : 'Click to Manage'}
                                        btnColor={data.inheritParent ? 'secondary' : errorGroup ? 'outline-danger' : 'outline-primary'}
                                        showTooltip={showTooltip}
                                        toggleShow={toggleShowFormSettingsTooltip}
                                        showPopupCreateCategories={showPopupCreateCategories}
                                        showTooltipCancel={showTooltipCancel}
                                   >
                                        <h5>Manage the settings for this form</h5>
                                        {/* Listener Name */}
                                        <div className="mb-1">Listener: {listenerName}</div>
                                        {showIgnoredForm && (
                                             <div className="mb-1">
                                                  This form is ignored. Categorize and name the form to remove it from the ignored list.
                                             </div>
                                        )}

                                        {/* Location */}
                                        {!hasChild && (
                                             <div>
                                                  View Form:{' '}
                                                  <a className="ml-1" href={data.formLocation} target="_blank" rel="noopener noreferrer">
                                                       <img
                                                            src="/assets/icons/icon-arrow-up-right.svg"
                                                            alt="icon-arrow-up-right"
                                                            className="icon-arrow-up-right"
                                                       />
                                                  </a>
                                             </div>
                                        )}

                                        {/* Inherit Parent */}
                                        {data.parentId && !hasChild && (
                                             <div className="mt-2 mb-2 inherit-parent-setting">
                                                  <CFormGroup variant="custom-checkbox" inline>
                                                       <CInputCheckbox
                                                            custom
                                                            id={`${className}-${data.id}`}
                                                            name={`${className}-${data.id}`}
                                                            value={data.inheritParent ? 'on' : 'off'}
                                                            checked={!!data.inheritParent}
                                                            // disabled={!parentValue || !parentName ? true : false}
                                                            onChange={(e) => handleChangeInheritParent(e)}
                                                            disabled={(!formParentIgnore && data.issue === 'isIgnored') || isLoading}
                                                       />
                                                       <CLabel variant="custom-checkbox" htmlFor={`${className}-${data.id}`}>
                                                            Inherit from parent
                                                       </CLabel>
                                                  </CFormGroup>
                                                  <div className="tooltip-wrapper">
                                                       <ClickInfoIconTooltip>
                                                            Select this in order to apply the parent form's category, name, and other settings to this
                                                            form. This is useful if we have a single form that appears on multiple pages (thereby
                                                            creating these child forms).
                                                       </ClickInfoIconTooltip>
                                                  </div>
                                             </div>
                                        )}

                                        {!data.inheritParent && (
                                             <>
                                                  {/* Redirect */}
                                                  {!data.isUnknown && (
                                                       <>
                                                            {disableListener ? (
                                                                 <div className="mt-3">
                                                                      <CButton className="btn btn-primary" onClick={handleOpenListenerForms}>
                                                                           Enable {data.listenerName} Listener
                                                                      </CButton>
                                                                 </div>
                                                            ) : (
                                                                 <div className="mt-2 mb-3 push-events-setting">
                                                                      <div className="mr-3">
                                                                           <CSwitch
                                                                                className="mr-3 d-inline-block align-middle"
                                                                                color={data.isRedirect ? 'success' : 'light'}
                                                                                checked={!!data.isRedirect}
                                                                                value={data.isRedirect ? 'on' : 'off'}
                                                                                size="sm"
                                                                                tabIndex="0"
                                                                                shape="pill"
                                                                                onChange={(e) => handleOnChangePushEvents(e.target.value)}
                                                                           />
                                                                           <span>Push events after redirect</span>
                                                                      </div>
                                                                      <div className="tooltip-wrapper">
                                                                           <ClickInfoIconTooltip>
                                                                                If this form redirects to a thank you page, we recommend enabling this
                                                                                toggle switch to improve tracking quality.
                                                                           </ClickInfoIconTooltip>
                                                                      </div>
                                                                 </div>
                                                            )}
                                                       </>
                                                  )}

                                                  {/* Form Category - Form Name */}
                                                  {data.isUnknown ? (
                                                       !customFormListenerEnabled ? (
                                                            <div className="mt-3">
                                                                 <p>Enable the Custom HTML Forms Listener to begin tracking forms like this one. </p>
                                                                 <CButton className="btn btn-primary" onClick={handleOpenListenerForms}>
                                                                      Enable Custom Forms Listener
                                                                 </CButton>
                                                            </div>
                                                       ) : (
                                                            <div className="mt-3">
                                                                 {data.formValue ? (
                                                                      <p>
                                                                           To target this form using the Custom HTML Forms Listener, you need to
                                                                           include the form ID in the targeting rules. Click the button and we’ll
                                                                           display your current rules so you can add this ID to them.{' '}
                                                                      </p>
                                                                 ) : (
                                                                      <p>You must add a form ID to the code of this form to track it.</p>
                                                                 )}
                                                                 {data.formValue && (
                                                                      <CButton className="btn btn-primary" onClick={handleTargetForm}>
                                                                           Target This Form
                                                                      </CButton>
                                                                 )}
                                                            </div>
                                                       )
                                                  ) : (
                                                       <>
                                                            {!disableListener && (
                                                                 <div className="form-group-requiring mt-3">
                                                                      {flagSuggestCategories ? (
                                                                           <>
                                                                                <div className="form-validate">
                                                                                     <CInput
                                                                                          id={`formCategory-${className}-${data.id}`}
                                                                                          name={`formCategory-${className}-${data.id}`}
                                                                                          type="text"
                                                                                          value={
                                                                                               initialPropose.proposeCategory
                                                                                                    ? initialPropose.proposeCategory
                                                                                                    : data.categoryId
                                                                                          }
                                                                                          onChange={(e) =>
                                                                                               handleChangeProposeCategory(e, 'proposeCategory')
                                                                                          }
                                                                                          autoComplete="off"
                                                                                          data-lpignore="true"
                                                                                          placeholder="Enter a form category"
                                                                                     />
                                                                                </div>

                                                                                <div className="form-validate">
                                                                                     <CInput
                                                                                          id={`formName-${className}-${data.id}`}
                                                                                          name={`formName-${className}-${data.id}`}
                                                                                          type="text"
                                                                                          value={
                                                                                               initialPropose.proposeName
                                                                                                    ? initialPropose.proposeName
                                                                                                    : data.name
                                                                                          }
                                                                                          onChange={(e) =>
                                                                                               handleChangeProposeCategory(e, 'proposeName')
                                                                                          }
                                                                                          autoComplete="off"
                                                                                          data-lpignore="true"
                                                                                          placeholder="Enter a form name"
                                                                                     />
                                                                                </div>
                                                                           </>
                                                                      ) : !showIgnoredForm ? (
                                                                           <>
                                                                                <div className="form-validate">
                                                                                     <SelectTakeAction
                                                                                          className={`${
                                                                                               errorGroup && errorGroup.invalidCategory
                                                                                                    ? 'is-invalid'
                                                                                                    : ''
                                                                                          }`}
                                                                                          options={options}
                                                                                          handleChangeTakeAction={handleChangeFormCategory}
                                                                                          defaultValue={defaultValue || null}
                                                                                          onMenuOpen={() => setShowTooltip(true)}
                                                                                          onMenuClose={() => setShowTooltip(false)}
                                                                                          handleClickLinkNoOptions={handleClickLinkNoOptions}
                                                                                     />
                                                                                     <CInvalidFeedback>
                                                                                          Form Category cannot be empty!
                                                                                     </CInvalidFeedback>
                                                                                </div>

                                                                                <div className="form-validate">
                                                                                     <CInput
                                                                                          id={`formName-${className}-${data.id}`}
                                                                                          name={`formName-${className}-${data.id}`}
                                                                                          type="text"
                                                                                          value={data.name || ''}
                                                                                          onChange={(e) => handleChangeFormName(e)}
                                                                                          invalid={errorGroup && errorGroup.invalidName}
                                                                                          autoComplete="off"
                                                                                          data-lpignore="true"
                                                                                          placeholder="Enter a form name"
                                                                                     />
                                                                                     <CInvalidFeedback>Form Name cannot be empty!</CInvalidFeedback>
                                                                                </div>
                                                                           </>
                                                                      ) : (
                                                                           <>
                                                                                <div className="form-validate">
                                                                                     <SelectTakeAction
                                                                                          className={`${
                                                                                               errorGroup && errorGroup.invalidCategory
                                                                                                    ? 'is-invalid'
                                                                                                    : ''
                                                                                          }`}
                                                                                          options={options}
                                                                                          handleChangeTakeAction={handleChangeFormCategory}
                                                                                          defaultValue={defaultValue || null}
                                                                                          onMenuOpen={() => setShowTooltip(true)}
                                                                                          onMenuClose={() => setShowTooltip(false)}
                                                                                          handleClickLinkNoOptions={handleClickLinkNoOptions}
                                                                                     />
                                                                                     <CInvalidFeedback>
                                                                                          Form Category cannot be empty!
                                                                                     </CInvalidFeedback>
                                                                                </div>

                                                                                <div className="form-validate">
                                                                                     <CInput
                                                                                          id={`formName-${className}-${data.id}`}
                                                                                          name={`formName-${className}-${data.id}`}
                                                                                          type="text"
                                                                                          value={data.name || ''}
                                                                                          onChange={(e) => handleChangeFormName(e)}
                                                                                          invalid={errorGroup && errorGroup.invalidName}
                                                                                          autoComplete="off"
                                                                                          data-lpignore="true"
                                                                                          placeholder="Enter a form name"
                                                                                     />
                                                                                     <CInvalidFeedback>Form Name cannot be empty!</CInvalidFeedback>
                                                                                </div>
                                                                           </>
                                                                      )}
                                                                 </div>
                                                            )}
                                                       </>
                                                  )}
                                                  {!showIgnoredForm && (
                                                       <div className={showDraftButton ? 'd-flex justify-content-between mt-3 mb-2' : ''}>
                                                            {showDraftButton && (
                                                                 <>
                                                                      {data.proposeCategory && data.proposeName && !data.categoryId && !data.name ? (
                                                                           <>
                                                                                <CButton color="primary" onClick={saveAccept}>
                                                                                     <Waiting isLoading={saveDraftLoading}>Accept</Waiting>
                                                                                </CButton>
                                                                                <CButton color="primary" onClick={clickCancel}>
                                                                                     <Waiting>Cancel</Waiting>
                                                                                </CButton>
                                                                           </>
                                                                      ) : (
                                                                           <>
                                                                                <CButton
                                                                                     color="primary"
                                                                                     onClick={saveDraft}
                                                                                     disabled={saveDraftDisabled}
                                                                                >
                                                                                     <Waiting isLoading={saveDraftLoading}>Save Draft</Waiting>
                                                                                </CButton>
                                                                           </>
                                                                      )}
                                                                 </>
                                                            )}

                                                            {data.issue !== 'isIgnored' && (
                                                                 <div className="ignore-form">
                                                                      <CButton
                                                                           color="link"
                                                                           onClick={() => {
                                                                                setShowTooltip(true);
                                                                                setShowIgnoreModal(true);
                                                                           }}
                                                                      >
                                                                           Ignore this form
                                                                      </CButton>
                                                                      <div className="tooltip-wrapper">
                                                                           <ClickInfoIconTooltip>
                                                                                Ignoring this form will hide it from the list of forms. The form will
                                                                                not be categorized, named, or tracked. You can adjust the settings on
                                                                                forms you ignore by changing your filter to view them.
                                                                           </ClickInfoIconTooltip>
                                                                      </div>
                                                                 </div>
                                                            )}
                                                       </div>
                                                  )}

                                                  {/* show ignore form */}
                                                  {/* {showIgnoredForm && (
                                                       <div
                                                            className={
                                                                 (data.proposeCategory || data.proposeName) && !data.categoryId && !data.name
                                                                      ? 'd-flex mt-3 mb-2'
                                                                      : ''
                                                            }
                                                       >
                                                            <>
                                                                 {(data.proposeCategory || data.proposeName) && !data.categoryId && !data.name && (
                                                                      <>
                                                                           <CButton className="mr-3" color="primary" onClick={saveAcceptIgnored}>
                                                                                <Waiting isLoading={saveDraftLoading}>Accept</Waiting>
                                                                           </CButton>
                                                                           <CButton color="primary" onClick={clickCancelIgnored}>
                                                                                <Waiting>Cancel</Waiting>
                                                                           </CButton>
                                                                      </>
                                                                 )}
                                                            </>
                                                       </div>
                                                  )} */}
                                             </>
                                        )}
                                   </ClickInfoIconTooltip>
                              </div>
                              <CInvalidFeedback>This field is required!</CInvalidFeedback>
                         </div>

                         {/* Inherit Parent */}
                         <div className={`inherit-category table-body-row`}>
                              {data.parentId && !hasChild && (
                                   <>
                                        <CFormGroup variant="custom-checkbox" inline>
                                             <CInputCheckbox
                                                  custom
                                                  id={`${className}-${data.id}`}
                                                  name={`${className}-${data.id}`}
                                                  value={data.inheritParent ? 'on' : 'off'}
                                                  checked={!!data.inheritParent}
                                                  // disabled={!parentValue || !parentName ? true : false}
                                                  onChange={(e) => handleChangeInheritParent(e)}
                                                  disabled={!formParentIgnore && data.issue === 'isIgnored'}
                                             />
                                             <CLabel variant="custom-checkbox" htmlFor={`${className}-${data.id}`}>
                                                  Inherit from parent
                                             </CLabel>
                                        </CFormGroup>
                                        {/* {(!parentValue || !parentName) && (
                                             <div className='block-tooltip'>
                                                  <CIcon name='arrow-preview' className='arrow-preview'></CIcon>
                                                  <div className='block-tooltip-text'>
                                                       <p>Categorize and enter a name for the parent form first.</p>
                                                  </div>
                                             </div>
                                        )} */}
                                   </>
                              )}
                         </div>

                         {/* Accept */}
                         {/* <div className={`accept-new-child-form table-body-row`}>
                              {data.parentId && checkNewChildForm(data) && (
                                   <CButton color="link" onClick={handleAcceptNewChildForm}>
                                        Accept
                                   </CButton>
                              )}
                         </div> */}
                    </>
               </div>
               {popupEnableListener && (
                    <ConfirmSaveChange
                         show={popupEnableListener}
                         onAccept={() => handleShowEnableListener(data.listenerId)}
                         onClose={onClosePopup}
                         isIcon={false}
                         btnTextSave="Continue"
                         btnTextCancel="Back"
                         title={`Enable ${data.isUnknown ? 'Custom HTML Forms' : listenerName} Listener`}
                         isLoading={submitLoading}
                    >
                         {data.isUnknown ? (
                              <p>
                                   You are about to enable the Custom Forms Listener. This will allow you to track forms that are not targeted by
                                   other Listeners. You will be required to publish your ListenLayer account before the Listener is live.
                              </p>
                         ) : (
                              <p>
                                   You are about to enable the {listenerName} Listener. This will allow you to name and categorize your forms from{' '}
                                   {listenerName}. You will be required to publish your ListenLayer account before the Listener is live.
                              </p>
                         )}
                    </ConfirmSaveChange>
               )}
               {showIgnoreModal && (
                    <ConfirmSaveChange
                         show={showIgnoreModal}
                         onAccept={handleIgnoreForms}
                         onClose={() => {
                              setShowTooltip(false);
                              setShowIgnoreModal(false);
                         }}
                         isLoading={submitLoading}
                         btnTextSave="Continue"
                         btnTextCancel="Back"
                         title="Ignore This Form"
                         isIcon={false}
                    >
                         <p>
                              The form will not be categorized, named, or tracked. It will be hidden from this table but you can still manage and
                              change its settings in the future by selecting the "<strong>Show only ignored forms</strong>" option.
                         </p>
                         {/* <p>
                              You are choosing to ignore a {data.hasChild ? 'parent' : 'child'} form. This will apply to the{' '}
                              {data.hasChild ? 'child' : 'parent'} form and all other forms.
                         </p> */}
                    </ConfirmSaveChange>
               )}

               {showTargetFormModal && (
                    <ConfirmSaveChange
                         show={showTargetFormModal}
                         onAccept={() => {
                              setShowUpdateRulesModal(true);
                              setShowTargetFormModal(false);
                         }}
                         onClose={() => {
                              setShowTooltip(false);
                              setShowTargetFormModal(false);
                         }}
                         isLoading={submitLoading}
                         btnTextSave="Continue"
                         btnTextCancel="Back"
                         title="Update Targeting Rules"
                         isIcon={false}
                    >
                         <p>
                              We'll add a rule to your targeting rules to include this form. After you complete this action, you'll be able to
                              categorize and name this form.
                         </p>
                    </ConfirmSaveChange>
               )}

               {showUpdateRulesModal && (
                    <TargetRulesModal
                         show={showUpdateRulesModal}
                         onBack={() => {
                              setShowUpdateRulesModal(false);
                              setShowTooltip(false);
                         }}
                         data={data}
                    />
               )}

               {showPopupCreateCategories && (
                    <ModalCreateCategories
                         show={showPopupCreateCategories}
                         onCancel={handleCancelCreateCategory}
                         setFormRequiringEdit={setFormRequiringEdit}
                         setFormIgnoreEdit={setFormIgnoreEdit}
                    />
               )}
          </>
     );
};

export default FormRequiringTableRow;
