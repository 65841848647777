import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import cookie from 'js-cookie';
import Select from 'react-select';
import Calendar from 'react-calendar';
import cloneDeep from 'lodash/cloneDeep';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CRow, CCol, CCard, CCardBody, CButtonGroup, CButton, CTooltip, CFormGroup, CLabel } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { guidGenerator, handleToggleSidebar, toastError, tryParseJSON } from '../../../../utils';
import { API_ALL_EVENT_NAME, DEFAULT_DATE_FORMAT, DEFAULT_FILTER_METRICS, SUBSCRIBER_PATH } from '../../../../constants';
import { fetchAnAccount, setAllEvents, setShowBlockAccountPopup, setShowUpgradeAccountPopup, setTour } from '../../../../actions/subscriber';
import EventChart from './EventChart';
import EventTable from './event-table/EventTable';
import { callTokenApi } from '../../../../apiCaller.js';
import { VariableValueModal } from '../../../general/popup';
import GetHelpLabel from '../../../general/popup/support-ticket/GetHelpLabel';
import { usePrevious } from '../../../../helpers/customHooks';
import { TOURSTEPS, TOUR_FORCE_OPEN, TourBody } from '../../../../containers/subscriber/TheTour';

const infinityIcon = <i className='far fa-infinity'></i>;

const EventReporting = () => {
    const history = useHistory();
    const accounts = useSelector((state) => state.subscriber.accounts);
    const activeAccount = useSelector((state) => state.subscriber.activeAccount);
    const { showNewLayout } = useSelector((state) => state.subscriber.newLayout);
    const { showTour, stepTour } = useSelector((state) => state.subscriber.tour);

    const [filterType, setFilterType] = useState('nthDay');
    const [customFilterType, setCustomFilterType] = useState('');
    const [filterBeginDate, setFilterBeginDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [keysFilterMetrics, setKeysFilterMetrics] = useState(DEFAULT_FILTER_METRICS);
    const [keysConcept, setKeysConcept] = useState([
        {
            label: 'All Events',
            value: 'all',
        },
    ]);
    const [customDate, setCustomDate] = useState(false);
    const [chartStateReload, setChartStateReload] = useState(false);
    const [variableValueToShow, setVariableValueToShow] = useState({ id: '', value: '' });
    const [chartLoading, setChartLoading] = useState(true);
    const [eventTableId, setEventTableId] = useState(guidGenerator());
    const dateFormat = 'DD / MMM / YYYY';
    const events = useSelector((state) => state.subscriber.events);
    const dispatch = useDispatch();
    const maxDate = activeAccount.timeZone ? dayjs().tz(activeAccount.timeZone)['$d'] : new Date();

    const { id: accountId, domains } = activeAccount;
    const initialDomains = domains ? tryParseJSON(domains) : [];
    const scriptInstalled = initialDomains && initialDomains.length > 0 && initialDomains.some((domain) => domain.scriptInstalled);

    let fromDay, endDay;
    const prevFilterDate = usePrevious({ filterBeginDate, filterEndDate });
    const {
        numberOfDomains,
        numberOfPremium,
        numberOfEventsPerMonth,
        // numberOfCusDatalayerVariables,
        // numberOfUsers,
        numberOfEventMonitor,
        numberOfSessionsPerMonth,
        numberOfVisitorsPerMonth,
        packageNumberOfDomains,
        packageNumberOfPremium,
        packageNumberOfEventsPerMonth,
        // packageNumberOfCusDatalayerVariables,
        // packageNumberOfUsers,
        packageNumberOfEventMonitor,
        packageNumberOfVisitors,
        packageNumberOfSessions,
    } = activeAccount;

    const accountStatistics = [
        {
            name: 'Users',
            used: numberOfVisitorsPerMonth.toLocaleString('en-US'),
            available:
                packageNumberOfVisitors === -1
                    ? infinityIcon
                    : (packageNumberOfVisitors - numberOfVisitorsPerMonth > 0 ? packageNumberOfVisitors - numberOfVisitorsPerMonth : 0).toLocaleString('en-US'),
        },
        {
            name: 'Sessions',
            used: numberOfSessionsPerMonth.toLocaleString('en-US'),
            available:
                packageNumberOfSessions === -1
                    ? infinityIcon
                    : (packageNumberOfSessions - numberOfSessionsPerMonth > 0 ? packageNumberOfSessions - numberOfSessionsPerMonth : 0).toLocaleString('en-US'),
        },
        {
            name: 'Events this Month',
            used: numberOfEventsPerMonth.toLocaleString('en-US'),
            available:
                packageNumberOfEventsPerMonth === -1
                    ? infinityIcon
                    : (packageNumberOfEventsPerMonth - numberOfEventsPerMonth > 0 ? packageNumberOfEventsPerMonth - numberOfEventsPerMonth : 0).toLocaleString('en-US'),
        },
        {
            name: 'Domains',
            used: numberOfDomains.toLocaleString('en-US'),
            available:
                packageNumberOfDomains === -1
                    ? infinityIcon
                    : (packageNumberOfDomains - numberOfDomains > 0 ? packageNumberOfDomains - numberOfDomains : 0).toLocaleString('en-US'),
        },
        {
            name: 'Premium Listeners & Features',
            used: numberOfPremium.toLocaleString('en-US'),
            available:
                packageNumberOfPremium === -1
                    ? infinityIcon
                    : (packageNumberOfPremium - numberOfPremium > 0 ? packageNumberOfPremium - numberOfPremium : 0).toLocaleString('en-US'),
        },
        {
            name: 'Monitoring',
            used: numberOfEventMonitor.toLocaleString('en-US'),
            available:
                packageNumberOfEventMonitor === -1
                    ? infinityIcon
                    : (packageNumberOfEventMonitor - numberOfEventMonitor > 0 ? packageNumberOfEventMonitor - numberOfEventMonitor : 0).toLocaleString('en-US'),
        },
        // {
        //      name: 'Custom Data Layer Variables',
        //      used: numberOfCusDatalayerVariables.toLocaleString('en-US'),
        //      available: (packageNumberOfCusDatalayerVariables === -1) ? infinityIcon : (packageNumberOfCusDatalayerVariables - numberOfCusDatalayerVariables > 0 ? packageNumberOfCusDatalayerVariables - numberOfCusDatalayerVariables : 0).toLocaleString('en-US')
        // },
        // {
        //      name: 'Users',
        //      used: numberOfUsers.toLocaleString('en-US'),
        //      available: (packageNumberOfUsers === -1) ? infinityIcon : (packageNumberOfUsers - numberOfUsers > 0 ? packageNumberOfUsers - numberOfUsers : 0).toLocaleString('en-US')
        // },
    ];

    // Reload EventTable component if date filter changes
    useEffect(() => {
        if (prevFilterDate && (prevFilterDate.filterBeginDate !== filterBeginDate || prevFilterDate.filterEndDate !== filterEndDate)) {
            setEventTableId(guidGenerator());
        }
    }, [filterBeginDate, filterEndDate]); // eslint-disable-line

    if (filterBeginDate) {
        fromDay = dayjs(filterBeginDate).format(DEFAULT_DATE_FORMAT);
    } else {
        fromDay = activeAccount.timeZone ? dayjs().tz(activeAccount.timeZone).format(DEFAULT_DATE_FORMAT) : dayjs().format(DEFAULT_DATE_FORMAT);
    }

    if (filterEndDate) {
        endDay = dayjs(filterEndDate).format(DEFAULT_DATE_FORMAT);
    } else {
        endDay = activeAccount.timeZone ? dayjs().tz(activeAccount.timeZone).format(DEFAULT_DATE_FORMAT) : dayjs().format(DEFAULT_DATE_FORMAT);
    }

    const handleFilterType = (type) => {
        setFilterType(type);
    };

    const getEvents = () => {
        callTokenApi(`${API_ALL_EVENT_NAME}${activeAccount.id}`, 'GET', null).then((response) => {
            if (response.status === 200) {
                let events = response.data.codes.map((item) => item);
                let options = [{ value: 'all', label: 'All Events' }];

                events.forEach((item) => {
                    options.push({ value: item.featureId, label: item.friendlyName || item.featureCode });
                });
                dispatch(setAllEvents({ listenerId: '-1', listEvents: options }));
            } else {
                if (response.data.accountBlocked) {
                    dispatch(setShowBlockAccountPopup(true));
                } else {
                    toastError(response);
                }
            }
        });
    };
    useEffect(getEvents, []);

    const handleClickCustom = () => {
        // If user doesn't pick a date yet, it will automatically choose today when opening the calender
        // if (customFilterType === "") {
        //     setCustomFilterType("custom");
        //     if (filterBeginDate === "") {
        //         setFilterBeginDate(maxDate);
        //         setFilterEndDate(maxDate);
        //     }
        // }

        setTimeout(() => {
            if (customDate) {
                setCustomDate(false);
            } else {
                setCustomDate(true);
            }
        }, 100);
    };

    const handleCloseDateRange = () => {
        setCustomDate(false);
    };

    const onChangeDate = (value) => {
        let start = value[0];
        let end = value[1] || start;

        // Set customFilterType = custom when selecting a date on the calender
        if (customFilterType === '') {
            setCustomFilterType('custom');
        }

        setFilterBeginDate(start);
        setFilterEndDate(end);
    };

    const onSelectEvent = (values) => {
        if (values !== null) {
            setKeysConcept(values);
        } else {
            setKeysConcept([
                {
                    label: 'All Events',
                    value: 'all',
                },
            ]);
        }
    };

    const onSelectFilterMetrics = (values) => {
        if (values !== null) {
            setKeysFilterMetrics(values);
        }
    };

    const onClickRemoveDateRange = () => {
        setCustomFilterType('');
        setFilterBeginDate('');
        setFilterEndDate('');
    };

    const getFormatedDate = (date) => dayjs(date).format(dateFormat);

    const onClickLastTime = (time) => {
        if (customFilterType === '') {
            setCustomFilterType('custom');
        }

        let now = activeAccount.timeZone ? dayjs().tz(activeAccount.timeZone) : dayjs();
        let beginDate, endDate;

        switch (time) {
            case '7 days':
                beginDate = now.subtract(7, 'day')['$d'];
                endDate = now.subtract(1, 'day')['$d'];
                break;
            case '28 days':
                beginDate = now.subtract(28, 'day')['$d'];
                endDate = now.subtract(1, 'day')['$d'];
                break;
            case '30 days':
                beginDate = now.subtract(30, 'day')['$d'];
                endDate = now.subtract(1, 'day')['$d'];
                break;
            case '90 days':
                beginDate = now.subtract(90, 'day')['$d'];
                endDate = now.subtract(1, 'day')['$d'];
                break;
            case 'week':
                beginDate = now.subtract(1, 'week').startOf('week').day(1)['$d'];
                endDate = now.subtract(1, 'week').endOf('week').add(1, 'day')['$d'];
                break;
            case 'month':
                beginDate = now.subtract(1, 'month').startOf('month')['$d'];
                endDate = now.subtract(1, 'month').endOf('month')['$d'];
                break;
            case 'year':
                beginDate = now.subtract(1, 'year').startOf('year')['$d'];
                endDate = now.subtract(1, 'year').endOf('year')['$d'];
                break;
            default:
                break;
        }

        if (beginDate && endDate) {
            setFilterBeginDate(beginDate);
            setFilterEndDate(endDate);
        } else {
            setFilterBeginDate(now['$d']);
            setFilterEndDate(now['$d']);
        }
    };

    useEffect(() => {
        if (showNewLayout && history.location.pathname === SUBSCRIBER_PATH.INSIGHTS.replace(':secondId', activeAccount.secondId)) {
            history.push(`${SUBSCRIBER_PATH.INSIGHTS_DATA_LAYER.replace(':secondId', activeAccount.secondId)}`);
        }
    }, [history, showNewLayout]); // eslint-disable-line

    const nextTour = () => {
        const currentStepTour = parseInt(cloneDeep(stepTour));
        let nextStepTour = currentStepTour + 1;
        if (TOUR_FORCE_OPEN.scriptNotInstall.includes(nextStepTour)) {
            if (scriptInstalled) {
                nextStepTour = TOURSTEPS.accessSettings.orderNumber;
            }
        }
        dispatch(setTour({ stepTour: nextStepTour }));
        cookie.set(`stepTour${accountId}`, nextStepTour, { expires: 365 });
    };

    const backTour = () => {
        const currentStepTour = cloneDeep(stepTour);
        let previousStepTour = currentStepTour - 1;
        dispatch(setTour({ stepTour: previousStepTour }));
        cookie.set(`stepTour${accountId}`, previousStepTour, { expires: 365 });
    };

    const skipTour = () => {
        if (history.location.pathname !== `/${activeAccount.secondId}`) {
            history.push(`/${activeAccount.secondId}`);
        }
        handleToggleSidebar({ toggleShowNavChild: true, forceShowNavChild: false });
        dispatch(setTour({ showTour: false }));
    };

    const reloadReporting = () => {
        setChartLoading(true);
        setChartStateReload(!chartStateReload);
        dispatch(fetchAnAccount(activeAccount, accounts));
    };

    return (
        <div className='global-reporting'>
            {showNewLayout && (
                <CRow>
                    <CCol xl='5'>
                        <h1>Data Layer Reporting</h1>
                        <p style={{ fontWeight: 400 }}>
                            View data layer event and variable counts over time and save values. This report is primarily used to validate data layer activity and diagnose issues.
                        </p>
                    </CCol>

                    <CCol xl='7'>
                        <CCard className='upgrade-package upgrade-package-dashboard p-13'>
                            <CCardBody>
                                <div className='d-inline-block upgrade-package-text'>
                                    <h6>Upgrade your account</h6>
                                    <p>Unlock powerful features to customize and enhance your data layers.</p>
                                </div>
                                <div className='d-inline-block upgrade-package-button'>
                                    <CButton className='align-middle compare-plans text-capitalize' onClick={() => dispatch(setShowUpgradeAccountPopup(true))}>
                                        Compare Plans
                                    </CButton>
                                    <CButton className='btn-success' size='md' onClick={() => dispatch(setShowUpgradeAccountPopup(true))}>
                                        UPGRADE NOW
                                    </CButton>
                                </div>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            )}
            <CRow>
                {accountStatistics.map((item) => {
                    return (
                        <CCol key={item.name} sm='6' xl='4'>
                            <CCard>
                                <CCardBody className='text-center account-item' style={{ padding: '18px 20px 23px' }}>
                                    <p className='item-name' style={{ fontSize: '18px', fontWeight: 700, color: '#121221', marginBottom: '1.1em' }}>
                                        {item.name}
                                    </p>
                                    <CRow>
                                        <CCol xs='6' className='border-right'>
                                            <p className='number' style={{ fontSize: '20px', fontWeight: 500, marginBottom: '0.1em', color: '#121221' }}>
                                                {item.used}
                                            </p>
                                            <p
                                                className='text-muted'
                                                style={{
                                                    fontSize: '12px',
                                                    fontWeight: 500,
                                                    textTransform: 'uppercase',
                                                    marginBottom: '0.2em',
                                                    color: '#121221',
                                                }}
                                            >
                                                used
                                            </p>
                                        </CCol>
                                        <CCol xs='6'>
                                            <p className='number' style={{ fontSize: '20px', fontWeight: 500, marginBottom: '0.1em', color: '#121221' }}>
                                                {item.available}
                                            </p>
                                            <p
                                                className='text-muted'
                                                style={{
                                                    fontSize: '12px',
                                                    fontWeight: 500,
                                                    textTransform: 'uppercase',
                                                    marginBottom: '0.2em',
                                                    color: '#121221',
                                                }}
                                            >
                                                available
                                            </p>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    );
                })}
            </CRow>
            {/* Chart */}
            <CCard className={`dashboard-event${showTour ? ' tour-element' : ''}${stepTour === TOURSTEPS.eventReporting.orderNumber ? ' highlight' : ''}`}>
                <TourBody
                    show={showTour && stepTour === TOURSTEPS.eventReporting.orderNumber}
                    attribute={TOURSTEPS.eventReporting}
                    nextTour={nextTour}
                    backTour={backTour}
                    skipTour={skipTour}
                >
                    <div className='content' dangerouslySetInnerHTML={{ __html: TOURSTEPS.eventReporting.html }} />
                </TourBody>
                <CCardBody>
                    <CRow className='mb-2'>
                        <CCol lg='6' xl='4'>
                            <h4 id='traffic' className='card-title mb-0'>
                                Data Layer Event Reporting
                            </h4>
                        </CCol>
                        <CCol lg='12' xl='8'>
                            <div className='d-flex align-items-center justify-content-end box-date-filter'>
                                <div className='date-range-wrapper ml-3'>
                                    {customDate && <div className='date-range-overlay' onClick={handleCloseDateRange}></div>}
                                    <CButton className='date-range' onClick={handleClickCustom}>
                                        {filterBeginDate !== '' ? `${getFormatedDate(filterBeginDate)} - ` : 'From - '}
                                        {filterEndDate !== '' ? `${getFormatedDate(filterEndDate)}` : 'To'}
                                    </CButton>
                                    {customFilterType === 'custom' && (
                                        <CButton className='remove-custom-date'>
                                            <CTooltip content='Reset Date Range'>
                                                <CIcon name='cil-delete' onClick={onClickRemoveDateRange} />
                                            </CTooltip>
                                        </CButton>
                                    )}
                                    {customDate && (
                                        <CCard className='custom-date-wrapper'>
                                            {/* <CIcon name="arrow-account" className="arrow-account" height={30}></CIcon> */}
                                            <CIcon
                                                name='cil-x'
                                                onClick={() => {
                                                    setCustomDate(false);
                                                }}
                                                className='icon-close-popup'
                                            />
                                            <div className='calendar-wrapper'>
                                                <Calendar
                                                    maxDate={maxDate}
                                                    // defaultActiveStartDate={maxDate} // If opening the calender default choose a date, enable this
                                                    value={filterBeginDate || filterBeginDate ? [filterBeginDate, filterEndDate] : null}
                                                    allowPartialRange={true}
                                                    selectRange={true}
                                                    onChange={onChangeDate}
                                                    returnValue='range'
                                                    tileDisabled={chartLoading ? () => true : () => false}
                                                />
                                            </div>
                                            <div className='date-buttons'>
                                                <CButton
                                                    className='text-capitalize'
                                                    color={customFilterType === '7 days' ? 'info' : 'light'}
                                                    onClick={() => onClickLastTime('7 days')}
                                                    disabled={chartLoading}
                                                >
                                                    Last 7 days
                                                </CButton>
                                                <CButton
                                                    className='text-capitalize'
                                                    color={customFilterType === '28 days' ? 'info' : 'light'}
                                                    onClick={() => onClickLastTime('28 days')}
                                                    disabled={chartLoading}
                                                >
                                                    Last 28 days
                                                </CButton>
                                                <CButton
                                                    className='text-capitalize'
                                                    color={customFilterType === '90 days' ? 'info' : 'light'}
                                                    onClick={() => onClickLastTime('90 days')}
                                                    disabled={chartLoading}
                                                >
                                                    Last 90 days
                                                </CButton>
                                            </div>
                                        </CCard>
                                    )}
                                </div>
                                <CButtonGroup>
                                    <CButton
                                        className='text-capitalize'
                                        color={filterType === 'nthHour' ? 'info' : 'light'}
                                        onClick={() => handleFilterType('nthHour')}
                                        disabled={chartLoading}
                                    >
                                        Hourly
                                    </CButton>
                                    <CButton
                                        className='text-capitalize'
                                        color={filterType === 'nthDay' ? 'info' : 'light'}
                                        onClick={() => handleFilterType('nthDay')}
                                        disabled={chartLoading}
                                    >
                                        Daily
                                    </CButton>
                                    <CButton
                                        className='text-capitalize'
                                        color={filterType === 'nthWeek' ? 'info' : 'light'}
                                        onClick={() => handleFilterType('nthWeek')}
                                        disabled={chartLoading}
                                    >
                                        Weekly
                                    </CButton>
                                    <CButton
                                        className='text-capitalize'
                                        color={filterType === 'nthMonth' ? 'info' : 'light'}
                                        onClick={() => handleFilterType('nthMonth')}
                                        disabled={chartLoading}
                                    >
                                        Monthly
                                    </CButton>
                                </CButtonGroup>
                            </div>
                        </CCol>
                    </CRow>
                    <CRow className='dashboard-event-filters'>
                        <CCol lg='6' xl='4'>
                            <div className='visitor-session'>
                                <CFormGroup>
                                    <CLabel className='ml-0 mb-1'>Filter Metrics</CLabel>
                                    <Select
                                        className='list-metrics'
                                        placeholder='Filter Metrics'
                                        options={DEFAULT_FILTER_METRICS}
                                        isMulti={true}
                                        onChange={(values) => {
                                            onSelectFilterMetrics(values);
                                        }}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                        value={keysFilterMetrics}
                                        isDisabled={chartLoading}
                                    />
                                </CFormGroup>
                            </div>
                        </CCol>
                        <CCol lg='6' xl='4' className='filter-event'>
                            <div className='visitor-session'>
                                <CFormGroup>
                                    <CLabel className='ml-0 mb-1'>Filter Events</CLabel>
                                    <Select
                                        placeholder='All Events'
                                        options={events.listEvents}
                                        isMulti={true}
                                        onFocus={() => {
                                            if (customDate) {
                                                setCustomDate(false);
                                            }
                                        }}
                                        onChange={(values) => {
                                            onSelectEvent(values);
                                        }}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                        value={keysConcept}
                                        isDisabled={chartLoading}
                                    />
                                </CFormGroup>
                            </div>
                        </CCol>
                        <div className='filter-buttons'>
                            <div className='button-reload-wrap'>
                                <CButton className='button-reload hover-show-tooltip' onClick={reloadReporting}>
                                    <CIcon name='iconReload' />
                                </CButton>
                                <div className='block-tooltip'>
                                    <CIcon name='arrow-preview' className='arrow-preview'></CIcon>
                                    <div className='block-tooltip-text'>
                                        <p>Reload Reporting</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CRow>
                    <EventChart
                        filter={customFilterType}
                        selected={filterType}
                        keysConcept={keysConcept}
                        fromDay={fromDay}
                        endDay={endDay}
                        chartLoading={chartLoading}
                        setChartLoading={setChartLoading}
                        keysFilterMetrics={keysFilterMetrics}
                        chartStateReload={chartStateReload}
                    />
                </CCardBody>
            </CCard>
            <EventTable
                key={eventTableId}
                filter={customFilterType}
                selected={filterType}
                keysConcept={keysConcept}
                fromDay={fromDay}
                endDay={endDay}
                setVariableValueToShow={setVariableValueToShow}
            />
            <VariableValueModal
                variableValueToShow={variableValueToShow}
                setVariableValueToShow={setVariableValueToShow}
                parentCustomFilterType={customFilterType}
                parentFilterType={filterType}
                parentFilterBeginDate={filterBeginDate}
                parentFilterEndDate={filterEndDate}
            />
            <GetHelpLabel />
        </div>
    );
};

export default EventReporting;
