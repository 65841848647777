import React, { useContext } from "react";
import { CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
    SupportTicketPopupContext,
    DECISION_STEPS,
    HELP_DATA_LAYERS,
    CREATE_SUPPORT_TICKET,
} from "../GetHelpLabel";
import { HIDE_TICKET_FEATURES, STEP_PREVIOUS_CREATE_TICKET } from "../../../../../constants";

const DecisionStep3 = () => {
    const { setCurrentStep, setTicketType } = useContext(SupportTicketPopupContext);

    const listOptions = [
        {
            value: 'Getting ListenLayer to work on my website',
            span: `(script placement, domains, publishing/versions, general debugging)`,
            onclickValue: CREATE_SUPPORT_TICKET.step1,
            saveLocal: true,
            hideTicketFeatures: true,
        },
        {
            value: 'An issue with a specific Listener',
            span: `(or a feature inside a Listener)`,
            onclickValue: CREATE_SUPPORT_TICKET.step1,
            saveLocal: true,
            hideTicketFeatures: true,
        },
        {
            value: 'An issue with my account',
            span: `(billing, access, etc.)`,
            onclickValue: CREATE_SUPPORT_TICKET.step1,
            saveLocal: true,
        },
        {
            value: 'I need help with data layers',
            span: '',
            onclickValue: HELP_DATA_LAYERS.step1,
            saveLocal: false
        },
        {
            value: 'Report a bug',
            span: `(use this if you know you've found a bug!)`,
            onclickValue: CREATE_SUPPORT_TICKET.step1,
            saveLocal: true
        },
        {
            value: 'Something else',
            span: '',
            onclickValue: CREATE_SUPPORT_TICKET.step1,
            saveLocal: true
        }
    ]

    const buttonHandleClick = (val, step, save, hideTicketFeatures = false) => {
        setTicketType(val);
        if (save) {
            localStorage.setItem(
                STEP_PREVIOUS_CREATE_TICKET,
                DECISION_STEPS.step3
            );
        }
        localStorage.setItem(HIDE_TICKET_FEATURES, hideTicketFeatures);
        setCurrentStep(step);
    }
    return (
        <div className="ticket-popup-content">
            <div className="btn-back-ticket">
                <CIcon name="icon-chevron-left" width="6" />
                <span onClick={() => setCurrentStep(DECISION_STEPS.step2)}>Back</span>
            </div>
            <div className="ticket-popup-header">
                <h5>We're Here to Help!</h5>
                <p>What type of problem are you having?</p>
            </div>
            <div className="ticket-popup-body">
                {/* Go1 */}
                {
                    listOptions.map((option, index) => {
                        return (
                            <CButton
                                key={index}
                                color="primary"
                                className="btn-text-example"
                                onClick={() => buttonHandleClick(option.value, option.onclickValue, option.saveLocal, option.hideTicketFeatures)}
                            >
                                <span>{option.value}</span>
                                <span>{option.span}</span>
                            </CButton>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default DecisionStep3;
