import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
     CButton,
     CModal,
     CModalBody,
     CModalFooter,
} from '@coreui/react';
import { callApi } from '../../../../../apiCaller';
import { getTokenCookie } from '../../../../../utils';
import { API_CONSENT_RULE } from '../../../../../constants';
import { Redirect } from 'react-router-dom';
import SimpleRuleStep1 from './simple/EditStep1';
import SimpleRuleStep2 from './simple/EditStep2';
import SimpleRuleStep3 from './simple/EditStep3';
// import SimpleRuleStep4 from './simple/EditStep4';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';

const EditSimpleRule = ({ rule }) => {
     const [stepsData, setStepsData] = useState({ ...rule.data, id: rule.id, type: rule.type});
     return (
          <>
               <SimpleRuleStep1
                    stepsData={stepsData}
                    setStepsData={setStepsData}
               />
               <SimpleRuleStep2
                    stepsData={stepsData}
                    setStepsData={setStepsData}
               />
               <SimpleRuleStep3
                    stepsData={stepsData}
                    setStepsData={setStepsData}
               />
               {/* <SimpleRuleStep4
                    stepsData={stepsData}
                    setStepsData={setStepsData}
               /> */}
          </>
     )
}

export const RuleContext = React.createContext({});

const EditRule = ({ id }) => {
     const token = getTokenCookie();
     const editingRuleReload = useSelector(state => state.subscriber.editingRuleReload);
     const redirectRules = useSelector(state => state.subscriber.redirectRules);
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const [rule, setRule] = useState();
     const [modal, setModal] = useState(false);
     const [activeStep, setActiveStep] = useState(1);
     const [fetchRuleLoading, setFetchRuleLoading] = useState();
     const [changeConsentMethod, setChangeConsentMethod] = useState(false)


     const fetchRule = () => {
          setFetchRuleLoading(true);

          callApi(`${API_CONSENT_RULE}${activeAccount.id}/${id}`, 'GET', null, token)
               .then(response => {
                    if (response.status === 200) {
                         const { rule } = response.data;

                         if (rule.accountId !== activeAccount.id) {
                              window.location.href = '/';
                         }
                         setRule(rule);
                    }
               })
               .finally(() => {
                    setFetchRuleLoading(false);
               });
     }

     // editingRuleReload meaning: Editing rule pusher will make editingRuleReload = !editingRuleReload,
     // so other users are editing the same rule will fetch rule again
     useEffect(fetchRule, [editingRuleReload]);

     return (
          <>
               {
                    fetchRuleLoading ? (
                         <CenterSpinner />
                    ) : (
                         <div className="cvr-create-new custom-rule consent-privacy">
                              {redirectRules.status && <Redirect to={redirectRules.to} />}
                              {
                                   rule && (
                                        <>
                                             <p className='mb-0'><strong>Edit Regional Consent Rule:</strong></p>
                                             <h1>{rule.data.name}</h1>
                                             <p>Use the steps below to configure your rule.</p>
                                        
                                             <RuleContext.Provider
                                                  value={{
                                                       activeStep,
                                                       setRule,
                                                       setActiveStep,
                                                       fetchRule,
                                                       changeConsentMethod,
                                                       setChangeConsentMethod
                                                  }}
                                             >
                                                  <EditSimpleRule rule={rule} />
                                             </RuleContext.Provider>
                                             <CModal
                                                  show={modal}
                                                  centered={true}
                                                  onClose={() => setModal(false)}
                                             >
                                                  <CModalBody className="text-center">
                                                       <h3 className="mb-4">Warning! This Will Change Your Rule</h3>
                                                       <p>
                                                            You are about to change your rule type. Doing this will reset and remove all settings inside this rule other than the name and description.
                                                       </p>
                                                       <p>Are you sure you want to do this? You cannot undo this once you publish it!</p>
                                                  </CModalBody>
                                                  <CModalFooter className="text-center justify-content-center">
                                                       <CButton
                                                            color="primary"
                                                            className="mr-3 text-uppercase"
                                                            onClick={() => setModal(false)}>Cancel</CButton>
                                                       <CButton
                                                            color="secondary"
                                                            onClick={() => { }}
                                                       >Save Change and Reset Rule</CButton>
                                                  </CModalFooter>
                                             </CModal>
                                        </>
                                   )
                              }
                         </div>
                    )
               }
          </>
     )
}

export default EditRule
