import React from 'react'
import { CLabel, CSelect, CTooltip } from '@coreui/react';
import { useContext } from 'react';
import { LookupTableContext } from './LookupTable';
import { VARIABLE_OPERATORS } from '../../../../../../constants';

const TableHeaderRow = () => {
    const {
        pusherCondition, pusherCustomVariable,
        conditions, IsExisted, handleSelectOperator
    } = useContext(LookupTableContext);

    const eventKey = (find, key) => {
        let string = find && find.friendlyName ? find.friendlyName : key;

        return (
            <>
                <CTooltip
                    content={string}
                    placement="top"
                    advancedOptions={{ delay: { hide: 0 } }}
                >
                    <span>{string}</span>
                </CTooltip>
            </>
        )
    }

    return (
        <>
            <tr className="table-header">
                {
                    conditions && conditions[0].map((condition, index) => {
                        let { conjunction } = condition;
                        let conditionValues = condition.g0;
                        let { type, operator, key } = conditionValues;
                        let find = IsExisted(conditionValues.id, type);

                        return (
                            <React.Fragment key={index}>
                                {
                                    find && (
                                        <td
                                            className={
                                                operator === null ?
                                                    `no-condition variable-header`
                                                    : `has-condition variable-header`
                                            }
                                        >
                                            <CLabel>{type}</CLabel>
                                            {eventKey(find, key)}
                                            {
                                                (type !== 'customVariable' && index < pusherCondition.length - 1) && (
                                                    <CSelect
                                                        name="conjunction"
                                                        className="conditions"
                                                        value={conjunction ? conjunction : "and"}
                                                        onChange={(e) => handleSelectOperator(e, index)}
                                                    >
                                                        <option value="and">and</option>
                                                        <option value="or">or</option>
                                                    </CSelect>
                                                )
                                            }
                                        </td>
                                    )
                                }
                            </React.Fragment>
                        )
                    })
                }
                {
                    pusherCustomVariable && pusherCustomVariable.map((customVariable, index) => {
                        return (
                            <td key={index}>
                                <CLabel>Variable</CLabel>
                                <CTooltip
                                    content={
                                        customVariable.name
                                    }
                                    placement="right-end"
                                >
                                    <span>{customVariable.name}</span>
                                </CTooltip>
                            </td>
                        )
                    })
                }
            </tr>
            <tr className="table-body conditions-row">
                {
                    conditions && conditions[0].map((condition, index) => {
                        let conditionValues = condition.g0;
                        let { type, operator } = conditionValues;
                        let find = IsExisted(conditionValues.id, type);
                        return (
                            <React.Fragment key={index}>
                                {
                                    find && (
                                        <td>
                                            <CSelect
                                                name="operator"
                                                className="variable-conditions rounded-0"
                                                value={operator}
                                                onChange={(e) => handleSelectOperator(e, index)}
                                            >
                                                <option value="" disabled hidden>Select</option>
                                                {
                                                    VARIABLE_OPERATORS.map(({ VALUE, TEXT }) => (
                                                        <option key={VALUE} value={VALUE}>
                                                            {TEXT}
                                                        </option>
                                                    ))
                                                }
                                            </CSelect>
                                        </td>
                                    )
                                }
                            </React.Fragment>
                        )
                    })
                }
                {
                    pusherCustomVariable && pusherCustomVariable.map((_, index) => {
                        return <td key={index} className="custom-variable-conditions"></td>
                    })
                }
            </tr>
        </>
    )
}

export default TableHeaderRow
