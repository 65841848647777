import CIcon from '@coreui/icons-react';
import { CButton, CPagination } from '@coreui/react';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import {
     setBrowserRules,
     setClickRules,
     setConsentRules,
     setCustomVariableRules,
     setPopulateRules,
     setUserSourceRules,
} from '../../../actions/subscriber';
import { getListenerColorIcon } from '../../../helpers/cms/subscriber';
import { useActiveFeatureV2 } from '../../../helpers/customHooks';
import CenterSpinner from '../Loadings/CenterSpinner';
import { ConfirmRemovePopup, ConfirmSaveChange, InstructionPopup } from '../popup';
import RuleCard from './RuleCard';
import RuleCardNoDrag from './RuleCardNoDrag';

const ListRules = ({
     allRules,
     rules,
     featureType = '',
     allowEdit = true,
     setSaveOrderBtn,
     handleDelete,
     handleToggleStatus,
     saveLoading,
     handleSaveReorderRules,
     setMenuIsOpen,
     onClickEdit,
}) => {
     const dispatch = useDispatch();

     const [modal, setModal] = useState(false);
     const [tempRule, setTempRule] = useState({});
     const [toggleStatusModal, setToggleStatusModal] = useState(false);

     const onClickDelete = (index) => {
          setModal(true);
          setTempRule(rules[index]);
     };

     const onToggleStatus = (index) => {
          setToggleStatusModal(true);
          setTempRule(rules[index]);
     };

     const reorder = (list, startIndex, endIndex) => {
          const result = Array.from(list);
          const [removed] = result.splice(startIndex, 1);
          result.splice(endIndex, 0, removed);

          // Update rule order
          if (removed.order) {
               const tempOrder = result[startIndex].order;
               result[startIndex].order = result[endIndex].order;
               result[endIndex].order = tempOrder;
          } else if (removed.ruleOrder) {
               const tempOrder = result[startIndex].ruleOrder;
               result[startIndex].ruleOrder = result[endIndex].ruleOrder;
               result[endIndex].ruleOrder = tempOrder;
          }

          return result;
     };

     const onDragEnd = (result) => {
          if (!result.destination) {
               return;
          }

          if (result.destination.index === result.source.index) {
               return;
          }

          const newReorderRules = reorder(rules, result.source.index, result.destination.index);
          const listRuleIds = rules.map((el) => el.id);
          const allRulesFilter = allRules.filter((rule) => !listRuleIds.includes(rule.id));
          const newAllRules = [...allRulesFilter, ...newReorderRules];

          setSaveOrderBtn(true);
          switch (featureType) {
               case 'consent':
                    dispatch(setConsentRules(newAllRules));
                    break;

               case 'storage':
                    dispatch(setBrowserRules(newAllRules));
                    break;

               case 'customization':
                    dispatch(setCustomVariableRules(newAllRules));
                    break;

               case 'user sources':
                    dispatch(setUserSourceRules(newAllRules));
                    break;

               case 'populate':
                    dispatch(setPopulateRules(newAllRules));
                    break;

               case 'event':
                    dispatch(setClickRules(newAllRules));
                    break;

               default:
                    break;
          }
     };

     const onDragStart = () => {
          if (setMenuIsOpen) {
               setMenuIsOpen(false);
          }
     };

     return (
          <>
               <div className='list-rules'>
                    {handleSaveReorderRules ? (
                         <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                              <Droppable droppableId='list'>
                                   {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps} className='moveable-rules'>
                                             {rules.map((rule, index) => {
                                                  return (
                                                       <React.Fragment key={index}>
                                                            <RuleCard
                                                                 rule={rule}
                                                                 index={index}
                                                                 onClickDelete={() => onClickDelete(index)}
                                                                 onToggleStatus={onToggleStatus}
                                                                 allowEdit={allowEdit}
                                                                 onClickEdit={() => onClickEdit(rule.id, rule.type)}
                                                            />
                                                       </React.Fragment>
                                                  );
                                             })}
                                             {provided.placeholder}
                                        </div>
                                   )}
                              </Droppable>
                         </DragDropContext>
                    ) : (
                         rules.map((rule, index) => {
                              return (
                                   <React.Fragment key={rule.id}>
                                        <RuleCardNoDrag
                                             rule={rule}
                                             index={index}
                                             onToggleStatus={onToggleStatus}
                                             onClickDelete={() => onClickDelete(index)}
                                             onClickEdit={() => onClickEdit(rule.id, rule.type)}
                                             allowEdit={allowEdit}
                                        />
                                   </React.Fragment>
                              );
                         })
                    )}
               </div>

               {handleDelete && (
                    <ConfirmRemovePopup
                         show={modal}
                         onAccept={() => {
                              setModal(false);
                              handleDelete(tempRule);
                         }}
                         onClose={() => {
                              setModal(false);
                              setTempRule({});
                         }}
                         isLoading={saveLoading}
                    >
                         <p>
                              You are about to delete the rule <strong>"{tempRule ? tempRule.name : ''}"</strong>. Please note{' '}
                              <span className='text-danger'>this action is irreversible</span>.
                         </p>
                    </ConfirmRemovePopup>
               )}

               {handleToggleStatus && (
                    <ConfirmSaveChange
                         show={toggleStatusModal}
                         onAccept={() => {
                              setToggleStatusModal(false);
                              handleToggleStatus(tempRule);
                         }}
                         onClose={() => {
                              setToggleStatusModal(false);
                              setTempRule({});
                         }}
                         isLoading={saveLoading}
                         title='Are you sure?'
                    >
                         <p>
                              You are about to {tempRule && tempRule.status ? 'pause' : 'active'} the rule <strong>"{tempRule ? tempRule.name : ''}"</strong>.
                         </p>
                    </ConfirmSaveChange>
               )}
          </>
     );
};

const ListAllRules = ({
     allRules,
     allInitRules = null,
     fetchLoading,
     handleDelete = null,
     onClickCreate = null,
     onClickEdit = null,
     handleToggleStatus = null,
     handleSaveReorderRules = null,
     showDragDropGuide = true,
     featureType = '',
     saveLoading,
     allowCreate = true,
     allowEdit = true,
     hideNoRuleMessage,
     setMenuIsOpen,
}) => {
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const listActiveListeners = accountListeners && accountListeners.filter((listener) => listener.configStatus).sort((a, b) => a.name.localeCompare(b.name));
     const activeFeature = useActiveFeatureV2(featureType);

     const [newOrder, setNewOrder] = useState();
     const [activePage, setActivePage] = useState(1);
     const [reorderModal, setReorderModal] = useState(false);
     const [saveOrderBtn, setSaveOrderBtn] = useState(false);
     const [instructionPopupContent, setInstructionPopupContent] = useState(null);

     const itemsPerPage = 20;
     const totalPages = allRules && Math.ceil(allRules.length / itemsPerPage);
     const pagingRules = [];

     listActiveListeners.forEach((listener) => {
          allRules.forEach((rule) => {
               if (listener.id === rule.listenerId) {
                    pagingRules.push(rule);
               }
          });
     });

     const newPagingRules = pagingRules.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);

     const fetchActivePage = () => {
          if (newPagingRules.length === 0 && activePage > 1) {
               setActivePage(activePage - 1);
          }
     };

     useEffect(fetchActivePage, [newPagingRules]);

     const onClickSaveOrderChange = () => {
          const dataSave = {
               accountId: activeAccount.id,
               rulesOrder: [],
          };

          if (allInitRules && allInitRules.length > 0) {
               allRules.forEach((rule, index) => {
                    if (allInitRules[index]) {
                         if (allInitRules[index].order) {
                              dataSave.rulesOrder.push({
                                   id: rule.id,
                                   order: allInitRules[index].order,
                              });
                         } else {
                              dataSave.rulesOrder.push({
                                   id: rule.id,
                                   ruleOrder: allInitRules[index].ruleOrder,
                              });
                         }
                    }
               });
          }

          setNewOrder(dataSave);
          setReorderModal(true);
     };

     const onPaginationChange = (activePage) => {
          setActivePage(activePage);
     };

     return (
          <>
               {allRules && allRules.length > 1 && handleSaveReorderRules && showDragDropGuide && (
                    <div className='drag-drop-guide'>
                         <CIcon name='iconStraightFlag' height={12} />
                         <strong>Drag and drop your rules to change the order in which they apply. Rules are read from top to bottom until a match is found.</strong>
                    </div>
               )}

               {fetchLoading ? (
                    <CenterSpinner />
               ) : allRules.length > 0 ? (
                    listActiveListeners &&
                    listActiveListeners.map((listener) => {
                         const listenerRules = newPagingRules.filter((rule) => rule.listenerId === listener.id);

                         return (
                              listenerRules &&
                              listenerRules.length > 0 && (
                                   <div key={listener.id} className='list-all-rules'>
                                        <div className='d-flex listener-name'>
                                             <img src={getListenerColorIcon(listener)} alt='Icon' />
                                             <span>{listener.name}</span>
                                        </div>
                                        <ListRules
                                             allRules={allRules}
                                             rules={listenerRules}
                                             featureType={featureType}
                                             allowEdit={allowEdit}
                                             handleDelete={handleDelete}
                                             setSaveOrderBtn={setSaveOrderBtn}
                                             saveLoading={saveLoading}
                                             handleToggleStatus={handleToggleStatus}
                                             handleSaveReorderRules={handleSaveReorderRules}
                                             setMenuIsOpen={setMenuIsOpen}
                                             onClickEdit={onClickEdit}
                                        />
                                   </div>
                              )
                         );
                    })
               ) : (
                    !hideNoRuleMessage && <div className='no-rule text-center'>There are no rules</div>
               )}

               {totalPages > 1 && <CPagination activePage={activePage} pages={totalPages} onActivePageChange={(i) => onPaginationChange(i)} />}

               {allowCreate && (
                    <div className='drag-drop-actions d-flex align-items-center justify-content-start'>
                         {saveOrderBtn && (
                              <CButton className='btn-save-change' color='success' onClick={onClickSaveOrderChange}>
                                   <CIcon name='icon-check' />
                                   SAVE ORDER
                              </CButton>
                         )}
                         <CButton className='btn-create-rule' color='primary' onClick={onClickCreate}>
                              <i className='fal fa-plus mr-1'></i>
                              CREATE A NEW RULE
                         </CButton>

                         {get(activeFeature, 'featureContent.ruleInstruction.isOn') && (
                              <CButton
                                   className='learn-how-to-write'
                                   color='link'
                                   onClick={
                                        activeFeature.featureContent.ruleInstruction.popup
                                             ? () => setInstructionPopupContent(activeFeature.featureContent.ruleInstruction.popup)
                                             : null
                                   }
                              >
                                   {activeFeature.featureContent.ruleInstruction.buttonText
                                        ? activeFeature.featureContent.ruleInstruction.buttonText
                                        : 'Learn how to write rules'}
                              </CButton>
                         )}
                    </div>
               )}

               {handleSaveReorderRules && (
                    <ConfirmSaveChange
                         show={reorderModal}
                         onAccept={() => {
                              setReorderModal(false);
                              setSaveOrderBtn(false);
                              handleSaveReorderRules(newOrder);
                         }}
                         onClose={() => setReorderModal(false)}
                         isLoading={saveLoading}
                         title='Are you sure you want to save?'
                    >
                         Change order list {featureType} rules.
                    </ConfirmSaveChange>
               )}

               <InstructionPopup show={!!instructionPopupContent} onClose={() => setInstructionPopupContent(null)} {...instructionPopupContent} />
          </>
     );
};

export default ListAllRules;
