import React, { useState, useRef, useCallback, useEffect } from 'react';
import Select, { createFilter } from 'react-select';
import { CInput } from '@coreui/react';
import PropTypes from 'prop-types';

import { useOutsideHandling } from '../../../utils';

// const { colors } = defaultTheme;

const defaultSelectStyles = {
     control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
     menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

const Dropdown = ({ children, isOpen, target, onClose, fieldFull }) => (
     <div className="select-wrapper ">
          {target}
          {fieldFull ? isOpen ? <Menu1>{children}</Menu1> : null : isOpen ? <Menu>{children}</Menu> : null}
          {isOpen ? <Blanket onClick={onClose} /> : null}
     </div>
);

const Menu1 = (props) => {
     return (
          <div
               style={{
                    // backgroundColor: 'white',
                    borderRadius: 4,
                    marginTop: 8,
                    position: 'absolute',
                    zIndex: 2,
                    cursor: 'default',
               }}
               {...props}
          />
     );
};
const Menu = (props) => {
     return (
          <div
               style={{
                    // backgroundColor: 'white',
                    borderRadius: 4,
                    marginTop: 8,
                    position: 'absolute',
                    zIndex: 2,
                    width: '100%',
                    cursor: 'default',
               }}
               {...props}
          />
     );
};

const Blanket = (props) => (
     <div
          style={{
               bottom: 0,
               left: 0,
               top: 0,
               right: 0,
               position: 'absolute',
               zIndex: 1,
          }}
          {...props}
     />
);

// const Svg = p => (
//     <svg
//         width="24"
//         height="24"
//         viewBox="0 0 24 24"
//         focusable="false"
//         role="presentation"
//         {...p}
//     />
// );

// const DropdownIndicator = () => (
//     <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
//         <Svg>
//             <path
//                 d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
//                 fill="currentColor"
//                 fillRule="evenodd"
//             />
//         </Svg>
//     </div>
// );

const SelectSearchField = (props) => {
     const {
          id,
          isBlur,
          placeholder,
          options,
          onChange,
          value,
          isDisabled,
          className,
          classNamePrefix,
          classNameWrapper,
          selectInputType,
          displayPlaceholder,
          filterOption,
          selectStyles,
          fieldFull,
     } = props;
     const wrapperRef = useRef(null);
     const [isOpen, setIsOpen] = useState(false);
     const [initValue, setInitValue] = useState(value);
     const inputValue = initValue && initValue.label && !displayPlaceholder ? initValue.label : placeholder;
     const finalFilterOption =
          filterOption ||
          createFilter({
               matchFrom: 'any',
               stringify: (option) => `${option.label}`,
               ignoreAccents: false,
          });
     const styles = selectStyles ? { ...selectStyles, ...defaultSelectStyles } : defaultSelectStyles;

     useEffect(() => {
          if (value) {
               setInitValue(value);
          }
     }, [value]);

     const clickOutside = useCallback(() => {
          setIsOpen(false);
     }, []);

     useOutsideHandling(wrapperRef, clickOutside);

     const toggleOpen = () => {
          setTimeout(() => {
               setIsOpen(!isOpen);
          }, 1);
     };

     const onSelectChange = (e) => {
          toggleOpen();
          setInitValue(e);
          onChange(e);
     };
     return (
          <div
               className={`${classNameWrapper}${isBlur ? ' select-react-customize blur' : ' select-react-customize'}${
                    !value || !value.value ? ' not-selected' : ''
               } ${className}`}
               ref={wrapperRef}
          >
               <Dropdown
                    fieldFull={fieldFull}
                    isOpen={isOpen}
                    onClose={toggleOpen}
                    target={
                         selectInputType ? (
                              <CInput
                                   onClick={() => toggleOpen()}
                                   readOnly
                                   value={inputValue}
                                   disabled={isDisabled}
                                   className={`${isDisabled ? 'disable-select' : ''}`}
                              />
                         ) : (
                              <div
                                   className={`stretch-container form-control ${isDisabled ? 'disabled' : ''} ${isDisabled ? 'disable-select' : ''} `}
                                   onClick={toggleOpen}
                              >
                                   <span className="overflow-drag">{inputValue}</span>
                              </div>
                         )
                    }
               >
                    <Select
                         id={id}
                         autoFocus
                         backspaceRemovesValue={false}
                         components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                         controlShouldRenderValue={false}
                         hideSelectedOptions={false}
                         isClearable={false}
                         menuIsOpen
                         onChange={(e) => onSelectChange(e)}
                         options={options}
                         filterOption={finalFilterOption}
                         placeholder="Type here to filter the list…"
                         styles={styles}
                         tabSelectsValue={false}
                         value={initValue}
                         classNamePrefix={classNamePrefix}
                         menuShouldScrollIntoView
                    />
               </Dropdown>
          </div>
     );
};

SelectSearchField.propTypes = {
     id: PropTypes.string,
     isBlur: PropTypes.bool,
     placeholder: PropTypes.string,
     options: PropTypes.array,
     onChange: PropTypes.func,
     value: PropTypes.object,
     isDisabled: PropTypes.bool,
     className: PropTypes.string,
     classNamePrefix: PropTypes.string,
     classNameWrapper: PropTypes.string,
     selectInputType: PropTypes.bool,
     displayPlaceholder: PropTypes.bool,
     filterOption: PropTypes.func,
     selectStyles: PropTypes.object,
};

SelectSearchField.defaultProps = {
     selectInputType: true,
};

export default SelectSearchField;
