import React, { useContext } from "react";
import { CButton } from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { SupportTicketPopupContext, DECISION_STEPS, GETTING_LISTEN_LAYER, CREATE_SUPPORT_TICKET } from "../GetHelpLabel";
import { STEP_PREVIOUS_CREATE_TICKET } from "../../../../../constants";

const GettingListenLayer1 = () => {
    const { setCurrentStep } = useContext(SupportTicketPopupContext);

    return (
        <div className="ticket-popup-content">
            <div className="btn-back-ticket">
                <CIcon name="icon-chevron-left" width="6" />
                <span onClick={() => setCurrentStep(DECISION_STEPS.step3)}>Back</span>
            </div>
            <div className="ticket-popup-header">
                <h5>We're Here to Help!</h5>
                <p>Tell us about your issue.</p>
            </div>
            <div className="ticket-popup-body">
                <CButton
                    onClick={() => setCurrentStep(GETTING_LISTEN_LAYER.step2)}
                    color="primary"
                    className="btn-text-example"
                >
                    <span>ListenLayer isn't working on my website</span>
                    <span>{`(script placement, domains, etc.)`}</span>
                </CButton>
                <CButton color="primary" onClick={() => setCurrentStep(GETTING_LISTEN_LAYER.step3)}>
                    I'm having issues with publishing or versions
                </CButton>
                <CButton color="primary" onClick={() => {
                    setCurrentStep(CREATE_SUPPORT_TICKET.step1)
                    localStorage.setItem(STEP_PREVIOUS_CREATE_TICKET, GETTING_LISTEN_LAYER.step1)
                }}>
                    Something else
                </CButton>
            </div>
        </div>
    );
};

export default GettingListenLayer1;
