import { CButton, CFade, CCollapse, CLink } from '@coreui/react';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { NOTICE_TYPE } from '../../../constants';
import { getPreviewNoticeMessage } from '../../../helpers/cms/subscriber';
import { CreateTheWebsiteContext } from './ThePreviewWebsite';
import CIcon from '@coreui/icons-react';

const DomainWarning = () => {
     const { setActionType, setShowPopup } = useContext(CreateTheWebsiteContext);

     return (
          <CFade timeout={200} in={true} className={`notice notice-warning`}>
               <div className='d-flex align-items-center justify-content-between'>
                    <div className='content'>
                         <h4>Warning! Domain Configuration Error</h4>
                         <p>This domain is not configured to work with your ListenLayer account.</p>
                         <p>Add the domain to your website settings and refresh the preview.</p>
                    </div>
                    <CButton
                         color='dark'
                         onClick={() => {
                              setActionType('addDomain');
                              setShowPopup(true);
                         }}
                    >
                         Add the domain
                    </CButton>
               </div>
          </CFade>
     );
};

const TestListenerCompleted = () => {
     const { currentTestingListener, setShowPopupEndTest } = useContext(CreateTheWebsiteContext);

     return (
          <CFade timeout={200} in={true} className='notice notice-complete'>
               <div className='d-flex align-items-center justify-content-between'>
                    <div className='content'>
                         <h4>{currentTestingListener.name} Test Complete</h4>
                         <p>We've received enough {currentTestingListener.name} events to complete this test.</p>
                         <p>End the Session when you are ready.</p>
                    </div>
                    <CButton
                         className="text-capitalize"
                         onClick={() => {
                              setShowPopupEndTest(true);
                         }}
                    >
                         End Test
                    </CButton>
               </div>
          </CFade>
     );
};

const TestListenerWarning = () => {
     const { currentTestingListener, listFeatures } = useContext(CreateTheWebsiteContext);
     const columnCountClass = listFeatures.length <= 3 ? 'columnCount__1' : listFeatures.length <= 6 ? 'columnCount__2' : 'columnCount__3';

     const [showCollapse, setShowCollapse] = useState(true);

     return (
          <CFade timeout={200} in={true} className='notice notice-warning listener-warning'>
               <h4>Action Required: Test {currentTestingListener.name}</h4>
               <div className='content'>
                    {getPreviewNoticeMessage(currentTestingListener)}

                    {listFeatures.length > 0 && (
                         <div className='collapse-row'>
                              <CLink className={`btn-expand ${showCollapse ? 'show' : ''}`} onClick={() => setShowCollapse(!showCollapse)}>
                                   {showCollapse ? 'Minimize' : 'Expand'}
                              </CLink>
                              <CCollapse show={showCollapse}>
                                   <div className={`list-events ${columnCountClass}`}>
                                        {listFeatures.map(({ name, fired }) => {
                                             return (
                                                  <div className='d-flex align-items-center event-status' key={name}>
                                                       {fired ? <CIcon name='solidCheckCircle' /> : <CIcon name='solidCircle' />}
                                                       <p>{name}</p>
                                                  </div>
                                             );
                                        })}
                                   </div>
                              </CCollapse>
                         </div>
                    )}
               </div>
          </CFade>
     );
};

const ScriptWarning = () => {
     const { setActionType, setShowPopup } = useContext(CreateTheWebsiteContext);

     return (
          <CFade timeout={200} in={true} className='notice notice-warning'>
               <div className='d-flex align-items-center justify-content-between'>
                    <div className='content'>
                         <h4>Warning! ListenLayer Script Missing</h4>
                         <p>
                              You're on a website or page that doesn't contain your ListenLayer script. Learn how to add your script to this page so
                              ListenLayer will work.
                         </p>
                    </div>
                    <CButton
                         className='text-capitalize'
                         onClick={() => {
                              setActionType('addScriptManual');
                              setShowPopup(true);
                         }}
                         color='dark'
                    >
                         Get My Script
                    </CButton>
               </div>
          </CFade>
     );
};

const AccountWarning = () => {
     const { setActionType, setShowPopup } = useContext(CreateTheWebsiteContext);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);

     return (
          <CFade timeout={200} in={true} className='notice notice-warning'>
               <div className='d-flex align-items-center justify-content-between'>
                    <div className='content'>
                         <h4>This website does not contain the script for the account you are testing (account ID: {activeAccount.id}).</h4>
                         <p>{`Place the correct ListenLayer script on your website using Google Tag Manager, or place it above the GTM container in your <head> tag.`}</p>
                    </div>
                    <CButton
                         className='text-capitalize'
                         onClick={() => {
                              setActionType('addScriptManualPreview');
                              setShowPopup(true);
                         }}
                         color='dark'
                    >
                         Get My Script
                    </CButton>
               </div>
          </CFade>
     );
};

const NoticeWebsite = () => {
     const { noticeType, currentTestingListener, iframeLoading } = useContext(CreateTheWebsiteContext);
     const { accountWarning } = useSelector((state) => state.subscriber.preview);

     const noticeShow = (type) => {
          let content = '';

          switch (type) {
               case NOTICE_TYPE.LISTENER_WARNING:
                    if (currentTestingListener) {
                         content = <TestListenerWarning />;
                    }
                    break;
               case NOTICE_TYPE.LISTENER_COMPLETED:
                    if (currentTestingListener) {
                         content = <TestListenerCompleted />;
                    }
                    break;
               case NOTICE_TYPE.DOMAIN_WARNING:
                    content = <DomainWarning />;
                    break;
               case NOTICE_TYPE.SCRIPT_WARNING:
                    content = <ScriptWarning />;
                    break;
               default:
                    break;
          }

          return content;
     };

     return !iframeLoading && accountWarning ? <AccountWarning /> : noticeShow(noticeType);
};

export default NoticeWebsite;
