import { useContext, useEffect } from "react";

import { getSessionKeySignupListeners } from "../../../../../../utils";
import { CreateAccountContext } from "../CreateAccount";

const useSaveListenerProgress = (dontSaveCurrentStep) => {
     const { account, stepsData, currentStep } = useContext(CreateAccountContext);
     const {
          selectedListenerTopics,
          allSelectedListeners,
          selectedWeatherFeatures,
          tempSelectedListenerTopics
     } = stepsData;

     const handleFirstLoad = () => {
          const sessionData = JSON.stringify({
               selectedListenerTopics,
               allSelectedListeners,
               selectedWeatherFeatures,
               tempSelectedListenerTopics,
               listenerCurrentStep: dontSaveCurrentStep ? '' : currentStep
          });
          sessionStorage.setItem(getSessionKeySignupListeners(account.id), sessionData);
     }

     useEffect(handleFirstLoad, []);
}

export default useSaveListenerProgress;