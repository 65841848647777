import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { setSubscriberState } from '../../../actions/subscriber.js';
import { getListenerColorIcon } from '../../../helpers/cms/subscriber.js';
import { VIEW_ALL } from '../../../constants/index.js';

const formatOptionLabel = ({ iconSrc, label }) => (
     <div className='option-row'>
          {iconSrc && <img src={iconSrc} alt={label} className='icon' />}
          <div>{label}</div>
     </div>
);

const SelectListenerRule = ({ isDisabled = false }) => {
     const dispatch = useDispatch();
     const selectedListener = useSelector((state) => state.subscriber.selectedListener);
     const selectedListenerRule = useSelector((state) => state.subscriber.selectedListenerRule);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const [menuIsOpen, setMenuIsOpen] = useState(false);

     const listActiveListeners = (accountListeners && accountListeners.filter((listener) => listener.configStatus).sort((a, b) => a.name.localeCompare(b.name))) || [];
     const activeListenerOptions = [];

     listActiveListeners.forEach((listener) => {
          if (listener.configStatus) {
               const iconSrc = getListenerColorIcon(listener);
               activeListenerOptions.push({ value: listener.id, label: listener.name, iconSrc });
          }
     });

     const selectedValue = selectedListenerRule ? activeListenerOptions.find((option) => option.value === selectedListenerRule) : null;

     const fetchSelectListener = () => {
          if (selectedListener === VIEW_ALL) {
               dispatch(setSubscriberState({ selectedListenerRule: null }));
          }
     };
     useEffect(fetchSelectListener, [selectedListener]);

     const handleSetActiveListener = (value) => {
          dispatch(setSubscriberState({ selectedListenerRule: value }));

          if (selectedListener !== VIEW_ALL) {
               dispatch(setSubscriberState({ selectedListener: value }));
          }
     };
     return (
          <div className={`select-listener-wrapper ${menuIsOpen ? 'menu-open' : ''}`}>
               <Select
                    placeholder={'Select a Listener to create its rules'}
                    options={activeListenerOptions}
                    formatOptionLabel={formatOptionLabel}
                    value={selectedValue}
                    onChange={(option) => handleSetActiveListener(option.value)}
                    isDisabled={isDisabled}
                    className={`select-listener ${isDisabled ? 'disable-select' : ''}`}
                    menuIsOpen={menuIsOpen}
                    onMenuOpen={() => setMenuIsOpen(true)}
                    onMenuClose={() => setMenuIsOpen(false)}
               />
          </div>
     );
};

export default SelectListenerRule;
