import { CButton, CCol, CForm, CLabel, CRow } from '@coreui/react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setFlexibleModal, setRuleHaveEditting } from '../../../../../../actions/common';
import { updateRule } from '../../../../../../actions/pusher';
import {
     setClickRules,
     setCustomVariableOptions,
     setInitialClickRules,
     setNewLayout,
     setShowBlockAccountPopup,
     setUnsavedLookupTableModal
} from '../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../apiCaller';
import {
     API_CLIENT_CUSTOM_VARIABLE, API_CLIENT_RULE, API_CLIENT_RULE_REVERT, API_CLIENT_RULE_REVERT_DEFAULT, API_CLIENT_RULE_UPDATE, COMPONENT_NAME, TYPE_SHOW_UNSAVE_CHANGE
} from '../../../../../../constants';
import { subscriberNavKey } from '../../../../../../containers/_nav/subscriber';
import { checkIfDefaultRule } from '../../../../../../helpers/cms/subscriber';
import { useActiveListener } from '../../../../../../helpers/customHooks';
import { deepCopyArray, toastError } from '../../../../../../utils';
import { ConfirmRemovePopup, ConfirmSaveChange, LeaveComponentChangePopup } from '../../../../../general/popup';
import RemovePopup from '../../../../../general/popup/RemovePopup';
import ImportExportTable from './ImportExportTable';
import TableBodyRows from './TableBodyRows';
import TableHeaderRow from './TableHeaderRow';
import CIcon from '@coreui/icons-react';
import { RuleTableContext } from './EditLookupTable';

export const LookupTableContext = React.createContext({});

const LookupTable = ({ rule, initialRule }) => {
     const history = useHistory();
     const dispatch = useDispatch();
     const clickRules = useSelector((state) => state.subscriber.clickRules);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const flexibleModal = useSelector(state => state.theme.flexibleModal);
     const { setRule, fetchRule } = useContext(RuleTableContext);

     const [saveLoading, setSaveLoading] = useState(false);
     const [saveTableModal, setSaveTableModal] = useState(false);
     const [revertModal, setRevertModal] = useState(false);
     const [revertDefaultModal, setRevertDefaultModal] = useState(false);
     const [deleteRuleModal, setDeleteRuleModal] = useState(false);
     const [leaveComponentModal, setLeaveComponentModal] = useState(false);
     const [navigateTo, setNavigateTo] = useState();
     const [btnSaveStatus, setBtnSaveStatus] = useState(isEqual(rule, initialRule));
     const [removeRowModal, setRemoveRowModal] = useState({
          show: false,
          index: -1,
     });
     const [deleteLoading, setDeleteLoading] = useState(false);
     const [hasError, setHasError] = useState([]);
     const [tableRow, setTableRow] = useState();
     const customVariableOptions = useSelector((state) => state.subscriber.customVariableOptions);
     const activeListener = useActiveListener();

     const { conditions, customVariables } = rule;
     const [showVariableModal, setShowVariableModal] = useState(false);
     const unsavedLookupTableModal = useSelector((state) => state.subscriber.unsavedLookupTableModal);
     const [btnAbandon, setBtnAbandon] = useState(false);
     const trackAsText =
          activeListener.code === 'pdfClicks'
               ? 'Track as PDF Click'
               : activeListener.code === 'outboundClicks'
                    ? 'Track as Outbound Clicks?'
                    : 'Track as Internal Clicks?';
     const isDefaultRule = checkIfDefaultRule(activeListener.code, initialRule.name);

     const { id: activeAccountId } = activeAccount;

     const defaultRow = () => {
          const defaultConditions = {
               clickUrlElement: '',
               matchType: '',
               value: '',
          };
          const defaultCustomVariables = { trackAsClicks: '' };
          return { conditions: defaultConditions, customVariables: defaultCustomVariables };
     };

     const getInitialTableRow = () => {
          let table = [];
          let conditionData = conditions.map((item) => item);
          let customVariableData = customVariables.map((item) => item);
          for (let i = 0; i < conditionData.length; i++) {
               table.push({ conditions: conditionData[i], customVariables: customVariableData[i] });
          }
          setTableRow(table);
     };

     const fetchCustomVariables = () => {
          if (customVariableOptions.length === 0) {
               const urlToCall = `${API_CLIENT_CUSTOM_VARIABLE}${activeAccountId}?group=click`;

               callTokenApi(urlToCall, 'GET', null).then((response) => {
                    if (response.status === 200) {
                         dispatch(setCustomVariableOptions(response.data.variables));
                    }
               });
          }
     };

     const fetchData = () => {
          getInitialTableRow();
          fetchCustomVariables();
     };

     useEffect(fetchData, []);

     const beforeunload = function (e) {
          e.preventDefault();
          e.returnValue = '';
     };

     useEffect(() => {
          dispatch(setUnsavedLookupTableModal({ unsaved: !btnSaveStatus }));

          let unblock = history.block((location, action) => {
               let url = location.pathname;
               if (!btnSaveStatus) {
                    setBtnSaveStatus(true);
                    setNavigateTo(url);
                    setLeaveComponentModal(true);
                    return false;
               }
               return true;
          });

          if (!btnSaveStatus) {
               document.querySelector('.flexible-modal-body').addEventListener('beforeunload', beforeunload);
          }

          return () => {
               document.querySelector('.flexible-modal-body').removeEventListener('beforeunload', beforeunload);
               unblock();
          };
     }, [btnSaveStatus, history, dispatch]);

     const handleAcceptLeaveComponent = () => {
          if (unsavedLookupTableModal.show) {
               let table = [];
               let conditionData = initialRule.conditions.map((item) => item);
               let customVariableData = initialRule.customVariables.map((item) => item);
               for (let i = 0; i < conditionData.length; i++) {
                    table.push({ conditions: conditionData[i], customVariables: customVariableData[i] });
               }
               setTableRow(table);

               setBtnSaveStatus(true);
               unsavedLookupTableModal.onAccept();
               dispatch(setUnsavedLookupTableModal({ show: false, onAccept: null }));
          } else {
               setBtnAbandon(true);
               window.location.href = navigateTo;
          }
     };

     const handleCloseLeaveComponent = () => {
          setLeaveComponentModal(false);
          setBtnSaveStatus(false);
          dispatch(setUnsavedLookupTableModal({ show: false }));
          dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.LISTENERS }));
     };

     const handleAddRow = (rows = 1) => {
          let newTableRow = [...tableRow];
          for (let i = 0; i < rows; i++) {
               let newRow = {};

               newRow.conditions = {
                    clickUrlElement: '',
                    matchType: '',
                    value: '',
               };
               newRow.customVariables = {
                    trackAsClicks: '',
               };
               newTableRow.push(newRow);
          }
          setTableRow(newTableRow);
          resetBtnSaveStatus();
     };

     const confirmRemoveRow = () => {
          let data = [...tableRow];
          let { index } = removeRowModal;
          if (data.length > 1) data.splice(index, 1);
          else data = [defaultRow()];
          setTableRow(data);
          setRemoveRowModal({ show: false, index: -1 });
          setDeleteLoading(false);
          resetBtnSaveStatus();
          setDeleteLoading(false);
     };

     const onSubmit = (e) => {
          e.preventDefault();
          let { errorColumn, errorName } = validateSubmited();
          if (errorColumn.length > 0) {
               setHasError(errorColumn);
               if (errorName === 'empty') {
                    toast.error(`Field cannot be empty.`);
               } else {
                    toast.error(`Duplicate!`);
               }
          } else {
               setSaveTableModal(true);
          }
     };

     const validateSubmited = () => {
          let errorColumn = [],
               errorName = '';

          for (let i = 0; i < tableRow.length; i++) {
               for (let j = i + 1; j < tableRow.length - 1; j++) {
                    if (
                         tableRow[i].conditions.clickUrlElement === tableRow[j].conditions.clickUrlElement &&
                         tableRow[i].conditions.matchType === tableRow[j].conditions.matchType &&
                         tableRow[i].conditions.value === tableRow[j].conditions.value &&
                         tableRow[i].customVariables.trackAsClicks === tableRow[j].customVariables.trackAsClicks
                    ) {
                         errorColumn.push(i, j);
                         errorName = 'double';
                    }
               }
          }

          tableRow.some((row, index) => {
               if (
                    row.conditions.clickUrlElement === '' ||
                    (!['exav', 'nex'].includes(row.conditions.matchType) && row.conditions.value === '') ||
                    row.customVariables.trackAsClicks === ''
               ) {
                    errorColumn.push(index);
                    errorName = 'empty';
                    return true;
               }
               return false;
          });

          return { errorColumn, errorName };
     };

     const onAcceptSaveModal = () => {
          let data = { ...rule };
          let newConditions = [],
               newCustomVariables = [];

          tableRow.forEach((row) => {
               newConditions.push(row.conditions);
               newCustomVariables.push(row.customVariables);
          });

          handleSaveLookupTable({
               accountId: data.accountId,
               listenerId: data.listenerId,
               id: data.id,
               conditions: newConditions,
               customVariables: newCustomVariables,
               type: data.type,
               name: data.name,
               description: data.description,
          });
          setBtnSaveStatus(true);
     };

     const handleSaveLookupTable = (data) => {
          setSaveLoading(true);
          callTokenApi(API_CLIENT_RULE_UPDATE, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         const newRule = {
                              ...rule,
                              ...data,
                         };
                         setRule(newRule);

                         const newClickRules = [...clickRules].map((rule) => {
                              if (rule.id === newRule.id) {
                                   return { ...rule, ...newRule };
                              }
                              return rule;
                         });
                         dispatch(setClickRules(newClickRules));
                         dispatch(setInitialClickRules(deepCopyArray(newClickRules)));
                         dispatch(setRuleHaveEditting({ show: false, type: '', showLv2: false, typeLv2: '' }));
                         dispatch(setFlexibleModal({
                              show: false,
                              showLv2: false,
                         }));
                    } else {
                         if (response.data.accountBlocked) {
                              dispatch(setShowBlockAccountPopup(true));
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => {
                    setSaveLoading(false);
                    setSaveTableModal(false);
               });
     };

     const handleReverting = () => {
          const data = {
               accountId: activeAccountId,
               ruleId: rule.id,
          };
          setSaveLoading(true);
          callTokenApi(API_CLIENT_RULE_REVERT, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         dispatch(updateRule(data));
                         fetchRule();
                    } else {
                         if (response.data.accountBlocked) {
                              dispatch(setShowBlockAccountPopup(true));
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => setSaveLoading(false));
     };

     const revertToDefault = () => {
          let data = {
               accountId: activeAccountId,
               ruleId: rule.id,
          };
          setSaveLoading(true);
          callTokenApi(API_CLIENT_RULE_REVERT_DEFAULT, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         fetchRule();
                         dispatch(updateRule(data));
                         toast.success('Revert to default rules!');
                    } else {
                         if (response.data.accountBlocked) {
                              dispatch(setShowBlockAccountPopup(true));
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => {
                    setSaveLoading(false);
                    setRevertDefaultModal(false);
               });
     };

     const handleDelete = () => {
          setBtnSaveStatus(true);
          setDeleteLoading(true);
          callTokenApi(`${API_CLIENT_RULE}${rule.id}`, 'DELETE', null).then((response) => {
               if (response.status === 200) {
                    const newClickRules = clickRules.filter((el) => el.id !== rule.id);
                    dispatch(setClickRules(newClickRules));
                    dispatch(setInitialClickRules(deepCopyArray(newClickRules)));
                    dispatch(setFlexibleModal({
                         show: false,
                         showLv2: false,
                         ruleId: null,
                         ruleIdLv2: null,
                         component: '',
                         componentLv2: '',
                    }));
                    dispatch(setRuleHaveEditting({ show: false, type: '', showLv2: false, typeLv2: '' }));
                    setDeleteRuleModal(false);
                    toast.success('Rule is deleted!');
               } else {
                    if (response.data.accountBlocked) {
                         dispatch(setShowBlockAccountPopup(true));
                    } else {
                         toastError(response);
                    }
               }
          });
     };

     const resetBtnSaveStatus = () => {
          if (btnSaveStatus === true) {
               setBtnSaveStatus(false);
               dispatch(setRuleHaveEditting({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.EDIT_TABLE }));
          }
     };

     const handleChangeRowData = (value, arrayName, rowIndex) => {
          let data = [...tableRow];

          switch (arrayName) {
               case 'matchType':
                    data[rowIndex].conditions.matchType = value;
                    break;
               case 'clickUrlElement':
                    data[rowIndex].conditions.clickUrlElement = value;
                    break;
               case 'value':
                    data[rowIndex].conditions.value = value;
                    break;
               case 'trackAsClicks':
                    data[rowIndex].customVariables.trackAsClicks = value;
                    break;
               default:
                    break;
          }

          setTableRow(data);
          resetBtnSaveStatus();
     };

     const onDragEnd = (result) => {
          if (!result.destination) {
               return;
          }

          if (result.destination.index === result.source.index) {
               return;
          }

          const rows = reorder(tableRow, result.source.index, result.destination.index);
          resetBtnSaveStatus();
          setTableRow(rows);
     };

     const reorder = (list, startIndex, endIndex) => {
          const result = Array.from(list);
          const [removed] = result.splice(startIndex, 1);
          result.splice(endIndex, 0, removed);

          return result;
     };

     const toggleVariableModal = useCallback(() => {
          setShowVariableModal(!showVariableModal);
     }, [showVariableModal]);

     const bodyRowContext = {
          tableRow,
          setTableRow,
          resetBtnSaveStatus,
          setRemoveRowModal,
          handleChangeRowData,
          toggleVariableModal,
          hasError,
          name: rule.name,
     };
     const importExportContext = {
          // lastSavedConditions: initialRule.conditions,
          // lastSavedCustomVariables: initialRule.customVariables,
          tableRow,
          setTableRow,
          // IsExisted,
          resetBtnSaveStatus,
     };
     const observedElementsRef = useRef([]);

     useEffect(() => {
          // Handle lockdown table header Start
          let header = document.querySelectorAll('.flexible-modal-header');
          let body = document.querySelectorAll('.flexible-modal-body');
          let table = document.querySelector(".lookup-table-init");
          let stickyTable = document.querySelector(".lookup-table-sticky");

          if (header.length > 0) {
               header = header[!flexibleModal.showLv2 ? 0 : 1];
          }

          if (body.length > 0) {
               body = body[!flexibleModal.showLv2 ? 0 : 1];
          }

          let headerHeight = header.offsetHeight;

          const handleResize = () => {
               let tableHeaderTop = table ? table.offsetTop : 0;
               let headerBottom = headerHeight + body.scrollTop;
               let tableHeader = table ? table.childNodes[0] : [];
               let tableHeader2ndRow = tableHeader.childNodes[1];
               let tableHeaderColumns;
               if (tableHeader2ndRow && tableHeader2ndRow.childNodes) {
                    tableHeaderColumns = tableHeader2ndRow.childNodes;
               }
               let stickyTableHeader = stickyTable.childNodes[0];
               headerHeight = header.offsetHeight;
               const updateStickyTable = () => {
                    stickyTable.style.width = table.parentNode.offsetWidth + 'px';
                    stickyTable.style.top = headerHeight + 'px';
                    for (let i = 0; i < tableHeaderColumns.length; i++) {
                         stickyTableHeader.childNodes[1].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';

                    }
               }

               if (headerBottom > tableHeaderTop + 10) {
                    if (window.screen.width === 991) {
                         setTimeout(() => {
                              updateStickyTable();
                         }, 600);
                    } else {
                         updateStickyTable();
                    }
               }
          }
          let modalBody = document.querySelectorAll('.flexible-modal-body');

          if (modalBody.length > 0) {
               modalBody = modalBody[!flexibleModal.showLv2 ? 0 : 1];
          }

          const handleScroll = () => {
               let tableHeaderTop = table ? table.offsetTop : 0;
               let headerBottom = headerHeight + body.scrollTop;
               let tableHeader = table ? table.childNodes[0] : [];
               let tableHeader2ndRow = tableHeader.childNodes ? tableHeader.childNodes[1] : null;
               let tableHeaderColumns;
               if (tableHeader2ndRow && tableHeader2ndRow.childNodes) {
                    tableHeaderColumns = tableHeader2ndRow.childNodes;
               }
               let stickyTableHeader = stickyTable.childNodes[0];
            headerHeight = header.offsetHeight;

               if (headerBottom > tableHeaderTop + 15) {
                    tableHeader.style.visibility = 'hidden';
                    stickyTable.style.display = 'block';
                    stickyTable.style.width = table.parentNode.offsetWidth + 'px';
                    stickyTable.style.top = headerHeight + 'px';
                    for (let i = 0; i < tableHeaderColumns.length; i++) {
                         stickyTableHeader.childNodes[1].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                    }
               } else {
                    table.children[0].style.visibility = 'visible';
                    stickyTable.style.display = 'none';
               }
          }

          modalBody.addEventListener('scroll', handleScroll);
          const resizeObserver = new ResizeObserver(entries => {
               entries.forEach(() => {
                    handleResize();
               });
          });

          const observedElements = document.querySelectorAll(`.ll-flexible-modal`);

          observedElements.forEach(element => {
               resizeObserver.observe(element);
          });

          observedElementsRef.current = Array.from(observedElements);
          return () => {
               resizeObserver.disconnect();
               modalBody.removeEventListener('scroll', handleScroll);
          }
     }, []) // eslint-disable-line react-hooks/exhaustive-deps

     const tableWrapperOnScroll = (e) => {
          let stickyTable = document.querySelector('.lookup-table-sticky');
          let scrollLeft = e.target.scrollLeft;
          stickyTable.scrollLeft = scrollLeft;
     }

     const handleClickEditButton = () => {
          dispatch(
               setFlexibleModal({
                    show: true,
                    showLv2: true,
                    ruleId: rule.id,
                    ruleIdLv2: rule.id,
                    component: COMPONENT_NAME.CLICK_LOOKUP_TABLE,
                    componentLv2: COMPONENT_NAME.CLICK_SETTING_RULE,
               })
          );
          dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
     };

     return (
          <div className='rule-detail'>
               <CForm className='form-edit-lookup' onSubmit={onSubmit}>
                    <CRow>
                         <CCol md='12' className="form-edit-lookup-row">
                              <div className='form-edit-lookup-title'>
                                   <h1>Edit {rule.type}: {rule.name}</h1>
                              </div>
                              <div className='form-edit-lookup-button'>
                                   <div className='form-edit-lookup-button d-flex justify-content-end'>
                                        <div className='form-edit-lookup-button-child'>
                                             {isDefaultRule ? (
                                                  <CButton
                                                       color='primary'
                                                       onClick={() => {
                                                            setRevertDefaultModal(true);
                                                       }}
                                                       className=' d-inline-block text-uppercase'
                                                  >
                                                       Revert to Default Rules
                                                  </CButton>
                                             ) : (
                                                  <>
                                                       <CButton
                                                            onClick={() => {
                                                                 setDeleteRuleModal(true);
                                                            }}
                                                            className={`d-inline-block text-uppercase btn-outline-secondary text-dark`}
                                                            disabled={deleteLoading}
                                                       >
                                                            Delete Rule
                                                       </CButton>
                                                       {
                                                            !flexibleModal.showLv2 && (
                                                                 <CButton color='primary' onClick={handleClickEditButton} className='d-inline-block text-uppercase'>
                                                                      Edit Rule Settings
                                                                 </CButton>
                                                            )
                                                       }
                                                  </>
                                             )}
                                        </div>
                                        <div>
                                             {rule.hasOldVersion && (
                                                  <CButton
                                                       color='primary'
                                                       onClick={() => {
                                                            setRevertModal(true);
                                                       }}
                                                       className='mr-3 d-inline-block text-uppercase'
                                                  >
                                                       Revert Recent Changes
                                                  </CButton>
                                             )}
                                             <CButton color='success' type='submit' className='d-inline-block text-uppercase' disabled={btnSaveStatus}>
                                                  Save Changes
                                             </CButton>
                                        </div>
                                   </div>
                              </div>
                         </CCol>
                         <CCol md='12' className='form-edit-lookup-description'>
                              <p>The rules below define the click settings. Rules are read from top to bottom until a match is found. Insert your own rules or modify
                                   the default values here.</p>
                         </CCol>
                    </CRow>

                    <div className='table-wrapper mb-3' onScroll={(e) => tableWrapperOnScroll(e)}>
                         <DragDropContext onDragEnd={onDragEnd}>
                              <Droppable droppableId='table'>
                                   {(provided) => (
                                        <>
                                             <table className='table table-user-source lookup-table lookup-table-init' ref={provided.innerRef}>
                                                  <thead>
                                                       <tr className='rule-flow'>
                                                            <td colSpan='3'>If these conditions are true</td>
                                                            <td colSpan='1'>{trackAsText}</td>
                                                       </tr>
                                                       <TableHeaderRow />
                                                  </thead>
                                                  <tbody>
                                                       <LookupTableContext.Provider innerRef={provided.innerRef} {...provided.droppableProps} value={bodyRowContext}>
                                                            <TableBodyRows />
                                                            {provided.placeholder}
                                                       </LookupTableContext.Provider>
                                                  </tbody>
                                             </table>
                                             <table className='table lookup-table lookup-table-sticky'>
                                                  <thead>
                                                       <tr className='rule-flow'>
                                                            <td colSpan='3'>If these conditions are true</td>
                                                            <td colSpan='1'>Track as Internal Clicks?</td>
                                                       </tr>
                                                       <TableHeaderRow />
                                                  </thead>
                                             </table>
                                        </>
                                   )}
                              </Droppable>
                         </DragDropContext>
                    </div>
                    <div className={`add-margin-cheat-sheet form-edit-lookup-row`}>
                         <div className={`add-row-button mb-4 d-flex align-items-center `}>
                              <div className='add-row-button-child d-flex'>
                                   <CButton
                                        className='btn-add-row'
                                        onClick={() => handleAddRow(1)}
                                   >
                                        <CIcon name='iconAddField' className='icon-add' />
                                        <CLabel className='add-row mb-0'>Add Row</CLabel>
                                   </CButton>
                                   <CButton
                                        className='btn-add-row'
                                        onClick={() => handleAddRow(5)}
                                   >
                                        <CIcon name='iconAddField' className='icon-add' />
                                        <CLabel className='add-row mb-0'>Add 5 Rows</CLabel>
                                   </CButton>
                              </div>
                              <LookupTableContext.Provider value={importExportContext}>
                                   <ImportExportTable />
                              </LookupTableContext.Provider>
                         </div>
                         <div className="mb-3 form-edit-lookup-button text-right d-flex justify-content-end">
                              <div className='form-edit-lookup-button-child'>
                                   {isDefaultRule ? (
                                        <CButton
                                             color='primary'
                                             onClick={() => {
                                                  setRevertDefaultModal(true);
                                             }}
                                             className=' d-inline-block text-uppercase'
                                        >
                                             Revert to Default Rules
                                        </CButton>
                                   ) : (
                                        <>
                                             <CButton
                                                  onClick={() => {
                                                       setDeleteRuleModal(true);
                                                  }}
                                                  className={`mr-3 d-inline-block text-uppercase btn-outline-secondary text-dark`}
                                                  disabled={deleteLoading}
                                             >
                                                  Delete Rule
                                             </CButton>
                                             {
                                                  !flexibleModal.showLv2 && (
                                                       <CButton color='primary' onClick={handleClickEditButton} className='d-inline-block text-uppercase'>
                                                            Edit Rule Settings
                                                       </CButton>
                                                  )
                                             }
                                        </>
                                   )}
                              </div>
                              <div>
                                   {rule.hasOldVersion && (
                                        <CButton
                                             color='primary'
                                             onClick={() => {
                                                  setRevertModal(true);
                                             }}
                                             className='mr-3 d-inline-block text-uppercase'
                                        >
                                             Revert Recent Changes
                                        </CButton>
                                   )}
                                   <CButton color='success' type='submit' className='d-inline-block text-uppercase' disabled={btnSaveStatus}>
                                        Save Changes
                                   </CButton>
                              </div>
                         </div>
                    </div>
               </CForm>
               <RemovePopup show={removeRowModal.show} onAccept={confirmRemoveRow} onClose={() => setRemoveRowModal({ show: false, index: -1 })} loading={deleteLoading}>
                    <p>Are you sure to remove this row?</p>
               </RemovePopup>
               <ConfirmSaveChange
                    show={saveTableModal}
                    onAccept={onAcceptSaveModal}
                    onClose={() => setSaveTableModal(false)}
                    title='Save Your Changes?'
                    isLoading={saveLoading}
               >
                    It looks like you have made changes to this lookup table but have not saved them. Would you like to save the changes before navigating away?
               </ConfirmSaveChange>
               <ConfirmSaveChange show={revertModal} onAccept={handleReverting} onClose={() => setRevertModal(false)} title='Revert This Rule?' isLoading={saveLoading}>
                    This rule will be reverted to the last version. Would you like to revert it?
               </ConfirmSaveChange>
               <ConfirmSaveChange
                    show={revertDefaultModal}
                    onAccept={revertToDefault}
                    onClose={() => setRevertDefaultModal(false)}
                    title='Revert Default This Rule?'
                    isLoading={saveLoading}
               >
                    This rule will be reverted to default. Would you like to revert it?
               </ConfirmSaveChange>
               <LeaveComponentChangePopup
                    show={leaveComponentModal || unsavedLookupTableModal.show}
                    onAccept={handleAcceptLeaveComponent}
                    onClose={handleCloseLeaveComponent}
                    title='You Have Unsaved Changes'
                    btnAbandon={btnAbandon ? <span className='dots-waiting'>Waiting</span> : 'Abandon My Changes & Leave'}
               >
                    You haven’t saved the changes you started making to this lookup table. If you navigate away, you will lose the changes. What do you want to do?
               </LeaveComponentChangePopup>
               <ConfirmRemovePopup show={deleteRuleModal} onAccept={handleDelete} onClose={() => setDeleteRuleModal(false)} isLoading={deleteLoading}>
                    <p className='mb-4'>
                         You are about to delete the rule <strong>"{rule.name}"</strong>. Please note <span className='text-danger'>this action is irreversible</span>.
                    </p>
               </ConfirmRemovePopup>
          </div>
     );
};

LookupTable.propTypes = {
     rule: PropTypes.object,
     initialRule: PropTypes.object,
     setRule: PropTypes.func,
};
export default LookupTable;
