import React from 'react';
import { CModal, CModalBody, CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import PropTypes from 'prop-types';

const ConfirmAreYourSure = ({ show, onClose, onAccept, btnTextSave, btnTextCancel }) => {
    return (
        <div className={show ? 'confirm-are-your-sure-popup' : 'confirm-are-your-sure-popup'}>
            <CModal show={show} onClose={onClose} color="primary" centered closeOnBackdrop={false}>
                <CModalBody className="text-center confirm-save">
                    <div className="confirm-save-icon">
                        <CIcon name="arrowBack" height="54" />
                    </div>
                    <h3>Are you sure?</h3>
                    <div className="confirm-save-content">
                        This will take you back to add a different website domain to your account and we will re-verify it.
                    </div>
                    <div className="confirm-save-content back">Are you sure you want to go back?</div>
                    <div className="position-relative d-inline-block">
                        <CButton className="btn-light" onClick={onAccept}>
                            {btnTextSave}
                        </CButton>
                        <CButton className="btn-light" onClick={onClose} >
                            {btnTextCancel}
                        </CButton>
                    </div>
                </CModalBody>
            </CModal>
        </div>
    );
};

ConfirmAreYourSure.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    onAccept: PropTypes.func,
    btnTextSave: PropTypes.string,
    btnTextCancel: PropTypes.string
};

ConfirmAreYourSure.defaultProps = {
    btnTextSave: 'Save Changes',
    btnTextCancel: 'Cancel'
};

export default ConfirmAreYourSure;
