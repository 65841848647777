import React, { useState, useCallback } from 'react';
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import PropTypes from 'prop-types';
import { ConfirmRemovePopup } from '../popup';

const Modal = ({ color, btnText, title, body, onAccept }) => {
     const [isVisible, setIsVisible] = useState(false);

     const toggleModal = useCallback(() => {
          setIsVisible(!isVisible);
     }, [isVisible]);

     const handleAccept = useCallback(() => {
          onAccept();
          setIsVisible(!isVisible);
     }, [onAccept, isVisible]);

     return (
          <>
               <CButton size='sm' className='ml-1' color={color} onClick={toggleModal}>
                    {btnText}
               </CButton>
               {color === 'danger' ? (
                    <ConfirmRemovePopup show={isVisible} onClose={toggleModal} onAccept={handleAccept}>
                         <p>{body}</p>
                    </ConfirmRemovePopup>
               ) : (
                    <CModal show={isVisible} onClose={toggleModal} color={color}>
                         <CModalHeader closeButton>
                              <CModalTitle>{title}</CModalTitle>
                         </CModalHeader>
                         {body && <CModalBody>{body}</CModalBody>}
                         <CModalFooter>
                              <CButton color={color} onClick={handleAccept}>
                                   Proceed
                              </CButton>{' '}
                              <CButton color='secondary' onClick={toggleModal}>
                                   Cancel
                              </CButton>
                         </CModalFooter>
                    </CModal>
               )}
          </>
     );
};

Modal.defaultProps = {
     color: '',
     btnText: '',
};

Modal.propTypes = {
     color: PropTypes.string,
     btnText: PropTypes.string,
     title: PropTypes.string,
     body: PropTypes.string,
     onAccept: PropTypes.func,
};

export default Modal;
