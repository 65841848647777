import CIcon from '@coreui/icons-react';
import {
     CBadge,
     CButton, CDataTable, CHeader,
     // CHeaderBrand,
     CHeaderNav,
     CHeaderNavItem, CLink, CToggler
} from '@coreui/react';
import classNames from 'classnames';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// import TheHeaderDropdownNotif from './TheHeaderDropdownNotif';
// import TheHeaderDropdownTasks from './TheHeaderDropdownTasks';
import { toast } from 'react-toastify';
import {
     setOverlay, setSidebarShow, toggleTheme
} from '../../../actions/common';
import {
     setNumberOfHistories, setPublishingVersion
} from '../../../actions/pusher';
import {
     fetchAnAccount,
     fetchMonitoringNotifications,
     handleSetFormCategoriesAll,
     resetAllPreview,
     resetSubscriberReducer,
     setAccountPreview,
     setAccounts,
     setActiveListener,
     setDomainPreview,
     setDomains,
     setDomainsPreview,
     setDormantAccount,
     setListenerPreview,
     setNumberOffsetFormsCategories,
     setRoutes,
     setShowAccountDormantPopup,
     setShowConfigurationRequire,
     setShowCreateAccountPopup,
     setShowIgnoredForm,
     setShowIgnoredInventoryForm,
     setShowPopupDomain,
     setShowPublishPopup,
     setTogglePreviewPopup,
     setVersionPublish
} from '../../../actions/subscriber';
import { callTokenApi } from '../../../apiCaller';
import {
     Dropdown, DropdownItem,
     DropdownMenu, DropdownToggle
} from '../../../components/general/dropdown';
import {
     API_CLIENT_FIND_USER_ACCOUNT, API_VERSION_PUBLISH, AUTH_PATH, DORMANT_STATUSES, LOCAL_SETUP_INVITED_ACCOUNT,
     LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS, LOCAL_SHOW_ONLY_ENABLED_LISTENERS,
     PLATFORM_ACCOUNT_STATUSES,
     SUBSCRIBER_MY_ACCOUNT_PATH,
     SUBSCRIBER_PATH
} from '../../../constants';
import SubscriberAccountDropdown from './AccountDropdown';
import GlobalMessage from './GlobalMessage';
import PublishVersionModal from './PublishVersionModal';
import SelectDomainModal from './SelectDomainModal';
import UpgradeToggler from './UpgradeToggler';

import ModalConfigurationRequired from '../../../components/cms/subscriber/listener-settings-v2/ModalConfigurationRequired';
import { gtmTrackingChangeLLAccount, handleToggleSidebar } from '../../../utils';
import EndSession from './EndSession';
import GetStarted from './GetStarted';
import PreviewPublishPopup from './PreviewPublishPopup';
import TheHeaderMonitoringNotification from './TheHeaderMonitoringNotification';
import Thesetting from './TheSetting';
import ToursAndConfigure from './ToursAndConfigure';
import { TOURSTEPS, TOUR_FORCE_OPEN } from '../TheTour';
import { subscriberNavKey } from '../../_nav/subscriber';

const SubscriberHeader = (props) => {
     const {
          theme,
          accounts,
          activeAccount,
          numberOfHistories,
          versionPublish,
          listenersLoading,
          accountLoading,
          setSidebarShow,
          setRoutes,
          resetSubscriberReducer,
          setPublishingVersion,
          setVersionPublish,
          setNumberOfHistories,
          setShowCreateAccountPopup,
          fetchAnAccount,
          isCustomerSupportLoginAs,
          lessonVideoRef,
          showPublishPopup,
          setShowPublishPopup,
          previewShow,
          previewRunning,
          setShowPopupDomain,
          showPopupDomain,
          setListenerPreview,
          showConfigurationRequired,
     } = props;
     const location = useLocation();
     const history = useHistory();
     const dispatch = useDispatch();
     const { sidebarShow, overlay, user } = theme;
     const accordion = useSelector(state => state.subscriber.accordion);
     const previewUrl = useSelector((state) => state.subscriber.previewUrl);
     const { showTour, stepTour } = useSelector(state => state.subscriber.tour);
     const { showNewLayout, activeMainNavItem } = useSelector(state => state.subscriber.newLayout);

     const headerClass = classNames({ 'open-dropdown': overlay });
     const showMyAccount = Object.entries(SUBSCRIBER_MY_ACCOUNT_PATH).some(([_, value]) => location.pathname.includes(value));
     const userId = user.id;

     const [endSession, setEndSession] = useState(false);
     const [handleEndSession, setHandleEndSession] = useState(() => () => { });

     const handleToggleIcon = (val) => {
          const headerToggle = document.querySelector('.c-wrapper .toggle-sidebar');
          if (headerToggle) {
               const headerToggleClassList = headerToggle.classList;
               if (val === 'responsive') {
                    headerToggleClassList.remove('toggler-right');
               } else {
                    headerToggleClassList.add('toggler-right');
               }
          }

          const headerToggleMobile = document.querySelector('.c-wrapper .toggle-sidebar-mobile');
          if (headerToggleMobile) {
               const headerToggleClassList = headerToggleMobile.classList;
               if (val === 'responsive') {
                    headerToggleClassList.add('toggler-right');
               } else {
                    headerToggleClassList.remove('toggler-right');
               }
          }
     }

     const toggleSidebar = () => {
          if (showNewLayout && !showMyAccount) {
               handleToggleSidebar({ toggleShowNavChild: true });
          } else {
               const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive';
               setSidebarShow(val);
               handleToggleIcon(val);
          }
     }

     const toggleSidebarMobile = () => {
          const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive';
          setSidebarShow(val);
          handleToggleIcon(val);
     }

     const handleRemoveGuideLocalStorage = () => {
          localStorage.removeItem('showAnimationRoadmap');
          localStorage.removeItem('guideGetStarted');
          localStorage.removeItem('installGoals');
          localStorage.removeItem('forCategorization');
          localStorage.removeItem('dataEnrichment');
          localStorage.removeItem('sendingData');
          localStorage.removeItem('customOptional');
          localStorage.removeItem('configureDestination');
          localStorage.removeItem('endRoadmap');
     };

     const changeAccount = (account) => {
          if (account.id !== activeAccount.id) {
               handleRemoveGuideLocalStorage();
               
               if (DORMANT_STATUSES.includes(account.platformAccountStatus)) {
                    dispatch(setDormantAccount(account));
                    dispatch(setShowAccountDormantPopup(true));
               } else if (previewRunning) {
                    setHandleEndSession(() => () => {
                         dispatch(resetAllPreview(activeAccount.id));
                         setEndSession(false);
                         selectAccountID(account);
                         if (previewUrl) {
                              const openedWindow = window.open(previewUrl.replace('previewLL=iframe', 'previewLL=1'), 'LL Preview');
                              if (openedWindow) {
                                   openedWindow.close();
                              }
                         }
                    });
                    setEndSession(true);
               } else {
                    selectAccountID(account);
               }
               dispatch(setNumberOffsetFormsCategories(0));
               dispatch(handleSetFormCategoriesAll());
               dispatch(setShowIgnoredForm(false));
               dispatch(setShowIgnoredInventoryForm(false));
          }
     };

     const selectAccountID = (account) => {
          // Prevent select same account
          if (account.id !== activeAccount.id) {
               setRoutes([]); // Prevent Dashboard from loading twice cause it's gonna fetch data twice
               resetSubscriberReducer();
               fetchAnAccount(account, accounts); // Mainly fetch new used items for selected account
               dispatch(fetchMonitoringNotifications(account.id));
               dispatch(setActiveListener(null));
               // dispatch(setActiveDestination(null));

               localStorage.setItem('accountId', account.id);
               localStorage.removeItem(LOCAL_SHOW_ONLY_ENABLED_LISTENERS);
               localStorage.removeItem(LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS);
               localStorage.removeItem('LeaveStepCustomForms');

               history.push(`/${account.secondId}`);

               let localSetupInvitedAccount = localStorage.getItem(LOCAL_SETUP_INVITED_ACCOUNT);
               callTokenApi(`${API_CLIENT_FIND_USER_ACCOUNT}${userId}/${account.id}`, 'GET', null).then(response => {
                    if (response.status === 200) {
                         const { userAccount } = response.data;
                         // Tracking Change Account
                         const userDetails = {
                              userId: user.id,
                              email: user.email
                         };

                         const _activeAccount = accounts.find(acc => acc.id === userAccount.accountId);
                         const accountDetails = {
                              accountid: _activeAccount.id,
                              accountname: _activeAccount.name
                         };
                         gtmTrackingChangeLLAccount(userDetails, accountDetails);
                         if (localSetupInvitedAccount === 'yes' || (userAccount && !userAccount.firstLoginAccount && typeof userAccount.firstLoginAccount !== undefined && userAccount.firstLoginAccount !== 'undefined')) {
                              localStorage.removeItem(LOCAL_SETUP_INVITED_ACCOUNT);
                              // callTokenApi(`${API_CLIENT_ACCOUNT}${activeAccount.accountId}`, 'GET', null);
                              const hash = history.location.hash;
                              if (!hash || hash !== '#payment-method') {
                                   history.push(AUTH_PATH.WELCOME_SCREEN);
                              }
                         }
                    }
               });
          }
     }

     const togglePublishModal = () => {
          setTimeout(() => {
               if (activeAccount.numberOfDomains < 1 && !showPublishPopup) {
                    toast.warn("Domain settings incomplete. This is vital for your website tracking to work.");
                    history.push(SUBSCRIBER_PATH.WEBSITE);
               } else {
                    dispatch(setShowPublishPopup(!showPublishPopup));
               }
          }, 0);
     }
     const toggleClosePopupConfigurationRequire = () => {

          dispatch(setShowConfigurationRequire({ show: false, listener: accordion }))

     }

     const previewTogglePublishModal = () => {
          if (activeAccount.numberOfDomains < 1 && !showPublishPopup) {
               document.getElementById('togglePopupPublish').click();
               toast.warn("Domain settings incomplete. This is vital for your website tracking to work.");
               history.push(SUBSCRIBER_PATH.WEBSITE);
          } else {
               dispatch(setOverlay(false));
               dispatch(setShowPublishPopup(!showPublishPopup));
          }
     }

     const onAccept = (values, { setSubmitting, setValues }) => {
          if (values.name.trim() === "") {
               toast.warn("Name not empty!");
          } else {
               if (activeAccount && versionPublish.version) {
                    values.versionId = versionPublish.version.id;
                    values.accountId = activeAccount.id;
                    callTokenApi(API_VERSION_PUBLISH, 'POST', values)
                         .then((response) => {
                              if (response.status === 200) {
                                   toast.success(response.data.message);
                                   dispatch(setNumberOfHistories(0));
                                   dispatch(setPublishingVersion(response.data.version));
                                   dispatch(setVersionPublish({
                                        version: '',
                                        isPublish: 'publishing'
                                   }));
                              } else {
                                   toast.error(response.data.message);
                              }
                         })
                         .finally(() => {
                              setValues({
                                   name: '',
                                   description: ''
                              });
                              setSubmitting(false);
                              dispatch(setShowPublishPopup(!showPublishPopup));
                              dispatch(resetAllPreview(activeAccount.id));
                         })
               } else {
                    setSubmitting(false);
                    dispatch(setNumberOfHistories(0));
                    toast.error('Latest version has already published');
                    dispatch(setShowPublishPopup(!showPublishPopup));
                    dispatch(resetAllPreview(activeAccount.id));
               }
          }
     }

     const createAccountBtnClicked = () => {
          handleRemoveGuideLocalStorage();

          window.localStorage.removeItem('isRoadmap' + activeAccount.id);
          if (previewRunning) {
               setHandleEndSession(() => () => {
                    dispatch(resetAllPreview(activeAccount.id));
                    setEndSession(false);
                    setShowCreateAccountPopup(true);
                    if (previewUrl) {
                         const openedWindow = window.open(previewUrl.replace('previewLL=iframe', 'previewLL=1'), 'LL Preview');
                         if (openedWindow) {
                              openedWindow.close();
                         }
                    }
               });
               setEndSession(true);
          } else {
               setShowCreateAccountPopup(true);
          }
     }

     const createFirsTrackingAccount = () => {
          setShowCreateAccountPopup(true);

          // Pause lesson video from masterclass
          if (lessonVideoRef) {
               if (typeof lessonVideoRef.pauseVideo === 'function') {  // Youtube video
                    lessonVideoRef.pauseVideo();
               }

               if (typeof lessonVideoRef.pause === 'function') { // Wistia video
                    lessonVideoRef.pause();
               }
          }
     };

     const previewOnClick = () => {
          if (activeAccount.numberOfDomains < 1 && !showPublishPopup) {
               toast.warn("Domain settings incomplete. This is vital for your website tracking to work.");
               history.push(SUBSCRIBER_PATH.WEBSITE);
          } else {
               if (previewRunning) {
                    dispatch(setTogglePreviewPopup(true));
               } else {
                    if (activeAccount.numberOfDomains === 1) {
                         previewOnAccept();
                    } else {
                         dispatch(setShowPopupDomain(true));
                    }
               }
          }
     };

     const previewOnAccept = () => {
          dispatch(setTogglePreviewPopup(true));
          dispatch(setShowPopupDomain(false));
          dispatch(setAccountPreview({ accountId: activeAccount.id }));

          const newInitDomains = activeAccount.domains ? JSON.parse(activeAccount.domains) : [];
          dispatch(setDomainsPreview(newInitDomains));
          if (newInitDomains && newInitDomains.length === 1) {
               dispatch(setDomainPreview(newInitDomains[0].name));
          }
     };

     const cancelSelecDomain = () => {
          dispatch(setListenerPreview(''));
          dispatch(setShowPopupDomain(false));
     }

     const handleClickBackTo = () => {
          history.push(SUBSCRIBER_PATH.DASHBOARD.replace(':secondId', activeAccount.secondId));
     }

     return (
          // <CHeader className={`${headerClass} ${showTour ? ' tour-element' : ''}${TOUR_FORCE_OPEN.header.includes(stepTour) || stepTour === TOURSTEPS.notifications.orderNumber ? ' highlight' : ''}${TOUR_FORCE_OPEN.headerChild.includes(stepTour) ? ' highlight-child' : ''}`} withSubheader>
          <CHeader className={`${headerClass} ${showTour ? ' tour-element' : ''}${TOUR_FORCE_OPEN.header.includes(stepTour) ? ' highlight' : ''}${TOUR_FORCE_OPEN.headerChild.includes(stepTour) ? ' highlight-child' : ''}`} withSubheader>
               {
                    (!showMyAccount && activeAccount.id && !listenersLoading && !accountLoading) && (
                         <>
                              <GetStarted />
                              <GlobalMessage />
                         </>
                    )
               }
               <div className="mr-auto c-header-nav">
                    <CToggler
                         inHeader
                         className="ml-md-3 d-lg-none toggle-sidebar-mobile"
                         onClick={toggleSidebarMobile}
                    >
                         <CIcon name="left-hamburger" />
                    </CToggler>
                    {((activeMainNavItem && activeMainNavItem !== subscriberNavKey.DASHBOARD) || showMyAccount) && (
                         <CToggler
                              inHeader
                              className={`ml-3 d-md-down-none${showNewLayout && !showMyAccount ? ' toggle-sidebar hide' : ' toggle-sidebar'}`}
                              onClick={toggleSidebar}
                         >
                              <CIcon name="left-hamburger" />
                         </CToggler>
                    )}
                    {
                         activeAccount.id ? (
                              showMyAccount ? (
                                   <CLink onClick={handleClickBackTo} className="back-to-link">
                                        <CIcon name="icon-chevron-left" width="6" />
                                        <span>Back to {activeAccount.name}</span>
                                   </CLink>
                              ) : (
                                   <>
                                        <CHeaderNav className="d-flex">
                                             <CHeaderNavItem className="pl-6 nav-item-publish">
                                                  {
                                                       (versionPublish.isPublish === 'enable' || parseInt(numberOfHistories) > 0) ?
                                                            <CButton className={`btn-brand${showTour && stepTour === TOURSTEPS.publish.orderNumber ? ' highlight-item' : ''}`} color="primary" onClick={togglePublishModal}><span>PUBLISH</span><CIcon name="cil-send" className="ml-2" /></CButton>
                                                            : <CButton className={`btn-brand${showTour && stepTour === TOURSTEPS.publish.orderNumber ? ' highlight-item' : ''}`} color="secondary" disabled><span>PUBLISH</span><CIcon name="cil-send" className="ml-2" /></CButton>
                                                  }
                                                  {
                                                       <>
                                                            <div className="my-0 position-relative flex-column">
                                                                 {
                                                                      parseInt(numberOfHistories) > 0 ? (
                                                                           <span className="text-unpublished text-danger">{parseInt(numberOfHistories) + ' unpublished changes '}</span>
                                                                      ) : (
                                                                           <span className="text-unpublished text-secondary">0 unpublished changes</span>
                                                                      )
                                                                 }
                                                                 <CIcon name='icon-info' className="hover-show-tooltip" height="14"></CIcon>
                                                                 <div className="block-tooltip">
                                                                      <CIcon name="arrow-preview" className="arrow-preview"></CIcon>
                                                                      <div className="block-tooltip-text">
                                                                           <p>Changes you make in the interface are accumulated and must be published to go live on your website.</p>
                                                                      </div>
                                                                 </div>
                                                                 <div className="versions" >
                                                                      <CLink to={SUBSCRIBER_PATH.VERSIONS.replace(':secondId', activeAccount.secondId)}>Versions</CLink>
                                                                 </div>
                                                            </div>


                                                       </>
                                                  }
                                             </CHeaderNavItem>
                                             {
                                                  (parseInt(numberOfHistories) > 0 && !showPublishPopup) ? (
                                                       <PreviewPublishPopup togglePublishModal={previewTogglePublishModal} />
                                                  ) : (
                                                       <CHeaderNavItem className={`nav-item-datalayer text-center${stepTour === TOURSTEPS.tourOver.orderNumber ? ' highlight-item' : ''}`}>
                                                            {!previewShow && previewRunning && parseInt(numberOfHistories) === 0 ? (
                                                                 <CButton className="box-preview btn-header" onClick={previewOnClick}>
                                                                      <CIcon name="icon-expand" className='mr-2' />
                                                                      Active Live Test
                                                                      <span className={`pulse-status${previewRunning ? ' online' : ''}`}></span>
                                                                 </CButton>
                                                            ) : (
                                                                 <CButton className="btn-datalayer" onClick={previewOnClick}>
                                                                      <CIcon name="icon-expand" className="mr-2 p-0" />
                                                                      <span>TEST MY DATA LAYER</span>
                                                                 </CButton>
                                                            )}
                                                       </CHeaderNavItem>
                                                  )
                                             }
                                        </CHeaderNav>
                                   </>
                              )
                         ) : (
                              showMyAccount && (
                                   <CLink to={'#'} onClick={createFirsTrackingAccount} className="back-to-link">
                                        <CIcon name="icon-chevron-left" width="6" />
                                        <span>Create your first tracking account</span>
                                   </CLink>
                              )
                         )
                    }
               </div>
               <CHeaderNav className="right nav-account-profile">
                    {!showMyAccount && (
                         <>
                              <ToursAndConfigure />
                              <Thesetting />
                              <TheHeaderMonitoringNotification />
                         </>
                    )}
                    {
                         (!showMyAccount && !isCustomerSupportLoginAs) && (
                              <div className="account-wrap">
                                   <div className="acount">
                                        <p className="your-account">Your Accounts</p>
                                   </div>
                                   <Dropdown isHeaderDropdown className={`switch-account-nav${showTour && stepTour === TOURSTEPS.listenerAccounts.orderNumber ? ' highlight-item' : ''}`} focusInputSelector=".table-accounts input" forceOpen={showTour && stepTour === TOURSTEPS.listenerAccounts.orderNumber}>
                                        <DropdownToggle className="switch-account-btn">
                                             {
                                                  activeAccount.id ? (
                                                       <span className="account-name">{activeAccount.name}</span>
                                                  ) : (
                                                       <span className="account-name">Select an account</span>
                                                  )
                                             }
                                        </DropdownToggle>
                                        <DropdownMenu className="account-select">
                                             <div className={`table-accounts ${accounts.length < 8 ? 'no-filter' : ''}`}>
                                                  <CDataTable
                                                       items={accounts}
                                                       fields={[
                                                            { key: 'name', label: 'Account', _style: { width: '100%' }, filter: true, sorter: true },
                                                       ]}
                                                       tableFilter={{ placeholder: 'Type to filter list...' }}
                                                       columnFilter
                                                       hover
                                                       sorter
                                                       sorterValue={{
                                                            column: 'name',
                                                            asc: true
                                                       }}
                                                       scopedSlots={{
                                                            name:
                                                                 account => {
                                                                      let isActive = false;
                                                                      const accountDormant = account.platformAccountStatus === PLATFORM_ACCOUNT_STATUSES.DORMANT_PERMANENT ? 'Dormant (permanent)' : account.platformAccountStatus === PLATFORM_ACCOUNT_STATUSES.DORMANT_TEMPORARY ? 'Dormant (temporary)' : '';

                                                                      if (activeAccount.id) {
                                                                           isActive = (account.id === activeAccount.id);
                                                                      }
                                                                      return (
                                                                           <td key={account.id} onClick={() => changeAccount(account)}>
                                                                                <DropdownItem>
                                                                                     <span className={isActive ? 'active account-name' : 'account-name'}>
                                                                                          {account.name}
                                                                                          {accountDormant && <span className='label-dormant'>{accountDormant}</span>}
                                                                                     </span>
                                                                                     {
                                                                                          isActive && (
                                                                                               <CBadge className="label-current" color="secondary">
                                                                                                    CURRENT
                                                                                               </CBadge>
                                                                                          )
                                                                                     }
                                                                                </DropdownItem>
                                                                           </td>
                                                                      )
                                                                 },
                                                       }}
                                                  />
                                             </div>
                                             <DropdownItem tag="div" className="button-wrapper remove-hover" role="menuitem">
                                                  <CButton className="add-account" variant="outline" onClick={createAccountBtnClicked}>
                                                       Create New Account
                                                  </CButton>
                                             </DropdownItem>
                                        </DropdownMenu>
                                   </Dropdown>
                              </div>
                         )
                    }
                    <SubscriberAccountDropdown userRole="subscriber" />
                    <UpgradeToggler />
               </CHeaderNav>
               <PublishVersionModal show={showPublishPopup} onAccept={onAccept} onCancel={togglePublishModal} />
               <EndSession show={endSession} onAccept={handleEndSession} onCancel={() => setEndSession(false)} />
               {showPopupDomain && <SelectDomainModal show={showPopupDomain} onAccept={previewOnAccept} onClose={() => cancelSelecDomain()} />}
               {showConfigurationRequired.show && <ModalConfigurationRequired show={showConfigurationRequired.show} onCancel={() => toggleClosePopupConfigurationRequire()} />}


          </CHeader>
     )
}

const mapStateToProps = (state) => {
     return {
          theme: state.theme,
          accounts: state.subscriber.accounts,
          activeAccount: state.subscriber.activeAccount,
          activeAccountName: state.subscriber.activeAccountName,
          versionPublish: state.subscriber.versionPublish,
          numberOfHistories: state.subscriber.numberOfHistories,
          listenersLoading: state.subscriber.loadings.listeners,
          accountLoading: state.subscriber.loadings.account,
          isCustomerSupportLoginAs: state.subscriber.isCustomerSupportLoginAs,
          lessonVideoRef: state.subscriber.lessonVideoRef,
          showPublishPopup: state.subscriber.showPublishPopup,
          previewRunning: state.subscriber.preview.running,
          showPopupDomain: state.subscriber.showPopupDomain,
          showPopupBrowserVariable: state.subscriber.showPopupBrowserVariable,
          showConfigurationRequired: state.subscriber.showConfigurationRequired,
     }
}

const mapDispatchToProps = {
     toggleTheme,
     setSidebarShow,
     setAccounts,
     setRoutes,
     setDomains,
     resetSubscriberReducer,
     setPublishingVersion,
     setVersionPublish,
     setNumberOfHistories,
     setShowCreateAccountPopup,
     fetchAnAccount,
     setShowPublishPopup,
     setTogglePreviewPopup,
     setShowPopupDomain,
     setListenerPreview
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(SubscriberHeader))
