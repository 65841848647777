import React from 'react';
import {
     CButton,
     CModal,
     CModalBody,
} from '@coreui/react';
const LargeSandBox = ({ show, onClose, isScheduleDemo, toggleTalkToSales }) => {
     const onClickSchedule = () => {
          onClose()
          toggleTalkToSales()
     }
     return (
          <CModal addContentClass={'popup-sandbox'} show={show} closeOnBackdrop={true} onClose={onClose} color="primary" centered={true} className='popup-sandbox'>
               <CModalBody>
                    <h3>Need a large sandbox</h3>
                    <p>Request a demo and we’ll discuss your needs for a larger sandbox for testing.</p>
                    <CButton color="primary" disabled={isScheduleDemo} onClick={() => onClickSchedule()} className='btn-schedule'>Schedule a demo</CButton>
               </CModalBody>
          </CModal>
     );
};

export default LargeSandBox;
