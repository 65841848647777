import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CButton, CContainer, CModal } from '@coreui/react';
import { API_CLIENT_CUSTOM_VARIABLE, API_CLIENT_EVENT_ACCOUNT_RULE, LIMITATION_TYPE, RULE_GROUP, VIEW_ALL } from '../../../../../../constants';
import {
     fetchAllRulesRequest,
     setLimitationType,
     setLoadings,
     setPreviewCustomRule,
     setPreviewOptions,
     setShowHamburgerIcon,
} from '../../../../../../actions/subscriber';
import { Link } from 'react-router-dom';
import CIcon from '@coreui/icons-react';
import StepProgress from './StepProgress';
import { callTokenApi } from '../../../../../../apiCaller';

const Step1 = React.lazy(() => import('./Step1'));
const Step2 = React.lazy(() => import('./Step2'));
const Step3 = React.lazy(() => import('./Step3'));
const Step4 = React.lazy(() => import('./Step4'));

export const RuleContext = React.createContext({});
export const STEPS = {
     Step1: 'Name your rule',
     Step2: 'Write your condition',
     Step3: 'Name your variable',
     Step4: 'Save your value',
};
export const INIT_STEP_DATA = {
     name: '',
     description: '',
     conditions: [
          {
               conjunction: '',
               g0: {
                    type: 'Event',
                    key: '',
                    operator: 'eq',
                    value: 'true',
                    conjunction: '',
               },
          },
     ],
     customVariables: [
          {
               id: '',
               value: '',
          },
     ],
     group: 'rule',
};

export const CreateRuleBody = ({
     show = false,
     onBackBtnClicked,
     backBtnDisabled,
     shouldHideBackBtn,
     children,
     mainContentClassName = '',
     className = '',
}) => {
     return (
          <CContainer className={`main-container ${show ? 'show' : ''} ${className}`}>
               <div className='d-flex align-items-center m-0 header'>
                    <CButton
                         className={`btn-back m-0 ${onBackBtnClicked && !shouldHideBackBtn ? '' : 'invisible'}`}
                         onClick={onBackBtnClicked && !shouldHideBackBtn ? onBackBtnClicked : null}
                         disabled={backBtnDisabled}
                    >
                         Back
                    </CButton>
                    <div className='hero-logo'>
                         <CIcon name='logo-dark' height={23} />
                    </div>
               </div>
               <div className={`main-content ${mainContentClassName}`}>
                    {show && children}
                    <StepProgress />
               </div>
               <div className='footer-create-account'>
                    <div className='logo-footer'>
                         <CIcon name='logo-dark' height={23} />
                    </div>
                    <div className='nav-link-footer'>
                         <ul>
                              <li>
                                   <Link to='/'>Support</Link>
                              </li>
                              <li>
                                   <Link to=''>Terms of Use</Link>
                              </li>
                              <li>
                                   <Link to='/'>Privacy & Data Policy</Link>
                              </li>
                         </ul>
                    </div>
               </div>
          </CContainer>
     );
};

const CreatePreviewRule = () => {
     const dispatch = useDispatch();
     const { eventOptions, customVariableOptions } = useSelector((state) => state.subscriber.previewOptions);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const { showPopup, selectedListener, customValueData, eventCode } = useSelector((state) => state.subscriber.previewCustomRule);
     const { numberOfCusBrowserRules: usedCusBrowserRules, packageNumberOfCusBrowserRules: maxCusBrowserRules } = activeAccount;
     const activeListener = selectedListener !== VIEW_ALL && accountListeners && accountListeners.find((listener) => listener.id === selectedListener);
     const { listenerId: eventListenerId } = eventOptions;

     const [activeStep, setActiveStep] = useState(STEPS.Step1);
     const [stepsData, setStepsData] = useState({
          name: '',
          description: '',
          conditions: [
               {
                    conjunction: '',
                    g0: {
                         type: 'Event',
                         key: '',
                         operator: 'eq',
                         value: 'true',
                         conjunction: '',
                    },
               },
          ],
          customVariables: [
               {
                    id: '',
                    value: '',
               },
          ],
          group: 'rule',
     });

     const handleWhenHideAndShowPopup = () => {
          if (customValueData && showPopup) {
               setTimeout(() => document.body.classList.add('no-scroll'), 0);
          } else {
               document.body.classList.remove('no-scroll');
          }
     };
     useEffect(handleWhenHideAndShowPopup, [customValueData, showPopup]);

     const checkLimitation = () => {
          if (customValueData && usedCusBrowserRules >= maxCusBrowserRules && maxCusBrowserRules !== -1) {
               dispatch(setLimitationType(LIMITATION_TYPE.BROWSER_RULE));
               dispatch(setShowHamburgerIcon(true));
          }

          return () => dispatch(setShowHamburgerIcon(false));
     };
     useEffect(checkLimitation, [customValueData, activeListener, usedCusBrowserRules, maxCusBrowserRules]);

     const fetchRules = () => {
          if (customValueData && activeListener) {
               dispatch(fetchAllRulesRequest(activeAccount.id, RULE_GROUP.DATA_LAYER));
          }
     };
     useEffect(fetchRules, [customValueData]);

     const fetchCustomVariables = () => {
          if (customVariableOptions.length === 0) {
               dispatch(setLoadings({ customVariablesLoading: true }));
               callTokenApi(`${API_CLIENT_CUSTOM_VARIABLE}${activeAccount.id}?group=rule`, 'GET')
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(setPreviewOptions({ customVariableOptions: response.data.variables }));
                         }
                    })
                    .finally(() => dispatch(setLoadings({ customVariablesLoading: false })));
          }
     };
     useEffect(fetchCustomVariables, [customVariableOptions]);

     const fetchEvents = () => {
          if (customValueData && activeListener && (!eventListenerId || eventListenerId !== activeListener.id)) {
               callTokenApi(`${API_CLIENT_EVENT_ACCOUNT_RULE}${activeAccount.id}/${activeListener.id}`, 'GET').then((response) => {
                    if (response.status === 200) {
                         dispatch(
                              setPreviewOptions({
                                   eventOptions: {
                                        listenerId: activeListener.id,
                                        events: response.data.eventAccounts,
                                   },
                              })
                         );
                    }
               });
          }
     };
     useEffect(fetchEvents, [customValueData, activeListener, eventListenerId]);

     const closeModal = () => {
          dispatch(setPreviewCustomRule({ showPopup: false }));
     };

     const resetAllData = () => {
          setActiveStep(STEPS.Step1);
          setStepsData({
               name: '',
               description: '',
               conditions: [
                    {
                         conjunction: '',
                         g0: {
                              type: 'Event',
                              key: '',
                              operator: 'eq',
                              value: 'true',
                              conjunction: '',
                         },
                    },
               ],
               customVariables: [
                    {
                         id: '',
                         value: '',
                    },
               ],
               group: 'rule',
          });
          dispatch(setPreviewCustomRule({ customValueData: null }));
     };

     const getCurrentComponent = useCallback(() => {
          switch (activeStep) {
               case STEPS.Step1:
                    return <Step1 />;
               case STEPS.Step2:
                    return <Step2 />;
               case STEPS.Step3:
                    return <Step3 />;
               case STEPS.Step4:
                    return <Step4 />;
               default:
                    return <></>;
          }
     }, [activeStep]);

     return (
          <div className={`create-account-popup create-preview-rule cvr-create-new ${activeStep === STEPS.Step1 ? 'account-setup' : ''}`}>
               {customValueData && activeListener && (
                    <RuleContext.Provider
                         value={{
                              activeStep,
                              setActiveStep,
                              stepsData,
                              setStepsData,
                              eventCode,
                              customValueData,
                              activeListener,
                              resetAllData,
                         }}
                    >
                         <CModal show={showPopup} onClose={closeModal} id='create-account-content' closeOnBackdrop={false}>
                              <div className='modal-body'>{getCurrentComponent()}</div>
                         </CModal>
                    </RuleContext.Provider>
               )}
          </div>
     );
};

export default CreatePreviewRule;
